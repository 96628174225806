import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldArrowRight } from 'components/icons/components/bold/IconBoldArrowRight';
import { useGetOrganizationCompetitorsForSocialListeningSettingsCompetitorsViewQuery } from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useState } from 'react';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { CompetitorEdit } from './sections/competitorEdit';

export const SocialListeningSettingsCompetitorsView = () => {
  const { data: competitorsData, loading: loadingCompetitorsData } =
    useGetOrganizationCompetitorsForSocialListeningSettingsCompetitorsViewQuery(
      {
        fetchPolicy: 'cache-and-network',
      },
    );
  const navigate = useGuardNavigate();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const competitors = competitorsData?.organizationCompetitors || [];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb={6}
      >
        <Typography variant="headline-md" fontSize={theme.spacing(5)}>
          Competitors
        </Typography>
        <Button
          variant="outlined"
          endIcon={<IconBoldAddCircle size={16} />}
          onClick={() => {
            navigate(PlotRoutes.socialListeningOnboarding('competitors'));
          }}
          sx={{
            '&, &:hover': {
              borderRadius: 10,
              borderWidth: 2,
              borderColor: theme.colors?.primary.black,
              backgroundColor: theme.colors?.primary.black,
              color: theme.colors?.primary.parchment,
            },
          }}
        >
          <Typography variant="headline-sm">New Competitor</Typography>
        </Button>
      </Box>

      {!competitors.length && !loadingCompetitorsData && (
        <Typography variant="body-md" color={theme.colors?.utility[700]}>
          No competitors added yet
        </Typography>
      )}

      {loadingCompetitorsData && (
        <Typography variant="body-md" color={theme.colors?.utility[700]}>
          Loading competitors...
        </Typography>
      )}

      {!loadingCompetitorsData && !!competitors.length && (
        <>
          {competitors.map((competitor, index) => (
            <Accordion
              expanded={expandedItems.includes(competitor.id)}
              sx={{
                border: 'none',
                boxShadow: 'none',
                '&:before': {
                  backgroundColor: 'transparent',
                },
              }}
              onChange={(event, isExpanded) => {
                const isExpandedAlready = expandedItems.includes(competitor.id);
                setExpandedItems(
                  isExpanded
                    ? [...expandedItems, competitor.id]
                    : expandedItems.filter((id) => id !== competitor.id),
                );
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography
                  variant="headline-md"
                  fontSize={theme.spacing(5)}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  color={theme.colors?.utility[700]}
                >
                  <IconBoldArrowRight
                    style={{
                      transform: expandedItems.includes(competitor.id)
                        ? 'rotate(90deg)'
                        : 'rotate(0deg)',
                    }}
                    size={16}
                  />
                  <Box component="span" color={theme.colors?.primary.black}>
                    {competitor.name}
                  </Box>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CompetitorEdit competitor={competitor} />
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </>
  );
};
