import { gql } from '@apollo/client';
import { Box, PopoverProps, Typography } from '@mui/material';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { Tooltip } from 'components/common/Tooltip';
import {
  TaskFragmentTaskStatusFragment,
  TaskStatus as TaskStatusType,
  useUpdateTaskForTaskStatusMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { TaskStatusSelectItem } from './TaskStatusSelectItem';
import { getTaskStatusIcon, getTaskStatusText } from './utils';

export const TASK_FRAGMENT_TASK_STATUS = gql`
  fragment TaskFragmentTaskStatus on TaskModel {
    id
    status
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskStatus($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      ...TaskFragmentTaskStatus
    }
  }
  ${TASK_FRAGMENT_TASK_STATUS}
`;

type TaskStatusProps = {
  task: TaskFragmentTaskStatusFragment;
  readOnly?: boolean;
  variant?: 'icon' | 'button';
  iconFillColor?: string;
  iconSize?: number;
  componentsProps?: {
    popover?: Partial<PopoverProps>;
  };
  onChange?: (value: TaskStatusType) => void;
};

export const TaskStatus = (props: TaskStatusProps) => {
  const {
    task,
    readOnly,
    variant = 'icon',
    iconFillColor,
    iconSize,
    componentsProps = {},
    onChange: _onChange,
  } = props;

  const [updateTask] = useUpdateTaskForTaskStatusMutation();

  const onChange =
    _onChange ||
    ((status: TaskStatusType) => {
      updateTask({
        variables: {
          data: {
            taskId: task.id,
            data: {
              status,
            },
          },
        },
        optimisticResponse: {
          updateTask: {
            ...task,
            status,
          },
        },
      });
    });

  const options = [
    TaskStatusType.ToDo,
    TaskStatusType.InProgress,
    TaskStatusType.InReview,
    TaskStatusType.Done,
    TaskStatusType.Archived,
  ].map((status) => ({
    label: getTaskStatusText(status),
    value: status,
  }));

  const statusIcon = getTaskStatusIcon(task.status);

  // TODO: Move this into a separate component (similar to TaskPriorityLabel & TaskPriorityButton)
  const renderTriggerButton = () => (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      p={theme.spacing(2, 2.5)}
      borderRadius={3}
      whiteSpace="nowrap"
      bgcolor={theme.colors?.utility[250]}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <statusIcon.Icon
        color={iconFillColor ?? statusIcon.color}
        size={iconSize ?? statusIcon.size}
      />
      <Typography variant="body-xl" color={theme.colors?.utility[1000]}>
        {getTaskStatusText(task.status)}
      </Typography>
    </Box>
  );

  // TODO: Move this into a separate component (similar to TaskPriorityLabel & TaskPriorityButton)
  const renderTriggerIcon = () => (
    <Tooltip title={getTaskStatusText(task.status)} placement="top">
      <Box
        display="flex"
        alignItems="center"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <statusIcon.Icon
          color={iconFillColor ?? statusIcon.color}
          size={iconSize ?? statusIcon.size}
        />
      </Box>
    </Tooltip>
  );

  return (
    <AutocompletePopup
      readOnly={readOnly}
      value={options.find((option) => option.value === task.status)}
      options={options}
      renderTrigger={() =>
        variant === 'icon' ? renderTriggerIcon() : renderTriggerButton()
      }
      onChange={(_, value) =>
        !Array.isArray(value) && onChange?.(value?.value as TaskStatusType)
      }
      renderOption={(props, option, state) => {
        return (
          <TaskStatusSelectItem
            {...props}
            {...option}
            selected={state.selected}
          />
        );
      }}
      placeholder="Change status"
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        ...componentsProps?.popover,
      }}
    />
  );
};
