import {
  BillingPlanName,
  OrganizationBillingFragmentFreeTrialFragment,
  OrganizationType,
  BillingSubscriptionStatus,
} from 'graphql/generated';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { useEffect, useMemo, useState } from 'react';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { PlotRoutes } from 'Routes';
import moment from 'moment';
import { DateUtils } from 'utils/date';
import { useUserContext } from 'contexts/users/User.context';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { BillingUpgradeModal } from '../upgradeModal';

type BillingFreeTrialIndicatorProps = {
  organizationBilling: OrganizationBillingFragmentFreeTrialFragment;
  sx?: SxProps;
};

export const BillingFreeTrialIndicator = (
  props: BillingFreeTrialIndicatorProps,
) => {
  const { organizationBilling, sx } = props;

  const navigate = useGuardNavigate();

  const {
    isOpen: upgradeModalOpen,
    onOpen: upgradeModalOnOpen,
    onClose: upgradeModalOnClose,
  } = useDisclosure();

  const { setIsAlertBannerVisible, isEnterpriseOrganization } =
    useUserContext();

  const remainTrialTime = useMemo(() => {
    const trialEnd = moment(
      (organizationBilling.subscription?.trialEnd ?? 0) * 1000,
    );
    const now = moment();

    return trialEnd.diff(now, 'day');
  }, [organizationBilling]);

  const [hideBanner, setHideBanner] = useState(false);

  const isOnFreePlan = useMemo(() => {
    return organizationBilling.plan === BillingPlanName.Free;
  }, [organizationBilling.plan]);

  // only show this indicator during free trial and 5 days or less until expiry
  const isDuringTrial = useMemo(() => {
    return !!(
      organizationBilling.subscription &&
      organizationBilling.subscription.status ===
        BillingSubscriptionStatus.Trialing &&
      organizationBilling.subscription.trialEnd &&
      remainTrialTime <= 5
    );
  }, [remainTrialTime, organizationBilling.subscription]);

  useEffect(() => {
    setIsAlertBannerVisible((isDuringTrial || isOnFreePlan) && !hideBanner);
  }, [isDuringTrial, isOnFreePlan, hideBanner]);

  const renderContent = () => {
    if (isOnFreePlan && !hideBanner) {
      return (
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            width: '100%',
            bgcolor: theme.colors?.utility['orange-1'],
            padding: theme.spacing(6, 0),
            textAlign: 'center',
            position: 'relative',
            ...sx,
          }}
        >
          <Typography variant="subhead-xl">
            <Typography
              variant="subhead-xl"
              sx={{
                fontWeight: 600,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={upgradeModalOnOpen}
            >
              {organizationBilling.hasUsedFreeTrial
                ? 'Upgrade Now'
                : 'Start Free Trial'}
            </Typography>{' '}
            to unlock the full power of Plot ⚡️
          </Typography>

          <IconButton
            size="small"
            sx={{ position: 'absolute', right: '11px', top: '17px' }}
            onClick={() => setHideBanner(true)}
          >
            <IconBoldCloseCircle
              color={theme.colors?.utility['yellow-3-new']}
            />
          </IconButton>
        </Box>
      );
    }

    if (isDuringTrial) {
      return (
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            width: '100%',
            bgcolor: theme.colors?.utility['orange-1'],
            padding: theme.spacing(6, 0),
            textAlign: 'center',
            ...sx,
          }}
        >
          <Typography variant="subhead-xl">
            {DateUtils.fromNowInDays(
              (organizationBilling.subscription?.trialEnd ?? 0) * 1000,
            )}{' '}
            left on free trial!{' '}
          </Typography>

          {isEnterpriseOrganization ? (
            <Typography
              variant="subhead-xl"
              style={{
                fontWeight: 600,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() =>
                window.open(
                  'https://plotworkspace.typeform.com/plot-demo',
                  '_target',
                )
              }
            >
              Request upgrade
            </Typography>
          ) : (
            <Typography
              variant="subhead-xl"
              style={{
                fontWeight: 600,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => navigate(PlotRoutes.billing({ editCard: true }))}
            >
              Add credit card details
            </Typography>
          )}

          <Typography variant="subhead-xl">
            {' '}
            for a smooth transition.
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      {renderContent()}

      <BillingUpgradeModal
        isOpen={upgradeModalOpen}
        onClose={upgradeModalOnClose}
      />
    </>
  );
};
