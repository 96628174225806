import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { PostFragmentPostNewBadgeFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_POST_NEW_BADGE = gql`
  fragment PostFragmentPostNewBadge on PostModel {
    id
    isNew
  }
`;

export type PostNewBadgeProps = {
  post: PostFragmentPostNewBadgeFragment;
  sx?: SxProps;
};

export const PostNewBadge = (props: PostNewBadgeProps) => {
  const { post, sx } = props;

  if (!post.isNew) {
    return null;
  }

  return (
    <Box
      sx={{
        ...typography['subhead-xs'],
        py: 1,
        px: 2,
        borderRadius: 50,
        fontWeight: 600,
        bgcolor: theme.colors?.utility['blue-3-new'],
        color: theme.colors?.primary.parchment,
        ...sx,
      }}
    >
      New
    </Box>
  );
};
