import { Box, IconButton, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldArrowDown } from 'components/icons/components/bold/IconBoldArrowDown';
import { IconBoldArrowRight } from 'components/icons/components/bold/IconBoldArrowRight';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconBoldWarning2 } from 'components/icons/components/bold/IconBoldWarning2';
import { CollectionMultiPostPreview } from 'features/collection/components';
import { useCollectionPermissionCollectionTreeHook } from 'features/collectionPermission/hooks';
import { SelectMode } from 'features/collectionPermission/hooks/useCollectionPermissionCollectionTreeSelectionState';
import { PostPreview } from 'features/post';
import { PostPermissionSummary } from 'features/post-permission';
import { CollectionPermission } from 'graphql/generated';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { CollectionPermissionSummaryView } from '../summary';
import { CollectionPermissionCollectionTreeViewProps } from './types';

export const CollectionPermissionCollectionTreeView = ({
  collectionId,
  parentCollectionId,
  currentlevelOfNesting = 1,
  selectAllChildren = false,
  updatePermissionData,
}: CollectionPermissionCollectionTreeViewProps) => {
  const [showNestedCollections, setShowNestedCollections] = useState<string[]>(
    [],
  );
  const [toggledCollectionIds, setToggledCollectionIds] = useState<string[]>(
    [],
  );
  const [toggledPostIds, setToggledPostIds] = useState<string[]>([]);
  const [currentSelectMode, setCurrentSelectMode] =
    useState<SelectMode>('selectedAll');

  useEffect(() => {
    updatePermissionData({
      collectionId,
      parentCollectionId,
      mode: currentSelectMode,
      excludedChildCollectionIds: toggledCollectionIds,
      excludedPostIds: toggledPostIds,
    });
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    collectionId,
    parentCollectionId,
    currentSelectMode,
    toggledCollectionIds,
    toggledPostIds,
  ]);

  useEffect(() => {
    setCurrentSelectMode(selectAllChildren ? 'selectedAll' : 'deselectedAll');
    setToggledCollectionIds([]);
    setToggledPostIds([]);
  }, [selectAllChildren]);

  const { collections, posts, fetchNextPage, hasMoreCollections } =
    useCollectionPermissionCollectionTreeHook({ collectionId });

  const checkIsCollectionOrPostIsSelected = (
    itemId: string,
    type: 'collection' | 'post',
  ) => {
    if (type === 'collection') {
      const isSelected =
        currentSelectMode === 'selectedAll' &&
        !toggledCollectionIds.includes(itemId);

      if (isSelected) {
        return isSelected;
      }

      const selectedInDeselectedMode =
        currentSelectMode === 'deselectedAll' &&
        toggledCollectionIds.includes(itemId);

      return selectedInDeselectedMode;
    }

    const isSelected =
      currentSelectMode === 'selectedAll' && !toggledPostIds.includes(itemId);

    if (isSelected) {
      return isSelected;
    }

    const selectedInDeselectedMode =
      currentSelectMode === 'deselectedAll' && toggledPostIds.includes(itemId);

    return selectedInDeselectedMode;
  };

  const renderSelectBtn = (type: 'collection' | 'post', itemId = '') => {
    const handleClick = () => {
      if (type === 'collection') {
        if (itemId === collectionId) {
          if (currentSelectMode === 'selectedAll') {
            setCurrentSelectMode('deselectedAll');
          } else if (currentSelectMode === 'deselectedAll') {
            setCurrentSelectMode('selectedAll');
          }
          setToggledCollectionIds([]);
          setToggledPostIds([]);
        } else if (toggledCollectionIds.includes(itemId)) {
          setToggledCollectionIds((x) => x.filter((y) => y !== itemId));
        } else {
          setToggledCollectionIds((x) => [...x, itemId]);
        }
      } else if (type === 'post') {
        if (toggledPostIds.includes(itemId)) {
          setToggledPostIds((x) => x.filter((y) => y !== itemId));
        } else {
          setToggledPostIds((x) => [...x, itemId]);
        }
      }
    };

    if (checkIsCollectionOrPostIsSelected(itemId, type)) {
      return (
        <IconButton disableRipple onClick={handleClick}>
          <IconBoldTickCircle size={24} color={theme.colors?.primary.black} />
        </IconButton>
      );
    }

    return (
      <Box
        onClick={handleClick}
        sx={{
          m: 2,
          ml: 2.5,
          cursor: 'pointer',
          width: theme.spacing(5),
          height: theme.spacing(5),
          border: `1px solid ${theme.colors?.utility[800]}`,
          borderRadius: theme.spacing(5),
        }}
      />
    );
  };

  if (!collections.length && !posts.length) {
    return (
      <Box
        my={4}
        ml={theme.spacing((currentlevelOfNesting - 1) * 6 + 10)}
        color={theme.colors?.utility[600]}
      >
        <Typography variant="body-lg">No posts or collections</Typography>
      </Box>
    );
  }

  return (
    <Box
      maxHeight="calc(100vh - 400px)"
      sx={{
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <InfiniteScroll
        hasMore={hasMoreCollections}
        threshold={200}
        initialLoad={false}
        useWindow={false}
        loadMore={() => fetchNextPage()}
      >
        <Box sx={{ minWidth: 0, mt: 4 }}>
          {currentlevelOfNesting === 1 && (
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <Box sx={{ width: theme.spacing(10) }}>
                {renderSelectBtn('collection', collectionId)}
              </Box>
              <Box flex={2} ml={4} textAlign="left" padding="none">
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[700]}
                  fontWeight={600}
                >
                  Name
                </Typography>
              </Box>
              <Box flex={1} textAlign="left" padding="none">
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[700]}
                  fontWeight={600}
                >
                  Previews
                </Typography>
              </Box>
              <Box flex={1} textAlign="left" padding="none">
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[700]}
                  fontWeight={600}
                >
                  Permissions
                </Typography>
              </Box>
            </Box>
          )}
          <Box>
            {collections.map((c) => {
              const canEdit = c.myPermissions.includes(
                CollectionPermission.Update,
              );
              return (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      width: '100%',
                      height: theme.spacing(18),
                      borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <Box sx={{ width: theme.spacing(10) }} padding="none">
                      {canEdit ? (
                        renderSelectBtn('collection', c.id)
                      ) : (
                        <Tooltip title="You can't share this post because you don't have full access">
                          <Box
                            display="flex"
                            m={2}
                            color={theme.colors?.utility[800]}
                          >
                            <IconBoldWarning2 />
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                    <Box
                      padding="none"
                      sx={{
                        color: theme.colors?.primary.black,
                        flex: 2,
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={2}
                        sx={{
                          ml: theme.spacing((currentlevelOfNesting - 1) * 6),
                          opacity: canEdit ? 1 : 0.5,
                        }}
                      >
                        <IconButton
                          disableRipple
                          onClick={() => {
                            if (showNestedCollections.includes(c.id)) {
                              setShowNestedCollections((x) =>
                                x.filter((item) => item !== c.id),
                              );
                            } else {
                              setShowNestedCollections((x) => [...x, c.id]);
                            }
                          }}
                        >
                          {showNestedCollections.includes(c.id) ? (
                            <IconBoldArrowDown size={16} />
                          ) : (
                            <IconBoldArrowRight size={16} />
                          )}
                        </IconButton>
                        <Typography
                          sx={{
                            ...theme.typography['headline-sm'],
                            color: theme.colors?.primary.black,
                          }}
                        >
                          {c.name}
                        </Typography>
                      </Box>
                    </Box>
                    <CollectionMultiPostPreview
                      collection={c}
                      variant="card"
                      sx={{ display: 'flex', flex: 1, borderBottom: 'none' }}
                    />
                    <Box sx={{ display: 'flex', flex: 1 }}>
                      <CollectionPermissionSummaryView collection={c} />
                    </Box>
                  </Box>
                  <Box>
                    {showNestedCollections.includes(c.id) && (
                      <CollectionPermissionCollectionTreeView
                        selectAllChildren={
                          (currentSelectMode === 'selectedAll' &&
                            !toggledCollectionIds.includes(c.id)) ||
                          (currentSelectMode === 'deselectedAll' &&
                            toggledCollectionIds.includes(c.id))
                        }
                        currentlevelOfNesting={currentlevelOfNesting + 1}
                        collectionId={c.id}
                        parentCollectionId={collectionId}
                        updatePermissionData={updatePermissionData}
                      />
                    )}
                  </Box>
                </>
              );
            })}

            {posts.map((p) => (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  width: '100%',
                  height: theme.spacing(18),
                  borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                }}
              >
                <Box sx={{ width: theme.spacing(10) }} padding="none">
                  {renderSelectBtn('post', p.id)}
                </Box>
                <Box
                  padding="none"
                  sx={{
                    color: theme.colors?.primary.black,
                    flex: 2,
                  }}
                >
                  <Box
                    display="flex"
                    sx={{
                      ...theme.typography['headline-sm'],
                      pl: theme.spacing((currentlevelOfNesting - 1) * 6 + 10),
                    }}
                  >
                    {p.title ||
                      p.description ||
                      p.attachment?.name ||
                      p.urlMetadata[0].url}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flex: 1, borderBottom: 'none' }}>
                  <PostPreview
                    post={p}
                    variant="compact"
                    sx={{
                      width: theme.spacing(9),
                      height: theme.spacing(12),
                      borderRadius: theme.spacing(1),
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', flex: 1 }}>
                  <PostPermissionSummary
                    post={p}
                    componentProps={{
                      sx: {
                        backgroundColor: 'rgba(35, 6, 3, 0.10)',
                        ml: 2,
                        '& .MuiTypography-root': {
                          ...typography['body-lg'],
                          fontWeight: 400,
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </InfiniteScroll>
    </Box>
  );
};
