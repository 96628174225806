import { Box, Typography } from '@mui/material';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { SocialMediaListeningTopicOnboardingView } from 'features/socialMediaListening/views/onboarding/SocialMediaListeningTopicOnboardingView';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export const SocialMediaListeningOnboardingTopicPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const isNew = params.get('new') === 'true';

  useEffect(() => {
    if (isNew) {
      navigate(PlotRoutes.socialListeningOnboarding('topic'));
    }
  }, [isNew, navigate, params]);

  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (isNew) {
      setReload(true);

      setTimeout(() => {
        setReload(false);
      }, 0);
    }
  }, [isNew]);

  const { setRenderTitle, setHeaderSx } = useCustomHeaderContext();

  useEffect(() => {
    setHeaderSx({
      borderBottom: `1px solid ${theme.colors?.primary.parchment}`,
      color: theme.colors?.primary.parchment,
      bgcolor: theme.colors?.utility.blueberry,
      '& svg': {
        color: theme.colors?.primary.parchment,
      },
    });

    setRenderTitle(() => (
      <Box
        color={theme.colors?.primary.parchment}
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
      // eslint-disable-next-line react/jsx-no-useless-fragment
      setRenderTitle(() => <></>);
    };
  }, [location.pathname]); // eslint-disable-line

  return reload ? null : <SocialMediaListeningTopicOnboardingView />;
};
