/**
 * Format number to fixed decimal places
 */
export const formatNumber = (number: number, fix = 2) => {
  if (Number.isNaN(number)) {
    return number;
  }

  return Number(number.toFixed(fix));
};

/**
 * Format number to compact format with k, m, b, ...
 */
export const formatBigNumber = (number: number) => {
  if (Number.isNaN(number)) {
    return number;
  }

  if (number < 1000) {
    return number;
  }

  const units = ['k', 'm', 'b', 't'];
  const unit = Math.floor((number.toFixed(0).length - 1) / 3);
  const num = formatNumber(number / 1000 ** unit, 2);

  return num + units[unit - 1];
};
