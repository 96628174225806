import { Box, BoxProps } from '@mui/material';
import { DndDragItemTypes } from 'features/dnd/constants';
import { ReactNode } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

export type DndDropItemProps = Omit<BoxProps, 'onDrop'> & {
  accept: DndDragItemTypes[];
  children?: ReactNode;
  onDrop?: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
  ) => void;
};

export const DndDropItem = (props: DndDropItemProps) => {
  const { accept, children, sx, onDrop, ...rest } = props;

  const [collected, drop] = useDrop(() => ({
    accept,
    drop: (...props) => onDrop?.(...props),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <Box
      ref={drop}
      sx={{
        position: 'relative',
        pointerEvents: collected.canDrop ? 'auto' : 'none',
        '&:after': {
          content: '""',
          display: 'block',
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transition: 'opacity 0.2s',
          opacity: collected.isOver ? 0.5 : 0,
          bgcolor: collected.canDrop ? '#F8E8CA' : 'transparent',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
