import { gql } from '@apollo/client';
import { Avatar, Box } from '@mui/material';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import {
  Platform,
  CreatorFragmentCreatorAvatarFragment,
} from 'graphql/generated';
import { forwardRef } from 'react';
import { theme } from 'styles/theme';

const platformIconMap = {
  [Platform.Instagram]: IconBoldInstagram,
  [Platform.Tiktok]: IconCustomTiktok,
};

export const CREATOR_FRAGMENT_CREATOR_AVATAR = gql`
  fragment CreatorFragmentCreatorAvatar on CreatorModel {
    id
    platform
    handle
    profilePictureUrl
  }
`;

export type CreatorAvatarProps = {
  creator: CreatorFragmentCreatorAvatarFragment;
  size?: number;
};

export const CreatorAvatar = forwardRef((props: CreatorAvatarProps, ref) => {
  const { creator, size = 24, ...rest } = props;

  const Icon = platformIconMap[creator.platform];

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
      }}
      {...rest}
    >
      <Avatar
        src={creator.profilePictureUrl || ''}
        alt={creator.handle || creator.platform}
        sx={{
          width: size,
          height: size,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: -4,
          right: -4,
          width: size / 2,
          height: size / 2,
          borderRadius: 5,
          backgroundColor: theme.colors?.utility[100],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {Icon && (
          <Icon size={size / 2 - 4} color={theme.colors?.primary.black} />
        )}
      </Box>
    </Box>
  );
});
