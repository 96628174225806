import { gql } from '@apollo/client';
import {
  ListeningTopicType,
  SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboardingFragmentDoc,
  TopicFragmentForUseTopicDataForSocialListeningOnboardingFragment,
  TopicFragmentForUseTopicDataForSocialListeningOnboardingFragmentDoc,
  UpdateBrandMentionTopicInput,
  UpdateBrandMentionTopicInputData,
  useCreateBrandMentionedTopicForUseTopicDataForSocialListeningOnboardingMutation,
  useCreateCreatorTrackingTopicForUseTopicDataForSocialListeningOnboardingMutation,
  useCreateEngagementTopicForUseTopicDataForSocialListeningOnboardingMutation,
  useDeleteTopicForUseTopicDataForSocialListeningOnboardingMutation,
  useGetAiGeneratedTopicPromptForUseTopicDataForSocialListeningOnboardingLazyQuery,
  useUpdateBrandMentionedTopicForUseTopicDataForSocialListeningOnboardingMutation,
  useUpdateCreatorTrackingTopicForUseTopicDataForSocialListeningOnboardingMutation,
  useUpdateEngagementTopicForUseTopicDataForSocialListeningOnboardingMutation,
  useUpdateTopicActiveStatusForUseTopicDataForSocialListeningOnboardingMutation,
} from 'graphql/generated';
import { useState } from 'react';
import {
  evictObject,
  getCustomOperationContext,
  modifyObject,
} from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment TopicFragmentForUseTopicDataForSocialListeningOnboarding on TopicModel {
    id
    brandId
    name
    type
    active
    generatedPrompt
    topicDescription
    prioritizationInfo
    communicationStrategy
    allowToUpdateSearchTerm
    searchTerms {
      ...SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboarding
    }
  }
  ${SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetAIGeneratedTopicPromptForUseTopicDataForSocialListeningOnboarding(
    $topicId: String!
  ) {
    aiGeneratedTopicPrompt(topicId: $topicId) {
      engagementPlan
      priorityContent
      relevantContent
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateBrandMentionedTopicForUseTopicDataForSocialListeningOnboarding(
    $data: CreateBrandMentionTopicInput!
  ) {
    createBrandMentionTopic(data: $data) {
      id
      ...TopicFragmentForUseTopicDataForSocialListeningOnboarding
    }
  }
  ${TopicFragmentForUseTopicDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateBrandMentionedTopicForUseTopicDataForSocialListeningOnboarding(
    $data: UpdateBrandMentionTopicInput!
  ) {
    updateBrandMentionTopic(data: $data) {
      id
      ...TopicFragmentForUseTopicDataForSocialListeningOnboarding
    }
  }
  ${TopicFragmentForUseTopicDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateEngagementTopicForUseTopicDataForSocialListeningOnboarding(
    $data: CreateEngagementTopicInput!
  ) {
    createEngagementTopic(data: $data) {
      id
      ...TopicFragmentForUseTopicDataForSocialListeningOnboarding
    }
  }
  ${TopicFragmentForUseTopicDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateEngagementTopicForUseTopicDataForSocialListeningOnboarding(
    $data: UpdateEngagementTopicInput!
  ) {
    updateEngagementTopic(data: $data) {
      id
      ...TopicFragmentForUseTopicDataForSocialListeningOnboarding
    }
  }
  ${TopicFragmentForUseTopicDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateCreatorTrackingTopicForUseTopicDataForSocialListeningOnboarding(
    $data: CreateCreatorTrackingTopicInput!
  ) {
    createCreatorTrackingTopic(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateCreatorTrackingTopicForUseTopicDataForSocialListeningOnboarding(
    $data: UpdateCreatorTrackingTopicInput!
  ) {
    updateCreatorTrackingTopic(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation DeleteTopicForUseTopicDataForSocialListeningOnboarding(
    $topicId: String!
  ) {
    deleteTopic(topicId: $topicId) {
      message
      success
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateTopicActiveStatusForUseTopicDataForSocialListeningOnboarding(
    $active: Boolean!
    $topicId: String!
  ) {
    updateTopicActiveStatus(active: $active, topicId: $topicId) {
      message
      success
    }
  }
`;

type UseTopicDataForSocialListeningOnboarding = {
  topics: TopicFragmentForUseTopicDataForSocialListeningOnboardingFragment[];
};

export const useTopicDataForSocialListeningOnboarding = ({
  topics,
}: UseTopicDataForSocialListeningOnboarding) => {
  const [
    createBrandMentionedTopic,
    { loading: createBrandMentionedTopicLoading },
  ] =
    useCreateBrandMentionedTopicForUseTopicDataForSocialListeningOnboardingMutation();
  const [
    updateBrandMentionedTopic,
    { loading: updateBrandMentionedTopicLoading },
  ] =
    useUpdateBrandMentionedTopicForUseTopicDataForSocialListeningOnboardingMutation();
  const [createEngagementTopic, { loading: createEngagementTopicLoading }] =
    useCreateEngagementTopicForUseTopicDataForSocialListeningOnboardingMutation();
  const [updateEngagementTopic, { loading: updateEngagementTopicLoading }] =
    useUpdateEngagementTopicForUseTopicDataForSocialListeningOnboardingMutation();
  const [deleteTopic, { loading: deletingTopic }] =
    useDeleteTopicForUseTopicDataForSocialListeningOnboardingMutation();
  const [updateTopicActiveStatus, { loading: updatingTopicStatus }] =
    useUpdateTopicActiveStatusForUseTopicDataForSocialListeningOnboardingMutation();
  const [
    getAIGeneratedTopicPrompt,
    { loading: getAIGeneratedTopicPromptLoading },
  ] =
    useGetAiGeneratedTopicPromptForUseTopicDataForSocialListeningOnboardingLazyQuery();

  const [
    createCreatorTrackingTopic,
    { loading: createCreatorTrackingTopicLoading },
  ] =
    useCreateCreatorTrackingTopicForUseTopicDataForSocialListeningOnboardingMutation();
  const [
    updateCreatorTrackingTopic,
    { loading: updateCreatorTrackingTopicLoading },
  ] =
    useUpdateCreatorTrackingTopicForUseTopicDataForSocialListeningOnboardingMutation();

  const handleAddBrandMentionedTopic = async (
    brandId: string,
    brandName: string,
    input: UpdateBrandMentionTopicInputData,
  ) => {
    const existingBrandMention = topics.find(
      (topic) =>
        topic.brandId === brandId &&
        topic.type === ListeningTopicType.BrandMention,
    );

    const generatedPrompt =
      input.communicationStrategy && input.prioritizationInfo
        ? generateTopicPromptForBrandMention(
            brandName,
            input.communicationStrategy,
            input.prioritizationInfo,
          )
        : '';

    // create a new brand mentioned topic if it doesn't exist
    if (!existingBrandMention) {
      return createBrandMentionedTopic({
        variables: {
          data: {
            brandId,
            communicationStrategy: input.communicationStrategy,
            prioritizationInfo: input.prioritizationInfo,
            generatedPrompt,
          },
        },
        update: (cache, { data }) => {
          modifyObject(cache, brandId, 'BrandModel', {
            topics: (cachedTopics = []) => [
              ...cachedTopics,
              data?.createBrandMentionTopic,
            ],
          });
        },
      });
    }

    return updateBrandMentionedTopic({
      variables: {
        data: {
          id: existingBrandMention.id,
          data: {
            name: input.name,
            communicationStrategy: input.communicationStrategy,
            prioritizationInfo: input.prioritizationInfo,
            generatedPrompt,
          },
        },
      },
    });
  };

  const handleAddEngagementTopic = async (
    data: {
      topicName?: string;
      topicDescription?: string;
      prioritizationInfo?: string;
      communicationStrategy?: string;
    },
    brandId: string,
    topicId?: string,
  ) => {
    const { topicName } = data;
    const generatedPrompt =
      data.prioritizationInfo && data.communicationStrategy
        ? generateTopicPromptForEngagementTopic(
            data.topicDescription || '',
            data.communicationStrategy || '',
            data.prioritizationInfo || '',
          )
        : '';

    // create a new engagement topic if it doesn't exist
    if (!topicId) {
      if (!topicName) {
        throw new Error('Topic name is required to create a new topic');
      }

      const createdTopicId = await createEngagementTopic({
        variables: {
          data: {
            name: topicName || '',
            brandId,
          },
        },
        context: getCustomOperationContext({
          suppressTopLevelToast: true,
        }),
        update: (cache, { data }) => {
          modifyObject(cache, brandId, 'BrandModel', {
            topics: (cachedTopics = []) => [
              ...cachedTopics,
              data?.createEngagementTopic,
            ],
          });
        },
        onError: (error) => {
          throw new Error(error.message);
        },
      });

      return createdTopicId.data?.createEngagementTopic.id;
    }

    const updatedTopic = await updateEngagementTopic({
      variables: {
        data: {
          id: topicId,
          data: {
            ...(topicName ? { name: topicName } : {}),
            ...(generatedPrompt ? { generatedPrompt } : {}),
            ...(data.topicDescription
              ? { topicDescription: data.topicDescription }
              : {}),
            ...(data.prioritizationInfo
              ? { prioritizationInfo: data.prioritizationInfo }
              : {}),
            ...(data.communicationStrategy
              ? { communicationStrategy: data.communicationStrategy }
              : {}),
          },
        },
      },
      update: (cache, { data }) => {
        modifyObject(cache, topicId, 'TopicModel', {
          name: () => topicName,
        });
        modifyObject(cache, brandId, 'BrandModel', {
          topics: (cachedTopics = []) => {
            const updatedTopics = cachedTopics.map((cachedTopic) => {
              if (cachedTopic.id === topicId) {
                return data?.updateEngagementTopic;
              }
              return cachedTopic;
            });
            return updatedTopics;
          },
        });
      },
    });
    return updatedTopic.data?.updateEngagementTopic.id;
  };

  const handleDeleteTopic = async (topicId: string) => {
    return deleteTopic({
      variables: {
        topicId,
      },
      update: (cache) => {
        evictObject(cache, topicId, 'TopicModel');
      },
    });
  };

  const handleUpdateTopicActiveStatus = async (
    active: boolean,
    topicId: string,
  ) => {
    return updateTopicActiveStatus({
      variables: {
        active,
        topicId,
      },
      update: (cache) => {
        modifyObject(cache, topicId, 'TopicModel', {
          active: () => active,
        });
      },
    });
  };

  const generateTopicPromptForBrandMention = (
    brandName: string,
    communicationStrategy: string,
    contentPrioritization: string,
  ) => {
    return `We want to engage with videos that directly mention ${brandName} and/or its products in the correct context.
This is our communication strategy:
${communicationStrategy}
This is how we plan to prioritize content:
${contentPrioritization}`;
  };

  const generateTopicPromptForEngagementTopic = (
    topicDescription: string,
    communicationStrategy: string,
    contentPrioritization: string,
  ) => {
    const topicGeneratedPrompt = topicDescription
      ? `This is how we defined whether a post is relevant to this topic
${topicDescription}`
      : '';

    const communicationGeneratedPrompt = communicationStrategy
      ? `When engaging on the post, this is our communication strategy
${communicationStrategy}`
      : '';

    const contentGeneratedPrompt = contentPrioritization
      ? `This is how we plan to prioritize our confidence score on whether to engage with the post
${contentPrioritization}`
      : '';

    return `${topicGeneratedPrompt}
${communicationGeneratedPrompt}
${contentGeneratedPrompt}`;
  };

  const handleGetAIGeneratedTopicPrompt = (topicId: string) => {
    return getAIGeneratedTopicPrompt({
      variables: {
        topicId,
      },
    });
  };

  const handleAddCreatorTrackingTopic = async (
    data: {
      topicName?: string;
      topicDescription?: string;
      prioritizationInfo?: string;
      communicationStrategy?: string;
    },
    brandId: string,
    topicId?: string,
  ) => {
    const { topicName } = data;
    const generatedPrompt =
      data.prioritizationInfo && data.communicationStrategy
        ? generateTopicPromptForEngagementTopic(
            data.topicDescription || '',
            data.communicationStrategy || '',
            data.prioritizationInfo || '',
          )
        : '';

    // create a new engagement topic if it doesn't exist
    if (!topicId) {
      if (!topicName) {
        throw new Error('Topic name is required to create a new topic');
      }

      const createdTopicId = await createCreatorTrackingTopic({
        variables: {
          data: {
            name: topicName || '',
            brandId,
          },
        },
        context: getCustomOperationContext({
          suppressTopLevelToast: true,
        }),
        update: (cache, { data }) => {
          modifyObject(cache, brandId, 'BrandModel', {
            topics: (cachedTopics = []) => [
              ...cachedTopics,
              data?.createCreatorTrackingTopic,
            ],
          });
        },
        onError: (error) => {
          throw new Error(error.message);
        },
      });

      return createdTopicId.data?.createCreatorTrackingTopic.id;
    }

    const updatedTopic = await updateCreatorTrackingTopic({
      variables: {
        data: {
          id: topicId,
          data: {
            ...(topicName ? { name: topicName } : {}),
            ...(generatedPrompt ? { generatedPrompt } : {}),
            ...(data.topicDescription
              ? { topicDescription: data.topicDescription }
              : {}),
            ...(data.prioritizationInfo
              ? { prioritizationInfo: data.prioritizationInfo }
              : {}),
            ...(data.communicationStrategy
              ? { communicationStrategy: data.communicationStrategy }
              : {}),
          },
        },
      },
      update: (cache, { data }) => {
        modifyObject(cache, topicId, 'TopicModel', {
          name: () => topicName,
        });
        modifyObject(cache, brandId, 'BrandModel', {
          topics: (cachedTopics = []) => {
            const updatedTopics = cachedTopics.map((cachedTopic) => {
              if (cachedTopic.id === topicId) {
                return data?.updateCreatorTrackingTopic;
              }
              return cachedTopic;
            });
            return updatedTopics;
          },
        });
      },
    });
    return updatedTopic.data?.updateCreatorTrackingTopic.id;
  };

  return {
    queries: {
      getAIGeneratedTopicPrompt: handleGetAIGeneratedTopicPrompt,
    },
    topicActions: {
      saving:
        createBrandMentionedTopicLoading ||
        updateBrandMentionedTopicLoading ||
        createEngagementTopicLoading ||
        updateEngagementTopicLoading ||
        updatingTopicStatus ||
        deletingTopic,

      handleAddBrandMentionedTopic,
      handleAddEngagementTopic,
      handleAddCreatorTrackingTopic,
      handleDeleteTopic,
      handleUpdateTopicActiveStatus,
    },
  };
};
