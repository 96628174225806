import { gql, useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { PlotRoutes } from 'Routes';
import { toast } from 'components/common/Toast';
import { useCollectionPermissionUpdateCollectionPermissionToParentCollectionPermission } from 'features/collectionPermission';
import {
  CollectionFragmentCollectionMultiPostPreviewFragment,
  CollectionFragmentUpdateCollectionPermissionAlertViewFragment,
  useMoveCollectionsToCollectionForUseMoveToAnotherCollectionModalMutation,
} from 'graphql/generated';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';
import { CollectionMultiPostPreview } from '../components';
import { CollectionMoveToAnotherCollection } from '../views/moveToAnotherCollection';
import { useCheckIfCustomCollection } from './useCheckIfCustomCollection';

type Props = {
  collectionId: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation MoveCollectionsToCollectionForUseMoveToAnotherCollectionModal(
    $data: MoveCollectionsToCollectionInput!
  ) {
    moveCollectionsToCollection(data: $data) {
      success
      message
    }
  }
`;

export const useMoveToAnotherCollectionModal = ({ collectionId }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const isCustomCollection = useCheckIfCustomCollection(collectionId);

  const [moveCollectionsToCollection] =
    useMoveCollectionsToCollectionForUseMoveToAnotherCollectionModalMutation();

  const {
    onOpenUpdateCollectionPermissionDialog,
    renderUpdateCollectionPermissionAlert,
  } =
    useCollectionPermissionUpdateCollectionPermissionToParentCollectionPermission();

  const { cache } = useApolloClient();

  const onMoveCollection = useCallback(
    async (
      collectionIds: string[],
      toCollection: CollectionFragmentUpdateCollectionPermissionAlertViewFragment,
    ) => {
      await moveCollectionsToCollection({
        variables: {
          data: {
            collectionIds,
            toCollectionId: toCollection.id,
          },
        },
      });

      // TODO: support multiple collections
      await onOpenUpdateCollectionPermissionDialog(
        collectionIds[0],
        toCollection,
        () => {
          collectionIds.forEach((collectionId) => {
            evictObject(cache, collectionId, 'CollectionModel');
          });
        },
      );

      if (toCollection) {
        toast({
          position: 'bottom-center',
          shouldShowCloseButton: false,
          sx: {
            bgcolor: theme.colors?.utility['purple-1'],
            color: theme.colors?.utility['purple-4'],
            borderRadius: theme.spacing(6),
            padding: theme.spacing(5, 6),
            alignItems: 'center',
            '& .MuiButtonBase-root': {
              padding: 0,
            },
          },
          message: '',
          icon: toCollection ? (
            <CollectionMultiPostPreview
              sx={{ width: theme.spacing(15), height: theme.spacing(15) }}
              collection={
                toCollection as unknown as CollectionFragmentCollectionMultiPostPreviewFragment
              }
              variant="card-stack-square"
            />
          ) : undefined,
          title: 'Collection moved!',
          actionButtons: [
            {
              variant: 'text',
              children: `Go to ${toCollection.name}`,
              onClick: () => {
                navigate(PlotRoutes.collection(toCollection.id));
              },
              sx: {
                color: theme.colors?.primary.black,
                ...theme.typography['headline-sm'],
              },
            },
          ],
        });
      }
      onClose();
    },
    [moveCollectionsToCollection, onClose],
  );

  const render = useCallback(() => {
    return (
      <>
        {isCustomCollection || !isOpen ? null : (
          <CollectionMoveToAnotherCollection
            key={collectionId}
            collectionId={collectionId}
            isOpen={isOpen}
            onClose={onClose}
            onMove={onMoveCollection}
          />
        )}

        {renderUpdateCollectionPermissionAlert()}
      </>
    );
  }, [
    isCustomCollection,
    isOpen,
    collectionId,
    onClose,
    onMoveCollection,
    renderUpdateCollectionPermissionAlert,
  ]);

  return {
    onMoveCollection,
    renderMoveToAnotherCollectionModal: render,
    showMoveCollection: onOpen,
    hideMoveCollection: onClose,
  };
};
