import { OrganizationBillingFragmentGuestPaywallFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { Box, SxProps, Typography } from '@mui/material';
import { ProgressBar } from 'components/common/ProgressBar';
import { useMemo } from 'react';
import { Parser } from 'utils/parser';
import { IconCustomInfinity } from 'components/icons/components/custom/IconCustomInfinity';

type BillingGuestLimitProgressProps = {
  organizationBilling: OrganizationBillingFragmentGuestPaywallFragment;
  filledColor?: string;
  sx?: SxProps;
};

export const BillingGuestLimitProgress = (
  props: BillingGuestLimitProgressProps,
) => {
  const { organizationBilling, filledColor, sx } = props;

  const percentageUsed = useMemo(() => {
    if (organizationBilling.guestLimit) {
      return Parser.to2Decimal(
        (organizationBilling.guestUsage / organizationBilling.guestLimit) * 100,
      );
    }
    return 0;
  }, [organizationBilling]);

  const progressBarFilledColor = useMemo(() => {
    if (percentageUsed >= 0 && percentageUsed < 60) {
      return theme.colors?.primary.black;
    }

    if (percentageUsed >= 60 && percentageUsed < 80) {
      return theme.colors?.utility['yellow-3-new'];
    }

    if (percentageUsed >= 80) {
      return theme.colors?.utility['pink-3'];
    }

    return theme.colors?.primary.maroon;
  }, [percentageUsed]);

  return !organizationBilling.guestLimit ? null : (
    <Box display="flex" flexDirection="column" gap={theme.spacing(2)} sx={sx}>
      <Box>
        <Typography color={theme.colors?.utility[1000]} variant="headline-xxs">
          Guests
        </Typography>

        <Typography variant="headline-xxs" color={theme.colors?.utility[700]}>
          {' '}
          • {organizationBilling.guestPermission} Access
        </Typography>
      </Box>

      {organizationBilling.guestLimit ? (
        <>
          <ProgressBar
            variant="percentage"
            completed={organizationBilling.guestUsage}
            total={organizationBilling.guestLimit}
            bgColor={theme.colors?.utility[275]}
            filledColor={filledColor ?? progressBarFilledColor}
          />

          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            {organizationBilling.guestUsage} out of{' '}
            {organizationBilling.guestLimit}
          </Typography>
        </>
      ) : (
        <Box
          display="flex"
          gap={theme.spacing(1)}
          justifyContent="flex-start"
          alignItems="center"
        >
          <IconCustomInfinity color={theme.colors?.utility[900]} />
          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            Unlimited Guests
          </Typography>
        </Box>
      )}
    </Box>
  );
};
