import { Masonry } from '@mui/lab';
import { Box } from '@mui/material';
import {
  COLLECTION_DRAGGABLE_PREFIX,
  CollectionPostDndCollectionCard,
  CollectionPostDndPostCard,
  POST_DRAGGABLE_PREFIX,
} from 'features/collectionPostDnd/components';
import { useNavigatePostSearchParams } from 'features/post/hooks/useNavigateSearchParams';
import {
  CollectionFragmentCollectionPostDndCollectionCardFragment,
  PostFragmentCollectionPostDndPostCardFragment,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect, useState } from 'react';
import { Droppable, DroppableProps } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { usePostMovedSnackbar } from 'features/post/hooks/usePostMovedSnackbar';
import { useCollectionPostDndContainerContext } from '../dndContainer';
import { theme } from '../../../../styles/theme';

export type CollectionPostDndMasonryViewProps = Pick<
  DroppableProps,
  'droppableId'
> & {
  posts: PostFragmentCollectionPostDndPostCardFragment[];
  collections: CollectionFragmentCollectionPostDndCollectionCardFragment[];
  smartSearchPostIds?: string[];
  layout?: 'default' | 'compact';
  stopReorderingItems?: boolean;
  startIndex?: number;
  onPostClick?: (
    postId: string,
    position: number,
    totalSearchResults: number,
  ) => void;
  onCollectionClick?: (
    collectionId: string,
    position: number,
    totalSearchResults: number,
  ) => void;
};

export const CollectionPostDndMasonryView = (
  props: CollectionPostDndMasonryViewProps,
) => {
  const {
    posts,
    collections,
    droppableId,
    smartSearchPostIds = [],
    layout = 'default',
    onPostClick,
    onCollectionClick,
    stopReorderingItems,
    startIndex = 0,
  } = props;
  const {
    renderSnackbar: renderPostMovedSnackbar,
    showSnackbar: showPostMovedSnackbar,
  } = usePostMovedSnackbar();

  const isMobile = useMediaQueryMobile();

  const { collectionId: currentCollectionId = '' } = useParams();
  const { searchParams } = useNavigatePostSearchParams({
    collectionId: currentCollectionId,
  });

  const {
    draggingId,
    isLoading,
    isSelectModeActive,
    selectedCollectionIds,
    onSelectCollection,
    selectedPostIds,
    onSelectPost,
    onDraggableCardMouseDownRef,
    onDraggableCardMouseUp,
  } = useCollectionPostDndContainerContext();

  const [dropDisabled, setDropDisabled] = useState(true);

  const handleDroppable = () => {
    if (draggingId) {
      // should only enable masonry droppable when the dragging item does not belong to the masonry
      if (draggingId.includes(COLLECTION_DRAGGABLE_PREFIX)) {
        const draggingCollectionId = draggingId.split('-')[2];
        setDropDisabled(collections.some((c) => c.id === draggingCollectionId));
      } else if (draggingId.includes(POST_DRAGGABLE_PREFIX)) {
        const draggingPostId = draggingId.split('-')[2];
        setDropDisabled(posts.some((c) => c.id === draggingPostId));
      }
    } else {
      setDropDisabled(false);
    }
  };

  useEffect(() => {
    handleDroppable();
  }, [draggingId]); // eslint-disable-line

  return (
    <Droppable droppableId={droppableId} isDropDisabled={dropDisabled}>
      {(provided, snapshot) => {
        return (
          <Box
            position="relative"
            ref={provided.innerRef}
            {...provided.droppableProps}
            key={droppableId ?? 'masonry'}
            sx={{
              backgroundColor: snapshot.isDraggingOver
                ? theme.colors?.utility['yellow-1']
                : 'transparent',
            }}
          >
            <Masonry
              columns={{
                xs: 1,
                sm: 2,
                md: layout === 'compact' ? 2 : 3,
                lg: layout === 'compact' ? 2 : 4,
                xl: layout === 'compact' ? 3 : 5,
              }}
              spacing={4}
              sx={{
                pointerEvents: isLoading ? 'none' : 'auto',
                opacity: isLoading ? 0.5 : 1,
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              {collections.map((collection, index) => {
                const selected = selectedCollectionIds.includes(collection.id);

                return (
                  <CollectionPostDndCollectionCard
                    key={collection.id}
                    collection={collection}
                    index={index + startIndex}
                    stopReorderingItems={stopReorderingItems}
                    selectable
                    selected={selected}
                    isSelectModeActive={isSelectModeActive}
                    onClick={(e) => {
                      onCollectionClick?.(
                        collection.id,
                        index + 1,
                        collections.length,
                      );

                      if (!isSelectModeActive) {
                        return;
                      }

                      e.preventDefault();
                      e.stopPropagation();
                      onSelectCollection(collection, e.shiftKey);
                    }}
                    isDropDisabled={!dropDisabled}
                    onEnterCardDropZone={() => setDropDisabled(true)}
                    onLeaveCardDropZone={handleDroppable}
                    onMouseDown={(e) => {
                      // We are not supporting drag & drop on mobile for now
                      if (isMobile) {
                        return;
                      }

                      if (isSelectModeActive) {
                        onDraggableCardMouseDownRef.current?.(e);
                      }
                    }}
                    onMouseUp={() => {
                      // We are not supporting drag & drop on mobile for now
                      if (isMobile) {
                        return;
                      }

                      if (isSelectModeActive) {
                        onDraggableCardMouseUp();
                      }
                    }}
                  />
                );
              })}

              {posts.map((post, index) => {
                const selected = selectedPostIds.includes(post.id);

                return (
                  <CollectionPostDndPostCard
                    key={post.id}
                    currentCollectionId={currentCollectionId}
                    post={post}
                    searchParams={searchParams}
                    index={index + collections.length + startIndex}
                    selectable
                    onPostMovedToCollection={(toCollectionId) => {
                      showPostMovedSnackbar(post, toCollectionId);
                    }}
                    stopReorderingItems={stopReorderingItems}
                    selected={selected}
                    isSmartSearch={smartSearchPostIds?.includes(post.id)}
                    isSelectModeActive={isSelectModeActive}
                    onClick={(e) => {
                      onPostClick?.(post.id, index + 1, posts.length);

                      if (!isSelectModeActive) {
                        return;
                      }

                      e.preventDefault();
                      e.stopPropagation();
                      onSelectPost(post, e.shiftKey);
                    }}
                    onMouseDown={(e) => {
                      // We are not supporting drag & drop on mobile for now
                      if (isMobile) {
                        return;
                      }

                      if (isSelectModeActive) {
                        onDraggableCardMouseDownRef.current?.(e);
                      }
                    }}
                    onMouseUp={() => {
                      // We are not supporting drag & drop on mobile for now
                      if (isMobile) {
                        return;
                      }

                      if (isSelectModeActive) {
                        onDraggableCardMouseUp();
                      }
                    }}
                  />
                );
              })}
            </Masonry>
            {renderPostMovedSnackbar()}
          </Box>
        );
      }}
    </Droppable>
  );
};
