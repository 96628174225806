import { gql } from '@apollo/client';
import {
  CollectionFragmentCollectionPostDndCollectionCardFragmentDoc,
  SortOrder,
  SortType,
  useGetContentIdeaCollectionsForCustomCollectionQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetContentIdeaCollectionsForCustomCollection(
    $after: String
    $take: Int
    $skip: Int
    $sortBy: SortByInputData
  ) {
    contentIdeaCollections(
      after: $after
      take: $take
      skip: $skip
      sortBy: $sortBy
    ) {
      data {
        ...CollectionFragmentCollectionPostDndCollectionCard
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CollectionFragmentCollectionPostDndCollectionCardFragmentDoc}
`;

export type UseCollectionQueryForContentCalendarProps = {
  skip?: boolean;
  sortType?: SortType;
};

export const useCollectionQueryForContentCalendar = ({
  skip,
  sortType,
}: UseCollectionQueryForContentCalendarProps) => {
  const getSortByInputData = () => {
    if (sortType === SortType.DateCreated) {
      return {
        field: 'createdAt',
        order: SortOrder.Desc,
      };
    }
    return {
      field: 'updatedAt',
      order: SortOrder.Desc,
    };
  };

  const { data, loading, fetchMore } =
    useGetContentIdeaCollectionsForCustomCollectionQuery({
      variables: {
        sortBy: getSortByInputData(),
      },
      skip,
      fetchPolicy: 'cache-and-network',
    });
  const isFirstLoading = loading && !data;
  const fetchMoreContentIdeaCollections = async () => {
    fetchMore({
      variables: {
        after: data?.contentIdeaCollections.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          contentIdeaCollections: {
            ...fetchMoreResult.contentIdeaCollections,
            data: [
              ...(prev.contentIdeaCollections.data || []),
              ...(fetchMoreResult.contentIdeaCollections.data || []),
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.id === e.id),
            ),
          },
        };
      },
    });
  };
  return {
    collectionData: data,
    isCollectionDataLoading: isFirstLoading,
    fetchMoreContentIdeaCollections,
  };
};
