import { gql } from '@apollo/client';
import { useClipboard, useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import {
  RegistrationMethod,
  UserFragmentAvatarGroupFragment,
  useUpdateUserProfileForProfileTabMutation,
} from 'graphql/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { Avatar } from 'components/common/AvatarGroup/Avatar';
import { AvatarUpload } from 'components/common/AvatarUpload';
import { toast } from 'components/common/Toast';
import { CustomController } from 'components/common/form/CustomController';
import { IconOutlineEdit2 } from 'components/icons/components/outline/IconOutlineEdit2';
import { SUPPORT_EMAIL } from 'constants/support';
import { useUserContext } from 'contexts/users/User.context';
import { useCallbackPrompt } from 'hooks/navigation/useCallbackPrompt';
import { ConfirmationDialog } from 'hooks/useConfirmationDialog/components/ConfirmationDialog';
import { theme } from 'styles/theme/theme';
import _ from 'lodash';

// eslint-disable-next-line
gql`
  mutation UpdateUserProfileForProfileTab($data: UpdateUserProfileInput!) {
    updateUserProfile(data: $data) {
      id
      firstName
      lastName
      email
      avatarUrl
    }
  }
`;

export const ProfileTab = () => {
  const [updateUserProfile] = useUpdateUserProfileForProfileTabMutation();

  const { user } = useUserContext();
  const { onCopy } = useClipboard(SUPPORT_EMAIL);
  const [uploadedImage, setUploadedImage] = useState<File>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  const { firstName, lastName, email } = watch();

  const isChanged = React.useMemo(() => {
    return (
      !_.isEqual(firstName, user?.firstName) ||
      !_.isEqual(lastName, user?.lastName) ||
      !_.isEqual(email, user?.email)
    );
  }, [
    email,
    firstName,
    lastName,
    user?.email,
    user?.firstName,
    user?.lastName,
  ]);

  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(isChanged);

  const { open: openAvatarUploader, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 8000000,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    onDropAccepted: async (files) => {
      if (files[0]) {
        setUploadedImage(files[0]);
        onOpen();
      }
    },
  });

  const onSubmit = handleSubmit(async (values) => {
    updateUserProfile({
      variables: {
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
      },
    });

    toast({
      message: 'Profile updated successfully!',
      type: 'success',
    });
  });

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      });
    }
  }, [user]); // eslint-disable-line -- only need to listen to user
  const isGoogleUser = user?.registrationMethod.includes(
    RegistrationMethod.Google,
  );
  // const [isPasswordDisabled, setPasswordDisabled] = useState(isGoogleUser);

  const fields = useMemo(() => {
    const outlinedInputs = [
      {
        label: 'First Name',
        name: 'firstName',
        rules: {
          required: 'Required',
        },
        render: ({ field, fieldState }) => {
          return (
            <OutlinedInput
              {...field}
              {...fieldState}
              error={Boolean(fieldState.error)}
            />
          );
        },
      },
      {
        label: 'Last Name',
        name: 'lastName',
        rules: {
          required: 'Required',
        },
        render: ({ field, fieldState }) => {
          return (
            <OutlinedInput
              {...field}
              {...fieldState}
              error={Boolean(fieldState.error)}
            />
          );
        },
      },
    ];

    const emailInput = {
      label: 'Email',
      name: 'email',
      rules: {
        required: 'Required',
      },
      render: ({ field, fieldState }) => {
        return (
          <>
            <OutlinedInput
              {...field}
              {...fieldState}
              error={Boolean(fieldState.error)}
              type="email"
              disabled
            />
            <Box component="small" sx={{ color: theme.colors?.utility[800] }}>
              {isGoogleUser ? (
                <>
                  This account was linked with Google. To change email please
                  contact{' '}
                  <Box
                    component="button"
                    type="button"
                    onClick={() => {
                      onCopy();
                      toast({
                        type: 'success',
                        message: 'Support email has been copied!',
                      });
                    }}
                    sx={{
                      fontWeight: 600,
                      color: theme.colors?.primary.maroon,
                    }}
                  >
                    Customer Support.
                  </Box>
                </>
              ) : (
                ''
              )}
            </Box>
          </>
        );
      },
    };

    outlinedInputs.push(emailInput);

    return outlinedInputs;
  }, [isGoogleUser, onCopy]);

  if (!user) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={10}>
      <Box display="flex" gap={4}>
        <Box position="relative">
          <Avatar
            user={user as UserFragmentAvatarGroupFragment}
            size={14}
            avatarSx={{ width: 120, height: 120 }}
          />
          <Box
            position="absolute"
            bottom={0}
            display="flex"
            justifyContent="center"
            width="100%"
            sx={{ transform: 'translateY(50%)' }}
          >
            <IconButton
              onClick={openAvatarUploader}
              sx={{
                color: 'white',
                bgcolor: theme.colors?.primary.maroon,
                ':hover': {
                  color: 'white',
                  bgcolor: theme.colors?.primary.maroon,
                },
              }}
            >
              <IconOutlineEdit2 />
            </IconButton>
            <input {...getInputProps()} />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          justifyContent="center"
        >
          <Typography variant="headline-sm">Avatar</Typography>
          <Typography variant="body-lg" color={theme.colors?.utility[800]}>
            JPG or PNG / 8MB maximum / 250x250px minimum
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        rowSpacing={10}
        columnSpacing={5}
        component="form"
        onSubmit={onSubmit}
      >
        {fields.map((field) => {
          return (
            <Grid item xs={6} key={field.name}>
              <CustomController {...field} control={control} />
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isChanged}
              sx={{
                color: 'white',
                bgcolor: theme.colors?.primary.maroon,
              }}
            >
              Save Changes
            </Button>
          </Box>
        </Grid>
      </Grid>
      {!!uploadedImage && (
        <AvatarUpload
          {...{ isOpen, onClose, uploadedImage, setUploadedImage }}
        />
      )}
      <ConfirmationDialog
        title="Leave page?"
        subtitle="You have unsaved changes, are you sure you want to leave?"
        confirmText="Save changes"
        cancelText="Leave page"
        open={showPrompt}
        onConfirm={() => {
          onSubmit();
          cancelNavigation();
        }}
        onCancel={() => {
          confirmNavigation();
        }}
      />
    </Box>
  );
};
