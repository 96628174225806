/* eslint-disable jsx-a11y/media-has-caption */
import { Box, LinearProgress, SxProps, Typography } from '@mui/material';
import { Cursor } from 'components/common/Annotation/Common/Cursor';
import { RenderAnnotation } from 'components/common/Annotation/Common/RenderAnnotation';
import { AnnotationPopover } from 'components/common/Annotation/Common/RenderAnnotation/AnnotationPopover';
import { RenderSelector } from 'components/common/Annotation/Common/RenderSelector';
import { MousePosition } from 'components/common/Annotation/types';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconBoldVolumeHigh } from 'components/icons/components/bold/IconBoldVolumeHigh';
import { IconBoldVolumeSlash } from 'components/icons/components/bold/IconBoldVolumeSlash';
import { IconLinearMaximize4 } from 'components/icons/components/linear/IconLinearMaximize4';
import { IconLinearMinimize } from 'components/icons/components/linear/IconLinearMinimize';
import { IconOutlineBackward5Seconds } from 'components/icons/components/outline/IconOutlineBackward5Seconds';
import { IconOutlineForward5Seconds } from 'components/icons/components/outline/IconOutlineForward5Seconds';
import { IconOutlineMessage2 } from 'components/icons/components/outline/IconOutlineMessage2';
import { usePostManager } from 'features/juicebox/contexts';
import {
  PostAnnotationFragmentAnnotationFragment,
  PostAnnotationInputData,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FullScreen } from 'react-full-screen';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { useViewManager } from '../../contexts/ViewManager.context';
import { getScale } from '../../utils/getScale';
import { AddTimeComment } from './AddTimeComment';
import { PlaybackSpeedSelect } from './PlaybackSpeedSelect';
import { RenderSeekbarHighlight } from './RenderSeekbarHighlight';
import { useVideoControls } from './hooks/useVideoControls';
import {
  BoxWrapper,
  ControlsWrapper,
  LeaveCommentBtn,
  StyledIconButton,
  Wrapper,
  useStyles,
} from './styles';
import { VideoAnnotationInput } from './types';

type Props = {
  videoUrl: string;
  canAnnotate?: boolean;
  annotations?: PostAnnotationFragmentAnnotationFragment[];
  onCreateAnnotation?: (annotation: PostAnnotationInputData) => void;
  renderErrorView?: () => React.ReactElement;
  videoStyle?: React.CSSProperties;
  scaleMultiplier?: number;
  alwaysShowControls?: boolean;

  onRenderDimensions?: (dimensions: { width: number; height: number }) => void;

  componentProps?: {
    wrapper: {
      sx?: SxProps;
    };
  };
};

export const CustomVideo = ({
  canAnnotate = false,
  videoUrl,
  onCreateAnnotation,
  annotations = [],
  renderErrorView,
  videoStyle: _videoStyle,
  scaleMultiplier = 1,
  componentProps,
  onRenderDimensions,
  alwaysShowControls = true,
}: Props) => {
  const location = useLocation();
  const [hasVideoPlayedOnce, setHasVideoPlayedOnce] = useState(false);
  // Get postAnnotationToPop from location state. See CommentRenderer::L61
  const locationState = location.state as {
    postAnnotationToPop?: PostAnnotationFragmentAnnotationFragment;
  } | null;

  const {
    viewportWidth,
    viewportHeight,
    maxOriginalBoundingBoxWidth,
    setMaxBoundingBoxWidth,
    setMaxOriginalBoundingBoxWidth,
  } = useViewManager();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [scale, setScale] = useState(1);
  const classes = useStyles();
  const isMobileView = useMediaQueryMobile();

  const { hideAnnotations } = usePostManager();

  const [maxOriginalBoundingBoxHeight, setMaxOriginalBoundingBoxHeight] =
    useState(0);
  const [error, setError] = useState(false);
  // current time of video
  const [currentTime, setCurrentTime] = useState(0);
  // update seekbar value according to current time
  const [seekbarValue, setSeekbarValue] = useState(0);
  // set default normal playback speed
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  // set the time when user start drawing annotation
  const [selectedTimeForAnnotation, setSelectedTimeForAnnotation] = useState(0);

  const [clickedAnnotation, setClickedAnnotation] =
    useState<PostAnnotationFragmentAnnotationFragment | null>(null);
  const bubbleRef = useRef<HTMLDivElement>(null);

  const [canCommentOnTime, setCanCommentOnTime] = useState(false);

  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: null,
    y: null,
  });

  const {
    handleFullScreen,
    onFullScreen,
    onSeekBackward,
    onSeekForward,
    onSeekbarClicked,
    setDuration,
    isFullScreen,
    setIsFullScreen,
    duration,
  } = useVideoControls(videoRef, setMaxBoundingBoxWidth);

  useLayoutEffect(() => {
    if (videoRef.current) {
      onRenderDimensions?.({
        width: videoRef.current.offsetWidth,
        height: videoRef.current.videoHeight,
      });
    }
  }, [onRenderDimensions, scale]);

  useEffect(() => {
    const scale = getScale({
      viewportWidth: isFullScreen
        ? window.innerWidth
        : viewportWidth * scaleMultiplier,
      viewportHeight: isFullScreen
        ? window.innerHeight
        : viewportHeight * scaleMultiplier,
      maxOriginalBoundingBoxWidth,
      maxOriginalBoundingBoxHeight,
    });
    setScale(scale);
    // eslint-disable-next-line
  }, [
    viewportWidth,
    viewportHeight,
    maxOriginalBoundingBoxWidth,
    maxOriginalBoundingBoxHeight,
    isFullScreen,
  ]);

  useEffect(() => {
    if (!videoRef || !videoRef.current || !videoUrl) return;

    const video = videoRef.current;
    videoRef.current.src = videoUrl;
    const handleTimeUpdate = () => {
      setCurrentTime(video.currentTime);
      setSeekbarValue((video.currentTime / video.duration) * 100);
      setMaxBoundingBoxWidth(getVideoWidth());
    };
    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
    // eslint-disable-next-line
  }, [videoRef]);

  // if annotation is showing, hide it when video time is greater than annotation time
  useEffect(() => {
    if (
      clickedAnnotation &&
      videoRef.current &&
      videoRef.current.currentTime > clickedAnnotation.time! + 1
    ) {
      setClickedAnnotation(null);
    }
  }, [clickedAnnotation, currentTime]);

  // update annotation when reply comment is added
  useEffect(() => {
    if (clickedAnnotation) {
      const updatedAnnotations = annotations.find(
        (annotation) => annotation.id === clickedAnnotation.id,
      );

      if (!updatedAnnotations) return;
      setClickedAnnotation(updatedAnnotations);
    }
  }, [clickedAnnotation, annotations]);

  // update max bounding box width when video resize
  useEffect(() => {
    const video = videoRef.current;
    // it calles when video resize
    const resizeObserver = new ResizeObserver(() => {
      if (video) {
        setMaxBoundingBoxWidth(getVideoWidth());
      }
    });

    if (video) {
      resizeObserver.observe(video);
    }

    // play and pause video on spacebar press
    const handleKeyDown = (event) => {
      const isInput =
        document?.activeElement?.matches('textarea') ||
        document?.activeElement?.matches('input') ||
        document?.activeElement?.className.includes('ProseMirror'); // exclude text editor
      if (event.code === 'Space' && video && !isInput) {
        event.preventDefault(); // Prevents spacebar from scrolling the page
        if (video.paused) {
          playVideo();
        } else {
          video.pause();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function to disconnect observer on unmount
    return () => {
      resizeObserver.disconnect();
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // eslint-disable-line

  const getVideoWidth = () => {
    if (videoRef.current) {
      return videoRef.current.getBoundingClientRect().width;
    }
    return 0;
  };

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (!(event.target instanceof HTMLVideoElement)) {
      return;
    }

    if (x && y) {
      setMousePosition({ x: null, y: null });
      return;
    }

    if (!canAnnotate) return;
    const { clientX, clientY } = event;
    const rect = event.currentTarget.getBoundingClientRect();
    const startX = clientX - rect.left;
    const startY = clientY - rect.top;

    setMousePosition({ x: startX / scale, y: startY / scale });
  };

  const onAddAnnotation = (annotation: Omit<VideoAnnotationInput, 'time'>) => {
    if (onCreateAnnotation) {
      onCreateAnnotation({
        ...annotation,
        time:
          selectedTimeForAnnotation === 0
            ? videoRef.current?.currentTime!
            : selectedTimeForAnnotation,
      });
      setMousePosition({ x: null, y: null });
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      setHasVideoPlayedOnce(true);
      videoRef.current.play();
    }
  };

  const onAnnotationClicked = (
    clickedAnnotation: PostAnnotationFragmentAnnotationFragment | null,
  ) => {
    setClickedAnnotation(clickedAnnotation);

    if (videoRef.current && clickedAnnotation) {
      videoRef.current.currentTime = clickedAnnotation.time!;
      videoRef.current.pause();
    }
  };

  // Focus annotation when postAnnotationToPop params from state changes
  // See line 56
  useEffect(() => {
    if (locationState?.postAnnotationToPop && videoRef.current) {
      const focusedAnnotation = annotations.find(
        (a) => a.id === locationState?.postAnnotationToPop?.id,
      );

      if (focusedAnnotation) {
        onAnnotationClicked(focusedAnnotation);
      }
    }
  }, [locationState?.postAnnotationToPop]); // eslint-disable-line

  const { x, y } = mousePosition;
  if (!videoUrl) return null;

  const videoStyle = {
    width: maxOriginalBoundingBoxWidth * scale,
    height: maxOriginalBoundingBoxHeight * scale,
    ..._videoStyle,
  };

  return (
    <Wrapper
      className="video-wrapper"
      sx={{ ...(componentProps?.wrapper.sx || {}) }}
    >
      <FullScreen
        handle={handleFullScreen}
        onChange={(isFullScreen) => {
          if (!isFullScreen) {
            setIsFullScreen(false);
          }
        }}
        className="fullscreen"
      >
        {error && renderErrorView?.()}
        {canAnnotate && <Cursor />}
        <Box
          sx={{
            height: isFullScreen ? '100vh' : 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: isFullScreen ? '100vw' : 'fit-content',
              position: 'relative',
            }}
          >
            <video
              className="react-custom-annotation"
              ref={videoRef}
              src={videoUrl}
              // for mobile player
              playsInline
              controls={isMobileView}
              autoPlay={isMobileView}
              onError={() => setError(true)}
              onLoadedMetadata={() => {
                if (videoRef.current) {
                  setDuration(videoRef.current.duration);
                  setMaxOriginalBoundingBoxWidth(videoRef.current.videoWidth);
                  setMaxOriginalBoundingBoxHeight(videoRef.current.videoHeight);
                  setMaxBoundingBoxWidth(getVideoWidth());
                  const scale = getScale({
                    viewportWidth,
                    viewportHeight,
                    maxOriginalBoundingBoxWidth: videoRef.current.videoWidth,
                    maxOriginalBoundingBoxHeight: videoRef.current.videoHeight,
                  });
                  setScale(scale * scaleMultiplier);
                }
              }}
              style={{ ...videoStyle, display: 'block', margin: '0 auto' }}
              onMouseDown={(e) => {
                onMouseDown(e);
                if (videoRef && videoRef.current) {
                  // pause the video when start drawing annotation
                  videoRef.current?.pause();
                  setSelectedTimeForAnnotation(videoRef.current.currentTime);
                }
              }}
            />
            {videoRef.current?.paused && (
              <Box
                position="absolute"
                sx={{
                  inset: 0,
                  borderRadius: theme.spacing(4),
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (videoRef.current) {
                    playVideo();
                  }
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <IconBoldPlayCircle
                    color={theme.colors?.primary.white}
                    size={60}
                  />
                </Box>
              </Box>
            )}

            <RenderSelector
              x={x}
              y={y}
              customScale={scale}
              onCreateAnnotation={onAddAnnotation}
              onCancel={() => setMousePosition({ x: null, y: null })}
            />

            {/* render annotation */}
            {!error && clickedAnnotation && (
              <RenderAnnotation
                annotation={clickedAnnotation}
                popoverOpenByDefault
                customScale={scale}
              />
            )}

            {clickedAnnotation &&
              clickedAnnotation.x &&
              clickedAnnotation.y && (
                <AnnotationPopover
                  anchorEl={bubbleRef.current}
                  annotation={clickedAnnotation}
                  onClose={() => {
                    setClickedAnnotation(null);
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  // Because react-full-screen will hide all the elements underneath the backdrop
                  // we need to render the popover under the DOM of the anchor instead of using portal
                  disablePortal={isFullScreen}
                />
              )}
          </Box>
          {/* ------------------------------- */}
        </Box>
        {!error && (
          <Box
            sx={{
              position: 'absolute',
              flexDirection: 'row',
              width: '100%',
              transform: 'translateY(-100%)',
              visibility:
                hasVideoPlayedOnce || alwaysShowControls ? 'visible' : 'hidden',
            }}
          >
            {!hideAnnotations && (
              <RenderSeekbarHighlight
                annotations={annotations}
                clickedAnnotation={clickedAnnotation}
                duration={duration}
                onAnnotationClicked={onAnnotationClicked}
              />
            )}
            <LinearProgress
              variant="determinate"
              value={seekbarValue || 0}
              onClick={onSeekbarClicked}
              sx={{ cursor: 'pointer' }}
              classes={{
                root: classes.root,
                barColorPrimary: classes.barColorPrimary,
              }}
            />

            {canCommentOnTime ? (
              <AddTimeComment
                onCreateAnnotation={onCreateAnnotation}
                onClose={() => {
                  setCanCommentOnTime(false);
                  setSelectedTimeForAnnotation(0);
                }}
                selectedTimeForAnnotation={selectedTimeForAnnotation}
                videoRef={videoRef.current!}
              />
            ) : (
              <ControlsWrapper
                padding="8px 20px 12px 20px"
                sx={{
                  backdropFilter: 'blur(4px)',
                }}
              >
                <BoxWrapper gap={1}>
                  <StyledIconButton
                    disableRipple
                    onClick={() =>
                      videoRef.current?.paused
                        ? playVideo()
                        : videoRef.current?.pause()
                    }
                  >
                    {videoRef.current?.paused ? (
                      <IconBoldPlayCircle color={theme.colors?.primary.white} />
                    ) : (
                      <IconBoldPauseCircle
                        color={theme.colors?.primary.white}
                      />
                    )}
                  </StyledIconButton>
                  <StyledIconButton disableRipple onClick={onSeekBackward}>
                    <IconOutlineBackward5Seconds
                      color={theme.colors?.primary.white}
                    />
                  </StyledIconButton>
                  <StyledIconButton disableRipple onClick={onSeekForward}>
                    <IconOutlineForward5Seconds
                      color={theme.colors?.primary.white}
                    />
                  </StyledIconButton>
                </BoxWrapper>

                <BoxWrapper>
                  {/* show the current time / total duration of video */}
                  <Typography
                    variant="headline-sm"
                    fontWeight={600}
                    color={theme.colors?.primary.white}
                  >
                    {`${Math.floor(currentTime / 60)}:${`0${Math.floor(
                      currentTime % 60,
                    )}`.slice(-2)}`}
                    /
                    {`${Math.floor(duration / 60)}:${`0${Math.floor(
                      duration % 60,
                    )}`.slice(-2)}`}
                  </Typography>
                </BoxWrapper>
                <BoxWrapper>
                  <StyledIconButton
                    disableRipple
                    onClick={() => {
                      if (videoRef.current) {
                        videoRef.current.muted = !videoRef.current.muted;
                      }
                    }}
                  >
                    {videoRef.current?.muted ? (
                      <IconBoldVolumeSlash
                        color={theme.colors?.primary.white}
                      />
                    ) : (
                      <IconBoldVolumeHigh color={theme.colors?.primary.white} />
                    )}
                  </StyledIconButton>

                  {onCreateAnnotation && (
                    <LeaveCommentBtn
                      variant="secondary-reverse"
                      size="small"
                      onClick={() => {
                        setCanCommentOnTime(true);
                        setSelectedTimeForAnnotation(
                          videoRef.current?.currentTime!,
                        );
                        videoRef.current?.pause();
                      }}
                    >
                      Leave a comment
                      <IconOutlineMessage2 size={16} />
                    </LeaveCommentBtn>
                  )}
                  <PlaybackSpeedSelect
                    onPlaybackSpeedChanged={(speed) => {
                      setPlaybackSpeed(speed);
                      if (!videoRef || !videoRef.current) return;
                      videoRef.current.playbackRate = speed as number;
                    }}
                    playbackSpeed={playbackSpeed}
                  />
                  <StyledIconButton
                    disableRipple
                    onClick={onFullScreen}
                    sx={{
                      mt: 1,
                    }}
                  >
                    {isFullScreen ? (
                      <IconLinearMinimize color={theme.colors?.primary.white} />
                    ) : (
                      <IconLinearMaximize4
                        size={16}
                        color={theme.colors?.primary.white}
                      />
                    )}
                  </StyledIconButton>
                </BoxWrapper>
              </ControlsWrapper>
            )}
          </Box>
        )}
      </FullScreen>
    </Wrapper>
  );
};
