import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowLeft } from 'components/icons/components/linear/IconLinearArrowLeft';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { CUSTOM_COLLECTION } from 'features/collection';
import {
  PostFilterType,
  PostFragmentUseNavigatePostSearchParamsFragment,
  PostType,
  SortOrder,
  useGetPostsSmartSearchForPostNavigationSearchParamsLazyQuery,
  usePostsForPaginationLazyQuery,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';

type Props = {
  collectionId?: string;
  postId?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
const POST_FRAGMENT_USE_NAVIGATE_POST_SEARCH_PARAMS = gql`
  fragment PostFragmentUseNavigatePostSearchParams on PostModel {
    id
    type
  }
`;

// eslint-disable-next-line
gql`
  query PostsForPagination(
    $filters: PostFilters!
    $take: Int
    $after: String
    $sortBy: SortByInputData
  ) {
    posts(filters: $filters, take: $take, after: $after, sortBy: $sortBy) {
      data {
        ...PostFragmentUseNavigatePostSearchParams
      }
    }
  }
  ${POST_FRAGMENT_USE_NAVIGATE_POST_SEARCH_PARAMS}
`;

// eslint-disable-next-line
gql`
  query GetPostsSmartSearchForPostNavigationSearchParams(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
    $sortType: SortType
    $sortBy: SortByInputData
  ) {
    postsSmartSearch(
      filters: $filters
      take: $take
      after: $after
      sortType: $sortType
      sortBy: $sortBy
    ) {
      data {
        item {
          ...PostFragmentUseNavigatePostSearchParams
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${POST_FRAGMENT_USE_NAVIGATE_POST_SEARCH_PARAMS}
`;

const SearchParams = {
  collectionId: 'collection',
};

export const useNavigatePostSearchParams = (props: Props) => {
  const [params] = useSearchParams();
  const location = useLocation();
  const prevLinkRef = useRef<HTMLAnchorElement>(null);
  const nextLinkRef = useRef<HTMLAnchorElement>(null);

  const [prevPost, setPrevPost] = useState<
    PostFragmentUseNavigatePostSearchParamsFragment | undefined
  >();
  const [nextPost, setNextPost] = useState<
    PostFragmentUseNavigatePostSearchParamsFragment | undefined
  >();

  const collectionId = params.get(SearchParams.collectionId);

  const [getPostsForPaginationForNext] = usePostsForPaginationLazyQuery();
  const [getPostsForPaginationForPrev] = usePostsForPaginationLazyQuery();
  const [getPostsSmartSearchForPaginationForNext] =
    useGetPostsSmartSearchForPostNavigationSearchParamsLazyQuery();
  const [getPostsSmartSearchForPaginationForPrev] =
    useGetPostsSmartSearchForPostNavigationSearchParamsLazyQuery();

  useEffect(() => {
    setNextPost(undefined);
    setPrevPost(undefined);

    getPost('next').then((post) => {
      if (post) {
        setNextPost(post);
      }
    });

    getPost('prev').then((post) => {
      if (post) {
        setPrevPost(post);
      }
    });
  }, [props.postId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // List of input element types to exclude
      // Here we exclude the div element because richtext editor is a div element
      const excludedInputTypes = ['input', 'textarea', 'select', 'div'];

      // Check if any input element is focused
      const isInputFocused =
        document.activeElement instanceof HTMLElement &&
        excludedInputTypes.includes(
          document.activeElement.tagName.toLowerCase(),
        );

      if (!isInputFocused) {
        if (event.key === 'ArrowLeft' && prevLinkRef.current) {
          prevLinkRef.current.click();
        } else if (event.key === 'ArrowRight' && nextLinkRef.current) {
          nextLinkRef.current.click();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [prevLinkRef, nextLinkRef]);

  const generateQueryParams = () => {
    // These params are applied on the masonry view to the post items
    return {
      ...(props.collectionId
        ? { [SearchParams.collectionId]: props.collectionId }
        : {}),
    };
  };

  const getPost = async (direction: 'next' | 'prev') => {
    if (!props.postId) {
      return;
    }

    let _filterType: PostFilterType | undefined;
    let _collectionId;
    // eslint-disable-next-line default-case
    switch (collectionId) {
      case CUSTOM_COLLECTION.ALL_POSTS:
        _filterType = PostFilterType.OrganizationPosts;
        break;
      case CUSTOM_COLLECTION.MY_POSTS:
        _filterType = PostFilterType.MyPosts;
        break;
      case CUSTOM_COLLECTION.SAVED:
        _filterType = PostFilterType.MyFavoritePosts;
        break;
      default:
        _filterType = PostFilterType.OrganizationPosts;
        _collectionId = collectionId;
    }

    const existingFilters =
      // @ts-ignore
      location.state?.filters;

    if (existingFilters && Object.keys(existingFilters).length) {
      const variables = {
        filters: {
          ...existingFilters,
          collectionIds:
            existingFilters.collectionIds.length > 0
              ? existingFilters.collectionIds
              : _collectionId
              ? [_collectionId]
              : [],
        },
        take: 1,
        after: props.postId,
      };

      const { data } =
        direction === 'next'
          ? await getPostsSmartSearchForPaginationForNext({
              variables: {
                ...variables,
                sortBy: {
                  field: 'createdAt',
                  order: SortOrder.Desc,
                },
              },
              fetchPolicy: 'network-only',
            })
          : await getPostsSmartSearchForPaginationForPrev({
              variables: {
                ...variables,
                sortBy: {
                  field: 'createdAt',
                  order: SortOrder.Asc,
                },
              },
              fetchPolicy: 'network-only',
            });

      return data?.postsSmartSearch.data?.[0]?.item;
    }

    const paginationVariables = {
      filters: {
        ...(_filterType ? { filterType: _filterType } : {}),
        ...(_collectionId ? ({ collectionId: _collectionId } as any) : {}),
      },
      take: 1,
      after: props.postId,
    };

    const { data } =
      direction === 'next'
        ? await getPostsForPaginationForNext({
            variables: {
              ...paginationVariables,
              sortBy: {
                field: 'updatedAt',
                order: SortOrder.Desc,
              },
            },
            fetchPolicy: 'network-only',
          })
        : await getPostsForPaginationForPrev({
            variables: {
              ...paginationVariables,
              sortBy: {
                field: 'updatedAt',
                order: SortOrder.Asc,
              },
            },
            fetchPolicy: 'network-only',
          });

    return data?.posts.data[0];
  };

  const renderPrevBtn = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {prevPost && (
          <Link
            to={{
              pathname:
                prevPost.type === PostType.Note
                  ? PlotRoutes.juiceboxNote({ id: prevPost.id })
                  : PlotRoutes.juice(prevPost.id),
              search: params.toString(),
            }}
            replace
            state={{
              ...((location.state || {}) as any),
              backgroundLocation: (
                location.state as { backgroundLocation?: Location }
              )?.backgroundLocation,
            }}
            ref={prevLinkRef}
          >
            <Box
              className="qwertyuiop"
              sx={{
                cursor: 'pointer',
              }}
              position="absolute"
              top="50%"
              left={theme.spacing(5)}
              width={theme.spacing(6)}
              height={theme.spacing(6)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                position="absolute"
                sx={{
                  inset: 0,
                  borderRadius: theme.spacing(10),
                  backgroundColor: theme.colors?.primary.white,
                  opacity: 0.2,
                }}
              />
              <IconLinearArrowLeft size={20} />
            </Box>
          </Link>
        )}
      </>
    );
  };

  const renderNextBtn = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {nextPost && (
          <Link
            to={{
              pathname:
                nextPost.type === PostType.Note
                  ? PlotRoutes.juiceboxNote({ id: nextPost.id })
                  : PlotRoutes.juice(nextPost.id),
              search: params.toString(),
            }}
            replace
            state={{
              ...((location.state || {}) as any),
              backgroundLocation: (
                location.state as { backgroundLocation?: Location }
              )?.backgroundLocation,
            }}
            ref={nextLinkRef}
          >
            <Box
              className="qwertyuiop"
              sx={{
                cursor: 'pointer',
              }}
              position="absolute"
              top="50%"
              right={theme.spacing(5)}
              width={theme.spacing(6)}
              height={theme.spacing(6)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                position="absolute"
                sx={{
                  inset: 0,
                  borderRadius: theme.spacing(10),
                  backgroundColor: theme.colors?.primary.white,
                  opacity: 0.2,
                }}
              />
              <IconLinearArrowRight size={20} />
            </Box>
          </Link>
        )}
      </>
    );
  };

  return {
    searchParams: generateQueryParams(),
    renderPrevBtn,
    renderNextBtn,
    prevPost,
    nextPost,
  };
};
