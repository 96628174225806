import { Box, Button, Dialog, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { AccessRequestAdminList } from 'features/accessRequest';
import {
  BillingPlanName,
  UserFragmentAccessRequestAdminListFragment,
} from 'graphql/generated';
import { PlotRoutes } from 'Routes';
import { useMembersHandlers } from '../../../../pages/org/settings/members/hooks/useMembersHandlers';
import { useBillingMutations } from '../../hooks';

type BillingNewPricingBasicPlanConfirmationProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  usersToBeDisabled: UserFragmentAccessRequestAdminListFragment[];
  type: 'upgrade' | 'freeTrial';
  onGoBack: VoidFunction;
};

export const BillingNewPricingBasicPlanConfirmation = (
  props: BillingNewPricingBasicPlanConfirmationProps,
) => {
  const { isOpen, onClose, usersToBeDisabled, type, onGoBack } = props;

  const navigate = useGuardNavigate();

  const { disableUsers, userDisableConfirmationDialog } = useMembersHandlers();
  const { createFreeTrialSubscription } = useBillingMutations();

  const handleClose = () => {
    disableUsers(
      usersToBeDisabled.map((u) => u.id),
      async () => {
        if (type === 'upgrade') {
          onClose();
          navigate(
            PlotRoutes.billingCheckout({ planName: BillingPlanName.Basic }),
          );
        } else if (type === 'freeTrial') {
          await createFreeTrialSubscription(BillingPlanName.Basic);
          navigate(0);
        }
      },
    );
  };

  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            p: 8,
            width: '465px',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(6),
          },
        }}
      >
        <Typography variant="headline-lg">
          You are making changes to the organization
        </Typography>

        <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
          🔴 The following users will be removed from the Organization:
        </Typography>

        <AccessRequestAdminList admins={usersToBeDisabled} />

        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(3),
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button onClick={onGoBack}>View Members</Button>

          <Button
            variant="contained"
            sx={{
              bgcolor: theme.colors?.primary.black,
              color: theme.colors?.primary.white,
              borderRadius: theme.spacing(2),
              ':hover': {
                bgcolor: theme.colors?.primary.black,
              },
            }}
            onClick={handleClose}
          >
            {type === 'upgrade' ? 'Continue to Payment' : 'Start a Free Trial'}
          </Button>
        </Box>
      </Dialog>

      {userDisableConfirmationDialog}
    </>
  );
};
