import { useUserContext } from 'contexts/users/User.context';
import { SocialMediaListeningIntroPage } from 'pages/socialMediaListening/intro';
import { Outlet } from 'react-router-dom';

export const SocialMediaListeningAuthLayout = () => {
  const { orgBilling } = useUserContext();

  if (!orgBilling?.socialListeningEnabled) {
    return <SocialMediaListeningIntroPage />;
  }

  return <Outlet />;
};
