import { Box, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import { IconLinearArrowDown1 } from 'components/icons/components/linear/IconLinearArrowDown1';
import { IconLinearArrowUp } from 'components/icons/components/linear/IconLinearArrowUp';
import { theme } from 'styles/theme';

export type SocialMediaListeningMetricsTrackerProps = {
  label: string;
  labelTooltip?: string;
  mainValue?: string;
  up?: boolean;
  difference?: string;
  against?: string;
};

export const SocialMediaListeningMetricsTracker = (
  props: SocialMediaListeningMetricsTrackerProps,
) => {
  const { label, labelTooltip, mainValue, up, difference, against } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
        <Typography variant="headline-sm">{label}</Typography>
        {labelTooltip && (
          <Tooltip title={labelTooltip}>
            <IconBoldInfoCircleAlt
              size={14}
              color={theme.colors?.utility[600]}
            />
          </Tooltip>
        )}
      </Box>
      <Typography
        variant="headline-xl"
        sx={{
          fontSize: 32,
          mb: 1,
        }}
      >
        {mainValue}
      </Typography>
      {difference && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {up ? (
            <IconLinearArrowUp
              size={16}
              color={theme.colors?.utility['green-3-new']}
            />
          ) : (
            <IconLinearArrowDown1
              size={16}
              color={theme.colors?.utility['pink-3']}
            />
          )}
          <Typography
            variant="headline-xs"
            sx={{
              color: up
                ? theme.colors?.utility['green-3-new']
                : theme.colors?.utility['pink-3'],
            }}
          >
            {difference}
          </Typography>
          {against && (
            <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
              {against}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
