import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  CollectionFragmentUpdatePostPermissionAlertViewFragmentDoc,
  useGetCollectionForUsePostPermissionUpdatePostPermissionToCollectionPermissionQuery,
  useShouldUpdatePostPermissionToCollectionPermissionMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { UpdatePostPermissionAlertView } from '../views';

// eslint-disable-next-line
gql`
  mutation ShouldUpdatePostPermissionToCollectionPermission(
    $data: ShouldUpdatePostPermissionToCollectionPermissionInput!
  ) {
    shouldUpdatePostPermissionToCollectionPermission(data: $data) {
      should
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetCollectionForUsePostPermissionUpdatePostPermissionToCollectionPermission(
    $id: String!
  ) {
    collection(id: $id) {
      id
      ...CollectionFragmentUpdatePostPermissionAlertView
    }
  }
  ${CollectionFragmentUpdatePostPermissionAlertViewFragmentDoc}
`;

export const usePostPermissionUpdatePostPermissionToCollectionPermission =
  () => {
    const [collectionId, setCollectionId] = useState<string>();
    const { data: collectionData } =
      useGetCollectionForUsePostPermissionUpdatePostPermissionToCollectionPermissionQuery(
        {
          variables: {
            id: collectionId || '',
          },
          skip: !collectionId,
        },
      );
    const collection = collectionData?.collection;

    const [postIds, setPostIds] = useState<string[]>();
    const [onCallback, setOnCallback] = useState<VoidFunction>();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isPostPermissionLessThanCollectionPermission] =
      useShouldUpdatePostPermissionToCollectionPermissionMutation();

    const handleOpenDialog = async (
      postIds: string[],
      collectionId: string,
      onCallback?: VoidFunction,
    ) => {
      setCollectionId(collectionId);
      setPostIds(postIds);
      setOnCallback(() => onCallback);

      const response = await isPostPermissionLessThanCollectionPermission({
        variables: {
          data: {
            postIds,
            collectionId,
          },
        },
      });

      if (
        response.data?.shouldUpdatePostPermissionToCollectionPermission.should
      ) {
        onOpen();
      }
    };

    const renderUpdatePostPermissionAlert = () => {
      if (!collection || !postIds || postIds.length === 0) {
        return null;
      }

      return !isOpen ? null : (
        <UpdatePostPermissionAlertView
          isOpen={isOpen}
          onClose={onClose}
          postIds={postIds}
          collection={collection}
          onCallback={onCallback}
        />
      );
    };
    return {
      onOpenUpdatePostPermissionDialog: handleOpenDialog,
      renderUpdatePostPermissionAlert,
    };
  };
