import { Box, BoxProps } from '@mui/material';
import { DndDragItemTypes } from 'features/dnd/constants';
import { ReactNode } from 'react';
import { useDrag } from 'react-dnd';

export type DndDragItemProps<T> = BoxProps & {
  type: DndDragItemTypes;
  item: T;
  children?: ReactNode;
  canDrag?: boolean;
};

export const DndDragItem = <T,>(props: DndDragItemProps<T>) => {
  const { type, item, children, canDrag = true, sx, ...rest } = props;

  const [collected, drag] = useDrag(() => ({
    type,
    item,
    canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={drag}
      sx={{
        opacity: collected.isDragging ? 0.5 : 1,
        cursor: collected.isDragging ? 'move' : 'pointer',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
