import { useDisclosure } from '@dwarvesf/react-hooks';
import { useUserContext } from 'contexts/users/User.context';
import {
  PermissionDialogLayout,
  PermissionsDialogViewProps,
} from 'features/permission';
import { usePostPermissionMutations } from 'features/post-permission/hooks';
import {
  GeneralPermission,
  PermissionLevel,
  PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment,
  useGetPostSuggestedAuthForPostPermissionSuggestedDialogQuery,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { useImmer } from 'use-immer';
import {
  PostPermissionSuggestedAuthDialogBodyProps,
  PostPermissionSuggestedDialogBody,
} from './PostPermissionSuggestedAuthDialogBody';

export type Props = {
  postIds: string[];
  collectionIds: string[];
  componentsProps?: {
    dialog?: Pick<PermissionsDialogViewProps, 'componentsProps' | 'customBtn'>;
    dialogBody?: Pick<
      PostPermissionSuggestedAuthDialogBodyProps,
      'sx' | 'shouldRenderCopyLink' | 'componentsProps'
    >;
  };
};

export const usePostPermissionSuggestedAuthDialog = (props: Props) => {
  const { collectionIds, postIds, componentsProps = {} } = props;
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [note, setNote] = useState('');
  const [shouldCreateComment, setShouldCreateComment] = useState(true);

  const updateBtnRef = useRef<HTMLButtonElement>(null);

  const { user } = useUserContext();

  const [postSuggestedAuth, setPostSuggestedAuth] =
    useImmer<PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment>(
      {
        generalPermission:
          user?.personalSettings?.postGeneralPermissionDefault ??
          GeneralPermission.InviteOnly,
        permissionLevel: PermissionLevel.View,
        suggestedInviteMembers: [],
      },
    );

  const { data: suggestedAuthApiData, loading: suggestedAuthApiDataLoading } =
    useGetPostSuggestedAuthForPostPermissionSuggestedDialogQuery({
      variables: {
        collectionIds,
      },
    });

  useEffect(() => {
    // merge suggestedAuthApiData with postSuggestedAuth
    if (
      !suggestedAuthApiDataLoading &&
      suggestedAuthApiData?.getPostSuggestedAuth
    ) {
      const nonExistingUsers =
        suggestedAuthApiData?.getPostSuggestedAuth.suggestedInviteMembers.filter(
          (inviteMember) => {
            return !postSuggestedAuth.suggestedInviteMembers.find(
              (m) => m.user.email === inviteMember.user.email,
            );
          },
        );

      setPostSuggestedAuth((draft) => {
        draft.suggestedInviteMembers.push(...nonExistingUsers);
        draft.generalPermission =
          suggestedAuthApiData.getPostSuggestedAuth.generalPermission;
        draft.permissionLevel =
          suggestedAuthApiData.getPostSuggestedAuth.permissionLevel;
      });
    }
  }, [suggestedAuthApiData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateSuggestedAuth = (
    data: PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment,
    note: string,
    shouldCreateComment: boolean,
  ) => {
    setPostSuggestedAuth((draft) => {
      draft.permissionLevel = data.permissionLevel;
      draft.generalPermission = data.generalPermission;
      draft.suggestedInviteMembers = data.suggestedInviteMembers;
    });

    setNote(note);
    setShouldCreateComment(shouldCreateComment);

    onClose();
  };

  const { updatePostPermissions } = usePostPermissionMutations({
    postIds,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    postSuggestedAuth,
    setPostSuggestedAuth,
    note,
    shouldCreateComment,
    onSubmit: async () => {
      await updatePostPermissions({
        inviteMembers: {
          members: postSuggestedAuth.suggestedInviteMembers.map((m) => ({
            user: m.user,
            permissionLevel: m.permissionLevel,
          })),
        },
        generalPermission: {
          generalPermission: postSuggestedAuth.generalPermission,
          permissionLevel: postSuggestedAuth.permissionLevel,
        },
        shouldCreateComment,
        note,
      });
    },
    renderUI: () => (
      <PermissionDialogLayout
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        hasPendingChanges={hasPendingChanges}
        onPressSaveChanges={() => {
          updateBtnRef?.current?.click();
        }}
        renderDialogBody={() => (
          <PostPermissionSuggestedDialogBody
            postSuggestedAuth={postSuggestedAuth}
            updateSuggestedAuthDataChanged={handleUpdateSuggestedAuth}
            setHasPendingChanges={setHasPendingChanges}
            updateBtnRef={updateBtnRef}
            {...componentsProps.dialogBody}
          />
        )}
        {...componentsProps.dialog}
      />
    ),
    showInvitationUI: onOpen,
  };
};
