import { gql, useApolloClient } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconCustomPinOutline } from 'components/icons/components/custom/IconCustomPinOutline';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconLinearRotateLeft } from 'components/icons/components/linear/IconLinearRotateLeft';
import { IconOutlineArrowSwapHorizontal } from 'components/icons/components/outline/IconOutlineArrowSwapHorizontal';
import { IconOutlineEdit2 } from 'components/icons/components/outline/IconOutlineEdit2';
import { IconOutlineInfoCircle } from 'components/icons/components/outline/IconOutlineInfoCircle';
import { IconOutlineShare } from 'components/icons/components/outline/IconOutlineShare';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import { useCheckIfCustomCollection } from 'features/collection/hooks';
import {
  CollectionFragmentCollectionContextMenuFragment,
  CollectionFragmentCollectionPinFragmentDoc,
  CollectionPermission,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';
import { Parser } from 'utils/parser';
import { useCollectionHandlers } from './useCollectionHandlers';

export const COLLECTION_FRAGMENT_COLLECTION_CONTEXT_MENU = gql`
  fragment CollectionFragmentCollectionContextMenu on CollectionModel {
    id
    creatorId
    parentCollectionId
    myPermissions
    hasPreviewPost
    ...CollectionFragmentCollectionPin
    previewPost {
      id
    }
  }
  ${CollectionFragmentCollectionPinFragmentDoc}
`;

type CollectionContextMenuProps = Omit<
  ContextMenuProps,
  'options' | 'renderButton'
> &
  Partial<Pick<ContextMenuProps, 'options' | 'renderButton'>> & {
    hideOptions?: (
      | 'delete'
      | 'move'
      | 'rename'
      | 'updatePostCover'
      | 'pin'
      | 'share'
    )[];
    onRenameCollection: VoidFunction;
    collection: CollectionFragmentCollectionContextMenuFragment;
    onCollectionDeleted?: VoidFunction;
    onMoveCollection?: VoidFunction;
    onPinCollectionToParent?: (collectionId: string) => void;
    onUnpinCollectionFromParent?: (collectionId: string) => void;
    onUnpinCollectionFromRoot?: (collectionId: string) => void;
    onPinCollectionToRoot?: (collectionId: string) => void;
    onShareCollection?: (collectionId: string) => void;
  };

export const CollectionContextMenu = (props: CollectionContextMenuProps) => {
  const {
    collection,
    onRenameCollection,
    onCollectionDeleted,
    onMoveCollection,
    onPinCollectionToParent,
    onUnpinCollectionFromParent,
    onUnpinCollectionFromRoot,
    onPinCollectionToRoot,
    onShareCollection,
    hideOptions = [],
    ...rest
  } = props;

  const navigate = useGuardNavigate();
  const client = useApolloClient();
  const location = useLocation();
  const currentCollectionId =
    Parser.getCollectionIdFromUrl(location.pathname) || '';
  const isCustomCollection = useCheckIfCustomCollection(currentCollectionId);

  const canUpdate = collection.myPermissions.includes(
    CollectionPermission.Update,
  );
  const canDelete = collection.myPermissions.includes(
    CollectionPermission.Delete,
  );

  const { onDeleteCollection, onCollectionPreviewUpdate } =
    useCollectionHandlers();

  const {
    dialog: deleteCollectionDialog,
    onOpen: openDeleteCollectionConfirmationDialog,
  } = useConfirmationDialog();

  const menuOptions = [
    ...(onMoveCollection && !hideOptions.includes('move')
      ? [
          {
            title: `Move Collection`,
            icon: IconOutlineArrowSwapHorizontal,
            color: theme.colors?.primary.black,
            onClick: () => {
              onMoveCollection();
            },
            disabled: !canUpdate,
          },
          {
            isDivider: true,
          },
        ]
      : []),
    ...(hideOptions.includes('pin')
      ? []
      : [
          ...(!collection.parentCollectionId
            ? []
            : collection.isPinnedToParent
            ? [
                {
                  title: `Unpin from this Collection`,
                  icon: IconCustomPinOutline,
                  color: theme.colors?.primary.black,
                  onClick: () => onUnpinCollectionFromParent?.(collection.id),
                },
              ]
            : [
                {
                  title: `Pin to this Collection`,
                  icon: IconCustomPinOutline,
                  color: theme.colors?.primary.black,
                  onClick: () => onPinCollectionToParent?.(collection.id),
                },
              ]),
          collection.isPinnedToRoot
            ? {
                title: `Unpin from Creative Juicebox`,
                icon: IconCustomPinOutline,
                color: theme.colors?.primary.black,
                onClick: () => onUnpinCollectionFromRoot?.(collection.id),
              }
            : {
                title: `Pin to Creative Juicebox`,
                icon: IconCustomPinOutline,
                color: theme.colors?.primary.black,
                onClick: () => onPinCollectionToRoot?.(collection.id),
              },
        ]),
    ...(hideOptions.includes('rename') ||
    hideOptions.includes('updatePostCover')
      ? []
      : [
          {
            isDivider: true,
          },
        ]),
    ...(hideOptions.includes('rename')
      ? []
      : [
          {
            title: `Rename Collection`,
            icon: IconOutlineEdit2,
            color: theme.colors?.primary.black,
            onClick: onRenameCollection,
            disabled: !canUpdate,
          },
        ]),
    ...(hideOptions.includes('share') || !onShareCollection
      ? []
      : [
          {
            title: `Share Collection`,
            icon: IconOutlineShare,
            color: theme.colors?.primary.black,
            onClick: () => {
              onShareCollection(collection.id);
            },
            disabled: !canUpdate,
          },
        ]),
    ...(collection.hasPreviewPost &&
    collection.previewPost &&
    hideOptions.includes('updatePostCover')
      ? []
      : [
          {
            title: `Restore Default Cover`,
            icon: IconLinearRotateLeft,
            color: theme.colors?.primary.black,
            onClick: () => {
              onCollectionPreviewUpdate(collection.id);
            },
            disabled: !canUpdate,
          },
        ]),
    ...(hideOptions.includes('delete')
      ? []
      : [
          {
            isDivider: true,
          },
          {
            title: `Delete collection`,
            icon: IconOutlineTrash,
            color: theme.colors?.primary.maroon,
            onClick: () =>
              openDeleteCollectionConfirmationDialog({
                subtitle: `Once it removed, this collection will no longer be accessible.`,
                onConfirm: async () => {
                  await onDeleteCollection(
                    collection.id,
                    collection?.parentCollectionId,
                  );

                  onCollectionDeleted?.();
                  evictObject(client.cache, collection.id, 'CollectionModel');
                  if (!isCustomCollection) {
                    navigate(
                      collection?.parentCollectionId
                        ? PlotRoutes.collection(collection?.parentCollectionId)
                        : PlotRoutes.juicebox(),
                    );
                  }
                },
              }),
            disabled: !canDelete,
            closeOnClick: false,
          },
        ]),
  ];

  return (
    <>
      <ContextMenu
        renderButton={() => (
          <IconButton>
            <IconLinearMore
              style={{
                color: theme.colors?.primary.black,
                transform: `rotate(90deg)`,
              }}
            />
          </IconButton>
        )}
        options={menuOptions.map((option) => {
          const Icon = option.icon;
          return {
            isDivider: option.isDivider,
            renderOption: () => (
              <Box display="flex" gap={2} alignItems="center">
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {Icon && <Icon size={16} color={option.color} />}
                </Box>
                <Typography variant="subhead-lg" color={option.color}>
                  {option.title}
                </Typography>
              </Box>
            ),
            onClick: option.onClick,
            disabled: option.disabled,
            closeOnClick: option.closeOnClick,
          };
        })}
        MenuListProps={{
          sx: {
            maxWidth: 266,
            gap: `${theme.spacing(2)} !important`,
          },
        }}
        renderExtraBottom={
          !canUpdate || !canDelete
            ? () => {
                return (
                  <Box
                    sx={{
                      mt: 3,
                      py: 4,
                      mx: 3,
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 3,
                      borderTop: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <IconOutlineInfoCircle
                      size={16}
                      style={{
                        color: theme.colors?.primary.maroon,
                        flexShrink: 0,
                      }}
                    />
                    <Typography variant="subhead-lg">
                      {!canDelete && canUpdate
                        ? `Default collections in a content idea cannot be deleted.`
                        : `Only the creator of this collection can rename and delete it.`}
                    </Typography>
                  </Box>
                );
              }
            : undefined
        }
        disableRestoreFocus
        sx={{
          '& .MuiMenu-paper': {
            backdropFilter: 'blur(20px)',
            backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
          },
          ...(rest.sx || {}),
        }}
        {...rest}
      />
      {deleteCollectionDialog}
    </>
  );
};
