import { Table, TableHead, TableRow } from '@mui/material';
import { theme } from 'styles/theme';
import { SocialMediaListeningListItem } from './socialMediaListeningListItem/SocialMediaListeningListItem';
import { StyledTableHeadingCell } from './styles';
import { SocialMediaListeningPostsListViewProps } from './types';

export const SocialMediaListeningPostsListView = ({
  socialPosts,
  searchCriteria,
}: SocialMediaListeningPostsListViewProps) => {
  // create MUI table here
  return (
    <Table>
      {/* Table headers */}
      <TableHead>
        <TableRow>
          <StyledTableHeadingCell sx={{ width: theme.spacing(10) }}>
            Preview
          </StyledTableHeadingCell>
          <StyledTableHeadingCell
            sx={{
              minWidth: theme.spacing(60),
              maxWidth: theme.spacing(60),
              width: theme.spacing(60),
            }}
          >
            Key takeaway about brand
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(32) }}>
            Status
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            Sentiment
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            Follower count
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            Confidence score
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            Post Engagement
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            Author Engagement
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            Views
          </StyledTableHeadingCell>
          <StyledTableHeadingCell
            sx={{ width: theme.spacing(50), minWidth: theme.spacing(50) }}
          >
            Topics
          </StyledTableHeadingCell>
          <StyledTableHeadingCell
            sx={{ width: theme.spacing(24), minWidth: theme.spacing(24) }}
          >
            Go to link
          </StyledTableHeadingCell>
        </TableRow>
      </TableHead>

      {/* Table rows */}
      {socialPosts.map((post, index) => (
        <SocialMediaListeningListItem
          post={post}
          searchCriteria={searchCriteria}
          itemPosition={(index + 1).toString()}
        />
      ))}
    </Table>
  );
};
