import { Box, Typography } from '@mui/material';
import { IconLinearArrowRight2 } from 'components/icons/components/linear/IconLinearArrowRight2';
import { getTrendingStatusText } from 'features/trending/constants';
import { theme } from 'styles/theme';
import { formatBigNumber, formatNumber } from 'utils/number';
import { TrendInfoProps } from './types';

export const TrendInfo = ({ trendInfo }: TrendInfoProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {trendInfo.title && (
        <Typography
          variant="headline-xl"
          fontWeight={400}
          fontSize={28}
          letterSpacing="-1.12px"
        >
          {trendInfo.title}
        </Typography>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        {trendInfo.status && (
          <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
            {getTrendingStatusText(trendInfo.status)}
          </Typography>
        )}
        {trendInfo.trendAudio && (
          <>
            {!!trendInfo.trendAudio.totalVideoCount &&
              trendInfo.trendAudio.totalVideoCount > 0 && (
                <Typography
                  variant="headline-sm"
                  color={theme.colors?.primary.black}
                >
                  {formatBigNumber(trendInfo.trendAudio.totalVideoCount)} videos
                </Typography>
              )}

            {!!trendInfo.trendAudio?.changePercentage &&
              trendInfo.trendAudio?.changePercentage > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <IconLinearArrowRight2
                      color={theme.colors?.utility['green-3-new']}
                    />
                  </Box>
                  <Typography
                    variant="headline-sm"
                    color={theme.colors?.utility['green-3-new']}
                  >
                    {formatNumber(trendInfo.trendAudio?.changePercentage, 2)}%
                  </Typography>
                </Box>
              )}
          </>
        )}
      </Box>

      {trendInfo.description && (
        <Box
          sx={{
            '& p': {
              margin: 0,
            },
            '& span': {
              fontFamily: `${theme.typography['body-xl'].fontFamily} !important`,
            },
          }}
        >
          <Typography
            variant="body-xl"
            fontWeight={500}
            color={theme.colors?.utility[800]}
            dangerouslySetInnerHTML={{
              __html: trendInfo.description,
            }}
          />
        </Box>
      )}
    </Box>
  );
};
