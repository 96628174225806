import { Slider, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledSlider = styled(Slider)({
  color: theme.colors?.utility[400],
  height: 6,
  width: '100%',
  '& .MuiSlider-mark[data-index="0"], & .MuiSlider-mark[data-index="5"]': {
    display: 'none',
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.colors?.utility[600],
    height: theme.spacing(1),
    borderRadius: 0,
  },

  '& .MuiSlider-markLabel': {
    opacity: 0,
    color: theme.colors?.utility[700],
    ...theme.typography['subhead-lg'],
    pointerEvents: 'none',
    cursor: 'auto',
  },

  '& .MuiSlider-markLabel[data-index="0"], & .MuiSlider-markLabel[data-index="5"]':
    {
      opacity: 1,
      pointerEvents: 'auto',
      cursor: 'pointer',
    },

  '& .MuiSlider-rail': {
    opacity: 1,
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: theme.colors?.utility['orange-4'],
    border: `1px solid ${theme.colors?.primary.parchment}`,
    // boxShadow: '0px 0px 0px 8px rgba(147, 110, 84,0.2)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgba(147, 110, 84,0.2)',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.colors?.primary.black,
    '&::before': { display: 'none' },
  },
});
