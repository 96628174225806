import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { CollectionFragmentCollectionBreadcrumbsFragment } from 'graphql/generated';
import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentCollectionBreadcrumbs on CollectionModel {
    id
    breadcrumbsFromRoot {
      id
      name
    }
  }
`;

export type CollectionBreadcrumbsProps = {
  collection: CollectionFragmentCollectionBreadcrumbsFragment;
  delimiter?: ReactNode;
  // only ellipse when breadcrumbs length are longer than this value
  ellipseAtLength?: number;
  ellipse?: boolean;
  hideCurrentCollection?: boolean;
  sx?: SxProps;
  componentProps?: {
    text?: {
      sx?: SxProps;
      lastLocationSx?: SxProps;
    };
    breadcrumbContainer?: {
      sx?: SxProps;
    };
    breadcrumbText?: {
      sx?: SxProps;
    };
  };
  onCollectionClick?: (collectionId: string) => void;
  wrapBreadcrumbs?: boolean;
};

const CollectionClickHandler = ({
  collectionId,
  children,
  onClick,
  sx,
  onSetWrapperWidth,
}: {
  collectionId: string;
  children: ReactNode;
  onClick?: (collectionId: string) => void;
  sx?: SxProps;
  onSetWrapperWidth: (width: number) => void;
}) => {
  const ref = useRef<HTMLAnchorElement>(null);

  const location = useLocation();

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.getBoundingClientRect().width;
      onSetWrapperWidth(width);
    }
  }, [collectionId, onSetWrapperWidth]);

  if (onClick) {
    return (
      <Box
        onClick={() => onClick(collectionId)}
        sx={{ cursor: 'pointer', display: 'inline', ...(sx || {}) }}
        ref={ref}
      >
        {children}
      </Box>
    );
  }

  return (
    <Link
      key={collectionId}
      to={PlotRoutes.collection(collectionId)}
      state={location.state}
      ref={ref}
    >
      {children}
    </Link>
  );
};

export const CollectionBreadcrumbs = (props: CollectionBreadcrumbsProps) => {
  const {
    collection,
    delimiter = (
      <IconLinearArrowRight size={14} color={theme.colors?.utility[700]} />
    ),
    ellipseAtLength,
    ellipse,
    hideCurrentCollection,
    sx,
    componentProps,
    onCollectionClick,
    wrapBreadcrumbs = true,
  } = props;
  const containerRef = useRef<HTMLElement>(null);

  const breadCrumbs = useMemo(() => {
    if (hideCurrentCollection && collection.breadcrumbsFromRoot.length > 0) {
      return [...collection.breadcrumbsFromRoot].splice(
        0,
        collection.breadcrumbsFromRoot.length - 1,
      );
    }

    return [...collection.breadcrumbsFromRoot];
  }, [collection.breadcrumbsFromRoot, hideCurrentCollection]);

  const [renderedWidth, setRenderedWidth] = useState(-1);
  const [wrapperWidth, setWrapperWidth] = useState(-1);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.getBoundingClientRect().width;
      setRenderedWidth(width);
    }
  }, [collection.breadcrumbsFromRoot, ellipseAtLength]);

  const getTypographyBaseStyles = useCallback(() => {
    if (renderedWidth > 0) {
      if (breadCrumbs.length === 1) {
        return {
          maxWidth: renderedWidth,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        } as SxProps;
      }
      return {
        ...(ellipseAtLength &&
          ellipseAtLength > breadCrumbs.length && {
            maxWidth: `calc(${renderedWidth / 2}px - ${theme.spacing(
              ellipseAtLength * 1,
            )})`,
          }),
        ...(ellipseAtLength &&
          ellipseAtLength <= breadCrumbs.length && {
            maxWidth: `calc(${
              renderedWidth / ellipseAtLength
            }px - ${theme.spacing(1.5 * ellipseAtLength)})`,
          }),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      } as SxProps;
    }
  }, [breadCrumbs, ellipseAtLength, renderedWidth]);

  return breadCrumbs.length === 0 ? null : (
    // Show tooltip only when the wrapper width is greater than the viewable width (i.e., when the label is ellipsized)
    <Tooltip
      title={
        wrapperWidth > renderedWidth
          ? collection.breadcrumbsFromRoot
              .map((b) => b.name)
              .reduce((a, b) => `${a}/${b}`)
          : ''
      }
    >
      <Box
        key={
          collection.breadcrumbsFromRoot.map((b) => b.id).join('-') +
          collection.id
        }
        className="bcc"
        sx={{
          display: 'flex',
          flexWrap: wrapBreadcrumbs ? 'wrap' : 'nowrap',
          overflow: wrapBreadcrumbs ? 'visible' : 'hidden',
          textOverflow: wrapBreadcrumbs ? 'clip' : 'ellipsis',
          gap: theme.spacing(0, 2),
          alignItems: 'center',
          color: theme.colors?.utility[700],
          width: '100%',
          ...sx,
        }}
        ref={containerRef}
      >
        {/* no parent collection */}
        {breadCrumbs.length === 1 ? (
          <CollectionClickHandler
            collectionId={breadCrumbs[0].id}
            onClick={onCollectionClick}
            onSetWrapperWidth={(width) => setWrapperWidth(width)}
          >
            <Typography
              sx={[
                {
                  ...getTypographyBaseStyles(),
                },
                {
                  ...theme.typography['headline-xs'],
                  color: theme.colors?.primary.black,
                },
                ...(componentProps &&
                componentProps.text &&
                Array.isArray(componentProps.text.sx)
                  ? componentProps.text.sx
                  : [componentProps?.text?.sx]),
                ...(componentProps &&
                componentProps.text &&
                Array.isArray(componentProps.text.lastLocationSx)
                  ? componentProps.text.lastLocationSx
                  : [componentProps?.text?.lastLocationSx]),
              ]}
            >
              {breadCrumbs[0].name}
            </Typography>
          </CollectionClickHandler>
        ) : ellipse ||
          (ellipseAtLength && ellipseAtLength < breadCrumbs.length) ? (
          <CollectionClickHandler
            collectionId={collection.id}
            onClick={onCollectionClick}
            onSetWrapperWidth={(width) => setWrapperWidth(width)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0, 1),
              }}
            >
              <Typography
                variant="headline-xs"
                color={theme.colors?.utility[700]}
                sx={
                  {
                    ...(getTypographyBaseStyles() || {}),
                    ...(componentProps?.text?.sx || {}),
                  } as SxProps
                }
              >
                {breadCrumbs[0].name}
              </Typography>

              {breadCrumbs.length > 2 && (
                <>
                  {delimiter}
                  <Typography
                    sx={{
                      ...theme.typography['headline-xs'],
                      color: theme.colors?.utility[700],
                      ...componentProps?.text?.sx,
                    }}
                  >
                    ...
                  </Typography>
                </>
              )}

              {delimiter}
              <Typography
                sx={[
                  {
                    ...getTypographyBaseStyles(),
                    ...theme.typography['headline-xs'],
                    color: theme.colors?.primary.black,
                  },
                  ...(componentProps &&
                  componentProps.text &&
                  Array.isArray(componentProps.text.sx)
                    ? componentProps.text.sx
                    : [componentProps?.text?.sx]),
                  ...(componentProps &&
                  componentProps.text &&
                  Array.isArray(componentProps.text.lastLocationSx)
                    ? componentProps.text.lastLocationSx
                    : [componentProps?.text?.lastLocationSx]),
                ]}
              >
                {breadCrumbs[breadCrumbs.length - 1].name}
              </Typography>
            </Box>
          </CollectionClickHandler>
        ) : (
          breadCrumbs.map((breadcrumb, index) => (
            <CollectionClickHandler
              key={index}
              collectionId={breadcrumb.id}
              onClick={onCollectionClick}
              sx={componentProps?.breadcrumbContainer?.sx}
              onSetWrapperWidth={(width) => setWrapperWidth(width)}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: theme.spacing(1),
                  ...(componentProps?.breadcrumbContainer?.sx || {}),
                }}
              >
                <Typography
                  sx={[
                    {
                      ...getTypographyBaseStyles(),
                      ...theme.typography['headline-xs'],
                      color: theme.colors?.utility[700],
                    },
                    componentProps?.breadcrumbText?.sx || {},
                    ...(componentProps &&
                    componentProps.text &&
                    Array.isArray(componentProps.text.sx)
                      ? componentProps.text.sx
                      : [componentProps?.text?.sx]),
                    ...(index === breadCrumbs.length - 1
                      ? componentProps &&
                        componentProps.text &&
                        Array.isArray(componentProps.text.lastLocationSx)
                        ? componentProps.text.lastLocationSx
                        : [componentProps?.text?.lastLocationSx]
                      : []),
                  ]}
                >
                  <Box component="span">{breadcrumb.name}</Box>
                </Typography>

                {index < breadCrumbs.length - 1 && delimiter}
              </Box>
            </CollectionClickHandler>
          ))
        )}
      </Box>
    </Tooltip>
  );
};
