import { gql } from '@apollo/client';
import {
  SocialListeningPostsSortBy,
  SocialPostFragmentSocialMediaListeningListItemFragmentDoc,
  SocialPostFragmentSocialMediaListeningPostCardFragmentDoc,
  SortOrder,
  useGetSocialPostsQuery,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSocialPosts(
    $filters: GetPaginatedSocialPostFilterInput
    $after: String
    $before: String
    $sortBy: SocialListeningPostsSortBy
    $sortOrder: SortOrder
    $take: Int
    $topicIds: [String!]
    $brandId: String!
  ) {
    getPaginatedSocialPosts(
      filters: $filters
      after: $after
      before: $before
      sortBy: $sortBy
      sortOrder: $sortOrder
      take: $take
    ) {
      data {
        id
        __typename
        ...SocialPostFragmentSocialMediaListeningListItem
        ...SocialPostFragmentSocialMediaListeningPostCard
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      meta {
        totalCount
      }
    }
  }
  ${SocialPostFragmentSocialMediaListeningListItemFragmentDoc}
  ${SocialPostFragmentSocialMediaListeningPostCardFragmentDoc}
`;

export type UseSocialPostsListProps = {
  brandId: string;
  sortBy: SocialListeningPostsSortBy;
  sortOrder: SortOrder;
  filters: { [key: string]: string[] | number };
};

export const useSocialPostsList = ({
  brandId,
  sortBy,
  sortOrder,
  filters,
}: UseSocialPostsListProps) => {
  const {
    data: socialPostsData,
    loading,
    fetchMore,
    refetch,
  } = useGetSocialPostsQuery({
    variables: {
      sortBy,
      sortOrder,
      take: 20,
      filters: {
        ...filters,
      },
      topicIds: (filters?.topicIds as string[]) ?? [],
      brandId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const hasNextPage =
    socialPostsData?.getPaginatedSocialPosts.pageInfo.hasNextPage;

  const fetchMorePostSuggestions = () => {
    if (loading || !hasNextPage) return;

    fetchMore({
      variables: {
        sortBy,
        sortOrder,
        take: 20,
        after: socialPostsData?.getPaginatedSocialPosts.pageInfo.endCursor,
        filters,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          getPaginatedSocialPosts: {
            ...prev.getPaginatedSocialPosts,
            data: [
              ...prev.getPaginatedSocialPosts.data,
              ...fetchMoreResult.getPaginatedSocialPosts.data,
            ],
            meta: fetchMoreResult.getPaginatedSocialPosts.meta,
            pageInfo: fetchMoreResult.getPaginatedSocialPosts.pageInfo,
          },
        };
      },
    });
  };

  return { socialPostsData, loading, refetch, fetchMorePostSuggestions };
};
