/**
 * EXPERIMENTAL. Most likely will need refactoring.
 */

import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  ContentIdeaFieldOptionFragmentMultiSelectFieldFragment,
  ContentIdeaFieldOptionFragmentSelectFieldFragment,
  FieldType,
  UpdateContentIdeaFieldValue,
  UpdateContentIdeaFieldValueData,
  useUpdateContentIdeaFieldValueForContentIdeaFieldMutation,
} from 'graphql/generated';
import moment from 'moment';
import { CheckboxField, CheckboxFieldProps } from './CheckboxField';
import { CollectionField, CollectionFieldProps } from './CollectionField';
import { DateField, DateFieldProps } from './DateField';
import { MultiSelectField, MultiSelectFieldProps } from './MultiSelectField';
import { SelectField, SelectFieldProps } from './SelectField';
import { TextField, TextFieldProps } from './TextField';
import { UsersField, UsersFieldProps } from './UsersField';
import { TasksField } from './tasks';
import {
  CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_CHECKBOX,
  CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_COLLECTION,
  CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_DATE,
  CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_MULTI_SELECT,
  CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_SELECT,
  CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_TASKS,
  CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_TEXT,
  CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_USERS,
} from './types';

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaField on ContentIdeaFieldValueModel {
    id
    ...ContentIdeaFieldValueFragmentContentIdeaFieldText
    ...ContentIdeaFieldValueFragmentContentIdeaFieldSelect
    ...ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelect
    ...ContentIdeaFieldValueFragmentContentIdeaFieldDate
    ...ContentIdeaFieldValueFragmentContentIdeaFieldCheckbox
    ...ContentIdeaFieldValueFragmentContentIdeaFieldTasks
    ...ContentIdeaFieldValueFragmentContentIdeaFieldUsers
    ...ContentIdeaFieldValueFragmentContentIdeaFieldCollection
  }
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_TEXT}
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_SELECT}
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_MULTI_SELECT}
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_DATE}
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_CHECKBOX}
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_TASKS}
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_USERS}
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_COLLECTION}
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaFieldValueForContentIdeaField(
    $data: UpdateContentIdeaFieldValueInput!
  ) {
    updateContentIdeaFieldValue(data: $data) {
      id
      ...ContentIdeaFieldValueFragmentContentIdeaField
    }
  }
  ${CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD}
`;

export type ContentIdeaFieldProps = {
  id: string;
  value: UpdateContentIdeaFieldValue;
  valueId: string;
  onAfterChange?: (selectedOption: null | string | boolean | string[]) => void;
  onUpdateFieldValue?: (value: UpdateContentIdeaFieldValueData) => void;
  readOnly?: boolean;
} & (
  | ({
      type: FieldType.Select;
    } & Omit<SelectFieldProps, 'value' | 'onChange'>)
  | ({
      type: FieldType.MultiSelect;
    } & Omit<MultiSelectFieldProps, 'value' | 'onChange'>)
  | ({
      type: FieldType.Date;
    } & Omit<DateFieldProps, 'value' | 'onChange'>)
  | ({
      type: FieldType.Text;
    } & Omit<TextFieldProps, 'value' | 'onChange'>)
  | ({
      type: FieldType.Checkbox;
    } & Omit<CheckboxFieldProps, 'checked' | 'onChange'>)
  | {
      type: FieldType.Tasks;
    }
  | ({
      type: FieldType.Users;
    } & Omit<UsersFieldProps, 'value' | 'onChange'>)
  | ({
      type: FieldType.Collection;
    } & Omit<CollectionFieldProps, 'collectionId'>)
);

export const ContentIdeaField = (props: ContentIdeaFieldProps) => {
  const { value, valueId, type, onAfterChange, onUpdateFieldValue } = props;

  const [updateContentIdeaFieldValue] =
    useUpdateContentIdeaFieldValueForContentIdeaFieldMutation();

  const onChange = async (
    selectedOption: null | string | boolean | string[],
    optimisticResponseOptions?: {
      select?: ContentIdeaFieldOptionFragmentSelectFieldFragment;
      multiSelect?: ContentIdeaFieldOptionFragmentMultiSelectFieldFragment[];
    },
  ) => {
    const data: UpdateContentIdeaFieldValueData = {
      value: {},
    };
    let optimisticResponseValue = {};

    switch (type) {
      case FieldType.Text: {
        data.value = {
          text: selectedOption as string,
        };
        optimisticResponseValue = {
          text: selectedOption as string,
          select: null,
          multiSelect: null,
          date: null,
          checkbox: null,
          tasks: null,
          users: null,
          collection: null,
        };

        break;
      }
      case FieldType.Select: {
        data.value = {
          select: selectedOption as string,
        };
        optimisticResponseValue = {
          text: null,
          select: optimisticResponseOptions?.select || null,
          multiSelect: null,
          date: null,
          checkbox: null,
          tasks: null,
          users: null,
          collection: null,
        };

        break;
      }
      case FieldType.MultiSelect: {
        data.value = {
          multiSelect: selectedOption as string[],
        };
        optimisticResponseValue = {
          text: null,
          select: null,
          multiSelect: optimisticResponseOptions?.multiSelect || null,
          date: null,
          checkbox: null,
          tasks: null,
          users: null,
          collection: null,
        };

        break;
      }
      case FieldType.Date: {
        data.value = {
          date: selectedOption as string,
        };
        optimisticResponseValue = {
          text: null,
          select: null,
          multiSelect: null,
          date: (selectedOption as string) || null,
          checkbox: null,
          tasks: null,
          users: null,
          collection: null,
        };

        break;
      }
      case FieldType.Checkbox: {
        data.value = {
          checkbox: selectedOption as boolean,
        };
        optimisticResponseValue = {
          text: null,
          select: null,
          multiSelect: null,
          date: null,
          checkbox: (selectedOption as boolean) || null,
          tasks: null,
          users: null,
          collection: null,
        };

        break;
      }
      case FieldType.Tasks: {
        data.value = {
          taskIds: selectedOption as string[],
        };
        optimisticResponseValue = {
          text: null,
          select: null,
          multiSelect: null,
          date: null,
          checkbox: null,
          tasks: (selectedOption as string[]).map((t) => ({ id: t })) || null,
          users: null,
          collection: null,
        };

        break;
      }
      case FieldType.Users: {
        data.value = {
          userIds: selectedOption as string[],
        };
        optimisticResponseValue = {
          text: null,
          select: null,
          multiSelect: null,
          date: null,
          checkbox: null,
          tasks: null,
          users: (selectedOption as string[]).map((t) => ({ id: t })) || null,
          collection: null,
        };

        break;
      }
      case FieldType.Collection: {
        data.value = {
          collectionId: selectedOption as string,
        };
        optimisticResponseValue = {
          text: null,
          select: null,
          multiSelect: null,
          date: null,
          checkbox: null,
          tasks: null,
          users: null,
          collection: selectedOption
            ? {
                id: selectedOption as string,
              }
            : null,
        };

        break;
      }
      default: {
        return;
      }
    }

    // if onUpdateFieldValue is provided, call it instead of the mutation
    if (onUpdateFieldValue) {
      onUpdateFieldValue(data);
    } else {
      await updateContentIdeaFieldValue({
        variables: {
          data: {
            contentIdeaFieldValueId: valueId,
            data: {
              ...data,
              timeZone: moment.tz.guess(),
            },
          },
        },
        optimisticResponse: {
          updateContentIdeaFieldValue: {
            id: valueId,
            value: {
              ...optimisticResponseValue,
            },
            __typename: 'ContentIdeaFieldValueModel',
          },
        },
      });
    }

    onAfterChange?.(selectedOption);
  };

  switch (type) {
    case FieldType.Text: {
      return (
        <TextField {...props} value={value.text || ''} onChange={onChange} />
      );
    }
    case FieldType.Select: {
      return (
        <SelectField
          {...props}
          value={value.select || ''}
          onChange={onChange}
        />
      );
    }
    case FieldType.MultiSelect: {
      return (
        <MultiSelectField
          {...props}
          value={value.multiSelect || []}
          onChange={onChange}
        />
      );
    }
    case FieldType.Date: {
      return (
        <DateField
          {...props}
          value={value.date ? moment(value.date) : null}
          onChange={(date) => onChange(date ? date.toISOString() : null)}
        />
      );
    }
    case FieldType.Checkbox: {
      return (
        <CheckboxField
          {...props}
          checked={value.checkbox || false}
          onChange={onChange}
        />
      );
    }
    case FieldType.Tasks: {
      return (
        <TasksField
          {...props}
          onChange={onChange}
          value={value.taskIds || []}
        />
      );
    }
    case FieldType.Users: {
      return (
        <UsersField
          {...props}
          value={value.userIds || []}
          onChange={onChange}
        />
      );
    }
    case FieldType.Collection: {
      return (
        <CollectionField {...props} collectionId={value.collectionId || ''} />
      );
    }
    default: {
      return <Box>No implementation</Box>;
    }
  }
};
