import { gql } from '@apollo/client';
import {
  PostFragmentPostPreviewFragmentDoc,
  SocialPostFragmentUseCreatePostHandlerForSocialListeningFragment,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc,
  useAddToContentCalendarForSocialListeningPostMutation,
  useCreatePostForSocialListeningPostMutation,
} from 'graphql/generated';
import React from 'react';
import { useSocialListeningPostLinkGenerator } from './useSocialListeningPostLinkGenerator';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentUseCreatePostHandlerForSocialListening on SocialPostModel {
    id
    ...SocialPostFragmentUseSocialListeningPostLinkGenerator
  }
  ${SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreatePostForSocialListeningPost(
    $data: CreatePostForSocialListeningPostInput!
  ) {
    createPostForSocialListeningPost(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation AddToContentCalendarForSocialListeningPost(
    $data: AddToContentCalendarForSocialListeningPostInput!
  ) {
    addToContentCalendarForSocialListeningPost(data: $data) {
      id
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

type Props = {
  socialListeningPost: SocialPostFragmentUseCreatePostHandlerForSocialListeningFragment;
};

export const useCreatePostHandlerForSocialListening = ({
  socialListeningPost,
}: Props) => {
  const [createPostForSocialListeningPost] =
    useCreatePostForSocialListeningPostMutation();
  const [addToContentCalendarForSocialListeningPost] =
    useAddToContentCalendarForSocialListeningPostMutation();

  const { generateLink } = useSocialListeningPostLinkGenerator();

  const createPostHandler = async () => {
    const link = generateLink(socialListeningPost);
    if (!link) {
      throw new Error('Link is not generated');
    }
    return createPostForSocialListeningPost({
      variables: {
        data: {
          socialMediaUrl: link,
          socialPostId: socialListeningPost.id,
        },
      },
    });
  };

  const addToContentCalendarHandler = async () => {
    const link = generateLink(socialListeningPost);
    if (!link) {
      throw new Error('Link is not generated');
    }
    return addToContentCalendarForSocialListeningPost({
      variables: {
        data: {
          socialMediaUrl: link,
          socialPostId: socialListeningPost.id,
        },
      },
    });
  };

  return {
    createPost: createPostHandler,
    addToContentCalendar: addToContentCalendarHandler,
  };
};
