/**
 * Returns the character for meta or control key based on the OS
 */
export const getMetaKey = () => {
  if (window.navigator.userAgent.indexOf('Mac OS') !== -1) {
    return '⌘';
  }

  return '^';
};
