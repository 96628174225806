import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  IconButton,
  Input,
  InputProps,
  Popover,
  SxProps,
  Typography,
} from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import {
  PostFragmentPostTitleFragment,
  PostFragmentUrlMetadataFragmentDoc,
  PostPermission,
  useUpdatePostTitleMutation,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

export const POST_FRAGMENT_POST_TITLE = gql`
  fragment PostFragmentPostTitle on PostModel {
    id
    title
    myPermissions
    creator {
      id
      firstName
    }
    urlMetadata {
      id
      url
    }
  }
  ${PostFragmentUrlMetadataFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UpdatePostTitle($data: UpdatePostInput!) {
    updatePost(data: $data) {
      id
      title
    }
  }
`;

export type PostTitleProps = {
  post: PostFragmentPostTitleFragment;
  sx?: SxProps;
  inputProps?: Omit<InputProps, 'value' | 'onChange' | 'onBlur'>;
  variant?: 'default' | 'popover';
  onToggleEditingView?: (postId: string) => void;
};

export const PostTitle = (props: PostTitleProps) => {
  const {
    post,
    sx = {},
    inputProps = {},
    variant = 'default',
    onToggleEditingView,
  } = props;

  const canUpdate = post.myPermissions.includes(PostPermission.Update);

  const [updateTitle] = useUpdatePostTitleMutation();

  const editingState = useDisclosure();

  const [existingTitle, setExistingTitle] = useState('');
  const [title, setTitle] = useState(existingTitle);
  const anchorRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const title =
      post.title ||
      (post.urlMetadata.length > 0 ? post.urlMetadata[0].url : 'No title');
    setExistingTitle(title);
    setTitle(title);
  }, [post]);

  const onUpdateTitle = () => {
    const updatedTitle =
      title.trim() ||
      `${post.creator.firstName}'s · ${moment().format('D MMMM')}`;
    updateTitle({
      variables: {
        data: {
          data: {
            title: updatedTitle,
          },
          postId: post.id,
        },
      },
      update: (cache, { data }) => {
        if (!data?.updatePost) return;
        modifyObject(cache, post.id, 'PostModel', {
          title: () => data.updatePost.title,
        });
      },
    });
    setTitle(updatedTitle);
    setExistingTitle(updatedTitle);
    onClose();
  };

  const renderTitle = useMemo(() => {
    return (
      <Typography
        variant="headline-xxs"
        sx={{
          borderBottom: `1px dashed ${theme.colors?.utility[600]}`,
          wordBreak: 'break-word',
          ...(sx || {}),
        }}
      >
        {existingTitle}
      </Typography>
    );
  }, [existingTitle, sx]);

  const saveOnEnter = (e) => {
    if (e.key === 'Enter') {
      onUpdateTitle();
    }
  };

  const onStartEditing = (e) => {
    e.stopPropagation();
    if (!canUpdate) return;
    editingState.onOpen();
    onToggleEditingView?.(post.id);
  };

  const onClose = () => {
    editingState.onClose();
    onToggleEditingView?.(post.id);
  };

  if (variant === 'default') {
    return (
      <Box
        sx={{
          cursor: canUpdate ? 'pointer' : 'auto',
          ...(editingState.isOpen
            ? {
                padding: 2,
                borderRadius: 2,
                background: theme.colors?.utility[275],
              }
            : {}),
        }}
        onClick={onStartEditing}
      >
        {editingState.isOpen ? (
          <Input
            autoFocus
            disableUnderline
            value={title}
            placeholder="Title"
            fullWidth
            multiline
            onChange={(e) => setTitle(e.target.value)}
            {...inputProps}
            sx={{
              padding: 0,
              ...theme.typography['headline-xxs'],
              ...inputProps?.sx,
            }}
            onBlur={onUpdateTitle}
            onKeyDown={saveOnEnter}
          />
        ) : (
          renderTitle
        )}
      </Box>
    );
  }

  return (
    <>
      <Popover
        open={editingState.isOpen}
        anchorEl={anchorRef.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 250,
            borderRadius: theme.spacing(3),
            background: 'rgba(250, 243, 236, 0.50)',
            backdropFilter: 'blur(20px)',
            padding: theme.spacing(3),
            border: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Input
            autoFocus
            disableUnderline
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            {...inputProps}
            sx={{
              ...theme.typography['headline-xs'],
              ...inputProps?.sx,
            }}
            onKeyDown={saveOnEnter}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <IconButton sx={{ padding: 0 }} disableRipple onClick={onClose}>
              <IconBoldCloseCircle
                size={22}
                color={theme.colors?.utility[700]}
              />
            </IconButton>
            <IconButton
              sx={{ padding: 0 }}
              disableRipple
              onClick={onUpdateTitle}
            >
              <IconBoldTickCircle
                size={22}
                color={theme.colors?.primary.black}
              />
            </IconButton>
          </Box>
        </Box>
      </Popover>
      <Box
        sx={{
          cursor: canUpdate ? 'pointer' : 'auto',
          borderRadius: 1,
          pb: 2,
          width: '100%',
        }}
        onClick={onStartEditing}
        ref={anchorRef}
      >
        {renderTitle}
      </Box>
    </>
  );
};
