import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import {
  DateField,
  LocalizationProvider as LocalizationProviderPro,
  TimeField,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment as AdapterMomentPro } from '@mui/x-date-pickers-pro/AdapterMoment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IconLinearArrowLeft1 } from 'components/icons/components/linear/IconLinearArrowLeft1';
import { IconLinearArrowRight1 } from 'components/icons/components/linear/IconLinearArrowRight1';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { useStyles } from './styles';

const PaperContent = (props: {
  children: React.ReactNode;
  value?: moment.Moment;
  includeTime?: boolean;
  setIncludeTime?: (includeTime: boolean) => void;
  onChange?: (date: moment.Moment) => void;
}) => {
  const {
    children,
    value,
    includeTime = false,
    setIncludeTime,
    onChange,
  } = props;

  return (
    <Box width={320}>
      <Box pt={3} px={3}>
        <Box
          sx={{
            bgcolor: theme.colors?.utility[275],
            borderRadius: 2,
            border: `1px solid ${theme.colors?.utility[400]}`,
            display: 'flex',
            alignItems: 'center',
            fieldset: {
              borderRadius: 0,
              border: 'none',
            },
            input: {
              py: 1,
            },
            py: 2,
          }}
        >
          <LocalizationProviderPro dateAdapter={AdapterMomentPro}>
            <DateField
              value={value}
              sx={{
                width: '50%',
                minWidth: 0,
              }}
              onChange={(date) => {
                if (date) {
                  onChange?.(date);
                }
              }}
            />
            {includeTime && (
              <TimeField
                value={value}
                sx={{
                  width: '50%',
                  minWidth: 0,
                  fieldset: {
                    borderLeft: `1px solid ${theme.colors?.utility[600]} !important`,
                  },
                }}
                onChange={(date) => {
                  if (date) {
                    onChange?.(date);
                  }
                }}
              />
            )}
          </LocalizationProviderPro>
        </Box>
      </Box>
      {children}
      <Box
        sx={{
          pb: 3,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subhead-lg">Include time</Typography>
        <Switch
          checked={includeTime}
          // NOTE: Using `onClick` here because `onChange` is not working???
          onClick={() => {
            setIncludeTime?.(!includeTime);
          }}
        />
      </Box>
    </Box>
  );
};

export type DatePickerProps = Omit<
  MuiDatePickerProps<moment.Moment, moment.Moment>,
  'renderInput'
> & {
  includeTimeByDefault?: boolean;
  renderInput?: (
    props: TextFieldProps & {
      isOpen: boolean;
      openPicker: () => void;
      closePicker: () => void;
      togglePicker: () => void;
      onClear: () => void;
    },
  ) => React.ReactElement<any>;
};

export const DatePicker = (props: DatePickerProps) => {
  const {
    value,
    onChange,
    includeTimeByDefault,
    renderInput = (props) => <TextField {...props} onClick={onOpen} />,
    ...rest
  } = props;

  const styles = useStyles();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const onClear = () => {
    props.onChange(null);
  };

  const internalRenderInput = (props: TextFieldProps) => {
    return renderInput({
      ...props,
      openPicker: onOpen,
      closePicker: onClose,
      togglePicker: onToggle,
      onClear,
      isOpen,
    });
  };

  const [includeTime, setIncludeTime] = useState(includeTimeByDefault);

  // NOTE: This is a workaround for timezone issues
  // We'll always default time to 12:00 PM when `includeTime` is false OR when the date is populated for the first time
  const internalOnChange = (date: Moment | null) => {
    if (!date) {
      onChange(null);

      return;
    }

    if (!includeTime || !value) {
      onChange(date.startOf('day').hour(12));
    } else {
      onChange(date);
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{ clearButtonLabel: 'Clear Selection' }}
    >
      <MuiDatePicker
        open={isOpen}
        value={value}
        renderInput={internalRenderInput}
        PopperProps={{
          placement: 'bottom-start',
        }}
        PaperProps={{
          className: [styles.default, styles.date].join(' '),
        }}
        onClose={onClose}
        componentsProps={{
          paperContent: {
            value,
            includeTime,
            setIncludeTime,
            onChange: internalOnChange,
          },
        }}
        components={{
          OpenPickerIcon: IconOutlineCalendar1,
          LeftArrowIcon: IconLinearArrowLeft1,
          RightArrowIcon: IconLinearArrowRight1,
          PaperContent,
        }}
        dayOfWeekFormatter={(day) => day.toUpperCase()}
        showDaysOutsideCurrentMonth
        onChange={internalOnChange}
        {...rest}
      />
    </LocalizationProvider>
  );
};
