import { gql, useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconLinearDocumentUpload } from 'components/icons/components/linear/IconLinearDocumentUpload';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconOutlineArrowDownload } from 'components/icons/components/outline/IconOutlineArrowDownload';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { IconOutlineMinusCirlce } from 'components/icons/components/outline/IconOutlineMinusCirlce';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { CollectionCardView } from 'features/collection';
import {
  PostPreview,
  PostPreviewProps,
  isPostDownloadable,
} from 'features/post';
import { usePostPermissionUpdatePostPermissionToCollectionPermission } from 'features/post-permission';
import {
  CollectionFragmentCollectionCardViewFragmentDoc,
  PostFragmentPostCommandFragment,
  PostFragmentPostPreviewFragmentDoc,
  PostPermission,
  PostType,
  useDeletePostForCollectionFieldMutation,
  useGetCollectionForCollectionFieldQuery,
  useRemovePostFromCollectionForCollectionFieldMutation,
  useSavePostsToCollectionsForCollectionFieldMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useRef, useState } from 'react';
import { Link, Location, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

export const COLLECTION_FRAGMENT_COLLECTION_FIELD = gql`
  fragment CollectionFragmentCollectionField on CollectionModel {
    id
    posts {
      id
      myPermissions
      ...PostFragmentPostPreview
    }
    childCollections {
      id
      ...CollectionFragmentCollectionCardView
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
  ${CollectionFragmentCollectionCardViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCollectionForCollectionField($collectionId: String!) {
    collection(id: $collectionId) {
      id
      ...CollectionFragmentCollectionField
    }
  }
  ${COLLECTION_FRAGMENT_COLLECTION_FIELD}
`;

// eslint-disable-next-line
gql`
  mutation SavePostsToCollectionsForCollectionField(
    $data: SavePostsToCollectionsInput!
  ) {
    savePostsToCollections(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation RemovePostFromCollectionForCollectionField(
    $data: RemovePostFromCollectionInput!
  ) {
    removePostFromCollection(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeletePostForCollectionField($id: String!) {
    deletePost(id: $id) {
      message
      success
    }
  }
`;

export type CollectionFieldProps = {
  sx?: SxProps;
  readOnly?: boolean;
  collectionId: string;
  componentProps?: {
    postPreview?: Omit<PostPreviewProps, 'post'>;
  };

  /**
   * Id of the original content idea's representative collection.
   * Here to support POST_SELECT_FROM_CJB command from a content idea.
   */
  contentIdeaCollectionId: string;
};

export const CollectionField = (props: CollectionFieldProps) => {
  const {
    sx,
    readOnly,
    collectionId,
    componentProps = {},
    contentIdeaCollectionId,
  } = props;

  const location = useLocation();
  const { backgroundLocation } =
    (location.state as { backgroundLocation?: Location }) || {};
  const [hoveredPostId, setHoveredPostId] = useState<string>('');

  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isMenuOpen,
    onOpen: openMenu,
    onClose: closeMenu,
  } = useDisclosure();
  const { dialog: deletePostDialog, onOpen: openDeletePostConfirmationDialog } =
    useConfirmationDialog();

  const { triggerCommand } = useCommandContext();

  const client = useApolloClient();

  const { data: collectionData } = useGetCollectionForCollectionFieldQuery({
    variables: {
      collectionId,
    },
    skip: !collectionId,
    fetchPolicy: 'cache-and-network',
  });
  const posts = collectionData?.collection.posts || [];
  const childCollections = collectionData?.collection.childCollections || [];

  const [savePostsToCollections] =
    useSavePostsToCollectionsForCollectionFieldMutation();
  const [removePostFromCollection] =
    useRemovePostFromCollectionForCollectionFieldMutation();
  const [deletePost] = useDeletePostForCollectionFieldMutation();

  /**
   * Cache update function to add new posts to the collection.
   */
  const onPostsAdded = async (newPosts: PostFragmentPostCommandFragment[]) => {
    modifyObject(client.cache, collectionId, 'CollectionModel', {
      posts: () => [...posts, ...newPosts],
    });
  };

  /**
   * Additional logic to update post permissions when adding new posts from CJB command flow.
   */
  const { onOpenUpdatePostPermissionDialog, renderUpdatePostPermissionAlert } =
    usePostPermissionUpdatePostPermissionToCollectionPermission();
  const onPostsAddedFromCJB = async (
    newPosts: PostFragmentPostCommandFragment[],
  ) => {
    await savePostsToCollections({
      variables: {
        data: {
          collectionIds: [collectionId],
          postIds: newPosts.map((i) => i.id),
        },
      },
    });

    onOpenUpdatePostPermissionDialog(
      newPosts.map((i) => i.id),
      collectionId,
    );

    onPostsAdded(newPosts);
  };

  const renderPostActionButtons = (post) => {
    // eslint-disable-next-line
    const PostActionButton = ({
      tooltip,
      onClick,
      Icon,
    }: {
      tooltip: string;
      onClick?: VoidFunction;
      Icon: any;
    }) => {
      return (
        <IconButtonWithTooltip
          tooltip={tooltip}
          sx={{
            borderRadius: 25,
            background: `rgba(35, 6, 3, 0.10)`,
            backdropFilter: `blur(13.33px)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 16,
            height: 16,
          }}
          onClick={() => onClick?.()}
        >
          <Icon
            size={12}
            color={theme.colors?.primary.white}
            style={{
              flexShrink: 0,
            }}
          />
        </IconButtonWithTooltip>
      );
    };

    const options = [
      {
        label: 'Remove from this Collection',
        onClick: () => {
          removePostFromCollection({
            variables: {
              data: {
                postId: post.id,
                collectionId,
              },
            },
          });

          modifyObject(client.cache, collectionId, 'CollectionModel', {
            posts: () => posts.filter((i) => i.id !== post.id),
          });
        },
        Icon: IconOutlineMinusCirlce,
        color: theme.colors?.utility[900],
      },
      {
        label: 'Delete from Creative Juicebox',
        onClick: () => {
          openDeletePostConfirmationDialog({
            title: (
              <Typography variant="headline-lg">
                Delete from Creative Juicebox?
              </Typography>
            ),
            subtitle: (
              <Typography
                variant="subhead-xl"
                color={theme.colors?.primary.black}
              >
                {`Deleting this ${
                  post.type === PostType.Note ? 'note' : 'post'
                } from Creative Juicebox is permanent and cannot be undone.`}
              </Typography>
            ),
            onConfirm: () => {
              deletePost({
                variables: {
                  id: post.id,
                },
              });

              modifyObject(client.cache, collectionId, 'CollectionModel', {
                posts: () => posts.filter((i) => i.id !== post.id),
              });
            },
            confirmText: 'Delete',
          });
        },
        Icon: IconOutlineMinusCirlce,
        color: theme.colors?.utility['pink-3'],
      },
    ];
    return (
      <>
        {isPostDownloadable(post) && (
          <PostActionButton
            Icon={IconOutlineArrowDownload}
            onClick={() => {
              triggerCommand(COMMAND_TYPE.POST_DOWNLOAD, {
                posts: [post],
                canDownloadSinglePost: true,
              });
            }}
            tooltip="Download"
          />
        )}
        {post.myPermissions.includes(PostPermission.Delete) && (
          <ContextMenu
            PaperProps={{
              sx: {
                borderRadius: 3,
                background: `rgba(255, 255, 255, 0.80)`,
                backdropFilter: `blur(20px)`,
                '& .MuiList-root': {
                  gap: `0 !important`,
                  li: {
                    padding: 3,
                  },
                },
              },
            }}
            options={options.map(({ Icon, color, label, onClick }) => ({
              onClick,
              renderOption: () => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Icon size={16} color={color} />
                  <Typography variant="headline-xs" color={color}>
                    {label}
                  </Typography>
                </Box>
              ),
            }))}
            renderButton={() => (
              <PostActionButton Icon={IconLinearTrash} tooltip="Delete" />
            )}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        {posts.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              overflowX: 'auto',
              gap: 1.5,
            }}
          >
            {childCollections.map((childCollection) => {
              return (
                <Box
                  component="a"
                  key={childCollection.id}
                  href={PlotRoutes.collection(childCollection.id)}
                  target="_blank"
                  style={{ flexShrink: 0 }}
                >
                  <CollectionCardView
                    collection={childCollection}
                    sx={{
                      width: 180,
                      height: 120,
                      bgcolor: theme.colors?.utility[300],
                      pointerEvents: 'none',
                    }}
                    componentsProps={{
                      disableContextMenu: true,
                      permission: false,
                    }}
                  />
                </Box>
              );
            })}
            {posts.map((post, index) => (
              <Box
                key={post.id}
                sx={{
                  position: 'relative',
                }}
                onMouseEnter={() => setHoveredPostId(post.id)}
                onMouseLeave={() => setHoveredPostId('')}
              >
                <Link
                  key={`${post.id}-${index}`}
                  to={PlotRoutes.juicePreview(post.id)}
                  state={{
                    backgroundLocation,
                    secondaryLocation: location,
                  }}
                  style={{
                    flexShrink: 0,
                  }}
                >
                  <PostPreview
                    disableMouseEvents
                    post={post}
                    {...componentProps.postPreview}
                    sx={{
                      height: 120,
                      maxWidth: 180,
                      borderRadius: 2,
                      ...componentProps.postPreview?.sx,
                    }}
                  />
                </Link>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    margin: 1,
                    gap: 1,
                  }}
                >
                  {hoveredPostId === post.id && renderPostActionButtons(post)}
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {readOnly ? (
          posts.length === 0 && (
            <Typography variant="subhead-lg" color={theme.colors?.utility[600]}>
              Empty
            </Typography>
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <IconButton
              ref={anchorElRef}
              onClick={openMenu}
              sx={{
                backgroundColor: theme.colors?.utility[400],
                width: 20,
                height: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx,
              }}
            >
              <IconLinearAdd
                size={16}
                color={theme.colors?.utility[800]}
                style={{
                  flexShrink: 0,
                }}
              />
            </IconButton>
            {posts.length > 0 && (
              <IconButton
                onClick={() => {
                  triggerCommand(COMMAND_TYPE.POST_DOWNLOAD, {
                    posts,
                  });
                }}
                sx={{
                  backgroundColor: theme.colors?.utility[400],
                  width: 20,
                  height: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ...sx,
                }}
              >
                <IconOutlineArrowDownload
                  size={16}
                  color={theme.colors?.utility[800]}
                  style={{
                    flexShrink: 0,
                  }}
                />
              </IconButton>
            )}
          </Box>
        )}
      </Box>

      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            p: 3,
            borderRadius: 3,
            bgcolor: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
          },
        }}
      >
        {[
          [
            IconOutlineLink,
            'Paste a link or image',
            () => {
              triggerCommand(COMMAND_TYPE.POST_PASTE_A_LINK, {
                shouldShowAfterCreationDialog: false,
                collectionId,
                onCompleted: onPostsAdded,
              });
            },
          ],
          [
            IconLinearDocumentUpload,
            'Upload media',
            () => {
              triggerCommand(COMMAND_TYPE.POST_UPLOAD_FILES, {
                shouldShowAfterCreationDialog: false,
                collectionId,
                onCompleted: onPostsAdded,
              });
            },
          ],
          [
            IconCustomCreativeJuiceBox,
            'Add from Creative Juicebox',
            () => {
              triggerCommand(COMMAND_TYPE.POST_SELECT_FROM_CJB, {
                submitButtonLabel: 'Continue',
                hardcodedContentIdeaCollectionId: contentIdeaCollectionId,
                onCompleted: onPostsAddedFromCJB,
              });
            },
          ],
        ].map(([Icon, label, onClick], index) => {
          return (
            <MenuItem
              key={index}
              sx={{
                borderRadius: 3,
                p: 3,
              }}
              onClick={() => {
                // @ts-ignore
                onClick();
                closeMenu();
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon size={16} />
                <Typography variant="headline-xs" fontSize={13}>
                  {label as string}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
      {deletePostDialog}
      {renderUpdatePostPermissionAlert()}
    </>
  );
};
