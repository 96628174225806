import { gql } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { EmojiPickerWrapper } from 'components/common/EmojiPicker';
import { ReactionList } from 'components/common/ReactionList';
import { Timestamp } from 'components/common/Timestamp';
import { RichTextEditor } from 'components/common/form/RichTextEditor';
import { RichTextEditorRef } from 'components/common/form/RichTextEditor/RichTextEditor';
import { IconCustomSmiley } from 'components/icons/components/custom/IconCustomSmiley';
import { useUserContext } from 'contexts/users/User.context';
import {
  CommentAttachmentListReadonly,
  CommentInput,
  CommentInputProps,
  MoreActions,
} from 'features/comments/components';
import { getUseEditorExtensionsPropsByVariant } from 'features/tiptap';
import {
  CommentFragmentCommentContentViewFragment,
  CommentFragmentCommentInputFragmentDoc,
  UpdateCommentInputData,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { formatDuration } from 'utils/date';
import { getFullName } from 'utils/users';
import { StyledCommentWrapper, StyledRichTextWrapper } from './styles';

export const COMMENT_FRAGMENT_COMMENT_CONTENT_VIEW = gql`
  fragment CommentFragmentCommentContentView on CommentModel {
    id
    text
    createdAt
    parentThreadId
    createdBy {
      id
      ...UserFragmentAvatarGroup
    }
    userReactions {
      id
      emoji
      userId
      user {
        id
        ...UserFragmentAvatarGroup
      }
    }
    postAnnotation {
      id
      postId
      x
      y
      time
      pageNumber
      noteCommentAnchorId
      noteCommentExtract
    }
    ...CommentFragmentCommentInput
  }
  ${UserFragmentAvatarGroupFragmentDoc}
  ${CommentFragmentCommentInputFragmentDoc}
`;

export type CommentContentProps = Pick<
  CommentInputProps,
  'inputStyle' | 'editorStyle'
> & {
  comment: CommentFragmentCommentContentViewFragment;
  canEdit?: boolean;
  setEditCommentId?: (id: string) => void;
  onReactToComment: (emoji: string) => void;
  onUpdateComment: (comment: UpdateCommentInputData) => void;
  onDeleteComment: () => void;
};

export const CommentContentView = ({
  comment,
  canEdit = true,
  setEditCommentId,
  inputStyle,
  editorStyle,
  onReactToComment,
  onDeleteComment,
  onUpdateComment,
}: CommentContentProps) => {
  const { user } = useUserContext();

  const richTextEditorRef = useRef<RichTextEditorRef | null>(null);
  const [editedComment, setEditedComment] = useState<
    (UpdateCommentInputData & { id: string }) | null
  >(null);

  const {
    dialog: deletePostCommentDialog,
    onOpen: openDeletePostCommentConfirmationDialog,
  } = useConfirmationDialog();

  useEffect(() => {
    if (!editedComment) {
      setEditCommentId?.('');
    }
  }, [editedComment]); // eslint-disable-line

  const _canEdit = canEdit && comment.createdBy.id === user?.id;
  const isEditing = editedComment?.id === comment.id;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: isEditing ? 'column' : 'row',
        gap: theme.spacing(2),
        width: '100%',
      }}
      className="comment-renderer"
    >
      {isEditing ? (
        <>
          <CommentInput
            commentInputRef={richTextEditorRef}
            comment={comment}
            defaultValues={{
              comment: {
                html: comment.text,
                text: comment.text,
              },
            }}
            inputStyle={inputStyle}
            editorStyle={editorStyle}
            autoFocus
            onChange={(comment) => {
              setEditedComment({
                ...editedComment,
                ...comment,
              });
            }}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              size="small"
              variant="text"
              onClick={(e) => {
                e.preventDefault();
                setEditedComment(null);
              }}
              sx={{ p: theme.spacing(1, 2), minWidth: 44 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="primary-alt"
              sx={{ p: theme.spacing(1, 2), minWidth: 44 }}
              onClick={(e) => {
                e.preventDefault();
                onUpdateComment({
                  comment: editedComment.comment,
                  attachments: editedComment.attachments,
                });
                setEditedComment(null);
              }}
            >
              Save
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Avatar size={24} user={comment.createdBy} />
          <StyledCommentWrapper>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box display="flex" gap={2} alignItems="center">
                <Typography variant="subhead-xl">
                  {getFullName(comment.createdBy)}
                </Typography>
                <Timestamp
                  date={comment.createdAt}
                  humanized
                  humanizedVariant="short"
                  sx={{
                    ...theme.typography['body-sm'],
                    color: theme.colors?.utility[700],
                  }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <EmojiPickerWrapper
                  maxEmojiSelect={1}
                  onEmojiSelect={(emoji) => {
                    onReactToComment(emoji.native);
                  }}
                >
                  <Box
                    display="flex"
                    width={theme.spacing(5)}
                    height={theme.spacing(5)}
                    sx={{ cursor: 'pointer', position: 'relative' }}
                  >
                    <IconCustomSmiley
                      size={20}
                      color={theme.colors?.utility[700]}
                    />
                  </Box>
                </EmojiPickerWrapper>

                {_canEdit && (
                  <MoreActions
                    onSetEditedComment={() => {
                      setEditCommentId?.(comment.id);
                      setEditedComment({
                        id: comment.id,
                        comment: comment.text,
                      });
                      setTimeout(() => {
                        richTextEditorRef.current?.setDefaultContent(
                          comment.text,
                        );
                      }, 300);
                    }}
                    onDeletePostComment={() =>
                      openDeletePostCommentConfirmationDialog({
                        title: <>Would you like to delete the comment?</>,
                        subtitle:
                          'Once you delete, this will no longer be accessible.',
                        onConfirm: () => {
                          onDeleteComment();
                        },
                        confirmText: 'Delete',
                      })
                    }
                    onToggleMenu={(val) =>
                      val
                        ? setEditCommentId?.(comment.id)
                        : setEditCommentId?.('')
                    }
                  />
                )}
              </Box>
            </Box>

            {/* TODO: Extract this into a component */}
            {comment.postAnnotation?.time !== undefined &&
            comment.postAnnotation.time !== null ? (
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[700]}
                my={1}
              >
                {formatDuration(
                  moment.duration(comment.postAnnotation?.time, 'seconds'),
                )}
              </Typography>
            ) : null}

            {/* Only show note extract if this is a parent comment */}
            {!comment.parentThreadId &&
              comment.postAnnotation?.noteCommentExtract && (
                <Typography
                  variant="body-lg"
                  component="div"
                  sx={{
                    display: 'flex',
                    pl: 2,
                    my: 1,
                    alignItems: 'center',
                    overflow: 'hidden',
                    borderLeft: `4px solid ${theme.colors?.utility['yellow-2-new']}`,
                    color: theme.colors?.utility[700],
                  }}
                >
                  {comment.postAnnotation.noteCommentExtract}
                </Typography>
              )}

            <StyledRichTextWrapper isEditing={isEditing}>
              <RichTextEditor
                defaultContent={comment.text}
                content={comment.text}
                hideToolBar
                editable={false}
                style={{
                  color: theme.colors?.utility[900],
                }}
                containerProps={{
                  sx: {
                    paddingRight: theme.spacing(2),
                  },
                }}
                borderless
                useEditorExtensionsProps={getUseEditorExtensionsPropsByVariant(
                  'inline',
                )}
              />
            </StyledRichTextWrapper>

            {comment.attachments.length > 0 && (
              <CommentAttachmentListReadonly comment={comment} />
            )}

            <ReactionList
              userReactions={comment.userReactions}
              user={user}
              onClickEmoji={(emoji) => {
                onReactToComment(emoji);
              }}
              display="flex"
              flexWrap="wrap"
            />
          </StyledCommentWrapper>
        </>
      )}
      {deletePostCommentDialog}
    </Box>
  );
};
