import { Box, Button, Checkbox, Divider, Typography } from '@mui/material';
import { CustomController } from 'components/common/form/CustomController';
import { RichTextEditor } from 'components/common/form/RichTextEditor';
import { RichTextEditorRef } from 'components/common/form/RichTextEditor/RichTextEditor';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useUserContext } from 'contexts/users/User.context';
import { GenerateId } from 'utils/generateId';
import {
  CreateTaskInput,
  TaskFragmentCreateSubtaskFragment,
  TaskStatus,
  useCreateTaskForCreateTaskModalMutation,
} from 'graphql/generated';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme/theme';
import {
  AssigneeList,
  DueDateSelectButton,
  PrioritySelectButton,
  StatusSelectButton,
  Subtask,
} from './components';
import {
  StyledButton,
  StyledButtonWrapper,
  StyledContainer,
  StyledTaskNameInput,
} from './styles';
import { CreateNewTaskProps } from './types';

export const CreateNewTask = (props: CreateNewTaskProps) => {
  const { onTaskCreated, onCancel, options, onCreateTask } = props;
  const { user } = useUserContext();

  const [subtasks, setSubtasks] = useState<TaskFragmentCreateSubtaskFragment[]>(
    [],
  );

  const [isCreateAnother, setCreateAnother] = React.useState(
    !!options.initialValues?.isCreateAnother,
  );

  const elementRef = React.useRef<any>(null);

  const defaultValues = {
    name: '',
    status: TaskStatus.ToDo,
    owner: options?.initialValues?.owner ? [options?.initialValues?.owner] : [],
    description: '',
    priority: null,
    dates: {
      startDate: null,
      endDate: null,
    },
  };

  const [createTask] = useCreateTaskForCreateTaskModalMutation();
  const { control, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const getCreateSubtask = () => {
    return {
      id: GenerateId.create(),
      name: '',
      status: TaskStatus.ToDo,
      taskMembers: [],
      endDate: null,
      startDate: null,
      priority: null,
    } as TaskFragmentCreateSubtaskFragment;
  };

  const taskDescriptionRef = React.useRef<RichTextEditorRef | null>(null);

  const { name } = watch();

  const canCreateTask = !!name;

  const onResetForm = () => {
    if (isCreateAnother) {
      reset({
        ...defaultValues,
      });
      taskDescriptionRef.current?.clear();
      setSubtasks([]);
    } else {
      reset();
      taskDescriptionRef.current?.clear();
      setSubtasks([]);
      onCancel?.();
    }
  };

  const onTaskCreate = async (values) => {
    const input: CreateTaskInput = {
      projectId: values.projectId || undefined,
      newTask: {
        name: values.name.trim(),
        status: values.status,
        memberIds: values.owner.map((user) => user.id),
        description: values.description,
        startDate: values.dates.startDate,
        endDate: values.dates.endDate,
        priority: values.priority,
        // NOTE: This should be available when users hit Plot this idea
        // from the juice modal
        postId: options.initialValues?.postId,
        // This should be available when users create subtask from TaskDetailPage (new side by side flow)
        parentTaskId: options.initialValues?.parentTaskId,
        subtasks: subtasks.map((subtask) => ({
          name: subtask.name,
          endDate: subtask.endDate,
          startDate: subtask.startDate,
          id: subtask.id,
          memberIds: subtask.taskMembers.map((m) => m.id),
          priority: subtask.priority,
          status: subtask.status,
        })),
      },
    };
    // This is for the case when another mutation is using for create task
    if (onCreateTask) {
      onCreateTask(input);
      onResetForm();
      return;
    }
    await createTask({
      variables: {
        data: input,
      },
      onCompleted(data) {
        if (data.createTask) {
          onTaskCreated(data.createTask);
          onResetForm();
        }
      },
    });
  };

  const onCreateSubtask = () => {
    setSubtasks([...subtasks, getCreateSubtask()]);
  };

  if (!user) return null;

  return (
    <Box display="flex" flexDirection="column" flex={1} overflow="auto">
      {options.onNavigateBack && (
        <Box display="flex" alignItems="center" gap={2} px={8} pt={8} pb={5}>
          <Box
            display="flex"
            onClick={options.onNavigateBack}
            sx={{ cursor: 'pointer' }}
          >
            <IconOutlineArrowLeft />
          </Box>
          <Typography variant="headline-md" color={theme.colors?.utility[1000]}>
            Create a new task
          </Typography>
        </Box>
      )}
      <Divider
        sx={{ borderBottom: `1px solid ${theme.colors?.utility[300]}` }}
      />
      <Box sx={{ display: 'flex', flex: 1 }}>
        <StyledContainer flex={1}>
          <CustomController
            sx={{ marginBottom: -2 }}
            name="name"
            control={control}
            rules={{
              required: 'Task title is required',
            }}
            render={({ field, fieldState }) => (
              <StyledTaskNameInput
                {...field}
                disableUnderline
                error={!!fieldState.error?.message}
                placeholder="Add a Title"
                fullWidth
                autoFocus
                sx={{
                  color: theme.colors?.utility[1000],
                  ...theme.typography['headline-xl'],
                  fontSize: 28,
                  letterSpacing: '-1.12px',
                  fontWeight: 500,
                }}
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 2.5,
            }}
          >
            <CustomController
              name="status"
              control={control}
              sx={{
                maxWidth: 150,
              }}
              render={({ field }) => (
                <StatusSelectButton
                  iconSize={20}
                  sx={{ padding: theme.spacing(2, 2.5) }}
                  {...field}
                />
              )}
            />
            <CustomController
              name="dates"
              control={control}
              sx={{
                maxWidth: 150,
              }}
              render={({ field }) => (
                <DueDateSelectButton
                  {...field}
                  value={[field.value.startDate, field.value.endDate]}
                  onChange={([startDate, endDate]) =>
                    setValue('dates', { startDate, endDate } as any)
                  }
                  sx={{
                    bgcolor: theme.colors?.utility[250],
                    padding: theme.spacing(2, 2.5),
                    borderRadius: 3,
                    whiteSpace: 'nowrap',
                  }}
                />
              )}
            />
            <CustomController
              name="priority"
              control={control}
              sx={{
                maxWidth: 150,
              }}
              render={({ field }) => (
                <PrioritySelectButton
                  {...field}
                  onChange={(priority) => setValue('priority', priority as any)}
                  componentsProps={{
                    icon: {
                      color: theme.colors?.utility[700],
                      size: 24,
                    },
                    popover: {
                      PaperProps: {
                        style: {
                          borderRadius: 12,
                          background: `rgba(255, 255, 255, 0.80)`,
                        },
                      },
                    },
                    priorityButton: {
                      btnText: 'Pick Priority',
                      sx: {
                        '& span': {
                          ...theme.typography['subhead-xl'],
                          color: theme.colors?.utility[700],
                        },
                      },
                    },
                  }}
                />
              )}
            />
            <CustomController
              name="owner"
              control={control}
              sx={{
                maxWidth: 150,
              }}
              render={({ field }) => (
                <AssigneeList
                  assignees={field.value}
                  onChange={(owner) => {
                    field.onChange(owner);
                  }}
                  sx={{
                    bgcolor: theme.colors?.utility[250],
                    px: 3,
                    py: 2,
                    borderRadius: theme.spacing(3),
                    minHeight: theme.spacing(10),
                    minWidth: theme.spacing(33),
                  }}
                  shouldShowEmptyText
                />
              )}
            />
          </Box>
          <CustomController
            name="description"
            control={control}
            sx={{
              margin: theme.spacing(-4, 0),
            }}
            render={({ field }) => (
              <Box ref={elementRef}>
                <RichTextEditor
                  placeholder="Description..."
                  style={{
                    ...theme.typography['subhead-xl'],
                    color: theme.colors?.utility[1000],
                    flex: 1,
                  }}
                  borderless
                  containerProps={{
                    sx: {
                      flex: 1,
                    },
                  }}
                  hideToolBar
                  {...field}
                  ref={taskDescriptionRef}
                  onChange={({ html }) => {
                    setValue('description', html);
                  }}
                />
              </Box>
            )}
          />
          <Divider sx={{ borderColor: theme.colors?.utility[300] }} />
          {subtasks.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              {subtasks.map((subtask) => (
                <Subtask
                  task={subtask}
                  onUpdate={(updatedTask) => {
                    setSubtasks((prevSubtasks) => {
                      return prevSubtasks.map((task) => {
                        if (task.id === updatedTask.id) {
                          return updatedTask;
                        }
                        return task;
                      });
                    });
                  }}
                />
              ))}
            </Box>
          )}
          {subtasks.length ? (
            <Button
              startIcon={<IconLinearAdd size={16} />}
              sx={{
                ...theme.typography['subhead-xl'],
                width: 'fit-content',
                padding: 0,
                mt: -2,
              }}
              onClick={onCreateSubtask}
            >
              Add a Subtask
            </Button>
          ) : (
            <Button
              variant="text"
              size="small"
              sx={{
                background: theme.colors?.utility[300],
                border: `1px solid ${theme.colors?.utility[400]}`,
                width: 'fit-content',
                padding: theme.spacing(2, 4),
                ...theme.typography['headline-sm'],
              }}
              onClick={onCreateSubtask}
            >
              Add a Subtask
            </Button>
          )}
        </StyledContainer>
      </Box>
      <Divider sx={{ borderBottomColor: theme.colors?.utility[300] }} />
      <StyledButtonWrapper>
        <Box
          onClick={() => setCreateAnother((pre) => !pre)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
            cursor: 'pointer',
          }}
        >
          <Checkbox
            checked={isCreateAnother}
            sx={{
              padding: 0,
              '&.Mui-checked': { color: theme.colors?.primary.black },
            }}
          />
          <Typography variant="headline-xs" color={theme.colors?.utility[800]}>
            Create another task
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(4),
            alignItems: 'center',
          }}
        >
          <StyledButton
            variant="text"
            onClick={onCancel}
            sx={{
              color: theme.colors?.utility[700],
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="primary-alt"
            disabled={!canCreateTask}
            size="small"
            onClick={handleSubmit(onTaskCreate)}
          >
            Create task
          </StyledButton>
        </Box>
      </StyledButtonWrapper>
    </Box>
  );
};
