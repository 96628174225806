import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { theme } from 'styles/theme';
import { FilterOption } from '../types';

type Props = {
  currentFilters: { [key: string]: string[] };
  options: FilterOption[];
  toggleValue: (key: string, value: string) => void;
};

export const FilterByOptions = ({
  currentFilters,
  options,
  toggleValue,
}: Props) => {
  return (
    <Box sx={{ width: 270, py: 2 }}>
      {options.map((option) => {
        const isMultiSelect = option?.isMultiSelect ?? true;
        return (
          <Accordion
            defaultExpanded
            sx={{
              '&.MuiAccordion-root': {
                border: 'none',
                boxShadow: 'none',
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<IconLinearArrowDown size={16} />}
              sx={{
                p: theme.spacing(2, 4),
                m: 0,
                '& .Mui-expanded, &.Mui-expanded, &.MuiAccordionSummary-root': {
                  minHeight: '30px',
                  height: '40px',
                  margin: 0,
                  alignItems: 'center',
                },
              }}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    p: 1,
                    backgroundColor: 'rgba(35, 6, 3, 0.05)',
                    borderRadius: theme.spacing(1),
                  }}
                >
                  {option.icon}
                </Box>
                <Typography variant="body-lg">{option.label}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {option.options.map((_option) => (
                  <Box
                    key={_option.value}
                    display="flex"
                    alignItems="center"
                    gap={2}
                  >
                    {isMultiSelect && (
                      <Checkbox
                        disableRipple
                        checked={currentFilters[option.key]?.includes(
                          _option.value,
                        )}
                        sx={{
                          p: 1,
                          color: theme.colors?.primary.black,
                          '&.MuiCheckbox-root': {
                            color: theme.colors?.primary.black,
                          },
                        }}
                        onChange={(e) => {
                          toggleValue(option.key, _option.value);
                        }}
                      />
                    )}
                    {typeof _option.render === 'string' ? (
                      <Typography variant="body-lg">
                        {_option.render}
                      </Typography>
                    ) : (
                      _option.render
                    )}
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};
