import { Box, Typography } from '@mui/material';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { BrandCompetitorsView } from 'features/brandCompetitors';
import { PlotFeature } from 'graphql/generated';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

type Props = {};

export const SocialMediaListeningCompetitorsPage = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setHeaderMenuOptions, setRenderTitle } = useCustomHeaderContext();
  const { isFeatureEnabled, loading } = useFeatureFlagContext();

  useEffect(() => {
    if (!loading) {
      const _isFeatureEnabled = isFeatureEnabled(
        PlotFeature.CompetitorAnalysis,
      );
      if (!_isFeatureEnabled) {
        navigate(PlotRoutes.home());
      }
    }
  }, [isFeatureEnabled, loading]); // eslint-disable-line

  useEffect(() => {
    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.black}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening • Competitor Analytics
        </Typography>
      </Box>
    ));
    setHeaderMenuOptions({
      copyLink: false,
      search: true,
      inbox: true,
    });
  }, [location.pathname]); // eslint-disable-line

  return <BrandCompetitorsView />;
};
