import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  SocialPostCommentFragmentSocialPostCommentBrandResponderFragment,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';

gql`
  fragment SocialPostCommentFragmentSocialPostCommentBrandResponder on SocialPostCommentModel {
    id
    socialPost {
      brandSocialPostEngagement(brandId: $brandId) {
        id
        respondedByUser {
          ...UserFragmentAvatarGroup
        }
      }
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

type SocialPostCommentBrandResponderProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentBrandResponderFragment;
};

export const SocialPostCommentBrandResponder = (
  props: SocialPostCommentBrandResponderProps,
) => {
  const { socialPostComment } = props;

  return !socialPostComment.socialPost.brandSocialPostEngagement
    ?.respondedByUser ? null : (
    <Box>
      <AvatarWithName
        user={
          socialPostComment.socialPost.brandSocialPostEngagement
            ?.respondedByUser
        }
        shouldHideEmail
        avatarSize={20}
        componentsProps={{
          sx: {
            bgcolor: '#2306030D',
            borderRadius: theme.spacing(5),
            padding: theme.spacing(0.5, 1, 0.5, 0.5),
            gap: theme.spacing(1),
          },
          name: {
            sx: {
              ...theme.typography['subhead-sm'],
            },
          },
        }}
      />
    </Box>
  );
};
