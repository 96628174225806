import { gql, useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  CollectionFragmentUpdatePostPermissionAlertViewFragment,
  PostFragmentPostMoveToAnotherCollectionFragment,
  useMovePostsToCollectionForUseMoveToAnotherCollectionModalMutation,
} from 'graphql/generated';
import { useCallback } from 'react';
import { evictObject } from 'utils/apollo';
import { usePostPermissionUpdatePostPermissionToCollectionPermission } from 'features/post-permission';
import { PostMoveToAnotherCollection } from '../views/moveToAnotherCollection';

type Props = {
  post: PostFragmentPostMoveToAnotherCollectionFragment;
  currentCollectionId: string;
  onMoved?: (destinationCollectionId: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation MovePostsToCollectionForUseMoveToAnotherCollectionModal(
    $data: MovePostsToCollectionInput!
  ) {
    movePostsToCollection(data: $data) {
      success
      message
    }
  }
`;
export const useMoveToAnotherCollectionModalForPost = ({
  post,
  currentCollectionId,
  onMoved,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [movePostToCollection] =
    useMovePostsToCollectionForUseMoveToAnotherCollectionModalMutation();

  const { onOpenUpdatePostPermissionDialog, renderUpdatePostPermissionAlert } =
    usePostPermissionUpdatePostPermissionToCollectionPermission();

  const { cache } = useApolloClient();

  const onMovePost = useCallback(
    async (
      postIds: string[],
      toCollection: CollectionFragmentUpdatePostPermissionAlertViewFragment,
    ) => {
      await movePostToCollection({
        variables: {
          data: {
            postIds,
            toCollectionId: toCollection.id,
            fromCollectionId: currentCollectionId,
          },
        },
      });

      // TODO: support multi move
      await onOpenUpdatePostPermissionDialog(postIds, toCollection.id, () => {
        evictObject(cache, post.id, 'PostModel');
      });

      onMoved?.(toCollection.id);
    },
    [onClose], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const render = useCallback(
    () =>
      currentCollectionId && (
        <>
          <PostMoveToAnotherCollection
            key={post.id}
            post={post}
            isOpen={isOpen}
            onClose={onClose}
            onMove={onMovePost}
            currentCollectionId={currentCollectionId}
          />

          {renderUpdatePostPermissionAlert()}
        </>
      ),
    [
      currentCollectionId,
      post,
      isOpen,
      onClose,
      onMovePost,
      renderUpdatePostPermissionAlert,
    ],
  );

  return {
    renderMoveToAnotherPostModal: render,
    showMovePost: onOpen,
    hideMovePost: onClose,
  };
};
