import {
  Box,
  Button,
  Dialog,
  Divider,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { CheckboxMenuItem, RadioMenuItem } from 'components/common/form/Select';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import {
  DetailedSentiment,
  FollowersScale,
  SocialPostNotificationConfigFragmentNotificationsSectionFragment,
  TopicFragmentNotificationsSectionFragment,
  useCreateSocialPostNotificationConfigForNotificationsSectionMutation,
  useUpdateSocialPostNotificationConfigForNotificationsSectionMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import {
  socialPostNotificationConfigFollowersScaleOptions,
  socialPostNotificationConfigRelevancyOptions,
  socialPostNotificationConfigSentimentOptions,
} from './constants';
import { getSocialPostNotificationConfigFollowersScaleSelectLabel } from './utils';

export type CustomTopicNotificationDialogProps = {
  /**
   * Currently selected topic's notification config
   */
  socialPostNotificationConfig?: SocialPostNotificationConfigFragmentNotificationsSectionFragment;

  /**
   * List of available topics
   */
  topics: TopicFragmentNotificationsSectionFragment[];

  onClose: () => void;
};

export const CustomTopicNotificationDialog = (
  props: CustomTopicNotificationDialogProps,
) => {
  const { socialPostNotificationConfig, topics, onClose } = props;

  const [createSocialPostNotificationConfig] =
    useCreateSocialPostNotificationConfigForNotificationsSectionMutation();
  const [updateSocialPostNotificationConfig] =
    useUpdateSocialPostNotificationConfigForNotificationsSectionMutation();

  const [values, setValues] = useState({
    topicId: socialPostNotificationConfig?.topic?.id || '',
    followersScale: socialPostNotificationConfig?.creatorFollowing || [],
    minimumScore: socialPostNotificationConfig?.minimumScore || 0.8,
    sentiments: socialPostNotificationConfig?.sentiments || [],
    creatorFollowingEnabled:
      socialPostNotificationConfig?.creatorFollowingEnabled || false,
    minimumScoreEnabled:
      socialPostNotificationConfig?.minimumScoreEnabled ?? true,
    sentimentsEnabled: socialPostNotificationConfig?.sentimentsEnabled,
    enabled: socialPostNotificationConfig?.enabled || true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (socialPostNotificationConfig) {
        await updateSocialPostNotificationConfig({
          variables: {
            data: {
              id: socialPostNotificationConfig.id,
              data: {
                ...values,
              },
            },
          },
        });
      } else {
        await createSocialPostNotificationConfig({
          variables: {
            data: {
              data: {
                ...values,
              },
            },
          },
        });
      }

      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const commonSelectProps: Partial<SelectProps> = {
    sx: {
      bgcolor: theme.colors?.utility[300],
      height: '32px',
      borderRadius: 1,
      '.MuiSelect-select': {
        minHeight: '32px !important',
        lineHeight: '32px !important',
        py: '4px !important',
        pr: '32px !important',
        pl: '12px !important',
      },
      fieldset: {
        border: `1px solid ${theme.colors?.utility[400]}`,
      },
    },
    MenuProps: {
      PaperProps: {
        sx: {
          p: 3,
        },
      },
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom',
      },
      transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
      },
    },
  };

  const canSubmit = Boolean(values.topicId) && !isLoading;

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 6,
          p: 8,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#FAF3ECCC',
          backdropFilter: 'blur(12px)',
        },
      }}
    >
      <Typography variant="headline-xl" fontSize={28} mb={2}>
        Customize topic notifications
      </Typography>
      <Typography
        variant="subhead-lg"
        color={theme.colors?.utility[800]}
        mb={6}
      >
        Configure custom notifications for a specific topic of interest.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Typography variant="headline-sm">Topic:</Typography>
        <Select
          value={values.topicId}
          {...commonSelectProps}
          onChange={(e) => {
            setValues((prev) => ({
              ...prev,
              topicId: e.target.value as string,
            }));
          }}
          renderValue={() => {
            const topic = topics.find((topic) => topic.id === values.topicId);

            if (!topic) {
              return (
                <Typography variant="subhead-lg">
                  <i>Make a selection</i>
                </Typography>
              );
            }

            return <Typography variant="subhead-lg">{topic.name}</Typography>;
          }}
        >
          {topics.map((topic, index) => {
            return (
              <RadioMenuItem
                key={index}
                value={topic.id}
                label={topic.name}
                checked={values.topicId === topic.id}
                radioPosition="end"
                renderRadio={(checked) => {
                  if (!checked) {
                    return null;
                  }

                  return <IconCustomTick size={16} />;
                }}
                sx={{
                  minWidth: 220,
                }}
              />
            );
          })}
        </Select>
      </Box>
      <Divider
        sx={{
          my: 6,
          borderColor: '#2306031A',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
          mx: -3,
          mb: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckboxMenuItem
            value=""
            label={<Typography variant="subhead-lg">Creator has</Typography>}
            checked={values.creatorFollowingEnabled}
            onClick={() => {
              setValues((prev) => ({
                ...prev,
                creatorFollowingEnabled: !prev.creatorFollowingEnabled,
              }));
            }}
          />
          <Select
            value={values.followersScale}
            multiple
            {...commonSelectProps}
            onChange={(e) => {
              setValues((prev) => ({
                ...prev,
                followersScale: e.target.value as FollowersScale[],
                // Also enable the creator following if users are adding the followers scale
                ...(values.followersScale.length === 0 && {
                  creatorFollowingEnabled: true,
                }),
                ...((e.target.value as FollowersScale[]).length === 0 && {
                  creatorFollowingEnabled: false,
                }),
              }));
            }}
            renderValue={() => {
              if (!values?.followersScale || !values?.followersScale.length) {
                return (
                  <Typography variant="subhead-lg">
                    <i>Make a selection</i>
                  </Typography>
                );
              }

              return (
                <Typography variant="subhead-lg">
                  {getSocialPostNotificationConfigFollowersScaleSelectLabel(
                    values.followersScale,
                  )}
                </Typography>
              );
            }}
          >
            {socialPostNotificationConfigFollowersScaleOptions.map((option) => {
              return (
                <CheckboxMenuItem
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={values.followersScale.includes(option.value)}
                />
              );
            })}
          </Select>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckboxMenuItem
            value=""
            label={
              <Typography variant="subhead-lg">
                The topic relevancy is more than
              </Typography>
            }
            checked={values.minimumScoreEnabled}
            onClick={() => {
              setValues((prev) => ({
                ...prev,
                minimumScoreEnabled: !prev.minimumScoreEnabled,
              }));
            }}
          />
          <Select
            value={values.minimumScore}
            {...commonSelectProps}
            onChange={(e) => {
              setValues((prev) => ({
                ...prev,
                minimumScore: e.target.value as number,
                // Also enable the minimum score if users are picking a minimum score
                // 0.5 is the default value from the backend
                ...((values.minimumScore === undefined ||
                  values.minimumScore === 0.5) && {
                  minimumScoreEnabled: true,
                }),
              }));
            }}
            renderValue={() => {
              const option = socialPostNotificationConfigRelevancyOptions.find(
                (option) => option.value === values?.minimumScore,
              );

              if (!option) {
                return (
                  <Typography variant="subhead-lg">
                    <i>Make a selection</i>
                  </Typography>
                );
              }

              return (
                <Typography variant="subhead-lg">{option.label}</Typography>
              );
            }}
          >
            {socialPostNotificationConfigRelevancyOptions.map((option) => {
              return (
                <RadioMenuItem
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={values.minimumScore === option.value}
                />
              );
            })}
          </Select>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckboxMenuItem
            value=""
            label={
              <Typography variant="subhead-lg">The sentiment is</Typography>
            }
            checked={values.sentimentsEnabled}
            onClick={() => {
              setValues((prev) => ({
                ...prev,
                sentimentsEnabled: !prev.sentimentsEnabled,
              }));
            }}
          />
          <Select
            value={values.sentiments}
            multiple
            {...commonSelectProps}
            onChange={(e) => {
              setValues((prev) => ({
                ...prev,
                sentiments: e.target.value as DetailedSentiment[],
                // Also enable the sentiments if users are adding a sentiment
                ...(values.sentiments.length === 0 && {
                  sentimentsEnabled: true,
                }),
              }));
            }}
            renderValue={() => {
              if (!values?.sentiments || !values?.sentiments.length) {
                return (
                  <Typography variant="subhead-lg">
                    <i>Make a selection</i>
                  </Typography>
                );
              }

              return (
                <Typography variant="subhead-lg">
                  {values?.sentiments
                    .map((sentiment) =>
                      socialPostNotificationConfigSentimentOptions.find(
                        (option) => option.value === sentiment,
                      ),
                    )
                    .map((option) => option?.label)
                    .join(', ')}
                </Typography>
              );
            }}
          >
            {socialPostNotificationConfigSentimentOptions.map((option) => {
              return (
                <CheckboxMenuItem
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={values.sentiments.includes(option.value)}
                />
              );
            })}
          </Select>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary-alt" onClick={onSubmit} disabled={!canSubmit}>
          Save
        </Button>
      </Box>
    </Dialog>
  );
};
