import { useClipboard } from '@dwarvesf/react-hooks';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconOutlineArrowDownload } from 'components/icons/components/outline/IconOutlineArrowDownload';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { PostFragmentPostDetailContentViewFragment } from 'graphql/generated';
import { useCallback, useState } from 'react';
import { theme } from 'styles/theme';
import { CustomSocialMediaImage } from '../components/Image';
import { CustomOverflowTitle } from '../components/OverflowTitle/CustomOverflowTitle';
import { CustomVideo } from '../components/Video';

type Props = {
  post: PostFragmentPostDetailContentViewFragment;
};

export const useSocialMediaContentRenderer = ({ post }: Props) => {
  const urlMetadata = post.urlMetadata[0];
  const [renderedWidth, setRenderedWidth] = useState(-1);
  const { onCopy, hasCopied } = useClipboard(urlMetadata.url);
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0);
  const { triggerCommand } = useCommandContext();

  const currentMedia = urlMetadata.metadata?.medias?.[currentVisibleIndex];

  const loadingUI = renderedWidth <= 0;

  const renderSocialMediaContent = useCallback(() => {
    return (
      <>
        {loadingUI && (
          <Skeleton
            sx={{
              width: 300,
              height: 400,
              borderRadius: 3,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
        <Box
          display="block"
          justifyContent="center"
          key={currentMedia?.url}
          sx={{
            opacity: renderedWidth <= 0 ? 0 : 1,
            position: 'relative',
            mx: 'auto',
            boxShadow:
              '0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08)',
            borderRadius: theme.spacing(4.5),
            padding: theme.spacing(2, 2, 4),
            backgroundColor: theme.colors?.primary.parchment,
            flexDirection: 'column',
            ...(currentMedia?.type === 'image' && {
              width: `calc(${renderedWidth}px + ${theme.spacing(4)})`,
            }),
          }}
        >
          {(urlMetadata.metadata?.medias || []).length > 1 && (
            <Box
              sx={{
                position: 'absolute',
                zIndex: 2,
                left: '50%',
                transform: 'translateX(-50%)',
                bottom:
                  urlMetadata.metadata?.medias?.[currentVisibleIndex].type ===
                  'video'
                    ? theme.spacing(40)
                    : theme.spacing(25),
                display: 'flex',
                justifyContent: 'space-between',
                height: theme.spacing(10),
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => {
                  setRenderedWidth(0);
                  setCurrentVisibleIndex((prev) =>
                    prev === 0
                      ? (urlMetadata.metadata?.medias?.length || 1) - 1
                      : prev - 1,
                  );
                }}
                disableRipple
                sx={{
                  color: theme.colors?.primary.white,
                }}
              >
                <IconOutlineArrowLeft size={24} />
              </IconButton>
              <Box width="100%" display="flex" justifyContent="center" gap={2}>
                {(urlMetadata.metadata?.medias || []).map((media, index) => {
                  return (
                    <Box
                      key={index}
                      onClick={() => {
                        setRenderedWidth(0);
                        setCurrentVisibleIndex(index);
                      }}
                      sx={{
                        cursor: 'pointer',
                        width: 8,
                        height: 8,
                        borderRadius: 4,
                        backgroundColor:
                          currentVisibleIndex === index
                            ? theme.colors?.utility[400]
                            : theme.colors?.utility[600],
                        transform:
                          currentVisibleIndex === index ? 'scale(1.5)' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                      }}
                    />
                  );
                })}
              </Box>
              <IconButton
                onClick={() => {
                  setRenderedWidth(0);
                  setCurrentVisibleIndex((prev) =>
                    prev === (urlMetadata.metadata?.medias || []).length - 1
                      ? 0
                      : prev + 1,
                  );
                }}
                disableRipple
                sx={{
                  color: theme.colors?.primary.white,
                  transform: 'rotate(180deg)',
                }}
              >
                <IconOutlineArrowLeft size={24} />
              </IconButton>
            </Box>
          )}

          {urlMetadata.metadata?.medias &&
            currentMedia &&
            currentMedia.type === 'video' && (
              <Box
                height="100%"
                width="fit-content"
                sx={{
                  overflow: 'hidden',
                  borderBottomLeftRadius: theme.spacing(4),
                  borderBottomRightRadius: theme.spacing(4),
                }}
              >
                <CustomVideo
                  alwaysShowControls={false}
                  scaleMultiplier={0.8}
                  annotations={[]}
                  canAnnotate={false}
                  videoUrl={currentMedia.url || ''}
                  videoStyle={{
                    borderRadius: theme.spacing(4.5),
                    overflow: 'hidden',
                  }}
                  onRenderDimensions={({ width, height }) => {
                    if (width && height && currentMedia.type === 'video') {
                      setRenderedWidth(width);
                    }
                  }}
                  componentProps={{
                    wrapper: {
                      sx: {
                        width: 'inherit',
                        '& .fullscreen': {
                          width: 'inherit',
                        },
                      },
                    },
                  }}
                />
              </Box>
            )}

          {currentMedia && currentMedia.type === 'image' && (
            <CustomSocialMediaImage
              imageName="test"
              imageUrl={currentMedia.url}
              onRenderDimensions={({ width, height }) => {
                if (width && height) {
                  setRenderedWidth(width);
                }
              }}
            />
          )}

          {urlMetadata.metadata?.site === 'Instagram' && (
            <Box
              sx={{
                position: 'absolute',
                right: theme.spacing(4),
                top: theme.spacing(4),
              }}
            >
              <IconCustomInstagram />
            </Box>
          )}
          {urlMetadata.metadata?.site === 'TikTok' && (
            <Box
              sx={{
                position: 'absolute',
                right: theme.spacing(4),
                top: theme.spacing(4),
              }}
            >
              <IconCustomTiktok />
            </Box>
          )}

          <Box
            sx={{
              color: theme.colors?.utility[900],
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 2,
              width:
                currentMedia?.type === 'video' && renderedWidth
                  ? `${renderedWidth}px`
                  : '100%',
              mx: 'auto',
            }}
          >
            <Box display="flex" justifyContent="space-between" mr={2}>
              <Box display="flex" gap={1} alignItems="center">
                {urlMetadata.metadata?.author && (
                  <Typography
                    variant="body-xl"
                    fontWeight={600}
                    color={theme.colors?.primary.black}
                  >
                    {urlMetadata.metadata?.author}
                  </Typography>
                )}
              </Box>
              <Box display="flex" gap={2} alignItems="center">
                <Box style={{ cursor: 'pointer' }} onClick={onCopy}>
                  <Box
                    display="flex"
                    p={1.5}
                    sx={{
                      borderRadius: theme.spacing(10),
                      backgroundColor: theme.colors?.utility[400],
                    }}
                    onClick={() =>
                      triggerCommand(COMMAND_TYPE.POST_DOWNLOAD, {
                        posts: [post],
                        canDownloadSinglePost: true,
                      })
                    }
                  >
                    <IconOutlineArrowDownload size={16} />
                  </Box>
                </Box>

                <Tooltip title={hasCopied ? 'Copied' : 'Copy'}>
                  <Box style={{ cursor: 'pointer' }} onClick={onCopy}>
                    <Box
                      display="flex"
                      p={1.5}
                      sx={{
                        borderRadius: theme.spacing(10),
                        backgroundColor: theme.colors?.utility[400],
                        transform: 'rotate(-35deg)',
                      }}
                    >
                      <IconOutlineLink size={16} />
                    </Box>
                  </Box>
                </Tooltip>

                <Box
                  component="a"
                  href={urlMetadata.url}
                  target="_blank"
                  style={{ cursor: 'pointer' }}
                >
                  <Box
                    display="flex"
                    p={1.5}
                    sx={{
                      borderRadius: theme.spacing(10),
                      backgroundColor: theme.colors?.utility[400],
                    }}
                  >
                    <IconLinearExport2 size={16} />
                  </Box>
                </Box>
              </Box>
            </Box>
            {urlMetadata.metadata?.title && currentMedia && (
              <Box sx={{ display: 'flex', gap: 3, overflow: 'hidden' }}>
                <Box
                  display="flex"
                  gap={1}
                  alignItems="center"
                  width="100%"
                  overflow="hidden"
                >
                  <CustomOverflowTitle
                    currentUrl={currentMedia?.url || ''}
                    title={urlMetadata.metadata?.title}
                    onShowMore={() => {
                      window.open(urlMetadata.url, '_blank');
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    urlMetadata,
    renderedWidth,
    hasCopied,
    onCopy,
    currentVisibleIndex,
    currentMedia,
  ]);

  return { renderSocialMediaContent };
};
