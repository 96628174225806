import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { avatarBgColors } from 'features/accessRequest/constants';
import {
  UserFragmentAccessRequestAdminListFragment,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

export const USER_FRAGMENT_ACCESS_REQUEST_ADMIN_LIST = gql`
  fragment UserFragmentAccessRequestAdminList on UserProfileModel {
    ...UserFragmentAvatarGroup
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export interface AccessRequestAdminListProps {
  admins: UserFragmentAccessRequestAdminListFragment[];
}

export const AccessRequestAdminList = ({
  admins,
}: AccessRequestAdminListProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {admins.map((admin) => {
        const colorRandomIndex = Math.floor(
          Math.random() * avatarBgColors.length,
        );
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Avatar
              user={admin}
              size={20}
              avatarSx={{
                bgcolor: avatarBgColors[colorRandomIndex],
                border: `0.83px solid rgba(250, 243, 236, 0.50) !important`,
              }}
            />
            <Typography
              variant="headline-sm"
              color={theme.colors?.primary.black}
            >
              {getFullName(admin)}
            </Typography>
            <Typography
              variant="subhead-xl"
              color={theme.colors?.primary.black}
            >
              ({admin.email})
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
