import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  CollectionFragmentSelectModeFragment,
  PostFragmentSelectModeFragment,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentSelectMode on CollectionModel {
    id
  }
`;

// eslint-disable-next-line
gql`
  fragment PostFragmentSelectMode on PostModel {
    id
  }
`;

type Props = {
  collections?: CollectionFragmentSelectModeFragment[];
  posts?: PostFragmentSelectModeFragment[];
  disabled?: boolean;
};

export const useSelectMode = (props: Props) => {
  const { collections = [], posts = [], disabled } = props;

  const { collectionId = '' } = useParams();
  const location = useLocation();
  const initialPathnameRef = useRef(location.pathname);

  const analytics = useAnalytics();

  const [selectedPostIds, setSelectedPostIds] = useState<string[]>([]);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
    [],
  );

  const hasSelected =
    selectedPostIds.length > 0 || selectedCollectionIds.length > 0;

  const {
    isOpen: isSelectModeActive,
    onOpen: enterSelectMode,
    onClose: _exitSelectMode,
  } = useDisclosure();

  const exitSelectMode = () => {
    if (isSelectModeActive) {
      // === Analytics ===
      analytics.track(EventName.PostMultiSelectExited, {
        collectionId,
        selectedPostCount: selectedPostIds.length,
        selectedCollectionCount: selectedCollectionIds.length,
      });
      // === End of Analytics ===
    }

    setSelectedPostIds([]);
    setSelectedCollectionIds([]);
    _exitSelectMode();
  };

  // Only send tracking event of select mode starting when there's at least 1 selected post
  useEffect(() => {
    if (hasSelected) {
      // === Analytics ===
      analytics.track(EventName.PostMultiSelectEntered, {
        collectionId,
        selectedPostCount: selectedPostIds.length,
        selectedCollectionCount: selectedCollectionIds.length,
      });
      // === End of Analytics ===
    }
  }, [hasSelected]); // eslint-disable-line

  // Listen to meta key to toggle select mode
  useEffect(() => {
    if (disabled) {
      return;
    }

    // Do nothing if this is being called from a background location
    // or from a location that doesn't match the initial pathname where it was first loaded
    if (location.pathname !== initialPathnameRef.current) {
      return;
    }

    const onKeyDown = (e: KeyboardEvent) => {
      // Ignore if target is not body
      // TODO: Apply this to other global (window) keyboard event listeners. Rn we are mostly checking
      // for input, which is not enough
      if (e.target !== document.body) {
        return;
      }

      if (e.key === 'Meta' || e.key === 'Control') {
        enterSelectMode();
      }

      // Exit select mode and clear selection if escape key is pressed
      if (e.key === 'Escape') {
        exitSelectMode();
        setSelectedPostIds([]);
        setSelectedCollectionIds([]);
      }

      // If we see combo of meta key and "A", select all posts
      if ((e.metaKey || e.ctrlKey) && e.key === 'a') {
        e.preventDefault();
        setSelectedPostIds(posts.map((p) => p.id));
        setSelectedCollectionIds(collections.map((c) => c.id));
      }
    };

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Meta' || e.key === 'Control') {
        // Exit select mode if there's no selected post
        if (
          selectedPostIds.length === 0 &&
          selectedCollectionIds.length === 0
        ) {
          exitSelectMode();
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [selectedPostIds, selectedCollectionIds, disabled, location.pathname]); // eslint-disable-line

  const onSelectPost = (
    post: PostFragmentSelectModeFragment,
    shiftKey = false,
  ) =>
    setSelectedPostIds((o) => {
      if (o.includes(post.id)) {
        const newO = o.filter((id) => id !== post.id);

        return newO;
      }

      // Sort selected post ids by their index in the posts array
      let newO = [...o, post.id].sort(
        (a, b) =>
          posts.findIndex((p) => p.id === a) -
          posts.findIndex((p) => p.id === b),
      );

      // If shift key is pressed, select all posts in between the first selected post and the current post
      if (shiftKey) {
        const firstSelectedPostIndex = posts.findIndex((p) => p.id === newO[0]);
        const currentPostIndex = posts.findIndex((p) => p.id === post.id);

        const postsInBetween = posts.slice(
          Math.min(firstSelectedPostIndex, currentPostIndex),
          Math.max(firstSelectedPostIndex, currentPostIndex) + 1,
        );

        newO = postsInBetween.map((p) => p.id);
      }

      return newO;
    });

  const onSelectCollection = (
    collection: CollectionFragmentSelectModeFragment,
    shiftKey = false,
  ) =>
    setSelectedCollectionIds((o) => {
      if (o.includes(collection.id)) {
        const newO = o.filter((id) => id !== collection.id);

        return newO;
      }

      // Sort selected post ids by their index in the collections array
      let newO = [...o, collection.id].sort(
        (a, b) =>
          collections.findIndex((p) => p.id === a) -
          collections.findIndex((p) => p.id === b),
      );

      // If shift key is pressed, select all posts in between the first selected post and the current post
      if (shiftKey) {
        const firstSelectedPostIndex = collections.findIndex(
          (p) => p.id === newO[0],
        );
        const currentPostIndex = collections.findIndex(
          (p) => p.id === collection.id,
        );

        const collectionsInBetween = collections.slice(
          Math.min(firstSelectedPostIndex, currentPostIndex),
          Math.max(firstSelectedPostIndex, currentPostIndex) + 1,
        );

        newO = collectionsInBetween.map((p) => p.id);
      }

      return newO;
    });

  return {
    selectedPostIds,
    selectedCollectionIds,
    isSelectModeActive,
    onSelectPost,
    onSelectCollection,
    exitSelectMode,
    enterSelectMode,
    setSelectedPostIds,
  };
};
