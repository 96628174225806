import { gql } from '@apollo/client';
import { Box, Tooltip, Typography } from '@mui/material';
import { useVideoPlayer } from 'components/common/Media/components/Video/hooks/useVideoPlayer';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { SocialPostFragmentUseRenderSocialListeningPostVideoFragment } from 'graphql/generated';
import { useEffect } from 'react';
import { theme } from 'styles/theme';

type Props = {
  post?: SocialPostFragmentUseRenderSocialListeningPostVideoFragment;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentUseRenderSocialListeningPostVideo on SocialPostModel {
    videoUrl
    socialPostEngagementSuggestions {
      id
      oneLineSummary
      highlights {
        end
        start
        highlightSummary
      }
    }
  }
`;

export const useRenderSocialListeningPostVideo = ({ post }: Props) => {
  const {
    currentTime,
    videoUI,
    videoHeight,
    controls: videoControls,
    UI: { setRenderVideoTimeMarkers },
  } = useVideoPlayer({
    videoUrl: post?.videoUrl || '',
  });

  useEffect(() => {
    if (!post || !post.socialPostEngagementSuggestions?.[0]?.highlights) {
      return;
    }

    setRenderVideoTimeMarkers(
      post.socialPostEngagementSuggestions[0].highlights?.map((highlight) => {
        return {
          timeInSeconds: highlight.start / 1000,
          renderUI: () => (
            <Tooltip
              title={
                <Box
                  sx={{
                    maxWidth: theme.spacing(80),
                    color: theme.colors?.primary.parchment,
                  }}
                >
                  <Typography variant="body-lg">
                    {highlight.highlightSummary}
                  </Typography>
                </Box>
              }
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    transition: 'none',
                    p: 2,
                    borderRadius: theme.spacing(3),
                    background: 'rgba(35, 6, 3, 0.20)',
                    backdropFilter: 'blur(37.5px)',
                  },
                },
              }}
            >
              <Box
                onClick={() => {
                  videoControls.seekTime(highlight.start / 1000);
                }}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  backgroundColor: 'rgba(35, 6, 3, 0.30)',
                  backdropFilter: 'blur(2px)',
                  borderRadius: theme.spacing(25),
                  p: 1,
                  mb: 1,
                  color: theme.colors?.utility['yellow-1'],
                }}
              >
                <IconCustomSparkles size={14} />
              </Box>
            </Tooltip>
          ),
        };
      }),
    );
  }, [post]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const renderVideoPlayer = () => <>{videoUI}</>;

  const seekVideoTime = (time: number) => {
    videoControls.seekTime(time);
  };

  return {
    renderVideoPlayer,
    seekVideoTime,
    currentVideoTime: currentTime,
    renderedVideoHeight: videoHeight,
  };
};
