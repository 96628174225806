import { TrendCategory, TrendStatus } from 'graphql/generated';
import { theme } from 'styles/theme';

export const getTrendCategoryLabel = (category: TrendCategory) => {
  switch (category) {
    case TrendCategory.Music:
      return 'Music';
    case TrendCategory.Voiceover:
      return 'Voiceover';
    case TrendCategory.VideoStructure:
      return 'Video Structure';
    case TrendCategory.PopCulture:
      return 'Pop Culture';
    default:
      return 'Nonsense';
  }
};

export const getTrendCategoryIcon = (category: TrendCategory) => {
  switch (category) {
    case TrendCategory.Music:
      return '🎶';
    case TrendCategory.Voiceover:
      return '🎙️';
    case TrendCategory.VideoStructure:
      return '🎥';
    case TrendCategory.PopCulture:
      return '🍿';
    default:
      return '🤷️';
  }
};

export const getTrendCategoryStyles = (category: TrendCategory) => {
  switch (category) {
    case TrendCategory.Music:
      return {
        backgroundColor: theme.colors?.utility['blue-1-new'],
        color: theme.colors?.utility.blueberry,
        cancelIconColor: theme.colors?.utility['blue-4'],
      };
    case TrendCategory.Voiceover:
      return {
        backgroundColor: theme.colors?.utility['purple-1'],
        color: theme.colors?.utility['purple-4'],
        cancelIconColor: theme.colors?.utility['purple-2'],
      };
    case TrendCategory.VideoStructure:
      return {
        backgroundColor: theme.colors?.utility['green-1'],
        color: theme.colors?.utility['green-4'],
        cancelIconColor: theme.colors?.utility['pale-olive'],
      };
    case TrendCategory.PopCulture:
      return {
        backgroundColor: theme.colors?.utility['orange-1'],
        color: theme.colors?.utility['orange-4'],
        cancelIconColor: theme.colors?.utility['orange-2'],
      };
    default:
      return {
        backgroundColor: theme.colors?.utility['yellow-1'],
        color: theme.colors?.utility['yellow-4-new'],
        cancelIconColor: theme.colors?.utility['yellow-2-new'],
      };
  }
};

export const getTrendingStatusText = (status: TrendStatus) => {
  switch (status) {
    case TrendStatus.Rising:
      return '📈 Rising';
    case TrendStatus.Falling:
      return '📉 Falling';
    case TrendStatus.Hot:
      return '🔥 Hot';
    default:
      return '';
  }
};

export const PLACEHOLDER_BACKGROUND_COLORS = [
  theme.colors?.utility['purple-1'],
  theme.colors?.utility['orange-1'],
  theme.colors?.utility['teal-1'],
];
