import { gql } from '@apollo/client';
import { Box, PopoverProps, SxProps, Typography } from '@mui/material';
import { AvatarGroup } from 'components/common/AvatarGroup';
import { USER_FRAGMENT_AVATAR_GROUP } from 'components/common/AvatarGroup/types';
import { Tooltip } from 'components/common/Tooltip';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { IconBoldUser } from 'components/icons/components/bold/IconBoldUser';
import { useUserContext } from 'contexts/users/User.context';
import {
  TaskFragmentTaskAssigneeFragment,
  UserFragmentAvatarGroupFragment,
  useUpdateTaskForTaskAssigneeMutation,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { TaskAssigneeSelectItem } from './TaskAssigneeSelectItem';

export const TASK_FRAGMENT_TASK_ASSIGNEE = gql`
  fragment TaskFragmentTaskAssignee on TaskModel {
    id
    taskMembers {
      ...UserFragmentAvatarGroup
    }
    ${USER_FRAGMENT_AVATAR_GROUP}
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskAssignee($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      ...TaskFragmentTaskAssignee
    }
  }
  ${TASK_FRAGMENT_TASK_ASSIGNEE}
`;

export type TaskAssigneeProps = {
  task: TaskFragmentTaskAssigneeFragment;
  readOnly?: boolean;
  sx?: SxProps;
  variant?: 'icon' | 'button' | 'icon-text';
  componentsProps?: {
    popover?: Partial<PopoverProps>;
    icon?: {
      size?: number;
      color?: string;
    };
  };
  renderTrigger?: () => React.ReactElement;
  onChange?: (
    userIds: string[],
    users: UserFragmentAvatarGroupFragment[],
  ) => void;
};

export const TaskAssignee = (props: TaskAssigneeProps) => {
  const {
    task,
    readOnly,
    sx,
    variant = 'icon',
    componentsProps = {},
    renderTrigger,
    onChange: _onChange,
  } = props;

  // TODO: Get this from backend
  const { user } = useUserContext();
  const allUsers = [
    ...(user?.organization.users || []),
    ...(user?.organization.externalUsers || []),
  ];
  const autocompleteOptions = allUsers.map((u) => ({
    label: `${getFullName(u)} ${u.email}`,
    value: u.id,
  }));
  const isMobileView = useMediaQueryMobile();

  const [updateTask] = useUpdateTaskForTaskAssigneeMutation();

  const onChange =
    _onChange ||
    ((value: string[]) => {
      updateTask({
        variables: {
          data: {
            taskId: task.id,
            data: {
              memberIds: value,
            },
          },
        },
        optimisticResponse: {
          updateTask: {
            ...task,
            taskMembers: allUsers.filter((u) => value.includes(u.id)),
          },
        },
      });
    });

  // TODO: Move this into a separate component (similar to TaskPriorityLabel & TaskPriorityButton)
  const renderTriggerButton = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        p={theme.spacing(2, 2.5)}
        gap={2}
        borderRadius={3}
        whiteSpace="nowrap"
        bgcolor={theme.colors?.utility[250]}
        onClick={(e) => {
          e.preventDefault();
        }}
        sx={sx}
      >
        {task.taskMembers.length === 0 ? (
          <>
            <IconBoldUser
              size={componentsProps?.icon?.size || 16}
              color={componentsProps?.icon?.color}
            />
            <Typography variant="body-xl" color={theme.colors?.utility[900]}>
              Assignees
            </Typography>
          </>
        ) : (
          <AvatarGroup
            avatarSize={componentsProps?.icon?.size || 16}
            users={task.taskMembers}
            max={isMobileView ? 2 : 3}
          />
        )}
      </Box>
    );
  };

  const renderTriggerIconText = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        p={theme.spacing(2, 2.5)}
        gap={2}
        borderRadius={3}
        whiteSpace="nowrap"
        bgcolor={theme.colors?.utility[250]}
        onClick={(e) => {
          e.preventDefault();
        }}
        sx={sx}
      >
        {task.taskMembers.length === 0 ? (
          <>
            <IconBoldUser
              size={componentsProps?.icon?.size || 16}
              color={componentsProps?.icon?.color}
            />
            <Typography variant="body-xl" color={theme.colors?.utility[900]}>
              Assignees
            </Typography>
          </>
        ) : (
          <>
            <AvatarGroup
              avatarSize={componentsProps?.icon?.size || 16}
              users={task.taskMembers}
              max={isMobileView ? 2 : 3}
            />
            {task.taskMembers.length === 1 && (
              <Typography variant="body-xl" color={theme.colors?.utility[900]}>
                {getFullName(task.taskMembers[0])}
              </Typography>
            )}
          </>
        )}
      </Box>
    );
  };

  // TODO: Move this into a separate component (similar to TaskPriorityLabel & TaskPriorityButton)
  const renderTriggerIcon = () => {
    return task.taskMembers.length === 0 ? (
      <Tooltip title="Add people to your task" placement="top">
        <Box display="flex" sx={sx}>
          <IconBoldUser
            style={{ cursor: 'pointer' }}
            size={componentsProps?.icon?.size || 16}
            color={componentsProps?.icon?.color}
          />
        </Box>
      </Tooltip>
    ) : (
      <Box display="flex" gap={1} alignItems="center" sx={sx}>
        <AvatarGroup
          avatarSize={componentsProps?.icon?.size || 16}
          users={task.taskMembers}
          max={isMobileView ? 2 : 3}
        />
      </Box>
    );
  };

  return (
    <AutocompletePopup
      readOnly={readOnly}
      multiple
      value={
        autocompleteOptions.filter((option) =>
          task.taskMembers.some((member) => member.id === option.value),
        ) || undefined
      }
      options={autocompleteOptions}
      renderTrigger={() =>
        renderTrigger !== undefined
          ? renderTrigger()
          : variant === 'icon'
          ? renderTriggerIcon()
          : variant === 'icon-text'
          ? renderTriggerIconText()
          : renderTriggerButton()
      }
      onChange={(_, value) => {
        if (Array.isArray(value)) {
          onChange(
            value.map((v) => v.value),
            allUsers.filter((u) => value.map((v) => v.value).includes(u.id)),
          );
        } else {
          onChange(value?.value ? [value?.value] : [], [
            allUsers.find(
              (u) => u.id === value?.value,
            ) as UserFragmentAvatarGroupFragment,
          ]);
        }
      }}
      renderOption={(props, option, state) => {
        const assignee = allUsers.find((u) => u.id === option.value);

        if (!assignee) {
          return null;
        }

        return (
          <TaskAssigneeSelectItem
            {...props}
            user={assignee}
            selected={state.selected}
          />
        );
      }}
      placeholder="Add task owner..."
      popoverProps={componentsProps.popover}
      // TODO: Add this back in
      //   // eslint-disable-next-line react/jsx-no-useless-fragment
      //   <>
      //     {onInviteNewUsers && (
      //       <Box
      //         component="button"
      //         display="flex"
      //         gap={2}
      //         alignItems="center"
      //         px={1}
      //         py={2}
      //         onClick={onInviteNewUsers}
      //         width="100%"
      //       >
      //         <IconOutlineAddCircle size={20} />
      //         <Typography variant="headline-xs">Invite new user</Typography>
      //       </Box>
      //     )}
      //   </>
      // }
    />
  );
};
