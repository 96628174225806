import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { RichTextEditor } from 'components/common/form/RichTextEditor';
import {
  RichTextEditorProps,
  RichTextEditorRef,
} from 'components/common/form/RichTextEditor/RichTextEditor';
import { getUseEditorExtensionsPropsByVariant } from 'features/tiptap';
import {
  TaskFragmentTaskNameFragment,
  useUpdateTaskNameMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export const TASK_FRAGMENT_TASK_NAME = gql`
  fragment TaskFragmentTaskName on TaskModel {
    id
    name
    parentTask {
      id
      name
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskName($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      id
      name
    }
  }
`;

export type TaskNameProps = {
  task: TaskFragmentTaskNameFragment;

  /**
   * If this is true,
   * task name will also include the parent task's name
   */
  includeParentTaskName?: boolean;

  /**
   * If this is provided, it will be called when the task name is changed
   * instead of the default mutation.
   */
  onChange?: (name: string) => void;
  sx?: SxProps;
} & RichTextEditorProps;

export const TaskName = forwardRef(
  (props: TaskNameProps, ref: ForwardedRef<RichTextEditorRef>) => {
    const {
      task,
      includeParentTaskName,
      onChange: _onChange,
      sx,
      ...rest
    } = props;

    const [updateTask] = useUpdateTaskNameMutation();

    const onChange = useMemo(
      () =>
        _onChange ||
        debounce((value: string) => {
          updateTask({
            variables: {
              data: {
                taskId: task.id,
                data: {
                  name: value,
                },
              },
            },
            optimisticResponse: {
              updateTask: {
                id: task.id,
                __typename: 'TaskModel',
                name: value,
              },
            },
          });
        }, 300),
      [task.id, _onChange, updateTask],
    );

    /**
     * These logic should only be used when includeParentTaskName is true
     */
    const [isFocused, setIsFocused] = useState(false);
    const isSubtask = Boolean(task.parentTask);

    // FIXME: This is a hack to trigger re-rendering when the task name changes from OUTSIDE
    // We have to do this because the RichTextEditor doesn't re-render when the defaultContent changes
    const internalNameRef = useRef(task.name);
    const [renderKey, setRenderKey] = useState(Date.now());

    useEffect(() => {
      if (internalNameRef.current !== task.name) {
        internalNameRef.current = task.name;
        setRenderKey(Date.now());
      }
    }, [task.name]);

    return (
      <Box
        sx={{
          position: 'relative',
          '& p': { margin: 0 },
          ...sx,
        }}
      >
        <RichTextEditor
          key={renderKey}
          ref={ref}
          defaultContent={task.name}
          placeholder="Task... #link a collection, @mention someone"
          hideToolBar
          borderless
          onChange={(value) => {
            if (value.text && value.html !== task.name) {
              let finalHtml = value.html;

              // NOTE: This is very hacky right now,
              // but we have to do this because we want to keep the html content output as the same as
              // the task list in the note.

              // Trim the html content:
              // Remove all all <p> or </p> tags
              finalHtml = finalHtml.replaceAll(/<p>/g, '');
              finalHtml = finalHtml.replaceAll(/<\/p>/g, '');

              // ...and extract the part between <<span style="font-family: TT Commons Pro">>...</span>
              // with regex
              finalHtml = finalHtml.replace(
                /<span style="font-family: TT Commons Pro">(.*)<\/span>/,
                '$1',
              );

              onChange(finalHtml);
              internalNameRef.current = finalHtml;
            }
          }}
          enableBubbleMenu={false}
          {...rest}
          editorProps={{
            handleKeyDown(_, event) {
              // Ignore Enter and Tab keys
              if (event.key === 'Enter' || event.key === 'Tab') {
                return true;
              }

              return false;
            },
            ...rest.editorProps,
          }}
          useEditorExtensionsProps={{
            ...getUseEditorExtensionsPropsByVariant('inline'),
            ...rest.useEditorExtensionsProps,
          }}
          onFocus={(e) => {
            rest.onFocus?.(e);
            setIsFocused(true);
          }}
          onBlur={(e) => {
            rest.onBlur?.(e);
            setIsFocused(false);
          }}
        />
        {isSubtask && includeParentTaskName && !isFocused && (
          <Box
            sx={{ opacity: 0.5, ...rest.style }}
            dangerouslySetInnerHTML={{
              __html: `/ ${task.parentTask?.name}` ?? '',
            }}
          />
        )}
      </Box>
    );
  },
);
