import { Masonry } from '@mui/lab';
import { Box } from '@mui/material';
import {
  CUSTOM_COLLECTION,
  CollectionCardView,
  CollectionCardViewSkeleton,
} from 'features/collection';
import { CollectionModel } from 'graphql/generated';
import { useCollectionQueryForContentCalendar } from 'pages/collections/detail/hooks';
import InfiniteScroll from 'react-infinite-scroller';

type ContentCalendarCustomCollectionMasonrySectionProps = {
  collectionId: string;
  onCollectionClick: (collection: Pick<CollectionModel, 'id' | 'name'>) => void;
};

export const ContentCalendarCustomCollectionMasonrySection = (
  props: ContentCalendarCustomCollectionMasonrySectionProps,
) => {
  const { collectionId, onCollectionClick } = props;

  // NOTE: Reusing query from Collection page
  const {
    collectionData,
    fetchMoreContentIdeaCollections,
    isCollectionDataLoading,
  } = useCollectionQueryForContentCalendar({
    skip: collectionId !== CUSTOM_COLLECTION.CONTENT_CALENDAR,
  });
  const collections = collectionData?.contentIdeaCollections.data || [];
  const hasNextPage =
    collectionData?.contentIdeaCollections.pageInfo.hasNextPage || false;

  if (isCollectionDataLoading) {
    return (
      <Box pt={8}>
        <Masonry
          columns={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
          }}
          spacing={4}
        >
          {[...Array(6)].map((_, index) => (
            <CollectionCardViewSkeleton key={index} />
          ))}
        </Masonry>
      </Box>
    );
  }

  return (
    <InfiniteScroll
      hasMore={hasNextPage}
      initialLoad={false}
      loadMore={fetchMoreContentIdeaCollections}
      threshold={1024}
      useWindow={false}
      getScrollParent={() =>
        document.querySelector(
          '.collection-cjb-window-view-scrollable-container',
        )
      }
    >
      <Box sx={{ mr: -4 }}>
        <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={4}>
          {collections.map((collection) => {
            return (
              <CollectionCardView
                key={collection.id}
                collection={collection}
                onClick={() => onCollectionClick(collection)}
                componentsProps={{
                  link: false,
                  multiPostPreview: {
                    variant: 'card-stack-alt',
                  },
                }}
              />
            );
          })}
        </Masonry>
      </Box>
    </InfiniteScroll>
  );
};
