import { Components, Theme } from '@mui/material';

export const radioOverrides: Components<Theme>['MuiRadio'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        borderRadius: 16,
        '&.Mui-checked': {
          color: theme.colors?.primary.maroon,
        },
        '&.MuiCheckbox-indeterminate': {
          color: theme.colors?.utility[800],
        },
        '&.Mui-disabled': {
          color: theme.colors?.utility[500],
        },
      };
    },
  },
};
