import { gql } from '@apollo/client';
import { SxProps, TableCell } from '@mui/material';
import { CollectionMultiPostPreview } from 'features/collection/components';
import {
  CollectionFragmentCollectionMultiPostPreviewFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowCellPreviewFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentJuiceboxTableCollectionTableRowCellPreview on CollectionModel {
    id
    isDisabled
    ...CollectionFragmentCollectionMultiPostPreview
  }
  ${CollectionFragmentCollectionMultiPostPreviewFragmentDoc}
`;

export type JuiceboxTableCollectionTableRowCellPreviewProps = {
  collection: CollectionFragmentJuiceboxTableCollectionTableRowCellPreviewFragment;
  sx?: SxProps;
};

export const JuiceboxTableCollectionTableRowCellPreview = (
  props: JuiceboxTableCollectionTableRowCellPreviewProps,
) => {
  const { collection, sx = {} } = props;

  return (
    <TableCell
      sx={{
        opacity: collection.isDisabled ? 0.4 : 1,
        ...sx,
      }}
    >
      <CollectionMultiPostPreview
        collection={collection}
        variant="card"
        sx={{
          height: '50px',
        }}
        componentProps={{
          card: {
            sx: {
              borderRadius: theme.spacing(1),
              border: 'none',
            },
          },
        }}
      />
    </TableCell>
  );
};
