import '@mui/material/styles';

export const colors = {
  primary: {
    maroon: 'var(--primary-maroon)',
    black: 'var(--primary-black)',
    parchment: 'var(--primary-parchment)',
    white: 'var(--primary-white)',
  },
  secondary: {
    'dark-cream': 'var(--secondary-dark-cream)',
    'light-cream': 'var(--secondary-light-cream)',
    'pale-maroon': 'var(--secondary-pale-maroon)',
    'dark-maroon': 'var(--secondary-dark-maroon)',
  },
  utility: {
    50: 'var(--utility-50)',
    100: 'var(--utility-100)',
    200: 'var(--utility-200)',
    250: 'var(--utility-250)',
    275: 'var(--utility-275)',
    300: 'var(--utility-300)',
    400: 'var(--utility-400)',
    500: 'var(--utility-500)',
    600: 'var(--utility-600)',
    700: 'var(--utility-700)',
    800: 'var(--utility-800)',
    900: 'var(--utility-900)',
    1000: 'var(--utility-1000)',
    1100: 'var(--utility-1100)',

    yellow: 'var(--utility-yellow)',
    'light-yellow': 'var(--utility-light-yellow)',
    'dark-yellow': 'var(--utility-dark-yellow)',
    'yellow-1': 'var(--utility-yellow-1)',
    'yellow-2': 'var(--utility-yellow-2)',
    'yellow-2-new': 'var(--utility-yellow-2-new)',
    'yellow-3': 'var(--utility-yellow-3)',
    'yellow-3-new': 'var(--utility-yellow-3-new)',
    'yellow-4': 'var(--utility-yellow-4)',
    'yellow-4-new': 'var(--utility-yellow-4-new)',
    'yellow-5': 'var(--utility-yellow-5)',
    'yellow-6': 'var(--utility-yellow-6)',
    'yellow-7': 'var(--utility-yellow-7)',
    'yellow-8': 'var(--utility-yellow-8)',

    green: 'var(--utility-green)',
    'light-green': 'var(--utility-light-green)',
    'dark-green': 'var(--utility-dark-green)',
    'green-1': 'var(--utility-green-1)',
    'green-2': 'var(--utility-green-2)',
    'green-3': 'var(--utility-green-3)',
    'green-3-new': 'var(--utility-green-3-new)',
    'green-4': 'var(--utility-green-4)',

    red: 'var(--utility-red)',
    'light-red': 'var(--utility-light-red)',
    'red-1': 'var(--utility-red-1)',
    'red-4': 'var(--utility-red-4)',

    orange: 'var(--utility-orange)',
    'light-orange': 'var(--utility-light-orange)',
    'orange-500': 'var(--utility-orange-500)',
    'orange-1': 'var(--utility-orange-1)',
    'orange-3': 'var(--utility-orange-3)',
    'orange-2': 'var(--utility-orange-2)',
    'orange-4': 'var(--utility-orange-4)',

    cultured: 'var(--utility-cultured)',
    'light-cultured': 'var(--utility-light-cultured)',

    blue: 'var(--utility-blue)',
    'blue-1': 'var(--utility-blue-1)',
    'blue-2': 'var(--utility-blue-2)',
    'blue-3': 'var(--utility-blue-3)',
    'blue-4': 'var(--utility-blue-4)',
    'blue-1-new': 'var(--utility-blue-1-new)',
    'blue-3-new': 'var(--utility-blue-3-new)',

    'pink-1': 'var(--utility-pink-1)',
    'pink-2': 'var(--utility-pink-2)',
    'pink-3': 'var(--utility-pink-3)',
    'pink-4': 'var(--utility-pink-4)',

    eggplant: 'var(--utility-eggplant)',

    blueberry: 'var(--utility-blueberry)',
    'blueberry-light': 'var(--utility-blueberry-light)',

    grape: 'var(--utility-grape)',
    'grape-light': 'var(--utility-grape-light)',

    olive: 'var(--utility-olive)',
    'olive-light': 'var(--utility-olive-light)',
    'pale-olive': 'var(--utility-pale-olive)',

    'black-currant': 'var(--utility-black-currant)',

    tamarind: 'var(--utility-tamarind)',
    'tamarind-light': 'var(--utility-tamarind-light)',

    turnip: 'var(--utility-turnip)',
    'turnip-light': 'var(--utility-turnip-light)',

    chard: 'var(--utility-chard)',
    'chard-light': 'var(--utility-chard-light)',

    'purple-1': 'var(--utility-purple-1)',
    'purple-2': 'var(--utility-purple-2)',
    'purple-3': 'var(--utility-purple-3)',
    'purple-4': 'var(--utility-purple-4)',
    'purple-5': 'var(--utility-purple-5)',

    'teal-1': 'var(--utility-teal-1)',
    'teal-2': 'var(--utility-teal-2)',
    'teal-3': 'var(--utility-teal-3)',
    'teal-4': 'var(--utility-teal-4)',

    'highlight-opacity': 'var(--utility-highlight-opacity)',
  },
};

// Extend Theme properties with colors
// We will not be overriding Palette here because the data structure
// for Palette doesn't fit our design system
// https://mui.com/material-ui/customization/theming/#custom-variables
declare module '@mui/material/styles' {
  interface Theme {
    colors?: typeof colors;
  }

  interface ThemeOptions {
    colors?: typeof colors;
  }
}
