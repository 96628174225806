import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { useUserContext } from 'contexts/users/User.context';
import { OrganizationBillingFragmentOrganizationMemberPaywallFragment } from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { BillingUpgradeModal } from '../upgradeModal';

type BillingGuestLimitIndicatorProps = {
  organizationBilling: OrganizationBillingFragmentOrganizationMemberPaywallFragment;
  moreMemberUsage?: number;
  variant?: 'banner' | 'component' | 'text';
  customText?: string;
  sx?: SxProps;
  renderNoPaywallHit?: () => ReactNode;
};

export const BillingOrganizationMemberLimitIndicator = (
  props: BillingGuestLimitIndicatorProps,
) => {
  const {
    organizationBilling,
    moreMemberUsage,
    variant = 'text',
    customText,
    sx,
    renderNoPaywallHit,
  } = props;

  const navigate = useGuardNavigate();

  const {
    isOpen: upgradeModalOpen,
    onOpen: upgradeModalOnOpen,
    onClose: upgradeModalOnClose,
  } = useDisclosure();

  const { setIsAlertBannerVisible } = useUserContext();

  const isOrganizationLimitGreaterThanOne = useMemo(() => {
    return !!(
      organizationBilling.organizationMemberLimit &&
      organizationBilling.organizationMemberLimit > 1
    );
  }, [organizationBilling.organizationMemberLimit]);

  const memberUsage = useMemo(() => {
    if (moreMemberUsage) {
      return moreMemberUsage + organizationBilling.organizationMemberUsage;
    }
    return organizationBilling.organizationMemberUsage;
  }, [moreMemberUsage, organizationBilling.organizationMemberUsage]);

  const isPaywallHit = useMemo(() => {
    return (
      memberUsage !== 0 &&
      (organizationBilling.organizationMemberLimit
        ? memberUsage >= organizationBilling.organizationMemberLimit
        : false)
    );
  }, [organizationBilling.organizationMemberLimit, memberUsage]);

  const [hideBanner, setHideBanner] = useState(false);

  useEffect(() => {
    setIsAlertBannerVisible(
      variant === 'banner' &&
        isPaywallHit &&
        isOrganizationLimitGreaterThanOne &&
        !hideBanner,
    );
  }, [isPaywallHit, hideBanner, variant, isOrganizationLimitGreaterThanOne]);

  const renderVariant = () => {
    if (variant === 'component') {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `solid 1px ${theme.colors?.primary.black}`,
            borderRadius: theme.spacing(2),
            background: theme.colors?.utility['orange-1'],
            padding: theme.spacing(4),
            ...sx,
          }}
        >
          <Typography variant="subhead-xl">
            Can’t add more members as you’ve reached a limit of{' '}
            {organizationBilling.organizationMemberLimit} organization member
            {memberUsage > 1 ? 's' : ''}.{' '}
            <Typography
              variant="subhead-xl"
              style={{
                fontWeight: 500,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={upgradeModalOnOpen}
            >
              {organizationBilling.hasUsedFreeTrial
                ? 'Upgrade Now'
                : 'Start Free Trial'}
            </Typography>{' '}
            or{' '}
            <Typography
              variant="subhead-xl"
              style={{
                fontWeight: 500,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(`${PlotRoutes.members()}#members`);
              }}
            >
              Manage Members
            </Typography>
          </Typography>
        </Box>
      );
    }

    if (variant === 'text') {
      return (
        <Typography variant="body-sm" sx={{ ...sx }}>
          <Typography
            variant="body-sm"
            sx={{
              fontWeight: 600,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={upgradeModalOnOpen}
          >
            {organizationBilling.hasUsedFreeTrial
              ? 'Upgrade Now'
              : 'Start Free Trial'}
          </Typography>{' '}
          {customText ??
            `to add more than ${
              organizationBilling.organizationMemberLimit
            } member${memberUsage > 1 ? 's' : ''}`}
        </Typography>
      );
    }

    if (
      variant === 'banner' &&
      !hideBanner &&
      // we only show the banner when org has more than 1 limit member
      isOrganizationLimitGreaterThanOne
    ) {
      return (
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            width: '100%',
            bgcolor: theme.colors?.utility['orange-1'],
            padding: theme.spacing(6, 0),
            textAlign: 'center',
            position: 'relative',
            ...sx,
          }}
        >
          <Typography variant="subhead-xl">
            You've reached a limit of{' '}
            {organizationBilling.organizationMemberLimit} organization members.{' '}
            <Typography
              variant="subhead-xl"
              sx={{
                fontWeight: 600,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={upgradeModalOnOpen}
            >
              Upgrade
            </Typography>
          </Typography>

          <IconButton
            size="small"
            sx={{ position: 'absolute', right: '11px', top: '17px' }}
            onClick={() => setHideBanner(true)}
          >
            <IconBoldCloseCircle
              color={theme.colors?.utility['yellow-3-new']}
            />
          </IconButton>
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      {isPaywallHit ? (
        <>
          {renderVariant()}
          <BillingUpgradeModal
            isOpen={upgradeModalOpen}
            onClose={upgradeModalOnClose}
          />
        </>
      ) : renderNoPaywallHit ? (
        renderNoPaywallHit()
      ) : null}
    </>
  );
};
