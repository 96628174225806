import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Radio,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening/hooks';
import { HoverPlayableVideo } from 'features/socialMediaListening/views/postsGrid/postCard/HoverPlayableVideo';
import {
  GetSocialPostsCreatedForCompetitorsInput,
  SortOrder,
  useGetSocialPostsCreatedForCompetitorsForCompetitorTopPostsQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { CompetitorTopPostsSkeleton } from './CompetitorTopPostsSkeleton';
import { SocialMediaListeningCompetitorDetailTopPostsProps } from './types';

enum SortKey {
  EngagementRate = 'engagementRate',
  PlayCount = 'playCount',
}

export const CompetitorTopPosts = ({
  competitorId,
  creatorId,
}: SocialMediaListeningCompetitorDetailTopPostsProps) => {
  const [sortKey, setSortKey] = useState(SortKey.EngagementRate);

  const payload: GetSocialPostsCreatedForCompetitorsInput = useMemo(
    () => ({
      competitorId,
      creatorId,
      take: 10,
      sortBy: {
        field: sortKey,
        order: SortOrder.Desc,
      },
    }),
    [competitorId, creatorId, sortKey],
  );

  const {
    data: topSocialPostsData,
    fetchMore,
    loading,
  } = useGetSocialPostsCreatedForCompetitorsForCompetitorTopPostsQuery({
    variables: {
      data: {
        ...payload,
      },
    },
    skip: !creatorId,
  });
  const topPosts =
    topSocialPostsData?.getSocialPostsCreatedForCompetitors.data || [];
  const cursor =
    topSocialPostsData?.getSocialPostsCreatedForCompetitors.pageInfo.endCursor;

  const { generateLink } = useSocialListeningPostLinkGenerator();

  if (loading && !topPosts.length) {
    return <CompetitorTopPostsSkeleton />;
  }

  return (
    <Box
      display="flex"
      p={8}
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 100,
          minHeight: 550,
          overflowY: 'auto',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          mb={4}
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Top posts
          </Typography>

          <ContextMenu
            sx={{
              '& .MuiMenuItem-root': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={[
              {
                renderOption: () => (
                  <Box display="flex" alignItems="center">
                    <Radio checked={sortKey === SortKey.PlayCount} />
                    <Typography variant="body-lg">Play Count</Typography>
                  </Box>
                ),
                onClick: () => {
                  setSortKey(SortKey.PlayCount);
                },
              },
              {
                renderOption: () => (
                  <Box display="flex" alignItems="center">
                    <Radio checked={sortKey === SortKey.EngagementRate} />
                    <Typography variant="body-lg">Engagement Rate</Typography>
                  </Box>
                ),
                onClick: () => {
                  setSortKey(SortKey.EngagementRate);
                },
              },
            ]}
            renderButton={() => (
              <Button
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  gap: 2,
                  p: theme.spacing(1, 2),
                  alignItems: 'center',
                  '&:hover': { backgroundColor: theme.colors?.utility[275] },
                }}
                disableRipple={false}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05);',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <IconLinearSort size={16} />
                </Box>
                <Typography fontWeight={500} variant="body-xl">
                  {sortKey === SortKey.EngagementRate && 'Engagement Rate'}
                  {sortKey === SortKey.PlayCount && 'Play Count'}
                </Typography>
              </Button>
            )}
          />
        </Box>

        {!topPosts.length && (
          <Box display="flex" justifyContent="center">
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[700]}
              textAlign="center"
              mt={8}
            >
              No posts found
            </Typography>
          </Box>
        )}

        {!!topPosts.length && (
          <InfiniteScroll
            loadMore={() => {
              fetchMore({
                variables: {
                  data: {
                    ...payload,
                    after: cursor,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    getSocialPostsCreatedForCompetitors: {
                      ...fetchMoreResult.getSocialPostsCreatedForCompetitors,
                      data: [
                        ...prev.getSocialPostsCreatedForCompetitors.data,
                        ...fetchMoreResult.getSocialPostsCreatedForCompetitors
                          .data,
                      ],
                    },
                  };
                },
              });
            }}
            hasMore={
              topSocialPostsData?.getSocialPostsCreatedForCompetitors.pageInfo
                .hasNextPage
            }
            threshold={200}
            useWindow={false}
          >
            <Grid container spacing={4} columns={20}>
              {topPosts.map((post, index) => (
                <Grid
                  item
                  xs={10}
                  md={5}
                  lg={4}
                  key={post.id + index}
                  component={Link}
                  to={PlotRoutes.socialListeningPost(post.id)}
                >
                  <HoverPlayableVideo
                    platform={post.platform}
                    thumbnailUrl={post.thumbnailUrl || ''}
                    videoUrl={post.videoUrl || ''}
                    renderHoverActionsTopRight={() => (
                      <Box
                        display="flex"
                        gap={2}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        {/* {post.videoUrl && (
                <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                  <IconButton
                    disableRipple
                    sx={{
                      p: 1,
                      transform: 'rotate(-45deg)',
                      backgroundColor: 'rgba(35, 6, 3, 0.10)',
                      color: theme.colors?.primary.white,
                      backdropFilter: 'blur(4px)',
                    }}
                    onClick={onTextCopy}
                  >
                    <IconOutlineLink size={16} />
                  </IconButton>
                </Tooltip>
              )} */}

                        {post.videoUrl && post.creator?.handle && (
                          <IconButton
                            disableRipple
                            sx={{
                              p: 1,
                              backgroundColor: 'rgba(35, 6, 3, 0.10)',
                              color: theme.colors?.primary.white,
                              backdropFilter: 'blur(4px)',
                            }}
                            onClick={() => {
                              window.open(generateLink(post) || '', '_blank');
                            }}
                          >
                            <IconLinearExport2 size={16} />
                          </IconButton>
                        )}
                      </Box>
                    )}
                    renderHoverActionsBottom={() => {
                      return (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <Box
                            display="flex"
                            gap={1}
                            alignItems="center"
                            ml={2}
                            width="70%"
                            maxWidth="70%"
                          >
                            <Avatar
                              src={post.creator?.profilePictureUrl || ''}
                              sx={{
                                width: theme.spacing(4),
                                height: theme.spacing(4),
                              }}
                            />
                            <Typography
                              color={theme.colors?.primary.parchment}
                              variant="body-lg"
                              fontWeight={600}
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {post.creator?.handle}
                            </Typography>
                          </Box>

                          <Box
                            mr={2}
                            display="flex"
                            gap={1}
                            alignItems="center"
                          >
                            <IconBoldPlay
                              size={16}
                              color={theme.colors?.primary.parchment}
                            />
                            <Typography
                              color={theme.colors?.primary.parchment}
                              variant="body-lg"
                              fontWeight={600}
                            >
                              {formatBigNumber(post.playCount || 0)}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
};
