import { gql } from '@apollo/client';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentCollectionCardViewFragmentDoc,
  CollectionFragmentCollectionPermissionSummaryFragmentDoc,
  CollectionFragmentCollectionThumbnailFragmentDoc,
  CollectionFragmentMoveToAnotherCollectionFragment,
  CollectionFragmentMoveToAnotherCollectionFragmentDoc,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const COLLECTION_FRAGMENT_MOVE_TO_ANOTHER_COLLECTION = gql`
  fragment CollectionFragmentMoveToAnotherCollection on CollectionModel {
    ...CollectionFragmentCollectionCardView
    ...CollectionFragmentCollectionBreadcrumbs
    ...CollectionFragmentCollectionThumbnail
    ...CollectionFragmentCollectionPermissionSummary
  }
  ${CollectionFragmentCollectionCardViewFragmentDoc}
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
  ${CollectionFragmentCollectionThumbnailFragmentDoc}
  ${CollectionFragmentCollectionPermissionSummaryFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetCollectionForMoveToAnotherCollection($collectionId: String!) {
    collection(id: $collectionId) {
      id
      ...CollectionFragmentMoveToAnotherCollection
    }
  }
  ${CollectionFragmentMoveToAnotherCollectionFragmentDoc}
`;

export type CollectionMoveToAnotherCollectionProps = {
  collectionId: string;
  isOpen: boolean;
  onClose: VoidFunction;
  onMove: (
    collectionIds: string[],
    toCollection: CollectionFragmentMoveToAnotherCollectionFragment,
  ) => void;
};
