import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  CollectionFragmentUpdateCollectionPermissionAlertViewFragment,
  useIsCollectionPermissionLessThanParentCollectionPermissionLazyQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { UpdateCollectionPermissionAlertView } from '../views';

// eslint-disable-next-line
gql`
  query IsCollectionPermissionLessThanParentCollectionPermission(
    $data: IsCollectionPermissionLessThanParentCollectionPermissionInput!
  ) {
    isCollectionPermissionLessThanParentCollectionPermission(data: $data)
  }
`;

export const useCollectionPermissionUpdateCollectionPermissionToParentCollectionPermission =
  () => {
    const [parentCollection, setParentCollection] =
      useState<CollectionFragmentUpdateCollectionPermissionAlertViewFragment>();

    const [collectionId, setCollectionId] = useState<string>();
    const [onCallback, setOnCallback] = useState<VoidFunction>();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isCollectionPermissionLessThanParentCollectionPermission] =
      useIsCollectionPermissionLessThanParentCollectionPermissionLazyQuery();

    const handleOpenDialog = async (
      collectionId: string,
      parentCollection: CollectionFragmentUpdateCollectionPermissionAlertViewFragment,
      onCallback?: VoidFunction,
    ) => {
      setParentCollection(parentCollection);
      setCollectionId(collectionId);
      setOnCallback(() => onCallback);

      const response =
        await isCollectionPermissionLessThanParentCollectionPermission({
          variables: {
            data: {
              parentCollectionId: parentCollection.id,
              collectionId,
            },
          },
        });

      if (
        response.data?.isCollectionPermissionLessThanParentCollectionPermission
      ) {
        onOpen();
      } else {
        onCallback?.();
      }
    };

    const renderUpdateCollectionPermissionAlert = () => {
      if (!parentCollection || !collectionId) return;

      return !isOpen ? null : (
        <UpdateCollectionPermissionAlertView
          isOpen={isOpen}
          onClose={onClose}
          collectionId={collectionId}
          parentCollection={parentCollection}
          onCallback={onCallback}
        />
      );
    };

    return {
      onOpenUpdateCollectionPermissionDialog: handleOpenDialog,
      renderUpdateCollectionPermissionAlert,
    };
  };
