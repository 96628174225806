import { theme } from 'styles/theme';

export const avatarBgColors = [
  theme.colors?.utility['purple-4'],
  theme.colors?.utility.blueberry,
  theme.colors?.utility['teal-4'],
  theme.colors?.utility['green-4'],
  theme.colors?.utility['yellow-4-new'],
  theme.colors?.utility['orange-4'],
  theme.colors?.utility['pink-4'],
];
