import { Button, Menu, Typography } from '@mui/material';
import { IconBoldSetting5 } from 'components/icons/components/bold/IconBoldSetting5';
import {
  SOCIAL_LISTENING_LIST_FILTERS,
  SOCIAL_LISTENING_LIST_SORT_BY,
  SOCIAL_LISTENING_LIST_SORT_ORDER,
} from 'features/socialMediaListening/constants';
import {
  BrandSocialPostEngagementStatus,
  Platform,
  SortOrder,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { FilterBy } from './FilterBy/FilterBy';
import { SortBy } from './SortBy/SortBy';
import { SocialMediaListeningFilterSelectionProps } from './types';

export const SocialMediaListeningFilterSelection = ({
  filterBy,
  onFilterUpdated,
  sortBy,
  onSortUpdated,
}: SocialMediaListeningFilterSelectionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { value: filterData, setValue: setFilterData } = useLocalStorage<{
    [key: string]: string[];
  }>(SOCIAL_LISTENING_LIST_FILTERS, {
    platforms: [Platform.Tiktok, Platform.Instagram],
    statuses: [BrandSocialPostEngagementStatus.NotStarted],
  });
  const { value: sortByKey, setValue: setSortByKey } = useLocalStorage(
    SOCIAL_LISTENING_LIST_SORT_BY,
    sortBy?.[0].key || '',
  );
  const { value: sortOrder, setValue: setSortOrder } = useLocalStorage(
    SOCIAL_LISTENING_LIST_SORT_ORDER,
    SortOrder.Desc,
  );

  useEffect(() => {
    onFilterUpdated?.(filterData);
  }, [filterData]);

  useEffect(() => {
    onSortUpdated?.(sortByKey, sortOrder);
  }, [sortByKey, sortOrder]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          display: 'flex',
          backgroundColor: theme.colors?.utility[275],
          p: 3,
          borderRadius: 3,
          gap: 2,
          alignItems: 'center',
          '&:hover': {
            backgroundColor: theme.colors?.utility[275],
          },
        }}
      >
        <IconBoldSetting5 size={12} />
        <Typography
          variant="body-xl"
          fontWeight={500}
          color={theme.colors?.utility[700]}
        >
          Sort & Filter
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{}}
      >
        {filterBy?.length && (
          <FilterBy
            currentFilters={filterData}
            toggleFilter={(key, value) => {
              const newFilterData = _.cloneDeep(filterData) as {
                [key: string]: string[];
              };
              if (newFilterData[key]) {
                if (newFilterData[key].includes(value)) {
                  newFilterData[key] = newFilterData[key].filter(
                    (val) => val !== value,
                  );
                } else {
                  newFilterData[key].push(value);
                }
              } else {
                newFilterData[key] = [value];
              }
              setFilterData(newFilterData);
              onFilterUpdated?.(newFilterData);
            }}
            appliedFilterData={filterData}
            options={filterBy}
          />
        )}
        {sortBy?.length && (
          <SortBy
            currentSortBy={sortByKey}
            options={sortBy}
            onOptionClick={(key) => {
              if (key !== sortByKey) {
                setSortByKey(key);
                setSortOrder(SortOrder.Desc);
              }
              onSortUpdated?.(key, sortOrder);
            }}
          />
        )}
      </Menu>
    </>
  );
};
