import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { AllCategoriesDialog } from '../allCategoriesDialog';
import { ThemeDialog } from '../themeDialog';
import { SentimentTrendTableDataProps } from './types';

export const SentimentTrendTableData = ({
  sentimentTrendsData,
  selectedTopicIds,
  timeRange,
  sentiment,
  componentProps,
}: SentimentTrendTableDataProps) => {
  const themeDialogDisclosure = useDisclosure();
  const allCategoriesDialogDisclosure = useDisclosure();

  const { mainColor = theme.colors?.primary.black, highlightColor } =
    componentProps || {};

  const [clickedSentimentName, setClickedSentimentName] = useState('');
  const selectedTrend = useMemo(
    () => sentimentTrendsData.find((x) => x.data.name === clickedSentimentName),
    [sentimentTrendsData, clickedSentimentName],
  );

  return (
    <Box
      p={6}
      color={theme.colors?.primary.black}
      display="flex"
      gap={3}
      flexDirection="column"
      sx={{
        maxHeight: theme.spacing(150),
        overflowY: 'auto',
      }}
    >
      <Box display="flex" gap={6} justifyContent="space-between" width="100%">
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          Themes
        </Typography>
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          Video Count
        </Typography>
      </Box>

      {sentimentTrendsData.map((trend) => (
        <Box
          display="flex"
          key={trend.data.name}
          gap={6}
          justifyContent="space-between"
          width="100%"
        >
          <Box
            flex={1}
            onClick={() => {
              setClickedSentimentName(trend.data.name);
              themeDialogDisclosure.onOpen();
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                cursor: 'pointer',
                fontWeight: 600,
                color: mainColor,
                backgroundColor: highlightColor,
                width: 'fit-content',
                p: theme.spacing(1.5, 3),
                borderRadius: theme.spacing(1),
              }}
            >
              <Typography variant="body-xl">{trend.data.name}</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="body-xl"
              color={theme.colors?.utility[700]}
              fontWeight={500}
            >
              {trend.score}
            </Typography>
          </Box>
        </Box>
      ))}

      <Typography
        display="inline-block"
        variant="body-xl"
        color={theme.colors?.utility[700]}
        fontWeight={600}
        sx={{
          cursor: 'pointer',
        }}
        onClick={allCategoriesDialogDisclosure.onOpen}
      >
        See all categories
      </Typography>

      {selectedTrend && (
        <ThemeDialog
          {...themeDialogDisclosure}
          topicIds={selectedTopicIds}
          brandSentimentTrend={selectedTrend}
          timeRange={timeRange}
        />
      )}

      {allCategoriesDialogDisclosure.isOpen && (
        <AllCategoriesDialog
          sentiment={sentiment}
          {...allCategoriesDialogDisclosure}
        />
      )}
    </Box>
  );
};
