import { gql } from '@apollo/client';
import {
  PersonalSettings,
  useUpdateUserPersonalSettingsMutation,
} from 'graphql/generated';
import { useUserContext } from 'contexts/users/User.context';

gql`
  mutation UpdateUserPersonalSettings($data: UpdateUserPersonalSettingsInput!) {
    updateUserPersonalSettings(data: $data) {
      id
      personalSettings {
        postGeneralPermissionDefault
        collectionGeneralPermissionDefault
      }
    }
  }
`;

export const useMeMutations = () => {
  const { user } = useUserContext();

  const [updateUserPersonalSettings] = useUpdateUserPersonalSettingsMutation();

  return {
    updateUserPersonalSettings: async (personalSettings: PersonalSettings) => {
      await updateUserPersonalSettings({
        variables: {
          data: {
            ...personalSettings,
          },
        },
        optimisticResponse: {
          updateUserPersonalSettings: {
            __typename: 'MeModel',
            id: user?.id || '',
            personalSettings: {
              ...user?.personalSettings,
              ...personalSettings,
            },
          },
        },
      });
    },
  };
};
