import { useDisclosure } from '@dwarvesf/react-hooks';
import { Button, Popover, SxProps, Typography } from '@mui/material';
import { DateRangePicker } from 'components/common/DatePicker';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import moment from 'moment';
import { useMemo, useRef } from 'react';

export type SocialMediaListeningFilterByDateRangeButtonProps = {
  selectedDateRange: (Date | null)[];
  onChange: (dateRange: (Date | null)[]) => void;
  componentProps?: {
    sx?: SxProps;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
  };
};

export const SocialMediaListeningFilterByDateRangeButton = (
  props: SocialMediaListeningFilterByDateRangeButtonProps,
) => {
  const { selectedDateRange, onChange, componentProps } = props;

  const anchorElRef = useRef<HTMLButtonElement>(null);

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  const startDate = selectedDateRange[0];
  const endDate = selectedDateRange[1];

  const predefinedOptions = useMemo(
    () => [
      {
        label: '7 days',
        value: [moment().subtract(7, 'days').toDate(), moment().toDate()],
      },
      {
        label: '30 days',
        value: [moment().subtract(30, 'days').toDate(), moment().toDate()],
      },
      {
        label: '3 months',
        value: [moment().subtract(3, 'months').toDate(), moment().toDate()],
      },
      {
        label: '6 months',
        value: [moment().subtract(6, 'months').toDate(), moment().toDate()],
      },
      {
        label: '12 months',
        value: [moment().subtract(12, 'months').toDate(), moment().toDate()],
      },
      {
        label: 'This year',
        value: [
          moment().startOf('year').hours(moment().hours()).toDate(),
          moment().endOf('year').hours(moment().hours()).toDate(),
        ],
      },
    ],
    [],
  );

  const selectedPredefinedOptionIndex = useMemo(() => {
    return predefinedOptions.findIndex(
      ({ value }) =>
        moment(startDate).isSame(value[0], 'day') &&
        moment(endDate).isSame(value[1], 'day'),
    );
  }, [startDate, endDate, predefinedOptions]);

  const hasSelectedCustomRange = Boolean(
    startDate && endDate && selectedPredefinedOptionIndex === -1,
  );
  const customRangeTriggerRef = useRef<HTMLLIElement>(null);
  const {
    isOpen: isCustomRangePickerOpen,
    onOpen: openCustomRangePicker,
    onClose: closeCustomRangePicker,
  } = useDisclosure();

  return (
    <>
      <Button
        ref={anchorElRef}
        variant="tertiary"
        startIcon={
          componentProps?.startIcon || <IconOutlineCalendar1 size={16} />
        }
        endIcon={componentProps?.endIcon || <IconLinearArrowDown size={16} />}
        sx={{
          borderRadius: 2,
          ...componentProps?.sx,
        }}
        onClick={openPopover}
      >
        <Typography variant="subhead-xl">
          {hasSelectedCustomRange ? (
            <>
              {startDate ? moment(startDate).format('MMM D, YYYY') : 'From'} -{' '}
              {endDate ? moment(endDate).format('MMM D, YYYY') : 'To'}
            </>
          ) : (
            predefinedOptions[selectedPredefinedOptionIndex].label
          )}
        </Typography>
      </Button>
      <Popover
        open={isPopoverOpen}
        onClose={() => {
          closePopover();
          closeCustomRangePicker();
        }}
        anchorEl={anchorElRef.current}
        PaperProps={{
          sx: {
            mt: 2,
            p: 4,
            width: 270,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {predefinedOptions.map((options, index) => {
          const { label, value } = options;

          return (
            <RadioMenuItem
              key={JSON.stringify(value)}
              value={JSON.stringify(value)}
              checked={index === selectedPredefinedOptionIndex}
              onClick={() => {
                onChange(value);
                closePopover();
              }}
              label={<Typography variant="subhead-lg">{label}</Typography>}
            />
          );
        })}

        {/* Custom range picker */}
        <RadioMenuItem
          ref={customRangeTriggerRef}
          value="custom-range"
          checked={hasSelectedCustomRange}
          onClick={openCustomRangePicker}
          label={<Typography variant="subhead-lg">Custom Range</Typography>}
        />
        <DateRangePicker
          calendars={1}
          open={isCustomRangePickerOpen}
          onClose={closeCustomRangePicker}
          value={
            hasSelectedCustomRange
              ? [moment(startDate), moment(endDate)]
              : [null, null]
          }
          onChange={([startDate, endDate]) => {
            onChange([
              startDate ? startDate.toDate() : null,
              endDate
                ? endDate.toDate()
                : startDate
                ? startDate.toDate()
                : null,
            ]);
          }}
          sx={{ '& .MuiTypography-root': { display: 'none' } }}
          slots={{
            // eslint-disable-next-line
            textField: () => null,
          }}
          slotProps={{
            popper: {
              anchorEl: customRangeTriggerRef.current,
              placement: 'left-end',
            },
          }}
        />
      </Popover>
    </>
  );
};
