import { Popover, TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)`
  min-width: 240px;
  padding: 24px 24px 0 24px;

  .MuiInputBase-root {
    padding: 8px 12px !important;
    border-radius: 100px;
    background-color: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.02);

    input {
      padding: 0 !important;
      font-size: 13px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0 !important;
      outline: 0 !important;
    }
  }
`;

export const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    border: 0;
    backdrop-filter: blur(12px);
    background: rgba(255, 255, 255, 0.8);
  }

  .MuiAutocomplete-noOptions {
    font-size: 13px;
    padding: 4px;
  }

  .MuiAutocomplete-listbox {
    .MuiAutocomplete-option {
      flex-shrink: 0;
    }

    .MuiAutocomplete-option[aria-selected='true'] {
      background-color: transparent;
    }

    .MuiAutocomplete-option[aria-selected='true'].Mui-focused {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;
