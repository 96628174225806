import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useState, ReactNode } from 'react';
import { Box } from '@mui/material';

const shouldIgnoreTarget = (domNode: HTMLElement) => {
  return domNode.closest('.ProseMirror');
};

const ModifiedBackend = (...args: any) => {
  // @ts-ignore
  const instance = new HTML5Backend(...args);

  // this prevents listeners from interfering with TipTap
  const listeners = [
    'handleTopDragStart',
    'handleTopDragStartCapture',
    'handleTopDragEndCapture',
    'handleTopDragEnter',
    'handleTopDragEnterCapture',
    'handleTopDragLeaveCapture',
    'handleTopDragOver',
    'handleTopDragOverCapture',
    'handleTopDrop',
    'handleTopDropCapture',
  ];
  listeners.forEach((name) => {
    const original = instance[name];
    instance[name] = (e: any, ...extraArgs: any) => {
      if (!shouldIgnoreTarget(e.target)) {
        original(e, ...extraArgs);
      }
    };
  });

  return instance;
};

export type DndContextProviderProps = {
  children: ReactNode;
};

export const DndContextProvider = (props: DndContextProviderProps) => {
  const { children } = props;

  /**
   * This allows DND to hook all the drop and drag event listeners to this
   * rootElement rather than the entire window. Otherwise, these event listeners
   * will interfere with other event listeners that we have in the app and from
   * other libraries
   */
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

  return (
    <Box ref={setRootElement}>
      {rootElement && (
        <DndProvider backend={ModifiedBackend} options={rootElement}>
          {children}
        </DndProvider>
      )}
    </Box>
  );
};
