import { IconButton } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowLeft } from 'components/icons/components/linear/IconLinearArrowLeft';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { useGetSocialPostsQuery } from 'graphql/generated';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { UseSocialPostsListProps } from './useSocialPostsList';

export type UsePostDetailNavigateProps = {
  searchParams: URLSearchParams;
};

export const usePostDetailNavigate = ({
  searchParams,
}: UsePostDetailNavigateProps) => {
  const searchCriteria = searchParams.get('searchCriteria') || '';
  const position = searchParams.get('position') || '';

  const paginationAvailable = searchCriteria && position;

  const appliedSearchCriteria = searchCriteria
    ? (JSON.parse(window.atob(searchCriteria)) as UseSocialPostsListProps)
    : {
        brandId: '',
      };
  const itemPosition = parseInt(position, 10);

  const { data: nextPost } = useGetSocialPostsQuery({
    variables: {
      ...appliedSearchCriteria,
      after: position,
      take: 1,
    },
    skip: !paginationAvailable,
    fetchPolicy: 'network-only',
  });
  const { data: prevPost } = useGetSocialPostsQuery({
    variables: {
      ...appliedSearchCriteria,
      after: +position - 2 > 0 ? String(+position - 2) : '',
      take: 1,
    },
    skip: !paginationAvailable,
    fetchPolicy: 'network-only',
  });

  const nextPostId = nextPost?.getPaginatedSocialPosts.data?.[0]?.id;
  const prevPostId = prevPost?.getPaginatedSocialPosts.data?.[0]?.id;

  const renderLeftArrow = () => {
    if (!paginationAvailable || itemPosition <= 1 || !prevPostId || !position)
      return null;
    return (
      <Link
        to={{
          pathname: PlotRoutes.socialListeningPost(prevPostId),
          search: `?searchCriteria=${searchCriteria}&position=${
            itemPosition - 1
          }`,
        }}
        replace
      >
        <IconButton
          disableRipple
          sx={{
            position: 'fixed',
            left: theme.spacing(4),
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: theme.colors?.utility[275],
          }}
        >
          <IconLinearArrowLeft size={16} />
        </IconButton>
      </Link>
    );
  };

  const renderRightArrow = () => {
    if (!paginationAvailable || !nextPostId) return null;
    return (
      <Link
        to={{
          pathname: PlotRoutes.socialListeningPost(nextPostId),
          search: `?searchCriteria=${searchCriteria}&position=${
            itemPosition + 1
          }`,
        }}
        replace
      >
        <IconButton
          disableRipple
          sx={{
            position: 'fixed',
            right: theme.spacing(4),
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: theme.colors?.utility[275],
          }}
        >
          <IconLinearArrowRight size={16} />
        </IconButton>
      </Link>
    );
  };

  return {
    renderLeftArrow,
    renderRightArrow,
    topicIds: (appliedSearchCriteria as UseSocialPostsListProps)?.filters
      ?.topicIds,
  };
};
