import { useClipboard } from '@dwarvesf/react-hooks';
import { IconButton } from '@mui/material';
import { toast } from 'components/common/Toast';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { theme } from 'styles/theme';

type HeaderCopyLinkButtonProps = {
  iconColor?: string;
};

export const HeaderCopyLinkButton = (props: HeaderCopyLinkButtonProps) => {
  const { iconColor } = props;

  const { onCopy: onCopyLink } = useClipboard(window.location.href);

  const isMobileView = useMediaQueryMobile();

  return (
    <IconButton
      onClick={() => {
        onCopyLink();
        toast({
          type: 'success',
          message: 'Link copied successfully!!',
        });
      }}
    >
      <IconOutlineLink2
        size={isMobileView ? 20 : 24}
        color={iconColor ?? theme.colors?.primary.black}
      />
    </IconButton>
  );
};
