import { Box, Typography } from '@mui/material';
import { IconBoldChart1 } from 'components/icons/components/bold/IconBoldChart1';
import { IconBoldEmojiHappy } from 'components/icons/components/bold/IconBoldEmojiHappy';
import { IconBoldHeart } from 'components/icons/components/bold/IconBoldHeart';
import { IconBoldTag } from 'components/icons/components/bold/IconBoldTag';
import { IconBoldUser } from 'components/icons/components/bold/IconBoldUser';
import { IconBoldVideoPlay } from 'components/icons/components/bold/IconBoldVideoPlay';
import { useGetCompetitorFlatCreatorForCompetitorsSummaryQuery } from 'graphql/generated';
import { useEffect } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { CompetitorSummarySkeleton } from './CompetitorSummarySkeleton';
import { CompetitorSummaryProps } from './types';

export const CompetitorSummary = ({
  competitorId,
  platform,
}: CompetitorSummaryProps) => {
  const {
    data: competitorData,
    loading,
    refetch,
  } = useGetCompetitorFlatCreatorForCompetitorsSummaryQuery({
    variables: {
      data: {
        filters: {
          platforms: [platform],
          competitorId,
        },
      },
    },
  });
  const competitorFlatCreator =
    competitorData?.searchCompetitorFlatCreators.data[0];

  useEffect(() => {
    refetch();
  }, [platform]); // eslint-disable-line

  if (loading) {
    return <CompetitorSummarySkeleton />;
  }

  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="headline-sm"
        color={theme.colors?.utility[700]}
        pb={1}
      >
        Summary
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldUser size={14} />
          Followers
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          {formatBigNumber(
            competitorFlatCreator?.trackingCreator.followerCount || 0,
          )}
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldHeart size={14} />
          Average likes
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          {formatBigNumber(competitorFlatCreator?.averageDigg || 0)}
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldVideoPlay size={14} />
          Average video views
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          {formatBigNumber(competitorFlatCreator?.averageVideoViews || 0)}
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldChart1 size={14} />
          Engagement rate
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          {parseFloat(
            (competitorFlatCreator?.averageEngagementRate || 0).toFixed(1),
          ) || 0}
          %
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldTag size={14} />
          Brand mentions/week
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          {parseInt(
            typeof competitorFlatCreator?.averageBrandMentionedPerWeek ===
              'number'
              ? competitorFlatCreator.averageBrandMentionedPerWeek.toString()
              : '0',
          )}
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldEmojiHappy size={14} />
          Sentiment
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          {competitorFlatCreator?.averageCreatorSentiment}
        </Box>
      </Typography>
    </Box>
  );
};
