import { Box, SxProps } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { getNoteColorFromColor } from 'features/note/utils';
import {
  PostContextMenu,
  PostCreator,
  PostIcon,
  PostNewBadge,
  PostPreview,
  PostReactionList,
  PostUnreadCommentBadge,
} from 'features/post/components';
import { SearchItemSearchTypeIndicator } from 'features/search';
import {
  PostFragmentPostMasonryCardViewFragment,
  PostType,
  SearchHitType,
} from 'graphql/generated';
import { useMemo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { StyledRightClickContextMenuWrapper } from './styles';

type PostMasonryCardViewProps = {
  searchParams?: Record<string, string>;
  post: PostFragmentPostMasonryCardViewFragment;
  sx?: SxProps;
  isSmartSearch?: boolean;
  componentsProps?: {
    rightClickContextMenu?: false;
    link?: Partial<LinkProps> | false;
  };
  onClick?: () => void;
};

const renderSmartSearchIcon = (isSmartSearch: boolean) => (
  <Box
    display="flex"
    position="absolute"
    left={theme.spacing(3.5)}
    top={theme.spacing(3.75)}
  >
    <SearchItemSearchTypeIndicator
      searchHitType={
        isSmartSearch ? SearchHitType.Smart : SearchHitType.Regular
      }
      size={theme.spacing(4)}
    />
  </Box>
);

export const PostMasonryCardView = (props: PostMasonryCardViewProps) => {
  const {
    post,
    isSmartSearch = false,
    sx,
    componentsProps = {},
    onClick,
  } = props;
  const location = useLocation();

  const postColor = useMemo(() => getNoteColorFromColor(post.color), [post]);

  return (
    <StyledRightClickContextMenuWrapper
      disabled={componentsProps.rightClickContextMenu === false}
      sx={{
        minHeight: 150,
        ...sx,
      }}
      renderMenu={(props) => (
        <PostContextMenu post={post} renderButton={false} {...props} />
      )}
      onClick={onClick}
    >
      <Link
        to={{
          pathname:
            post.type === PostType.Note
              ? PlotRoutes.juiceboxNote({ id: post.id, title: post.title })
              : PlotRoutes.juice(post.id),
          search: props.searchParams
            ? `?${new URLSearchParams(props.searchParams).toString()}`
            : '',
        }}
        state={{
          backgroundLocation: location,
        }}
        {...componentsProps.link}
        {...(componentsProps.link === false
          ? {
              onClick: (e) => {
                e.preventDefault();
              },
            }
          : {})}
      >
        {renderSmartSearchIcon(isSmartSearch)}
        <Box
          style={{
            height: '100%',
            minHeight: 'inherit',
            display: 'flex',
            position: 'relative',
          }}
        >
          <PostPreview post={post} disableMouseEvents />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            p: 4,
            color: postColor
              ? postColor.iconColor
              : theme.colors?.primary.parchment,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <PostCreator post={post} />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PostNewBadge post={post} />
            <PostUnreadCommentBadge post={post} />
            <PostIcon post={post} size={20} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            px: 4,
            pb: 3,
            pt: 4.5,
            overflow: 'auto',
            width: '100%',
            backgroundImage:
              'linear-gradient(180deg, rgba(35, 6, 3, 0.00) 0%, rgba(35, 6, 3, 0.80) 100%)',
          }}
        >
          <PostReactionList
            post={post}
            variant="hide-zero"
            sx={{
              gap: 1.5,
            }}
            componentsProps={{
              reactionButton: {
                sx: {
                  px: 2.5,
                  py: 1,
                  color: theme.colors?.primary.parchment,
                  bgcolor: 'rgba(250, 243, 236, 0.1)',
                  borderColor: 'rgba(250, 243, 236, 0.2)',
                },
              },
            }}
          />
        </Box>
      </Link>
    </StyledRightClickContextMenuWrapper>
  );
};
