import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { CollaborationRoom, useOthersMapped } from 'features/collaboration';
import { PostCollaborativeViewers } from 'features/post';
import { PostFragmentPostDetailContentCollaborationFragment } from 'graphql/generated';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment PostFragmentPostDetailContentCollaboration on PostModel {
    id
    roomId
    myPermissions
  }
`;

type PostDetailContentCollaborationProps = {
  post: PostFragmentPostDetailContentCollaborationFragment;
};

export const PostDetailContentCollaboration = (
  props: PostDetailContentCollaborationProps,
) => {
  const { post } = props;

  const roomId = useMemo(() => {
    if (post.roomId) {
      // liveblocks room is already created and connected
      return post.roomId;
    }

    // liveblocks room is not yet created
    return `post-${post.id}`;
  }, [post]);

  return (
    <CollaborationRoom roomId={roomId} initialPresence={{ cursor: null }}>
      <PostDetailContentCollaborationDetails post={post} />
    </CollaborationRoom>
  );
};

const PostDetailContentCollaborationDetails = (
  props: PostDetailContentCollaborationProps,
) => {
  const { post } = props;

  const location = useLocation();

  const onlineUsers = useOthersMapped((other) => other.info);
  const { addRenderMenuExtraLeft, removeRenderMenuExtraLeft } =
    useCustomHeaderContext();

  useEffect(() => {
    const render = () => (
      <Box display="flex" alignItems="center" gap={2} mr={2}>
        <Box marginRight={theme.spacing(2)}>
          <PostCollaborativeViewers
            postId={post.id}
            onlineUsers={onlineUsers.map(([_, data]) => data)}
          />
        </Box>
      </Box>
    );

    addRenderMenuExtraLeft(render, 0);

    return () => {
      removeRenderMenuExtraLeft(render, 0);
    };
  }, [location.pathname, onlineUsers, post]); // eslint-disable-line

  return null;
};
