import { ApolloCache, gql } from '@apollo/client';
import {
  CreateOrganizationUserAccessRequestInputData,
  InternalOrganizationRole,
  OrganizationType,
  OrganizationUserAccessRequestFragmentAccessRequestHandlersFragment,
  OrganizationUserAccessRequestStatus,
  useApproveOrganizationAccessRequestForEnterpriseOrgMutation,
  useApproveOrganizationAccessRequestMutation,
  useApproveOrganizationAccessRequestsMutation,
  useCreateOrganizationAccessRequestMutation,
  useGetMyOrganizationAccessRequestsLazyQuery,
  useRejectOrganizationAccessRequestMutation,
} from 'graphql/generated';
import { evictObject, modifyObject } from 'utils/apollo';
import { useUserContext } from '../../../contexts/users/User.context';

const ORGANIZATION_USER_ACCESS_REQUEST_FRAGMENT_ACCESS_REQUEST_HANDLERS = gql`
  fragment OrganizationUserAccessRequestFragmentAccessRequestHandlers on OrganizationUserAccessRequestModel {
    id
    message
    status
    user {
      id
      hasBeenApprovedByAdmin
    }
  }
`;

//eslint-disable-next-line
gql`
  mutation CreateOrganizationAccessRequest(
    $data: CreateOrganizationUserAccessRequestInput!
  ) {
    createOrganizationAccessRequest(data: $data) {
      id
      message
      status
      createdAt
      updatedAt
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetMyOrganizationAccessRequests {
    myOrganizationAccessRequests {
      id
      message
      status
      createdAt
      updatedAt
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation ApproveOrganizationAccessRequest(
    $data: ApproveOrganizationUserAccessRequestInput!
  ) {
    approveOrganizationAccessRequest(data: $data) {
      ...OrganizationUserAccessRequestFragmentAccessRequestHandlers
    }
  }
  ${ORGANIZATION_USER_ACCESS_REQUEST_FRAGMENT_ACCESS_REQUEST_HANDLERS}
`;

// eslint-disable-next-line
gql`
  mutation ApproveOrganizationAccessRequestForEnterpriseOrg(
    $data: ApproveOrganizationUserAccessRequestWithRoleInput!
  ) {
    approveOrganizationAccessRequestForEnterpriseOrg(data: $data) {
      ...OrganizationUserAccessRequestFragmentAccessRequestHandlers
    }
  }
  ${ORGANIZATION_USER_ACCESS_REQUEST_FRAGMENT_ACCESS_REQUEST_HANDLERS}
`;

// eslint-disable-next-line
gql`
  mutation ApproveOrganizationAccessRequests(
    $data: ApproveOrganizationUserAccessRequestsInput!
  ) {
    approveOrganizationAccessRequests(data: $data) {
      ...OrganizationUserAccessRequestFragmentAccessRequestHandlers
    }
  }
  ${ORGANIZATION_USER_ACCESS_REQUEST_FRAGMENT_ACCESS_REQUEST_HANDLERS}
`;

// eslint-disable-next-line
gql`
  mutation RejectOrganizationAccessRequest(
    $data: RejectOrganizationUserAccessRequestInput!
  ) {
    rejectOrganizationAccessRequest(data: $data) {
      ...OrganizationUserAccessRequestFragmentAccessRequestHandlers
    }
  }
  ${ORGANIZATION_USER_ACCESS_REQUEST_FRAGMENT_ACCESS_REQUEST_HANDLERS}
`;

export const useAccessRequestHandlers = () => {
  const { orgBilling, user } = useUserContext();
  const isEnterpriseOrg =
    user?.organization?.type === OrganizationType.EnterpriseLead;

  const [createOrganizationAccessRequest] =
    useCreateOrganizationAccessRequestMutation();

  const [getMyOrganizationAccessRequests] =
    useGetMyOrganizationAccessRequestsLazyQuery();

  const [approveOrganizationAccessRequest] =
    useApproveOrganizationAccessRequestMutation();

  const [approveOrganizationAccessRequestForEnterpriseOrg] =
    useApproveOrganizationAccessRequestForEnterpriseOrgMutation();

  // Approve multiple access requests
  const [approveOrganizationAccessRequests] =
    useApproveOrganizationAccessRequestsMutation();
  const [rejectOrganizationAccessRequest] =
    useRejectOrganizationAccessRequestMutation();

  const onCreateOrganizationAccessRequest = (
    input: CreateOrganizationUserAccessRequestInputData,
  ) => {
    createOrganizationAccessRequest({
      variables: {
        data: {
          input,
        },
      },
    });
  };

  const onCheckIsAccessRequestExist = async () => {
    const { data } = await getMyOrganizationAccessRequests();

    return (
      data?.myOrganizationAccessRequests.some(
        (request) =>
          request.status === OrganizationUserAccessRequestStatus.Pending,
      ) || false
    );
  };

  const updateCacheOnAccessRequestUpdate = (
    cache: ApolloCache<any>,
    request: OrganizationUserAccessRequestFragmentAccessRequestHandlersFragment,
  ) => {
    evictObject(cache, request.id, 'OrganizationUserAccessRequestModel');
    modifyObject(cache, request.user.id, 'UserProfileModel', {
      hasBeenApprovedByAdmin: () => request.user.hasBeenApprovedByAdmin,
    });
  };

  const onApproveOrganizationAccessRequest = (
    accessRequestId: string,
    role?: InternalOrganizationRole,
  ) => {
    if (!isEnterpriseOrg) {
      approveOrganizationAccessRequest({
        variables: {
          data: {
            accessRequestId,
          },
        },
        update: (cache, { data }) => {
          if (!data?.approveOrganizationAccessRequest) return;
          updateCacheOnAccessRequestUpdate(
            cache,
            data.approveOrganizationAccessRequest,
          );
          if (orgBilling) {
            modifyObject(cache, orgBilling.id, 'OrganizationBillingModel', {
              organizationMemberUsage: (existingFieldValues) =>
                existingFieldValues + 1,
            });
          }
        },
      });
    } else {
      approveOrganizationAccessRequestForEnterpriseOrg({
        variables: {
          data: {
            accessRequestId,
            role: role || InternalOrganizationRole.SocialListeningUser,
          },
        },
        update: (cache, { data }) => {
          if (!data?.approveOrganizationAccessRequestForEnterpriseOrg) return;
          updateCacheOnAccessRequestUpdate(
            cache,
            data.approveOrganizationAccessRequestForEnterpriseOrg,
          );
          if (orgBilling) {
            modifyObject(cache, orgBilling.id, 'OrganizationBillingModel', {
              organizationMemberUsage: (existingFieldValues) =>
                existingFieldValues + 1,
            });
          }
        },
      });
    }
  };

  const onApproveOrganizationAccessRequests = (accessRequestIds: string[]) => {
    if (isEnterpriseOrg) {
      return;
    }

    approveOrganizationAccessRequests({
      variables: {
        data: {
          accessRequestIds,
        },
      },
      update: (cache, { data }) => {
        if (!data?.approveOrganizationAccessRequests) return;
        data.approveOrganizationAccessRequests.forEach((request) => {
          updateCacheOnAccessRequestUpdate(cache, request);
        });
        if (orgBilling) {
          modifyObject(cache, orgBilling.id, 'OrganizationBillingModel', {
            organizationMemberUsage: (existingFieldValues) =>
              existingFieldValues + accessRequestIds.length,
          });
        }
      },
    });
  };

  const onRejectOrganizationAccessRequest = (accessRequestId: string) => {
    rejectOrganizationAccessRequest({
      variables: {
        data: {
          accessRequestId,
        },
      },
      update: (cache, { data }) => {
        if (!data?.rejectOrganizationAccessRequest) return;
        updateCacheOnAccessRequestUpdate(
          cache,
          data.rejectOrganizationAccessRequest,
        );
      },
    });
  };

  return {
    onCreateOrganizationAccessRequest,
    onCheckIsAccessRequestExist,
    onApproveOrganizationAccessRequest,
    onApproveOrganizationAccessRequests,
    onRejectOrganizationAccessRequest,
  };
};
