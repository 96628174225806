import { Box, Dialog, IconButton, SxProps } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { theme } from 'styles/theme';
import { BillingPlanOptions } from '../planOptions';

type BillingUpgradeModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  isNotClosable?: boolean;
  onGoBack?: VoidFunction;
  componentProps?: {
    paper?: {
      sx?: SxProps;
    };
    image?: {
      sx?: SxProps;
    };
    content?: {
      sx?: SxProps;
    };
  };
  onRequestParentModalClose?: VoidFunction;
  onCustomUpgradeToBasicClick?: (type: 'upgrade' | 'freeTrial') => void;
};

export const BillingUpgradeModal = (props: BillingUpgradeModalProps) => {
  const {
    isOpen,
    onClose,
    isNotClosable,
    onGoBack,
    componentProps,
    onRequestParentModalClose,
    onCustomUpgradeToBasicClick,
  } = props;

  const isMobileView = useMediaQueryMobile();

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          m: 0,
          p: theme.spacing(6, 10),
          borderRadius: 6,
          minWidth: '1240px',
          maxWidth: '1240px',
          height: '665px',
          maxHeight: '665px',
          bgcolor: theme.colors?.utility[400],
          [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            p: 0,
            borderRadius: 0,
            overflow: 'hidden',
            color: theme.colors?.primary.black,
          },
          ...componentProps?.paper?.sx,
        },
      }}
    >
      <Box sx={{ position: 'relative', height: '100%' }}>
        {!isNotClosable && (
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: '-3px',
              right: '-37px',
            }}
          >
            <IconBoldCloseCircle />
          </IconButton>
        )}

        <BillingPlanOptions
          title="Choose your plan:"
          onGoBack={onGoBack}
          onSelected={() => {
            onClose();
            onRequestParentModalClose?.();
          }}
          onCustomUpgradeToBasicClick={onCustomUpgradeToBasicClick}
        />
      </Box>
      {isMobileView && (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        >
          <IconBoldCloseCircle />
        </IconButton>
      )}
    </Dialog>
  );
};
