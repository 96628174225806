import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomFacebook2 } from 'components/icons/components/custom/IconCustomFacebook2';
import { IconCustomLinkedin2 } from 'components/icons/components/custom/IconCustomLinkedin2';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomX } from 'components/icons/components/custom/IconCustomX';
import { ContentIdeaPrimaryField, Stage } from 'graphql/generated';

export const platformIconMap = {
  Facebook: IconCustomFacebook2,
  Instagram: IconBoldInstagram,
  Twitter: IconCustomX,
  LinkedIn: IconCustomLinkedin2,
  Tiktok: IconCustomTiktok,
};

export const stageLabels = {
  [Stage.BrainDump]: 'Brain dump',
  [Stage.Production]: 'Production',
  [Stage.Publish]: 'Published',
  [Stage.IceBox]: 'Icebox',
};

/**
 * This is a workable solution for the time being, but it's not ideal.
 * The bug is due to graphql-codegen not generating the correct value for the ContentIdeaPrimaryField enum.
 * For example:
 *
 * enum ContentIdeaPrimaryField {
 *   DueDate = "Due Date"
 * }
 *
 * graphql-codegen generates the following:
 *
 * enum ContentIdeaPrimaryField {
 *   DueDate = 'DueDate'
 * }
 *
 * Basically we lost the space in the enum value.
 */
export const FormattedContentIdeaPrimaryField = {
  [ContentIdeaPrimaryField.Status]: 'Status',
  [ContentIdeaPrimaryField.DraftAssets]: 'Draft Assets',
  [ContentIdeaPrimaryField.DueDate]: 'Due Date',
  [ContentIdeaPrimaryField.FinalAssets]: 'Final Assets',
  [ContentIdeaPrimaryField.FinalCaption]: 'Final Caption',
  [ContentIdeaPrimaryField.FinalAudio]: 'Final Audio',
  [ContentIdeaPrimaryField.Moodboard]: 'Moodboard',
  [ContentIdeaPrimaryField.Owners]: 'Owners',
  [ContentIdeaPrimaryField.Pillars]: 'Pillars',
  [ContentIdeaPrimaryField.Platforms]: 'Platforms',
};
