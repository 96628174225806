import { useImmer } from 'use-immer';
import {
  GeneralPermission,
  PermissionLevel,
  PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment,
  useGetPostSuggestedAuthForPostPermissionSuggestedDialogQuery,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { PermissionDialogLayout } from 'features/permission';
import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Button } from '@mui/material';
import { theme } from 'styles/theme';
import { IconBoldLock } from 'components/icons/components/bold/IconBoldLock';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import {
  POST_SUGGESTED_AUTH_FRAGMENT_POST_PERMISSION_SUGGESTED_AUTH_DIALOG_BODY,
  PostPermissionSuggestedDialogBody,
} from './PostPermissionSuggestedAuthDialogBody';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetPostSuggestedAuthForPostPermissionSuggestedDialog(
    $collectionIds: [String!]!
  ) {
    getPostSuggestedAuth(collectionIds: $collectionIds) {
      ...PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBody
    }
  }
  ${POST_SUGGESTED_AUTH_FRAGMENT_POST_PERMISSION_SUGGESTED_AUTH_DIALOG_BODY}
`;

type PostPermissionSuggestedDialogViewProps = {
  collectionIds: string[];
  onSetPermissionData?: (
    data: PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment,
    note: string,
    shouldCreateComment: boolean,
  ) => void;
};

export const PostPermissionSuggestedAuthDialogView = (
  props: PostPermissionSuggestedDialogViewProps,
) => {
  const { collectionIds, onSetPermissionData } = props;
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const updateBtnRef = useRef<HTMLButtonElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [postSuggestedAuth, setPostSuggestedAuth] =
    useImmer<PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment>(
      {
        generalPermission: GeneralPermission.InviteOnly,
        permissionLevel: PermissionLevel.View,
        suggestedInviteMembers: [],
      },
    );

  const { data: suggestedAuthApiData, loading: suggestedAuthApiDataLoading } =
    useGetPostSuggestedAuthForPostPermissionSuggestedDialogQuery({
      variables: {
        collectionIds,
      },
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    // merge suggestedAuthApiData with postSuggestedAuth
    if (
      !suggestedAuthApiDataLoading &&
      suggestedAuthApiData?.getPostSuggestedAuth
    ) {
      const nonExistingUsers =
        suggestedAuthApiData?.getPostSuggestedAuth.suggestedInviteMembers.filter(
          (inviteMember) => {
            return !postSuggestedAuth.suggestedInviteMembers.find(
              (m) => m.user.email === inviteMember.user.email,
            );
          },
        );

      setPostSuggestedAuth((draft) => {
        draft.suggestedInviteMembers.push(...nonExistingUsers);
        draft.generalPermission =
          suggestedAuthApiData.getPostSuggestedAuth.generalPermission;
        draft.permissionLevel =
          suggestedAuthApiData.getPostSuggestedAuth.permissionLevel;
      });
    }
  }, [suggestedAuthApiData, suggestedAuthApiDataLoading]);

  const handleUpdateSuggestedAuth = (
    data: PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment,
    note: string,
    shouldCreateComment: boolean,
  ) => {
    setPostSuggestedAuth((draft) => {
      draft.permissionLevel = data.permissionLevel;
      draft.generalPermission = data.generalPermission;
      draft.suggestedInviteMembers = data.suggestedInviteMembers;
    });

    onSetPermissionData?.(data, note, shouldCreateComment);

    onClose();
  };

  return (
    <PermissionDialogLayout
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      customBtn={
        <Button
          sx={{
            ...theme.typography['headline-sm'],
            color: theme.colors?.primary.parchment,
            bgcolor: theme.colors?.primary.black,
            borderRadius: 2,
            padding: theme.spacing(2, 4),
            ':hover': {
              bgcolor: theme.colors?.primary.black,
            },
          }}
          startIcon={
            postSuggestedAuth?.generalPermission ===
            GeneralPermission.InviteOnly ? (
              postSuggestedAuth.suggestedInviteMembers.length === 0 ? (
                <IconBoldLock
                  size={16}
                  color={theme.colors?.primary.parchment}
                />
              ) : (
                <IconCustomUsers
                  size={16}
                  color={theme.colors?.primary.parchment}
                />
              )
            ) : (
              <IconBoldBuildings
                size={16}
                color={theme.colors?.primary.parchment}
              />
            )
          }
        >
          Share
        </Button>
      }
      hasPendingChanges={hasPendingChanges}
      onPressSaveChanges={() => {
        setHasPendingChanges(false);
        updateBtnRef.current?.click();
        onClose();
      }}
      renderDialogBody={() => (
        <PostPermissionSuggestedDialogBody
          updateBtnRef={updateBtnRef}
          postSuggestedAuth={postSuggestedAuth}
          updateSuggestedAuthDataChanged={handleUpdateSuggestedAuth}
          setHasPendingChanges={setHasPendingChanges}
        />
      )}
    />
  );
};
