/**
 * NOTE: Initially this was only to show posts when users search for things in juicebox.
 * Now it's been expanded to show collections as search result, too.
 * So I'm not sure if we should continue to keep it here & named like this,
 * but I'll probably go back and refactor it later.
 */

import { UseDisclosureProps } from '@dwarvesf/react-hooks/dist/useDisclosure';
import { SxProps } from '@mui/material';
import { MasonrySkeleton } from 'components/common/Skeleton';
import {
  DEFAULT_CJB_TAKE_LARGE,
  DEFAULT_CJB_TAKE_SMALL,
} from 'constants/pagination';
import { CollectionCreateFromSmartSearchResultDialogView } from 'features/collection';
import { CollectionPostMasonryView } from 'features/collectionPostDnd';
import { JuiceboxTableBaseTableView } from 'features/juiceboxTable';
import {
  CollectionFilters,
  PostFiltersForSmartSearch,
  SearchHitType,
  SortType,
  useCreateSearchHitForJuiceboxFilterSuggestionViewMutation,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useCollectionSmartSearchQuery } from './useCollectionSmartSearchQuery';
import { usePostSmartSearchQuery } from './usePostSmartSearchQuery';

export type PostSmartSearchResultViewProps = {
  postFilters: PostFiltersForSmartSearch;
  collectionFilters: CollectionFilters;
  createCollectionModal?: UseDisclosureProps & { isOpen: boolean };
  getScrollParent?: () => HTMLElement | null;
  sortType?: SortType;
  canSkipCollectionQuery?: boolean;
  canSkipPostQuery?: boolean;
  view?: 'grid' | 'list';
  componentsProps?: {
    listView?: {
      header?: {
        sx?: SxProps;
      };
    };
  };
};

export const PostSmartSearchResultView = (
  props: PostSmartSearchResultViewProps,
) => {
  const {
    postFilters,
    collectionFilters,
    createCollectionModal,
    getScrollParent,
    sortType,
    canSkipCollectionQuery,
    canSkipPostQuery,
    view = 'grid',
    componentsProps = {},
  } = props;

  const analytics = useAnalytics();
  const isMobileView = useMediaQueryMobile();

  const [createSearchHit] =
    useCreateSearchHitForJuiceboxFilterSuggestionViewMutation();

  const {
    data: collectionData,
    loading: isCollectionLoading,
    fetchMore: fetchMoreCollection,
  } = useCollectionSmartSearchQuery({
    filters: collectionFilters,
    canSkip: canSkipCollectionQuery,
    take: isMobileView ? DEFAULT_CJB_TAKE_SMALL : DEFAULT_CJB_TAKE_LARGE,
    sortType,
  });
  const collections =
    collectionData?.collectionsSmartSearch.data.map((x) => x.item) || [];

  const {
    data: postData,
    loading: isPostLoading,
    fetchMore: fetchMorePost,
  } = usePostSmartSearchQuery({
    filters: postFilters,
    sortType,
    canSkip: canSkipPostQuery,
  });
  const posts = postData?.postsSmartSearch.data.map((x) => x.item) || [];
  const hasNextPostPage = postData?.postsSmartSearch.pageInfo.hasNextPage;
  const hasNextCollectionPage =
    collectionData?.collectionsSmartSearch.pageInfo.hasNextPage;

  const smartSearchPostIds = useMemo(() => {
    return (
      postData?.postsSmartSearch.data
        .filter((x) => x.type === SearchHitType.Smart)
        .map((x) => x.item.id) || []
    );
  }, [postData]); // eslint-disable-line

  if (isCollectionLoading || isPostLoading) {
    return <MasonrySkeleton />;
  }

  const onPostClick = (
    postId: string,
    position: number,
    totalSearchResults: number,
  ) => {
    if (postFilters.query?.trim()) {
      createSearchHit({
        variables: {
          data: {
            post: {
              id: postId,
            },
            query: postFilters.query,
          },
        },
      });
    }
    analytics.track(EventName.PostSearchResultClicked, {
      query: props.postFilters.query,
      postId,
      position,
      totalSearchResults,
    });
  };

  const onCollectionClick = (
    collectionId: string,
    position: number,
    totalSearchResults: number,
  ) => {
    if (collectionFilters.query?.trim()) {
      createSearchHit({
        variables: {
          data: {
            collection: {
              id: collectionId,
            },
            query: collectionFilters.query,
          },
        },
      });
    }
    analytics.track(EventName.CollectionSearchResultClicked, {
      query: props.collectionFilters.query,
      collectionId,
      position,
      totalSearchResults,
    });
  };

  return (
    <>
      {view === 'list' ? (
        <JuiceboxTableBaseTableView
          collections={collections}
          posts={posts}
          hasMore={hasNextPostPage || hasNextCollectionPage}
          loadMore={() => {
            if (hasNextCollectionPage) {
              fetchMoreCollection();
            }
            if (hasNextPostPage) {
              fetchMorePost();
            }
          }}
          style={{ height: '100vh' }}
          componentProps={{
            header: componentsProps.listView?.header,
          }}
        />
      ) : (
        <InfiniteScroll
          hasMore={hasNextPostPage || hasNextCollectionPage}
          initialLoad={false}
          loadMore={() => {
            if (hasNextCollectionPage) {
              fetchMoreCollection();
            }
            if (hasNextPostPage) {
              fetchMorePost();
            }
          }}
          threshold={1024}
          useWindow={!getScrollParent}
          getScrollParent={getScrollParent}
        >
          <CollectionPostMasonryView
            collections={collections}
            posts={posts}
            onPostClick={onPostClick}
            onCollectionClick={onCollectionClick}
            smartSearchPostIds={smartSearchPostIds}
          />
        </InfiniteScroll>
      )}
      {createCollectionModal?.isOpen && (
        <CollectionCreateFromSmartSearchResultDialogView
          onClose={createCollectionModal?.onClose!}
          name={collectionFilters.query || ''}
          posts={posts}
        />
      )}
    </>
  );
};
