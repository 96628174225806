import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { CollectionCardView } from 'features/collection/views/card/CollectionCardView';
import {
  CollectionFragmentCollectionCardViewFragmentDoc,
  CollectionFragmentCollectionPostDndCollectionCardFragment,
  CollectionPermission,
} from 'graphql/generated';
import {
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { theme } from 'styles/theme';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionPostDndCollectionCard on CollectionModel {
    id
    ...CollectionFragmentCollectionCardView
  }
  ${CollectionFragmentCollectionCardViewFragmentDoc}
`;

export const COLLECTION_DROPPABLE_PREFIX = 'droppable-collection';
export const COLLECTION_DRAGGABLE_PREFIX = 'draggable-collection';

export type CollectionPostDndCollectionCardProps = {
  collection: CollectionFragmentCollectionPostDndCollectionCardFragment;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  index: number;
  stopReorderingItems?: boolean;
  selectable?: boolean;
  selected?: boolean;
  isSelectModeActive?: boolean;
  isDropDisabled?: boolean;
  sx?: SxProps;
  onEnterCardDropZone?: VoidFunction;
  onLeaveCardDropZone?: VoidFunction;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const CollectionPostDndCollectionCard = (
  props: CollectionPostDndCollectionCardProps,
) => {
  const {
    collection,
    onClick,
    index,
    stopReorderingItems,
    selectable,
    selected = false,
    isSelectModeActive,
    isDropDisabled,
    sx,
    onEnterCardDropZone,
    onLeaveCardDropZone,
    onMouseDown,
    onMouseUp,
  } = props;

  const isMobile = useMediaQueryMobile();

  const droppableId = `${COLLECTION_DROPPABLE_PREFIX}-${collection.id}`;
  const draggableId = `${COLLECTION_DRAGGABLE_PREFIX}-${collection.id}`;

  const getPreventReorderingStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot,
    selected: boolean,
  ) => {
    if (!snapshot.isDragging || (selected && !snapshot.isDropAnimating)) {
      return {};
    }

    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  };

  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={
        !collection.myPermissions.includes(CollectionPermission.Update) ||
        isDropDisabled
      }
    >
      {(droppableProvided, droppableSnapshot) => {
        const isDraggingOverOtherCollection =
          !selected &&
          droppableSnapshot.isDraggingOver &&
          droppableSnapshot.draggingOverWith !== draggableId;

        return (
          <Box
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            sx={sx}
            onClick={onClick}
            onMouseOver={onEnterCardDropZone}
            onMouseLeave={onLeaveCardDropZone}
          >
            <Draggable
              draggableId={draggableId}
              key={draggableId}
              index={index}
              isDragDisabled={
                !collection.myPermissions.includes(
                  CollectionPermission.Update,
                ) ||
                !selectable ||
                isMobile
              }
            >
              {(draggableProvided, draggableSnapshot) => {
                return (
                  <>
                    <Box
                      data-rbd-draggable-container-id={collection.id}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={
                        stopReorderingItems
                          ? getPreventReorderingStyle(
                              draggableProvided.draggableProps.style,
                              draggableSnapshot,
                              selected,
                            )
                          : draggableProvided.draggableProps.style
                      }
                      sx={{
                        position: 'relative',
                        transition: '0.2 all',
                        borderRadius: 4,
                        transform: isDraggingOverOtherCollection
                          ? 'translate(0, -8px) !important'
                          : 'inherit',
                        zIndex: isDraggingOverOtherCollection ? 999 : 'inherit',
                        ...(selected && {
                          color: theme.colors?.primary.black,
                          outline: `3px solid ${theme.colors?.primary.black}`,
                          outlineOffset: 3,
                          backgroundColor: theme.colors?.primary.white,
                          overflow: 'hidden',
                        }),
                      }}
                    >
                      <Box
                        data-rbd-draggable-content-id={collection.id}
                        sx={{
                          minHeight: 150,
                          overflow: 'hidden',
                          borderRadius: 3,
                        }}
                        onMouseDown={(e) => {
                          // Do nothing if users are using right click, as it messes with the context menu
                          if (e.button === 2) {
                            return;
                          }

                          onMouseDown?.(e);
                        }}
                        onMouseUp={onMouseUp}
                      >
                        <CollectionCardView
                          collection={collection}
                          componentsProps={{
                            multiPostPreview: {
                              variant: 'card-stack-alt',
                            },
                            disableContextMenu: isSelectModeActive,
                          }}
                          sx={{
                            transition: '0.2s all',
                            transform: isDraggingOverOtherCollection
                              ? 'scale(1) translateY(0)'
                              : 'scale(1)',
                            left: 0,
                            top: 0,
                            bgcolor: isDraggingOverOtherCollection
                              ? theme.colors?.utility['yellow-1']
                              : theme.colors?.primary.white,
                          }}
                        />
                      </Box>

                      {selected && (
                        <IconBoldTickCircle
                          size={16}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            margin: 3,
                          }}
                        />
                      )}
                    </Box>

                    {isDraggingOverOtherCollection &&
                      draggableSnapshot.isDragging &&
                      droppableProvided.placeholder}
                  </>
                );
              }}
            </Draggable>
          </Box>
        );
      }}
    </Droppable>
  );
};
