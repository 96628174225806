import {
  SocialMediaListeningFilterByUserButton,
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningFilterByPlatformsButton,
} from 'features/socialMediaListening';
import { Box } from '@mui/material';
import { SocialPostCommentsFilters } from 'graphql/generated';
import { theme } from 'styles/theme';

type SocialMediaListeningAnalyticsOutboundInteractionFiltersProps = {
  filters: SocialPostCommentsFilters;
  setFilters: (filters: SocialPostCommentsFilters) => void;
  componentProps?: {
    buttonColors?: string;
  };
};

export const SocialMediaListeningAnalyticsOutboundInteractionFilters = (
  props: SocialMediaListeningAnalyticsOutboundInteractionFiltersProps,
) => {
  const { filters, setFilters, componentProps } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <SocialMediaListeningFilterByUserButton
        selectedUsers={filters.responderIds || []}
        onChange={(userIds) => {
          setFilters({
            ...filters,
            responderIds: userIds.length > 0 ? userIds : undefined,
          });
        }}
        componentProps={{
          sx: {
            bgcolor: componentProps?.buttonColors,
            ':hover': {
              bgcolor: componentProps?.buttonColors,
            },
          },
        }}
      />

      <SocialMediaListeningFilterByPlatformsButton
        selectedPlatforms={filters.platforms || []}
        onChange={(platforms) => {
          setFilters({ ...filters, platforms });
        }}
        componentProps={{
          sx: {
            bgcolor: componentProps?.buttonColors,
            ':hover': {
              bgcolor: componentProps?.buttonColors,
            },
          },
        }}
      />

      <SocialMediaListeningFilterByDateRangeButton
        selectedDateRange={
          filters.dateRange
            ? [filters.dateRange.startDate, filters.dateRange.endDate]
            : [null, null]
        }
        onChange={(dateRange) => {
          setFilters({
            ...filters,
            dateRange: { startDate: dateRange[0], endDate: dateRange[1] },
          });
        }}
        componentProps={{
          sx: {
            bgcolor: componentProps?.buttonColors,
            ':hover': {
              bgcolor: componentProps?.buttonColors,
            },
          },
        }}
      />
    </Box>
  );
};
