/**
 * 2 things we need to look back into this component:
 * 1. Should not contain querying logic here
 * 2. Should find a better way to handle infinite scrolling. Abstract the methods out somehow that can be easier managed from outside
 *
 * Basically, this table at best should only handle UI rendering.
 */

import { gql } from '@apollo/client';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Avatar, AvatarGroup } from 'components/common/AvatarGroup';
import { CommandKey } from 'components/common/CommandKey';
import { ScrollableContainer } from 'components/common/ScrollableContainer';
import { GenerateId } from 'utils/generateId';
import {
  CollectionField,
  ContentIdeaAudio,
  ContentIdeaBadgeMenuItem,
  ContentIdeaCaption,
  ContentIdeaCollection,
  ContentIdeaContextMenu,
  ContentIdeaDueDate,
  ContentIdeaName,
  ContentIdeaOwners,
  ContentIdeaPillars,
  ContentIdeaPlatforms,
  ContentIdeaPreview,
  ContentIdeaStatus,
} from 'features/contentIdea/components';
import {
  ContentIdeaFieldValueFilter,
  ContentIdeaFilters,
  ContentIdeaFragmentContentIdeaAudioFragmentDoc,
  ContentIdeaFragmentContentIdeaCaptionFragmentDoc,
  ContentIdeaFragmentContentIdeaCollectionFragmentDoc,
  ContentIdeaFragmentContentIdeaContextMenuFragmentDoc,
  ContentIdeaFragmentContentIdeaDueDateFragmentDoc,
  ContentIdeaFragmentContentIdeaNameFragmentDoc,
  ContentIdeaFragmentContentIdeaOwnersFragmentDoc,
  ContentIdeaFragmentContentIdeaPillarsFragmentDoc,
  ContentIdeaFragmentContentIdeaPlatformsFragmentDoc,
  ContentIdeaFragmentContentIdeaPreviewFragmentDoc,
  ContentIdeaFragmentContentIdeaStatusFragmentDoc,
  ContentIdeaFragmentContentIdeaTableViewFragment,
  ContentIdeaFragmentContentIdeaTableViewFragmentDoc,
  ContentIdeaPermission,
  SortByInputData,
  Stage,
  useCreateContentIdeaForContentIdeaTableViewMutation,
  useGetContentIdeasForContentIdeaTableViewQuery,
} from 'graphql/generated';
import { useKeyboardCommand } from 'hooks/keyboard/useKeyboardCommand';
import moment from 'moment';
import { useContentIdeaMetaStats } from 'pages/contentCalendar/hooks';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { AddButton } from './AddButton';
import { ContentIdeaTableRowSkeleton } from './ContentIdeaTableRowSkeleton';
import { StyledBodyCell, StyledHeaderCell } from './styles';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_TABLE_VIEW = gql`
  fragment ContentIdeaFragmentContentIdeaTableView on ContentIdeaModel {
    id
    stage
    myPermissions
    pillars {
      value {
        multiSelect {
          bgcolor
        }
      }
    }
    platforms {
      value {
        multiSelect {
          bgcolor
        }
      }
    }
    moodboard {
      value {
        collection {
          id
        }
      }
    }
    draftAssets {
      value {
        collection {
          id
        }
      }
    }
    finalAssets {
      value {
        collection {
          id
        }
      }
    }
    ...ContentIdeaFragmentContentIdeaPreview
    ...ContentIdeaFragmentContentIdeaName
    ...ContentIdeaFragmentContentIdeaStatus
    ...ContentIdeaFragmentContentIdeaDueDate
    ...ContentIdeaFragmentContentIdeaOwners
    ...ContentIdeaFragmentContentIdeaPlatforms
    ...ContentIdeaFragmentContentIdeaPillars
    ...ContentIdeaFragmentContentIdeaCollection
    ...ContentIdeaFragmentContentIdeaCaption
    ...ContentIdeaFragmentContentIdeaAudio
    ...ContentIdeaFragmentContentIdeaContextMenu
  }
  ${ContentIdeaFragmentContentIdeaPreviewFragmentDoc}
  ${ContentIdeaFragmentContentIdeaNameFragmentDoc}
  ${ContentIdeaFragmentContentIdeaStatusFragmentDoc}
  ${ContentIdeaFragmentContentIdeaDueDateFragmentDoc}
  ${ContentIdeaFragmentContentIdeaOwnersFragmentDoc}
  ${ContentIdeaFragmentContentIdeaPlatformsFragmentDoc}
  ${ContentIdeaFragmentContentIdeaPillarsFragmentDoc}
  ${ContentIdeaFragmentContentIdeaCollectionFragmentDoc}
  ${ContentIdeaFragmentContentIdeaCaptionFragmentDoc}
  ${ContentIdeaFragmentContentIdeaAudioFragmentDoc}
  ${ContentIdeaFragmentContentIdeaContextMenuFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetContentIdeasForContentIdeaTableView(
    $filters: ContentIdeaFilters!
    $sortBy: SortByInputData
    $before: String
    $after: String
    $skip: Int
    $take: Int
  ) {
    contentIdeas(
      filters: $filters
      sortBy: $sortBy
      before: $before
      after: $after
      skip: $skip
      take: $take
    ) {
      data {
        id
        ...ContentIdeaFragmentContentIdeaTableView
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_TABLE_VIEW}
`;

// eslint-disable-next-line
gql`
  mutation CreateContentIdeaForContentIdeaTableView(
    $data: CreateContentIdeaInput!
  ) {
    createContentIdea(data: $data) {
      id
      ...ContentIdeaFragmentContentIdeaTableView
    }
  }
  ${ContentIdeaFragmentContentIdeaTableViewFragmentDoc}
`;

export type ContentIdeaTableViewProps = {
  stage: Stage;
  filterFields: ContentIdeaFieldValueFilter[];
  filterExtra: Pick<
    ContentIdeaFilters,
    'collectionIds' | 'organizationIds' | 'socialAuthorIds'
  >;
  sortBy?: SortByInputData;

  /**
   * The content idea that this section should base the list on.
   * Because the list is paginated, this will be used to fetch the prev/next page
   * around this content idea.
   */
  anchorContentIdeaId?: string;

  onRowClick?: (
    contentIdea: ContentIdeaFragmentContentIdeaTableViewFragment,
  ) => void;

  /**
   * This function is to expose the first content idea id to the parent component.
   * We need it to allow switching to Content view when currentlyFocusedContentIdeaId is not available.
   */
  setFirstContentIdeaId?: (id: string) => void;
};

export const ContentIdeaTableView = (props: ContentIdeaTableViewProps) => {
  const {
    stage,
    filterFields,
    filterExtra,
    sortBy,
    anchorContentIdeaId,
    onRowClick,
    setFirstContentIdeaId,
  } = props;

  const { onUpdateContentIdeaMetaStats } = useContentIdeaMetaStats({});

  const isAnchorContentIdeaIdAvailableOnMount = useRef(
    Boolean(anchorContentIdeaId),
  );

  const { data, loading, fetchMore, updateQuery } =
    useGetContentIdeasForContentIdeaTableViewQuery({
      variables: {
        filters: {
          stage,
          fields: filterFields,
          ...filterExtra,
        },
        sortBy: sortBy?.field ? sortBy : undefined,
        // If anchorContentIdeaId is provided, fetch 0 items here
        // We will handle fetching prev and next page in useEffect below
        take: isAnchorContentIdeaIdAvailableOnMount.current ? 0 : undefined,
      },
      fetchPolicy: 'cache-and-network',
    });
  const contentIdeas = data?.contentIdeas.data || [];
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const fetchPreviousPage = async (_before?: string, skip = true) => {
    const before = _before || contentIdeas[0]?.id;

    if (!before || isFetchingMore) {
      return;
    }

    setIsFetchingMore(true);

    await fetchMore({
      variables: {
        filters: {
          stage,
          fields: filterFields,
          ...filterExtra,
        },
        sortBy: sortBy?.field ? sortBy : undefined,
        before,
        skip: skip ? 1 : 0,
        take: 10,
      },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevData;
        }

        return {
          contentIdeas: {
            data: [
              ...fetchMoreResult.contentIdeas.data,
              ...prevData.contentIdeas.data,
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.id === e.id),
            ),
            pageInfo: {
              ...prevData.contentIdeas.pageInfo,
              hasPreviousPage:
                fetchMoreResult.contentIdeas.pageInfo.hasPreviousPage,
            },
          },
        };
      },
    });

    setIsFetchingMore(false);
  };
  const fetchNextPage = async (_after?: string, skip = true) => {
    const after = _after || contentIdeas[contentIdeas.length - 1]?.id;

    if (!after || isFetchingMore) {
      return;
    }

    setIsFetchingMore(true);

    await fetchMore({
      variables: {
        filters: {
          stage,
          fields: filterFields,
          ...filterExtra,
        },
        sortBy: sortBy?.field ? sortBy : undefined,
        after,
        skip: skip ? 1 : 0,
        take: 10,
      },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevData;
        }

        return {
          contentIdeas: {
            data: [
              ...prevData.contentIdeas.data,
              ...fetchMoreResult.contentIdeas.data,
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.id === e.id),
            ),
            pageInfo: {
              ...prevData.contentIdeas.pageInfo,
              hasNextPage: fetchMoreResult.contentIdeas.pageInfo.hasNextPage,
            },
          },
        };
      },
    });

    setIsFetchingMore(false);
  };

  // If anchorContentIdeaId is provided right away on mount,
  // fetch the previous & next page once the default query has finished running
  // NOTE: This should only run ONCE per stage change
  useEffect(() => {
    if (isAnchorContentIdeaIdAvailableOnMount) {
      (async () => {
        await fetchNextPage(anchorContentIdeaId, false);
        await fetchPreviousPage(anchorContentIdeaId, true);

        setTimeout(() => {
          document
            .querySelector(`[data-content-idea-id="${anchorContentIdeaId}"]`)
            ?.scrollIntoView();
        }, 300);
      })();
    }
  }, [stage]); // eslint-disable-line

  useEffect(() => {
    setFirstContentIdeaId?.(contentIdeas[0]?.id);
  }, [contentIdeas]); // eslint-disable-line

  const headerColumns = [
    'Preview',
    'Title',
    'Status',
    'Date',
    'Owner',
    'Pillars',
    'Platforms',
    'Collection',
    'Moodboard',
    'Draft Assets',
    'Final Assets',
    'Final Caption',
    'Final Audio',
  ];

  const [createContentIdea] =
    useCreateContentIdeaForContentIdeaTableViewMutation();
  const newContentIdeaIdRef = useRef<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const addNewContentIdea = async () => {
    if (isCreating) {
      return;
    }

    setIsCreating(true);

    const newContentIdeaId = GenerateId.create();
    const response = await createContentIdea({
      variables: {
        data: {
          contentIdeaId: newContentIdeaId,
          data: {
            stage,
          },
        },
      },
    });
    const contentIdea = response.data?.createContentIdea;

    if (contentIdea) {
      onUpdateContentIdeaMetaStats(contentIdea.stage);
      updateQuery((prevData) => {
        return {
          ...prevData,
          contentIdeas: {
            ...prevData.contentIdeas,
            data: [contentIdea, ...prevData.contentIdeas.data],
          },
        };
      });

      newContentIdeaIdRef.current = newContentIdeaId;
      setTimeout(() => {
        newContentIdeaIdRef.current = null;
      }, 500);
    }

    setIsCreating(false);
  };

  useKeyboardCommand(['I'], () => {
    addNewContentIdea();
  });

  const stopEvents = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  /**
   * Controllers for context menu.
   * We render context menu in a special way here, because wrapping the TableRow component with RightClickContextMenuWrapper
   * would potentially mess with MUI table rendering.
   */
  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [contextMenuContentIdea, setContextMenuContentIdea] =
    useState<ContentIdeaFragmentContentIdeaTableViewFragment | null>(null);

  // Reset context menu if the content idea is somehow removed from the list (e.g. delete)
  useEffect(() => {
    if (
      contextMenuContentIdea &&
      contentIdeas.find((ci) => ci.id === contextMenuContentIdea.id) ===
        undefined
    ) {
      setContextMenuAnchorPosition(null);
      setContextMenuContentIdea(null);
    }
  }, [contentIdeas]); // eslint-disable-line

  return (
    <>
      <ScrollableContainer
        sx={{
          height: '100%',
        }}
        hasPreviousPage={data?.contentIdeas.pageInfo.hasPreviousPage}
        hasNextPage={data?.contentIdeas.pageInfo.hasNextPage}
        onStartReached={fetchPreviousPage}
        onEndReached={fetchNextPage}
      >
        <TableContainer>
          <Table sx={{ minWidth: 'max-content' }}>
            <TableHead>
              <TableRow>
                {headerColumns.map((column, index) => (
                  <StyledHeaderCell key={`${column}-${index}`}>
                    {column}
                  </StyledHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isCreating && <ContentIdeaTableRowSkeleton />}
              {!contentIdeas.length && !loading ? (
                <TableRow>
                  <TableCell
                    colSpan={headerColumns.length}
                    sx={{
                      border: 'none',
                      height: 250,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="headline-xl"
                        color={theme.colors?.utility[700]}
                        fontSize={28}
                      >
                        You don't have any ideas brewing.
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: theme.colors?.utility[275],
                          borderRadius: 2,
                          padding: theme.spacing(2, 4),
                        }}
                      >
                        <Typography
                          variant="headline-sm"
                          color={theme.colors?.primary.black}
                        >
                          Press{' '}
                          <CommandKey
                            sx={{
                              display: 'inline-flex',
                              borderRadius: 1,
                              background: `rgba(35, 6, 3, 0.30)`,
                              ...theme.typography['headline-xxs'],
                              fontFamily: 'SF Mono',
                              color: theme.colors?.primary.parchment,
                            }}
                          >
                            I
                          </CommandKey>{' '}
                          to create content
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                contentIdeas.map((contentIdea) => {
                  const canEdit = contentIdea.myPermissions.includes(
                    ContentIdeaPermission.Update,
                  );
                  return (
                    <TableRow
                      data-content-idea-id={contentIdea.id}
                      key={contentIdea.id}
                      onClick={() => onRowClick?.(contentIdea)}
                      sx={{
                        cursor: onRowClick ? 'pointer' : 'default',
                      }}
                      onContextMenu={(e) => {
                        stopEvents(e);
                        setContextMenuAnchorPosition({
                          top: e.clientY,
                          left: e.clientX,
                        });
                        setContextMenuContentIdea(contentIdea);
                      }}
                    >
                      <StyledBodyCell>
                        <Box onClick={stopEvents}>
                          <ContentIdeaPreview
                            contentIdea={contentIdea}
                            readOnly={!canEdit}
                            sx={{
                              width: 56,
                              height: 72,
                              borderRadius: 2,
                              overflow: 'hidden',
                            }}
                          />
                        </Box>
                      </StyledBodyCell>
                      <StyledBodyCell>
                        <Box onClick={stopEvents}>
                          <ContentIdeaName
                            contentIdea={contentIdea}
                            autoFocus={
                              contentIdea.id === newContentIdeaIdRef.current
                            }
                            style={{
                              ...theme.typography['headline-sm'],
                            }}
                          />
                        </Box>
                      </StyledBodyCell>
                      <StyledBodyCell>
                        <Box onClick={stopEvents}>
                          <ContentIdeaStatus contentIdea={contentIdea} />
                        </Box>
                      </StyledBodyCell>
                      <StyledBodyCell>
                        <Box onClick={stopEvents}>
                          <ContentIdeaDueDate
                            contentIdea={contentIdea}
                            renderInput={(props) => {
                              const { inputRef, inputProps, openPicker } =
                                props;
                              const { value } = inputProps || {};

                              return (
                                <Box
                                  component="button"
                                  ref={inputRef}
                                  display="flex"
                                  alignItems="center"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openPicker();
                                  }}
                                  disabled={!canEdit}
                                >
                                  {value ? (
                                    <Typography
                                      variant="subhead-lg"
                                      color={theme.colors?.primary.black}
                                    >
                                      {moment(value).format('MMMM D, YYYY')}
                                    </Typography>
                                  ) : (
                                    <AddButton btnText="Add Date" />
                                  )}
                                </Box>
                              );
                            }}
                          />
                        </Box>
                      </StyledBodyCell>
                      <StyledBodyCell>
                        <Box onClick={stopEvents}>
                          <ContentIdeaOwners
                            contentIdea={contentIdea}
                            renderTrigger={() => {
                              const owners =
                                contentIdea.owners.value?.users || [];

                              if (owners.length === 0) {
                                return <AddButton btnText="Owner" />;
                              }

                              if (owners.length === 1) {
                                return (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    <Avatar user={owners[0]} size={20} />
                                    <Typography
                                      variant="subhead-lg"
                                      color={theme.colors?.primary.black}
                                    >
                                      {getFullName(owners[0])}
                                    </Typography>
                                  </Box>
                                );
                              }

                              return (
                                <AvatarGroup
                                  users={owners}
                                  avatarSize={20}
                                  max={4}
                                />
                              );
                            }}
                          />
                        </Box>
                      </StyledBodyCell>
                      <StyledBodyCell
                        sx={{
                          '& .MuiButtonBase-root': {
                            width: 'fit-content',
                            padding: `0 !important`,
                          },
                        }}
                      >
                        <Box onClick={stopEvents}>
                          <ContentIdeaPillars
                            contentIdea={contentIdea}
                            renderTrigger={() => {
                              const pillars =
                                contentIdea.pillars?.value?.multiSelect || [];

                              if (pillars.length === 0) {
                                return canEdit ? (
                                  <AddButton btnText="Pillars" />
                                ) : (
                                  <Typography
                                    variant="subhead-lg"
                                    color={theme.colors?.utility[700]}
                                  >
                                    Pillars
                                  </Typography>
                                );
                              }

                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  {pillars.map((pillar) => (
                                    <ContentIdeaBadgeMenuItem
                                      {...pillar}
                                      bgcolor={pillar.bgcolor || ''}
                                    />
                                  ))}
                                </Box>
                              );
                            }}
                          />
                        </Box>
                      </StyledBodyCell>
                      <StyledBodyCell
                        sx={{
                          '& .MuiButtonBase-root': {
                            width: 'fit-content',
                            padding: 0,
                          },
                        }}
                      >
                        <Box onClick={stopEvents}>
                          <ContentIdeaPlatforms
                            contentIdea={contentIdea}
                            triggerVariant="full"
                          />
                        </Box>
                      </StyledBodyCell>
                      <StyledBodyCell>
                        <Box onClick={stopEvents}>
                          <ContentIdeaCollection contentIdea={contentIdea} />
                        </Box>
                      </StyledBodyCell>
                      {[
                        contentIdea.moodboard?.value?.collection?.id || '',
                        contentIdea.draftAssets?.value?.collection?.id || '',
                        contentIdea.finalAssets?.value?.collection?.id || '',
                      ].map((collectionId) => (
                        <StyledBodyCell sx={{ maxWidth: 600 }}>
                          <Box onClick={stopEvents}>
                            <CollectionField
                              collectionId={collectionId}
                              readOnly={!canEdit}
                              sx={{
                                backgroundColor: theme.colors?.utility[300],
                              }}
                              contentIdeaCollectionId={
                                contentIdea.collection.id
                              }
                            />
                          </Box>
                        </StyledBodyCell>
                      ))}
                      <StyledBodyCell>
                        <Box onClick={stopEvents}>
                          <ContentIdeaCaption
                            contentIdea={contentIdea}
                            renderInput={{
                              style: {
                                ...theme.typography['subhead-lg'],
                                color: theme.colors?.utility[900],
                              },
                            }}
                          />
                        </Box>
                      </StyledBodyCell>
                      <StyledBodyCell>
                        <Box onClick={stopEvents}>
                          <ContentIdeaAudio
                            contentIdea={contentIdea}
                            renderInput={{
                              style: {
                                ...theme.typography['subhead-lg'],
                                color: theme.colors?.utility[900],
                              },
                            }}
                          />
                        </Box>
                      </StyledBodyCell>
                    </TableRow>
                  );
                })
              )}
              {(loading || isFetchingMore) &&
                [...Array(4)].map((_, index) => (
                  <ContentIdeaTableRowSkeleton key={index} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollableContainer>
      {contextMenuContentIdea && contextMenuAnchorPosition && (
        <ContentIdeaContextMenu
          open
          anchorPosition={contextMenuAnchorPosition}
          anchorReference="anchorPosition"
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => setContextMenuAnchorPosition(null)}
          contentIdea={contextMenuContentIdea}
          onMouseDown={stopEvents}
          onMouseMove={stopEvents}
        />
      )}
    </>
  );
};
