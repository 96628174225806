import { NetworkStatus } from '@apollo/client';
import { Box, Checkbox, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { MoreMenuItem } from 'components/common/Menu';
import { TextSwitch } from 'components/common/TextSwitch/TextSwitch';
import { IconCustomSmiley } from 'components/icons/components/custom/IconCustomSmiley';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconLinearChainlinkLink } from 'components/icons/components/linear/IconLinearChainlinkLink';
import { IconLinearClipboardText } from 'components/icons/components/linear/IconLinearClipboardText';
import { IconLinearGrid2 } from 'components/icons/components/linear/IconLinearGrid2';
import { IconLinearGroup } from 'components/icons/components/linear/IconLinearGroup';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconLinearPercentageSquare } from 'components/icons/components/linear/IconLinearPercentageSquare';
import { IconLinearTimer1 } from 'components/icons/components/linear/IconLinearTimer1';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineBulletedList } from 'components/icons/components/outline/IconOutlineBulletedList';
import { IconOutlineStatus } from 'components/icons/components/outline/IconOutlineStatus';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { BrandConnectedCreators } from 'features/brand/components';
import {
  SOCIAL_LISTENING_POST_VIEW_SETTING,
  SOCIAL_LISTENING_TOPIC_IDS_FILTER,
  SOCIAL_LISTENING_TOPIC_RELEVANCY_FILTERS,
  SocialMediaListeningPostsGridView,
  SocialMediaListeningPostsListView,
  useSocialPostsList,
  UseSocialPostsListProps,
} from 'features/socialMediaListening';
import { SocialMediaListeningWeeklySnapShotSummary } from 'features/socialMediaListening/components';
import { SocialMediaListeningFilterSelection } from 'features/socialMediaListening/components/filterSelection/SocialMediaListeningFilterSelection';
import { FilterOption } from 'features/socialMediaListening/components/filterSelection/types';
import { SocialPostStatusData } from 'features/socialPost';
import {
  BrandSocialPostEngagementStatus,
  FollowersType,
  Platform,
  PlotFeature,
  Sentiment,
  SocialListeningPostsSortBy,
  SortOrder,
  useGetBrandsForSocialMediaListeningQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { StyledSlider } from './styles';

const filterOptions: FilterOption[] = [
  {
    key: 'platforms',
    label: 'Platforms',
    icon: <IconLinearMonitorMobile size={16} />,
    options: [
      {
        render: 'TikTok',
        value: Platform.Tiktok,
      },
      {
        render: 'Instagram',
        value: Platform.Instagram,
      },
    ],
  },
  {
    key: 'followers',
    label: 'Followers',
    icon: <IconLinearUser size={16} />,
    options: [
      {
        render: '1-10k',
        value: FollowersType.Nano,
      },
      {
        render: '10k-100k',
        value: FollowersType.Micro,
      },
      {
        render: '100k-1M',
        value: FollowersType.Macro,
      },
      {
        render: '1M+',
        value: FollowersType.Mega,
      },
    ],
  },
  {
    key: 'sentiments',
    label: 'Sentiments',
    icon: <IconCustomSmiley size={16} />,
    options: [
      {
        render: 'Positive',
        value: Sentiment.Positive,
      },
      {
        render: 'Neutral',
        value: Sentiment.Neutral,
      },
      {
        render: 'Negative',
        value: Sentiment.Negative,
      },
      {
        render: 'None',
        value: Sentiment.None,
      },
    ],
  },
  {
    key: 'statuses',
    label: 'Status',
    icon: <IconOutlineStatus size={16} />,
    options: Object.keys(SocialPostStatusData).map((status) => ({
      render: (
        <Box
          sx={{
            my: 2,
            ...SocialPostStatusData[status as BrandSocialPostEngagementStatus]
              .sx,
          }}
        >
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[800]}
            fontWeight={600}
          >
            {SocialPostStatusData[status].label}
          </Typography>
        </Box>
      ),
      value: status,
    })),
  },
];

const sortOptions = [
  {
    key: SocialListeningPostsSortBy.CreatedAt,
    label: 'Recency',
    icon: <IconLinearTimer1 size={16} />,
  },
  {
    key: SocialListeningPostsSortBy.ConfidenceScore,
    label: 'Topic Relevancy',
    icon: <IconLinearChainlinkLink size={16} />,
  },
  {
    key: SocialListeningPostsSortBy.EngagementScore,
    label: 'Post Engagement',
    icon: <IconLinearPercentageSquare size={16} />,
  },
];

export const SocialMediaListeningPage = () => {
  const {
    value: topicRelevancyFilterData,
    setValue: setTopicRelevancyFilterData,
  } = useLocalStorage<number>(SOCIAL_LISTENING_TOPIC_RELEVANCY_FILTERS, 0.5);
  const [topicRelevancy, setTopicRelevancy] = useState(
    topicRelevancyFilterData,
  );

  const [filterByOptions, setFilterByOptions] = useState(filterOptions);

  useEffect(() => {
    const sliderRender = (
      <Box sx={{ width: '100%', pl: 4, pr: 2 }}>
        <StyledSlider
          defaultValue={0.5}
          valueLabelDisplay="auto"
          marks={[
            ...Array.from({ length: 6 }, (_, i) => ({
              value: 0.5 + i * 0.1,
              label: `${(0.5 + i * 0.1) * 100}%`,
            })),
            { value: 1, label: '100%' },
          ]}
          step={0.1}
          min={0.5}
          max={1}
          onChange={(_, value) => {
            if (typeof value === 'number') {
              setTopicRelevancy(value);
            }
          }}
          value={topicRelevancy}
          valueLabelFormat={(value) => `${value * 100}%`}
        />
      </Box>
    );

    setTopicRelevancyFilterData(topicRelevancy);

    setFilterByOptions((prevOptions) => {
      const optionIndex = prevOptions.findIndex(
        (o) => o.key === 'topicRelevancy',
      );

      if (optionIndex !== -1) {
        const updatedOptions = [...prevOptions];
        updatedOptions[optionIndex] = {
          ...updatedOptions[optionIndex],
          options: [
            {
              render: sliderRender,
              value: '',
            },
          ],
        };
        return updatedOptions;
      }
      return [
        ...prevOptions,
        {
          key: 'topicRelevancy',
          label: 'Topic Relevancy',
          icon: <IconLinearGroup size={16} />,
          isMultiSelect: false,
          options: [
            {
              render: sliderRender,
              value: '',
            },
          ],
        } as FilterOption,
      ];
    });
  }, [topicRelevancy]); // eslint-disable-line

  const location = useLocation();
  const navigate = useNavigate();

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);

  const { value: view, setValue: setView } = useLocalStorage<'grid' | 'list'>(
    SOCIAL_LISTENING_POST_VIEW_SETTING,
    'grid',
  );
  const { value: filteredTopics, setValue: setFilteredTopics } =
    useLocalStorage<string[]>(SOCIAL_LISTENING_TOPIC_IDS_FILTER, []);

  const [appliedFilters, setAppliedFilters] = useState<{
    [key: string]: string[];
  }>({});
  const {
    data: brands,
    loading: brandQueryLoading,
    networkStatus,
  } = useGetBrandsForSocialMediaListeningQuery();
  const topics = brands?.brands.flatMap((brand) => brand?.topics) || [];

  const [sortBy, setSortBy] = useState(sortOptions[0].key);
  const [sortOrder, setSortOrder] = useState(SortOrder.Desc);

  useEffect(() => {
    if (!brands?.brands.length && networkStatus === NetworkStatus.ready) {
      // Data for brands is not available
      navigate(PlotRoutes.socialListeningIntro(), {
        replace: true,
      });
    }
  }, [brands, networkStatus]); // eslint-disable-line

  useEffect(() => {
    refetch();
  }, [appliedFilters, filteredTopics]); // eslint-disable-line

  // right now, we assume one for one org. We are going to change this later
  const currentBrand = brands?.brands?.[0];

  const { loading, refetch, socialPostsData, fetchMorePostSuggestions } =
    useSocialPostsList({
      brandId: currentBrand?.id ?? '',
      sortBy,
      sortOrder,
      filters: { ...appliedFilters, topicIds: filteredTopics, topicRelevancy },
    });

  const socialPosts = socialPostsData?.getPaginatedSocialPosts.data;
  const hasNextPage =
    socialPostsData?.getPaginatedSocialPosts.pageInfo.hasNextPage;

  const { setRenderTitle, setHeaderSx } = useCustomHeaderContext();

  useEffect(() => {
    setHeaderSx({
      borderBottom: `1px solid transparent`,
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.black}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
    };
  }, [location.pathname]); // eslint-disable-line

  if (brandQueryLoading) {
    return <Box>Loading...</Box>;
  }

  if (!brands?.brands.length) {
    return null;
  }

  return (
    <Box m={theme.spacing(16, 8, 8)}>
      {/* <SocialMediaListeningZeroStateView /> */}
      <Box py={4} gap={4} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
            {/* Weekly Snapshot */}
          </Typography>
          {currentBrand && <BrandConnectedCreators brand={currentBrand} />}
        </Box>

        <SocialMediaListeningWeeklySnapShotSummary />
      </Box>

      <Box>
        <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
          Recent Posts
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={4}
        >
          {/* Replace the <Box /> with the SocialMediaListeningSearchInput component when it is ready */}
          <Box />
          {/* <SocialMediaListeningSearchInput onInputChange={(query) => {}} /> */}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={4}
            gap={4}
          >
            <TextSwitch
              textOptionLeft={<IconLinearGrid2 size={14} />}
              textOptionRight={<IconOutlineBulletedList size={14} />}
              selectedOptionTextColor={theme.colors?.primary.white}
              sx={{
                bgcolor: theme.colors?.utility[275],
                height: theme.spacing(10),
                width: theme.spacing(20),
                p: theme.spacing(0.5),
                borderRadius: theme.spacing(25),
              }}
              isLeftOptionSelected={view === 'grid'}
              onClick={(isLeftOptionSelected) =>
                setView(isLeftOptionSelected ? 'grid' : 'list')
              }
              componentProps={{
                text: { sx: { color: theme.colors?.primary.black } },
                indicator: {
                  sx: {
                    bgcolor: theme.colors?.primary.black,
                    borderRadius: theme.spacing(25),
                    height: '38px',
                  },
                },
              }}
            />
            <ContextMenu
              sx={{
                '& .MuiMenu-list': {
                  padding: `${theme.spacing(3)} !important`,
                  gap: `${theme.spacing(1)} !important`,
                },
                '& li': {
                  p: theme.spacing(1, 2),
                },
                '& li:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              renderButton={() => (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: theme.spacing(3),
                    backgroundColor: theme.colors?.utility[275],
                    p: theme.spacing(3, 3),
                  }}
                >
                  <IconLinearClipboardText size={16} />
                  <Typography variant="body-xl" fontWeight={500}>
                    {!filteredTopics.length ||
                    filteredTopics.length === topics.length
                      ? 'All Topics Selected'
                      : `${filteredTopics.length} ${
                          filteredTopics.length > 1 ? 'Topics' : 'Topic'
                        } Selected`}
                  </Typography>
                </Box>
              )}
              options={[
                ...topics.map((topic) => ({
                  renderOption: () => (
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      width="100%"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFilteredTopics((prev) => {
                          if (prev.includes(topic.id)) {
                            return prev.filter((id) => id !== topic.id);
                          }
                          return [...prev, topic.id];
                        });
                      }}
                    >
                      <Checkbox
                        disableRipple
                        checked={filteredTopics.includes(topic.id)}
                        sx={{
                          p: 1,
                          color: theme.colors?.primary.black,
                          '&.MuiCheckbox-root': {
                            color: theme.colors?.primary.black,
                          },
                        }}
                      />
                      <Typography variant="body-lg">{topic.name}</Typography>
                    </Box>
                  ),
                })),
                {
                  renderOption: () => (
                    <Box
                      sx={{
                        width: '100%',
                        my: theme.spacing(2),
                        borderTop: `1px solid ${theme.colors?.utility[500]}`,
                      }}
                    />
                  ),
                },
                {
                  renderOption: () => (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <MoreMenuItem
                        disableRipple
                        sx={{
                          minWidth: 240,
                          width: '100%',
                        }}
                        label={
                          <Typography
                            display="inline-block"
                            variant="body-md"
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                              borderRadius: theme.spacing(2),
                              p: theme.spacing(3, 2),
                              '&:hover': {
                                backgroundColor: theme.colors?.utility[275],
                              },
                            }}
                          >
                            Create a New Topic
                            <IconLinearArrowRight size={16} />
                          </Typography>
                        }
                      >
                        <Box
                          sx={{
                            width: 270,
                            py: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          <Link
                            to={PlotRoutes.socialListeningOnboarding('topic')}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                padding: theme.spacing(3),
                                borderRadius: theme.spacing(3),
                                '&:hover': {
                                  backgroundColor: theme.colors?.utility[275],
                                },
                              }}
                            >
                              <Typography variant="body-lg" fontWeight={500}>
                                Topic Insights
                              </Typography>
                              <Typography
                                variant="body-sm"
                                fontWeight={500}
                                color={theme.colors?.utility[900]}
                              >
                                Track conversations across customized topics
                              </Typography>
                            </Box>
                          </Link>
                          {creatorTrackingEnabled && (
                            <Link
                              to={PlotRoutes.socialListeningOnboarding(
                                'creator',
                              )}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 2,
                                  padding: theme.spacing(3),
                                  borderRadius: theme.spacing(3),
                                  '&:hover': {
                                    backgroundColor: theme.colors?.utility[275],
                                  },
                                }}
                              >
                                <Typography variant="body-lg" fontWeight={500}>
                                  Creator Focus
                                </Typography>
                                <Typography
                                  variant="body-sm"
                                  fontWeight={500}
                                  color={theme.colors?.utility[900]}
                                >
                                  Track specific creators discussing your key
                                  topics
                                </Typography>
                              </Box>
                            </Link>
                          )}
                        </Box>
                      </MoreMenuItem>
                    </Box>
                  ),
                },
              ]}
            />
            <SocialMediaListeningFilterSelection
              filterBy={filterByOptions}
              sortBy={sortOptions}
              onFilterUpdated={(filter) => {
                setAppliedFilters(filter);
              }}
              onSortUpdated={(key, order) => {
                setSortBy(key as SocialListeningPostsSortBy);
                setSortOrder(order);
              }}
            />
          </Box>
        </Box>
      </Box>

      <InfiniteScroll
        hasMore={hasNextPage}
        initialLoad={false}
        loadMore={fetchMorePostSuggestions}
        threshold={1024}
        getScrollParent={() =>
          document.querySelector(
            '.collection-cjb-window-view-scrollable-container',
          )
        }
      >
        {socialPosts?.length && socialPosts?.length > 0 ? (
          <Box
            sx={{
              maxWidth: `calc(100vw - ${theme.spacing(16)})`,
              overflowX: 'auto',
            }}
          >
            {view === 'grid' ? (
              <SocialMediaListeningPostsGridView
                socialPosts={socialPosts}
                searchCriteria={window.btoa(
                  JSON.stringify({
                    brandId: currentBrand?.id ?? '',
                    filters: { ...appliedFilters, topicIds: filteredTopics },
                    sortBy,
                    sortOrder,
                  } as UseSocialPostsListProps),
                )}
              />
            ) : (
              <SocialMediaListeningPostsListView
                socialPosts={socialPosts}
                searchCriteria={window.btoa(
                  JSON.stringify({
                    brandId: currentBrand?.id ?? '',
                    filters: { ...appliedFilters, topicIds: filteredTopics },
                    sortBy,
                    sortOrder,
                  } as UseSocialPostsListProps),
                )}
              />
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <Typography variant="body-lg" color={theme.colors?.utility[700]}>
              {loading ? 'Loading...' : 'No posts'}
            </Typography>
          </Box>
        )}
      </InfiniteScroll>
    </Box>
  );
};
