import { BillingPlanName, useGetBillingPlanQuery } from 'graphql/generated';
import { gql } from '@apollo/client';

gql`
  query GetBillingPlan($plan: String!) {
    billingPlan(plan: $plan) {
      name
      numberOfTrialDays
      organizationMemberLimit
    }
  }
`;

export const useBillingPlanData = (plan: BillingPlanName) => {
  const planData = useGetBillingPlanQuery({
    variables: {
      plan,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    numberOfTrialDays: planData.data?.billingPlan.numberOfTrialDays ?? 7,
    organizationMemberLimit: planData.data?.billingPlan.organizationMemberLimit,
  };
};
