export enum OnboardingStates {
  BrandIntro = 'brandIntro',
  CompetitorsIntro = 'competitorsIntro',
  AddCompetitors = 'addCompetitors',
  CompetitorsDetails = 'competitorsDetails',
  AskBrandName = 'askBrandName',
  AboutBrandForm = 'aboutBrandForm',
  AskTopicName = 'askTopicName',
  AskTopicDescription = 'askTopicDescription',
  CreateTopic = 'createTopic',
  SocialHandleForm = 'socialHandleForm',
  PostPriorityInfo = 'postPriorityInfo',
  CommunicationStrategy = 'communicationStrategy',
  Keywords = 'keywords',
  PostHistoryPreference = 'postHistoryPreference',
  OnboardingComplete = 'onboardingComplete',
  CountrySelection = 'countrySelection',
  TikTokCreatorTracking = 'tikTokCreatorTracking',
  AddAnotherTopic = 'addAnotherTopic',
  InstagramCreatorTracking = 'instagrmCreatorTracking',
}
