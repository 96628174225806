import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { CompetitorDetailType } from '../../SocialMediaListeningCompetitorsOnboardingView';
import { CompetitorDetail } from './sections/competitorDetail/CompetitorDetail';
import { ManageCompetitors } from './sections/manageCompetitors/ManageCompetitors';

type Props = {
  brandId: string;
  competitorsData: CompetitorDetailType[];
  updateCompetitorsData: (competitors: CompetitorDetailType[]) => void;

  onPrev: () => void;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingCompetitorsDetails = ({
  brandId,
  competitorsData,
  updateCompetitorsData,
  onNext,
  onPrev,
}: Props) => {
  const [saving, setSaving] = useState(false);

  const [activeUntilIndex, setActiveUntilIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const onSubmit = async () => {
    onNext();
  };

  return (
    <Box
      width="60vw"
      display="flex"
      gap={4}
      height="70vh"
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{
        overflowY: 'auto',
        py: theme.spacing(12),
        my: theme.spacing(12),
      }}
    >
      <Typography
        variant="body-lg"
        sx={{
          fontWeight: 600,
          width: 'fit-content',
          padding: theme.spacing(1, 3),
          bgcolor: 'rgba(250, 243, 236, 0.20)',
          borderRadius: theme.spacing(4),
        }}
      >
        Competitor Analytics
      </Typography>
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        Add competitor details
      </Typography>

      <Typography variant="body-xl" fontWeight={500}>
        {currentIndex + 1} of {competitorsData.length}
      </Typography>

      {competitorsData.map((competitor, index) => {
        if (index !== currentIndex) return null;

        return (
          <CompetitorDetail
            saving={saving}
            componentProps={{
              sx: {
                display: index === currentIndex ? 'flex' : 'none',
              },
            }}
            handleSubmit={async () => {
              if (index < competitorsData.length - 1) {
                setCurrentIndex(currentIndex + 1);
                setActiveUntilIndex(currentIndex + 1);
              } else if (currentIndex === competitorsData.length - 1) {
                setSaving(true);
                await onSubmit();
                setSaving(false);
              }
            }}
            competitorDetail={competitorsData[currentIndex]}
            updateCompetitorDetail={(competitorDetail) => {
              const existingCompetitorsData = [...competitorsData];
              existingCompetitorsData[currentIndex] = competitorDetail;
              updateCompetitorsData(existingCompetitorsData);
            }}
          />
        );
      })}

      <ManageCompetitors
        competitors={competitorsData}
        updateCompetitors={updateCompetitorsData}
        activeUntilIndex={activeUntilIndex}
        currentIndex={currentIndex}
        removeCompetitor={(index) => {
          const updatedCompetitors = competitorsData.filter(
            (_, competitorIndex) => index !== competitorIndex,
          );
          updateCompetitorsData(updatedCompetitors);
          setCurrentIndex(currentIndex - 1 > 0 ? currentIndex - 1 : 0);
          setActiveUntilIndex(
            activeUntilIndex - 1 > 0 ? activeUntilIndex - 1 : 0,
          );
        }}
        onClick={(index) => {
          setCurrentIndex(index);
        }}
      />
    </Box>
  );
};
