import { Box, Skeleton, SxProps } from '@mui/material';
import { Iframely } from 'components/common/Iframely';
import { useFallbackMetadata } from 'components/common/Iframely/hooks/useFallbackMetadata';
import { Image } from 'components/common/Image';
import {
  PostNotePreviewCompactViewProps,
  PostNotePreviewViewProps,
} from 'features/post/views';
import { PostNotePreviewView } from 'features/post/views/notePreview/PostNotePreviewView';
import { PostNotePreviewCompactView } from 'features/post/views/notePreviewCompact/PostNotePreviewCompactView';
import { PostFragmentPostPreviewFragment, PostType } from 'graphql/generated';
import { ResourceUploadType } from 'hooks/useResourceUploadQueue';
import { useResolveResourcePreview } from 'hooks/useResourceUploadQueue/useResolveResourcePreview';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { StyledContainer } from './styles';
import { getCustomPostPreviewStyles } from './utils';

export type PostPreviewProps = {
  post: PostFragmentPostPreviewFragment;
  sx?: SxProps;
  variant?: 'default' | 'compact';
  disableMouseEvents?: boolean;
  componentsProps?: {
    postNotePreview?: Pick<PostNotePreviewViewProps, 'sx' | 'componentsProps'>;
    postNotePreviewCompact?: Pick<
      PostNotePreviewCompactViewProps,
      'sx' | 'componentsProps'
    >;
  };
};

export const PostPreview = (props: PostPreviewProps) => {
  const {
    post,
    sx,
    variant = 'default',
    componentsProps,
    disableMouseEvents,
  } = props;

  // FIXME: Right now we are using the first urlMetadata object.
  // Not sure how this plays out in the future. Keep watch.
  const _urlMetadata = post.urlMetadata?.[0];

  // Check if we need to construct a fallback urlMetadata
  // Mostly used when users just created a new post and BE jobs are still processing urlMetadata
  const { fallbackUrlMetadata, shouldUseFallbackUrlMetadata } =
    useFallbackMetadata({
      url: _urlMetadata?.url || '',
      urlMetadata: _urlMetadata,
    });

  const urlMetadata = useMemo(() => {
    if (shouldUseFallbackUrlMetadata) {
      return fallbackUrlMetadata;
    }

    return _urlMetadata;
  }, [shouldUseFallbackUrlMetadata, fallbackUrlMetadata, _urlMetadata]);
  // ======

  const site = urlMetadata?.metadata?.site || '';

  const thumbnail = useMemo(() => {
    const thumbnails = urlMetadata?.metadata?.thumbnail || [];
    return thumbnails.reduce((prev, current) => {
      if (
        prev.mediaSize &&
        current.mediaSize?.width &&
        prev.mediaSize.width < current.mediaSize.width
      ) {
        return current;
      }
      return prev;
    }, thumbnails[0]);
  }, [urlMetadata]);
  const thumbnailUrl = thumbnail?.href || '';
  const mimeType = urlMetadata?.metadata?.type || '';

  const {
    isLoading: isLoadingPreview,
    isImagePreview,
    previewUrl,
  } = useResolveResourcePreview({
    iconSize: 64,
    type: ResourceUploadType.Attachment,
    content: urlMetadata?.url || '',
    name: '',
    mimeType,
    skip: Boolean(thumbnailUrl),
  });

  const finalThumbnailUrl = thumbnailUrl || previewUrl;
  const isShowingCustomPreviewIcon =
    !thumbnailUrl && !!previewUrl && !isImagePreview;

  // Track if we can't load the image preview
  const [hasImagePreviewError, setHasImagePreviewError] = useState(false);

  const renderCardContent = () => {
    if (post.type === PostType.Note) {
      if (variant === 'default') {
        return (
          <PostNotePreviewView
            post={post}
            {...componentsProps?.postNotePreview}
          />
        );
      }

      return (
        <PostNotePreviewCompactView
          post={post}
          {...componentsProps?.postNotePreviewCompact}
        />
      );
    }

    // Only show skeleton if:
    // 1. We don't have a finalThumbnailUrl, AND
    // 2. We are still loading the preview for fallback (see useResolveResourcePreview)
    if (!finalThumbnailUrl && isLoadingPreview) {
      return (
        <Skeleton
          sx={{
            width: '100%',
            height: '100%',
            minHeight: 'inherit',
            bgcolor: theme.colors?.utility[500],
            transform: 'scale(1)',
          }}
        />
      );
    }

    // Skip PDF icon as thumbnail. We have custom logic to render it
    if (
      finalThumbnailUrl &&
      finalThumbnailUrl !== '/file-icons/PDF.svg' &&
      !hasImagePreviewError
    ) {
      return (
        <Image
          src={finalThumbnailUrl}
          alt={post.title || ''}
          sx={{
            width: isShowingCustomPreviewIcon ? '64px' : '100%',
            m: 'auto',
            bgcolor: theme.colors?.utility[300],
            outline: 'none',
            border: 'none',
            objectFit: isShowingCustomPreviewIcon ? 'contain' : 'cover',
            display: 'block', // This will remove the default margin that browsers apply to images
            height: '100%', // Set the height to ensure the image covers the entire container
            minHeight: 'inherit',
          }}
          onError={() => setHasImagePreviewError(true)}
        />
      );
    }

    return <Iframely urlMetadata={urlMetadata} url={urlMetadata?.url || ''} />;
  };

  return (
    <StyledContainer
      sx={{
        ...sx,
        ...getCustomPostPreviewStyles(site),
        pointerEvents: disableMouseEvents ? 'none' : 'auto',
      }}
    >
      {disableMouseEvents && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            minHeight: 'inherit',
          }}
        />
      )}
      <Box
        style={{
          height: '100%',
          minHeight: 'inherit',
          display: 'flex',
        }}
      >
        {renderCardContent()}
      </Box>
    </StyledContainer>
  );
};
