import {
  BubbleMenu as BubbleMenuContainer,
  Editor,
  EditorContent,
  isTextSelection,
  useEditor,
} from '@tiptap/react';
import {
  MasonryExtensionName,
  NODE_EMBED,
  NODE_POST,
  useEditorExtensions,
} from 'features/tiptap';
import { NodeSelection } from 'prosemirror-state';
import React, { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { parseToHtml } from 'utils/html';
import { BubbleToolBar } from './components';
import { generateCustomExtensions } from './generateCustomExtensions';
import { useCommentHandlers } from './hooks/useCommentHandlers';
import { useValidateComments } from './hooks/useValidateComments';
import { NoteRichTextContainer } from './styles';
import {
  NoteRichTextEditorMode,
  NoteRichTextEditorProps,
  NoteRichTextEditorRef,
} from './types';

export const INNER_CONTENT_MAX_WIDTH = 740;
export const BOTTOM_PADDING = 150;

export const NoteRichTextEditor = React.forwardRef(
  (
    {
      provider,
      extraExtensions = [],
      defaultContent,
      placeholder,
      themeColor,
      editable = true,
      autoFocus = false,
      componentsProps = {},
      customNodeAttrs,
      customExtensionOptions,
      useEditorExtensionsProps,
      mode = NoteRichTextEditorMode.Full,
      onArrowUp,
      onContentSet,
      onContentChange,
      renderToolbar,
      ...rest
    }: NoteRichTextEditorProps,
    ref: React.ForwardedRef<NoteRichTextEditorRef>,
  ) => {
    const [isPendingSyncFromProvider, setIsPendingSyncFromProvider] = useState(
      Boolean(provider),
    );

    const [editorInState, setEditorInState] = useState<Editor | null>(null);
    const { revalidate } = useValidateComments({
      editor: editorInState,
      content: defaultContent || '',
    });

    useEffect(() => {
      if (editorInState) {
        revalidate();
      }
    }, [editorInState]); // eslint-disable-line

    const {
      commentHighlightStyles,
      handlersRender: commentHandlersRender,
      onTriggerAddComment,
      onCommentMarkClick,
    } = useCommentHandlers({
      onCreateComment: customExtensionOptions?.comment?.onCreateComment,
      onCommentDeleted: revalidate,
      themeColor,
    });

    const { extensions } = useEditorExtensions({
      starterKit: {
        // disable history because note uses collaboration history
        // this is not very generic since it assumes that note always has collaboration extension
        history: false,
      },
      placeholder: {
        placeholder,
      },
      mention: true,
      command: true,
      embed: {
        onTriggerAddComment,
        commentable:
          mode === NoteRichTextEditorMode.CommentOnly ||
          mode === NoteRichTextEditorMode.Full,
        editable: mode === NoteRichTextEditorMode.Full,
        captionable: true,
      },
      ...useEditorExtensionsProps,
    });
    const customExtensions = useMemo(
      () => {
        return generateCustomExtensions({
          taskItem: customNodeAttrs?.taskItem,
          masonry: customNodeAttrs?.masonry,
          comment: {
            onTriggerAddComment,
            onClick: onCommentMarkClick,
          },
          themeColor,
        });
      },
      // eslint-disable-next-line
      [
        // eslint-disable-next-line
        JSON.stringify({
          customNodeAttrs,
          customExtensionOptions,
          themeColor,
          mode,
        }),
      ],
    );

    React.useImperativeHandle(ref, () => ({
      focus: (...all) => {
        editor?.commands.focus(...all);
      },
      clear: () => {
        editor?.commands.clearContent();
      },
      setDefaultContent: (content: string) => {
        editor?.commands.setContent(parseToHtml(content));
      },
      editor,
    }));

    const editor = useEditor(
      {
        extensions: [...extensions, ...customExtensions, ...extraExtensions],
        editable: editable && !isPendingSyncFromProvider,
        onUpdate({ editor }) {
          const htmlValue = editor.getHTML();

          if (isPendingSyncFromProvider) {
            return;
          }

          // Only call update mutation if editable = true
          if (editable && onContentChange) {
            onContentChange(htmlValue);
          }
        },
        editorProps: {
          // Disable all keydown events if the editor is in comment-only mode
          ...(mode === NoteRichTextEditorMode.CommentOnly
            ? {
                handleKeyDown: () => {
                  return true;
                },
              }
            : {}),
          handleDOMEvents: {
            // Disable default dragstart event to prevent dragging the editor content
            // We'll only allow dragging using the drag handle
            dragstart: (view, event) => {
              event.preventDefault();
              return true;
            },
          },
          ...componentsProps.editor,
          attributes: {
            innerContentMaxWidth: `${INNER_CONTENT_MAX_WIDTH}`,
            bottomPadding: `${BOTTOM_PADDING}`,
            ...componentsProps.editor?.attributes,
          },
        },
        onCreate: componentsProps.editor?.onCreate,
        content: defaultContent,
      },
      [
        editable,
        // mode
        isPendingSyncFromProvider,
      ],
    );

    // Set default content state
    useEffect(() => {
      const setDefaultContent = () => {
        if (!editor) {
          return;
        }

        if (defaultContent && editor.getText() === '') {
          // in case of collaboration, if ydoc does not have any content in the server (Liveblocks), set content from our DB.
          editor.commands.setContent(parseToHtml(defaultContent));
        }

        onContentSet?.();
      };

      if (provider) {
        // for collaboration:
        // The sync event is triggered when the client has received content from the server.
        provider.on('sync', () => {
          setDefaultContent();
          setIsPendingSyncFromProvider(false);
        });

        return () => provider.off('sync', setDefaultContent);
      }

      // for non-collaboration, just set from our DB
      setDefaultContent();
    }, [provider, editor]); // eslint-disable-line

    useEffect(() => {
      if (editor && autoFocus) {
        editor?.commands.focus('end');
      }

      setEditorInState(editor);
    }, [editor]); // eslint-disable-line -- run once on editor mount

    const isBubbleMenuVisible = React.useMemo(() => {
      const isEmptyTextBlock =
        !editor?.view.state.doc.textBetween(
          editor?.view.state.selection.from,
          editor?.view.state.selection.to,
        ).length && isTextSelection(editor?.state.selection);

      // check by extension name
      const isMasonryBlock =
        editor?.view.state.tr.selection instanceof NodeSelection &&
        editor.view.state.tr.selection.node.type.name === MasonryExtensionName;

      const isEmbedBlock =
        editor?.view.state.tr.selection instanceof NodeSelection &&
        editor.view.state.tr.selection.node.type.name === NODE_EMBED;

      const isPostBlock =
        editor?.view.state.tr.selection instanceof NodeSelection &&
        editor.view.state.tr.selection.node.type.name === NODE_POST;

      // When clicking on a element inside the bubble menu the editor "blur" event
      // is called and the bubble menu item is focussed. In this case we should
      // consider the menu as part of the editor and keep showing the menu
      const element = document.querySelector('.key-bubble-menu');
      const isChildOfMenu = element?.contains(document.activeElement);

      const hasEditorFocus = editor?.view.hasFocus() || isChildOfMenu;

      // Check if the selection is the masonry => not show the bubble menu
      return !(
        isPostBlock ||
        isEmbedBlock ||
        isMasonryBlock ||
        !hasEditorFocus ||
        editor?.view.state.selection.empty ||
        isEmptyTextBlock ||
        !editor?.isEditable
      );
    }, [editor?.isEditable, editor?.state.selection, editor?.view]);

    // Update custom extension attrs to all related nodes
    useEffect(() => {
      if (editor && customNodeAttrs) {
        Object.keys(customNodeAttrs).forEach((nodeName) => {
          // @ts-ignore
          editor.state.doc.descendants((node, pos) => {
            if (node.type.name === nodeName) {
              const tr = editor.state.tr;

              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                externalAttrs: customNodeAttrs[nodeName],
              });

              editor.view.dispatch(tr);
            }
          });
        });
      }
      // eslint-disable-next-line
    }, [editor, JSON.stringify(customNodeAttrs)]);

    return (
      <NoteRichTextContainer
        sx={{
          "ul[data-type='taskList'] p": {
            marginRight: theme.spacing(2),
          },
          '.tiptap': {
            paddingBottom: `${BOTTOM_PADDING}px`,
            '> *': {
              maxWidth: INNER_CONTENT_MAX_WIDTH,
            },
          },
          ...commentHighlightStyles,
        }}
        className="NoteRichTextEditor"
        themeColor={themeColor}
        {...componentsProps.container}
      >
        {editor && editable && (
          <BubbleMenuContainer
            className={`bubble-menu-container ${
              isBubbleMenuVisible ? 'visible' : ''
            }`}
            editor={editor}
            tippyOptions={{
              duration: 300,
              maxWidth: mode === NoteRichTextEditorMode.Full ? '100vw' : 60,
            }}
          >
            <BubbleToolBar
              editor={editor}
              sx={
                isBubbleMenuVisible
                  ? undefined
                  : { visibility: 'hidden', pointerEvents: 'none' }
              }
              mode={mode}
            />
          </BubbleMenuContainer>
        )}

        <EditorContent
          editor={editor}
          multiple
          style={{
            ...theme.typography['subhead-xl'],
            color: themeColor?.textColor,
            flex: 1,
            paddingLeft: theme.spacing(3),
            // Hide caret in comment-only mode
            ...(mode === NoteRichTextEditorMode.CommentOnly
              ? {
                  caretColor: 'transparent',
                }
              : {}),
            ...rest.style,
          }}
          {...rest}
        />

        {editor && renderToolbar && renderToolbar(editor, isBubbleMenuVisible)}

        {commentHandlersRender}
      </NoteRichTextContainer>
    );
  },
);
