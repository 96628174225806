import { gql } from '@apollo/client';
import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  Popover,
  PopoverProps,
  TextField,
  Typography,
} from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import {
  COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
  CollectionListItemView,
  CollectionMenuItemAddNewView,
  CollectionMenuItemView,
  CollectionMenuItemWithPostPermissionAlertView,
} from 'features/collection';
import { useAutocompleteInfiniteCollections } from 'features/collection/hooks/useAutocompleteInfiniteCollections';
import {
  CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
  ContentCalendarCollectionMenuItem,
} from 'features/contentCalendar';
import { useSaveTrendHandler } from 'features/trending';
import {
  CollectionFragmentCollectionMenuItemViewFragmentDoc,
  CollectionPermission,
  GeneralPermission,
  PlotFeature,
  PostFragmentPostBookmarkButtonFragment,
  SearchHitType,
  useGetPostForPostBookmarkPopoverQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { usePostHandlers } from './usePostHandlers';

// eslint-disable-next-line
gql`
  query GetPostForPostBookmarkPopover($id: String!) {
    post(id: $id) {
      id
      ...PostFragmentPostPreview
      collections {
          id
        ...CollectionFragmentCollectionMenuItemView
      }
    }
    ${CollectionFragmentCollectionMenuItemViewFragmentDoc}
  }
`;

type AutocompleteOptionType = {
  value: string;
  label: string;
};

const filter = createFilterOptions<AutocompleteOptionType>();

export type PostBookmarkPopoverProps = PopoverProps & {
  post: PostFragmentPostBookmarkButtonFragment;

  /**
   * This is a workaround to disable the save button in the header,
   * when we are using this component for the trends.
   * We should build a separate component for the trends.
   */
  disableHeaderSaveButton?: boolean;
};

const SAVED_TREND_COLLECTION_ID = 'SAVED_TREND_COLLECTION_ID';

export const PostBookmarkPopover = (props: PostBookmarkPopoverProps) => {
  const { post, disableHeaderSaveButton, ...rest } = props;

  const { orgBilling } = useUserContext();

  // const showTrendingCollection = !!post.trend;
  const showTrendingCollection = true;
  const isSavedToTrendsCollection = post.isFavoriteTrend;

  const { user } = useUserContext();
  const [searchStr, setSearchStr] = useState('');
  const {
    autoCompleteProps,
    collectionSearchHits = [],
    loadCollectionsData,
    refetchCollections,
    fetchingCollections: loading,
  } = useAutocompleteInfiniteCollections({
    postIds: [post.id],
    searchStr,
  });

  const { isFeatureEnabled } = useFeatureFlagContext();

  useEffect(() => {
    loadCollectionsData();
  }, []); // eslint-disable-line

  // Get list of collections that the post is already saved to
  const { data: postData, refetch: refetchPost } =
    useGetPostForPostBookmarkPopoverQuery({
      variables: {
        id: post.id,
      },
      fetchPolicy: 'cache-and-network',
    });

  const collectionsPostHasBeenSavedTo = useMemo(() => {
    return postData?.post?.collections || [];
  }, [postData?.post?.collections]);

  const collectionIdsPostHasBeenSavedTo = [
    ...(isSavedToTrendsCollection ? [SAVED_TREND_COLLECTION_ID] : []),
    ...collectionsPostHasBeenSavedTo.map((c) => c.id),
  ].map((x) => x) as string[];

  const collections = useMemo(() => {
    return [
      ...(searchStr === ''
        ? [
            ...collectionsPostHasBeenSavedTo.map((x) => ({
              ...x,
              searchHitType: SearchHitType.Regular,
            })),
          ]
        : []),
      ...collectionSearchHits
        .map((x) => ({
          ...x.item,
          searchHitType: x.type,
        }))
        .filter((c) => c.myPermissions.includes(CollectionPermission.Update))
        .filter((c) =>
          searchStr === ''
            ? !collectionIdsPostHasBeenSavedTo.includes(c.id)
            : true,
        ),
    ];
  }, [
    collectionIdsPostHasBeenSavedTo,
    collectionSearchHits,
    collectionsPostHasBeenSavedTo,
    searchStr,
  ]);

  const options = useMemo(() => {
    const collectionOptions = collections
      .map((collection) => ({
        value: collection.id,
        label: collection.name,
      }))
      .sort((a, b) => {
        // Push selected collections to the top
        const aIsSelected = collectionIdsPostHasBeenSavedTo.includes(a.value)
          ? 1
          : 0;
        const bIsSelected = collectionIdsPostHasBeenSavedTo.includes(b.value)
          ? 1
          : 0;

        return bIsSelected - aIsSelected;
      });

    if (showTrendingCollection) {
      collectionOptions.unshift({
        value: SAVED_TREND_COLLECTION_ID,
        label: 'Trending',
      });
    }

    return collectionOptions;
  }, [collections]); // eslint-disable-line -- we don't add value here because we don't want to re-sort the options when the value changes

  const analytics = useAnalytics();

  const { onTogglePostCollection, onTogglePostFavorite } = usePostHandlers();
  const { onSelectTrendCollection } = useSaveTrendHandler({
    postId: post.id,
    isSavedToTrend: isSavedToTrendsCollection,
  });

  const onSelectCollection = async (collectionId: string) => {
    // === Analytics ===
    const collection = collections.find((c) => c.id === collectionId);
    if (collection?.searchHitType === SearchHitType.Smart) {
      // Find the position of the collection
      const collectionPosition = collections.findIndex(
        (c) => c.id === collection.id,
      );

      analytics.track(EventName.SuggestedCollectionSelected, {
        collectionId: collection.id,
        position: collectionPosition,
      });
    }
    // === End of Analytics ===

    onTogglePostCollection(
      post.id,
      collectionId,
      !collectionIdsPostHasBeenSavedTo.includes(collectionId),
    ).then(() => {
      refetchPost();
    });
  };

  const { triggerCommand } = useCommandContext();

  return (
    <Popover
      anchorOrigin={{ vertical: 'center', horizontal: 40 }} // offset 40px to the right
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      disablePortal
      PaperProps={{
        sx: {
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(24px)',
          boxShadow:
            '0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
          border: 'none',
        },
      }}
      {...rest}
    >
      <Box
        sx={{
          width: 320,
          color: theme.colors?.primary.black,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          sx={{
            py: 4,
            px: 6,
            bgcolor: 'rgba(35, 6, 3, 0.1)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CollectionListItemView
            collection={{
              id: 'saved',
              name: 'Saved',
              __typename: 'CollectionModel',
              generalPermission: GeneralPermission.OrganizationMembers,
              inviteMembers: [],
              hasPreviewPost: false,
              organizationName: '',
              breadcrumbsFromRoot: [],
            }}
            componentsProps={{
              name: {
                sx: {
                  fontWeight: 600,
                },
              },
            }}
          />
          <IconButtonWithTooltip
            tooltip={`${post.isFavorite ? 'Unsave' : 'Save'} this post`}
            size="small"
            sx={{
              width: 24,
              height: 24,
              p: 0,
            }}
            onClick={() => onTogglePostFavorite(post.id, !post.isFavorite)}
            disabled={disableHeaderSaveButton}
          >
            <IconBoldSave
              color={
                post.isFavorite ? theme.colors?.primary.black : '#23060333'
              }
              size={24}
            />
          </IconButtonWithTooltip>
        </Box>

        <Box
          sx={{
            flex: 1,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="headline-xxs"
            color={theme.colors?.utility[700]}
            mb={2}
            px={2}
            display="block"
          >
            Collections
          </Typography>

          <Autocomplete
            ListboxProps={autoCompleteProps.ListboxProps}
            ListboxComponent={autoCompleteProps.ListBoxComponent}
            inputValue={searchStr}
            onInputChange={(_, value, reason) => {
              if (reason === 'input') {
                setSearchStr(value);
              }
            }}
            open
            multiple
            value={[
              ...options.filter((o) =>
                collectionIdsPostHasBeenSavedTo.includes(o.value),
              ),
            ]}
            options={options}
            loading={loading}
            loadingText={
              <Box display="flex" gap={1}>
                <Typography
                  variant="headline-xs"
                  color={theme.colors?.utility[600]}
                >
                  Looking for your collection
                </Typography>
                <CircularProgress
                  sx={{ color: theme.colors?.utility[700] }}
                  size={16}
                />
              </Box>
            }
            disablePortal
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                placeholder="Search"
                sx={{
                  px: 2,
                  '.MuiOutlinedInput-root': {
                    height: '32px',
                    borderRadius: 100,
                    py: '6px !important',
                    px: '12px !important',
                    bgcolor: theme.colors?.primary.white,
                    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',

                    input: {
                      ...typography['subhead-lg'],
                      p: '0 !important',
                    },

                    '.MuiOutlinedInput-notchedOutline': {
                      display: 'none !important',
                    },
                  },
                }}
              />
            )}
            renderTags={() => null}
            renderOption={(props, _option) => {
              const option = _option as AutocompleteOptionType;
              if (
                user?.organization &&
                option.value === SAVED_TREND_COLLECTION_ID
              ) {
                return (
                  <CollectionMenuItemView
                    key={option.value}
                    selectedCollectionIds={collectionIdsPostHasBeenSavedTo}
                    onClick={onSelectTrendCollection}
                    collection={{
                      id: SAVED_TREND_COLLECTION_ID,
                      hasPreviewPost: false,
                      organizationName: user.organization.name,
                      generalPermission: GeneralPermission.InviteOnly,
                      myPermissions: [
                        CollectionPermission.Update,
                        CollectionPermission.Read,
                      ],
                      name: 'My Saved Trends',
                      inviteMembers: [],
                      __typename: 'CollectionModel',
                      breadcrumbsFromRoot: [],
                    }}
                    componentProps={{
                      menu: {
                        sx: {
                          display: 'none',
                        },
                      },
                    }}
                  />
                );
              }

              if (option.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY) {
                return (
                  <CollectionMenuItemAddNewView
                    {...props}
                    key={option.value}
                    label={option.label}
                  />
                );
              }
              const commonMenuItemProps = {
                componentProps: {
                  listItem: {
                    sx: {
                      maxWidth: 240,
                    },
                  },
                  menu: {
                    sx: {
                      '& .MuiPaper-root': {
                        boxShadow:
                          'rgba(24, 39, 75, 0.12) 0px 12px 42px -4px, rgba(24, 39, 75, 0.12) 0px 8px 18px -6px !important',
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                        backdropFilter: 'blur(24px)',
                        width: 310,
                      },
                    },
                  },
                },
              };
              if (
                option.value === CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY
              ) {
                return (
                  <ContentCalendarCollectionMenuItem
                    key={option.value}
                    onClick={(selectedCollection) => {
                      if (selectedCollection) {
                        onSelectCollection(selectedCollection.id);
                      }
                    }}
                    selectedCollectionIds={collectionIdsPostHasBeenSavedTo}
                    {...commonMenuItemProps}
                  />
                );
              }

              const collection = collections.find((c) => c.id === option.value);

              if (collection) {
                return (
                  <CollectionMenuItemWithPostPermissionAlertView
                    key={collection.id}
                    postIds={[post.id]}
                    collection={collection}
                    onClick={(selectedCollection) =>
                      onSelectCollection(selectedCollection.id)
                    }
                    selectedCollectionIds={collectionIdsPostHasBeenSavedTo}
                    shouldShowBreadcrumbsForRoot
                    {...commonMenuItemProps}
                    componentProps={{
                      ...commonMenuItemProps.componentProps,
                      listItem: {
                        ...commonMenuItemProps.componentProps.listItem,
                        isSmartSearchResult:
                          collection.searchHitType === SearchHitType.Smart,
                      },
                    }}
                  />
                );
              }

              return null;
            }}
            onChange={(event, value, reason, details) => {
              const option = (details?.option as AutocompleteOptionType) || {};

              // Only handle when user selects the add new option
              // For other options, we handle them in renderOption & only accept click event
              // Reason: It's confusing to let default keyboard events work here, because users can randomly Enter
              // and toggle collection without knowing it
              const canCreate = orgBilling?.collectionUsageLimit
                ? orgBilling.collectionUsageCount <
                  orgBilling.collectionUsageLimit
                : true;
              if (
                canCreate &&
                option?.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY
              ) {
                setSearchStr('');
                triggerCommand(COMMAND_TYPE.CREATE_COLLECTION, {
                  initialValues: {
                    name: option.label,
                    posts: [],
                  },
                  onCompleted: (collection) => {
                    onSelectCollection(collection.id).then(
                      () => refetchCollections(), // refetch to get the newly created collection
                    );
                  },
                  onSelectExistingCollection: (collectionId) => {
                    onSelectCollection(collectionId);
                  },
                });
              }
            }}
            popupIcon={null}
            clearIcon={null}
            // @ts-ignore
            PopperComponent={Box}
            PaperComponent={Box}
            componentsProps={{
              popper: {
                sx: {
                  mt: 4,
                  flex: 1,
                  position: 'relative',
                  width: '100% !important',
                  overflow: 'auto',
                  height: '100%',
                  '.MuiAutocomplete-listbox': {
                    maxHeight: 'unset',
                  },
                },
              },
            }}
            {...(loading
              ? {}
              : {
                  filterOptions: (
                    options: AutocompleteOptionType[],
                    params,
                  ) => {
                    const filtered = filter(options, params);
                    const isOptionExist = options.some(
                      (option) =>
                        option.label.trim().toLowerCase() ===
                        params.inputValue.trim().toLowerCase(),
                    );

                    if (params.inputValue !== '' && !isOptionExist) {
                      filtered.push({
                        label: params.inputValue,
                        value: COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
                      });
                    } else if (isFeatureEnabled(PlotFeature.ContentCalendar)) {
                      filtered.unshift({
                        label: 'Content Calendar',
                        value: CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
                      });
                    }

                    return filtered;
                  },
                })}
          />
        </Box>
      </Box>

      {orgBilling && (
        <BillingCollectionLimitIndicator
          variant="compact"
          organizationBilling={orgBilling}
          sx={{ borderRadius: theme.spacing(0, 0, 3, 3), width: 320 }}
        />
      )}
    </Popover>
  );
};
