/**
 * IMPORTANT: This hook returns an instance of ApolloClient
 * to use with the Apollo context provider. This instance will not be usable
 * for actual caching manipulation and stuff. For that, use the `useApolloClient` hook
 * exported by `@apollo/client`.
 */

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { toast } from 'components/common/Toast';
import { GENERAL_ERROR_MESSAGE, GENERAL_ERROR_TITLE } from 'constants/error';
import { useAnalyticsContext } from 'contexts/Analytic.context';
import { cookieOptions } from 'contexts/users/User.context';
import introspectionQueryResultData from 'graphql/fragmentTypes.json';
import { EventName } from 'hooks/useAnalytics';
import { useMemo } from 'react';
import { Cookies } from 'react-cookie';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme/theme';
import { CustomToastNotificationClass } from '../components/common/Toast/types';

export type CustomOperationContext = {
  suppressTopLevelToast: boolean;
};

export const useApolloClient = () => {
  const analytics = useAnalyticsContext();

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: `${process.env.REACT_APP_PLOT_BACKEND_ENDPOINT}/graphql`,
      credentials: 'include',
    });

    // send back client current version to the backend
    const currentVersionLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          'Current-Client-App-Version': '3.6.2',
        },
      };
    });

    // if there is a mismatch version from the server, ask user to refresh the page
    const releasedVersionLink = new ApolloLink((operation, forward) => {
      return new Observable((observer) => {
        const subscription = forward(operation).subscribe({
          next: (response) => {
            const context = operation.getContext();
            const newVersionReleased =
              context.response.headers.get('New-Version-Released') === 'true';

            if (newVersionReleased) {
              // warn user to refresh the page
              toast({
                title: 'New Version Available',
                message:
                  'Update to new version of the app to stay on top of your\n' +
                  'creative workflow management.',
                actionButtons: [
                  {
                    variant: 'contained',
                    sx: {
                      textTransform: 'initial',
                      color: theme.colors?.primary.white,
                      bgcolor: `${theme.colors?.primary.maroon} !important`,
                    },
                    children: 'Refresh page to update',
                    onClick: () => window.location.reload(),
                  },
                ],
                persist: true,
                position: 'bottom-left',
                duplicates: {
                  limit: 1,
                  notificationClass: CustomToastNotificationClass.NEW_VERSION,
                },
              });
            }

            observer.next(response);
          },
          error: (err) => {
            observer.error(err);
          },
          complete: () => {
            observer.complete();
          },
        });

        // Return a cleanup function
        return () => {
          if (subscription) {
            subscription.unsubscribe();
          }
        };
      });
    });

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from cookies if it exists
      const token = new Cookies().get('token');
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
      let { suppressTopLevelToast = false } =
        operation.getContext() as CustomOperationContext;

      // if user is not authenticated, don't show the toast and silently redirect them login page.
      // Also remove the token from cookie
      if (graphQLErrors?.[0].extensions?.code === 'UNAUTHENTICATED') {
        new Cookies().remove('token', cookieOptions);
        window.location.href = PlotRoutes.auth();

        return;
      }

      // handle not found
      // TODO: need to rethink about how to throw exception the backend
      if (
        !suppressTopLevelToast &&
        graphQLErrors?.[0].extensions?.code === '404'
      ) {
        suppressTopLevelToast = true;

        toast({
          type: 'error',
          title: 'Not Found',
          message: graphQLErrors?.[0].message,
        });
      }

      if (
        !suppressTopLevelToast &&
        graphQLErrors?.[0].extensions?.code === 'BAD_USER_INPUT'
      ) {
        suppressTopLevelToast = true;

        toast({
          type: 'error',
          // @ts-ignore
          title: graphQLErrors?.[0].extensions.response.error,
          message: graphQLErrors?.[0].message,
        });
      }

      if (
        !suppressTopLevelToast &&
        graphQLErrors?.[0].extensions?.code === 'FORBIDDEN'
      ) {
        suppressTopLevelToast = true;
        const errorMessage = graphQLErrors?.[0].message;
        toast({
          type: 'error',
          title: 'Forbidden',
          message:
            errorMessage === 'Forbidden'
              ? 'Cannot access this resource'
              : errorMessage,
          duplicates: {
            limit: 1,
            notificationClass: CustomToastNotificationClass.FORBIDDEN,
          },
        });
      }

      if (
        !suppressTopLevelToast &&
        graphQLErrors?.[0].message ===
          'Looks like the waitlist has not been opened yet, please come back later'
      ) {
        suppressTopLevelToast = true;
        toast({
          type: 'error',
          title: 'Waitlist Closed',
          message: 'Please come back later',
          duplicates: {
            limit: 1,
            notificationClass: CustomToastNotificationClass.FORBIDDEN,
          },
        });
      }

      Sentry.withScope((scope) => {
        scope.setTransactionName(operation.operationName);
        scope.setContext('apolloGraphQLOperation', {
          operationName: operation.operationName,
          variables: JSON.stringify(operation.variables),
          extensions: JSON.stringify(operation.extensions),
        });

        if ((graphQLErrors || []).length > 0) {
          graphQLErrors?.forEach((error) => {
            analytics.track(EventName.AppError, {
              type: 'apolloGraphQLError',
              code: error.extensions?.code,
              error: JSON.stringify(error),
              message: JSON.stringify(error.message),
              extensions: JSON.stringify(error.extensions),
            });

            Sentry.captureMessage(error.message, {
              level: 'error',
              fingerprint: ['{{ default }}', '{{ transaction }}'],
              contexts: {
                apolloGraphQLError: {
                  error: JSON.stringify(error),
                  message: JSON.stringify(error.message),
                  extensions: JSON.stringify(error.extensions),
                },
              },
            });
          });
          if (!suppressTopLevelToast) {
            // const graphQlError = (graphQLErrors || [])
            // .map((error) => error.message)
            // .join('\n');
            toast({
              type: 'error',
              title: GENERAL_ERROR_TITLE,
              message: GENERAL_ERROR_MESSAGE,
            });
          }
        }

        if (networkError) {
          analytics.track(EventName.AppError, {
            type: 'apolloNetworkError',
            code: networkError.name,
            error: networkError.message,
            trace: networkError.stack,
          });

          Sentry.captureMessage(networkError.message, {
            level: 'error',
            contexts: {
              apolloNetworkError: {
                error: JSON.stringify(networkError),
                extensions: (networkError as any).extensions,
              },
            },
          });

          if (!suppressTopLevelToast) {
            toast({
              type: 'error',
              title: GENERAL_ERROR_TITLE,
              message: networkError.message,
            });
          }
        }
      });
    });

    const cache = new InMemoryCache({
      possibleTypes: introspectionQueryResultData.possibleTypes,
      typePolicies: {
        // Custom merge function for onboardingState field in MeModel
        // as onboardingState is a JSON without an id
        MeModel: {
          fields: {
            onboardingState: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        // Custom merge function for value field in ContentIdeaFieldValueModel
        // as it could very be easily fragmented
        ContentIdeaFieldValueModel: {
          fields: {
            value: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        PostSearchHit: {
          keyFields: ['item', ['id']],
        },
        CollectionSearchHit: {
          keyFields: ['item', ['id']],
        },
      },
    });

    return new ApolloClient({
      link: ApolloLink.from([
        currentVersionLink,
        releasedVersionLink,
        errorLink,
        authLink,
        new SentryLink({
          setTransaction: false,
          setFingerprint: false,
          attachBreadcrumbs: {
            includeError: true,
          },
          shouldHandleOperation(operation) {
            return (
              operation.operationName !== 'IntrospectionQuery' &&
              operation.operationName !== 'login' &&
              operation.operationName !== 'signup'
            );
          },
        }),
        httpLink,
      ]),
      cache,
    });
  }, []); // eslint-disable-line -- run once on mount

  return { client };
};
