import { useDisclosure } from '@dwarvesf/react-hooks';
import { LinearProgress } from 'components/common/LinearProgress';
import { UpdateCollectionPermissionsDataInput } from 'features/collectionPermission/hooks';
import { CollectionFragmentCollectionPermissionDialogBodyFragment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { ManyPostsPermissionDialog } from './ManyPostsPermissionDialog';

type PermissionManageManyPostsPermissionViewProps = {
  isOpen: boolean;
  collection: CollectionFragmentCollectionPermissionDialogBodyFragment;
  onNavigateBack?: VoidFunction;
  onCallbackAfterClose?: VoidFunction;
  onCallBackAfterSubmit?: VoidFunction;

  inputData: UpdateCollectionPermissionsDataInput | null;
  setInputData: (data: UpdateCollectionPermissionsDataInput | null) => void;
};

export const CollectionPermissionManyPostsPermissionDialogView = (
  props: PermissionManageManyPostsPermissionViewProps,
) => {
  const {
    isOpen,
    collection,
    onNavigateBack,
    onCallbackAfterClose,
    onCallBackAfterSubmit,
    inputData,
    setInputData,
  } = props;

  const [loading] = useState(false);

  const {
    isOpen: manyPostsPermissionOpen,
    onClose: manyPostsPermissionOnClose,
    onOpen: manyPostsPermissionOnOpen,
  } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      manyPostsPermissionOnOpen();
    } else {
      manyPostsPermissionOnClose();
    }
  }, [isOpen]);

  const handleOnNavigateBack = () => {
    manyPostsPermissionOnClose();
    onNavigateBack?.();
  };

  const handleOnClose = () => {
    onCallbackAfterClose?.();
    manyPostsPermissionOnClose();
  };

  const handleOnCallBackAfterSubmit = () => {
    onCallBackAfterSubmit?.();
  };

  return loading ? (
    <LinearProgress />
  ) : collection && inputData ? (
    <ManyPostsPermissionDialog
      collection={collection}
      isOpen={manyPostsPermissionOpen}
      onClose={handleOnClose}
      onNavigateBack={handleOnNavigateBack}
      onCallBackAfterSubmit={handleOnCallBackAfterSubmit}
      inputData={inputData}
      setInputData={setInputData}
    />
  ) : null;
};
