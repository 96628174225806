import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { useUserContext } from 'contexts/users/User.context';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useMemo, useState } from 'react';
import { UserFragmentAccessRequestAdminListFragment } from 'graphql/generated';
import { ManageMembers } from '../../../../pages/billing/checkout/components/ManageMembers';
import { BillingNewPricingBasicPlanConfirmation } from './BillingNewPricingBasicPlanConfirmation';

type BillingNewPricingBasicDetailsProps = {
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  onGoBack: VoidFunction;
  onOpenPlanOptions: VoidFunction;
  selectedType: 'upgrade' | 'freeTrial';
};

export const BillingNewPricingBasicDetails = (
  props: BillingNewPricingBasicDetailsProps,
) => {
  const {
    isOpen,
    onClose,
    onOpen,
    onGoBack,
    onOpenPlanOptions,
    selectedType = 'upgrade',
  } = props;

  const { user } = useUserContext();

  const {
    isOpen: editMemberOpen,
    onClose: editMemberOnClose,
    onOpen: editMemberOnOpen,
  } = useDisclosure();

  const {
    isOpen: confirmationOpen,
    onClose: confirmationOnClose,
    onOpen: confirmationOnOpen,
  } = useDisclosure();

  const [usersToBeDisabled, setUsersToBeDisabled] = useState<
    UserFragmentAccessRequestAdminListFragment[]
  >([]);
  const [usersToBeEnabled, setUsersToBeEnabled] = useState<
    UserFragmentAccessRequestAdminListFragment[]
  >([]);

  const activeMembers = useMemo(() => {
    return (
      user?.organization.users.filter(
        (u) => !u.isDisabled && u.hasBeenApprovedByAdmin,
      ) ?? []
    );
  }, [user?.organization.users]);

  const handleManageMembers = (selectedMembers: string[]) => {
    setUsersToBeDisabled(
      (user?.organization.users ?? []).filter(
        (u) => !selectedMembers.includes(u.id),
      ),
    );
    setUsersToBeEnabled(
      (user?.organization.users ?? []).filter((u) =>
        selectedMembers.includes(u.id),
      ),
    );

    editMemberOnClose();
    confirmationOnOpen();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            width: '375px',
            padding: theme.spacing(8),
            bgcolor: theme.colors?.primary.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(6),
          },
        }}
      >
        <Button
          sx={{
            color: theme.colors?.primary.black,
            p: 0,
          }}
          startIcon={<IconOutlineArrowLeft />}
          onClick={onGoBack}
        >
          Back to Plans
        </Button>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(3),
          }}
        >
          <Typography variant="headline-xl" fontSize={24} fontWeight={500}>
            Basic Plan allows up to 5 organization members.
          </Typography>
          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            Your organization currently has{' '}
            <b>{activeMembers.length} members</b>.
          </Typography>
          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            Consider upgrading to the Business Plan to add more members, or
            remove some to stay on the Basic Plan.
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              ...theme.typography['headline-sm'],
              bgcolor: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              color: theme.colors?.primary.white,
              ':hover': { bgcolor: theme.colors?.primary.black },
            }}
            onClick={() => {
              onClose();
              editMemberOnOpen();
            }}
          >
            Edit members
          </Button>

          <Button
            fullWidth
            sx={{
              textDecoration: 'underline',
              ':hover': { textDecoration: 'underline' },
            }}
            onClick={onOpenPlanOptions}
          >
            Explore Business plan instead
          </Button>
        </Box>
      </Dialog>

      <BillingNewPricingBasicPlanConfirmation
        isOpen={confirmationOpen}
        onClose={confirmationOnClose}
        usersToBeDisabled={usersToBeDisabled}
        type={selectedType}
        onGoBack={() => {
          confirmationOnClose();
          editMemberOnOpen();
        }}
      />

      <ManageMembers
        isOpen={editMemberOpen}
        onClose={editMemberOnClose}
        selectionLimit={5}
        activeOrgUsers={user?.organization.users ?? []}
        isNotClosable
        onGoBack={() => {
          editMemberOnClose();
          onOpen();
        }}
        subDescription="Only 5 members would be allowed in your organization"
        onCustomSubmit={handleManageMembers}
      />
    </>
  );
};
