import { gql } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { usePermissionRequestHandler } from 'features/permission/hooks';
import {
  PermissionLevel,
  PermissionRequestFragmentPermissionApproveRequestFragment,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { useUserContext } from 'contexts/users/User.context';
import { PermissionLevelMap, PermissionSelector } from '../permissionSelector';

export const PERMISSION_REQUEST_FRAGMENT_PERMISSION_APPROVE_REQUEST = gql`
  fragment PermissionRequestFragmentPermissionApproveRequest on PermissionRequestModel {
    id
    note
    status
    user {
      organization {
        id
      }
      ...UserFragmentAvatarGroup
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

interface PermissionApproveRequestProps {
  entityOrganizationId: string;
  request: PermissionRequestFragmentPermissionApproveRequestFragment;
}

export const PermissionApproveRequest = (
  props: PermissionApproveRequestProps,
) => {
  const { entityOrganizationId, request } = props;

  const initialPermissionLevel = PermissionLevel.Full;
  const [selectedPermissionLevel, setSelectedPermissionLevel] =
    useState<PermissionLevel>(initialPermissionLevel);

  const { onApprovePermissionRequest, onRejectPermissionRequest } =
    usePermissionRequestHandler();

  const { user } = useUserContext();

  return (
    <Box position="relative">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '100%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box
          sx={{
            borderRadius: 100,
            bgcolor: theme.colors?.primary.maroon,
            height: 9,
            width: 9,
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Avatar user={request.user} size={36} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
            }}
          >
            <Typography
              variant="headline-sm"
              color={theme.colors?.primary.black}
            >
              {getFullName(request.user)}
            </Typography>
            <Typography variant="subhead-lg" color={theme.colors?.utility[900]}>
              {request.user.email}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            variant="text"
            sx={{ color: theme.colors?.utility[800], fontSize: 14 }}
            onClick={() =>
              onRejectPermissionRequest({
                permissionRequestId: request.id,
              })
            }
            size="small"
          >
            Deny
          </Button>
          <Button
            variant="secondary"
            size="small"
            sx={{
              bgcolor: theme.colors?.primary.black,
              borderRadius: 2,
              border: 'none',
              color: theme.colors?.primary.parchment,
              whiteSpace: 'nowrap',
              padding: theme.spacing(0, 0, 0, 3),
              fontSize: 14,
              ':hover': {
                bgcolor: theme.colors?.primary.black,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              onApprovePermissionRequest({
                permissionRequestId: request.id,
                input: {
                  permissionLevel: selectedPermissionLevel,
                },
              });
            }}
            endIcon={
              <PermissionSelector
                entityOrganizationId={entityOrganizationId}
                userOrganizationId={request.user.organization.id}
                initialValue={selectedPermissionLevel || initialPermissionLevel}
                onPermissionChange={(permission) => {
                  setSelectedPermissionLevel(permission);
                }}
                variant="icon"
                sx={{
                  '& .MuiSelect-select': {
                    padding: '8px 10px 8px 8px !important',
                  },
                  bgcolor: 'rgba(250, 243, 236, 0.20)',
                }}
              />
            }
          >
            {
              PermissionLevelMap[
                selectedPermissionLevel || initialPermissionLevel
              ].label
            }
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
