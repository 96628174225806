import { Box, TextField, Typography } from '@mui/material';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { SocialHandleInput } from 'features/socialListeningSettings';
import { useBrandDataForSocialListeningOnboarding } from 'features/socialMediaListening';
import {
  ListeningTopicType,
  Platform,
  PlotFeature,
  useCreateCreatorForSocialMediaListeningSettingsGeneralMutation,
  useRemoveCreatorForSocialMediaListeningSettingsGeneralMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';
import { NotificationsSection } from '../notifications';

export const SocialListeningSettingsGeneral = () => {
  const { isFeatureEnabled } = useFeatureFlagContext();

  const [createCreator] =
    useCreateCreatorForSocialMediaListeningSettingsGeneralMutation();
  const [removeCreator] =
    useRemoveCreatorForSocialMediaListeningSettingsGeneralMutation();

  const {
    brand,
    refetchBrand,
    onboardingActions: { handleBrandNameUpdate },
  } = useBrandDataForSocialListeningOnboarding();

  const topics = brand?.topics.length
    ? [
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.BrandMention,
        ),
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.TopicEngagement,
        ),
        ...(brand?.topics || []).filter(
          (t) => t.type === ListeningTopicType.CreatorTracking,
        ),
      ]
    : [];
  const [brandName, setBrandName] = useState('');
  const [currentTopic, setCurrentTopic] = useState(topics[0]);

  useEffect(() => {
    if (!currentTopic) {
      setCurrentTopic(topics[0]);
    } else {
      const topic = topics.find((topic) => topic.id === currentTopic.id);
      if (topic) {
        setCurrentTopic(topic);
      }
    }

    setBrandName(brand?.name || '');
  }, [brand]); // eslint-disable-line react-hooks/exhaustive-deps

  const tiktokCreator = brand?.creators.find(
    (c) => c.platform === Platform.Tiktok,
  );
  const instagramCreator = brand?.creators.find(
    (c) => c.platform === Platform.Instagram,
  );

  return (
    <Box display="flex" flexDirection="column" gap={6} width="100%">
      <Typography variant="headline-lg">General Settings</Typography>
      <Box display="flex" flexDirection="column" mt={2} gap={3}>
        <Typography variant="headline-sm">Brand Name</Typography>
        <TextField
          value={brandName}
          onChange={(e) => {
            setBrandName(e.target.value);
          }}
          onBlur={(e) => {
            handleBrandNameUpdate(e.target.value);
          }}
          sx={{
            width: 280,
            '& .MuiOutlinedInput-root': {
              fontWeight: 600,
              color: theme.colors?.primary.black,
              pb: 1,
              paddingRight: `${theme.spacing(2)}`,
              borderRadius: theme.spacing(2),
              backgroundColor: theme.colors?.utility[250],
              border: `2px solid ${theme.colors?.utility[300]}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
      </Box>

      {brand && (
        <>
          <SocialHandleInput
            creator={tiktokCreator}
            platform={Platform.Tiktok}
            actions={{
              onConfirm: async (creator) => {
                await createCreator({
                  variables: {
                    data: {
                      data: {
                        handle: creator.handle,
                        brandId: brand.id,
                        platform: Platform.Tiktok,
                      },
                    },
                  },
                  update: () => {
                    refetchBrand?.();
                  },
                });
              },
              onRemove: async () => {
                removeCreator({
                  variables: {
                    data: {
                      creatorId: tiktokCreator?.id!,
                      brandId: brand.id,
                    },
                  },
                  update: (cache) => {
                    evictObject(cache, tiktokCreator?.id!, 'CreatorModel');
                  },
                });
              },
            }}
            canRemove={!!tiktokCreator && !!instagramCreator}
          />
          <SocialHandleInput
            creator={instagramCreator}
            platform={Platform.Instagram}
            actions={{
              onConfirm: async (creator) => {
                await createCreator({
                  variables: {
                    data: {
                      data: {
                        handle: creator.handle,
                        brandId: brand.id,
                        platform: Platform.Instagram,
                      },
                    },
                  },
                  update: () => {
                    refetchBrand?.();
                  },
                });
              },
              onRemove: async () => {
                removeCreator({
                  variables: {
                    data: {
                      creatorId: instagramCreator?.id!,
                      brandId: brand.id,
                    },
                  },
                  update: (cache) => {
                    evictObject(cache, instagramCreator?.id!, 'CreatorModel');
                  },
                });
              },
            }}
            canRemove={!!tiktokCreator && !!instagramCreator}
          />
        </>
      )}

      {isFeatureEnabled(PlotFeature.SocialListeningNotifications) && (
        <NotificationsSection topics={topics} />
      )}
    </Box>
  );
};
