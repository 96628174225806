import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  IconButton,
  Input,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { toast } from 'components/common/Toast';
import { Tooltip } from 'components/common/Tooltip';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconOutlineCrossXClose } from 'components/icons/components/outline/IconOutlineCrossXClose';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { useUserContext } from 'contexts/users/User.context';
import { useAccessRequestHandlers } from 'features/accessRequest';
import { BillingOrganizationMemberLimitIndicator } from 'features/billing';
import {
  InternalOrganizationRole,
  OrganizationType,
  OrganizationUserAccessRequestFragmentUserApprovalRequestsTableFragment,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { getFullName } from 'utils/users';
import { StyledTableBodyCell, StyledTableHeadCell } from '../styles';
import { ApproveMemberDialog } from './sections/approveMemberDialog/ApproveMemberDialog';
import { toastStyle } from './styles';

const columns = ['Name', 'Message', 'Invited By'];

export const ORGANIZATION_USER_ACCESS_REQUEST_FRAGMENT_USER_APPROVAL_REQUESTS_TABLE = gql`
  fragment OrganizationUserAccessRequestFragmentUserApprovalRequestsTable on OrganizationUserAccessRequestModel {
    id
    message
    status
    user {
      role
      hasSignedUp
      ...UserFragmentAvatarGroup
    }
    invitedBy {
      ...UserFragmentAvatarGroup
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

interface UserApprovalRequestsTableProps {
  organizationName: string;
  requests: OrganizationUserAccessRequestFragmentUserApprovalRequestsTableFragment[];
  canEdit: boolean;
}

export const UserApprovalRequestsTable = ({
  organizationName,
  requests,
  canEdit,
}: UserApprovalRequestsTableProps) => {
  const [filteredRequests, setFilteredRequests] = useState<
    OrganizationUserAccessRequestFragmentUserApprovalRequestsTableFragment[]
  >([]);

  const [currentRequest, setCurrentRequest] =
    useState<OrganizationUserAccessRequestFragmentUserApprovalRequestsTableFragment | null>();

  const { orgBilling, user } = useUserContext();
  const isSocialListeningEnabled = orgBilling?.socialListeningEnabled;
  const approveMemberModalDisclosure = useDisclosure();

  const isOrganizationMemberLimitReached = useMemo(() => {
    return (
      !!orgBilling?.organizationMemberLimit &&
      orgBilling.organizationMemberUsage >= orgBilling.organizationMemberLimit
    );
  }, [orgBilling]);

  const isOrganizationMemberLimitReachedWhenIncludeAllRequests = useMemo(() => {
    return (
      !!orgBilling?.organizationMemberLimit &&
      orgBilling.organizationMemberUsage +
        (requests.length > 1 ? requests.length : 0) >=
        orgBilling.organizationMemberLimit
    );
  }, [orgBilling, requests]);

  const {
    onApproveOrganizationAccessRequest,
    onApproveOrganizationAccessRequests,
    onRejectOrganizationAccessRequest,
  } = useAccessRequestHandlers();

  useEffect(() => {
    setFilteredRequests(requests);
  }, [requests]);

  const handleApproveRequest = (
    request: OrganizationUserAccessRequestFragmentUserApprovalRequestsTableFragment,
  ) => {
    if (!isSocialListeningEnabled) {
      if (!canEdit) return;
      onApproveOrganizationAccessRequest(request.id);
      toast({
        message: `${getFullName(request.user)} added to ${organizationName}`,
        position: 'bottom-center',
        shouldShowCloseButton: false,
        sx: toastStyle,
      });
    } else {
      setCurrentRequest(request);
      approveMemberModalDisclosure.onOpen();
    }
  };

  return (
    <>
      {requests.length > 0 && (
        <>
          <Input
            fullWidth
            disableUnderline
            startAdornment={
              <IconOutlineSearchNormal1
                size={16}
                style={{ marginRight: theme.spacing(3) }}
              />
            }
            placeholder="Search for a Member"
            sx={{
              bgcolor: theme.colors?.utility[275],
              borderRadius: 50,
              ...theme.typography['subhead-xl'],
              padding: theme.spacing(1.5, 6),
              mb: 4,
            }}
            onChange={(e) => {
              const searchValue = e.target.value;
              setFilteredRequests(
                requests.filter((o) =>
                  [o.user?.firstName, o.user?.lastName, o.user?.email].some(
                    (field) =>
                      field?.toLowerCase().includes(searchValue.toLowerCase()),
                  ),
                ),
              );
            }}
          />

          {orgBilling && (
            <BillingOrganizationMemberLimitIndicator
              organizationBilling={orgBilling}
              variant="component"
              sx={{
                margin: theme.spacing(6, 0),
              }}
            />
          )}
        </>
      )}
      <TableContainer>
        <Table sx={{ minWidth: 650, minHeight: requests.length ? 0 : 300 }}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableHeadCell key={`${column}-${index}`}>
                  {column}
                </StyledTableHeadCell>
              ))}
              <StyledTableHeadCell
                align="right"
                sx={{
                  pr: 6,
                }}
              >
                {!!filteredRequests.length && !isSocialListeningEnabled && (
                  <Tooltip
                    title={
                      isOrganizationMemberLimitReachedWhenIncludeAllRequests
                        ? 'Approving all would reach your organization member limit'
                        : canEdit
                        ? ''
                        : 'Only admin can approve the requests'
                    }
                  >
                    <Box>
                      <IconButton
                        disabled={
                          !canEdit ||
                          isOrganizationMemberLimitReachedWhenIncludeAllRequests
                        }
                        sx={{
                          padding: 0,
                          ...theme.typography['headline-xxs'],
                          fontSize: 13,
                          textDecoration: 'underline',
                          color: theme.colors?.primary.black,
                        }}
                        disableRipple
                        onClick={() => {
                          if (!canEdit) return;
                          onApproveOrganizationAccessRequests(
                            requests.map((r) => r.id),
                          );
                          toast({
                            message: `${requests.length} members added to ${organizationName}`,
                            position: 'bottom-center',
                            shouldShowCloseButton: false,
                            sx: toastStyle,
                          });
                        }}
                      >
                        Approve all
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests.length === 0 ? (
              <TableRow>
                <StyledTableBodyCell
                  colSpan={4}
                  align="center"
                  sx={{
                    borderBottom: 'none',
                  }}
                >
                  <Typography
                    color={theme.colors?.utility[500]}
                    variant="headline-md"
                  >
                    No pending
                    <br /> member requests
                  </Typography>
                </StyledTableBodyCell>
              </TableRow>
            ) : (
              filteredRequests.map((request) => {
                return (
                  <TableRow key={request.id}>
                    <StyledTableBodyCell>
                      <AvatarWithName user={request.user} avatarSize={24} />
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <Typography
                        variant="body-md"
                        fontWeight={600}
                        color={theme.colors?.utility[800]}
                      >
                        {request.message}
                      </Typography>
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <Typography
                        variant="body-sm"
                        fontWeight={500}
                        color={theme.colors?.utility[800]}
                      >
                        {request.invitedBy
                          ? getFullName(request.invitedBy)
                          : '-'}
                      </Typography>
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 4,
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Tooltip
                          title={
                            canEdit ? '' : 'Only admin can reject the request'
                          }
                        >
                          <IconButton
                            sx={{
                              height: 36,
                              width: 36,
                              bgcolor: theme.colors?.primary.parchment,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              ':hover': {
                                bgcolor: theme.colors?.primary.parchment,
                              },
                            }}
                            onClick={() => {
                              if (!canEdit) return;
                              onRejectOrganizationAccessRequest(request.id);
                              toast({
                                message: `${getFullName(
                                  request.user,
                                )}'s request to join ${organizationName} has been rejected`,
                                position: 'bottom-center',
                                shouldShowCloseButton: false,
                                sx: toastStyle,
                              });
                            }}
                          >
                            <IconOutlineCrossXClose
                              color={theme.colors?.primary.black}
                              size={18}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            canEdit ? '' : 'Only admin can approve the request'
                          }
                        >
                          <IconButton
                            disabled={isOrganizationMemberLimitReached}
                            sx={{
                              height: 36,
                              width: 36,
                              bgcolor: theme.colors?.primary.black,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              ':hover': {
                                bgcolor: theme.colors?.primary.black,
                              },
                              '&.Mui-disabled': {
                                bgcolor: theme.colors?.primary.black,
                                opacity: 0.4,
                              },
                            }}
                            onClick={() => {
                              handleApproveRequest(request);
                            }}
                          >
                            <IconCustomTick
                              color={theme.colors?.primary.parchment}
                              size={16}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </StyledTableBodyCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {currentRequest && (
        <ApproveMemberDialog
          {...approveMemberModalDisclosure}
          email={currentRequest.user.email}
          onConfirm={async (role) => {
            await onApproveOrganizationAccessRequest(currentRequest.id, role);
            toast({
              message: `${getFullName(
                currentRequest.user,
              )} added to ${organizationName}`,
              position: 'bottom-center',
              shouldShowCloseButton: false,
              sx: toastStyle,
            });
          }}
          // If the user has already been approved by the admin, this means the user was already a SocialListeningUser and has requested to be a User
          // In this case, we should default the role to User, else we default to the user's current role with a fallback to SocialListeningUser
          defaultRole={
            currentRequest?.user?.hasSignedUp
              ? InternalOrganizationRole.User
              : currentRequest.user.role ||
                InternalOrganizationRole.SocialListeningUser
          }
        />
      )}
    </>
  );
};
