import { Box, SxProps } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { SocialMediaListeningPostCardView } from './postCard/SocialMediaListeningPostCardView';
import { SocialMediaListeningPostsGridViewProps } from './types';

export const SocialMediaListeningPostsGridView = ({
  socialPosts,
  searchCriteria,
}: SocialMediaListeningPostsGridViewProps) => {
  const [params] = useSearchParams();
  const newSocialPostIds = (params.get('newSocialPostIds') || '').split('|');

  if (!socialPosts) return null;

  return (
    <Box
      display="grid"
      gap={4}
      sx={{
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)', // 1 column on extra small screens
          sm: 'repeat(2, 1fr)', // 2 columns on small screens
          md: 'repeat(3, 1fr)', // 3 columns on medium screens
          lg: 'repeat(4, 1fr)', // 4 columns on large screens
        },
        ...newSocialPostIds.reduce((acc, id) => {
          if (socialPosts.some((post) => post.id === id)) {
            // @ts-ignore
            acc[`& .social-post-card-${id}`] = {
              bgcolor: '#F8E8CA80',
            };
          }

          return acc;
        }, {} as SxProps),
      }}
    >
      {socialPosts.map((post, index) => (
        <SocialMediaListeningPostCardView
          key={post.id}
          post={post}
          searchCriteria={searchCriteria}
          itemPosition={(index + 1).toString()}
          className={`social-post-card-${post.id}`}
        />
      ))}
    </Box>
  );
};
