import { gql } from '@apollo/client';
import { TableCell, Typography } from '@mui/material';
import {
  ListeningTopicType,
  TopicFragmentTopicInfoTableCellFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export const TopicProps = {
  [ListeningTopicType.BrandMention]: {
    label: 'Brand Mention',
    sx: {
      backgroundColor: theme.colors?.utility['blue-4'],
      color: theme.colors?.utility.blueberry,
    },
  },
  [ListeningTopicType.CreatorTracking]: {
    label: 'Creator Tracking',
    sx: {
      backgroundColor: theme.colors?.utility['purple-1'],
      color: theme.colors?.utility['purple-4'],
    },
  },
  [ListeningTopicType.TopicEngagement]: {
    label: 'Topic Engagement',
    sx: {
      color: theme.colors?.utility['yellow-4-new'],
      backgroundColor: theme.colors?.utility['yellow-1'],
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment TopicFragmentTopicInfoTableCell on TopicModel {
    id
    type
    name
    brandId
  }
`;

type Props = {
  brandId: string;
  topics: TopicFragmentTopicInfoTableCellFragment[];
};

export const TopicInfoTableCell = ({ brandId, topics }: Props) => {
  const brandTopic = topics.find((topic) => topic.brandId === brandId);
  if (!brandTopic || !topics.length) {
    return <TableCell>-</TableCell>;
  }
  return (
    <TableCell>
      <Typography mb={1} display="block" variant="body-lg">
        {brandTopic.name}
      </Typography>
      <Typography
        display="flex"
        width="fit-content"
        fontWeight={500}
        variant="body-sm"
        sx={{
          borderRadius: theme.spacing(4),
          padding: theme.spacing(1, 2),
          fontWeight: 600,
          ...TopicProps[brandTopic.type].sx,
        }}
      >
        {TopicProps[brandTopic.type].label}
      </Typography>
    </TableCell>
  );
};
