import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { MoreMenuItem } from 'components/common/Menu';
import { typography } from 'components/common/Typography/styles';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconLinearClipboardText } from 'components/icons/components/linear/IconLinearClipboardText';
import { IconLinearTagRight } from 'components/icons/components/linear/IconLinearTagRight';
import { IconOutlineEdit2 } from 'components/icons/components/outline/IconOutlineEdit2';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { SocialMediaListeningFilterByDateRangeButton } from 'features/socialMediaListening';
import {
  CommentInsightsCategoryType,
  Platform,
  PlotFeature,
  useGetBrandsForCommentInsightsFiltersQuery,
  useGetCategoriesForCommentInsightsFiltersQuery,
  useGetOrganizationSocialPostCommentLabelsForCommentInsightsFiltersQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { CompetitorLabel } from '../../editCompetitorLabels/CompetitorLabel';
import { TopicProps } from '../commentsInsightsTableCells/topicInfoTableCell';

type Props = {
  commentInsightsCategoryType: CommentInsightsCategoryType;
  brandId: string;
  platforms: Platform[];
  hideTopics?: boolean;
  currentDateRange?: [Date, Date];
  onUpdateFilters: (filters: {
    labels: string[];
    categories: string[];
    products: string[];
    dateRange: [Date, Date];
    topicIds?: string[];
  }) => void;
};

export const CompetitorCommentInsightsFilters = ({
  commentInsightsCategoryType,
  brandId,
  platforms,
  currentDateRange,
  hideTopics,
  onUpdateFilters,
}: Props) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);

  const { data: brandsData } = useGetBrandsForCommentInsightsFiltersQuery({
    skip: hideTopics,
  });

  const { data: labelsData } =
    useGetOrganizationSocialPostCommentLabelsForCommentInsightsFiltersQuery({
      variables: {
        data: {
          filters: {},
          organizationId: user?.organization.id!,
        },
      },
      skip: !user?.organization.id,
    });

  const { data: categoriesData } =
    useGetCategoriesForCommentInsightsFiltersQuery({
      variables: {
        data: {
          brandId,
          platforms,
          type: commentInsightsCategoryType,
          startDate: currentDateRange?.[0],
          endDate: currentDateRange?.[1],
        },
      },
      skip: !brandId,
    });

  const allLabels = labelsData?.organizationSocialPostCommentLabels.data || [];

  const topics = brandsData?.brands[0]?.topics || [];

  const allCategories =
    categoriesData?.socialPostCommentCategoriesForBrand || [];
  const allProducts = ['Product 1', 'Product 2', 'Product 3', 'Product 4'];

  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);

  const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(allCategories);

  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(allProducts);

  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);

  useEffect(() => {
    setFilteredCategories(
      allCategories.filter((category) =>
        category.toLowerCase().includes(categorySearchTerm.toLowerCase()),
      ),
    );
  }, [categorySearchTerm]); //eslint-disable-line

  useEffect(() => {
    setFilteredCategories(allCategories);
  }, [categoriesData]);

  useEffect(() => {
    setFilteredProducts(
      allProducts.filter((product) =>
        product.toLowerCase().includes(productSearchTerm.toLowerCase()),
      ),
    );
  }, [productSearchTerm]); //eslint-disable-line

  useEffect(() => {
    onUpdateFilters({
      labels: selectedLabels,
      categories: selectedCategories,
      products: selectedProducts,
      topicIds: selectedTopicIds,
      dateRange,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedLabels,
    selectedCategories,
    selectedProducts,
    dateRange,
    selectedTopicIds,
  ]);

  return (
    <Box display="flex" gap={3} alignItems="center">
      {!hideTopics && (
        <ContextMenu
          sx={{
            '& .MuiPaper-root': {
              maxHeight: 400,
              overflowY: 'auto',
            },
            '& .MuiMenuItem-root': {
              p: 0,
              color: theme.colors?.primary.black,
            },
          }}
          options={[
            ...topics.map((topic) => ({
              renderOption: () => (
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTopicIds((prev) =>
                      prev.includes(topic.id)
                        ? prev.filter((item) => item !== topic.id)
                        : [...prev, topic.id],
                    );
                  }}
                >
                  <Checkbox
                    sx={{
                      '&.Mui-checked': { color: theme.colors?.utility[800] },
                    }}
                    checked={selectedTopicIds.includes(topic.id)}
                  />
                  <Typography
                    fontWeight={500}
                    variant="body-lg"
                    flex={1}
                    mr={4}
                  >
                    {topic.name}
                  </Typography>

                  <Typography
                    fontWeight={500}
                    variant="body-sm"
                    sx={{
                      borderRadius: theme.spacing(4),
                      padding: theme.spacing(1, 2),
                      fontWeight: 600,
                      ...TopicProps[topic.type].sx,
                    }}
                  >
                    {TopicProps[topic.type].label}
                  </Typography>
                  <IconButton
                    sx={{ p: 0, ml: 2 }}
                    disableRipple
                    onClick={() =>
                      navigate(
                        PlotRoutes.socialListeningSettings({
                          tab: 'topics',
                          topicId: topic.id,
                        }),
                      )
                    }
                  >
                    <IconOutlineEdit2 size={20} />
                  </IconButton>
                </Box>
              ),
            })),
            {
              renderCustomComponent: () => (
                <Box
                  sx={{
                    borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                  }}
                />
              ),
            },
            {
              renderCustomComponent: () => (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <MoreMenuItem
                    disableRipple
                    sx={{
                      minWidth: 240,
                      width: '100%',
                    }}
                    label={
                      <Typography
                        display="inline-block"
                        variant="body-md"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          borderRadius: theme.spacing(2),
                          p: theme.spacing(3, 2),
                          '&:hover': {
                            backgroundColor: theme.colors?.utility[275],
                          },
                        }}
                      >
                        Create a New Topic
                        <IconLinearArrowRight size={16} />
                      </Typography>
                    }
                  >
                    <Box
                      sx={{
                        width: 270,
                        py: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      <Link to={PlotRoutes.socialListeningOnboarding('topic')}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            padding: theme.spacing(3),
                            borderRadius: theme.spacing(3),
                            '&:hover': {
                              backgroundColor: theme.colors?.utility[275],
                            },
                          }}
                        >
                          <Typography variant="body-lg" fontWeight={500}>
                            Topic Insights
                          </Typography>
                          <Typography
                            variant="body-sm"
                            fontWeight={500}
                            color={theme.colors?.utility[900]}
                          >
                            Track conversations across customized topics
                          </Typography>
                        </Box>
                      </Link>
                      {creatorTrackingEnabled && (
                        <Link
                          to={PlotRoutes.socialListeningOnboarding('creator')}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 2,
                              padding: theme.spacing(3),
                              borderRadius: theme.spacing(3),
                              '&:hover': {
                                backgroundColor: theme.colors?.utility[275],
                              },
                            }}
                          >
                            <Typography variant="body-lg" fontWeight={500}>
                              Creator Focus
                            </Typography>
                            <Typography
                              variant="body-sm"
                              fontWeight={500}
                              color={theme.colors?.utility[900]}
                            >
                              Track specific creators discussing your key topics
                            </Typography>
                          </Box>
                        </Link>
                      )}
                    </Box>
                  </MoreMenuItem>
                </Box>
              ),
            },
          ]}
          renderButton={() => (
            <Button
              sx={{
                backgroundColor: theme.colors?.utility[275],
                color: theme.colors?.primary.black,
                borderRadius: theme.spacing(2),
                display: 'flex',
                gap: 2,
                p: theme.spacing(1, 2),
                alignItems: 'center',
                '&:hover': { backgroundColor: theme.colors?.utility[275] },
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'rgba(35, 6, 3, 0.05);',
                  p: theme.spacing(1),
                  borderRadius: theme.spacing(1),
                }}
              >
                <IconLinearClipboardText size={16} />
              </Box>
              <Typography fontWeight={500} variant="body-xl">
                Topics
              </Typography>
              <IconLinearArrowDown size={16} />
            </Button>
          )}
        />
      )}
      <ContextMenu
        sx={{
          '& .MuiPaper-root': {
            maxHeight: 400,
            overflowY: 'auto',
          },
          '& .MuiMenuItem-root': {
            p: 0,
            color: theme.colors?.primary.black,
          },
        }}
        options={[
          ...allLabels.map((label) => ({
            renderOption: () => (
              <Box
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedLabels((prev) =>
                    prev.includes(label.id)
                      ? prev.filter((item) => item !== label.id)
                      : [...prev, label.id],
                  );
                }}
              >
                <Checkbox
                  sx={{
                    '&.Mui-checked': { color: theme.colors?.utility[800] },
                  }}
                  checked={selectedLabels.includes(label.id)}
                />
                <CompetitorLabel
                  label={label.label}
                  componentProps={{
                    sx: {
                      fontSize: theme.spacing(3.5),
                    },
                  }}
                />
              </Box>
            ),
          })),
        ]}
        renderButton={() => (
          <Button
            sx={{
              backgroundColor: theme.colors?.utility[275],
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              display: 'flex',
              gap: 2,
              p: theme.spacing(1, 2),
              alignItems: 'center',
              '&:hover': { backgroundColor: theme.colors?.utility[275] },
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(35, 6, 3, 0.05);',
                p: theme.spacing(1),
                borderRadius: theme.spacing(1),
              }}
            >
              <IconLinearTagRight size={16} />
            </Box>
            <Typography fontWeight={500} variant="body-xl">
              Labels
            </Typography>
            <IconLinearArrowDown size={16} />
          </Button>
        )}
      />

      <ContextMenu
        sx={{
          '& .MuiPaper-root': {
            maxHeight: 400,
            overflowY: 'auto',
          },
          '& .MuiMenuItem-root': {
            p: 0,
            color: theme.colors?.primary.black,
          },
        }}
        options={[
          {
            renderCustomComponent: () => (
              <Box px={2}>
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={categorySearchTerm}
                  onChange={(e) => setCategorySearchTerm(e.target.value)}
                  sx={{
                    my: theme.spacing(1),
                    width: '100%',
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.utility[250],
                    '& .MuiOutlinedInput-root': {
                      borderRadius: `${theme.spacing(5)} !important`,
                      border: 'none',
                    },
                    '& .MuiInputBase-input': {
                      ...typography['headline-sm'],
                      borderRadius: `${theme.spacing(5)} !important`,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                />
              </Box>
            ),
          },
          ...(!filteredCategories.length
            ? [
                {
                  renderCustomComponent: () => (
                    <Box>
                      <Typography
                        variant="body-lg"
                        fontWeight={500}
                        sx={{ p: theme.spacing(2) }}
                        color={theme.colors?.utility[700]}
                      >
                        No Categories
                      </Typography>
                    </Box>
                  ),
                },
              ]
            : []),
          ...filteredCategories.map((category) => ({
            renderOption: () => (
              <Box
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedCategories((prev) =>
                    prev.includes(category)
                      ? prev.filter((item) => item !== category)
                      : [...prev, category],
                  );
                }}
              >
                <Checkbox
                  sx={{
                    '&.Mui-checked': { color: theme.colors?.utility[800] },
                  }}
                  checked={selectedCategories.includes(category)}
                />
                <Typography variant="body-lg">{category}</Typography>
              </Box>
            ),
          })),
        ]}
        renderButton={() => (
          <Button
            sx={{
              backgroundColor: theme.colors?.utility[275],
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              display: 'flex',
              gap: 2,
              p: theme.spacing(1, 2),
              alignItems: 'center',
              '&:hover': { backgroundColor: theme.colors?.utility[275] },
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(35, 6, 3, 0.05);',
                p: theme.spacing(1),
                borderRadius: theme.spacing(1),
              }}
            >
              <IconCustomSparkles size={16} />
            </Box>
            <Typography fontWeight={500} variant="body-xl">
              Category
            </Typography>
            <IconLinearArrowDown size={16} />
          </Button>
        )}
      />

      {/* Not showing products for now */}
      {/* <ContextMenu
        sx={{
          '& .MuiMenuItem-root': {
            p: 0,
            color: theme.colors?.primary.black,
          },
        }}
        options={[
          {
            renderCustomComponent: () => (
              <Box px={2}>
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={productSearchTerm}
                  onChange={(e) => setProductSearchTerm(e.target.value)}
                  sx={{
                    my: theme.spacing(1),
                    width: '100%',
                    borderRadius: theme.spacing(5),
                    backgroundColor: theme.colors?.utility[250],
                    '& .MuiOutlinedInput-root': {
                      borderRadius: `${theme.spacing(5)} !important`,
                      border: 'none',
                    },
                    '& .MuiInputBase-input': {
                      ...typography['headline-sm'],
                      borderRadius: `${theme.spacing(5)} !important`,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                />
              </Box>
            ),
          },
          ...filteredProducts.map((product) => ({
            renderOption: () => (
              <Box
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedProducts((prev) =>
                    prev.includes(product)
                      ? prev.filter((item) => item !== product)
                      : [...prev, product],
                  );
                }}
              >
                <Checkbox
                  sx={{
                    '&.Mui-checked': { color: theme.colors?.utility[800] },
                  }}
                  checked={selectedProducts.includes(product)}
                />
                <Typography variant="body-lg">{product}</Typography>
              </Box>
            ),
          })),
        ]}
        renderButton={() => (
          <Button
            sx={{
              backgroundColor: theme.colors?.utility[275],
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              display: 'flex',
              gap: 2,
              p: theme.spacing(1, 2),
              alignItems: 'center',
              '&:hover': { backgroundColor: theme.colors?.utility[275] },
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(35, 6, 3, 0.05);',
                p: theme.spacing(1),
                borderRadius: theme.spacing(1),
              }}
            >
              <IconLinearTag2 size={16} />
            </Box>
            <Typography fontWeight={500} variant="body-xl">
              Product
            </Typography>
            <IconLinearArrowDown size={16} />
          </Button>
        )}
      /> */}

      <SocialMediaListeningFilterByDateRangeButton
        onChange={(range) => {
          if (range[0] && range[1]) {
            setDateRange(range as [Date, Date]);
          }
        }}
        selectedDateRange={dateRange}
      />
    </Box>
  );
};
