import { gql, useApolloClient } from '@apollo/client';
import { Box, Divider, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Avatar } from 'components/common/AvatarGroup';
import { Timestamp } from 'components/common/Timestamp';
import { typography } from 'components/common/Typography/styles';
import { IconLinearSave } from 'components/icons/components/linear/IconLinearSave';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  PostCollectionList,
  PostSaveToContentCalendarButton,
  PostTitle,
} from 'features/post/components';
import {
  GetPostForPostCollectionListDocument,
  PlotFeature,
  PostFragmentPostCollectionListFragmentDoc,
  PostFragmentPostDescriptionFragmentDoc,
  PostFragmentPostInfoViewFragment,
  PostFragmentPostSaveToContentCalendarButtonFragmentDoc,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { PostDescription } from '../../components/description';

export const POST_FRAGMENT_POST_INFO_VIEW = gql`
  fragment PostFragmentPostInfoView on PostModel {
    id
    createdAt
    isFavoriteTrend
    trend {
      id
      title
    }
    creator {
      id
      ...UserFragmentAvatarGroup
    }
    ...PostFragmentPostDescription
    ...PostFragmentPostCollectionList
    ...PostFragmentPostSaveToContentCalendarButton
  }
  ${UserFragmentAvatarGroupFragmentDoc}
  ${PostFragmentPostDescriptionFragmentDoc}
  ${PostFragmentPostCollectionListFragmentDoc}
  ${PostFragmentPostSaveToContentCalendarButtonFragmentDoc}
`;

interface PostInfoProps {
  post: PostFragmentPostInfoViewFragment;
}

export const PostInfoView = (props: PostInfoProps) => {
  const { post } = props;

  const { isFeatureEnabled } = useFeatureFlagContext();

  const client = useApolloClient();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <PostTitle
        post={post}
        inputProps={{
          sx: {
            ...theme.typography['headline-xl'],
            fontSize: 24,
            fontWeight: 500,
          },
        }}
        sx={{
          ...theme.typography['headline-xl'],
          fontSize: 24,
          fontWeight: 500,
          color: theme.colors?.primary.black,
          borderBottom: 'none',
          ':hover': {
            borderBottom: `1px dashed ${theme.colors?.utility['blue-4']}`,
          },
        }}
      />
      <Box display="flex" gap={1} alignItems="center">
        <Avatar size={20} user={post.creator} />
        <Typography variant="subhead-xl">
          {getFullName(post.creator)}
        </Typography>
        <Typography variant="subhead-lg" color={theme.colors?.utility['700']}>
          •
        </Typography>
        <Timestamp
          date={moment(post.createdAt)}
          humanized
          sx={{
            ...typography['subhead-lg'],
            color: theme.colors?.utility['700'],
            ':first-letter': {
              textTransform: 'capitalize',
            },
          }}
        />
      </Box>
      <PostDescription post={post} />
      {isFeatureEnabled(PlotFeature.ContentCalendar) && (
        <>
          <PostSaveToContentCalendarButton
            post={post}
            canShowToast
            // TODO: this is hack to refetch post after adding to content calendar
            onAfterAddedToContentCalendar={() => {
              client.refetchQueries({
                include: [GetPostForPostCollectionListDocument],
              });
            }}
          />
          <Divider
            sx={{
              borderColor: theme.colors?.utility[250],
              my: 2,
              borderWidth: 0.5,
            }}
          />
        </>
      )}
      {post.trend && (
        <Link to={PlotRoutes.trendingJuice(post.trend.id)}>
          <Typography
            color={theme.colors?.utility[800]}
            fontWeight={600}
            variant="body-xl"
          >
            ⚡ Trend: {post.trend.title}
          </Typography>
        </Link>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography variant="headline-xxs" color={theme.colors?.utility[600]}>
          Add to Collections
        </Typography>
        <PostCollectionList
          post={post}
          shouldShowCollectionsPill
          renderCustomBtn={() => (
            <Box
              sx={{
                padding: theme.spacing(1.5, 3),
                bgcolor: theme.colors?.utility['purple-1'],
                borderRadius: 25,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              +
              <IconLinearSave
                size={16}
                style={{
                  strokeWidth: 1.5,
                }}
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};
