import { gql } from '@apollo/client';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'components/common/Toast';
import { useUserContext } from 'contexts/users/User.context';
import { useAuthForMobileInappBrowser } from 'features/auth';
import {
  AuthFragmentUserContextFragmentDoc,
  LoginSource,
  useGoogleLoginMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { getCustomOperationContext } from 'utils/apollo';
import { StyledGoogleButton, StyledTypography } from './styles';

export type GoogleLoginButtonProps = {
  text: string;
};

// eslint-disable-next-line
gql`
  mutation GoogleLogin($data: GoogleLoginInput!) {
    googleLogin(data: $data) {
      ...AuthFragmentUserContext
    }
  }
  ${AuthFragmentUserContextFragmentDoc}
`;

export const GoogleLoginButton = (props: GoogleLoginButtonProps) => {
  const { text } = props;

  const { setLoginData } = useUserContext();

  const [googleLogin] = useGoogleLoginMutation({
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });
  const [isLoading, setIsLoading] = useState(false);

  const { isInMobileInAppBrowser, redirectToMobile } =
    useAuthForMobileInappBrowser();

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        if (response.access_token) {
          const accessToken = response.access_token;
          const { data } = await googleLogin({
            variables: {
              data: {
                input: {
                  token: accessToken,
                  source: isInMobileInAppBrowser
                    ? LoginSource.Mobile
                    : LoginSource.Web,
                },
              },
            },
          });

          if (data) {
            // If in mobile inapp browser, redirect to the redirectURI with the
            // access token attached in search params
            if (isInMobileInAppBrowser) {
              redirectToMobile({
                accessToken: data.googleLogin.accessToken,
                refreshToken: data.googleLogin.refreshToken,
                // Because GoogleLoginButton (and googleLogin mutation) is used for both login and sign-up,
                // this flag will ensure that if user login/sign-up with Google for the first time,
                // they won't hit the paywall on mobile app immediately.
                // Subsequent logins will already be blocked by the BE, so this flag will be ignored.
                isSignUp: data.googleLogin.isSignUp || false,
              });

              return;
            }

            setLoginData(data.googleLogin);
          }
        }
      } catch (error: any) {
        toast({
          message: error.message,
          type: 'error',
          captureMessage: true,
        });
      }

      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
    onNonOAuthError: () => {
      setIsLoading(false);
    },
  });

  return (
    <StyledGoogleButton
      onClick={() => {
        setIsLoading(true);
        login();
      }}
      disabled={isLoading}
    >
      <img src="/google-logo.svg" alt="google-logo" height={18} width={18} />
      <StyledTypography
        variant="headline-sm"
        color={theme.colors?.utility[1000]}
        letterSpacing={0}
      >
        {text}
      </StyledTypography>
    </StyledGoogleButton>
  );
};
