import LiveblocksProvider from '@liveblocks/yjs';
import { Box } from '@mui/material';
import { Extensions } from '@tiptap/react';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import {
  CollaborationConnectionToast,
  CollaborationRoom,
  Presence,
  RoomEvent,
  UserInfo,
  UserMeta,
  useOthersMapped,
  useRoom,
  useSelf,
} from 'features/collaboration';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';
import * as Y from 'yjs';
import { generateCollaborationExtensions } from './utils';

type CollaborationEditorProps = {
  roomId: string | null;
  initialYDoc?: Y.Doc;
  fallBack?: ReactNode;
  renderOnlineUsers?: (users: UserInfo[]) => ReactNode;
  renderEditor: (
    collaborationExtensions: Extensions,
    provider?: LiveblocksProvider<Presence, Storage, UserMeta, RoomEvent>,
  ) => ReactNode;
};

export const CollaborationEditor = (props: CollaborationEditorProps) => {
  const { roomId, renderOnlineUsers, renderEditor, initialYDoc, fallBack } =
    props;

  return roomId ? (
    <CollaborationRoom
      roomId={roomId}
      initialPresence={{ cursor: null }}
      fallBack={fallBack}
    >
      <EditorWrapper
        renderOnlineUsers={renderOnlineUsers}
        renderEditor={renderEditor}
        initialYDoc={initialYDoc}
      />
    </CollaborationRoom>
  ) : (
    <Box width="100%">{renderEditor([])}</Box>
  );
};

type EditorWrapperProps = Pick<
  CollaborationEditorProps,
  'renderOnlineUsers' | 'renderEditor' | 'initialYDoc'
>;

const EditorWrapper = (props: EditorWrapperProps) => {
  const { renderOnlineUsers, renderEditor, initialYDoc } = props;

  const currentUserInfo = useSelf((user) => user.info);

  const room = useRoom();
  const [doc, setDoc] = useState<Y.Doc>();
  const [provider, setProvider] =
    useState<LiveblocksProvider<Presence, Storage, UserMeta, RoomEvent>>();
  const [extensions, setExtensions] = useState<Extensions>();

  useEffect(() => {
    if (!extensions && doc && provider) {
      setExtensions(
        generateCollaborationExtensions(doc, provider, currentUserInfo),
      );
    }
  }, [doc, extensions, provider, currentUserInfo]);

  useEffect(() => {
    const yDoc = initialYDoc || new Y.Doc();
    const yProvider = new LiveblocksProvider(room, yDoc);

    setDoc(yDoc);
    setProvider(yProvider);

    return () => {
      yDoc?.destroy();
      yProvider?.destroy();
    };
  }, [room]); // eslint-disable-line

  if (!doc || !provider) {
    return null;
  }

  return (
    <Box width="100%">
      {extensions && renderEditor(extensions, provider)}

      <CollaborationConnectionToast />
      {renderOnlineUsers && (
        <OnlineUsersHandler renderOnlineUsers={renderOnlineUsers} />
      )}
    </Box>
  );
};

const OnlineUsersHandler = (props: {
  renderOnlineUsers: (users: UserInfo[]) => ReactNode;
}) => {
  const { renderOnlineUsers } = props;

  const location = useLocation();

  const onlineUsers = useOthersMapped((other) => other.info);
  const { addRenderMenuExtraLeft, removeRenderMenuExtraLeft } =
    useCustomHeaderContext();

  useEffect(() => {
    const render = () => (
      <Box display="flex" alignItems="center" gap={2} mr={2}>
        <Box marginRight={theme.spacing(2)}>
          {renderOnlineUsers(onlineUsers.map(([_, data]) => data))}
        </Box>
      </Box>
    );
    addRenderMenuExtraLeft(render, 0);

    return () => {
      removeRenderMenuExtraLeft(render, 0);
    };
  }, [location.pathname, onlineUsers]); // eslint-disable-line

  return null;
};
