import { gql } from '@apollo/client';
import { URL_METADATA_FRAGMENT_IFRAMELY } from 'components/common/Iframely/types';
import {
  PostFragmentPostNotePreviewCompactViewFragmentDoc,
  PostFragmentPostNotePreviewViewFragmentDoc,
  UrlMetadataFragmentPostPreviewFragmentDoc,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  fragment URLMetadataFragmentPostPreview on URLMetadataModel {
    ...URLMetadataFragmentIframely
  }
  ${URL_METADATA_FRAGMENT_IFRAMELY}
`;

export const POST_FRAGMENT_POST_PREVIEW = gql`
  fragment PostFragmentPostPreview on PostModel {
    id
    title
    type
    urlMetadata {
      ...URLMetadataFragmentPostPreview
    }
    ...PostFragmentPostNotePreviewView
    ...PostFragmentPostNotePreviewCompactView
  }
  ${UrlMetadataFragmentPostPreviewFragmentDoc}
  ${PostFragmentPostNotePreviewViewFragmentDoc}
  ${PostFragmentPostNotePreviewCompactViewFragmentDoc}
`;
