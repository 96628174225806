/**
 * DO NOT MODIFY THIS FILE WITHOUT ALSO MODIFYING THE TYPE ON MOBILE APP!
 */

export enum WebViewRichTextEditorEventType {
  ReadyToInitialize = 'webview-rich-text-editor-ready-to-initialize',
  Initialize = 'webview-rich-text-editor-initialize',
  ContentChange = 'webview-rich-text-editor-content-change',
  DimensionsChange = 'webview-rich-text-editor-dimensions-change',
  ExecuteCommand = 'webview-rich-text-editor-execute-command',
  EditorStateChange = 'webview-rich-text-editor-editor-state-state',
}

export interface WebViewRichTextEditorEventBaseData<
  T extends WebViewRichTextEditorEventType = WebViewRichTextEditorEventType,
> {
  type: T;
}

export interface WebViewRichTextEditorEventReadyToInitializeData
  extends WebViewRichTextEditorEventBaseData<WebViewRichTextEditorEventType.ReadyToInitialize> {}

export interface WebViewRichTextEditorEventInitializeData
  extends WebViewRichTextEditorEventBaseData<WebViewRichTextEditorEventType.Initialize> {
  roomId: string;
  defaultContent: string;
}

export interface WebViewRichTextEditorEventContentChangeData
  extends WebViewRichTextEditorEventBaseData<WebViewRichTextEditorEventType.ContentChange> {
  content: string;
}

export interface WebViewRichTextEditorEventDimensionsChangeData
  extends WebViewRichTextEditorEventBaseData<WebViewRichTextEditorEventType.DimensionsChange> {
  dimensions: { width: number; height: number };
}

export enum WebViewRichTextEditorCommandType {
  DocumentUpload = 'DocumentUpload',
  TaskList = 'TaskList',
  BulletedList = 'BulletedList',
  NumberedList = 'NumberedList',
  CodeBlock = 'CodeBlock',
  ChangeColor = 'ChangeColor',
  Disable = 'Disable',
  Enable = 'Enable',
  Undo = 'Undo',
  Redo = 'Redo',
}

export type WebViewRichTextEditorEventExecuteCommandData =
  WebViewRichTextEditorEventBaseData<WebViewRichTextEditorEventType.ExecuteCommand> &
    (
      | { command: 'ChangeColor'; color: string }
      | { command: Exclude<WebViewRichTextEditorCommandType, 'ChangeColor'> }
    );

// This is a custom object, not the same as EditorState of tiptap
export type WebViewRichTextEditorEditorCustomState = {
  canUndo: boolean;
  canRedo: boolean;
};

export interface WebViewRichTextEditorEventEditorStateData
  extends WebViewRichTextEditorEventBaseData<WebViewRichTextEditorEventType.EditorStateChange> {
  customState: WebViewRichTextEditorEditorCustomState;
}

export type WebViewRichTextEditorEventData =
  | WebViewRichTextEditorEventReadyToInitializeData
  | WebViewRichTextEditorEventInitializeData
  | WebViewRichTextEditorEventContentChangeData
  | WebViewRichTextEditorEventDimensionsChangeData
  | WebViewRichTextEditorEventExecuteCommandData
  | WebViewRichTextEditorEventEditorStateData;
