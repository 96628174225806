export enum COMMAND_TYPE {
  // Post
  POST_WRITE_A_NOTE = 'POST_WRITE_A_NOTE',
  POST_PASTE_A_LINK = 'POST_PASTE_A_LINK',
  POST_UPLOAD_FILES = 'POST_UPLOAD_FILES',
  POST_AFTER_CREATION = 'POST_AFTER_CREATION',
  POST_DOWNLOAD = 'POST_DOWNLOAD',
  POST_SELECT_FROM_CJB = 'POST_SELECT_FROM_CJB',

  // Search
  SEARCH = 'SEARCH',

  // Task
  CREATE_TASK = 'CREATE_TASK',

  // Collection
  CREATE_COLLECTION = 'CREATE_COLLECTION',
  SEND_TO_COLLECTION = 'SEND_TO_COLLECTION',

  // Content Idea
  CREATE_CONTENT_IDEA = 'CREATE_CONTENT_IDEA',
}

export const COMMAND_KEYS: Record<COMMAND_TYPE, string[]> = {
  // Post
  POST_WRITE_A_NOTE: ['n'],
  POST_PASTE_A_LINK: ['meta', 'v'],
  POST_UPLOAD_FILES: ['meta', 'u'],
  POST_AFTER_CREATION: ['p'],
  POST_DOWNLOAD: [],
  POST_SELECT_FROM_CJB: [],

  // Search
  SEARCH: ['meta', 'k'],

  // Task
  CREATE_TASK: ['t'],

  // Collection
  CREATE_COLLECTION: ['c'],
  SEND_TO_COLLECTION: [],

  // Content Idea
  CREATE_CONTENT_IDEA: [],
};
