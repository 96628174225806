import { Box } from '@mui/material';
import { theme } from 'styles/theme';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { Platform } from 'graphql/generated';

const platformIconMap = {
  [Platform.Instagram]: IconBoldInstagram,
  [Platform.Tiktok]: IconCustomTiktok,
};

export const StaticImage = ({
  thumbnailUrl,
  platform,
  renderHoverActionsBottom,
  renderHoverActionsTopRight,
}: {
  thumbnailUrl: string;
  platform: Platform;
  renderHoverActionsBottom?: () => React.ReactNode;
  renderHoverActionsTopRight?: () => React.ReactNode;
}) => {
  const Icon = platformIconMap[platform];

  return (
    <Box
      position="relative"
      width="100%"
      height="0"
      sx={{ paddingTop: '177.77%', overflow: 'hidden' }} // 56.25% for 16:9 aspect ratio
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        sx={{ borderRadius: 4, cursor: 'pointer', objectFit: 'cover' }}
        component="img"
        src={thumbnailUrl}
      />

      <Box
        position="absolute"
        top={theme.spacing(2)}
        right={theme.spacing(2)}
        display="flex"
        gap={2}
        alignItems="center"
        minHeight={24}
      >
        {renderHoverActionsTopRight && renderHoverActionsTopRight()}
        {Icon && (
          <Icon
            size={16}
            style={{
              color: theme.colors?.primary.white,
            }}
          />
        )}
      </Box>
      {renderHoverActionsBottom && (
        <Box
          position="absolute"
          bottom={theme.spacing(2)}
          right={theme.spacing(2)}
          left={theme.spacing(2)}
        >
          {renderHoverActionsBottom()}
        </Box>
      )}
    </Box>
  );
};
