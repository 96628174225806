import { Box, Grid } from '@mui/material';
import {
  BrandCompetitorInfo,
  BrandCompetitorInfoSkeleton,
  BrandCompetitorsPlatformSelector,
} from 'features/brandCompetitors';
import { CompetitorTopCreators } from 'features/brandCompetitors/components/competitorTopCreators';
import {
  Platform,
  useGetCompetitorForBrandCompetitorDetailQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { BrandMentionsChart } from '../../components/brandMentionsChart';
import { CompetitorCommentInsights } from '../../components/commentInsights';
import { CompetitorSentiment } from '../../components/competitorSentiment';
import { CompetitorSummary } from '../../components/competitorSummary/CompetitorSummary';
import { CompetitorTopHashtags } from '../../components/competitorTopHashtags';
import { CompetitorTopPosts } from '../../components/competitorTopPosts';

type Props = {
  competitorId: string;
  platform: Platform;
  searchParams?: URLSearchParams;
};

export const BrandCompetitorDetailView = ({
  competitorId,
  platform,
  searchParams,
}: Props) => {
  const navigate = useNavigate();
  const [currentPlatform, setCurrentPlatform] = useState(platform);
  const { data: competitorData, loading: loadingCompetitorData } =
    useGetCompetitorForBrandCompetitorDetailQuery({
      variables: {
        competitorId,
      },
    });

  const availablePlatforms =
    competitorData?.competitor.trackingBrand.creators.map(
      (creator) => creator.platform,
    );

  const currentCreatorId =
    competitorData?.competitor.trackingBrand.creators.find(
      (creator) => creator.platform === currentPlatform,
    )?.id || '';

  useEffect(() => {
    navigate(
      PlotRoutes.socialListeningCompetitor(competitorId, currentPlatform),
      {
        replace: true,
      },
    );
  }, [currentPlatform]);

  return (
    <Box
      mt={16}
      p={8}
      width="100%"
      display="flex"
      flexDirection="column"
      gap={6}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        {loadingCompetitorData || !competitorData ? (
          <BrandCompetitorInfoSkeleton />
        ) : (
          <BrandCompetitorInfo
            competitor={competitorData.competitor}
            currentPlatform={currentPlatform}
          />
        )}
        <Box>
          {availablePlatforms && availablePlatforms?.length > 1 && (
            <BrandCompetitorsPlatformSelector
              defaultPlatform={currentPlatform}
              onPlatformSelect={setCurrentPlatform}
            />
          )}
        </Box>
      </Box>

      <Grid container spacing={6} columns={12}>
        <Grid item xs={12} lg={4}>
          <Box display="flex" flexDirection="column" gap={6} width="100%">
            <CompetitorSummary
              competitorId={competitorId}
              platform={currentPlatform as Platform}
            />
            <CompetitorTopHashtags
              competitorId={competitorId}
              creatorId={currentCreatorId}
              currentPlatform={currentPlatform}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <CompetitorTopPosts
            competitorId={competitorId}
            creatorId={currentCreatorId}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} columns={12}>
        <Grid item xs={12}>
          <CompetitorTopCreators
            competitorId={competitorId}
            creatorId={currentCreatorId}
          />
        </Grid>
      </Grid>

      <BrandMentionsChart
        competitorId={competitorId}
        currentPlatform={currentPlatform}
      />

      <CompetitorSentiment
        competitorId={competitorId}
        brandId={competitorData?.competitor.trackingBrand.id || ''}
        currentPlatform={currentPlatform}
      />

      <CompetitorCommentInsights
        competitorId={competitorId}
        brandId={competitorData?.competitor.trackingBrand.id || ''}
        platform={currentPlatform}
      />
    </Box>
  );
};
