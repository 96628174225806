import { Box, MenuItem, Select, SxProps, Typography } from '@mui/material';
import { IconLinearUserRemove } from 'components/icons/components/linear/IconLinearUserRemove';
import { useUserContext } from 'contexts/users/User.context';
import { InternalOrganizationRole } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

const InternalOrganizationRolesMap = {
  [InternalOrganizationRole.Admin]: {
    label: 'Admin',
    points: [
      'Manage billing',
      'Full organization access',
      'Collaborator privileges',
    ],
  },
  [InternalOrganizationRole.User]: {
    label: 'All-Access Member',
    points: [
      'Full organization access',
      'Have edit, view or comment privileges',
    ],
  },
  [InternalOrganizationRole.SocialListeningUser]: {
    label: 'Social Listening Member',
    points: ['Access only to Social Listening', 'No access to core platform'],
  },
};

export type OrgRoleSelectorProps = {
  isDisabledUser?: boolean;
  minimalView?: boolean;
  canUpdate?: boolean;
  viewOnly?: boolean;
  initialValue: InternalOrganizationRole;
  onRoleChange: (role: InternalOrganizationRole) => void;
  selectPaperSx?: SxProps;
};

export const OrgRoleSelector = (props: OrgRoleSelectorProps) => {
  const {
    isDisabledUser,
    onRoleChange,
    initialValue,
    selectPaperSx = {},
    minimalView,
    canUpdate = true,
    viewOnly,
  } = props;

  const { orgBilling } = useUserContext();
  const socialListeningEnabled = Boolean(orgBilling?.socialListeningEnabled);

  const [currentRole, setCurrentRole] = useState<
    InternalOrganizationRole | undefined
  >(initialValue);

  useEffect(() => {
    if (currentRole) {
      onRoleChange(currentRole);
    }
  }, [currentRole]); // eslint-disable-line react-hooks/exhaustive-deps

  const rolesToRender = [
    InternalOrganizationRole.Admin,
    InternalOrganizationRole.User,
    socialListeningEnabled ? InternalOrganizationRole.SocialListeningUser : '',
  ].filter(Boolean) as InternalOrganizationRole[];

  if (viewOnly) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {currentRole && (
          <Box
            sx={{
              color: minimalView
                ? theme.colors?.utility[800]
                : theme.colors?.primary.white,
              backgroundColor: minimalView
                ? 'transparent'
                : theme.colors?.utility[800],
              display: 'flex',
              p: theme.spacing(1, 3),
              borderRadius: theme.spacing(4),
              width: 'fit-content',
            }}
          >
            <Typography variant="headline-xs">
              {InternalOrganizationRolesMap[currentRole]
                ? InternalOrganizationRolesMap[currentRole].label
                : currentRole}
            </Typography>
          </Box>
        )}
      </>
    );
  }

  return isDisabledUser ? (
    <Box
      sx={{
        bgcolor: theme.colors?.utility[800],
        borderRadius: theme.spacing(25),
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(1.25, 2),
      }}
    >
      <IconLinearUserRemove size={14} color={theme.colors?.primary.white} />
      <Typography variant="headline-xs" color={theme.colors?.primary.white}>
        Disabled
      </Typography>
    </Box>
  ) : (
    <Select
      size="small"
      sx={{
        '& .MuiSelect-select': {
          padding: '2px 22px 2px 8px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiSelect-icon': {
          color: minimalView
            ? theme.colors?.utility[800]
            : theme.colors?.primary.white,
        },

        backgroundColor: minimalView
          ? 'transparent'
          : theme.colors?.utility[800],
        borderRadius: theme.spacing(4),
        p: theme.spacing(1, 2),
        color: minimalView
          ? theme.colors?.utility[800]
          : theme.colors?.primary.white,
      }}
      value={initialValue}
      MenuProps={{
        PaperProps: {
          sx: {
            boxShadow: '0px 6px 12px -2px rgba(0, 0, 0, 0.30)',
            p: 1,
            borderRadius: 4,
            ...selectPaperSx,
          },
        },
      }}
      onChange={(e) => {
        setCurrentRole(e.target.value as InternalOrganizationRole);
      }}
      renderValue={(value: string) => (
        <Typography variant="headline-xs">
          {InternalOrganizationRolesMap[value]
            ? InternalOrganizationRolesMap[value].label
            : value}
        </Typography>
      )}
    >
      {rolesToRender.map((role, index) => (
        <MenuItem
          value={role}
          key={index}
          disabled={!canUpdate}
          sx={{
            '&.Mui-selected, &:hover': {
              backgroundColor: `${theme.colors?.utility[275]} !important`,
              borderRadius: theme.spacing(3),
            },
            '&.Mui-selected': {
              backgroundColor: `${theme.colors?.utility[400]} !important`,
            },
          }}
        >
          <Box display="flex" alignItems="center">
            <Box display="flex" gap={1} flexDirection="column" ml={3}>
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                sx={{ fontWeight: 600 }}
              >
                {InternalOrganizationRolesMap[role].label}
              </Typography>

              <Typography
                variant="body-sm"
                color={theme.colors?.utility[700]}
                sx={{ fontWeight: 500 }}
              >
                {InternalOrganizationRolesMap[role].points.map((point) => (
                  <Box display="flex">
                    <Typography variant="body-sm">• {point}</Typography>
                  </Box>
                ))}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};
