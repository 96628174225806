import { gql } from '@apollo/client';
import { SocialPostCommentFragmentSocialPostCommentCreatedTimeFragment } from 'graphql/generated';
import { Box, SxProps, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo } from 'react';

gql`
  fragment SocialPostCommentFragmentSocialPostCommentCreatedTime on SocialPostCommentModel {
    id
    platformCreateTime
  }
`;

type SocialPostCommentCreatedTimeProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentCreatedTimeFragment;
  componentProps?: {
    sx?: SxProps;
    textSx?: SxProps;
  };
};

export const SocialPostCommentCreatedTime = (
  props: SocialPostCommentCreatedTimeProps,
) => {
  const { socialPostComment, componentProps } = props;

  const getHumanFormat = useMemo(() => {
    return moment(socialPostComment.platformCreateTime).format('MMMM D, YYYY');
  }, [socialPostComment.platformCreateTime]);

  return (
    <Box sx={componentProps?.sx}>
      <Typography variant="subhead-sm" sx={componentProps?.textSx}>
        {getHumanFormat}
      </Typography>
    </Box>
  );
};
