import { useApolloClient } from '@apollo/client';
import { Box, Button, TextField, Typography } from '@mui/material';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import { SocialHandleInput } from 'features/socialListeningSettings';
import {
  SocialMediaListeningKeywords,
  WordLimitedInputArea,
} from 'features/socialMediaListening/components';
import { SocialMediaListeningOnboardingCountrySelectionInput } from 'features/socialMediaListening/views/onboarding/sections/countrySelection';
import {
  ListeningTopicType,
  Platform,
  UpdateCompetitorDataInput,
  useRemoveCompetitorsForCompetitorEditMutation,
  useUpdateCompetitorsForCompetitorEditMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import React, { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { evictObject, modifyObject } from 'utils/apollo';
import { CompetitorEditProps } from './types';

export const CompetitorEdit = ({ competitor }: CompetitorEditProps) => {
  const client = useApolloClient();

  const [brandName, setBrandName] = useState(competitor.name);
  const [aboutFieldValue, setAboutFieldValue] = useState(
    competitor.description,
  );
  const [updateCompetitorsForCompetitorEdit] =
    useUpdateCompetitorsForCompetitorEditMutation();
  const [removeCompetitors] = useRemoveCompetitorsForCompetitorEditMutation();

  const {
    dialog: deleteCompetitorConfirmation,
    onOpen: openDeleteCompetitorConfirmationDialog,
  } = useConfirmationDialog();

  useEffect(() => {
    setBrandName(competitor.name);
    setAboutFieldValue(competitor.description);
  }, [competitor]);

  const instagramCreator = competitor.trackingBrand?.creators.find(
    (creator) => creator.platform === Platform.Instagram,
  );
  const tiktokCreator = competitor.trackingBrand?.creators.find(
    (creator) => creator.platform === Platform.Tiktok,
  );

  const searchTerms = competitor.searchTerms || [];

  const updateCompetitor = async (data: UpdateCompetitorDataInput) => {
    updateCompetitorsForCompetitorEdit({
      variables: {
        data: {
          competitorId: competitor.id,
          data,
        },
      },
      update: (cache, res) => {
        modifyObject(cache, competitor.id, 'CompetitorModel', {
          isPaused: () =>
            data.isPaused !== undefined ? data.isPaused : competitor.isPaused,
          region: () =>
            data.regions !== undefined ? data.regions : competitor.regions,
          name: () => (data.name !== undefined ? data.name : competitor.name),
          creators: () =>
            data.creators !== undefined
              ? data.creators
              : competitor.trackingBrand.creators,
          description: () =>
            data.description !== undefined
              ? data.description
              : competitor.description,
        });

        if (data.searchTerms && res.data?.updateCompetitors) {
          const newSearchTerms = searchTerms.filter(
            (term) =>
              !data.searchTerms?.some((newTerm) => newTerm.term === term.term),
          );
          modifyObject(cache, competitor.id, 'SearchTermModel', {
            searchTerms: () => {
              return [...newSearchTerms, ...searchTerms];
            },
          });
        }
      },
    });
  };

  const updateCompetitorInstagramHandle = async (handle: string) => {
    updateCompetitor({
      creators: [
        ...(competitor.trackingBrand.creators
          ?.filter((creator) => creator.platform !== Platform.Instagram)
          .map((creator) => ({
            handle: creator.handle,
            platform: creator.platform,
          })) || []),
        {
          handle,
          platform: Platform.Instagram,
        },
      ],
    });
  };

  const updateCompetitorTiktokHandle = async (handle) => {
    updateCompetitor({
      creators: [
        ...(competitor.trackingBrand.creators
          ?.filter((creator) => creator.platform !== Platform.Tiktok)
          .map((creator) => ({
            platform: creator.platform,
            handle: creator.handle,
          })) || []),
        {
          platform: Platform.Tiktok,
          handle,
        },
      ],
    });
  };

  const onDeleteCompetitor = async (competitorId: string) => {
    openDeleteCompetitorConfirmationDialog({
      onConfirm: async () => {
        await removeCompetitors({
          variables: {
            data: {
              competitorIds: [competitorId],
            },
          },
          onCompleted: () => {
            evictObject(client.cache, competitorId, 'CompetitorModel');
          },
        });
      },
      title: 'Are you sure?',
      subtitle: 'Deleting this competitor is a permanent action?',
      confirmText: 'Delete competitor',
      cancelText: 'Cancel',
    });
  };

  return (
    <Box display="flex" gap={4}>
      <Box display="flex" flex={3} flexDirection="column" mt={2} gap={3}>
        <Typography variant="headline-sm">Brand Name</Typography>
        <TextField
          value={brandName}
          onChange={(e) => {
            setBrandName(e.target.value);
          }}
          onBlur={(e) => {
            if (competitor.name !== brandName) {
              updateCompetitor({
                name: e.target.value,
              });
            }
          }}
          sx={{
            width: 280,
            '& .MuiOutlinedInput-root': {
              fontWeight: 600,
              color: theme.colors?.primary.black,
              pb: 1,
              paddingRight: `${theme.spacing(2)}`,
              borderRadius: theme.spacing(2),
              backgroundColor: theme.colors?.utility[250],
              border: `2px solid ${theme.colors?.utility[300]}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
        <Box display="flex" flexDirection="column" gap={3}>
          <SocialHandleInput
            creator={tiktokCreator}
            actions={{
              onConfirm: async (creator) => {
                updateCompetitorTiktokHandle(creator.handle);
              },
              onRemove: () => {
                updateCompetitor({
                  creators: competitor.trackingBrand.creators
                    .filter((creator) => creator.platform !== Platform.Tiktok)
                    .map((creator) => ({
                      handle: creator.handle,
                      platform: creator.platform,
                    })),
                });
              },
            }}
            platform={Platform.Tiktok}
          />
          <SocialHandleInput
            creator={instagramCreator}
            actions={{
              onConfirm: async (creator) => {
                updateCompetitorInstagramHandle(creator.handle);
              },
              onRemove: () => {
                updateCompetitor({
                  creators: competitor.trackingBrand.creators
                    .filter(
                      (creator) => creator.platform !== Platform.Instagram,
                    )
                    .map((creator) => ({
                      handle: creator.handle,
                      platform: creator.platform,
                    })),
                });
              },
            }}
            platform={Platform.Instagram}
          />
        </Box>

        <SocialMediaListeningOnboardingCountrySelectionInput
          selectedCountries={competitor.regions}
          onCountriesUpdated={(list) => {
            const newCountries = list.map((country) => country.code);
            const existingCountries = competitor.regions;
            if (
              JSON.stringify(newCountries) !== JSON.stringify(existingCountries)
            ) {
              updateCompetitor({
                regions: newCountries,
              });
            }
          }}
          componentProps={{
            sx: {
              '.MuiOutlinedInput-root': {
                color: theme.colors?.primary.black,
                borderRadius: theme.spacing(2),
                backgroundColor: theme.colors?.utility[250],
                border: `2px solid ${theme.colors?.utility[300]}`,
                p: 0,
                m: 0,
              },
              '.country-input .MuiOutlinedInput-root': {
                p: `0 !important`,
                m: `0 !important`,
              },
              '.country-input .MuiOutlinedInput-root input': {
                color: theme.colors?.primary.black,
                p: `${theme.spacing(2)} !important`,
                m: `${theme.spacing(2)} !important`,
                fontSize: theme.spacing(3.5),
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '.country-tag': {
                color: theme.colors?.primary.black,
                my: 2,
              },
              '.country-tag svg': {
                color: theme.colors?.utility[800],
              },
            },
          }}
        />

        <Box display="flex" flexDirection="column" mt={2} gap={3}>
          <Typography variant="headline-md">About the brand</Typography>
          <Typography variant="body-xl" color={theme.colors?.utility[800]}>
            Fill us in about the company. For example, what do they sell? Who is
            their target audience?
          </Typography>

          <WordLimitedInputArea
            autoFocus={false}
            value={aboutFieldValue}
            onChange={(e) => {
              setAboutFieldValue(e.target.value);
            }}
            maxWords={100}
            componentProps={{
              wordUsage: {
                sx: {
                  position: 'absolute',
                  left: theme.spacing(4),
                  bottom: theme.spacing(4),
                  color: theme.colors?.utility[700],
                },
              },
            }}
            multiline
            minRows={7}
            maxRows={12}
            sx={{
              mt: 4,
              '.MuiOutlinedInput-root': {
                py: `${theme.spacing(4)} !important`,
                px: `${theme.spacing(4)} !important`,
                bgcolor: 'rgba(255, 255, 255, 0.10)',
                borderRadius: 3,
                border: `2px solid ${theme.colors?.utility[300]}`,
                background: theme.colors?.utility[200],

                input: {
                  p: '0 !important',
                  ...theme.typography['body-xl'],
                },

                '.MuiOutlinedInput-notchedOutline': {
                  display: 'none !important',
                },
              },
            }}
          >
            {competitor.description !== aboutFieldValue && (
              <Button
                fullWidth={false}
                variant="primary"
                onClick={() => {
                  updateCompetitor({
                    description: aboutFieldValue,
                  });
                }}
                sx={{
                  position: 'absolute',
                  right: theme.spacing(4),
                  bottom: theme.spacing(6),
                  mt: 4,
                  p: theme.spacing(2, 4),
                  borderRadius: theme.spacing(5),
                  backgroundColor: theme.colors?.primary.black,
                  border: 'none',
                  '&:hover': {
                    backgroundColor: theme.colors?.primary.black,
                  },
                }}
              >
                Save changes
              </Button>
            )}
          </WordLimitedInputArea>
        </Box>
        <Box display="flex" gap={3} mt={4}>
          <Button
            variant="primary"
            sx={{
              '&, &:hover': {
                p: theme.spacing(2, 4),
                width: 'fit-content',
                color: theme.colors?.primary.black,
                backgroundColor: theme.colors?.primary.white,
                borderRadius: 2,
                border: `1px solid ${theme.colors?.primary.black}`,
              },
            }}
            startIcon={
              !competitor.isPaused ? (
                <IconBoldPauseCircle size={16} />
              ) : (
                <IconBoldPlayCircle size={16} />
              )
            }
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              updateCompetitor({
                isPaused: !competitor.isPaused,
              })
            }
          >
            <Typography variant="headline-sm">
              {!competitor.isPaused ? 'Pause competitor' : 'Resume competitor'}
            </Typography>
          </Button>

          <Button
            variant="primary"
            sx={{
              '&, &:hover': {
                p: theme.spacing(2, 4),
                width: 'fit-content',
                color: theme.colors?.primary.parchment,
                backgroundColor: theme.colors?.utility['pink-3'],
                borderRadius: 2,
                border: 'none',
              },
            }}
            startIcon={<IconBoldTrash size={16} />}
            onClick={() => {
              onDeleteCompetitor(competitor.id);
            }}
          >
            <Typography variant="headline-sm">Delete competitor</Typography>
          </Button>
        </Box>
      </Box>

      <Box display="flex" flex={2} flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={3} flex={2}>
          <Typography variant="headline-md">Keywords</Typography>
          <Typography variant="body-xl" color={theme.colors?.utility[800]}>
            Fill us in about your company. For example, what do you sell? Who is
            your target audience?
          </Typography>
          <Box
            sx={{
              backgroundColor: theme.colors?.utility[200],
              border: `2px solid ${theme.colors?.utility[300]}`,
              p: 4,
              borderRadius: 3,
            }}
          >
            <SocialMediaListeningKeywords
              keywords={searchTerms.map((term) => term.term)}
              updateKeywords={async (updatedKeywords) => {
                updateCompetitor({
                  searchTerms: updatedKeywords.map((keyword) => ({
                    term: keyword,
                  })),
                });
              }}
              componentProps={{
                createKeyword: {
                  position: 'pre',
                  sx: {
                    mt: 0,
                    fontWeight: 500,
                    backgroundColor: theme.colors?.utility[300],
                    color: theme.colors?.utility[600],
                    '& .MuiInputBase-root': {
                      color: `${theme.colors?.primary.black} !important`,
                    },
                    '& .keyword-add': {
                      color: theme.colors?.utility[600],
                    },
                    '& .keyword-label': {
                      fontSize: theme.spacing(3.5),
                    },
                  },
                },
                keyword: {
                  sx: {
                    backgroundColor: theme.colors?.utility[300],
                    color: theme.colors?.primary.black,
                    fontWeight: 400,
                    '& .keyword-delete': {
                      color: theme.colors?.utility[600],
                    },
                    '& .keyword-label': {
                      fontSize: theme.spacing(3.5),
                      color: theme.colors?.primary.black,
                    },
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      {deleteCompetitorConfirmation}
    </Box>
  );
};
