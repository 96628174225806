import {
  Box,
  Button,
  IconButton,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { CUSTOM_COLLECTION } from 'features/collection/constants';
import {
  CollectionModel,
  PostFilterType,
  PostFragmentPostCommandFragment,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { CollectionListSection } from './sections';
import { ContentCalendarCustomCollectionMasonrySection } from './sections/ContentCalendarCustomCollectionMasonrySection';
import { CustomCollectionPostMasonrySection } from './sections/CustomCollectionPostMasonrySection';
import { NormalCollectionPostMasonrySection } from './sections/NormalCollectionPostMasonrySection';
import { SearchResultSection } from './sections/SearchResultSection';

export type JuiceboxWindowDialogViewProps = {
  sx?: SxProps;
  componentsProps?: {
    submitButton?: {
      label?: string;
    };
  };
  onClose: () => void;
  onSubmit: (posts: PostFragmentPostCommandFragment[]) => void;

  /**
   * Custom prop for content idea related flow.
   * When users init the POST_SELECT_FROM_CJB command from a content idea,
   * we'll want to display the content idea's representative collection as the first collection,
   * so that users can quickly, say, add a post from Draft Assets to Final Assets.
   *
   * It's hardcoded right now, but I'll look for a more generic solution later.
   */
  hardcodedContentIdeaCollectionId?: string;
};

export const JuiceboxWindowDialogView = (
  props: JuiceboxWindowDialogViewProps,
) => {
  const {
    hardcodedContentIdeaCollectionId,
    sx,
    componentsProps = {},
    onClose,
    onSubmit,
  } = props;

  const isMobileView = useMediaQueryMobile();

  const [query, setQuery] = useState('');

  const [collection, setCollection] =
    useState<Pick<CollectionModel, 'id' | 'name'>>();
  const breadcrumbRef = useRef<Pick<CollectionModel, 'id' | 'name'>[]>([]);
  useEffect(() => {
    if (collection) {
      breadcrumbRef.current.push(collection);
    }
  }, [collection?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedPosts, setSelectedPosts] = useState<
    PostFragmentPostCommandFragment[]
  >([]);

  // Reset selected post ids when collection changes
  useEffect(() => {
    setSelectedPosts([]);
    setQuery('');
  }, [collection]);

  const onPostClick = (post: PostFragmentPostCommandFragment) => {
    if (selectedPosts.some((p) => p.id === post.id)) {
      setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id));
    } else {
      setSelectedPosts([...selectedPosts, post]);
    }
  };

  const title = useMemo(() => {
    if (collection) {
      return collection.name;
    }

    return 'Creative Juicebox';
  }, [collection]);

  return (
    <Box
      sx={{
        width: 1024,
        height: 768,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box
        sx={{
          py: 5,
          px: 6,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: '#E6DDED',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {collection && (
            <IconButton
              size="small"
              sx={{ p: 0 }}
              onClick={() => {
                breadcrumbRef.current.pop();
                setCollection(breadcrumbRef.current.pop());
              }}
            >
              <IconOutlineArrowLeft />
            </IconButton>
          )}
          <Typography variant="headline-md">{title}</Typography>
        </Box>
        <IconButton
          size="small"
          onClick={onClose}
          sx={{ p: 0, color: theme.colors?.primary.black }}
        >
          <IconBoldCloseCircle />
        </IconButton>
      </Box>
      <Box
        className="collection-cjb-window-view-scrollable-container"
        sx={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
          px: 6,
          bgcolor: '#E6DDED',
        }}
      >
        <TextField
          placeholder="Find your creative juice..."
          autoFocus
          sx={{
            width: '100%',
            bgcolor: 'white',
            borderRadius: 24,
            padding: isMobileView ? '0 8px !important' : '0 16px !important',
            input: {
              ...typography['headline-lg'],
            },
            fieldset: {
              display: 'none',
            },
          }}
          onChange={debounce((e) => {
            setQuery(e.target.value);
          }, 300)}
        />
        {query ? (
          <SearchResultSection
            filters={{
              query,
              collectionId: collection?.id,
              filterType: PostFilterType.OrganizationPosts,
            }}
            selectedPostIds={selectedPosts.map((p) => p.id)}
            onPostClick={onPostClick}
            onCollectionClick={setCollection}
          />
        ) : collection ? (
          Object.values(CUSTOM_COLLECTION).includes(collection.id as any) ? (
            collection.id === CUSTOM_COLLECTION.CONTENT_CALENDAR ? (
              <ContentCalendarCustomCollectionMasonrySection
                collectionId={collection.id}
                onCollectionClick={setCollection}
              />
            ) : (
              <CustomCollectionPostMasonrySection
                collectionId={collection.id}
                selectedPostIds={selectedPosts.map((p) => p.id)}
                onPostClick={onPostClick}
              />
            )
          ) : (
            <NormalCollectionPostMasonrySection
              collectionId={collection.id}
              selectedPostIds={selectedPosts.map((p) => p.id)}
              onPostClick={onPostClick}
              onCollectionClick={setCollection}
            />
          )
        ) : (
          <CollectionListSection
            onCollectionClick={setCollection}
            hardcodedContentIdeaCollectionId={hardcodedContentIdeaCollectionId}
          />
        )}
      </Box>
      <Box
        sx={{
          py: 5,
          px: 6,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: `2px solid rgba(0, 0, 0, 0.10)`,
          background: 'rgba(255, 255, 255, 0.70)',
          backdropFilter: 'blur(25px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            px: 3,
            py: 2,
            bgcolor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: 2,
            opacity: selectedPosts.length > 0 ? 1 : 0,
          }}
        >
          <IconBoldTickCircle size={16} />
          <Typography variant="headline-sm" color={theme.colors?.utility[800]}>
            {selectedPosts.length} post
            {selectedPosts.length > 1 ? 's' : ''} selected
          </Typography>
        </Box>
        <Button
          variant="primary-alt"
          disabled={selectedPosts.length === 0}
          onClick={() => onSubmit(selectedPosts)}
        >
          {componentsProps?.submitButton?.label || 'Add to Media'}
        </Button>
      </Box>
    </Box>
  );
};
