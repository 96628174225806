import { Box, Button, styled, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconOutlineCloseCircle } from 'components/icons/components/outline/IconOutlineCloseCircle';
import { useEffect, useMemo, useState } from 'react';
import { toast, Toast, useToaster } from 'react-hot-toast';
import { theme } from 'styles/theme/theme';
import { CustomToastProps } from './types';

const StyledIconWrapper = styled(Box)(() => ({
  width: 32,
  height: 32,
  borderRadius: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const defaultIcons = {
  success: (
    <StyledIconWrapper bgcolor={theme.colors?.utility['light-green']}>
      <IconBoldTickCircle color={theme.colors?.utility.green} />
    </StyledIconWrapper>
  ),
  error: (
    <StyledIconWrapper bgcolor={theme.colors?.utility['light-red']}>
      <IconBoldCloseCircle color={theme.colors?.utility.red} />
    </StyledIconWrapper>
  ),
  info: (
    <StyledIconWrapper bgcolor={theme.colors?.utility['light-yellow']}>
      <IconBoldInfoCircleAlt color={theme.colors?.utility['yellow-2']} />
    </StyledIconWrapper>
  ),
  warning: (
    <StyledIconWrapper bgcolor={theme.colors?.utility['light-orange']}>
      <IconBoldInfoCircle color={theme.colors?.utility.orange} />
    </StyledIconWrapper>
  ),
};

const CustomToast = ({
  t,
  type,
  icon,
  title,
  message,
  actionButtons,
  duplicates,
  sx,
  shouldShowCloseButton = true,
}: CustomToastProps & { t: Toast }) => {
  const { toasts } = useToaster();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      if (duplicates) {
        // get the list of all the toasts that are the same as duplicate class name and visible
        // limit number of toasts
        // then close them all
        toasts
          .filter(
            (currentToast) =>
              currentToast.className === duplicates.notificationClass,
          )
          .filter((currentToast) => currentToast.visible)
          .filter((_, i) => i >= duplicates.limit)
          .forEach((currentToast) => toast.remove(currentToast.id));

        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [loading, duplicates, toasts]);

  const actionButtonRender = useMemo(
    () =>
      !!actionButtons?.length && (
        <Box display="flex" gap={3}>
          {actionButtons.map(({ children, ...rest }) => (
            <Button {...rest} size="small">
              {children}
            </Button>
          ))}
        </Box>
      ),
    [actionButtons],
  );

  return loading ? null : (
    <Box
      display="flex"
      gap={3}
      alignItems="flex-start"
      sx={{
        padding: '16px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        backgroundColor: theme.colors?.primary.white,
        ...sx,
      }}
    >
      {icon || (type ? defaultIcons[type] : null)}
      <Box display="flex" flexDirection="column" gap={4} flex={1}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexDirection="column" gap={0.25}>
            <Typography
              textTransform="capitalize"
              sx={{
                fontFamily: 'TT Commons Pro',
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {title || type}
            </Typography>
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[800]}
              sx={{
                div: { margin: 0 },
                fontFamily: 'TT Commons Pro',
                fontSize: 14,
                fontWeight: 500,
                color: theme.colors?.utility[800],
              }}
            >
              {message}
            </Typography>
          </Box>
          {actionButtonRender}
        </Box>
      </Box>
      {shouldShowCloseButton && (
        <Box
          component="button"
          type="button"
          onClick={() => toast.dismiss(t.id)}
          sx={{ color: theme.colors?.utility[700] }}
        >
          <IconOutlineCloseCircle />
        </Box>
      )}
    </Box>
  );
};

export const closeToast = (toastId: string) => {
  return toast.remove(toastId);
};

export const customToast = ({
  captureMessage,
  persist,
  position,
  ...props
}: CustomToastProps) => {
  if (captureMessage) {
    Sentry.captureMessage(props.message);
  }

  return toast.custom((t) => <CustomToast t={t} {...props} />, {
    duration: persist ? Infinity : 3000,
    position: position || 'top-right',

    // since react-hot-toast does not yet support handling duplicate toast
    // we will className as an identifier to hide any duplicate alerts
    className: props.duplicates ? props.duplicates.notificationClass : '',
  });
};
