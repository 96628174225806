import { Box, Divider, MenuProps, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { StyledMenu } from './styles';
import { ContextMenuProps } from './types';

export const ContextMenu = ({
  renderButton,
  options,
  onClose,
  renderExtraBottom,
  renderExtraTop,
  disabled,
  tooltip,
  ...rest
}: ContextMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose: MenuProps['onClose'] = (event, reason) => {
    // @ts-ignore
    event?.preventDefault?.();
    // @ts-ignore
    event?.stopPropagation?.();

    onClose?.(event, reason);
    setAnchorEl(null);
  };

  return (
    <>
      {renderButton !== false && (
        <Tooltip title="" {...tooltip}>
          <Box
            display="flex"
            width="fit-content"
            onClick={handleClick}
            sx={{
              ...(disabled
                ? {
                    opacity: 0.5,
                  }
                : {}),
            }}
          >
            {renderButton(open)}
          </Box>
        </Tooltip>
      )}
      {!disabled && (
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          {...rest}
        >
          {renderExtraTop && renderExtraTop()}
          {options.map((action, index) => (
            <Box component="span" key={index}>
              {action.isDivider ? (
                <Divider />
              ) : action.renderCustomComponent ? (
                action.renderCustomComponent()
              ) : (
                <Tooltip title="" {...action.tooltip}>
                  <MenuItem
                    key={index}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      if (action.closeOnClick ?? true) {
                        handleClose(event, 'backdropClick');
                      }

                      action.onClick?.();
                    }}
                    disableRipple
                    disabled={action.disabled}
                    sx={action.sx}
                  >
                    {action.renderOption?.()}
                  </MenuItem>
                </Tooltip>
              )}
            </Box>
          ))}
          {renderExtraBottom && renderExtraBottom()}
        </StyledMenu>
      )}
    </>
  );
};
