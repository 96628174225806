import { gql } from '@apollo/client';
import { Box, Radio, Tooltip, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { InternalOrganizationRole } from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment UserFragmentAddUsersToOrgViewMemberUserRoleSelection on UserProfileModel {
    id
    firstName
    lastName
    email
    avatarUrl
  }
`;

type Props = {
  userRoleData: { [email: string]: InternalOrganizationRole };
  onRoleChange: (userEmail: string, role: InternalOrganizationRole) => void;
};

export const MemberUserRoleSelection = ({
  onRoleChange,
  userRoleData,
}: Props) => {
  return (
    <Box>
      <Box
        display="flex"
        gap={3}
        py={2}
        flex={1}
        sx={{ borderBottom: `1px solid ${theme.colors?.utility[500]}`, px: 3 }}
      >
        <Box display="flex" flex={3}>
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[1000]}
            fontWeight={600}
          >
            Users
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1} flex={3}>
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[1000]}
            fontWeight={600}
          >
            All Plot features
          </Typography>
          <Typography variant="body-md" color={theme.colors?.utility[700]}>
            Additional paid user
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1} flex={3}>
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[1000]}
            fontWeight={600}
          >
            Social Listening Only
          </Typography>
          <Typography variant="body-md" color={theme.colors?.utility[700]}>
            Included
          </Typography>
        </Box>
      </Box>
      {Object.keys(userRoleData).map((email) => (
        <Box
          display="flex"
          gap={3}
          py={4}
          flex={1}
          sx={{
            width: '100%',
            borderBottom: `1px solid ${theme.colors?.utility[500]}`,
            px: 3,
          }}
        >
          <Box display="flex" flex={3} gap={2}>
            <Tooltip title={email} placement="top">
              <>
                <Avatar
                  size={20}
                  user={{
                    id: '',
                    email,
                    firstName: '',
                    lastName: '',
                  }}
                />
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[1000]}
                  fontWeight={600}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    maxWidth: theme.spacing(30),
                  }}
                >
                  {email}
                </Typography>
              </>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" gap={1} flex={3}>
            <Radio
              checked={userRoleData[email] === InternalOrganizationRole.User}
              onChange={() => {
                onRoleChange(email, InternalOrganizationRole.User);
              }}
              disableRipple
              sx={{
                padding: theme.spacing(0, 0, 0, 2),
                '&.MuiRadio-root': {
                  color: theme.colors?.primary.black,
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={1} flex={3}>
            <Radio
              checked={
                userRoleData[email] ===
                InternalOrganizationRole.SocialListeningUser
              }
              onChange={() => {
                onRoleChange(
                  email,
                  InternalOrganizationRole.SocialListeningUser,
                );
              }}
              disableRipple
              sx={{
                padding: theme.spacing(0, 0, 0, 2),
                '&.MuiRadio-root': {
                  color: theme.colors?.primary.black,
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
