import { Box } from '@mui/material';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { CollaborationEditor } from 'features/collaboration';
import {
  getNoteColorFromColor,
  NoteRichTextEditor,
  NoteRichTextEditorRef,
  NoteRichTextEditorSkeleton,
} from 'features/note';
import { useFileUploadHandlers } from 'features/tiptap';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  WebViewRichTextEditorCommandType,
  WebViewRichTextEditorEventData,
  WebViewRichTextEditorEventType,
} from './types';

export const WebViewRichTextEditorPage = () => {
  const [params] = useSearchParams();

  const editorRef = useRef<NoteRichTextEditorRef | null>(null);

  const [isInitialized, setIsInitialized] = useState(false);
  const [roomId, setRoomId] = useState<string>('');
  const [defaultContent, setDefaultContent] = useState<string>('');
  const [color, setColor] = useState(`#${params.get('color') || 'FFF'}`);
  const [editable, setEditable] = useState(false);
  // const [commentable] = useState(params.get('commentable') === 'true');

  const { handleMediaUpload } = useFileUploadHandlers();

  const sendEditorStateChangeEvent = () => {
    // @ts-ignore
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: WebViewRichTextEditorEventType.EditorStateChange,
        customState: {
          canUndo: editorRef.current?.editor?.can().undo() || false,
          canRedo: editorRef.current?.editor?.can().redo() || false,
        },
      }),
    );
  };

  /**
   * Listen to message from parent frame (mobile webview)
   */
  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      try {
        if (!event.data) {
          return;
        }

        const data = JSON.parse(event.data) as WebViewRichTextEditorEventData;

        switch (data.type) {
          case WebViewRichTextEditorEventType.Initialize: {
            setRoomId(data.roomId);
            setDefaultContent(data.defaultContent);

            setIsInitialized(true);

            break;
          }
          case WebViewRichTextEditorEventType.ExecuteCommand: {
            switch (data.command) {
              case WebViewRichTextEditorCommandType.DocumentUpload: {
                if (!editorRef.current?.editor) {
                  return;
                }

                const input = document.createElement('input');
                input.type = 'file';
                input.multiple = true;
                input.onchange = (e) => {
                  const fileList = Array.from(
                    (e.target as HTMLInputElement).files || [],
                  );

                  handleMediaUpload(editorRef.current!.editor!.view, fileList);
                };

                input.click();

                break;
              }
              case WebViewRichTextEditorCommandType.TaskList: {
                editorRef.current?.editor
                  ?.chain()
                  .focus()
                  .toggleTaskList()
                  .run();

                break;
              }
              case WebViewRichTextEditorCommandType.BulletedList: {
                editorRef.current?.editor
                  ?.chain()
                  .focus()
                  .toggleBulletList()
                  .run();

                break;
              }
              case WebViewRichTextEditorCommandType.NumberedList: {
                editorRef.current?.editor
                  ?.chain()
                  .focus()
                  .toggleOrderedList()
                  .run();

                break;
              }
              case WebViewRichTextEditorCommandType.CodeBlock: {
                editorRef.current?.editor
                  ?.chain()
                  .focus()
                  .toggleCodeBlock()
                  .run();

                break;
              }
              case WebViewRichTextEditorCommandType.ChangeColor: {
                setColor(data.color);

                break;
              }
              case WebViewRichTextEditorCommandType.Disable: {
                setEditable(false);

                break;
              }
              case WebViewRichTextEditorCommandType.Enable: {
                setEditable(true);

                break;
              }
              case WebViewRichTextEditorCommandType.Undo: {
                editorRef.current?.editor?.chain().focus().undo().run();
                sendEditorStateChangeEvent();

                break;
              }
              case WebViewRichTextEditorCommandType.Redo: {
                editorRef.current?.editor?.chain().focus().redo().run();
                sendEditorStateChangeEvent();

                break;
              }
              default: {
                break;
              }
            }

            break;
          }
          default: {
            break;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []); // eslint-disable-line

  /**
   * Listen to height change of the editor when the content changes.
   * We'll have to use ResizeObserver to get the height of the editor.
   * Container: .note-view-boddy
   */
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;

        // @ts-ignore
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: WebViewRichTextEditorEventType.DimensionsChange,
            dimensions: { width, height },
          }),
        );
      }
    });

    const container = document.querySelector('.note-view-body');

    if (container) {
      observer.observe(container);
    }

    return () => {
      observer.disconnect();
    };
  }, []); // eslint-disable-line

  /**
   * On ready to receive messages,
   * send a message to the parent frame (mobile webview)
   */
  useEffect(() => {
    // @ts-ignore
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: WebViewRichTextEditorEventType.ReadyToInitialize,
      }),
    );
  }, []);

  if (!isInitialized) {
    return null;
  }

  const themeColor = getNoteColorFromColor(color);

  return (
    <ErrorBoundary>
      <Box
        className="note-view-container"
        sx={{
          width: '100%',
          minHeight: '100vh',
          p: 4,
          bgcolor: themeColor?.color,
        }}
      >
        <CollaborationEditor
          // FIXME: Using a key to force re-render because tiptap editor sometimes crash when we switch the editable state
          // Need to investigate more on this
          key={String(editable)}
          roomId={roomId}
          fallBack={<NoteRichTextEditorSkeleton />}
          renderEditor={(collaborationExtensions, provider) => {
            return (
              <NoteRichTextEditor
                ref={editorRef}
                provider={provider}
                extraExtensions={collaborationExtensions}
                className="note-view-body"
                defaultContent={defaultContent}
                themeColor={themeColor}
                placeholder="A safe place for half-baked ideas. Please '/' for commands"
                editable={editable}
                onContentChange={(content) => {
                  // @ts-ignore
                  window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      type: WebViewRichTextEditorEventType.ContentChange,
                      content,
                    }),
                  );

                  sendEditorStateChangeEvent();
                }}
                // NOTE: We don't support comment mode for now
                // because on mobile the keyboard will show up in CommentOnly mode (due to the editor not actually being disabled)
                // editable={editable || commentable}
                // mode={
                //   !editable && commentable
                //     ? NoteRichTextEditorMode.CommentOnly
                //     : NoteRichTextEditorMode.Full
                // }
              />
            );
          }}
        />
      </Box>
    </ErrorBoundary>
  );
};
