import { Box, Card, Skeleton, Typography } from '@mui/material';
import { SocialMediaListeningFilterByDateRangeButton } from 'features/socialMediaListening/components';
import { useGraphDataMakerNew } from 'features/socialMediaListening/hooks/useGraphDataMakerNew';
import { useGetBrandMentionMetricsForBrandMentionsChartQuery } from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import { SocialMediaListeningCompetitorDetailBrandMentionsChartProps } from './types';

export const BrandMentionsChart = ({
  competitorId,
  currentPlatform,
}: SocialMediaListeningCompetitorDetailBrandMentionsChartProps) => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);

  const { data: brandMentionsData, loading } =
    useGetBrandMentionMetricsForBrandMentionsChartQuery({
      variables: {
        data: {
          competitorId,
          filters: {
            dateRange,
            platform: currentPlatform,
          },
        },
      },
    });

  const allTimeHigh =
    brandMentionsData?.getCompetitorBrandMentionsMetrics?.allTimeHigh;
  const averageCountPerDay =
    brandMentionsData?.getCompetitorBrandMentionsMetrics?.averageCountPerDay ||
    0;

  const { chartData } = useGraphDataMakerNew({
    dailyChartData:
      brandMentionsData?.getCompetitorBrandMentionsMetrics?.chartDataPoints ||
      [],
    dateRange,
  });

  const totalMentions = useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.Y, 0);
  }, [chartData]);
  const isEmpty = chartData.every((p) => !p.Y);

  return (
    <Box>
      <Card
        sx={{
          borderRadius: 5,
          my: 2,
          p: 6,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={1}
          mb={3}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="headline-md" fontSize={theme.spacing(5)}>
              Total number of brand mentions
            </Typography>

            <SocialMediaListeningFilterByDateRangeButton
              onChange={(range) => {
                if (range[0] && range[1]) {
                  setDateRange(range as [Date, Date]);
                }
              }}
              selectedDateRange={dateRange}
            />
          </Box>
          <Typography
            variant="headline-md"
            fontSize={theme.spacing(5)}
            color={theme.colors?.utility[700]}
          >
            {/* definition */}
          </Typography>
          <Typography variant="headline-lg" fontSize={theme.spacing(8)} mt={2}>
            {Number(totalMentions)}
          </Typography>
        </Box>
        {!isEmpty && (
          <ResponsiveContainer width="100%" height={400}>
            {loading ? (
              <Skeleton />
            ) : (
              <AreaChart
                data={chartData || []}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid
                  vertical={false}
                  stroke={theme.colors?.utility[300]}
                />
                <XAxis dataKey="X" />
                {typeof allTimeHigh === 'number' && (
                  <YAxis
                    domain={[
                      Math.min(
                        ...((chartData || []).map((x) =>
                          Math.floor(x.Y * 0.9),
                        ) || [0]),
                      ),
                      Math.max(
                        ...((chartData || []).map((x) =>
                          Math.ceil(x.Y * 1.1),
                        ) || [0]),
                        allTimeHigh || 0,
                      ),
                    ]}
                  />
                )}
                {/** Range on y axis - For showing All time high */}
                <Tooltip
                  formatter={(value, name) => [
                    parseFloat(Number(value).toFixed(2)),
                    'Mentions',
                  ]}
                />
                <Area
                  type="monotone"
                  dataKey="Y"
                  stroke={theme.colors?.utility[600]}
                  strokeWidth={3}
                  fill="rgba(61, 43, 43, 0.5)"
                />
                {typeof allTimeHigh === 'number' && (
                  <ReferenceLine
                    y={allTimeHigh}
                    stroke="purple"
                    strokeDasharray="3 3"
                  >
                    <Label
                      value="All Time High"
                      position="insideTopLeft"
                      offset={10}
                      style={{ fill: 'purple', fontSize: 14 }}
                    />
                  </ReferenceLine>
                )}
              </AreaChart>
            )}
          </ResponsiveContainer>
        )}
        {isEmpty && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 284,
              borderRadius: 3,
              bgcolor: theme.colors?.utility[275],
            }}
          >
            <Typography
              variant="headline-sm"
              textAlign="center"
              color={theme.colors?.utility[700]}
            >
              No data available for this date range
            </Typography>
          </Box>
        )}
      </Card>
      <Typography
        variant="subhead-xl"
        color={theme.colors?.utility[600]}
        ml="auto"
        width="100%"
        textAlign="right"
        display="block"
      >
        {/* TODO:Competitor: update timestamp here */}
        {/* Last updated {moment().subtract(1, 'days').fromNow()} */}
      </Typography>
    </Box>
  );
};
