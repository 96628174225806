import ResourceLinkParser from 'components/common/Media/utils/resourceLinkParser';
import { useEffect, useState } from 'react';
import { resolveFileIcon } from 'utils/fileIcons';
import { isImage } from 'utils/resources';
import { ResourceUploadType } from './types';

type ResolveResourcePreviewProps = {
  iconSize?: number;
  type: ResourceUploadType;
  content: string | File;
  name: string;
  mimeType?: string;
  skip?: boolean;
};

export const useResolveResourcePreview = ({
  iconSize = 64,
  type,
  content,
  name,
  mimeType,
  skip = false,
}: ResolveResourcePreviewProps) => {
  const [isImagePreview, setIsImagePreview] = useState(false);
  const [previewUrl, setPreviewImage] = useState<string>();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!content || skip) {
      setIsLoading(false);

      return;
    }

    setIsLoading(true);

    if (type === ResourceUploadType.Link) {
      const linkMeta = ResourceLinkParser.getLinkMeta(
        content as string,
        iconSize,
      );

      setPreviewImage(linkMeta.thumbNail);
      setIsImagePreview(!ResourceLinkParser.isFavicon(linkMeta.thumbNail));

      setIsLoading(false);
    } else {
      const url =
        typeof content === 'string'
          ? content
          : URL.createObjectURL(content as File);

      isImage(url)
        .then(() => {
          setPreviewImage(url);
          setIsImagePreview(true);
        })
        .catch(() => {
          const previewImage = resolveFileIcon(name, mimeType);
          setPreviewImage(previewImage);
          setIsImagePreview(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [type, content, name, mimeType, iconSize, skip]);

  return { isLoading, previewUrl, isImagePreview };
};
