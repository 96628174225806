import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { CommandKeys } from 'components/common/CommandKey';
import { typography } from 'components/common/Typography/styles';
import { RichTextEditor } from 'components/common/form/RichTextEditor';
import { RichTextEditorRef } from 'components/common/form/RichTextEditor/RichTextEditor';
import { IconBoldArrowUp } from 'components/icons/components/bold/IconBoldArrowUp';
import { IconOutlinePaperclip2 } from 'components/icons/components/outline/IconOutlinePaperclip2';
import { useUserContext } from 'contexts/users/User.context';
import cuid from 'cuid';
import { getUseEditorExtensionsPropsByVariant } from 'features/tiptap';
import {
  CommentFragmentCommentAttachmentListFragmentDoc,
  CommentFragmentCommentInputFragment,
  CommentInputData,
  UpdateCommentInputData,
  UserFragmentAvatarGroupFragment,
} from 'graphql/generated';
import {
  ResourceUploadType,
  useResourceUploadQueue,
} from 'hooks/useResourceUploadQueue';
import { CSSProperties, MutableRefObject, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { theme } from 'styles/theme';
import { CommentAttachmentList } from '../attachmentList';

export const COMMENT_FRAGMENT_COMMENT_INPUT = gql`
  fragment CommentFragmentCommentInput on CommentModel {
    id
    ...CommentFragmentCommentAttachmentList
  }
  ${CommentFragmentCommentAttachmentListFragmentDoc}
`;

export interface CommentInputProps {
  commentInputRef?: MutableRefObject<RichTextEditorRef | null>;
  defaultValues?: {
    comment: {
      html: string;
      text: string;
    };
  };
  inputStyle?: CSSProperties;
  editorStyle?: CSSProperties;
  autoFocus?: boolean;
  placeholder?: string;
  onChange?: (comment: UpdateCommentInputData) => void;
  onCreateComment?: (comment: CommentInputData) => void;

  /**
   * This should only be available when this component is being used to edit a comment.
   * We need it for attachment list.
   *
   * TODO: Find a better way to handle this. Using 1 prop for 2 different purposes is not a good practice.
   */
  comment?: CommentFragmentCommentInputFragment;
}

export const CommentInput = ({
  comment,
  commentInputRef,
  defaultValues,
  inputStyle,
  editorStyle,
  autoFocus,
  placeholder,
  onChange,
  onCreateComment,
}: CommentInputProps) => {
  const { user } = useUserContext();

  const {
    allResources,
    addResourcesToUploadQueue,
    deleteResource,
    clearAllResources,
  } = useResourceUploadQueue();

  const onSubmitRef = useRef<Function>();
  const { control, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      ...(defaultValues || {
        comment: {
          html: '',
          text: '',
        },
      }),
    },
  });

  const commentValue = watch('comment');

  const canSubmit =
    Boolean(commentValue.text) &&
    allResources.every((resource) => resource.uploadStatus === 'uploaded');

  const onSubmit = handleSubmit(async (values) => {
    if (!canSubmit) {
      return;
    }

    onCreateComment?.({
      comment: values.comment.html,
      attachments: allResources
        .filter(
          (r) => Boolean(r.uploadedInfo) && r.uploadedInfo?.metaData?.uuid,
        )
        .map((r) => ({
          name: r.resource.name,
          url: r.uploadedInfo!.url,
          metaData: r.uploadedInfo!.metaData!,
        })),
    });

    reset();
    clearAllResources();
  });

  // Call onChange when attachments are done uploading
  useEffect(() => {
    if (onChange) {
      onChange({
        comment: commentValue.text || '',
        attachments: allResources
          .filter(
            (r) => Boolean(r.uploadedInfo) && r.uploadedInfo?.metaData?.uuid,
          )
          .map((r) => ({
            name: r.resource.name,
            url: r.uploadedInfo!.url,
            metaData: r.uploadedInfo!.metaData!,
          })),
      });
    }
  }, [allResources]); // eslint-disable-line react-hooks/exhaustive-deps

  // It is used when the comment is created via enter key
  // Editor is memorized, sometimes data not being updated
  onSubmitRef.current = onSubmit;

  return (
    <Box
      sx={{
        display: 'relative',
        width: '100%',
      }}
    >
      <Box
        className="comment-input-wrapper"
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: 3,
          width: '100%',
        }}
      >
        <Box className="comment-user">
          <Avatar
            size={24}
            user={user as unknown as UserFragmentAvatarGroupFragment}
          />
        </Box>
        <Box
          component="form"
          onSubmit={onSubmit}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: '100%',
            gap: 3,
          }}
          style={inputStyle}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              minHeight: 32,
              '& p': {
                m: 0,
              },
              overflow: 'hidden',
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Controller
              control={control}
              name="comment"
              render={({ field }) => {
                return (
                  <RichTextEditor
                    ref={commentInputRef}
                    placeholder={placeholder || 'Add a comment...'}
                    borderless
                    hideToolBar
                    enableBubbleMenu={false}
                    autoFocus={autoFocus}
                    style={{
                      ...typography['body-xl'],
                      ...editorStyle,
                    }}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange?.({
                        comment: value.text || '',
                        attachments: allResources
                          .filter(
                            (r) =>
                              Boolean(r.uploadedInfo) &&
                              r.uploadedInfo?.metaData?.uuid,
                          )
                          .map((r) => ({
                            name: r.resource.name,
                            url: r.uploadedInfo!.url,
                            metaData: r.uploadedInfo!.metaData!,
                          })),
                      });
                    }}
                    editorProps={{
                      handleKeyDown(view, event) {
                        // Submit if user presses enter and shift is not pressed, AND
                        // some certain extensions are not active

                        // TODO: FIND A BETTER WAY
                        const isExtensionActive = Object.keys(view.state).some(
                          (key) => {
                            if (
                              ['mention', 'emoji'].some((ext) =>
                                key.includes(ext),
                              )
                            ) {
                              if (view.state[key]?.active) {
                                return true;
                              }
                            }

                            return false;
                          },
                        );

                        if (
                          event.key === 'Enter' &&
                          !event.shiftKey &&
                          !isExtensionActive
                        ) {
                          onSubmitRef?.current?.();

                          return true;
                        }

                        return false;
                      },
                      handlePaste(view, event) {
                        if (event.clipboardData) {
                          const items = Array.from(event.clipboardData.items);

                          const files = items.filter(
                            (item) => item.kind === 'file',
                          );

                          if (files.length > 0) {
                            addResourcesToUploadQueue(
                              files.map((file) => ({
                                id: cuid(),
                                type: ResourceUploadType.Attachment,
                                content: file.getAsFile()!,
                                name: file.getAsFile()!.name,
                                size: file.getAsFile()!.size,
                              })),
                            );
                          }
                        }
                      },
                    }}
                    useEditorExtensionsProps={getUseEditorExtensionsPropsByVariant(
                      'inline',
                    )}
                  />
                );
              }}
            />
          </Box>
          <Box
            className="comment-actions"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              mb: 1,
              '.MuiIconButton-root': {
                p: 0,
              },
            }}
          >
            <UploadButton
              onUpload={(e) => {
                addResourcesToUploadQueue(
                  Array.from(e.target.files || []).map((file) => ({
                    id: cuid(),
                    type: ResourceUploadType.Attachment,
                    content: file,
                    name: file.name,
                    size: file.size,
                  })),
                );
              }}
            />
            {onCreateComment && (
              <IconButton
                type="submit"
                disabled={!canSubmit}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <IconBoldArrowUp
                  size={20}
                  color={canSubmit ? theme.colors?.primary.maroon : ''}
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      {(allResources.length > 0 || (comment?.attachments || []).length > 0) && (
        <CommentAttachmentList
          comment={comment}
          pendingAttachments={allResources}
          deletePendingAttachment={deleteResource}
        />
      )}
      {Boolean(commentValue.text) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 1,
            mt: 2,
            color: theme.colors?.utility[700],
          }}
        >
          <CommandKeys keys={['⇧', 'enter']} />
          <Typography variant="subhead-sm">to add new line</Typography>
        </Box>
      )}
    </Box>
  );
};

const UploadButton = (props: {
  onUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { onUpload } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
        size="small"
      >
        <IconOutlinePaperclip2
          size={20}
          color={theme.colors?.utility[700]}
          style={{
            cursor: 'pointer',
          }}
        />
      </IconButton>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onUpload}
        multiple
      />
    </>
  );
};
