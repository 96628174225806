import { Typography } from '@mui/material';
import { useAuthForMobileInappBrowser } from 'features/auth';
import { NavLink, useLocation } from 'react-router-dom';
import { StyledAuthNavContainer } from './styles';

const enableSignUpForMobileApp =
  process.env.REACT_APP_ENABLE_SIGNUP_FOR_MOBILE_APP === 'true';

export const AuthNav = () => {
  const { search } = useLocation();

  const { isInMobileInAppBrowser } = useAuthForMobileInappBrowser();

  return (
    <StyledAuthNavContainer display="flex" gap={6} mb={6.5}>
      <NavLink
        to={{
          pathname: '/auth',
          search,
        }}
        end
        className={({ isActive }) =>
          `${isActive ? 'active' : 'inactive'} nav-link`
        }
      >
        <Typography className="link-text" variant="body-lg">
          Login
        </Typography>
      </NavLink>
      {!isInMobileInAppBrowser || enableSignUpForMobileApp ? (
        <NavLink
          end
          to={{ pathname: '/auth/register', search }}
          className={({ isActive }) =>
            `${isActive ? 'active' : 'inactive'} nav-link`
          }
        >
          <Typography className="link-text" variant="body-lg">
            Create Account
          </Typography>
        </NavLink>
      ) : null}
    </StyledAuthNavContainer>
  );
};
