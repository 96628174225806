import { Box } from '@mui/material';
import { Iframely } from 'components/common/Iframely';
import { Image } from 'components/common/Image';
import {
  TrendingAudioPreview,
  TrendingSocialMediaPreview,
} from 'features/trending/components';
import { TrendFragmentTrendingDetailViewFragment } from 'graphql/generated';
import { createRef, useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { getValidatedMimeType } from 'utils/mime';
import {
  TrendingDetailActionButtonsView,
  TrendingDetailActionButtonsViewProps,
} from '../ActionButtons';
import { TREND_EXAMPLE_PREFIX } from '../TrendingDetailContentView';

type Props = {
  trend: TrendFragmentTrendingDetailViewFragment;
  currentTrendExampleId: string;
  trendExamples: TrendFragmentTrendingDetailViewFragment['trendExamples'];
  componentProps: {
    actionButtons: Pick<
      TrendingDetailActionButtonsViewProps,
      'hideInfo' | 'onShowInfo'
    >;
  };
};

export const TrendingDetailMoreExamplesView = ({
  trend,
  componentProps,
  currentTrendExampleId,
  trendExamples,
}: Props) => {
  const [contentHeights, setContentHeights] = useState<number[]>([]);
  const videoRefs = useRef(
    trendExamples.map(() => createRef<HTMLVideoElement>()),
  );

  useEffect(() => {
    if (videoRefs?.current.length) {
      // pause all videos
      videoRefs.current.forEach((ref) => {
        ref?.current?.pause();
      });
      const trendExampleIndex = trendExamples.findIndex(
        (e) => e.id === currentTrendExampleId,
      );
      // play video that are on screen
      videoRefs.current[
        trendExampleIndex === -1 ? 0 : trendExampleIndex
      ].current?.play();
    }
  }, [currentTrendExampleId, trendExamples]);

  return (
    <>
      {trendExamples.map((example, index) => {
        const isMediaAvailable =
          (example.urlMetadata?.metadata?.medias || []).length > 0;
        const validatedMimeType = getValidatedMimeType(
          example.urlMetadata.metadata?.type || '',
          example.urlMetadata.url,
        );

        const isImage = /^image/.test(validatedMimeType);

        const isActive = currentTrendExampleId === example.id;

        return (
          <Box
            sx={{
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              display: isActive ? 'flex' : 'none',
              '::-webkit-scrollbar': {
                width: 0,
              },
            }}
            id={`${TREND_EXAMPLE_PREFIX}${example.id}`}
          >
            {isActive && (
              <Box
                display="flex"
                flex={1}
                width="100%"
                sx={{
                  height: contentHeights[index] ?? 'auto',
                  alignItems: 'flex-end',
                  gap: 3,
                  // TODO: animation is breaking the trends save UI
                  // animation: `fadeIn${
                  //   direction === 'up' ? 'Down' : 'Up'
                  // } 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards`,
                  // '@keyframes fadeInDown': {
                  //   '0%': { opacity: 0, transform: 'translateY(-20px)' },
                  //   '100%': { opacity: 1, transform: 'translateY(0)' },
                  // },
                  // '@keyframes fadeInUp': {
                  //   '0%': { opacity: 0, transform: 'translateY(20px)' },
                  //   '100%': { opacity: 1, transform: 'translateY(0)' },
                  // },
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gap: 1,
                  }}
                >
                  {isMediaAvailable ? (
                    <TrendingSocialMediaPreview
                      trendAudio={trend?.trendAudio ?? undefined}
                      trendExample={example}
                      ref={videoRefs.current[index]}
                      onVideoClick={() => {
                        // Pause all videos except the clicked one
                        videoRefs?.current.forEach((ref) => {
                          if (ref !== videoRefs.current[index]) {
                            ref?.current?.pause();
                          }
                        });
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        maxWidth: '1000px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: 200,
                        borderRadius: 4,
                        maxHeight: {
                          xs: theme.spacing(112),
                          sm: theme.spacing(115),
                          md: theme.spacing(127),
                          lg: theme.spacing(140),
                        },
                      }}
                    >
                      {isImage ? (
                        <Image
                          sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            maxHeight: 650,
                            borderRadius: 'inherit',
                          }}
                          src={example.urlMetadata.url}
                        />
                      ) : (
                        <Iframely
                          key={example.id}
                          onContentSizeUpdated={(height) => {
                            setContentHeights((prevHeights) => {
                              const newHeights = [...prevHeights];
                              newHeights[index] = height;
                              return newHeights;
                            });
                          }}
                          componentsProps={{
                            iframelyEmbedWidget: {
                              componentsProps: {
                                noPreview: {
                                  sx: {
                                    padding: 4,
                                    width: 300,
                                    height: 200,
                                    borderRadius: 4,
                                  },
                                },
                              },
                              sx: {
                                overflow: 'unset',
                              },
                            },
                          }}
                          urlMetadata={example.urlMetadata}
                          url={example.urlMetadata.url}
                        />
                      )}
                    </Box>
                  )}
                  {trend?.trendAudio && (
                    <TrendingAudioPreview
                      variant="detail"
                      trendAudio={trend.trendAudio}
                    />
                  )}
                </Box>
                <Box
                  flex={1}
                  sx={{
                    marginBottom: trend?.trendAudio?.coverUrl ? 19 : 0,
                  }}
                >
                  <TrendingDetailActionButtonsView
                    {...componentProps.actionButtons}
                    totalCounts={{
                      contentCalendar: example.savedContentCalendarTotal,
                      trends: example.totalSavedCount,
                    }}
                    trend={trend}
                    urlMetadataId={example.id}
                  />
                </Box>
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};
