import { Box, Tooltip } from '@mui/material';
import { theme } from 'styles/theme/theme';
import { useMemo } from 'react';
import { Avatar } from './Avatar';
import { StyledMuiAvatarGroup } from './styles';
import { AvatarGroupType } from './types';

export const AvatarGroup = ({
  users,
  max = 3,
  avatarSize = 40,
  sx,
  variant,
  showLeftNumber = true,
  customAvatarTooltip,
  avatarConditionalSx,
  hideAdditionalAvatarTooltip,
  additionalAvatarSx,
}: AvatarGroupType) => {
  const processedUsers = useMemo(() => {
    // retain users order because MUI Avatar group reverses the array
    if (users.length > max) {
      return users.slice().reverse();
    }

    return users;
  }, [users, max]);

  return (
    <Box display="flex">
      <StyledMuiAvatarGroup
        max={max}
        sx={{
          paddingLeft: variant === 'non-overlap' ? 0 : 2,
          ...sx,
          '& > .MuiBox-root': {
            marginLeft: variant === 'non-overlap' ? 1 : -2,
          },
          flexDirection: 'row',
        }}
        componentsProps={{
          additionalAvatar: {
            sx: {
              display: 'none',
            },
          },
        }}
      >
        {processedUsers.map((user) => (
          <Avatar
            user={user}
            key={user.email}
            size={avatarSize}
            avatarSx={avatarConditionalSx ? avatarConditionalSx(user) : {}}
            customTooltip={customAvatarTooltip}
          />
        ))}
      </StyledMuiAvatarGroup>
      {showLeftNumber && processedUsers.length > max && (
        <Tooltip
          title={
            hideAdditionalAvatarTooltip ? (
              ''
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  padding: 1,
                  background: theme.colors?.utility[1000],
                  borderRadius: 1,
                }}
              >
                {processedUsers.slice(max - 1).map((user) => (
                  <Avatar
                    user={user}
                    key={user.email}
                    size={avatarSize}
                    avatarSx={
                      avatarConditionalSx ? avatarConditionalSx(user) : {}
                    }
                    customTooltip={customAvatarTooltip}
                  />
                ))}
              </Box>
            )
          }
          componentsProps={{
            tooltip: {
              sx: {
                background: theme.colors?.primary.white,
                p: 0,
                maxWidth: 'unset',
              },
            },
          }}
          placement="top-start"
        >
          <Box
            sx={{
              position: 'relative',
              height: avatarSize,
              width: avatarSize,
              fontSize: avatarSize * 0.5,
              background: theme.colors?.utility[300]!,
              color: theme.colors?.utility[800]!,
              fontWeight: 600,
              cursor: 'pointer',
              borderRadius: '50%',
              marginLeft: variant === 'non-overlap' ? 1 : -2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...additionalAvatarSx,
            }}
          >
            +{processedUsers.slice(max - 1).length}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
