import { MetricsTimeRange } from 'graphql/generated';

export const getDurationNameByMetricsRange = (
  metricsRange: MetricsTimeRange,
) => {
  switch (metricsRange) {
    case MetricsTimeRange.OneWeek:
      return 'past week';
    case MetricsTimeRange.OneMonth:
      return 'past month';
    case MetricsTimeRange.ThreeMonths:
      return 'past 3 months';
    case MetricsTimeRange.SixMonths:
      return 'past 6 months';
    case MetricsTimeRange.OneYear:
      return 'past year';
    default:
      return '';
  }
};

export const generateRankingByNumber = (number: number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = number % 100;
  return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};
