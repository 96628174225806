import { gql } from '@apollo/client';
import {
  TaskFragmentTaskItemExtensionFragmentDoc,
  useGetTaskForTaskItemExtensionQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line
gql`
  query GetTaskForTaskItemExtension($id: String!) {
    task(id: $id) {
      id
      ...TaskFragmentTaskItemExtension
    }
  }
  ${TaskFragmentTaskItemExtensionFragmentDoc}
`;

const POLL_INTERVAL = 2000;

export type UseTaskQueryWithPollingProps = {
  taskId: string;

  skip?: boolean;

  /**
   * The delay before first fetch attempt is made
   */
  delayBeforeFirstFetch?: number;
};

export const useTaskQueryWithPolling = (
  props: UseTaskQueryWithPollingProps,
) => {
  const { taskId, skip, delayBeforeFirstFetch } = props;

  const [internalSkip, setInternalSkip] = useState(skip);
  const [isPolling, setIsPolling] = useState(true);

  const { data, loading, stopPolling } = useGetTaskForTaskItemExtensionQuery({
    variables: {
      id: taskId,
    },
    skip: internalSkip,
    pollInterval: POLL_INTERVAL,
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });
  const task = data?.task;

  useEffect(() => {
    if (!skip) {
      setTimeout(() => {
        setInternalSkip(false);
      }, delayBeforeFirstFetch);
    }
  }, [skip]); // eslint-disable-line

  useEffect(() => {
    if (task) {
      stopPolling();
      setIsPolling(false);
    }
  }, [task]); // eslint-disable-line

  return { task, loading: loading || (!skip && isPolling) };
};
