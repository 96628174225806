const NonWorkDomains = [
  'gmail.com',
  'hotmail.com',
  'outlook.com',
  'ehl.ch',
  'protonmail.com',
  'yahoo.com',
  'yahoo.com.au',
  'yahoo.com.br',
  'yahoo.com.cn',
  'yahoo.com.hk',
  'yahoo.com.kr',
  'yahoo.com.mx',
  'yahoo.com.ph',
  'yahoo.com.sg',
  'yahoo.com.tw',
  'yahoo.co.jp',
  'yahoo.co.kr',
  'yahoo.co.uk',
  'yahoo.co.za',
  'ymail.com',
  'ymail.com.au',
  'ymail.com.br',
  'ymail.com.cn',
  'ymail.com.hk',
  'ymail.com.kr',
  'ymail.com.mx',
  'ymail.com.ph',
  'ymail.com.sg',
  'ymail.com.tw',
  'ymail.co.jp',
  'ymail.co.kr',
  'ymail.co.uk',
  'ymail.co.za',
  'icloud.com',
  'inbox.com',
  'inbox.com.au',
  'inbox.com.br',
  'inbox.com.cn',
  'inbox.com.hk',
  'inbox.com.kr',
  'inbox.com.mx',
  'inbox.com.ph',
  'inbox.com.sg',
  'inbox.com.tw',
  'inbox.co.jp',
  'inbox.co.kr',
  'inbox.co.uk',
  'inbox.co.za',
  'mail.com',
  'mail.com.au',
  'mail.com.br',
  'mail.com.cn',
  'mail.com.hk',
  'mail.com.kr',
  'mail.com.mx',
  'mail.com.ph',
  'mail.com.sg',
  'mail.com.tw',
  'mail.co.jp',
  'mail.co.kr',
  'mail.co.uk',
  'mail.co.za',
  'me.com',
  'msn.com',
  'protonmail.com',
  'proton.me',
  'zoho.com',
  'tutanota.com',
  'tuta.io',
  'gmx.com',
  'yandex.com',
  'spikenow.com',
  'hey.com',
];

export const isWorkDomain = (domain: string) => {
  if (domain.includes('@')) {
    const emailDomain = domain.split('@')[1];
    return ![...NonWorkDomains].includes(emailDomain);
  }

  return ![...NonWorkDomains].includes(domain);
};

export const getSuggestedEmails = (orgDomain: string, searchStr: string) => {
  if (!searchStr) {
    return [];
  }

  if (searchStr.includes('@')) {
    return [searchStr];
  }

  if (isWorkDomain(orgDomain)) {
    return [`${searchStr}@${orgDomain}`];
  }

  return [
    `${searchStr}@gmail.com`,
    `${searchStr}@yahoo.com`,
    `${searchStr}@outlook.com`,
  ];
};
