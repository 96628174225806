import { gql } from '@apollo/client';
import { Box, Dialog, Typography } from '@mui/material';
import {
  CollectionBreadcrumbs,
  CollectionMoveToAnotherCollectionLayout,
} from 'features/collection';
import { PostPreview } from 'features/post/components';
import { getPostIconTypeName } from 'features/post/components/icon/utils';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentUseMoveToAnotherCollectionModalFragment,
  CollectionFragmentUseMoveToAnotherCollectionModalFragmentDoc,
  PostFragmentPostMoveToAnotherCollectionFragment,
  PostFragmentPostPreviewFragmentDoc,
  useGetCollectionForPostMoveToAnotherCollectionQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment PostFragmentPostMoveToAnotherCollection on PostModel {
    description
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentUseMoveToAnotherCollectionModal on CollectionModel {
    id
    name
    ...CollectionFragmentCollectionBreadcrumbs
  }
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;

type PostMoveToAnotherCollectionProps = {
  post: PostFragmentPostMoveToAnotherCollectionFragment;
  currentCollectionId: string;
  isOpen: boolean;
  onClose: VoidFunction;
  onMove: (
    postIds: string[],
    toCollection: CollectionFragmentUseMoveToAnotherCollectionModalFragment,
  ) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetCollectionForPostMoveToAnotherCollection($collectionId: String!) {
    collection(id: $collectionId) {
      id
      ...CollectionFragmentUseMoveToAnotherCollectionModal
    }
  }
  ${CollectionFragmentUseMoveToAnotherCollectionModalFragmentDoc}
`;

export const PostMoveToAnotherCollection = (
  props: PostMoveToAnotherCollectionProps,
) => {
  const { isOpen, onClose, onMove, post, currentCollectionId } = props;

  const { data } = useGetCollectionForPostMoveToAnotherCollectionQuery({
    variables: {
      collectionId: currentCollectionId,
    },
    skip: !currentCollectionId,
  });
  const currentCollection = data?.collection;

  const handleClose = () => {
    onClose();
  };

  const { icon, type } = getPostIconTypeName({
    url: post.urlMetadata?.[0]?.url || undefined,
    site: post.urlMetadata?.[0]?.metadata?.site || undefined,
    medium: post.urlMetadata?.[0]?.metadata?.medium || undefined,
    mimeType: post.urlMetadata?.[0]?.metadata?.type || undefined,
  });

  if (!currentCollection) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: theme.spacing(6),
          border: 'none',
        },
      }}
    >
      <CollectionMoveToAnotherCollectionLayout
        onSelectDestinationCollection={(collection) => {
          onMove([post.id], collection);
        }}
        type="post"
      >
        <Typography variant="headline-md" fontSize={theme.spacing(7)}>
          Move:
        </Typography>
        <Box display="flex" gap={3}>
          <PostPreview
            sx={{
              width: 52,
              height: 72,
              borderRadius: theme.spacing(2),
            }}
            post={post}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              gap={1}
              sx={{
                backgroundColor: 'rgba(35, 6, 3, 0.07)',
                borderRadius: 100,
                p: theme.spacing(1, 2),
              }}
            >
              {icon({ size: 16, color: theme.colors?.utility[800] })}
              <Typography
                color={theme.colors?.utility[800]}
                variant="body-sm"
                fontWeight={600}
              >
                {post.urlMetadata?.[0]?.metadata?.site ||
                  post.title ||
                  type ||
                  post.urlMetadata?.[0]?.url}
              </Typography>
            </Box>
            {post.description && (
              <Typography
                color={theme.colors?.utility[800]}
                variant="body-sm"
                fontWeight={600}
              >
                {post.description}
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            alignItems="center"
            mt={1}
            mb={2}
          >
            <Box
              sx={{ display: 'flex', gap: 2, minWidth: theme.spacing(26.5) }}
            >
              <Typography
                variant="body-lg"
                fontWeight={600}
                color={theme.colors?.utility[800]}
              >
                Current Location
              </Typography>
            </Box>

            <Box display="flex" gap={2} flex={1}>
              <CollectionBreadcrumbs
                collection={currentCollection}
                ellipseAtLength={2}
                onCollectionClick={() => {}}
                sx={{
                  ...theme.typography['body-md'],
                  color: theme.colors?.utility[800],
                  backgroundColor: 'rgba(35, 6, 3, 0.10)',
                  p: theme.spacing(1, 4),
                  borderRadius: 4,
                  width: 'fit-content',
                }}
              />
            </Box>
          </Box>
        </Box>
      </CollectionMoveToAnotherCollectionLayout>
    </Dialog>
  );
};
