import { gql, useApolloClient } from '@apollo/client';
import {
  Box,
  Chip,
  MenuItem,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { useContentIdeaFieldOptionHandlers } from 'features/contentIdea/hooks';
import { getCustomStatusStyles } from 'features/contentIdea/utils';
import {
  ContentIdeaFieldOptionFragmentSelectFieldFragment,
  useGetContentIdeaFieldForSelectFieldQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { ContentIdeaBadgeMenuItem } from '../badgeMenuItem';
import { optionBgColors } from './MultiSelectField';

const filter =
  createFilterOptions<ContentIdeaFieldOptionFragmentSelectFieldFragment>();

const CONTENT_IDEA_FIELD_OPTION_FRAGMENT_SELECT_FIELD = gql`
  fragment ContentIdeaFieldOptionFragmentSelectField on ContentIdeaFieldOptionModel {
    id
    value
    label
    bgcolor
  }
`;

const CONTENT_IDEA_FIELD_MODEL_FRAGMENT_SELECT_FIELD = gql`
  fragment ContentIdeaFieldModelFragmentSelectField on ContentIdeaFieldModel {
    id
    name
    allowNewOptions
    options {
      ...ContentIdeaFieldOptionFragmentSelectField
    }
  }
  ${CONTENT_IDEA_FIELD_OPTION_FRAGMENT_SELECT_FIELD}
`;

// eslint-disable-next-line
gql`
  query GetContentIdeaFieldForSelectField($id: String!) {
    contentIdeaField(id: $id) {
      ...ContentIdeaFieldModelFragmentSelectField
    }
  }
  ${CONTENT_IDEA_FIELD_MODEL_FRAGMENT_SELECT_FIELD}
`;

export type SelectFieldProps = {
  id: string;
  value: string;
  onChange: (
    value: string,
    optimisticResponseOptions?: {
      select?: ContentIdeaFieldOptionFragmentSelectFieldFragment;
    },
  ) => void;
  renderTrigger?: (props: {
    isOpen: boolean;
    options: ContentIdeaFieldOptionFragmentSelectFieldFragment[];
    onClear: () => void;
  }) => JSX.Element;
  readOnly?: boolean;
  showMoreOption?: boolean;
};

export const SelectField = (props: SelectFieldProps) => {
  const { value, id, onChange, renderTrigger, readOnly, showMoreOption } =
    props;

  const client = useApolloClient();
  const { onCreateOption, onDeleteOption, onUpdateOption } =
    useContentIdeaFieldOptionHandlers({
      onAfterOptionCreated: (option) => {
        const newOptionRef = client.cache.writeFragment({
          data: option,
          fragment: CONTENT_IDEA_FIELD_MODEL_FRAGMENT_SELECT_FIELD,
          fragmentName: 'ContentIdeaFieldModelFragmentSelectField',
        });
        modifyObject(client.cache, id, 'ContentIdeaFieldModel', {
          options: (cachedOptions = []) => [...cachedOptions, newOptionRef],
        });
      },
    });

  const [newOption, setNewOption] =
    useState<ContentIdeaFieldOptionFragmentSelectFieldFragment | null>(null);
  const [inputValue, setInputValue] = useState('');

  const { data } = useGetContentIdeaFieldForSelectFieldQuery({
    variables: {
      id,
    },
  });
  const options = data?.contentIdeaField?.options || [];

  const onCreateNewOption = async () => {
    if (!newOption) {
      return;
    }
    const contentIdeaFieldId = data?.contentIdeaField.id || '';

    await onCreateOption({
      contentIdeaFieldId,
      data: {
        value: newOption.value,
        label: newOption.label,
        bgcolor: newOption.bgcolor,
      },
    });
    onChange(newOption.value, { select: newOption });
    setNewOption(null);
  };

  const onClear = () => {
    onChange('');
  };

  const allowNewOptions = useMemo(
    () => data?.contentIdeaField.allowNewOptions ?? false,
    [data?.contentIdeaField?.allowNewOptions],
  );

  return (
    <AutocompletePopup
      defaultValue={options.find((o) => o.value === value)}
      options={options}
      freeSolo={allowNewOptions}
      readOnly={readOnly}
      renderTrigger={
        renderTrigger
          ? (open) => renderTrigger({ isOpen: open || false, options, onClear })
          : (open) => {
              const option = options.find((o) => o.value === value);

              if (!option) {
                return (
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.utility[600]}
                  >
                    Empty
                  </Typography>
                );
              }

              // FIXME: THIS IS A HACK FOR STATUS COLORS
              const customStyles = getCustomStatusStyles(
                option.value,
                option.bgcolor || '',
              );

              return (
                <Chip
                  sx={{
                    borderRadius: 1,
                    ...(customStyles.color
                      ? {
                          '.MuiChip-label': {
                            filter: 'none !important',
                            color: customStyles.color,
                          },
                        }
                      : {}),
                    ...customStyles,
                  }}
                  label={
                    <Typography variant="headline-xs">
                      {option.label}
                    </Typography>
                  }
                  variant="filled-borderless-color-dodge"
                  onDelete={readOnly ? undefined : onClear}
                />
              );
            }
      }
      triggerWrapperSx={{
        width: '100%',
      }}
      renderOption={(props, option, state) => {
        const selected = state.selected;

        const fieldOption = options.find((o) => o.value === option.value);

        if (!fieldOption) {
          return (
            <MenuItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '& .MuiButtonBase-root': {
                  padding: 0,
                },
              }}
              {...props}
            >
              <Typography variant="headline-xs">Create</Typography>
              <Chip
                sx={{
                  // @ts-ignore
                  bgcolor: props.bgcolor || theme.colors?.utility['400'],
                  borderRadius: 1,
                }}
                label={
                  <Typography variant="headline-xs">{option.label}</Typography>
                }
                variant="filled-borderless-color-dodge"
              />
            </MenuItem>
          );
        }

        return (
          <ContentIdeaBadgeMenuItem
            key={option.value}
            selected={selected}
            onDeleteOption={() => {
              onDeleteOption(fieldOption.id);
            }}
            onUpdateOption={(newLabel) => {
              onUpdateOption(
                {
                  contentIdeaFieldOptionId: fieldOption.id,
                  data: {
                    value: newLabel,
                  },
                },
                options,
              );
            }}
            showMoreOption={showMoreOption ?? true}
            {...props}
            {...option}
          />
        );
      }}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          setInputValue(value);
        } else {
          setInputValue('');
        }
      }}
      placeholder="Search"
      onChange={async (_, value) => {
        if (newOption) {
          await onCreateNewOption();
        } else {
          const existingOption = options.find((o) => {
            return Array.isArray(value)
              ? o.value === value[0].value
              : o.value === value?.value;
          });
          if (existingOption) {
            onChange(existingOption.value, { select: existingOption });
          }
        }
        setInputValue('');
      }}
      {...(allowNewOptions
        ? {
            filterOptions: (_options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              const isExisting = _options.some(
                (option) =>
                  inputValue.trim().toLowerCase() ===
                  option.label.toLowerCase(),
              );

              if (inputValue.trim() !== '' && !isExisting) {
                const option = {
                  id: inputValue,
                  value: inputValue,
                  label: inputValue,
                  bgcolor:
                    newOption?.bgcolor ??
                    optionBgColors[
                      Math.floor(Math.random() * optionBgColors.length)
                    ],
                };
                if (option.value !== newOption?.value) {
                  setNewOption(option);
                }
                filtered.push(option);
              } else {
                setNewOption(null);
              }

              return filtered;
            },
          }
        : {})}
      // eslint-disable-next-line
      PaperComponent={({ children, ...rest }) => {
        return (
          <Box
            {...rest}
            sx={{
              '.MuiAutocomplete-listbox': {
                pt: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
            }}
          >
            {children}
            {allowNewOptions && (
              <Box
                sx={{
                  px: 3,
                  py: 2,
                }}
              >
                <Typography
                  variant="subhead-lg"
                  color={theme.colors?.utility[600]}
                >
                  Select an Option{allowNewOptions ? ' or Create One' : ''}
                </Typography>
              </Box>
            )}
          </Box>
        );
      }}
    />
  );
};
