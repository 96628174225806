import { Box, IconButton, Typography } from '@mui/material';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconBoldVolumeHigh } from 'components/icons/components/bold/IconBoldVolumeHigh';
import { IconBoldVolumeSlash } from 'components/icons/components/bold/IconBoldVolumeSlash';
import { IconBoldWarning2 } from 'components/icons/components/bold/IconBoldWarning2';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { Platform } from 'graphql/generated';
import { useRef, useState } from 'react';
import { theme } from 'styles/theme';

const platformIconMap = {
  [Platform.Instagram]: IconBoldInstagram,
  [Platform.Tiktok]: IconCustomTiktok,
};

type Props = {
  platform: Platform;
  thumbnailUrl?: string;
  videoUrl: string;
  onPlay?: () => void;
  renderHoverActionsTopRight?: () => React.ReactNode;
  renderHoverActionsBottom?: () => React.ReactNode;
};

export const HoverPlayableVideo = ({
  platform,
  thumbnailUrl,
  videoUrl,
  onPlay,
  renderHoverActionsTopRight,
  renderHoverActionsBottom,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play();
      onPlay && onPlay();
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.load();
    }
    setIsHovered(false);
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  if (hasError || !videoUrl) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        minHeight={100}
        sx={{ backgroundColor: theme.colors?.utility[400], borderRadius: 4 }}
      >
        <Typography
          variant="body-md"
          color={theme.colors?.utility[700]}
          fontWeight={600}
          display="flex"
          alignItems="center"
          gap={1}
        >
          <IconBoldWarning2 size={16} />
          Error loading video
        </Typography>
      </Box>
    );
  }

  const Icon = platformIconMap[platform];

  return (
    <Box
      position="relative"
      width="100%"
      height="0"
      sx={{ paddingTop: '177.77%', overflow: 'hidden' }} // 56.25% for 16:9 aspect ratio
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        sx={{ borderRadius: 4, cursor: 'pointer', objectFit: 'cover' }}
        component="video"
        ref={videoRef}
        src={videoUrl}
        poster={thumbnailUrl}
        muted={isMuted}
        onError={handleError}
      />
      {isHovered && (
        <Box
          position="absolute"
          top={theme.spacing(2)}
          left={theme.spacing(2)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <IconButton
            onClick={toggleMute}
            disableRipple
            sx={{
              p: 1,
              color: theme.colors?.primary.white,
            }}
          >
            {isMuted ? (
              <IconBoldVolumeSlash size={16} />
            ) : (
              <IconBoldVolumeHigh size={16} />
            )}
          </IconButton>
        </Box>
      )}
      <Box
        position="absolute"
        top={theme.spacing(2)}
        right={theme.spacing(2)}
        display="flex"
        gap={2}
        alignItems="center"
        minHeight={24}
      >
        {isHovered &&
          renderHoverActionsTopRight &&
          renderHoverActionsTopRight()}
        {Icon && (
          <Icon
            size={16}
            style={{
              color: theme.colors?.primary.white,
            }}
          />
        )}
      </Box>
      {renderHoverActionsBottom && (
        <Box
          position="absolute"
          bottom={theme.spacing(2)}
          right={theme.spacing(2)}
          left={theme.spacing(2)}
        >
          {renderHoverActionsBottom()}
        </Box>
      )}
    </Box>
  );
};
