import { gql } from '@apollo/client';
import { useClipboard } from '@dwarvesf/react-hooks';
import { Box, Grid, OutlinedInput, Typography, Button } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup/Avatar';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomController } from 'components/common/form/CustomController';
import { useUserContext } from 'contexts/users/User.context';
import { theme } from 'styles/theme/theme';
import {
  RegistrationMethod,
  UserFragmentAvatarGroupFragment,
  useUpdatePasswordForUserProfileMutation,
} from 'graphql/generated';
import { SUPPORT_EMAIL } from 'constants/support';
import { toast } from 'components/common/Toast';
import { ConfirmationDialog } from 'hooks/useConfirmationDialog/components/ConfirmationDialog';
import { useCallbackPrompt } from 'hooks/navigation/useCallbackPrompt';

// eslint-disable-next-line
gql`
  mutation UpdatePasswordForUserProfile($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      id
      firstName
      lastName
      registrationMethod
    }
  }
`;

export const PasswordTab = () => {
  const [updatePassword] = useUpdatePasswordForUserProfileMutation();

  const { user } = useUserContext();
  const { onCopy } = useClipboard(SUPPORT_EMAIL);

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const { oldPassword, newPassword, confirmPassword } = watch();

  const isCanSubmit = React.useMemo(() => {
    return (
      oldPassword.length > 0 ||
      newPassword.length > 0 ||
      confirmPassword.length > 0
    );
  }, [confirmPassword.length, newPassword.length, oldPassword.length]);

  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(isCanSubmit);

  const onSubmit = handleSubmit(async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      toast({
        message: 'New password and confirm password must match',
        type: 'error',
        captureMessage: true,
      });
      return;
    }

    await updatePassword({
      variables: {
        data: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
      },
    });

    toast({
      message: 'Password updated successfully!',
      type: 'success',
    });
  });

  const isGoogleUser = user?.registrationMethod.includes(
    RegistrationMethod.Google,
  );
  const [isPasswordDisabled, setPasswordDisabled] = useState(isGoogleUser);

  const fields = useMemo(() => {
    if (isGoogleUser) {
      return [
        {
          label: 'Password',
          name: 'oldPassword',
          render: ({ field, fieldState }) => {
            return (
              <>
                <OutlinedInput
                  {...field}
                  {...fieldState}
                  error={Boolean(fieldState.error)}
                  disabled
                  type="password"
                  value="********"
                />
                <Box
                  component="small"
                  sx={{ color: theme.colors?.utility[800] }}
                >
                  This account was linked with Google. To change email please
                  contact{' '}
                  <Box
                    component="button"
                    type="button"
                    onClick={() => {
                      onCopy();
                      toast({
                        type: 'success',
                        message: 'Support email has been copied!',
                      });
                    }}
                    sx={{
                      fontWeight: 600,
                      color: theme.colors?.primary.maroon,
                    }}
                  >
                    Customer Support.
                  </Box>
                </Box>
              </>
            );
          },
        },
      ];
    }

    return [
      {
        label: 'Old Password',
        name: 'oldPassword',
        rules: {
          minLength: {
            value: 8,
            message: 'Password must be at least 8 characters',
          },
        },
        render: ({ field, fieldState }) => {
          return (
            <OutlinedInput
              {...field}
              {...fieldState}
              error={Boolean(fieldState.error)}
              type="password"
            />
          );
        },
      },
      {
        label: 'New Password',
        name: 'newPassword',
        rules: {
          minLength: {
            value: 8,
            message: 'Password must be at least 8 characters',
          },
        },
        render: ({ field, fieldState }) => {
          return (
            <OutlinedInput
              {...field}
              {...fieldState}
              error={Boolean(fieldState.error)}
              type="password"
            />
          );
        },
      },
      {
        label: 'Confirm Password',
        name: 'confirmPassword',
        rules: {
          minLength: {
            value: 8,
            message: 'Password must be at least 8 characters',
          },
        },
        render: ({ field, fieldState }) => {
          return (
            <OutlinedInput
              {...field}
              {...fieldState}
              error={Boolean(fieldState.error)}
              type="password"
            />
          );
        },
      },
    ];
  }, [onCopy, isGoogleUser, isPasswordDisabled]);

  if (!user) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={10}>
      <Box display="flex" gap={4}>
        <Avatar
          user={user as UserFragmentAvatarGroupFragment}
          size={14}
          avatarSx={{ width: 120, height: 120 }}
        />
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="headline-sm">Avatar</Typography>
        </Box>
      </Box>
      <Grid
        container
        rowSpacing={10}
        columnSpacing={5}
        component="form"
        onSubmit={onSubmit}
      >
        {fields.map((field) => {
          return (
            <Grid item xs={6} key={field.name}>
              <CustomController {...field} control={control} />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                color: 'white',
                bgcolor: theme.colors?.primary.maroon,
              }}
              disabled={isGoogleUser || !isCanSubmit}
            >
              Save Changes
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ConfirmationDialog
        title="Leave page?"
        subtitle="You have unsaved changes, are you sure you want to leave?"
        confirmText="Continue to change password"
        cancelText="Leave page"
        open={showPrompt}
        onConfirm={() => {
          cancelNavigation();
        }}
        onCancel={() => {
          confirmNavigation();
        }}
      />
    </Box>
  );
};
