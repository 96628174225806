import { Box, IconButton, Typography } from '@mui/material';
import { CommandKey } from 'components/common/CommandKey';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconCustomFiSrBulb } from 'components/icons/components/custom/IconCustomFiSrBulb';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { getMetaKey } from 'utils/keyboard';

export const LOCAL_STORAGE_HIDE_POST_SMART_SEARCH_HELP_TEXT_BANNER =
  'plotHidePostSmartSearchHelpTextBanner';

export const PostSmartSearchHelpTextBanner = () => {
  const [hideBanner, setHideBanner] = useState(
    localStorage.getItem(
      LOCAL_STORAGE_HIDE_POST_SMART_SEARCH_HELP_TEXT_BANNER,
    ) === '1',
  );

  if (hideBanner) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: 3,
        bgcolor: theme.colors?.utility['yellow-1'],
        p: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        mb: 6,
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }}
    >
      <Box
        sx={{
          width: 36,
          height: 36,
          borderRadius: 18,
          bgcolor: theme.colors?.utility['yellow-2-new'],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconCustomFiSrBulb
          size={24}
          color={theme.colors?.utility['yellow-4-new']}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 3,
          color: theme.colors?.utility['yellow-4-new'],
        }}
      >
        <Typography variant="headline-md">
          Add these to a collection:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 3,
            flex: 1,
          }}
        >
          <Typography variant="headline-md">
            1. Press{' '}
            <Box component="span" sx={{ display: 'inline-flex', gap: 1 }}>
              {[getMetaKey(), 'A'].map((key) => (
                <CommandKey
                  sx={{
                    width: 20,
                    height: 20,
                    bgcolor: '#2306030D',
                    border: '1px solid #23060326',
                    color: theme.colors?.utility['yellow-4-new'],
                    '> span': {
                      fontSize: '12px',
                    },
                  }}
                >
                  {key}
                </CommandKey>
              ))}
            </Box>{' '}
            to select all
          </Typography>
          <Typography variant="headline-md">
            2. Click on the save button at the bottom
          </Typography>
        </Box>
      </Box>
      <IconButton
        size="small"
        onClick={() => {
          setHideBanner(true);
          localStorage.setItem(
            LOCAL_STORAGE_HIDE_POST_SMART_SEARCH_HELP_TEXT_BANNER,
            '1',
          );
        }}
      >
        <IconBoldCloseCircle style={{ color: '#23060333' }} />
      </IconButton>
    </Box>
  );
};
