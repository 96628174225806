import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { useMemo, useState } from 'react';
import { InternalOrganizationRole } from 'graphql/generated';
import { AccessRequestAdminList } from 'features/accessRequest';
import { theme } from 'styles/theme';
import { IconLogoPlotLogo } from 'components/icons/components/logo/IconLogoPlotLogo';
import { IconOutlineLogout1 } from 'components/icons/components/outline/IconOutlineLogout1';
import { useBillingMutations } from '../../hooks';

type BillingNewPricingNotifyAdminProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const BillingNewPricingNotifyAdmin = (
  props: BillingNewPricingNotifyAdminProps,
) => {
  const { isOpen, onClose } = props;

  const { user, logout } = useUserContext();

  const admins = useMemo(() => {
    return (
      user?.organization.users.filter(
        (u) => u.role === InternalOrganizationRole.Admin,
      ) || []
    );
  }, [user?.organization.users]);

  const [sending, setSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const { notifyAdminForBilling } = useBillingMutations();
  const handleSendRequest = async () => {
    setSending(true);

    const res = await notifyAdminForBilling();

    if (res.data?.notifyAdminForBilling.success) {
      setIsSent(true);
    }

    setSending(false);
  };

  return (
    <Dialog fullScreen open={isOpen}>
      <Box
        sx={{
          bgcolor: theme.colors?.primary.parchment,
          height: 'calc(100vh - 100%)',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.05)',
          py: 8,
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: 320, sm: 470, md: 470 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 8,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
              justifyContent: 'center',
            }}
          >
            <IconLogoPlotLogo color={theme.colors?.primary.maroon} />
            <Typography
              variant="headline-xl"
              fontSize={36}
              color={theme.colors?.primary.maroon}
            >
              Plot
            </Typography>
          </Box>

          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                ...theme.typography['headline-xl'],
                fontSize: 74,
                position: 'absolute',
                top: 0,
                transform: 'translate(-80%, 0)',
              }}
            >
              🍋
            </Box>
            <Box
              sx={{
                ...theme.typography['headline-xl'],
                fontSize: 65,
                position: 'absolute',
                bottom: 0,
                transform: 'translate(-65%, 0)',
                rotate: '-28deg',
              }}
            >
              🍉
            </Box>
            <Box
              sx={{
                ...theme.typography['headline-xl'],
                fontSize: 74,
                position: 'absolute',
                right: '-6%',
                top: '-15%',
              }}
            >
              🍊
            </Box>
            <Box
              sx={{
                ...theme.typography['headline-xl'],
                fontSize: 67,
                position: 'absolute',
                right: '-8%',
                bottom: '25%',
              }}
            >
              🥝
            </Box>
            <Box
              sx={{
                borderRadius: 6,
                bgcolor: `rgba(35, 6, 3, 0.10)`,
                backdropFilter: 'blur(20px)',
                padding: 2,
              }}
            >
              <Box
                sx={{
                  borderRadius: 'inherit',
                  padding: 8,
                  width: '100%',
                  height: '100%',
                  bgcolor: theme.colors?.primary.white,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(4),
                }}
              >
                <Typography
                  variant="headline-xl"
                  fontWeight={500}
                  fontSize={40}
                >
                  Notify your admin to take action!
                </Typography>
                <Typography variant="subhead-xl">
                  Send a request to your admin(s) to join{' '}
                  <b>{user?.organization.name}</b> Workspace:
                </Typography>

                <AccessRequestAdminList admins={admins} />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      ...theme.typography['headline-sm'],
                      color: theme.colors?.primary.black,
                    }}
                    onClick={onClose}
                    disabled={isSent || sending}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="text"
                    disabled={isSent || sending}
                    sx={{
                      ...theme.typography['headline-sm'],
                      bgcolor: theme.colors?.primary.black,
                      color: theme.colors?.primary.parchment,
                      borderRadius: 2,
                      padding: theme.spacing(2, 4),
                      ':hover': {
                        bgcolor: theme.colors?.primary.black,
                      },
                      '&.Mui-disabled': {
                        bgcolor: theme.colors?.utility[500],
                        color: theme.colors?.primary.parchment,
                      },
                    }}
                    onClick={handleSendRequest}
                  >
                    {sending ? (
                      <CircularProgress size={16} />
                    ) : isSent ? (
                      'Request Sent'
                    ) : (
                      'Send Request'
                    )}
                  </Button>
                </Box>

                <Box
                  sx={{
                    borderTop: `2px solid ${theme.colors?.utility[400]}`,
                    textAlign: 'center',
                  }}
                >
                  <Button
                    startIcon={<IconOutlineLogout1 />}
                    onClick={logout}
                    sx={{ color: theme.colors?.utility['pink-3'] }}
                  >
                    Log out
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
