import { gql } from '@apollo/client';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentCollectionMultiPostPreviewFragmentDoc,
  CollectionFragmentCollectionPermissionSummaryFragmentDoc,
  CollectionFragmentCollectionThumbnailFragmentDoc,
  useGetMoveToAnotherCollectionCollectionsListQuery,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const COLLECTION_FRAGMENT_MOVE_TO_ANOTHER_COLLECTION_COLLECTIONS_LIST = gql`
  fragment CollectionFragmentMoveToAnotherCollectionCollectionsList on CollectionModel {
    id
    name
    ...CollectionFragmentCollectionThumbnail
    ...CollectionFragmentCollectionBreadcrumbs
    ...CollectionFragmentCollectionPermissionSummary
    ...CollectionFragmentCollectionMultiPostPreview
  }
  ${CollectionFragmentCollectionThumbnailFragmentDoc}
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
  ${CollectionFragmentCollectionPermissionSummaryFragmentDoc}
  ${CollectionFragmentCollectionMultiPostPreviewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetMoveToAnotherCollectionCollectionsList(
    $filters: CollectionFilters!
    $after: String
    $take: Int
  ) {
    collectionsSmartSearch(filters: $filters, after: $after, take: $take) {
      meta {
        totalCount
      }
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
      data {
        type
        item {
          ...CollectionFragmentMoveToAnotherCollectionCollectionsList
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT_MOVE_TO_ANOTHER_COLLECTION_COLLECTIONS_LIST}
`;

export const useMoveToAnotherCollectionCollectionsList = ({
  collectionIdToMove,
  collectionId,
  query,
}: {
  collectionIdToMove?: string;
  collectionId: string;
  query?: string;
}) => {
  const {
    data: paginatedCollectionData,
    loading: isLoadingCollections,
    fetchMore: fetchMoreCollections,
  } = useGetMoveToAnotherCollectionCollectionsListQuery({
    variables: {
      filters: {
        ...(!query && collectionId && collectionId.trim()
          ? { parentCollectionIds: [collectionId] }
          : {}),
        ...(query ? { query } : {}),
        searchAllCollections: true,
      },
      take: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  const collections = paginatedCollectionData?.collectionsSmartSearch.data
    .map((item) => item.item)
    .filter((c) => c.id !== collectionIdToMove);

  const fetchMoreCollectionsList = () => {
    fetchMoreCollections({
      variables: {
        filters: {
          ...(!query && collectionId && collectionId.trim()
            ? { parentCollectionIds: [collectionId] }
            : {}),
          ...(query ? { query } : {}),
        },
        take: 10,
        after:
          paginatedCollectionData?.collectionsSmartSearch.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          collectionsSmartSearch: {
            ...fetchMoreResult.collectionsSmartSearch,
            data: [
              ...prev.collectionsSmartSearch.data,
              ...fetchMoreResult.collectionsSmartSearch.data,
            ],
          },
        };
      },
    });
  };

  return {
    collections,
    isLoadingCollections,
    fetchMoreCollectionsList,
    hasMoreCollections:
      paginatedCollectionData?.collectionsSmartSearch.pageInfo.hasNextPage,
  };
};
