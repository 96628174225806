import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconLinearEdit } from 'components/icons/components/linear/IconLinearEdit';
import { useCreatorsTopicTrackingForSocialListening } from 'features/socialMediaListening/hooks/useCreatorsTopicTrackingForSocialListening';
import {
  CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment,
  Platform,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { CreatorsListing } from '../creatorsListing';
import { CreatorsSearch } from '../creatorsSearch/CreatorsSearch';

type Props = {
  topicId: string;
  instagramHandle?: string;
};

type TabType = 'creatorList' | 'followingCreators';

export const InstagramCreatorsDialogButton = ({
  topicId,
  instagramHandle,
}: Props) => {
  const instagramCreatorTrackingDisclosure = useDisclosure();
  const [tab, setTab] = useState('creatorList' as TabType);

  const [searchedStr, setSearchedStr] = useState('');
  const [currentSearchStr, setCurrentSearchStr] = useState('');

  const {
    data: {
      trackedCreators: {
        data: trackedCreators,
        loading: loadingTrackedCreators,
      },
      followingCreators: {
        data: followingCreators,
        loading: loadingFollowingCreators,
      },
      searchedCreators: { data: searchedCreators, loading: searchingCreators },
      inProgress: { updatingCreatorPlatformIds },
    },

    actions: { handleTrackCreator, handleUntrackCreator },
  } = useCreatorsTopicTrackingForSocialListening({
    topicId,
    platform: Platform.Instagram,
    handle: instagramHandle,
    searchCreator: searchedStr,
  });

  const noUsersState = useMemo(() => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        color={theme.colors?.utility[600]}
        py={16}
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
          <Typography variant="body-xl" fontWeight={600}>
            No Creators on your list yet.
          </Typography>

          <Typography variant="body-md" fontWeight={600}>
            Type in the search bar to start adding!
          </Typography>
        </Box>
      </Box>
    );
  }, []);

  const searchingState = useMemo(() => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Typography
          variant="body-xl"
          fontWeight={600}
          py={10}
          display="flex"
          alignItems="center"
          gap={2}
        >
          <CircularProgress
            sx={{ color: theme.colors?.primary.black }}
            size={20}
          />
          Searching...
        </Typography>
      </Box>
    );
  }, []);

  const creatorsList = searchedStr
    ? (searchedCreators as unknown as CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[])
    : trackedCreators;
  const followingCreatorsList =
    followingCreators as unknown as CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[];

  return (
    <>
      {!trackedCreators.length ? (
        <Button
          onClick={() => {
            instagramCreatorTrackingDisclosure.onOpen();
          }}
          size="small"
          variant="primary"
          sx={{
            borderRadius: theme.spacing(2),
            border: 'none',
            backgroundColor: theme.colors?.utility[300],
            color: theme.colors?.utility[700],
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              border: 'none',
              backgroundColor: theme.colors?.utility[300],
              color: theme.colors?.utility[700],
            },
          }}
          startIcon={<IconBoldAddCircle size={12} />}
        >
          Add Creators
        </Button>
      ) : (
        <Box>
          <Typography
            onClick={() => {
              instagramCreatorTrackingDisclosure.onOpen();
            }}
            variant="body-md"
            color={theme.colors?.utility['blue-3-new']}
            display="flex"
            gap={1}
            alignItems="center"
            fontWeight={600}
            py={2}
            sx={{
              cursor: 'pointer',
            }}
          >
            {trackedCreators.length}{' '}
            {trackedCreators.length === 1 ? 'Creator' : 'Creators'}
            <IconLinearEdit size={14} />
          </Typography>
        </Box>
      )}
      <Dialog
        open={instagramCreatorTrackingDisclosure.isOpen}
        onClose={instagramCreatorTrackingDisclosure.onClose}
        PaperProps={{
          sx: {
            width: theme.spacing(150),
            borderRadius: 6,
            border: 'none',
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box
          p={theme.spacing(6)}
          sx={{
            background: 'rgba(250, 243, 236, 0.80)',
            backdropFilter: 'blur(20px)',
            height: theme.spacing(160),
            overflowY: 'auto',
          }}
        >
          <Typography
            variant="headline-lg"
            display="flex"
            gap={2}
            alignItems="center"
          >
            <IconCustomInstagram />
            Instagram
          </Typography>

          <Box
            sx={{
              mt: 2,
              width: '100%',

              '& .video-dialog-tabs': {
                width: '100%',
                height: `calc(100% - ${theme.spacing(20)})`,
              },
              '& .MuiTabPanel-root': {
                height: '100%',
                overflowY: 'auto',
              },
              '& button.MuiTab-root': {
                pb: 0,
              },
              '& .MuiTabs-indicator': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Tabs
              className="video-dialog-tabs"
              tab={tab}
              onChange={(val) => {
                setTab(val as TabType);
                setSearchedStr('');
                setCurrentSearchStr('');
              }}
              tabs={[
                {
                  key: 'creatorList',
                  label: <Box>Creator List ({trackedCreators.length})</Box>,
                  content: (
                    <>
                      <CreatorsSearch
                        showHelperText={
                          !!currentSearchStr && currentSearchStr !== searchedStr
                        }
                        searchStr={currentSearchStr}
                        onChange={(value) => {
                          setCurrentSearchStr(value);
                        }}
                        onSearch={(value) => {
                          setSearchedStr(value);
                        }}
                      />
                      {searchingCreators ? (
                        searchingState
                      ) : !creatorsList.length ? (
                        noUsersState
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap={4}
                          py={4}
                        >
                          <CreatorsListing
                            trackedCreators={trackedCreators}
                            usersListToDisplay={creatorsList}
                            updatingCreatorPlatformIds={
                              updatingCreatorPlatformIds
                            }
                            showDetailsOnHover
                            handleCreatorTracking={async (
                              creatorPlatformId,
                              handle,
                              track,
                            ) => {
                              if (track) {
                                await handleTrackCreator(
                                  creatorPlatformId,
                                  topicId,
                                  handle,
                                );
                              } else {
                                await handleUntrackCreator(
                                  creatorPlatformId,
                                  topicId,
                                  handle,
                                );
                              }
                            }}
                          />
                        </Box>
                      )}
                    </>
                  ),
                },
                {
                  key: 'followingCreators',
                  label: (
                    <Box>
                      Following on Instagram ({followingCreators.length})
                    </Box>
                  ),
                  content: (
                    <>
                      <CreatorsSearch
                        showHelperText={
                          !!currentSearchStr && currentSearchStr !== searchedStr
                        }
                        searchStr={currentSearchStr}
                        onChange={(value) => {
                          setCurrentSearchStr(value);
                        }}
                        onSearch={(value) => {
                          setSearchedStr(value);
                        }}
                      />
                      {searchingCreators ? (
                        searchingState
                      ) : !followingCreatorsList.length ? (
                        noUsersState
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap={4}
                          py={4}
                        >
                          <CreatorsListing
                            trackedCreators={trackedCreators}
                            usersListToDisplay={followingCreatorsList}
                            updatingCreatorPlatformIds={
                              updatingCreatorPlatformIds
                            }
                            handleCreatorTracking={async (
                              creatorPlatformId,
                              handle,
                              track,
                            ) => {
                              if (track) {
                                await handleTrackCreator(
                                  creatorPlatformId,
                                  topicId,
                                  handle,
                                );
                              } else {
                                await handleUntrackCreator(
                                  creatorPlatformId,
                                  topicId,
                                  handle,
                                );
                              }
                            }}
                          />
                        </Box>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
