import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldArrowLeft1 } from 'components/icons/components/bold/IconBoldArrowLeft1';
import { IconBoldCloseSquare } from 'components/icons/components/bold/IconBoldCloseSquare';
import { IconLinearMaximize4 } from 'components/icons/components/linear/IconLinearMaximize4';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SocialMediaListeningPostDetailView } from './SocialMediaListeningPostDetailView';

type Props = {
  title?: string;
  isOpen: boolean;
  onClose: () => void;

  postId: string;
  searchParams?: URLSearchParams;
};

export const SocialMediaListeningPostDetailDialogView = ({
  title,
  isOpen,
  onClose,
  postId,
  searchParams,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxWidth: '1024px',
          borderRadius: theme.spacing(6),
          padding: theme.spacing(6),
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={4}
        mb={4}
        borderBottom={`1px solid ${theme.colors?.utility[275]}`}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton
            disableRipple
            onClick={onClose}
            sx={{ color: theme.colors?.primary.black, p: 0 }}
          >
            <IconBoldArrowLeft1 size={24} />
          </IconButton>
          {title && (
            <Typography
              variant="headline-sm"
              color={theme.colors?.primary.black}
            >
              {title}
            </Typography>
          )}
        </Box>

        <Box display="flex" gap={5} alignItems="center">
          <Link to={PlotRoutes.socialListeningPost(postId)}>
            <IconButton
              disableRipple
              sx={{ color: theme.colors?.primary.black, p: 0 }}
            >
              <IconLinearMaximize4 size={18} />
            </IconButton>
          </Link>
          <IconButton
            disableRipple
            onClick={onClose}
            sx={{ color: theme.colors?.primary.black, p: 0 }}
          >
            <IconBoldCloseSquare size={24} />
          </IconButton>
        </Box>
      </Box>
      <SocialMediaListeningPostDetailView
        socialPostId={postId}
        searchParams={searchParams}
        componentProps={{
          modalView: true,
        }}
      />
    </Dialog>
  );
};
