import { gql } from '@apollo/client';
import { Box, Chip, Divider, SxProps, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Avatar } from 'components/common/AvatarGroup';
import { typography } from 'components/common/Typography/styles';
import { IconBoldCalendar1 } from 'components/icons/components/bold/IconBoldCalendar1';
import { IconCustomUser } from 'components/icons/components/custom/IconCustomUser';
import { IconLinearSave } from 'components/icons/components/linear/IconLinearSave';
import { IconOutlineMaximize2 } from 'components/icons/components/outline/IconOutlineMaximize2';
import { IconOutlineStatus } from 'components/icons/components/outline/IconOutlineStatus';
import {
  ContentIdeaCollection,
  ContentIdeaDueDate,
  ContentIdeaField,
  ContentIdeaFieldName,
  ContentIdeaName,
  ContentIdeaNote,
  ContentIdeaOwners,
  ContentIdeaPlatforms,
  ContentIdeaPreview,
  ContentIdeaStatus,
} from 'features/contentIdea/components';
import { AddFieldButton } from 'features/contentIdea/components/field/AddFieldButton';
import { ContentIdeaPublishMetadataPublishUrlCustomField } from 'features/contentIdea/components/publishMetadataUrlCustomField/ContentIdeaPublishMetadataUrlCustomField';
import { FormattedContentIdeaPrimaryField } from 'features/contentIdea/constants';
import { getCustomStatusStyles } from 'features/contentIdea/utils';
import {
  DndDragItemTypes,
  DndSortableDragItem,
  DndSortableDropItem,
} from 'features/dnd';
import {
  CollectionFragmentCollectionFieldFragmentDoc,
  ContentIdeaFieldFragmentContentIdeaFieldNameFragmentDoc,
  ContentIdeaFieldValueFragmentContentIdeaFieldFragmentDoc,
  ContentIdeaFragmentContentIdeaCollectionFragmentDoc,
  ContentIdeaFragmentContentIdeaDetailViewFragment,
  ContentIdeaFragmentContentIdeaDueDateFragmentDoc,
  ContentIdeaFragmentContentIdeaNoteFragmentDoc,
  ContentIdeaFragmentContentIdeaOwnersFragmentDoc,
  ContentIdeaFragmentContentIdeaPlatformsFragmentDoc,
  ContentIdeaFragmentContentIdeaPreviewFragmentDoc,
  ContentIdeaFragmentContentIdeaStatusFragmentDoc,
  ContentIdeaPermission,
  ContentIdeaPrimaryField,
  ContentIdeaPublishMetadataFragmentContentIdeaPublishMetadataPublishUrlCustomFieldFragmentDoc,
  CreateContentIdeaFieldInputData,
  Platform,
  useCreateContentIdeaFieldForContentIdeaDetailViewMutation,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { useContentIdeaHandlers } from './useContentIdeaHandlers';

export const CONTENT_IDEA_FIELD_FRAGMENT_CONTENT_IDEA_DETAIL_VIEW = gql`
  fragment ContentIdeaFieldFragmentContentIdeaDetailView on ContentIdeaFieldModel {
    id
    sortOrder
    ...ContentIdeaFieldFragmentContentIdeaFieldName
  }
  ${ContentIdeaFieldFragmentContentIdeaFieldNameFragmentDoc}
`;

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_DETAIL_VIEW = gql`
  fragment ContentIdeaFragmentContentIdeaDetailView on ContentIdeaModel {
    id
    myPermissions
    collection {
      id
    }
    fields {
      id
      contentIdeaField {
        id
        ...ContentIdeaFieldFragmentContentIdeaDetailView
      }
      ...ContentIdeaFieldValueFragmentContentIdeaField
    }
    contentIdeaPublishMetadata {
      id
      platform
      ...ContentIdeaPublishMetadataFragmentContentIdeaPublishMetadataPublishUrlCustomField
    }
    ...ContentIdeaFragmentContentIdeaPreview
    ...ContentIdeaFragmentContentIdeaName
    ...ContentIdeaFragmentContentIdeaCollection
    ...ContentIdeaFragmentContentIdeaNote
    ...ContentIdeaFragmentContentIdeaPlatforms
    ...ContentIdeaFragmentContentIdeaStatus
    ...ContentIdeaFragmentContentIdeaOwners
    ...ContentIdeaFragmentContentIdeaDueDate
  }
  ${CONTENT_IDEA_FIELD_FRAGMENT_CONTENT_IDEA_DETAIL_VIEW}
  ${ContentIdeaFragmentContentIdeaPreviewFragmentDoc}
  ${ContentIdeaFieldFragmentContentIdeaFieldNameFragmentDoc}
  ${ContentIdeaFieldValueFragmentContentIdeaFieldFragmentDoc}
  ${ContentIdeaFragmentContentIdeaCollectionFragmentDoc}
  ${ContentIdeaFragmentContentIdeaNoteFragmentDoc}
  ${ContentIdeaFragmentContentIdeaPlatformsFragmentDoc}
  ${ContentIdeaPublishMetadataFragmentContentIdeaPublishMetadataPublishUrlCustomFieldFragmentDoc}
  ${ContentIdeaFragmentContentIdeaStatusFragmentDoc}
  ${ContentIdeaFragmentContentIdeaOwnersFragmentDoc}
  ${ContentIdeaFragmentContentIdeaDueDateFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation CreateContentIdeaFieldForContentIdeaDetailView(
    $data: CreateContentIdeaFieldInput!
  ) {
    createContentIdeaField(data: $data) {
      id
      ...ContentIdeaFieldFragmentContentIdeaFieldName
    }
  }
  ${ContentIdeaFieldFragmentContentIdeaFieldNameFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCollectionForContentIdeaDetailView($id: String!) {
    collection(id: $id) {
      id
      ...CollectionFragmentCollectionField
    }
  }
  ${CollectionFragmentCollectionFieldFragmentDoc}
`;

export type ContentIdeaDetailViewProps = {
  contentIdea: ContentIdeaFragmentContentIdeaDetailViewFragment;
  sx?: SxProps;
  refetchContentIdea?: () => Promise<void>;
  canNavigateToCollection?: boolean;
};

export const ContentIdeaDetailView = (props: ContentIdeaDetailViewProps) => {
  const { contentIdea, sx, refetchContentIdea, canNavigateToCollection } =
    props;

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  // By default, we render Status, Due Date and Owners differently, in a horizontal list.
  // The rest of the fields are rendered in a vertical list,
  // with primary fields following a certain order
  const { remainingFields } = useMemo(() => {
    const excludedPrimaryFields = [
      FormattedContentIdeaPrimaryField.Status,
      FormattedContentIdeaPrimaryField.DueDate,
      FormattedContentIdeaPrimaryField.Owners,
    ];

    const remainingFields = contentIdea.fields
      .filter((fieldValue) => {
        return !excludedPrimaryFields.includes(
          fieldValue.contentIdeaField.name as ContentIdeaPrimaryField,
        );
      })
      .sort((a, b) => {
        return a.contentIdeaField.sortOrder - b.contentIdeaField.sortOrder;
      });

    return {
      remainingFields,
    };
  }, [contentIdea.fields]);

  // Track the new field ID so we can open the field name's popover
  // by default when it renders
  const [newFieldId, setNewFieldId] = useState<string | null>(null);
  const [createContentIdeaField] =
    useCreateContentIdeaFieldForContentIdeaDetailViewMutation();
  const onAddField = async (data: CreateContentIdeaFieldInputData) => {
    const response = await createContentIdeaField({
      variables: {
        data: {
          contentIdeaId: contentIdea.id,
          data,
        },
      },
    });

    setNewFieldId(response.data?.createContentIdeaField.id || null);

    // TODO: We'll want cache manipulation here to avoid refetching the entire content idea.
    // For now, we'll just refetch the entire content idea because contentIdea.fields
    // actually use ContentIdeaFieldValueModel (not ContentIdeaFieldModel, which is what we're creating here)
    // so it'd be hard to update the cache to include the new field when we don't know the value object yet.
    // Can change the createContentIdeaField mutation to return the value object as well, but let's keep it simple for now.
    await refetchContentIdea?.();

    setTimeout(() => {
      setNewFieldId(null);
    }, 500);
  };

  /**
   * Render a field based on its type using `ContentIdeaField` component.
   * This method contains some custom logic for certain fields like Platforms.
   */
  const renderField = (
    fieldValue: ContentIdeaFragmentContentIdeaDetailViewFragment['fields'][0],
  ) => {
    if (
      fieldValue.contentIdeaField.name ===
      FormattedContentIdeaPrimaryField.Platforms
    ) {
      return (
        <ContentIdeaPlatforms contentIdea={contentIdea} triggerVariant="full" />
      );
    }

    return (
      <ContentIdeaField
        id={fieldValue.contentIdeaField.id}
        readOnly={!canEdit}
        value={{
          text: fieldValue.value?.text,
          date: fieldValue.value?.date,
          checkbox: fieldValue.value?.checkbox,
          multiSelect: fieldValue.value?.multiSelect?.map(
            (value) => value.value,
          ),
          select: fieldValue.value?.select?.value,
          userIds: fieldValue.value?.users?.map((user) => user.id),
          taskIds: fieldValue.value?.tasks?.map((task) => task.id),
          collectionId: fieldValue.value?.collection?.id,
        }}
        valueId={fieldValue.id}
        type={fieldValue.contentIdeaField.type}
        contentIdeaCollectionId={contentIdea.collection.id}
      />
    );
  };

  /**
   * Logic for updating a field's sort order.
   */
  const { onFieldSortEnd } = useContentIdeaHandlers({
    remainingFields: remainingFields.map((fv) => fv.contentIdeaField),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {canNavigateToCollection && (
        <Link
          to={PlotRoutes.collection(contentIdea.collection.id)}
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Typography variant="subhead-xl" color={theme.colors?.primary.black}>
            Go to Collection
          </Typography>
          <IconOutlineMaximize2 size={16} color={theme.colors?.primary.black} />
        </Link>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          mb: 8,
        }}
      >
        <ContentIdeaPreview
          contentIdea={contentIdea}
          readOnly={!canEdit}
          sx={{
            width: 100,
            height: 120,
            borderRadius: 3,
            overflow: 'hidden',
            flexShrink: 0,
          }}
        />
        <ContentIdeaName
          contentIdea={contentIdea}
          style={{
            ...typography['headline-xl'],
            fontWeight: 400,
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 4,
          flexWrap: 'wrap',
        }}
      >
        <Box>
          <ContentIdeaStatus
            contentIdea={contentIdea}
            renderTrigger={({ options }) => {
              const option = options.find(
                (o) => o.value === contentIdea.status.value?.select?.value,
              );

              // FIXME: THIS IS A HACK FOR STATUS COLORS
              const customStyles = getCustomStatusStyles(
                option?.value || '',
                option?.bgcolor || '',
              );

              return (
                <Chip
                  component="button"
                  icon={<IconOutlineStatus size={16} />}
                  label={option?.label || 'Status'}
                  variant="filled-borderless-color-dodge"
                  sx={{
                    cursor: 'pointer',
                    height: 32,
                    '& .MuiChip-label': {
                      px: 3,
                      ...(customStyles.color
                        ? {
                            filter: 'none !important',
                            color: customStyles.color,
                          }
                        : {}),
                    },
                    ...customStyles,
                    ...typography['subhead-xl'],
                  }}
                />
              );
            }}
          />
        </Box>
        <Box>
          <ContentIdeaDueDate
            contentIdea={contentIdea}
            renderInput={({ inputRef, openPicker, onClear }) => {
              return (
                <Chip
                  ref={inputRef}
                  component="button"
                  icon={
                    <IconBoldCalendar1 size={16} style={{ flexShrink: 0 }} />
                  }
                  label={
                    contentIdea.dueDate.value?.date
                      ? moment(contentIdea.dueDate.value?.date).format(
                          'MMM D, YYYY • hh:mmA',
                        )
                      : 'Publish Date'
                  }
                  variant="filled-borderless"
                  onClick={openPicker}
                  sx={{
                    cursor: 'pointer',
                    ...typography['subhead-xl'],
                    pointerEvents: canEdit ? 'auto' : 'none',
                  }}
                  onDelete={
                    canEdit && contentIdea.dueDate.value?.date
                      ? onClear
                      : undefined
                  }
                />
              );
            }}
          />
        </Box>
        <Box>
          <ContentIdeaOwners
            contentIdea={contentIdea}
            renderTrigger={() => {
              if (!contentIdea.owners.value?.users?.length) {
                return (
                  <Chip
                    component="button"
                    icon={<IconCustomUser size={16} />}
                    label="Owners"
                    variant="filled-borderless"
                    sx={{
                      cursor: 'pointer',
                      ...typography['subhead-xl'],
                    }}
                  />
                );
              }

              return (
                <Chip
                  component="button"
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap',
                      }}
                    >
                      {contentIdea.owners.value?.users?.map((user) => {
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Avatar user={user} size={16} />
                            <Typography variant="subhead-xl">
                              {getFullName(user)}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  }
                  variant="filled-borderless"
                  sx={{
                    cursor: 'pointer',
                    height: 'auto',
                    py: 1.5,
                    ...typography['subhead-xl'],
                  }}
                />
              );
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{ width: 128, display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <IconLinearSave size={16} color={theme.colors?.utility[700]} />
            <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
              Root Collection
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              p: 2,
              '&:hover': {
                bgcolor: theme.colors?.utility['250'],
              },
              borderRadius: 3,
            }}
          >
            <ContentIdeaCollection contentIdea={contentIdea} />
          </Box>
        </Box>

        <DndSortableDropItem
          accept={[DndDragItemTypes.ContentIdeaField]}
          onDrop={onFieldSortEnd}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {remainingFields.map((fieldValue) => {
            if (!fieldValue.contentIdeaField) {
              return null;
            }
            // NOTE: This whole bunch is a temporary solution to render the publish urls.
            // These should be removed once we move on to the complete Phase 3 implementation.
            const isPlatformsField =
              fieldValue.contentIdeaField.name ===
              FormattedContentIdeaPrimaryField.Platforms;
            const isPublished =
              contentIdea.status?.value?.select?.value === 'Published';
            const instagramContentIdeaPublishMetadata =
              contentIdea.contentIdeaPublishMetadata.find(
                (metadata) => metadata.platform === Platform.Instagram,
              );
            const tiktokContentIdeaPublishMetadata =
              contentIdea.contentIdeaPublishMetadata.find(
                (metadata) => metadata.platform === Platform.Tiktok,
              );

            return (
              <Box component="span" key={fieldValue.id}>
                <DndSortableDragItem
                  type={DndDragItemTypes.ContentIdeaField}
                  item={fieldValue.contentIdeaField}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Box sx={{ width: 128 }}>
                    <ContentIdeaFieldName
                      contentIdeaField={fieldValue.contentIdeaField}
                      contentIdeaId={contentIdea.id}
                      readOnly={
                        fieldValue.contentIdeaField.isPrimaryField || !canEdit
                      }
                      isNew={newFieldId === fieldValue.contentIdeaField.id}
                      onAfterUpdate={refetchContentIdea}
                      onAfterDuplicate={refetchContentIdea}
                    />
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      p: 2,
                      '&:hover': {
                        bgcolor: theme.colors?.utility['250'],
                      },
                      borderRadius: 3,
                    }}
                  >
                    {renderField(fieldValue)}
                  </Box>
                </DndSortableDragItem>

                {isPublished && isPlatformsField && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    {tiktokContentIdeaPublishMetadata &&
                      fieldValue.value?.multiSelect?.some(
                        (o) => o.value === 'Tiktok',
                      ) && (
                        <ContentIdeaPublishMetadataPublishUrlCustomField
                          contentIdeaPublishMetadata={
                            tiktokContentIdeaPublishMetadata
                          }
                        />
                      )}
                    {instagramContentIdeaPublishMetadata &&
                      fieldValue.value?.multiSelect?.some(
                        (o) => o.value === 'Instagram',
                      ) && (
                        <ContentIdeaPublishMetadataPublishUrlCustomField
                          contentIdeaPublishMetadata={
                            instagramContentIdeaPublishMetadata
                          }
                        />
                      )}
                  </Box>
                )}
              </Box>
            );
          })}
        </DndSortableDropItem>

        {canEdit && (
          <Box
            sx={{
              py: 2,
            }}
          >
            <AddFieldButton onAddField={onAddField} />
          </Box>
        )}

        <Divider />

        <Box
          sx={{
            py: 2,
          }}
        >
          <ContentIdeaNote contentIdea={contentIdea} />
        </Box>
      </Box>
    </Box>
  );
};
