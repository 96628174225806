import { Box, Typography, IconButton, TextField, SxProps } from '@mui/material';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import React, { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  keywords: string[];
  updateKeywords: (keywords: string[]) => void;
  componentProps?: {
    container?: {
      sx?: SxProps;
    };
    keyword?: {
      sx?: SxProps;
    };
    createButton?: {
      label?: string;
    };

    createKeyword?: {
      hideCreateKeyword?: boolean;
      position?: 'pre' | 'post' | 'after';
      sx?: SxProps;
    };
  };
};

export const SocialMediaListeningKeywords = ({
  keywords,
  componentProps,
  updateKeywords,
}: Props) => {
  const [newKeyword, setNewKeyword] = useState('');
  const [addingKeyword, setAddingKeyword] = useState(false);

  const addKeyword = useMemo(
    () =>
      !componentProps?.createKeyword?.hideCreateKeyword && (
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          mt={4}
          className="keyword-add-btn"
          sx={{
            width: 'fit-content',
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
            p: theme.spacing(2, 3),
            borderRadius: 2,
            color: theme.colors?.utility[500],
            ...(componentProps?.createKeyword?.sx || {}),
          }}
        >
          {!addingKeyword ? (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              onClick={() => setAddingKeyword(true)}
            >
              <IconBoldAddCircle className="keyword-add" size={16} />
              <Typography
                className="keyword-label"
                variant="body-xl"
                fontWeight={500}
              >
                {componentProps?.createButton?.label || 'Add a keyword'}
              </Typography>
            </Box>
          ) : (
            <TextField
              autoFocus
              onBlur={() => setAddingKeyword(false)}
              onChange={(e) => setNewKeyword(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter' && newKeyword.trim() !== '') {
                  updateKeywords([...keywords, newKeyword.trim()]);
                  setNewKeyword('');
                  setAddingKeyword(false);
                }
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: theme.colors?.primary.parchment,
                  fontWeight: 600,
                  borderRadius: 4,
                },
                '& .MuiInputBase-input': {
                  p: 0,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            />
          )}
        </Box>
      ),
    [componentProps, addingKeyword, keywords, newKeyword],
  );

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={3}
        mt={4}
        sx={{ ...(componentProps?.container?.sx || {}) }}
      >
        {componentProps?.createKeyword?.position === 'pre' && addKeyword}
        {keywords.map((keyword, index) => (
          <Box
            key={index}
            display="flex"
            gap={1}
            alignItems="center"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.10)',
              p: theme.spacing(2, 3),
              borderRadius: 2,
              color: theme.colors?.primary.parchment,
              '& .keyword-label': {
                fontWeight: 600,
                color: theme.colors?.primary.parchment,
              },
              '& .keyword-delete': {
                color: theme.colors?.primary.parchment,
              },
              ...(componentProps?.keyword?.sx || {}),
            }}
          >
            <Typography className="keyword-label" variant="body-xl">
              {keyword}
            </Typography>
            <IconButton
              className="keyword-delete"
              sx={{ p: 0 }}
              disableRipple
              onClick={() => {
                updateKeywords(keywords.filter((_, i) => i !== index));
              }}
            >
              <IconBoldCloseCircle size={16} />
            </IconButton>
          </Box>
        ))}
        {componentProps?.createKeyword?.position === 'post' && addKeyword}
      </Box>
      {(componentProps?.createKeyword?.position === 'after' ||
        !componentProps?.createKeyword?.position) &&
        addKeyword}
    </>
  );
};
