import { gql } from '@apollo/client';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  PostFiltersForSmartSearch,
  PostFragmentCollectionPostDndPostCardFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowWithContextMenuFragmentDoc,
  PostFragmentPostCommandFragment,
  SortType,
  ViewEntityType,
  useGetPostsSmartSearchForCollectionPageNormalCollectionQuery,
  useViewEntitiesForPostMutation,
} from 'graphql/generated';
import { useUpdateQueryByCommand } from 'hooks/commands/useUpdateQueryByCommand';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line
gql`
  query GetPostsSmartSearchForCollectionPageNormalCollection(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
    $sortType: SortType
  ) {
    postsSmartSearch(
      filters: $filters
      take: $take
      after: $after
      sortType: $sortType
    ) {
      data {
        item {
          id
          ...PostFragmentCollectionPostDndPostCard
          ...PostFragmentJuiceboxTablePostTableRowWithContextMenu
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PostFragmentCollectionPostDndPostCardFragmentDoc}
  ${PostFragmentJuiceboxTablePostTableRowWithContextMenuFragmentDoc}
`;

export type UsePostQueryForNormalCollectionProps = {
  filters: PostFiltersForSmartSearch;
  skip?: boolean;
  sortType?: SortType;
};

export const usePostQueryForNormalCollection = (
  props: UsePostQueryForNormalCollectionProps,
) => {
  const { filters, skip, sortType } = props;

  const { collectionId = '' } = useParams();

  const {
    data,
    loading,
    fetchMore: _fetchMore,
    updateQuery,
  } = useGetPostsSmartSearchForCollectionPageNormalCollectionQuery({
    variables: {
      filters: {
        ...filters,
      },
      sortType,
    },
    skip,
    fetchPolicy: 'cache-and-network',
  });
  const isFirstLoading = loading && !data;

  const fetchMore = async () => {
    if (loading) {
      return;
    }
    _fetchMore({
      variables: {
        filters: {
          ...filters,
        },
        after: data?.postsSmartSearch.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          postsSmartSearch: {
            ...fetchMoreResult.postsSmartSearch,
            data: [
              ...(prev.postsSmartSearch.data || []),
              ...(fetchMoreResult.postsSmartSearch.data || []),
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.item.id === e.item.id),
            ),
          },
        };
      },
    });
  };

  // Update query when creating/saving new posts
  const addNewPostsToQuery = (posts: PostFragmentPostCommandFragment[]) => {
    const postsThatShouldBeAddedToCurrentCollection = posts.filter((p) =>
      (p.collections || []).some((c) => c.id === collectionId),
    );

    if (postsThatShouldBeAddedToCurrentCollection.length > 0) {
      updateQuery((prevData) => {
        return {
          ...prevData,
          postsSmartSearch: {
            ...prevData.postsSmartSearch,
            data: [
              ...postsThatShouldBeAddedToCurrentCollection.map((p) => ({
                __typename: 'PostSearchHit' as any,
                item: p,
              })),
              ...prevData.postsSmartSearch.data,
            ]
              // Dedupe by id
              .filter(
                (e, index, self) =>
                  index === self.findIndex((t) => t.item.id === e.item.id),
              ),
          },
        };
      });
    }
  };

  useUpdateQueryByCommand(COMMAND_TYPE.POST_PASTE_A_LINK, addNewPostsToQuery);
  useUpdateQueryByCommand(COMMAND_TYPE.POST_UPLOAD_FILES, addNewPostsToQuery);
  useUpdateQueryByCommand(COMMAND_TYPE.POST_WRITE_A_NOTE, addNewPostsToQuery);
  useUpdateQueryByCommand(COMMAND_TYPE.POST_AFTER_CREATION, addNewPostsToQuery);

  // View all new posts after query is updated
  const [viewEntities] = useViewEntitiesForPostMutation();
  useEffect(() => {
    const newPosts = (data?.postsSmartSearch.data || []).filter(
      (p) => p.item.isNew,
    );
    if (newPosts.length > 0) {
      viewEntities({
        variables: {
          data: {
            entityIds: newPosts.map((p) => p.item.id),
            entityType: ViewEntityType.Post,
          },
        },
      });
    }
  }, [data]); // eslint-disable-line

  return {
    data,
    loading: isFirstLoading,
    fetchMore,
    updateQuery,
  };
};
