import { Avatar, Box, Button, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SocialMediaListeningPostCreatorProfileDetail } from './SocialMediaListeningCreatorProfileDetail';
import { SocialMediaListeningCreatorProfileProps } from './types';

export const SocialMediaListeningCreatorProfile = (
  props: SocialMediaListeningCreatorProfileProps,
) => {
  const { componentProps, creator, platformCreateTime } = props;

  const ProfileContent = (
    <Box
      display="flex"
      alignItems="center"
      gap={4}
      sx={{
        ...(componentProps?.containerSx?.sx || {}),
      }}
    >
      <Avatar
        src={creator.profilePictureUrl || ''}
        sx={{
          width: theme.spacing(11),
          height: theme.spacing(11),
          ...(componentProps?.avatarSx?.sx || {}),
        }}
      />
      <Box display="flex" flexDirection="column" gap={1} flex={1}>
        <Typography variant="headline-md" className="handle">
          {creator.handle}
        </Typography>
        <Typography
          className="nickName"
          color={theme.colors?.utility[800]}
          variant="body-lg"
        >
          {creator.nickName}{' '}
          {platformCreateTime && `• ${moment(platformCreateTime).fromNow()}`}
        </Typography>
        {componentProps?.showStats && (
          <Box display="flex" gap={4}>
            <Box display="flex" flexDirection="column">
              <Box>
                <Typography variant="headline-md">
                  {formatBigNumber(creator.followerCount || 0)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body-lg">followers</Typography>
              </Box>
            </Box>
            {!!creator.likeCount && (
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography variant="headline-md">
                    {formatBigNumber(creator.likeCount || 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body-lg">likes</Typography>
                </Box>
              </Box>
            )}
            {!!creator.engagementRate && (
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography variant="headline-md">
                    {parseFloat(
                      ((creator.engagementRate || 0) * 100).toFixed(1),
                    )}
                    %
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body-lg">engagement rate</Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {componentProps?.renderCreatorTrackingButton &&
        componentProps?.renderCreatorTrackingButton()}
    </Box>
  );

  return componentProps?.showDetailsOnHover ? (
    <Tooltip
      title={
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.90)',
            color: theme.colors?.primary.black,
            width: theme.spacing(125),
            p: 4,
            maxHeight: theme.spacing(100),
            overflowY: 'auto',
            boxShadow:
              '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)',
            borderRadius: 6,
          }}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <SocialMediaListeningPostCreatorProfileDetail
            componentProps={{
              renderCreatorTrackingButton:
                componentProps?.renderCreatorTrackingButton,
            }}
            creator={creator}
          />
        </Box>
      }
      placement="bottom-start"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      {ProfileContent}
    </Tooltip>
  ) : (
    ProfileContent
  );
};
