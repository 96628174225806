/**
 * TODO: Refactor & move these into constants folder.
 */

import { IconCustomDone } from 'components/icons/components/custom/IconCustomDone';
import { IconCustomProgress } from 'components/icons/components/custom/IconCustomProgress';
import { IconCustomReview } from 'components/icons/components/custom/IconCustomReview';
import { IconCustomTodo } from 'components/icons/components/custom/IconCustomTodo';
import { IconOutlineArchiveMinus } from 'components/icons/components/outline/IconOutlineArchiveMinus';
import { TaskStatus } from 'graphql/generated';
import { theme } from 'styles/theme';

export const getTaskStatusText = (status?: TaskStatus): string => {
  switch (status) {
    case TaskStatus.ToDo:
      // TODO: update the enum key TaskStatus to NotStarted later to make the key more clear
      // for now just update the status name
      return 'Todo';
    case TaskStatus.InProgress:
      return 'In Progress';
    case TaskStatus.Done:
      return 'Done';
    case TaskStatus.Archived:
      return 'Archived';
    case TaskStatus.InReview:
      return 'In Review';
    default:
      return '';
  }
};

export const getTaskStatusButtonStyles = (status?: TaskStatus) => {
  switch (status) {
    case TaskStatus.InProgress:
      return {
        color: 'white',
        backgroundColor: theme.colors?.utility['yellow-3-new'],
        borderColor: theme.colors?.utility['yellow-3-new'],
      };
    case TaskStatus.InReview:
      return {
        color: 'white',
        backgroundColor: theme.colors?.utility['purple-3'],
        borderColor: theme.colors?.utility['purple-3'],
      };
    case TaskStatus.Done:
      return {
        color: 'white',
        backgroundColor: theme.colors?.utility['green-3-new'],
        borderColor: theme.colors?.utility['green-3-new'],
      };
    case TaskStatus.Archived:
      return {
        color: theme.colors?.utility[900],
        backgroundColor: 'transparent',
        borderColor: theme.colors?.utility[1000],
      };
    default:
      return {
        color: theme.colors?.utility[1000],
        backgroundColor: theme.colors?.utility[250],
        borderColor: theme.colors?.utility[400],
      };
  }
};

export const getTaskStatusDotStyle = (status: TaskStatus) => {
  if (status === TaskStatus.ToDo) {
    return {
      borderWidth: '1px',
      borderColor: theme.colors?.utility[600],
      borderStyle: 'solid',
    };
  }

  const styles = getTaskStatusButtonStyles(status);

  return {
    backgroundColor: styles.backgroundColor,
  };
};

export const getTaskStatusIcon = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.InProgress:
      return {
        Icon: IconCustomProgress,
        color: theme.colors?.utility['yellow-3-new'],
        size: 16,
      };
    case TaskStatus.InReview:
      return {
        Icon: IconCustomReview,
        color: theme.colors?.utility['purple-3'],
        size: 16,
      };
    case TaskStatus.Done:
      return {
        Icon: IconCustomDone,
        color: theme.colors?.utility['green-3-new'],
        size: 16,
      };

    case TaskStatus.Archived:
      return {
        Icon: IconOutlineArchiveMinus,
        color: theme.colors?.utility[900],
        size: 16,
      };
    case TaskStatus.ToDo:
      return {
        Icon: IconCustomTodo,
        color: theme.colors?.utility[900],
        size: 16,
      };
    default:
      return {
        Icon: IconOutlineArchiveMinus,
        color: theme.colors?.utility['green-2'],
        size: 20,
      };
  }
};
