import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineCrossXClose } from 'components/icons/components/outline/IconOutlineCrossXClose';
import { taskGroupSelectOptions } from 'features/task/constants';
import { useRef } from 'react';
import { theme } from 'styles/theme';

export type TaskGroupSelectButtonFilterProps = {
  value?: string;
  onChange: (value?: string) => void;
};

export const TaskGroupSelectButtonFilter = (
  props: TaskGroupSelectButtonFilterProps,
) => {
  const { value, onChange } = props;

  const anchorElRef = useRef<HTMLButtonElement>(null);
  const {
    isOpen: isMenuOpen,
    onClose: closeMenu,
    onOpen: openMenu,
  } = useDisclosure();

  const selectedOption = taskGroupSelectOptions.find(
    (option) => option.value === value,
  );

  return (
    <>
      <IconButton
        ref={anchorElRef}
        size="small"
        sx={{
          p: 1,
          borderRadius: 1.5,
          bgcolor: theme.colors?.utility[300],
        }}
        onClick={openMenu}
      >
        {selectedOption ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <selectedOption.Icon size={16} />
            <IconButton
              size="small"
              sx={{ p: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onChange(undefined);
              }}
            >
              <IconOutlineCrossXClose size={12} />
            </IconButton>
          </Box>
        ) : (
          <IconLinearSort size={16} />
        )}
      </IconButton>
      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        anchorEl={anchorElRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            minWidth: 224,
            p: 2,
            boxShadow:
              '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
          },
        }}
      >
        {taskGroupSelectOptions.map((option) => {
          return (
            <MenuItem
              key={option.value}
              onClick={() => {
                onChange(option.value);
                closeMenu();
              }}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                borderRadius: 3,
              }}
            >
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 1,
                  borderRadius: 1,
                  bgcolor: 'rgba(35, 6, 3, 0.05)',
                }}
              >
                <option.Icon size={16} />
              </Box>
              <Box>
                <Typography variant="subhead-lg">{option.label}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
