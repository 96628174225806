import { gql } from '@apollo/client';
import { Box, Button, SxProps, TextField, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { typography } from 'components/common/Typography/styles';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconLinearAddCircle } from 'components/icons/components/linear/IconLinearAddCircle';
import { useUserContext } from 'contexts/users/User.context';
import {
  OrganizationSocialPostCommentLabelFragmentCommentInsightsFiltersFragmentDoc,
  OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment,
  OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragmentDoc,
  useCreateOrganizationSocialPostCommentLabelsForEditCompetitorLabelsMutation,
  useGetOrganizationSocialPostCommentLabelsForEditCompetitorLabelsQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { CompetitorLabel } from './CompetitorLabel';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment OrganizationSocialPostCommentLabelFragmentEditCompetitorLabels on OrganizationSocialPostCommentLabelModel {
    id
    organizationId
    label
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetOrganizationSocialPostCommentLabelsForEditCompetitorLabels(
    $data: OrganizationSocialPostCommentLabelsInput!
  ) {
    organizationSocialPostCommentLabels(data: $data) {
      data {
        ...OrganizationSocialPostCommentLabelFragmentEditCompetitorLabels
      }
    }
  }
  ${OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateOrganizationSocialPostCommentLabelsForEditCompetitorLabels(
    $data: CreateOrganizationSocialPostCommentLabelInput!
  ) {
    createOrganizationSocialPostCommentLabels(data: $data) {
      ...OrganizationSocialPostCommentLabelFragmentCommentInsightsFilters
    }
  }
  ${OrganizationSocialPostCommentLabelFragmentCommentInsightsFiltersFragmentDoc}
`;

type Props = {
  brandId: string;
  onLabelToggle?: (
    label: OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment,
  ) => void;
  // allLabels: OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment[];
  selectedLabels: OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment[];
  renderCustomButton?: () => React.ReactNode;
  componentProps?: {
    label?: {
      sx?: SxProps;
    };
  };
};

export const EditCompetitorLabels = ({
  brandId,
  onLabelToggle,
  renderCustomButton,
  componentProps,
  selectedLabels,
}: Props) => {
  const { user } = useUserContext();
  const [searchStr, setSearchStr] = useState('');

  const selectedLabelIds = useMemo(
    () => selectedLabels.map((label) => label.id),
    [selectedLabels],
  );

  const { data: brandCommentLabelsData, refetch } =
    useGetOrganizationSocialPostCommentLabelsForEditCompetitorLabelsQuery({
      variables: {
        data: {
          organizationId: user?.organization.id!,
          filters: {},
        },
      },
      skip: !user?.organization.id,
    });
  const [createBrandCommentLabel] =
    useCreateOrganizationSocialPostCommentLabelsForEditCompetitorLabelsMutation();

  const filteredLabels =
    brandCommentLabelsData?.organizationSocialPostCommentLabels.data.filter(
      (label) => {
        return label.label.toLowerCase().includes(searchStr.toLowerCase());
      },
    ) || [];

  const handleLabelClick = (
    label: OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment,
  ) => {
    onLabelToggle?.(label);
  };

  const handleCreateLabel = async () => {
    if (
      searchStr &&
      !brandCommentLabelsData?.organizationSocialPostCommentLabels.data.find(
        (label) => label.label === searchStr,
      )
    ) {
      await createBrandCommentLabel({
        variables: {
          data: {
            organizationId: user?.organization.id!,
            labelText: searchStr,
          },
        },
      });
      refetch();
      setSearchStr('');
    }
  };

  return (
    <ContextMenu
      options={[
        {
          renderCustomComponent: () => (
            <Box px={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                sx={{
                  my: theme.spacing(1),
                  width: '100%',
                  backgroundColor: 'transparent',
                  borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: `${theme.spacing(5)} !important`,
                    border: 'none',
                  },
                  '& .MuiInputBase-input': {
                    ...typography['headline-sm'],
                    borderRadius: `${theme.spacing(5)} !important`,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            </Box>
          ),
        },
        ...(filteredLabels.length > 0
          ? filteredLabels.map((label) => ({
              renderOption: () => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLabelClick(label);
                  }}
                >
                  <CompetitorLabel
                    label={label.label}
                    componentProps={componentProps?.label}
                  />
                  {selectedLabelIds.includes(label.id) ? (
                    <IconBoldTickCircle size={16} />
                  ) : (
                    <IconLinearAddCircle size={16} />
                  )}
                </Box>
              ),
            }))
          : searchStr
          ? [
              {
                renderOption: () => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    py={2}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCreateLabel();
                    }}
                  >
                    <CompetitorLabel label={searchStr} />
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        borderRadius: theme.spacing(5),
                        borderColor: theme.colors?.primary.black,
                        color: theme.colors?.primary.black,
                        '&:hover': {
                          borderColor: theme.colors?.primary.black,
                          backgroundColor: 'transparent',
                          color: theme.colors?.primary.black,
                        },
                      }}
                    >
                      Create
                    </Button>
                  </Box>
                ),
              },
            ]
          : [
              {
                renderCustomComponent: () => (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    py={2}
                  >
                    <Typography
                      variant="body-md"
                      color={theme.colors?.utility[700]}
                    >
                      No labels found
                    </Typography>
                  </Box>
                ),
              },
            ]),
      ]}
      renderButton={() =>
        renderCustomButton ? (
          renderCustomButton()
        ) : (
          <Box>
            {!selectedLabelIds.length ? (
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: theme.spacing(5),
                  backgroundColor: theme.colors?.utility[300],
                }}
              >
                <IconLinearAdd size={16} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                {selectedLabels.map((label) => (
                  <CompetitorLabel
                    key={label.id}
                    label={label.label}
                    componentProps={componentProps?.label}
                  />
                ))}
              </Box>
            )}
          </Box>
        )
      }
    />
  );
};
