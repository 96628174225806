import { Box, SxProps } from '@mui/material';
import { PostPreview } from 'features/post';
import { CollectionFragmentCollectionMultiPostPreviewFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { PLACEHOLDER_BACKGROUND_COLORS } from '../constants';

export type CardProps = {
  collection: CollectionFragmentCollectionMultiPostPreviewFragment;
  sx?: SxProps;
  componentProps?: {
    card?: {
      sx?: SxProps;
    };
  };
};

export const Card = (props: CardProps) => {
  const { collection, sx, componentProps } = props;
  const { posts } = collection;

  const renderPost = (index: number) => {
    const post = posts[index];

    if (!post) {
      return (
        <Box
          sx={{
            height: '100%',
            bgcolor: PLACEHOLDER_BACKGROUND_COLORS[index],
            borderRadius: 2,
          }}
        />
      );
    }

    return (
      <PostPreview
        key={post.id}
        post={post}
        sx={{
          height: '100%',
          bgcolor: PLACEHOLDER_BACKGROUND_COLORS[index],
          borderRadius: 2,
          ...componentProps?.card?.sx,
        }}
        variant="compact"
        componentsProps={{
          postNotePreviewCompact: {
            sx: {
              p: 6,
            },
            componentsProps: {
              icon: {
                size: 16,
              },
              title: {
                sx: {
                  fontSize: 16,
                },
              },
            },
          },
        }}
      />
    );
  };

  const defaultCardStyles: SxProps = {
    aspectRatio: '7 / 10',
    borderRadius: 2,
    border: `2px solid ${theme.colors?.primary.white}`,
  };

  return (
    <Box
      sx={{
        zIndex: 0,
        position: 'relative',
        aspectRatio: '4 / 3',
        display: 'flex',
        gap: theme.spacing(2),
        ...sx,
      }}
    >
      <Box
        sx={{
          ...defaultCardStyles,
          ...componentProps?.card?.sx,
        }}
      >
        {renderPost(0)}
      </Box>
      <Box
        sx={{
          ...defaultCardStyles,
          ...componentProps?.card?.sx,
        }}
      >
        {renderPost(1)}
      </Box>
      <Box
        sx={{
          ...defaultCardStyles,
          ...componentProps?.card?.sx,
        }}
      >
        {renderPost(2)}
      </Box>
    </Box>
  );
};
