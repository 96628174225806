import { gql } from '@apollo/client';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { ContentIdeaPreview } from 'features/contentIdea/components';
import {
  ContentIdeaFragmentContentIdeaCalendarEventPublishedViewFragment,
  ContentIdeaFragmentContentIdeaContextMenuFragmentDoc,
  ContentIdeaFragmentContentIdeaPreviewFragmentDoc,
  Platform,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { useMemo } from 'react';
import { Event, EventProps } from 'react-big-calendar';
import { theme } from 'styles/theme';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_CALENDAR_EVENT_PUBLISHED_VIEW = gql`
  fragment ContentIdeaFragmentContentIdeaCalendarEventPublishedView on ContentIdeaModel {
    id
    name
    dueDate {
      id
      value {
        date
      }
    }
    status {
      id
      value {
        select {
          id
          label
          value
          bgcolor
        }
      }
    }
    platforms {
      id
      value {
        multiSelect {
          id
          label
          value
          bgcolor
        }
      }
    }
    pillars {
      id
      value {
        multiSelect {
          id
          label
          value
          bgcolor
        }
      }
    }
    contentIdeaPublishMetadata {
      id
      platform
      publishUrl
      socialAuthor {
        id
        platformHandle
      }
    }
    ...ContentIdeaFragmentContentIdeaPreview
    ...ContentIdeaFragmentContentIdeaContextMenu
  }
  ${ContentIdeaFragmentContentIdeaPreviewFragmentDoc}
  ${UserFragmentAvatarGroupFragmentDoc}
  ${ContentIdeaFragmentContentIdeaContextMenuFragmentDoc}
`;

export type ContentIdeaCalendarEventPublishedViewProps = EventProps & {
  onClick?: () => void;
};

export const ContentIdeaCalendarEventPublishedView = (
  props: ContentIdeaCalendarEventPublishedViewProps,
) => {
  const { event, onClick } = props;
  const { contentIdea } = event as Event & {
    contentIdea: ContentIdeaFragmentContentIdeaCalendarEventPublishedViewFragment;
  };

  const instagramPublishMetadata = useMemo(() => {
    return contentIdea.contentIdeaPublishMetadata.find(
      (metadata) => metadata.platform === Platform.Instagram,
    );
  }, [contentIdea]);
  const tiktokPublishMetadata = useMemo(() => {
    return contentIdea.contentIdeaPublishMetadata.find(
      (metadata) => metadata.platform === Platform.Tiktok,
    );
  }, [contentIdea]);
  const publishMetadataToRender = useMemo(() => {
    return [
      ...(tiktokPublishMetadata
        ? [
            {
              url: tiktokPublishMetadata.publishUrl,
              label: tiktokPublishMetadata.socialAuthor?.platformHandle,
              Icon: IconCustomTiktok,
            },
          ]
        : []),
      ...(instagramPublishMetadata
        ? [
            {
              url: instagramPublishMetadata.publishUrl,
              label: instagramPublishMetadata.socialAuthor?.platformHandle,
              Icon: IconBoldInstagram,
            },
          ]
        : []),
    ].filter((m) => Boolean(m.url) && Boolean(m.label));
  }, [instagramPublishMetadata, tiktokPublishMetadata]);

  return (
    <Box
      onClick={onClick}
      sx={{
        p: 1.5,
        borderRadius: 2,
        bgcolor:
          contentIdea.status.value?.select?.bgcolor ||
          theme.colors?.primary.black,
        color: theme.colors?.primary.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1.5,
        height: '100%',
      }}
      id={contentIdea.id}
    >
      <ContentIdeaPreview
        // NOTE: We need this to ensure the ContentIdeaPreview is re-rendered properly when contentIdea changes
        // react-big-calendar is doing some behind-the-scene memoization that causes the ContentIdeaPreview to not re-render
        key={contentIdea.id}
        contentIdea={contentIdea}
        readOnly
        sx={{
          flex: 1,
          width: '100%',
          aspectRatio: '7 / 8',
          borderRadius: 2,
          overflow: 'hidden',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 14,
          left: 18,
          padding: theme.spacing(0.5, 1),
          borderRadius: 1,
          background: `rgba(250, 243, 236, 0.20)`,
        }}
      >
        <Typography
          variant="headline-xxs"
          color={theme.colors?.primary.parchment}
          fontSize={11}
        >
          • Live
        </Typography>
      </Box>
      <Typography
        variant="headline-xxs"
        color={theme.colors?.primary.white}
        sx={{
          whiteSpace: 'normal',
          display: '-webkit-box',
          overflow: 'hidden',
          '-webkit-box-orient': 'vertical',
          ' -webkit-line-clamp': 3,
        }}
      >
        {contentIdea.name}
      </Typography>
      {contentIdea.pillars.value?.multiSelect &&
        contentIdea.pillars.value.multiSelect.length > 0 && (
          <Tooltip
            title={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {contentIdea.pillars.value.multiSelect.map((pillar) => (
                  <Typography variant="subhead-sm">{pillar.label}</Typography>
                ))}
              </Box>
            }
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1.5,
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Chip
                variant="filled-borderless-color-dodge"
                sx={{
                  bgcolor: '#FAF3EC33',
                  borderRadius: 1,
                  height: 'auto',
                  '.MuiChip-label': {
                    py: 0.5,
                    px: 1,
                    filter: 'none !important',
                  },
                  overflow: 'hidden',
                }}
                label={
                  <Typography
                    variant="headline-xs"
                    fontSize={11}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: '#F1F1F1',
                    }}
                  >
                    {contentIdea.pillars.value.multiSelect[0].label}
                  </Typography>
                }
              />
              {contentIdea.pillars.value.multiSelect.length > 1 && (
                <Box
                  sx={{
                    px: 1.5,
                    borderRadius: 1,
                    bgcolor: '#FAF3EC33',
                  }}
                >
                  <Typography variant="headline-xs" fontSize={11}>
                    +{contentIdea.pillars.value.multiSelect.length - 1}
                  </Typography>
                </Box>
              )}
            </Box>
          </Tooltip>
        )}
      {publishMetadataToRender.length > 0 ? (
        <>
          <Divider sx={{ borderColor: '#FAF3EC33', width: '100%' }} />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {publishMetadataToRender.map((metadata) => {
              return (
                <Box
                  key={metadata.url}
                  component="a"
                  href={metadata.url || ''}
                  target="_blank"
                  sx={{
                    borderRadius: 1,
                    bgcolor: '#FAF3EC33',
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    py: 0.5,
                    px: 1,
                    gap: 1,
                    '.link-icon': {
                      display: 'none',
                    },
                    '&:hover': {
                      '.link-icon': {
                        display: 'block',
                      },
                    },
                  }}
                >
                  <metadata.Icon size={12} />
                  <Typography
                    variant="headline-xxs"
                    sx={{
                      flex: 1,
                      fontSize: 11,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {metadata.label}
                  </Typography>
                  <IconLinearExport2 size={10} className="link-icon" />
                </Box>
              );
            })}
          </Box>
        </>
      ) : null}
    </Box>
  );
};
