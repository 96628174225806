import { gql } from '@apollo/client';
import {
  CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc,
  CreatorProfileFragmentSocialMediaListeningCreatorProfileFragmentDoc,
  Platform,
  useGetFollowingForUseCreatorsTopicTrackingForSocialListeningQuery,
  useGetTrackedCreatorsForTopicQuery,
  useSearchCreatorsForUseCreatorsTopicTrackingForSocialListeningQuery,
  useTrackCreatorForCreatorTrackingTopicForUseCreatorTopicTrackingMutation,
  useUntrackCreatorForCreatorTrackingTopicForUseCreatorTopicTrackingMutation,
} from 'graphql/generated';
import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation TrackCreatorForCreatorTrackingTopicForUseCreatorTopicTracking(
    $data: TrackCreatorInput!
  ) {
    trackCreatorForCreatorTrackingTopic(data: $data) {
      ...CreatorFragmentForUseCreatorDataForSocialListeningOnboarding
    }
  }
  ${CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UntrackCreatorForCreatorTrackingTopicForUseCreatorTopicTracking(
    $data: TrackCreatorInput!
  ) {
    untrackCreatorForCreatorTrackingTopic(data: $data) {
      ...CreatorFragmentForUseCreatorDataForSocialListeningOnboarding
    }
  }
  ${CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetTrackedCreatorsForTopic($data: TopicTrackedCreatorsInput!) {
    getTrackedCreatorsForTopic(data: $data) {
      id
      ...CreatorProfileFragmentSocialMediaListeningCreatorProfile
    }
  }
  ${CreatorProfileFragmentSocialMediaListeningCreatorProfileFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetFollowingForUseCreatorsTopicTrackingForSocialListening(
    $filters: SocialCreatorFilters!
  ) {
    getFollowing(filters: $filters) {
      id
      nickName
      platform
      platformId
      profilePictureUrl
      updatedAt
      handle
      followingCount
      followerCount
      createdAt
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query SearchCreatorsForUseCreatorsTopicTrackingForSocialListening(
    $query: String!
    $filters: SocialCreatorFilters!
  ) {
    searchCreators(query: $query, filters: $filters) {
      id
      nickName
      platform
      platformId
      profilePictureUrl
      updatedAt
      handle
      followingCount
      followerCount
      createdAt
    }
  }
`;

type Props = {
  searchCreator?: string;
  topicId?: string;
  platform: Platform;
  handle?: string;
};

export const useCreatorsTopicTrackingForSocialListening = ({
  searchCreator,
  topicId,
  platform,
  handle,
}: Props) => {
  // This is to show a loading spinner when the user clicks on the follow button since the API takes a while to respond while connecting to rapidAPI
  const [updatingCreatorPlatformIds, setUpdatingCreatorPlatformIds] = useState<
    string[]
  >([]);

  const [trackCreator] =
    useTrackCreatorForCreatorTrackingTopicForUseCreatorTopicTrackingMutation();
  const [untrackCreator] =
    useUntrackCreatorForCreatorTrackingTopicForUseCreatorTopicTrackingMutation();

  const { data: followingCreatorsData, loading: loadingFollowingCreators } =
    useGetFollowingForUseCreatorsTopicTrackingForSocialListeningQuery({
      variables: {
        filters: {
          platform,
          handle,
        },
      },
      skip: !handle,
    });

  const { data: searchCreatorsData, loading: searchingCreators } =
    useSearchCreatorsForUseCreatorsTopicTrackingForSocialListeningQuery({
      variables: {
        query: searchCreator || '',
        filters: {
          platform,
        },
      },
      skip: !searchCreator,
    });

  const {
    data: trackedCreatorsData,
    loading,
    refetch: refetchTrackedCreatorsData,
  } = useGetTrackedCreatorsForTopicQuery({
    variables: {
      data: {
        topicId: topicId || '',
        platforms: [platform],
      },
    },
    skip: !topicId,
  });

  const trackedCreators = trackedCreatorsData?.getTrackedCreatorsForTopic || [];

  const handleTrackCreator = async (
    creatorPlatformId: string,
    topicId: string,
    handle: string,
  ) => {
    setUpdatingCreatorPlatformIds([
      ...updatingCreatorPlatformIds,
      creatorPlatformId,
    ]);

    return trackCreator({
      variables: {
        data: {
          topicId,
          data: {
            platformId: creatorPlatformId,
            platform,
            handle,
          },
        },
      },
      onError: () => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
      },
      onCompleted: () => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
        refetchTrackedCreatorsData();
      },
    });
  };

  const handleUntrackCreator = async (
    creatorPlatformId: string,
    topicId: string,
    handle: string,
  ) => {
    setUpdatingCreatorPlatformIds([
      ...updatingCreatorPlatformIds,
      creatorPlatformId,
    ]);

    return untrackCreator({
      variables: {
        data: {
          topicId,
          data: {
            platformId: creatorPlatformId,
            platform,
            handle,
          },
        },
      },
      onError: () => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
      },
      onCompleted: () => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
        refetchTrackedCreatorsData();
      },
    });
  };

  return {
    data: {
      trackedCreators: {
        data: trackedCreators,
        loading,
      },
      followingCreators: {
        data: followingCreatorsData?.getFollowing || [],
        loading: loadingFollowingCreators,
      },
      searchedCreators: {
        data: searchCreatorsData?.searchCreators || [],
        loading: searchingCreators,
      },

      inProgress: {
        updatingCreatorPlatformIds,
      },
    },

    actions: {
      handleTrackCreator,
      handleUntrackCreator,
    },
  };
};
