import { gql } from '@apollo/client';
import { Box, Card, Typography } from '@mui/material';
import { toolTipStyles } from 'components/common/Tooltip';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningFilterByPlatformsButton,
} from 'features/socialMediaListening/components';
import {
  ChartDataPointWithDateRange,
  useGraphDataMakerNew,
} from 'features/socialMediaListening/hooks/useGraphDataMakerNew';
import {
  BrandMetricsFilters,
  BrandMetricsResponseFragmentMetricsChartFragment,
  Platform,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import moment from 'moment';
import { ReactNode } from 'react';
import { Location, useLocation } from 'react-router-dom';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { useImmer } from 'use-immer';

export const BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART = gql`
  fragment BrandMetricsResponseFragmentMetricsChart on BrandMetricsResponse {
    total
    chartDataPoints {
      X
      Y
    }
  }
`;

const CustomTooltip = ({
  active,
  payload,
  label,
  platforms = [],
}: TooltipProps<ValueType, NameType> & {
  platforms: Platform[];
}) => {
  const location = useLocation();
  const { backgroundLocation } = (location.state || {}) as {
    backgroundLocation?: Location;
  };
  const navigate = useGuardNavigate();

  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          ...toolTipStyles,
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          pointerEvents: 'auto',
          cursor: 'pointer',
        }}
        onClick={() => {
          const dateRange = payload[0]?.payload as ChartDataPointWithDateRange;
          const startDate = dateRange.dateRange[0];
          const endDate = dateRange.dateRange[1];

          navigate(
            PlotRoutes.socialListeningAnalyticsOutboundInteractions({
              platforms,
              startDate,
              endDate,
            }),
            { state: { backgroundLocation: backgroundLocation || location } },
          );
        }}
      >
        <Typography variant="subhead-sm">{label}</Typography>
        <Typography variant="headline-sm">{`${payload[0].value} interactions`}</Typography>
      </Box>
    );
  }

  return null;
};

export type MetricsChartProps = {
  labels: {
    title: string;
    definition: string;
    tooltipTitle: string;
  };
  mainColor?: string;
  highlightColor?: string;
  metricsData: BrandMetricsResponseFragmentMetricsChartFragment;
  lastUpdatedAt: string;
  filters: BrandMetricsFilters;
  setFilters: (filters: BrandMetricsFilters) => void;
  emptyText?: ReactNode;
};

export const MetricsChart = (props: MetricsChartProps) => {
  const {
    labels,
    mainColor,
    highlightColor,
    lastUpdatedAt,
    metricsData,
    filters,
    setFilters,
    emptyText = 'No data.',
  } = props;

  const { chartData } = useGraphDataMakerNew({
    // Sort by asc time
    dailyChartData: metricsData.chartDataPoints,
    dateRange: filters.dateRange || [],
  });

  const [tooltipPos, setTooltipPos] = useImmer<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const handleMouseMove = (chartState: CategoricalChartState) => {
    if (chartState.isTooltipActive) {
      const { chartX, chartY } = chartState;
      if (chartX && chartY) {
        setTooltipPos((draft) => {
          draft.x = chartX - 20;
          draft.y = chartY - 15;
        });
      }
    } else {
      setTooltipPos((draft) => {
        draft.x = 0;
        draft.y = 0;
      });
    }
  };

  const isEmpty = chartData.every((p) => !p.Y);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Card
        sx={{
          flex: 1,
          borderRadius: 5,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 4,
            px: 6,
            pt: 6,
            pb: 3,
            borderBottom: `1px solid ${theme.colors?.utility[300]}`,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={1}
          >
            <Typography variant="headline-md" fontSize={theme.spacing(5)}>
              {labels.title}
            </Typography>
            <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
              {labels.definition}
            </Typography>
            <Typography variant="headline-lg" fontSize={theme.spacing(8)}>
              {metricsData.total}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <SocialMediaListeningFilterByPlatformsButton
              selectedPlatforms={filters.platforms || []}
              onChange={(platforms) => {
                setFilters({ ...filters, platforms });
              }}
            />
            <SocialMediaListeningFilterByDateRangeButton
              selectedDateRange={filters.dateRange || [null, null]}
              onChange={(dateRange) => {
                setFilters({ ...filters, dateRange });
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            p: 6,
          }}
        >
          {isEmpty ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 284,
                borderRadius: 3,
                bgcolor: theme.colors?.utility[275],
              }}
            >
              <Typography
                variant="headline-sm"
                textAlign="center"
                color={theme.colors?.utility[700]}
              >
                {emptyText}
              </Typography>
            </Box>
          ) : (
            <ResponsiveContainer width="100%" height={332}>
              <AreaChart
                data={chartData}
                onMouseMove={(chartState) => handleMouseMove(chartState)}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="X" />
                <YAxis
                  domain={[
                    0,
                    Math.max(...((chartData || []).map((x) => x.Y) || 0)),
                  ]}
                />
                <RechartsTooltip
                  content={
                    <CustomTooltip platforms={filters.platforms || []} />
                  }
                  position={{ x: tooltipPos.x, y: tooltipPos.y }}
                />
                <Area
                  type="monotone"
                  dataKey="Y"
                  stroke={mainColor}
                  strokeWidth={3}
                  fill={highlightColor}
                />
                <CartesianGrid
                  vertical={false}
                  stroke={theme.colors?.utility[400]}
                  strokeDasharray={5}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Card>
      <Typography
        variant="subhead-xl"
        color={theme.colors?.utility[600]}
        ml="auto"
      >
        Last updated {moment(lastUpdatedAt).fromNow()}
      </Typography>
    </Box>
  );
};
