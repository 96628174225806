import { Box, Checkbox, TextField, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconOutlineHashtag } from 'components/icons/components/outline/IconOutlineHashtag';
import { useMemo, useState } from 'react';
import {
  CollectionFragmentCollectionMenuItemViewFragmentDoc,
  CollectionFragmentContentCalendarFilterByCollectionsFragment,
  CollectionFragmentContentCalendarFilterByCollectionsFragmentDoc,
  useGetCollectionsSmartSearchForContentCalendarFilterByCollectionsQuery,
} from 'graphql/generated';
import { CollectionMenuItemView } from 'features/collection';
import { gql } from '@apollo/client';
import { debounce } from 'lodash';
import { StyledIconContainer, StyledMenuItemContainer } from '../../styles';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentContentCalendarFilterByCollections on CollectionModel {
    id
    ...CollectionFragmentCollectionMenuItemView
  }
  ${CollectionFragmentCollectionMenuItemViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCollectionsSmartSearchForContentCalendarFilterByCollections(
    $filters: CollectionFilters!
    $take: Int
    $sortType: SortType
    $after: String
  ) {
    collectionsSmartSearch(
      filters: $filters
      take: $take
      sortType: $sortType
      after: $after
    ) {
      data {
        item {
          ...CollectionFragmentContentCalendarFilterByCollections
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CollectionFragmentContentCalendarFilterByCollectionsFragmentDoc}
`;

type ContentCalendarFilterByCollectionsProps = {
  /**
   * We have to use the full collection object here, because
   * the list of collections is paginated, and we need to keep the selected collections
   * on top of the list.
   */
  selectedCollections: CollectionFragmentContentCalendarFilterByCollectionsFragment[];
  onSelectCollection: (
    selectedCollection: CollectionFragmentContentCalendarFilterByCollectionsFragment,
  ) => void;
};

export const ContentCalendarFilterByCollections = (
  props: ContentCalendarFilterByCollectionsProps,
) => {
  const { selectedCollections, onSelectCollection } = props;

  const [shouldLoadData, setShouldLoadData] = useState(false);

  const [query, setQuery] = useState('');
  const debouncedSetQuery = useMemo(
    () =>
      debounce((q: string) => {
        setQuery(q);
      }, 300),
    [],
  );

  const { data, loading, fetchMore } =
    useGetCollectionsSmartSearchForContentCalendarFilterByCollectionsQuery({
      variables: {
        filters: {
          query,
        },
        take: 4,
      },
      fetchPolicy: 'cache-and-network',
      skip: !shouldLoadData,
    });

  // By default, this should only return parent-level collections
  const collections = useMemo(() => {
    return [
      ...selectedCollections,
      ...(data?.collectionsSmartSearch.data
        .map((c) => c.item)
        .filter((c) => !selectedCollections.some((s) => s.id === c.id)) || []),
    ];
  }, [selectedCollections, data]);

  const fetchMoreCollections = () => {
    if (!data?.collectionsSmartSearch.pageInfo.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        filters: {
          query,
        },
        take: 10,
        after: data?.collectionsSmartSearch.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          collectionsSmartSearch: {
            ...fetchMoreResult.collectionsSmartSearch,
            data: [
              ...prev.collectionsSmartSearch.data,
              ...fetchMoreResult.collectionsSmartSearch.data.filter(
                (x) =>
                  !prev.collectionsSmartSearch.data.some(
                    (y) => y.item.id === x.item.id,
                  ),
              ),
            ],
          },
        };
      },
    });
  };

  return (
    <DropDownMoreMenuItem
      disableRipple
      renderLabelAsOption
      onCloseCallback={() => setShouldLoadData(false)}
      onOpenCallback={() => setShouldLoadData(true)}
      label={
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconOutlineHashtag size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">
            Collections{' '}
            {selectedCollections.length > 0 && (
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[600]}
              >
                ({selectedCollections.length})
              </Typography>
            )}
          </Typography>
        </StyledMenuItemContainer>
      }
      loadMoreItems={fetchMoreCollections}
      renderDropDownOptionSearchInput={() => (
        <TextField
          autoFocus
          placeholder="Search"
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            debouncedSetQuery(e.currentTarget.value);
          }}
          sx={{
            minWidth: `220px`,
            mb: 2,
            px: 1,

            '& .MuiOutlinedInput-notchedOutline': {
              border: `0 !important`,
              outline: `0 !important`,
            },

            '& .MuiInputBase-root': {
              borderRadius: 8,
              padding: `12px 20px !important`,
              boxShadow: '0 0 2px rgba(0, 0, 0, 0.1)',

              input: {
                padding: `0 !important`,
                fontSize: `13px`,
              },
            },
          }}
        />
      )}
      dropDownOptions={
        loading
          ? []
          : collections.map((collection) => {
              return {
                label: (
                  <CollectionMenuItemView
                    key={collection.id}
                    collection={collection}
                    selectIconPosition="start"
                    renderSelectIcon={(isSelected) => (
                      <Checkbox
                        checked={isSelected}
                        disableRipple
                        sx={{
                          p: 0,
                          width: theme.spacing(4),
                          height: theme.spacing(4),
                          color: theme.colors?.primary.black,
                          '&.Mui-checked': {
                            color: theme.colors?.primary.black,
                          },
                        }}
                      />
                    )}
                    onClick={(selectedCollection) => {
                      onSelectCollection(selectedCollection);
                    }}
                    selectedCollectionIds={selectedCollections.map((c) => c.id)}
                    shouldShowBreadcrumbsForRoot
                    componentProps={{
                      menuItems: {
                        sx: {
                          width: theme.spacing(60),
                        },
                      },
                      listItem: {
                        sx: {
                          maxWidth: 240,
                          // Prevent event cancelation inside this component (ContentIdeaListView)
                          // FIXME: We really need to fix the event cancelation issue with ContentIdeaListView
                          pointerEvents: 'none',
                        },
                      },
                      menu: {
                        sx: {
                          '& .MuiPaper-root': {
                            boxShadow:
                              'rgba(24, 39, 75, 0.12) 0px 12px 42px -4px, rgba(24, 39, 75, 0.12) 0px 8px 18px -6px !important',
                            bgcolor: 'rgba(255, 255, 255, 0.9)',
                            backdropFilter: 'blur(24px)',
                            width: 310,
                          },
                        },
                      },
                      autoCompleteMenu: {
                        filterOptions: undefined,
                        noOptionsText: (
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="subhead-lg"
                              color={theme.colors?.utility[600]}
                              textAlign="center"
                            >
                              No collections found
                            </Typography>
                          </Box>
                        ),
                      },
                    }}
                  />
                ),
                isChecked: selectedCollections.some(
                  (s) => s.id === collection.id,
                ),
              };
            })
      }
      componentsProps={{
        dropDownOptions: {
          container: {
            sx: {
              gap: 0,
              maxHeight: theme.spacing(57),
              overflow: 'auto',
            },
          },
          item: {
            sx: {
              p: 0,
            },
          },
        },
      }}
    />
  );
};
