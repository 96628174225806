import { Box, Typography } from '@mui/material';
import { MetricsTimeRange } from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  currentValue: string;
  options: string[];
  onChange: (value: string) => void;
};

const TimeRangeLabels = {
  [MetricsTimeRange.OneWeek]: '1w',
  [MetricsTimeRange.OneMonth]: '1m',
  [MetricsTimeRange.ThreeMonths]: '3m',
  [MetricsTimeRange.SixMonths]: '6m',
  [MetricsTimeRange.OneYear]: '1y',
  [MetricsTimeRange.AllTime]: 'All',
};

export const TimeDurationSelector = ({
  options,
  currentValue,
  onChange,
}: Props) => {
  const [currentSelection, setCurrentSelection] = useState(currentValue);

  useEffect(() => {
    setCurrentSelection(currentValue);
  }, [currentValue]);

  return (
    <Box
      display="flex"
      width="fit-content"
      gap={3}
      borderRadius={25}
      p={1}
      sx={{ backgroundColor: theme.colors?.utility[300] }}
    >
      {options.map((option) => (
        <Box
          onClick={() => {
            setCurrentSelection(option);
            onChange(option);
          }}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            p: theme.spacing(3),
            borderRadius: 25,
            ...(option === currentSelection
              ? {
                  boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
                  backgroundColor: theme.colors?.primary.white,
                }
              : {}),
          }}
        >
          <Typography variant="headline-sm">
            {TimeRangeLabels[option]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
