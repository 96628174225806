import { Masonry } from '@mui/lab';
import {
  CollectionPostDndCollectionCard,
  CollectionPostDndHoveringActionPopup,
  CollectionPostDndPostCard,
} from 'features/collectionPostDnd/components';
import { useSelectMode } from 'features/collectionPostDnd/hooks';
import { useNavigatePostSearchParams } from 'features/post/hooks/useNavigateSearchParams';
import {
  CollectionFragmentCollectionPostDndCollectionCardFragment,
  PostFragmentCollectionPostDndPostCardFragment,
} from 'graphql/generated';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CollectionPostDndContainer } from '../dndContainer';

export type CollectionPostMasonryViewProps = {
  posts: PostFragmentCollectionPostDndPostCardFragment[];
  collections: CollectionFragmentCollectionPostDndCollectionCardFragment[];
  smartSearchPostIds?: string[];
  layout?: 'default' | 'compact';
  onPostClick?: (
    postId: string,
    position: number,
    totalSearchResults: number,
  ) => void;
  onCollectionClick?: (
    collectionId: string,
    position: number,
    totalSearchResults: number,
  ) => void;
  onPostsSelected?: (selectedPostIds: string[]) => void;
  onCollectionsSelected?: (selectedCollectionIds: string[]) => void;
};

export const CollectionPostMasonryView = (
  props: CollectionPostMasonryViewProps,
) => {
  const {
    posts,
    collections,
    smartSearchPostIds = [],
    layout = 'default',
    onPostClick,
    onCollectionClick,
    onPostsSelected,
    onCollectionsSelected,
  } = props;

  const {
    selectedPostIds,
    selectedCollectionIds,
    isSelectModeActive,
    onSelectPost,
    onSelectCollection,
  } = useSelectMode({
    collections,
    posts,
  });

  // Expose list of selected post ids to parent component
  useEffect(() => {
    onPostsSelected?.(selectedPostIds);
  }, [selectedPostIds]); // eslint-disable-line

  // Expose list of selected post ids to parent component
  useEffect(() => {
    onCollectionsSelected?.(selectedCollectionIds);
  }, [selectedCollectionIds]); // eslint-disable-line

  const { collectionId: currentCollectionId = '' } = useParams();
  const { searchParams } = useNavigatePostSearchParams({
    collectionId: currentCollectionId,
  });

  return (
    <CollectionPostDndContainer
      collections={collections}
      posts={posts}
      pinnedEntities={[]}
    >
      <Masonry
        columns={{
          xs: 1,
          sm: 2,
          md: layout === 'compact' ? 2 : 3,
          lg: layout === 'compact' ? 2 : 4,
          xl: layout === 'compact' ? 3 : 5,
        }}
        spacing={4}
        sx={{
          pointerEvents: 'auto',
          opacity: 1,
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        {collections.map((collection, index) => {
          const selected = selectedCollectionIds?.includes(collection.id);

          return (
            <CollectionPostDndCollectionCard
              key={collection.id}
              collection={collection}
              index={index}
              selectable
              selected={selected}
              onClick={(e) => {
                onCollectionClick?.(
                  collection.id,
                  index + 1,
                  collections.length,
                );
                e.preventDefault();
                e.stopPropagation();
                onSelectCollection(collection, e.shiftKey);
              }}
            />
          );
        })}

        {posts.map((post, index) => {
          const selected = selectedPostIds?.includes(post.id);

          return (
            <CollectionPostDndPostCard
              key={post.id}
              post={post}
              searchParams={searchParams}
              index={index + collections.length}
              selectable
              isDragDisabled
              selected={selected}
              isSmartSearch={smartSearchPostIds?.includes(post.id)}
              isSelectModeActive={isSelectModeActive}
              onClick={(e) => {
                onPostClick?.(post.id, index + 1, posts.length);
                if (!isSelectModeActive) {
                  return;
                }
                e.preventDefault();
                e.stopPropagation();
                onSelectPost(post, e.shiftKey);
              }}
            />
          );
        })}
      </Masonry>

      {selectedCollectionIds.length === 0 && (
        <CollectionPostDndHoveringActionPopup
          selectedPosts={posts.filter(({ id }) => selectedPostIds.includes(id))}
        />
      )}
    </CollectionPostDndContainer>
  );
};
