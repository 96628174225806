import { gql } from '@apollo/client';
import { Grid } from '@mui/material';
import { DEFAULT_CJB_TAKE_SMALL } from 'constants/pagination';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  AllPostsCollectionCardView,
  CollectionCardView,
  CollectionCardViewSkeleton,
  ContentCalendarCollectionCardView,
  MyPostCollectionCardView,
  SavedCollectionCardView,
} from 'features/collection';
import {
  CUSTOM_COLLECTION,
  customCollectionLabels,
} from 'features/collection/constants';
import {
  CollectionFragmentCollectionCardViewFragmentDoc,
  CollectionModel,
  PlotFeature,
  useGetCollectionForCjbWindowCollectionListSectionQuery,
  useGetCollectionsSmartSearchForGridViewAllCollectionsSectionQuery,
} from 'graphql/generated';
import InfiniteScroll from 'react-infinite-scroller';

// eslint-disable-next-line
gql`
  query GetCollectionForCJBWindowCollectionListSection($id: String!) {
    collection(id: $id) {
      id
      ...CollectionFragmentCollectionCardView
    }
  }
  ${CollectionFragmentCollectionCardViewFragmentDoc}
`;

type CollectionListSectionProps = {
  hardcodedContentIdeaCollectionId?: string;
  onCollectionClick: (collection: Pick<CollectionModel, 'id' | 'name'>) => void;
};

export const CollectionListSection = (props: CollectionListSectionProps) => {
  const { hardcodedContentIdeaCollectionId = '', onCollectionClick } = props;

  // NOTE: Reusing query from Juicebox page
  const {
    data: collectionsData,
    loading: isCollectionDataLoading,
    fetchMore,
  } = useGetCollectionsSmartSearchForGridViewAllCollectionsSectionQuery({
    variables: {
      filters: {},
    },
    fetchPolicy: 'cache-and-network',
  });
  const collections =
    collectionsData?.collectionsSmartSearch.data.map((h) => h.item) || [];
  const isFirstLoading = isCollectionDataLoading && !collections?.length;

  const { isFeatureEnabled } = useFeatureFlagContext();

  /**
   * Custom logic to support POST_SELECT_FROM_CJB command from a content idea.
   */
  const { data: hardcodedContentIdeaCollectionData } =
    useGetCollectionForCjbWindowCollectionListSectionQuery({
      variables: {
        id: hardcodedContentIdeaCollectionId,
      },
      skip: !hardcodedContentIdeaCollectionId,
    });
  const hardcodedContentIdeaCollection =
    hardcodedContentIdeaCollectionData?.collection;

  return (
    <InfiniteScroll
      loadMore={() => {
        fetchMore({
          variables: {
            after: collectionsData?.collectionsSmartSearch.pageInfo.endCursor,
            take: DEFAULT_CJB_TAKE_SMALL,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              collectionsSmartSearch: {
                ...fetchMoreResult.collectionsSmartSearch,
                data: [
                  ...prev.collectionsSmartSearch.data,
                  ...fetchMoreResult.collectionsSmartSearch.data,
                ],
              },
            };
          },
        });
      }}
      hasMore={collectionsData?.collectionsSmartSearch.pageInfo.hasNextPage}
      threshold={512}
      useWindow={false}
      initialLoad={false}
      getScrollParent={() =>
        document.querySelector(
          '.collection-cjb-window-view-scrollable-container',
        )
      }
    >
      <Grid container spacing={6} pb={6}>
        {isFeatureEnabled(PlotFeature.ContentCalendar) &&
          hardcodedContentIdeaCollection && (
            <Grid item xs={12} md={6} lg={3}>
              <CollectionCardView
                collection={hardcodedContentIdeaCollection}
                componentsProps={{
                  disableContextMenu: true,
                  link: false,
                }}
                onClick={() =>
                  onCollectionClick?.(hardcodedContentIdeaCollection)
                }
              />
            </Grid>
          )}
        <Grid item xs={12} md={6} lg={3}>
          <AllPostsCollectionCardView
            componentsProps={{
              link: false,
            }}
            onClick={() =>
              onCollectionClick?.({
                id: CUSTOM_COLLECTION.ALL_POSTS,
                name: customCollectionLabels[CUSTOM_COLLECTION.ALL_POSTS],
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MyPostCollectionCardView
            componentsProps={{
              link: false,
            }}
            onClick={() =>
              onCollectionClick?.({
                id: CUSTOM_COLLECTION.MY_POSTS,
                name: customCollectionLabels[CUSTOM_COLLECTION.MY_POSTS],
              })
            }
          />
        </Grid>
        {isFeatureEnabled(PlotFeature.ContentCalendar) && (
          <Grid item xs={12} md={6} lg={3}>
            <ContentCalendarCollectionCardView
              componentsProps={{
                link: false,
              }}
              onClick={() =>
                onCollectionClick?.({
                  id: CUSTOM_COLLECTION.CONTENT_CALENDAR,
                  name: customCollectionLabels[
                    CUSTOM_COLLECTION.CONTENT_CALENDAR
                  ],
                })
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={3}>
          <SavedCollectionCardView
            componentsProps={{
              link: false,
            }}
            onClick={() =>
              onCollectionClick?.({
                id: CUSTOM_COLLECTION.SAVED,
                name: customCollectionLabels[CUSTOM_COLLECTION.SAVED],
              })
            }
          />
        </Grid>
        {isFirstLoading
          ? [...Array(4)].map((_, i) => (
              <Grid key={i} item xs={12} md={6} lg={3}>
                <CollectionCardViewSkeleton />
              </Grid>
            ))
          : collections.map((collection) => (
              <Grid key={collection.id} item xs={12} md={6} lg={3}>
                <CollectionCardView
                  collection={collection}
                  componentsProps={{
                    disableContextMenu: true,
                    link: false,
                  }}
                  onClick={() => onCollectionClick?.(collection)}
                />
              </Grid>
            ))}
      </Grid>
    </InfiniteScroll>
  );
};
