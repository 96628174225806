import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import { typography } from 'components/common/Typography/styles';
import {
  CUSTOM_COLLECTION,
  CollectionPin,
  useIsCollectionSavedToContentCalendar,
} from 'features/collection';
import {
  CollectionPermissionSummaryView,
  useCollectionPermissionDialogView,
} from 'features/collectionPermission';
import {
  CollectionContextMenu,
  CollectionMultiPostPreview,
  CollectionMultiPostPreviewProps,
  CollectionName,
  CollectionSummary,
  CollectionThumbnail,
} from 'features/collection/components';
import { useMoveToAnotherCollectionModal } from 'features/collection/hooks/useMoveToAnotherCollectionModal';
import { usePinnedEntityMutations } from 'features/pinnedEntity';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentCollectionCardViewFragment,
  CollectionFragmentCollectionContextMenuFragmentDoc,
  CollectionFragmentCollectionMultiPostPreviewFragmentDoc,
  CollectionFragmentCollectionNameFragmentDoc,
  CollectionFragmentCollectionPermissionSummaryFragmentDoc,
  CollectionFragmentCollectionPinFragmentDoc,
  CollectionFragmentCollectionSavedToContentCalendarFragmentDoc,
  CollectionFragmentCollectionSummaryFragmentDoc,
  CollectionFragmentCollectionThumbnailFragmentDoc,
} from 'graphql/generated';
import { useRef, useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionCardView on CollectionModel {
    id
    ...CollectionFragmentCollectionName
    ...CollectionFragmentCollectionSummary
    ...CollectionFragmentCollectionMultiPostPreview
    ...CollectionFragmentCollectionContextMenu
    ...CollectionFragmentCollectionPermissionSummary
    ...CollectionFragmentCollectionThumbnail
    ...CollectionFragmentCollectionPin
    ...CollectionFragmentCollectionBreadcrumbs
    ...CollectionFragmentCollectionSavedToContentCalendar
  }
  ${CollectionFragmentCollectionNameFragmentDoc}
  ${CollectionFragmentCollectionSummaryFragmentDoc}
  ${CollectionFragmentCollectionMultiPostPreviewFragmentDoc}
  ${CollectionFragmentCollectionContextMenuFragmentDoc}
  ${CollectionFragmentCollectionPermissionSummaryFragmentDoc}
  ${CollectionFragmentCollectionThumbnailFragmentDoc}
  ${CollectionFragmentCollectionPinFragmentDoc}
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
  ${CollectionFragmentCollectionSavedToContentCalendarFragmentDoc}
`;

export type CollectionCardViewProps = {
  collection: CollectionFragmentCollectionCardViewFragment;
  context?: 'parent' | 'root';
  sx?: SxProps;
  componentsProps?: {
    disableContextMenu?: boolean;
    multiPostPreview?: Pick<CollectionMultiPostPreviewProps, 'variant'>;
    link?: Partial<LinkProps> | false;
    permission?: false;
  };
  onClick?: () => void;
};

export const CollectionCardView = (props: CollectionCardViewProps) => {
  const {
    collection,
    context = 'parent',
    sx,
    componentsProps = {},
    onClick,
  } = props;

  const location = useLocation();

  const nameRef = useRef<HTMLTextAreaElement | null>(null);
  const [isRenaming, setIsRenaming] = useState(false);

  const { calendarIcon } = useIsCollectionSavedToContentCalendar({
    collection,
  });

  const {
    openDialog: showCollectionPermissionsDialog,
    renderContent: renderCollectionPermissionsDialog,
  } = useCollectionPermissionDialogView({
    collectionId: collection.id,
  });

  const { renderMoveToAnotherCollectionModal, showMoveCollection } =
    useMoveToAnotherCollectionModal({
      collectionId: collection.id,
    });

  const {
    onPinCollectionToParent,
    onUnpinCollectionFromParent,
    onPinCollectionToRoot,
    renderCollectionPinnedToRootFeedback,
    onUnpinCollectionFromRoot,
  } = usePinnedEntityMutations();

  return (
    <>
      <RightClickContextMenuWrapper
        disabled={componentsProps.disableContextMenu}
        sx={{
          p: 3,
          borderRadius: 3,
          bgcolor: collection.isDisabled
            ? theme.colors?.utility[275]
            : theme.colors?.primary.white,
          opacity: collection.isDisabled ? 0.8 : 1,
          ...sx,
        }}
        renderMenu={(props) => (
          <CollectionContextMenu
            key={`${collection.id}${collection.previewPost?.id || ''}`}
            collection={collection}
            renderButton={false}
            onRenameCollection={() => {
              setIsRenaming(true);
              setTimeout(() => {
                nameRef.current?.focus();
                nameRef.current?.select();
              });
            }}
            onMoveCollection={showMoveCollection}
            onPinCollectionToParent={onPinCollectionToParent}
            onUnpinCollectionFromParent={onUnpinCollectionFromParent}
            onPinCollectionToRoot={(collectionId) => {
              onPinCollectionToRoot(collectionId, context);
            }}
            onUnpinCollectionFromRoot={onUnpinCollectionFromRoot}
            onShareCollection={() => showCollectionPermissionsDialog()}
            {...props}
          />
        )}
        onClick={onClick}
      >
        <Link
          to={PlotRoutes.collection(collection.id)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
          state={location.state}
          {...componentsProps.link}
          {...(componentsProps.link === false
            ? { onClick: (e) => e.preventDefault() }
            : {})}
        >
          <Box mb={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              gap={1}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 1,
                }}
              >
                {calendarIcon}
                <CollectionName
                  ref={nameRef}
                  collection={collection}
                  style={{
                    ...typography['headline-sm'],
                    wordBreak: 'break-word',
                  }}
                  readOnly={!isRenaming}
                  onBlur={() => setIsRenaming(false)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </Box>

              {componentsProps.permission === false ? null : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                    maxWidth: '50%',
                  }}
                >
                  <CollectionPermissionSummaryView collection={collection} />
                  <CollectionPin
                    collection={collection}
                    pinContext={context}
                    componentProps={{
                      icon: { size: 24 },
                    }}
                  />
                </Box>
              )}
            </Box>
            <CollectionSummary collection={collection} />
          </Box>
          <Box sx={{ flex: 1, overflow: 'hidden' }}>
            {(Object.values(CUSTOM_COLLECTION) as string[]).includes(
              collection.id,
            ) ? (
              <CollectionMultiPostPreview
                collection={collection}
                sx={{
                  height: '100%',
                }}
                {...componentsProps.multiPostPreview}
              />
            ) : (
              <CollectionThumbnail collection={collection} />
            )}
          </Box>
        </Link>
      </RightClickContextMenuWrapper>

      {renderMoveToAnotherCollectionModal()}
      {renderCollectionPermissionsDialog()}
      {renderCollectionPinnedToRootFeedback(collection)}
    </>
  );
};
