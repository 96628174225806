import { gql } from '@apollo/client';
import { useClipboard } from '@dwarvesf/react-hooks';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { Iframely } from 'components/common/Iframely';
import { Image } from 'components/common/Image';
import { toast } from 'components/common/Toast';
import { IconBoldMusicSquare } from 'components/icons/components/bold/IconBoldMusicSquare';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import {
  Platform,
  TrendAudioFragmentTrendingAudioPreviewFragment,
  UrlMetadataFragmentIframelyFragmentDoc,
} from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

export const TREND_AUDIO_FRAGMENT_TRENDING_AUDIO_PREVIEW = gql`
  fragment TrendAudioFragmentTrendingAudioPreview on TrendAudioModel {
    id
    coverUrl
    duration
    totalVideoCount
    platform
    title
    authorUsername
    urlMetadata {
      ...URLMetadataFragmentIframely
    }
    author {
      id
      name
      avatarUrl
    }
  }
  ${UrlMetadataFragmentIframelyFragmentDoc}
`;

interface Props {
  trendAudio: TrendAudioFragmentTrendingAudioPreviewFragment;
  variant?: 'default' | 'detail';
}

export const TrendingAudioPreview = ({
  trendAudio,
  variant = 'default',
}: Props) => {
  const { onCopy } = useClipboard(trendAudio?.urlMetadata.url);

  const onCopyLink = () => {
    onCopy();
    toast({
      message: 'Link Copied',
      icon: <IconBoldTickCircle color={theme.colors?.primary.parchment} />,
      shouldShowCloseButton: false,
      position: 'bottom-center',
      sx: {
        bgcolor: theme.colors?.primary.black,
        padding: theme.spacing(1.5, 3, 1),
        borderRadius: 25,
        span: {
          color: theme.colors?.primary.parchment,
          ...theme.typography['headline-sm'],
        },
      },
    });
  };

  const Icon =
    trendAudio?.platform === Platform.Tiktok
      ? IconCustomTiktok
      : IconCustomInstagram;

  const renderAudioActionButton = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 3,
        }}
      >
        <IconButton
          sx={{
            padding: theme.spacing(2.5),
            bgcolor: `rgba(35, 6, 3, 0.10)`,
          }}
          onClick={onCopyLink}
        >
          <IconOutlineLink2 size={16} color={theme.colors?.primary.black} />
        </IconButton>
        <a href={trendAudio.urlMetadata.url} target="_blank" rel="noreferrer">
          <IconButton
            sx={{
              padding: theme.spacing(2.5),
              bgcolor: `rgba(35, 6, 3, 0.10)`,
            }}
          >
            <IconLinearExport2 size={16} color={theme.colors?.primary.black} />
          </IconButton>
        </a>
      </Box>
    );
  }, [trendAudio]); // eslint-disable-line

  if (variant === 'detail') {
    if (!trendAudio.coverUrl) return null;
    return (
      <Box
        sx={{
          borderRadius: 4,
          background: `rgba(255, 255, 255, 0.60)`,
          padding: theme.spacing(4),
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          gap: 2,
          maxHeight: theme.spacing(19),
        }}
      >
        <Box display="flex" gap={3}>
          <Avatar
            src={trendAudio.author?.avatarUrl || ''}
            sx={{
              width: theme.spacing(8),
              height: theme.spacing(8),
              boxShadow:
                '0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)',
            }}
            variant="circular"
          />
          <Box sx={{ display: 'grid', gap: 1 }}>
            <Typography variant="headline-sm">
              {trendAudio.author?.name}
            </Typography>
            <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
              {trendAudio?.authorUsername
                ? `@${trendAudio?.authorUsername}`
                : ''}
            </Typography>
          </Box>
        </Box>
        {renderAudioActionButton}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        height: '100%',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box
          sx={{
            minHeight: {
              xs: theme.spacing(115),
              sm: theme.spacing(115),
              md: theme.spacing(115),
              lg: theme.spacing(127),
              xl: theme.spacing(138),
            },
            maxHeight: theme.spacing(140),
            borderRadius: theme.spacing(4),
            minWidth: {
              xs: 'fit-content',
              sm: 'fit-content',
              md: 'fit-content',
              lg: theme.spacing(82),
            },
            maxWidth: theme.spacing(82),
            bgcolor: theme.colors?.utility['orange-4'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            padding: 2,
          }}
        >
          <a href={trendAudio.urlMetadata.url} target="_blank" rel="noreferrer">
            {trendAudio.platform === Platform.Reel && trendAudio.coverUrl ? (
              <Box
                sx={{
                  margin: 'auto',
                }}
              >
                <Image
                  src={trendAudio.coverUrl}
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: theme.spacing(2),
                    objectFit: 'cover',
                  }}
                />
              </Box>
            ) : (
              <Box
                position="relative"
                sx={{
                  margin: 'auto',
                  padding: 3,
                }}
              >
                <Box
                  sx={{
                    borderRadius: 3,
                    background: theme.colors?.primary.white,
                    padding: theme.spacing(4, 4, 5, 4.5),
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 4,
                    height: 'fit-content',
                  }}
                >
                  <Box position="relative">
                    {trendAudio.coverUrl ? (
                      <Image
                        src={trendAudio.coverUrl || ''}
                        sx={{
                          height: 96,
                          width: 96,
                          borderRadius: theme.spacing(2),
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <Box sx={{ maxHeight: 240, maxWidth: 234 }}>
                        <Iframely
                          url={trendAudio.urlMetadata.url}
                          urlMetadata={trendAudio.urlMetadata}
                          componentsProps={{
                            iframelyEmbedWidget: {
                              sx: {
                                overflow: 'hidden',
                              },
                            },
                          }}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: `translate(-50%, -50%)`,
                      }}
                    >
                      <IconBoldPlayCircle
                        size={48}
                        color={theme.colors?.primary.white}
                      />
                    </Box>
                  </Box>
                  {trendAudio.coverUrl && (
                    <Box>
                      <Box display="flex" gap={2} flexDirection="column">
                        <Typography
                          variant="headline-xl"
                          fontSize={24}
                          fontWeight={500}
                          sx={{
                            wordBreak: 'break-word',
                          }}
                        >
                          {trendAudio.title}
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Avatar
                            src={trendAudio.author?.avatarUrl || ''}
                            sx={{
                              width: theme.spacing(6),
                              height: theme.spacing(6),
                            }}
                            variant="circular"
                          />
                          <Typography variant="headline-sm">
                            {trendAudio.author?.name}
                          </Typography>
                        </Box>
                        {!!trendAudio.totalVideoCount &&
                          trendAudio.totalVideoCount > 0 && (
                            <Typography
                              variant="subhead-xl"
                              color={theme.colors?.utility[700]}
                            >
                              {formatBigNumber(trendAudio.totalVideoCount)}{' '}
                              videos
                            </Typography>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </a>
          <Box
            sx={{
              position: 'absolute',
              right: theme.spacing(3),
              top: theme.spacing(3),
            }}
          >
            <Icon size={32} color={theme.colors?.primary.parchment} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: 4,
          background: `rgba(255, 255, 255, 0.60)`,
          padding: theme.spacing(4),
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          gap: 2,
          maxHeight: theme.spacing(19),
        }}
      >
        <Box display="flex" gap={3}>
          <IconBoldMusicSquare size={32} />
          <Box sx={{ display: 'grid', gap: 1 }}>
            <Typography variant="headline-sm">Audio</Typography>
            <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
              on{' '}
              {trendAudio.platform === Platform.Tiktok ? 'TikTok' : 'Instagram'}
            </Typography>
          </Box>
        </Box>
        {renderAudioActionButton}
      </Box>
    </Box>
  );
};
