import {
  SocialPostFragmentBrandSocialPostEngagementStatusFragment,
  BrandSocialPostEngagementStatus,
  useUpdateBrandSocialPostEngagementForSocialPostBrandEngagementStatusMutation,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useState } from 'react';
import {
  Box,
  Portal,
  Snackbar,
  SnackbarContent,
  SxProps,
  Typography,
} from '@mui/material';
import { theme } from 'styles/theme';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { modifyObject } from 'utils/apollo';
import { SocialPostStatusData } from './types';

type BrandSocialPostEngagementStatusProps = {
  brandId: string;
  socialPost: SocialPostFragmentBrandSocialPostEngagementStatusFragment;
  showIcon?: boolean;
  onUpdateCallBack?: (status: BrandSocialPostEngagementStatus) => void;
  componentProps?: {
    button?: {
      sx?: SxProps;
    };
  };
};

export const SocialPostBrandEngagementStatus = (
  props: BrandSocialPostEngagementStatusProps,
) => {
  const { brandId, socialPost, componentProps, showIcon, onUpdateCallBack } =
    props;

  const [status, setStatus] = useState(
    socialPost.brandSocialPostEngagement?.status ??
      BrandSocialPostEngagementStatus.NotStarted,
  );
  useEffect(() => {
    if (socialPost.brandSocialPostEngagement?.status) {
      setStatus(socialPost.brandSocialPostEngagement?.status);
    }
  }, [socialPost.brandSocialPostEngagement?.status]);

  const [updateBrandSocialPostEngagement] =
    useUpdateBrandSocialPostEngagementForSocialPostBrandEngagementStatusMutation();

  const [prevStatus, setPrevStatus] =
    useState<BrandSocialPostEngagementStatus | null>(null);
  const [timerId, setTimerId] = useState<number | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false); // Updated state for Snackbar visibility

  let timerIds: number[] = [];

  const analytics = useAnalytics();

  const updateStatus = async (newStatus: BrandSocialPostEngagementStatus) => {
    // Clear all previous timers
    timerIds.forEach((timerId) => clearTimeout(timerId));
    timerIds = [];

    setPrevStatus(status);
    setStatus(newStatus);

    await updateBrandSocialPostEngagement({
      variables: {
        data: {
          brandId,
          socialPostId: socialPost.id,
          status: newStatus,
        },
      },
      update: (cache) => {
        modifyObject(
          cache,
          socialPost.brandSocialPostEngagement?.id ?? '',
          'BrandSocialPostEngagementModel',
          {
            status: () => newStatus,
          },
        );
      },
    });

    onUpdateCallBack?.(newStatus);

    if (newStatus === BrandSocialPostEngagementStatus.Pass) {
      setShowSnackbar(true);
      const id = window.setTimeout(() => {
        setPrevStatus(null);
        setShowSnackbar(false);
      }, 5000);
      setTimerId(id);
      timerIds.push(id);
    }

    analytics.track(EventName.SocialListeningPostStatusUpdated, {
      socialPostId: socialPost.id,
      status: newStatus,
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the key pressed is 'z' and either Ctrl or Command (Meta) is pressed
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault();
        // Check if the current or previous status is 'Pass'
        if (
          (status === BrandSocialPostEngagementStatus.Pass ||
            prevStatus === BrandSocialPostEngagementStatus.Pass) &&
          prevStatus !== null
        ) {
          updateStatus(prevStatus);
          setShowSnackbar(false);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [prevStatus, status]);

  useEffect(() => {
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [timerId]);

  return (
    <>
      <Portal>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <SnackbarContent
            sx={{
              borderRadius: theme.spacing(3),
              border: 'none',
              backgroundColor: theme.colors?.primary.black,
              color: theme.colors?.primary.parchment,
            }}
            message={
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                  variant="body-lg"
                  sx={{ fontWeight: 500, ml: theme.spacing(2) }}
                >
                  Press{' '}
                  <Box
                    component="span"
                    sx={{
                      p: theme.spacing(1, 1.5),
                      border: '1px solid rgba(250, 243, 236, 0.20)',
                      backgroundColor: 'rgba(250, 243, 236, 0.20)',
                      borderRadius: 1,
                      minWidth: theme.spacing(6),
                    }}
                  >
                    ⌘
                  </Box>{' '}
                  <Box
                    component="span"
                    sx={{
                      p: theme.spacing(1, 2),
                      border: '1px solid rgba(250, 243, 236, 0.20)',
                      backgroundColor: 'rgba(250, 243, 236, 0.20)',
                      borderRadius: 1,
                      minWidth: theme.spacing(6),
                    }}
                  >
                    Z
                  </Box>{' '}
                  to undo
                </Typography>
              </Box>
            }
          />
        </Snackbar>
      </Portal>
      <ContextMenu
        renderButton={() => (
          <Box
            sx={{
              ...SocialPostStatusData[status].sx,
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              ...componentProps?.button?.sx,
            }}
          >
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[800]}
              fontWeight={600}
            >
              {SocialPostStatusData[status].label}
            </Typography>

            {showIcon && <IconLinearArrowDown size={16} />}
          </Box>
        )}
        options={Object.keys(SocialPostStatusData).map((statusKey) => ({
          renderOption: () => (
            <Box
              sx={{
                ...SocialPostStatusData[
                  statusKey as BrandSocialPostEngagementStatus
                ].sx,
              }}
            >
              <Typography
                variant="body-lg"
                color={theme.colors?.utility[800]}
                fontWeight={600}
              >
                {SocialPostStatusData[statusKey].label}
              </Typography>
            </Box>
          ),
          onClick: () => {
            updateStatus(statusKey as BrandSocialPostEngagementStatus);
          },
        }))}
      />
    </>
  );
};
