import { Box } from '@mui/material';
import { ViewContainer } from 'components/common/Annotation/Common/ViewContainer';
import { AnnotationManagerProvider } from 'components/common/Annotation/contexts/Annotation.context';
import { ZoomOption } from 'components/common/Annotation/types';
import { Iframely } from 'components/common/Iframely';
import { useCheckRenderingWidget } from 'components/common/Iframely/hooks/useCheckRenderingWidget';
import { CustomImage } from 'components/common/Media/components/Image';
import { MediaErrorBoundary } from 'components/common/Media/components/MediaErrorBoundary';
import { CustomVideo } from 'components/common/Media/components/Video';
import { ViewManagerProvider } from 'components/common/Media/contexts/ViewManager.context';
import { ZoomManagerProvider } from 'components/common/Media/contexts/ZoomManager.context';
import { useSocialMediaContentRenderer } from 'components/common/Media/hooks/useSocialMediaContentRenderer';
import { PostFragmentPostDetailContentViewFragment } from 'graphql/generated';
import { lazy, useMemo, useState } from 'react';
import { usePostAnnotationHandlers } from './usePostAnnotationHandlers';

const CustomDocument = lazy(
  () => import('components/common/Media/components/Pdf'),
);

type PostDetailContentRendererProps = {
  post: PostFragmentPostDetailContentViewFragment;
};

export const PostDetailContentRenderer = ({
  post,
}: PostDetailContentRendererProps) => {
  const urlMetadata = post.urlMetadata[0];
  const { mimeType, url } = useMemo(
    () => ({
      url: urlMetadata?.url,
      mimeType: urlMetadata?.metadata?.type || '',
    }),
    [urlMetadata],
  );
  const { renderSocialMediaContent } = useSocialMediaContentRenderer({ post });
  const { customRenderingElement } = useCheckRenderingWidget({
    mimeType,
    url,
    hasPreviewableSocialMedia: !!urlMetadata.metadata?.medias?.length,
  });
  const [hasErrorUsingCustomRender, setHasErrorUsingCustomRender] =
    useState(false);

  const { onCreatePostAnnotation } = usePostAnnotationHandlers();

  const customRender = useMemo(() => {
    let finalCustomRender: React.ReactNode = null;

    switch (customRenderingElement) {
      case 'socialMediaPreview':
        finalCustomRender = <>{renderSocialMediaContent()}</>;
        break;

      case 'img': {
        finalCustomRender = (
          <CustomImage
            annotations={post.annotations}
            canAnnotate
            imageName="test"
            imageUrl={url}
            onCreateAnnotation={(annotation) => {
              onCreatePostAnnotation({
                annotation,
                postId: post.id,
              });
            }}
          />
        );

        break;
      }
      case 'video': {
        finalCustomRender = (
          <CustomVideo
            annotations={post.annotations}
            canAnnotate
            videoUrl={url}
            onCreateAnnotation={(annotation) => {
              onCreatePostAnnotation({
                annotation,
                postId: post.id,
              });
            }}
          />
        );

        break;
      }
      case 'pdf': {
        const onError = () => {
          setHasErrorUsingCustomRender(true);

          return <></>;
        };

        finalCustomRender = (
          <MediaErrorBoundary getErrorMessage={onError}>
            <CustomDocument
              pdfUrl={url}
              canAnnotate
              annotations={post.annotations}
              onError={onError}
              onCreateAnnotation={(annotation) => {
                onCreatePostAnnotation({
                  annotation,
                  postId: post.id,
                });
              }}
            />
          </MediaErrorBoundary>
        );

        break;
      }
      case 'audio':
        finalCustomRender = (
          <Box sx={{ width: '100%' }} component="audio" controls>
            <Box component="source" src={url} type={mimeType} />
          </Box>
        );

        break;
      default: {
        break;
      }
    }

    return finalCustomRender;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    url,
    post.annotations,
    post.id,
    customRenderingElement,
    onCreatePostAnnotation,
    renderSocialMediaContent,
  ]);

  if (customRender && !hasErrorUsingCustomRender) {
    let defaultZoomOption: ZoomOption | undefined;

    switch (customRenderingElement) {
      case 'socialMediaPreview':
      case 'img': {
        defaultZoomOption = ZoomOption.FIT_TO_SCREEN;

        break;
      }
      case 'video': {
        defaultZoomOption = ZoomOption.FIT_TO_SCREEN;

        break;
      }
      case 'pdf': {
        defaultZoomOption = ZoomOption.FIT_TO_WIDTH;

        break;
      }
      default: {
        break;
      }
    }

    return (
      <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        <AnnotationManagerProvider canAnnotateDefault>
          {/* Ensure we reload everything */}
          <ZoomManagerProvider
            key={post.id}
            defaultZoomOption={defaultZoomOption}
          >
            <ViewManagerProvider>
              <ViewContainer>{customRender}</ViewContainer>
            </ViewManagerProvider>
          </ZoomManagerProvider>
        </AnnotationManagerProvider>
      </Box>
    );
  }

  return (
    <Iframely
      url={url}
      urlMetadata={urlMetadata}
      fullWidth={false}
      doNotUseCustomRendering
      componentsProps={{
        iframelyEmbedWidget: {
          componentsProps: {
            noPreview: {
              sx: {
                width: 300,
                height: 200,
                borderRadius: 3,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              },
            },
          },
        },
      }}
    />
  );
};
