import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearNote2 } from 'components/icons/components/linear/IconLinearNote2';
import {
  getTrendCategoryIcon,
  getTrendCategoryLabel,
} from 'features/trending/constants';
import { TrendCategory } from 'graphql/generated';
import { theme } from 'styles/theme';

interface Props {
  selectedFilters: TrendCategory[];
  onSetSelectedFilters: (categories: TrendCategory[]) => void;
}

export const TrendingFilterSelectButton = ({
  onSetSelectedFilters,
  selectedFilters,
}: Props) => {
  const options = [
    {
      icon: getTrendCategoryIcon(TrendCategory.Music),
      label: getTrendCategoryLabel(TrendCategory.Music),
      value: TrendCategory.Music,
    },
    {
      icon: getTrendCategoryIcon(TrendCategory.Voiceover),
      label: getTrendCategoryLabel(TrendCategory.Voiceover),
      value: TrendCategory.Voiceover,
    },
    {
      icon: getTrendCategoryIcon(TrendCategory.VideoStructure),
      label: getTrendCategoryLabel(TrendCategory.VideoStructure),
      value: TrendCategory.VideoStructure,
    },
    {
      icon: getTrendCategoryIcon(TrendCategory.PopCulture),
      label: getTrendCategoryLabel(TrendCategory.PopCulture),
      value: TrendCategory.PopCulture,
    },
    {
      icon: getTrendCategoryIcon(TrendCategory.Other),
      label: getTrendCategoryLabel(TrendCategory.Other),
      value: TrendCategory.Other,
    },
  ];
  return (
    <ContextMenu
      PaperProps={{
        style: {
          borderRadius: theme.spacing(3),
          background: `rgba(255, 255, 255, 1)`,
          boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
          minWidth: 266,
        },
      }}
      renderButton={() => {
        return (
          <IconButton
            sx={{
              borderRadius: 25,
              bgcolor: theme.colors?.utility[300],
              padding: `10px 16px 10px 12px`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              minWidth: 155,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconLinearNote2 size={16} color={theme.colors?.utility[800]} />
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[800]}
              >
                Trends
              </Typography>
            </Box>
            <IconLinearArrowDown size={16} color={theme.colors?.utility[800]} />
          </IconButton>
        );
      }}
      options={options.map((option) => ({
        onClick: () => {
          if (selectedFilters.includes(option.value)) {
            onSetSelectedFilters([
              ...selectedFilters.filter((c) => c !== option.value),
            ]);
          } else {
            onSetSelectedFilters([...selectedFilters, option.value]);
          }
        },
        closeOnClick: false,
        renderOption: () => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Checkbox
              checked={selectedFilters.includes(option.value)}
              sx={{
                padding: 0,
                '& svg': {
                  width: '16px',
                  height: '16px',
                },
                '&.Mui-checked': { color: theme.colors?.primary.black },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 1,
                borderRadius: 1,
                bgcolor: theme.colors?.utility[400],
              }}
            >
              {option.icon}
            </Box>
            <Typography
              variant="subhead-lg"
              color={theme.colors?.primary.black}
            >
              {option.label}
            </Typography>
          </Box>
        ),
      }))}
    />
  );
};
