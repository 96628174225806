import {
  Autocomplete,
  AutocompleteProps,
  Box,
  PopoverProps,
  PopperProps,
  SxProps,
} from '@mui/material';
import React from 'react';
import { StyledPopover, StyledTextField } from './styles';

const PopperComponent = ({
  children,
  popoverBottomRender,
}: PopperProps & { popoverBottomRender?: React.ReactNode }) => {
  return (
    <Box px={3} pb={4}>
      {children as any}
      {popoverBottomRender}
    </Box>
  );
};

type AutocompletePopupProps = Pick<
  AutocompleteProps<
    { label: string; value: string },
    boolean,
    undefined,
    undefined
  >,
  | 'disabled'
  | 'defaultValue'
  | 'ListboxProps'
  | 'ListboxComponent'
  | 'inputValue'
  | 'onInputChange'
  | 'value'
  | 'options'
  | 'placeholder'
  | 'renderOption'
  | 'onChange'
  | 'multiple'
  | 'readOnly'
  | 'noOptionsText'
  | 'filterOptions'
  | 'PaperComponent'
> & {
  freeSolo?: boolean;
  popoverProps?: Partial<PopoverProps>;
  popoverBottomRender?: React.ReactNode;
  renderTrigger: (open?: boolean) => React.ReactElement<any>;
  triggerWrapperSx?: SxProps;
  inputSx?: SxProps;
  loading?: boolean;
  loadMoreItems?: VoidFunction;
  disableDefaultSearchFilter?: boolean;
};

export const AutocompletePopup = (props: AutocompletePopupProps) => {
  const {
    value,
    disabled,
    defaultValue,
    options,
    inputValue,
    placeholder,
    multiple = false,
    popoverProps,
    onInputChange,
    popoverBottomRender,
    readOnly,
    renderOption,
    onChange,
    renderTrigger,
    triggerWrapperSx,
    inputSx,
    loading,
    noOptionsText,
    loadMoreItems,
    disableDefaultSearchFilter,
    freeSolo,
    filterOptions,
    PaperComponent,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const internalOpen = popoverProps?.open || Boolean(anchorEl);

  return (
    <>
      <Box
        component="button"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        disabled={disabled || readOnly}
        sx={{
          textAlign: 'left',
          ...triggerWrapperSx,
        }}
      >
        {renderTrigger(internalOpen)}
      </Box>
      <StyledPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...popoverProps}
        open={internalOpen}
        anchorEl={anchorEl}
        onClose={(...params) => {
          setAnchorEl(null);
          popoverProps?.onClose?.(...params);
        }}
      >
        <Autocomplete
          open
          disabled={disabled}
          value={value || undefined}
          defaultValue={defaultValue || undefined}
          options={options}
          disablePortal
          disableClearable
          freeSolo={freeSolo}
          onInputChange={onInputChange}
          inputValue={inputValue}
          loading={loading}
          renderInput={(props) => (
            <StyledTextField
              {...props}
              autoFocus
              placeholder={placeholder}
              inputProps={{ ...props.inputProps, value: inputValue }}
              sx={inputSx}
            />
          )}
          renderTags={() => null}
          // @ts-ignore -- TODO: fix this
          renderOption={renderOption}
          // eslint-disable-next-line
          PopperComponent={(props) => (
            <PopperComponent
              {...props}
              popoverBottomRender={popoverBottomRender}
            />
          )}
          PaperComponent={PaperComponent || Box}
          ListboxProps={{
            onScrollCapture: (event) => {
              if (loadMoreItems) {
                const node = event.currentTarget;
                if (
                  node.scrollTop + node.clientHeight >
                  node.scrollHeight - 10
                ) {
                  loadMoreItems();
                }
              }
            },
          }}
          popupIcon={null}
          multiple={multiple}
          noOptionsText={noOptionsText}
          onChange={(...params) => {
            // @ts-ignore -- TODO: fix this
            onChange?.(...params);
            if (!multiple) {
              setAnchorEl(null);
            }
          }}
          {...(filterOptions ? { filterOptions } : {})}
          // disable default filter from Autocomplete
          {...(disableDefaultSearchFilter ? { filterOptions: (x) => x } : {})}
        />
      </StyledPopover>
    </>
  );
};
