import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import { CollectionSelectPopover } from 'features/collection';
import { useMemo, useState } from 'react';
import { StyledIconButton } from './styles';

export type Props = {
  postIds: string[];
  collectionIds: string[];
  setCollectionIds: (collectionIds: string[]) => void;
  willAddPostsToFavorites: boolean;
  setWillAddPostsToFavorites: (willAddPostsToFavorites: boolean) => void;
};

export const OrganizeButton = (props: Props) => {
  const {
    postIds,
    collectionIds,
    setCollectionIds,
    willAddPostsToFavorites,
    setWillAddPostsToFavorites,
  } = props;

  const { orgBilling } = useUserContext();

  const isAllowedToOrganize = useMemo(() => {
    return (
      orgBilling?.collectionUsageLimit == null ||
      orgBilling?.collectionUsageLimit > 0
    );
  }, [orgBilling]);

  const [collectionAnchorEl, setCollectionAnchorEl] =
    useState<null | HTMLElement>(null);
  const bannerShow = useDisclosure();

  return (
    <>
      <Tooltip
        placement="top"
        PopperProps={{
          sx: {
            zIndex: bannerShow.isOpen ? '1000' : '2000 !important',
          },
        }}
        title={
          isAllowedToOrganize ? (
            'Organize'
          ) : !orgBilling ? (
            ''
          ) : (
            <BillingCollectionLimitIndicator
              organizationBilling={orgBilling}
              variant="text"
              onModalOpened={bannerShow.onOpen}
              onModalClosed={bannerShow.onClose}
            />
          )
        }
      >
        <Box>
          <StyledIconButton
            disabled={!isAllowedToOrganize}
            onClick={(e) => {
              setCollectionAnchorEl(e.currentTarget);
            }}
          >
            <IconBoldSave />
          </StyledIconButton>
        </Box>
      </Tooltip>
      {isAllowedToOrganize && (
        <CollectionSelectPopover
          open={Boolean(collectionAnchorEl)}
          anchorEl={collectionAnchorEl}
          onClose={() => {
            setCollectionAnchorEl(null);
          }}
          value={collectionIds}
          onSelectCollection={(id) => {
            if (collectionIds.includes(id)) {
              setCollectionIds(collectionIds.filter((c) => c !== id));
            } else {
              setCollectionIds([...collectionIds, id]);
            }
          }}
          smartSearchProps={{
            postIds,
          }}
          isPostAddedToFavorites={willAddPostsToFavorites}
          onTogglePostsFavorite={() =>
            setWillAddPostsToFavorites(!willAddPostsToFavorites)
          }
        />
      )}
    </>
  );
};
