import { gql } from '@apollo/client';
import { Box, Divider } from '@mui/material';
import {
  POST_FRAGMENT_POST_PREVIEW,
  PostPreview,
} from 'features/post/components/preview';
import {
  TaskAssignee,
  TaskDescription,
  TaskDueDateRange,
  TaskName,
  TaskPriority,
  TaskStatus,
} from 'features/task';
import {
  TaskFragmentTaskDetailViewFragment,
  useGetPostForTaskDetailViewHeadingSectionQuery,
} from 'graphql/generated';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { extractTextFromHtml } from 'utils/html';
import { useNestedTaskNavigationContext } from '../../contexts';

export const POST_FRAGMENT_TASK_HEADING_SECTION = gql`
  fragment PostFragmentTaskHeadingSection on PostModel {
    ...PostFragmentPostPreview
  }
  ${POST_FRAGMENT_POST_PREVIEW}
`;

// eslint-disable-next-line
gql`
  query GetPostForTaskDetailViewHeadingSection($id: String!) {
    post(id: $id) {
      id
      ...PostFragmentTaskHeadingSection
    }
  }
  ${POST_FRAGMENT_TASK_HEADING_SECTION}
`;

export interface HeadingSectionProps {
  task: TaskFragmentTaskDetailViewFragment;
  onTaskUpdate?: (task: Partial<TaskFragmentTaskDetailViewFragment>) => void;
}

const taskNameFontStyle = {
  ...theme.typography['headline-xl'],
  fontSize: 28,
  fontWeight: 500,
  letterSpacing: '-1.12px',
};

export const TaskHeadingSection = (props: HeadingSectionProps) => {
  const { task, onTaskUpdate } = props;

  const { locationStack } = useNestedTaskNavigationContext();

  const [params] = useSearchParams();
  const postId = params.get('postId');
  const { data: postData } = useGetPostForTaskDetailViewHeadingSectionQuery({
    variables: {
      id: postId || '',
    },
    skip: !postId,
  });
  const post = postData?.post;

  return (
    <Box display="flex" flexDirection="column" bgcolor="white" gap={5}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
        {post && (
          <>
            <Box
              sx={{
                height: 94,
                width: 62,
              }}
            >
              <PostPreview
                post={post}
                sx={{
                  height: 94,
                  width: 62,
                  borderRadius: 2,
                  boxShadow:
                    '0px 1.461px 2.922px -1.096px rgba(24, 39, 75, 0.08), 0px 1.096px 1.461px -1.096px rgba(24, 39, 75, 0.12)',
                }}
              />
            </Box>
            <Divider
              orientation="vertical"
              sx={{ borderColor: theme.colors?.utility[400] }}
            />
          </>
        )}
        <Box display="flex" flexDirection="column" gap={5}>
          <Box display="flex" alignItems="center">
            <TaskName
              task={task}
              style={{
                ...taskNameFontStyle,
                color: theme.colors?.utility[900],
              }}
              sx={{
                '& p': {
                  margin: 0,
                  ...taskNameFontStyle,
                  color: theme.colors?.utility[900],
                },
              }}
              placeholder="New Task"
              editable={!!onTaskUpdate}
            />
            {locationStack.length === 0 && task.parentTask && (
              <Box
                sx={{
                  ...taskNameFontStyle,
                  color: theme.colors?.utility[700],
                }}
              >
                &nbsp;/&nbsp;{extractTextFromHtml(task.parentTask.name)}
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            gap={2}
            sx={{ flexWrap: 'wrap' }}
          >
            <TaskStatus task={task} variant="button" iconSize={24} />
            <TaskDueDateRange
              task={task}
              variant="button"
              componentsProps={{
                icon: {
                  size: 24,
                  color: theme.colors?.utility['blue-3-new'],
                },
              }}
            />
            <TaskPriority task={task} variant="button" />
            <TaskAssignee
              task={task}
              onChange={(_, taskMembers) =>
                onTaskUpdate?.({ ...task, taskMembers })
              }
              readOnly={!onTaskUpdate}
              variant={task.taskMembers.length === 1 ? 'icon-text' : 'button'}
              componentsProps={{
                icon: {
                  size: 24,
                  color: theme.colors?.utility[900],
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* Task description */}
      <TaskDescription
        task={task}
        style={{
          ...theme.typography['subhead-xl'],
          color: theme.colors?.utility[900],
        }}
      />
    </Box>
  );
};
