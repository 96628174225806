import { Extension, ReactRenderer } from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import tippy, { GetReferenceClientRect } from 'tippy.js';
import { CommandMenuList } from './CommandMenuList';
import { EXTENSION_COMMAND_MENU } from '../constants';

/**
 * This extension allows user to show the menu when typing '/'
 */
export const CommandMenu = Extension.create({
  name: EXTENSION_COMMAND_MENU,

  // show commands menu when typing slash (/)
  addOptions() {
    return {
      emptyEditorClass: 'is-commandMenu-empty',
      placeholder: 'Create a task, heading, list, upload an asset...',
      suggestion: {
        char: '/',
        command: ({ editor, range, props }) => {
          props.command({ editor, range });
        },
        decorationClass: 'commandMenu-suggestion',
      },
    };
  },

  // only support command in note rich text for now, this plugin will not relate to CommentInput
  addProseMirrorPlugins() {
    // this suggestion handles displaying the menu
    const suggestionPlugin = Suggestion({
      pluginKey: new PluginKey('commandMenu'),
      editor: this.editor,
      ...this.options.suggestion,
      render: () => {
        let component: ReactRenderer<any>;
        let popup: any;
        return {
          onStart: (props) => {
            component = new ReactRenderer(CommandMenuList, {
              props,
              editor: props.editor,
            });

            if (!props.clientRect) {
              return;
            }

            popup = tippy('body', {
              getReferenceClientRect:
                props.clientRect as GetReferenceClientRect,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            });
          },

          onUpdate(props) {
            component.updateProps(props);
            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            });
          },

          onKeyDown(props) {
            if (!component.ref) {
              return false;
            }

            if (props.event.key === 'Escape') {
              popup[0].hide();
              return true;
            }

            return component.ref.onKeyDown(props);
          },

          onExit() {
            popup[0].destroy();
            component.destroy();
          },
        };
      },
    });

    // this display the placeholder when the menu opens
    const suggestionPlaceholderPlugin = new Plugin({
      key: new PluginKey('commandMenuPlaceholder'),
      props: {
        decorations: ({ doc, selection }) => {
          const decorations: Decoration[] = [];
          const { anchor } = selection;

          const regex = /(^|\s)\/( |$)/;

          doc.descendants((node, pos) => {
            if (node.isTextblock) {
              const textContent = node.textContent;

              // only show the placeholder when the command menu initially starts
              if (
                textContent.charAt(anchor - pos - 2) ===
                  this.options.suggestion.char &&
                regex.test(textContent)
              ) {
                const classes: string[] = [this.options.emptyEditorClass];

                const decoration = Decoration.inline(anchor - 1, anchor, {
                  class: classes.join(' '),
                  'data-commandMenuPlaceholder':
                    typeof this.options.placeholder === 'function'
                      ? this.options.placeholder({
                          editor: this.editor,
                          node,
                          pos,
                        })
                      : this.options.placeholder,
                });
                decorations.push(decoration);
              }
            }
          });

          return DecorationSet.create(doc, decorations);
        },
      },
    });

    return [suggestionPlugin, suggestionPlaceholderPlugin];
  },
});
