import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldArrowDown } from 'components/icons/components/bold/IconBoldArrowDown';
import { IconOutlineImage } from 'components/icons/components/outline/IconOutlineImage';
import { BrandConnectedCreators } from 'features/brand';
import { BrandCompetitorsPlatformSelector } from 'features/brandCompetitors/components';
import { CreatorHandleChip } from 'features/creator';
import { SocialPostThumbnail } from 'features/socialPost';
import {
  AverageCompetitorSentiment,
  Platform,
  SortOrder,
  useGetBrandsForBrandCompetitorsViewQuery,
  useGetCompetitorFlatCreatorsListForBrandCompetitorsViewQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { TableHeaders } from './consts';
import { BrandCompetitorsListViewSkeleton } from './BrandCompetitorsListViewSkeleton';

const AverageSentimentLabels = {
  [AverageCompetitorSentiment.Positive]: 'Positive',
  [AverageCompetitorSentiment.SlightlyPositive]: 'Slightly Positive',
  [AverageCompetitorSentiment.Neutral]: 'Neutral',
  [AverageCompetitorSentiment.SlightlyNegative]: 'Slightly Negative',
  [AverageCompetitorSentiment.Negative]: 'Negative',
};

export const BrandCompetitorsView = () => {
  const { data: brands } = useGetBrandsForBrandCompetitorsViewQuery();
  const currentBrand = brands?.brands[0];
  const [currentPlatform, setCurrentPlatform] = useState(Platform.Tiktok);
  const [sortData, setSortData] = useState<{
    key: string;
    order: SortOrder;
  }>();

  const { data: competitorsData, loading: loadingCompetitors } =
    useGetCompetitorFlatCreatorsListForBrandCompetitorsViewQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        data: {
          filters: {
            platforms: [currentPlatform],
          },
          ...(sortData
            ? {
                sortBy: {
                  field: sortData?.key,
                  order: sortData?.order,
                },
              }
            : {}),
        },
        socialPostsData: {
          take: 3,
        },
      },
    });
  const competitors = competitorsData?.searchCompetitorFlatCreators.data || [];

  return (
    <Box mt={16} px={8}>
      <Box py={4} gap={4} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
            Competitor Analytics
          </Typography>
          {currentBrand && <BrandConnectedCreators brand={currentBrand} />}
        </Box>
      </Box>
      <BrandCompetitorsPlatformSelector onPlatformSelect={setCurrentPlatform} />
      <Box
        sx={{
          width: `calc(100vw - ${theme.spacing(16)})`,
          overflowX: 'auto',
        }}
      >
        {loadingCompetitors ? (
          <BrandCompetitorsListViewSkeleton />
        ) : (
          <Table
            sx={{
              mt: 8,
              overflowX: 'auto',
              '& td': {
                border: 'none',
              },
            }}
          >
            <TableHead>
              <TableRow>
                {TableHeaders.map((header, index) => (
                  <TableCell
                    sx={{
                      ...header.sx,
                      ...(header.canSort ? { cursor: 'pointer' } : {}),
                    }}
                    onClick={() => {
                      if (header.canSort) {
                        if (
                          sortData?.key === header.key &&
                          sortData?.order === SortOrder.Desc
                        ) {
                          setSortData(undefined);
                        } else {
                          setSortData({
                            key: header.key,
                            order:
                              sortData?.key === header.key
                                ? sortData?.order === SortOrder.Asc
                                  ? SortOrder.Desc
                                  : SortOrder.Asc
                                : SortOrder.Asc,
                          });
                        }
                      }
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      component="span"
                    >
                      {header.label}

                      {header.canSort && (
                        <Box display="flex" gap={0} flexDirection="column">
                          {sortData?.key === header.key && (
                            <>
                              {sortData?.order === SortOrder.Asc && (
                                <IconBoldArrowDown
                                  size={16}
                                  style={{
                                    transform: 'rotate(180deg)',
                                  }}
                                />
                              )}
                              {sortData?.order === SortOrder.Desc && (
                                <IconBoldArrowDown size={16} />
                              )}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {competitors.map((competitor, index) => {
                return (
                  <TableRow
                    component={Link}
                    to={PlotRoutes.socialListeningCompetitor(
                      competitor.id,
                      currentPlatform,
                    )}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.colors?.utility[275],
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <TableCell>{index + 1}.</TableCell>
                    <TableCell>
                      <Avatar
                        src={
                          competitor.trackingCreator?.profilePictureUrl || ''
                        }
                        sx={{
                          width: theme.spacing(12),
                          height: theme.spacing(12),
                          border: `2px solid ${theme.colors?.utility[300]}`,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['headline-sm'],
                      }}
                    >
                      {competitor.trackingBrand.name}
                      <CreatorHandleChip creator={competitor.trackingCreator} />
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {formatBigNumber(
                        competitor.trackingCreator.followerCount,
                      )}{' '}
                      followers
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {Number(competitor.averageEngagementRate).toFixed(1)}%
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {formatBigNumber(competitor.averageVideoViews || 0)}{' '}
                      average views
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {formatBigNumber(competitor.averageDigg || 0)} average
                      likes
                    </TableCell>
                    <TableCell>
                      <Box display="flex" gap={2} alignItems="center">
                        {competitor.socialPosts?.data.length ? (
                          competitor.socialPosts?.data.map((post) => (
                            <SocialPostThumbnail
                              socialPost={post}
                              sx={{
                                width: theme.spacing(10),
                                height: theme.spacing(14),
                                borderRadius: theme.spacing(1),
                                backgroundColor: theme.colors?.utility[300],
                                p: 0,
                              }}
                              componentProps={{
                                hideCreatedTime: true,
                                hidePlatform: true,
                                thumbnailFallback: (
                                  <IconOutlineImage size={24} />
                                ),
                              }}
                            />
                          ))
                        ) : (
                          <Typography
                            variant="body-lg"
                            color={theme.colors?.utility[700]}
                          >
                            -
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 500,
                      }}
                    >
                      <Box component="span" fontWeight={600}>
                        {formatBigNumber(
                          competitor?.averageSocialPostCreationPerWeek || 0,
                        )}
                      </Box>
                      {' / week'}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 500,
                      }}
                    >
                      <Box component="span" fontWeight={600}>
                        {formatBigNumber(
                          competitor?.averageBrandMentionedPerWeek || 0,
                        )}
                      </Box>
                      {' / week'}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 500,
                      }}
                    >
                      {
                        AverageSentimentLabels[
                          competitor.averageCreatorSentiment
                        ]
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  );
};
