import { Box, Typography } from '@mui/material';
import { CollectionMultiPostPreview } from 'features/collection/components';
import { useMoveToAnotherCollectionCollectionsList } from 'features/collection/hooks/useMoveToAnotherCollectionCollectionsList';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

type Props = {
  collectionIdToMove?: string;
  collectionId: string;
  onCollectionClick: (collectionId: string) => void;
};

export const CollectionMoveToAnotherCollectionDestinationCollectionItemsSection =
  ({ collectionIdToMove, collectionId, onCollectionClick }: Props) => {
    const { collections, fetchMoreCollectionsList, hasMoreCollections } =
      useMoveToAnotherCollectionCollectionsList({
        collectionIdToMove,
        collectionId,
      });

    if (!collections?.length) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            flex: 1,
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Typography
            variant="headline-sm"
            textAlign="center"
            color={theme.colors?.utility[700]}
          >
            This collection has <br />
            no nested collections!
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          flex: 1,
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <InfiniteScroll
          hasMore={hasMoreCollections}
          initialLoad={false}
          loadMore={fetchMoreCollectionsList}
          threshold={300}
          useWindow={false}
          getScrollParent={() =>
            document.querySelector(
              '.collection-cjb-window-view-scrollable-container',
            )
          }
        >
          {collections.map((collection) => (
            <Box
              display="flex"
              flex={1}
              justifyContent="space-between"
              sx={{
                alignItems: 'center',
                borderRadius: theme.spacing(2),
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                },
              }}
              onClick={() => onCollectionClick(collection.id)}
            >
              <Typography
                variant="body-lg"
                color={theme.colors?.utility[1000]}
                fontWeight={500}
                pl={2}
              >
                {collection.name}
              </Typography>
              <CollectionMultiPostPreview
                collection={collection}
                variant="card"
                sx={{
                  display: 'flex',
                  borderBottom: 'none',
                  width: theme.spacing(38),
                  px: 4,
                  py: 2,
                }}
              />
            </Box>
          ))}
        </InfiniteScroll>
      </Box>
    );
  };
