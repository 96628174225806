import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { ContextMenu } from 'components/common/ContextMenu';
import { Option } from 'components/common/ContextMenu/types';
import { IconLinearMore2 } from 'components/icons/components/linear/IconLinearMore2';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { OrganizationMembersPermittedCollections } from 'features/organizationMembers/components';
import { OrganizationPermission } from 'graphql/generated';
import { useOrganizationPermissions } from 'hooks/permissions/useOrganizationPermissions';
import { useMembersHandlers } from 'pages/org/settings/members/hooks/useMembersHandlers';
import { theme } from 'styles/theme/theme';
import { getFullName } from 'utils/users';
import {
  StyledContextMenuOptionWrapper,
  StyledTableBodyCell,
  StyledTableHeadCell,
} from '../styles';
import { Props } from './types';

const columns = ['Name', 'Access to', 'Invited By', ''];

export const GuestMembersTable = ({ users }: Props) => {
  const permissions = useOrganizationPermissions();
  const canManageMembers = permissions.includes(
    OrganizationPermission.MemberManagement,
  );

  const { userRemoveGuestUserConfirmationDialog, removeGuestUserHandler } =
    useMembersHandlers();

  if (!users.length) {
    return (
      <Box textAlign="center">
        <Typography color={theme.colors?.utility[700]} variant="body-lg">
          No guests invited!
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableHeadCell key={`${column}-${index}`}>
                  {column}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .sort((a, b) => {
                if (a.title === '') {
                  return 1;
                }
                if (b.title === '') {
                  return -1;
                }

                return 0;
              })
              .map((u) => {
                const options: Option[] = [];

                if (canManageMembers) {
                  options.push({
                    onClick: () => {
                      removeGuestUserHandler(u.id);
                    },
                    renderOption: () => (
                      <StyledContextMenuOptionWrapper
                        sx={{
                          color: theme.colors?.utility['pink-3'],
                          p: theme.spacing(3, 0),
                        }}
                      >
                        <IconLinearTrash size={18} />
                        <Typography
                          variant="body-md"
                          fontWeight={500}
                          color={theme.colors?.utility['pink-3']}
                          pt={0.5}
                        >
                          Remove Access
                        </Typography>
                      </StyledContextMenuOptionWrapper>
                    ),
                  });
                }

                return (
                  <TableRow key={u.id}>
                    <StyledTableBodyCell>
                      <AvatarWithName user={u} />
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <Typography
                        variant="body-md"
                        fontWeight={600}
                        color={theme.colors?.utility[800]}
                      >
                        <OrganizationMembersPermittedCollections
                          userId={u.id}
                        />
                      </Typography>
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <Typography
                        variant="body-sm"
                        fontWeight={500}
                        color={theme.colors?.utility[800]}
                      >
                        {u.isInvitedBy ? getFullName(u.isInvitedBy) : '-'}
                      </Typography>
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {!!options.length && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <ContextMenu
                            sx={{
                              '& .MuiPaper-root': {
                                borderRadius: theme.spacing(4),
                                border: 'none',
                                minWidth: 250,
                              },
                            }}
                            className="rererere"
                            renderButton={() => (
                              <IconLinearMore2
                                color={theme.colors?.utility[800]}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                            options={options}
                          />
                        </Box>
                      )}
                    </StyledTableBodyCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {userRemoveGuestUserConfirmationDialog}
    </>
  );
};
