import { Box, IconButton, PopoverProps, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RichTextEditorRef } from 'components/common/form/RichTextEditor/RichTextEditor';
import { IconOutlineClose } from 'components/icons/components/outline/IconOutlineClose';
import { IconOutlineMore } from 'components/icons/components/outline/IconOutlineMore';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import { useUserContext } from 'contexts/users/User.context';
import { CommentInput, CommentInputProps } from 'features/comments';
import { CommentContentView } from 'features/comments/views/content/CommentContentView';
import { usePostAnnotationHandlers } from 'features/post/views/detail/content/usePostAnnotationHandlers';
import { PostAnnotationFragmentAnnotationFragment } from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useRef } from 'react';
import {
  StyledCommentInputWrapper,
  StyledCommentRendererWrapper,
  StyledCommentsContainer,
  StyledMenuOption,
  StyledPopover,
  editorStyle,
  inputStyle,
} from './styles';

export type AnnotationPopoverProps = Omit<PopoverProps, 'open' | 'onClose'> & {
  annotation: PostAnnotationFragmentAnnotationFragment;
  componentsProps?: {
    commentInput?: CommentInputProps;
  };
  onClose: () => void;
};

export const AnnotationPopover = ({
  annotation,
  anchorEl,
  anchorOrigin,
  componentsProps = {},
  onClose,
  ...rest
}: AnnotationPopoverProps) => {
  const { user } = useUserContext();

  const comments = annotation.comments
    .slice(0, 1)
    .flatMap(({ childComments, ...comment }) => [comment, ...childComments]);

  const { onDeletePostAnnotation, onDeletePostAnnotationComment } =
    usePostAnnotationHandlers();

  const {
    dialog: assetDeleteConfirmationDialog,
    onOpen: openAssetDeleteConfirmationDialog,
  } = useConfirmationDialog();

  const onDeleteAnnotation = () => {
    openAssetDeleteConfirmationDialog({
      title: (
        <>
          Would you like to delete <b>annotation</b>?
        </>
      ),
      subtitle:
        'Once you delete, this annotation will no longer be accessible.',
      onConfirm: () => {
        comments.forEach((comment) => {
          onDeletePostAnnotationComment(comment.id);
        });
        onDeletePostAnnotation(annotation.id);
        onClose?.();
      },
    });
  };

  return (
    <>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin || { horizontal: 'left', vertical: 'bottom' }
        }
        onClose={onClose}
        {...rest}
      >
        <StyledCommentsContainer>
          <Box className="comments-header" display="flex" alignItems="center">
            {/* only annotation owner can delete the annotation */}
            {annotation.createdBy.id === user?.id && (
              <ContextMenu
                renderButton={() => (
                  <IconButton>
                    <IconOutlineMore size={16} />
                  </IconButton>
                )}
                options={[
                  {
                    renderOption: () => (
                      <StyledMenuOption
                        display="flex"
                        gap={2}
                        alignItems="center"
                      >
                        <IconOutlineTrash size={16} />
                        <Typography className="option-label" variant="body-lg">
                          Delete
                        </Typography>
                      </StyledMenuOption>
                    ),
                    onClick: () => onDeleteAnnotation(),
                  },
                ]}
              />
            )}
            {onClose && (
              <IconButton sx={{ padding: '3px' }} onClick={onClose}>
                <IconOutlineClose size={24} />
              </IconButton>
            )}
          </Box>
          <AnnotationPopoverContent
            annotation={annotation}
            componentsProps={componentsProps}
          />
        </StyledCommentsContainer>
      </StyledPopover>
      {assetDeleteConfirmationDialog}
    </>
  );
};

// NOTE: This is a temporary workaround.
// We need this component to be reusable for the comment threads in rich text editor.
export const AnnotationPopoverContent = (props: {
  annotation: PostAnnotationFragmentAnnotationFragment;
  componentsProps?: {
    commentInput?: CommentInputProps;
  };
  onCommentDeleted?: () => void;
}) => {
  const { annotation, componentsProps = {}, onCommentDeleted } = props;

  const comments = annotation.comments
    .slice(0, 1)
    .flatMap(({ childComments, ...comment }) => [comment, ...childComments]);

  const {
    onDeletePostAnnotation,
    onCreatePostAnnotationComment,
    onDeletePostAnnotationComment,
    onReactToPostAnnotationComment,
    onUpdatePostAnnotationComment,
  } = usePostAnnotationHandlers();
  const commentInputRef = useRef<RichTextEditorRef | null>(null);

  const parentCommentId = comments.find(
    (comment) => !comment.parentThreadId,
  )?.id;

  return (
    <>
      <Box
        sx={{
          maxHeight: 250,
          overflowY: 'auto',
        }}
      >
        {comments.map((comment) => (
          <StyledCommentRendererWrapper key={comment.id}>
            <CommentContentView
              comment={comment}
              inputStyle={inputStyle}
              editorStyle={editorStyle}
              onReactToComment={(emoji) => {
                onReactToPostAnnotationComment(comment.id, emoji);
              }}
              onUpdateComment={(data) => {
                onUpdatePostAnnotationComment({
                  commentId: comment.id,
                  data,
                });
              }}
              onDeleteComment={async () => {
                await onDeletePostAnnotationComment(comment.id);

                if (comments.length === 1) {
                  await onDeletePostAnnotation(annotation.id);
                }

                onCommentDeleted?.();
              }}
            />
          </StyledCommentRendererWrapper>
        ))}
      </Box>
      <StyledCommentInputWrapper>
        <CommentInput
          commentInputRef={commentInputRef}
          autoFocus
          inputStyle={{
            ...inputStyle,
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}
          editorStyle={editorStyle}
          onCreateComment={(comment) => {
            onCreatePostAnnotationComment({
              postAnnotationId: annotation.id,
              postId: annotation.postId,
              data: {
                ...comment,
                parentThreadId: parentCommentId,
              },
            });
            commentInputRef.current?.clear();
          }}
          placeholder="Reply"
          {...componentsProps.commentInput}
        />
      </StyledCommentInputWrapper>
    </>
  );
};
