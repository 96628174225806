import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@mui/material';
import { theme } from 'styles/theme';
import { gql } from '@apollo/client';
import {
  CollectionFragmentCollectionNameFragmentDoc,
  CollectionFragmentUpdateCollectionPermissionAlertViewFragment,
  useUpdateCollectionPermissionToParentCollectionPermissionMutation,
} from 'graphql/generated';
import { useState } from 'react';

// eslint-disable-next-line
gql`
  mutation UpdateCollectionPermissionToParentCollectionPermission(
    $data: UpdateCollectionPermissionToParentCollectionPermissionInput!
  ) {
    updateCollectionPermissionToParentCollectionPermission(data: $data) {
      success
      message
    }
  }
`;

gql`
  fragment CollectionFragmentUpdateCollectionPermissionAlertView on CollectionModel {
    id
    ...CollectionFragmentCollectionName
  }
  ${CollectionFragmentCollectionNameFragmentDoc}
`;

type UpdateCollectionPermissionAlertViewProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  collectionId: string;
  parentCollection: CollectionFragmentUpdateCollectionPermissionAlertViewFragment;
  onCallback?: VoidFunction;
};

export const UpdateCollectionPermissionAlertView = (
  props: UpdateCollectionPermissionAlertViewProps,
) => {
  const { isOpen, onClose, collectionId, parentCollection, onCallback } = props;

  const [updateCollectionPermissionToParentCollectionPermission] =
    useUpdateCollectionPermissionToParentCollectionPermissionMutation();

  const [loading, setLoading] = useState(false);

  const handleOnClose = () => {
    onCallback?.();
    onClose();
  };

  const handleUpdatePermission = async () => {
    setLoading(true);
    await updateCollectionPermissionToParentCollectionPermission({
      variables: {
        data: {
          parentCollectionId: parentCollection.id,
          collectionIds: [collectionId],
        },
      },
    });
    setLoading(false);

    handleOnClose();
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        // FIXME: This is a temporary fix for the dialog to be on top of
        // most of other things
        zIndex: 2000,
      }}
      PaperProps={{
        sx: {
          width: 350,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={theme.spacing(6)}
        padding={theme.spacing(6)}
      >
        <Box display="flex" flexDirection="column" gap={theme.spacing(4)}>
          <Typography variant="headline-lg">
            Want to update your collection permissions?
          </Typography>

          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            Some people in #{parentCollection.name} won’t be able to see this
            post. Update collection permission so they can access.
          </Typography>
        </Box>

        <Box>
          <Button
            disabled={loading}
            fullWidth
            variant="contained"
            onClick={handleUpdatePermission}
            sx={{
              bgcolor: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              ':hover': {
                bgcolor: theme.colors?.primary.black,
                opacity: 0.7,
              },
            }}
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
          >
            Update permissions
          </Button>
          <Button
            fullWidth
            onClick={handleOnClose}
            sx={{
              marginTop: theme.spacing(2),
              textDecoration: 'underline',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Continue without
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
