import { Box, Typography } from '@mui/material';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { BrandConnectedCreators } from 'features/brand/components';
import { useBrandDetails } from 'features/socialMediaListening';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';

export const SocialMediaListeningAnalyticsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setRenderTitle, setHeaderSx } = useCustomHeaderContext();

  const { brand } = useBrandDetails();

  useEffect(() => {
    setHeaderSx({
      borderBottom: `1px solid transparent`,
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.black}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
    };
  }, [location.pathname]); // eslint-disable-line

  return (
    <Box mt={16} mx={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
          Analytics
        </Typography>
        {brand && <BrandConnectedCreators brand={brand} />}
      </Box>

      <Outlet />
    </Box>
  );
};
