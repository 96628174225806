import { Autocomplete } from '@mui/lab';
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearAddCircle } from 'components/icons/components/linear/IconLinearAddCircle';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { TCountryCode, countries, getEmojiFlag } from 'countries-list';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  selectedCountries?: string[];
  onCountriesUpdated: (countries: { code: string; name: string }[]) => void;

  componentProps?: {
    sx?: SxProps;
    textField?: {
      sx?: SxProps;
    };
  };
};

export const SocialMediaListeningOnboardingCountrySelectionInput = ({
  selectedCountries,
  onCountriesUpdated,
  componentProps,
}: Props) => {
  const [searchStr, setSearchStr] = useState('');
  const [selectedValues, setSelectedValues] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const countryList = Object.keys(countries).map((key) => ({
    code: key,
    name: countries[key].name,
  }));

  const options = countryList.map((country) => {
    return {
      label: country.name,
      value: country.code,
    };
  });

  useEffect(() => {
    if (selectedCountries?.length) {
      setSelectedValues(
        selectedCountries.map((country) => {
          return {
            label: countries[country].name,
            value: country,
          };
        }),
      );
    }
  }, [selectedCountries]);

  return (
    <Autocomplete
      options={options}
      sx={{ ...componentProps?.sx }}
      inputValue={searchStr}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          setSearchStr(value);
        }
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      ListboxComponent={({ children, ...other }) => (
        <List {...other}>
          <ListItem>
            <ListItemText
              primary="You can select multiple regions:"
              sx={{
                ...typography['body-lg'],
                mt: theme.spacing(2),
                mb: 0,
                fontWeight: 500,
                color: theme.colors?.utility[700],
              }}
            />
          </ListItem>
          {children}
        </List>
      )}
      multiple
      openOnFocus
      value={selectedValues}
      onChange={(event, newValues, reason, currentValue) => {
        if (
          selectedValues.find((x) => x.value === currentValue?.option.value)
        ) {
          setSelectedValues((prev) =>
            prev.filter((item) => item.value !== currentValue?.option.value),
          );

          if (selectedValues) {
            onCountriesUpdated(
              selectedValues
                .filter((value) => {
                  return value.value !== currentValue?.option.value;
                })
                .map((value) => {
                  return {
                    code: value.value,
                    name: value.label,
                  };
                }),
            );
          }
        } else {
          setSelectedValues((prev) => [...prev, currentValue?.option!]);
          onCountriesUpdated(
            [...selectedValues, currentValue?.option!].map((value) => {
              return {
                code: value.value,
                name: value.label,
              };
            }),
          );
        }
      }}
      disablePortal
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          className="country-input"
          {...params}
          placeholder="country"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ mr: 2, color: theme.colors?.primary.parchment }}
              >
                <IconLinearArrowDown size={20} />
              </InputAdornment>
            ),
          }}
          sx={{
            mt: 4,
            '& .MuiOutlinedInput-root': {
              // height: theme.spacing(10),
              py: '6px !important',
              px: '12px !important',
              width: theme.spacing(180),
              backgroundColor: 'rgba(255, 255, 255, 0.10)',
              color: theme.colors?.primary.parchment,
              borderRadius: 4,

              input: {
                ...typography['headline-lg'],
                fontSize: theme.spacing(7),
                p: `${theme.spacing(6)} !important`,
                borderRadius: 4,
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
            ...componentProps?.textField?.sx,
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          className="country-option"
          component="li"
          {...props}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            my: theme.spacing(2),
          }}
        >
          <Typography
            variant="body-lg"
            fontWeight={500}
            display="flex"
            flex={1}
            alignItems="center"
          >
            <Box component="span" pr={2} fontSize={theme.spacing(5)}>
              {getEmojiFlag(option.value as TCountryCode)}
            </Box>
            {option.label}
          </Typography>

          {selectedValues.find((x) => x.value === option.value) ? (
            <IconBoldTickCircle size={20} />
          ) : (
            <IconLinearAddCircle size={16} />
          )}
        </Box>
      )}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          return (
            <Chip
              key={index}
              className="country-tag"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                my: theme.spacing(6),
                mx: theme.spacing(1),
                color: theme.colors?.primary.parchment,
                backgroundColor: 'rgba(217, 227, 243, 0.30)',
                border: 'none',
              }}
              label={
                <Box display="flex" alignItems="center" gap={1} sx={{}}>
                  <Typography
                    variant="body-lg"
                    fontWeight={500}
                    display="flex"
                    flex={1}
                  >
                    <Box component="span" pr={2} fontSize={theme.spacing(5)}>
                      {getEmojiFlag(option.value as TCountryCode)}
                    </Box>

                    {option.label}
                  </Typography>
                  <IconButton
                    sx={{ p: 0, color: theme.colors?.primary.parchment }}
                    onClick={() => {
                      setSelectedValues((prev) =>
                        prev.filter((item) => item.label !== option.label),
                      );
                      onCountriesUpdated(
                        selectedValues
                          .filter((value) => {
                            return value.label !== option.label;
                          })
                          .map((value) => {
                            return {
                              code: value.value,
                              name: value.label,
                            };
                          }),
                      );
                    }}
                  >
                    <IconBoldCloseCircle size={20} />
                  </IconButton>
                </Box>
              }
            />
          );
        });
      }}
      popupIcon={null}
      clearIcon={null}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) => {
          if (searchStr === '') {
            return true;
          }

          return option.label.toLowerCase().includes(searchStr.toLowerCase());
        });

        return filtered;
      }}
    />
  );
};
