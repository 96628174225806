import { Box, Typography } from '@mui/material';
import { UserFragmentAvatarGroupFragment } from 'graphql/generated';
import { useMemo, useState } from 'react';
import { Avatar } from 'components/common/AvatarGroup';
import { Tabs } from 'components/common/Tabs';
import { useUserContext } from 'contexts/users/User.context';
import { usePageAnalytics } from 'hooks/useAnalytics';
import { theme } from 'styles/theme/theme';
import { getFullName } from 'utils/users';
import { PasswordTab } from './components/PasswordTab';
import { ProfileTab } from './components/ProfileTab';

type TabKey = 'profile' | 'password';

export const Account = () => {
  usePageAnalytics('Account');

  const { user } = useUserContext();
  const [tab, setTab] = useState<TabKey>('profile');

  const tabs = useMemo(() => {
    return [
      {
        key: 'profile',
        label: 'Profile',
        content: <ProfileTab />,
      },
      {
        key: 'password',
        label: 'Password',
        content: <PasswordTab />,
      },
    ];
  }, []);

  if (!user) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={10}>
      <Typography variant="headline-lg">Account Settings</Typography>

      <Box display="flex" gap={3} alignItems="center">
        <Avatar user={user as UserFragmentAvatarGroupFragment} size={80} />
        <Box display="flex" flexDirection="column">
          <Typography variant="headline-md">{getFullName(user)}</Typography>
          <Typography variant="body-md" color={theme.colors?.utility[700]}>
            {user.email}
          </Typography>
        </Box>
      </Box>

      <Tabs tabs={tabs} tab={tab} onChange={(t) => setTab(t as TabKey)} />
    </Box>
  );
};
