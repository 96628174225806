import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconOutlineVideo } from 'components/icons/components/outline/IconOutlineVideo';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { PlotFeature } from 'graphql/generated';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export const SocialListeningSidebarLink = () => {
  const { isFeatureEnabled } = useFeatureFlagContext();
  const competitorAnalysisEnabled = isFeatureEnabled(
    PlotFeature.CompetitorAnalysis,
  );
  const { user } = useUserContext();
  const hasSocialListeningData =
    !!user?.organization.socialListeningBrands.length;

  return (
    <Accordion
      sx={{
        '&.MuiPaper-root': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: 'none',
        },
        '& .MuiAccordionSummary-root': {
          minHeight: 'unset !important',
          my: 0,
          padding: 0,
        },
        '& .MuiAccordionSummary-content': {
          margin: `0 !important`,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<IconLinearArrowDown size={16} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconOutlineVideo size={20} />
          <Typography
            ml={3}
            variant="subhead-md"
            color={theme.colors?.utility[900]}
            width="100%"
          >
            Social Listening
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {hasSocialListeningData ? (
          <>
            <Box
              component={Link}
              to={PlotRoutes.socialListening()}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                pb: 1,
              }}
            >
              <Typography
                ml={3}
                variant="subhead-md"
                width="100%"
                sx={{
                  color: theme.colors?.utility[700],
                }}
              >
                Engagement
              </Typography>
            </Box>
            <Box
              component={Link}
              to={PlotRoutes.socialListeningAnalyticsInbound()}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                pb: 1,
              }}
            >
              <Typography
                ml={3}
                variant="subhead-md"
                width="100%"
                sx={{
                  color: theme.colors?.utility[700],
                }}
              >
                Brand Analytics
              </Typography>
            </Box>
            {competitorAnalysisEnabled && (
              <Box
                component={Link}
                to={PlotRoutes.socialListeningCompetitors()}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: 2,
                  pb: 1,
                }}
              >
                <Typography
                  ml={3}
                  variant="subhead-md"
                  width="100%"
                  sx={{
                    color: theme.colors?.utility[700],
                  }}
                >
                  Competitor Analytics
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box
            component={Link}
            to={PlotRoutes.socialListeningIntro()}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              pb: 1,
            }}
          >
            <Typography
              ml={3}
              variant="subhead-md"
              width="100%"
              sx={{
                color: theme.colors?.utility[700],
              }}
            >
              Intro
            </Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
