import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { typography } from 'components/common/Typography/styles';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import {
  ExpandableText,
  SentimentIcon,
} from 'features/socialMediaListening/components';
import { SocialMediaListeningPostContextMenu } from 'features/socialMediaListening/components/postContextMenu';
import { SocialMediaListeningTopicChips } from 'features/socialMediaListening/components/topicChips/SocialMediaListeningTopicChips';
import {
  useBrandDetails,
  useSocialListeningPostLinkGenerator,
} from 'features/socialMediaListening/hooks';
import { SocialPostBrandEngagementStatus } from 'features/socialPost';
import { BrandSocialPostEngagementStatus } from 'graphql/generated';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SocialMediaListeningListItemViewProps } from './types';

export const SocialMediaListeningListItem = ({
  post,
  searchCriteria,
  itemPosition,
}: SocialMediaListeningListItemViewProps) => {
  const { brand } = useBrandDetails();

  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const contextMenuHandlers = useDisclosure();

  const [isPassed, setIsPassed] = useState(false);
  const navigate = useNavigate();
  const { generateLink } = useSocialListeningPostLinkGenerator();
  const topics =
    post.socialPostEngagementSuggestions?.map(
      (suggestion) => suggestion.topic,
    ) || [];

  const stopEvents = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <TableRow
        sx={{ cursor: 'pointer', display: isPassed ? 'none' : 'table-row' }}
        onClick={() =>
          navigate({
            pathname: PlotRoutes.socialListeningPost(post.id),
            search: `?searchCriteria=${searchCriteria}&position=${itemPosition}`,
          })
        }
        onContextMenu={(e) => {
          stopEvents(e);
          setContextMenuAnchorPosition({
            top: e.clientY,
            left: e.clientX,
          });
          contextMenuHandlers.onOpen();
        }}
      >
        <TableCell>
          {post.thumbnailUrl ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: theme.spacing(10),
                height: 'auto',
                minHeight: theme.spacing(8),
                borderRadius: 2,
                backgroundColor: theme.colors?.utility[275],
              }}
              component="img"
              src={post.thumbnailUrl || ''}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: theme.spacing(10),
                height: 'auto',
                minHeight: theme.spacing(8),
                borderRadius: 2,
                backgroundColor: theme.colors?.utility[275],
              }}
              component="video"
              src={post.videoUrl || ''}
            />
          )}
        </TableCell>
        <TableCell>
          {post.socialPostEngagementSuggestions?.[0].oneLineSummary && (
            <ExpandableText
              text={
                post.socialPostEngagementSuggestions[0].oneLineSummary || ''
              }
              collapsedHeight={44}
            />
          )}
        </TableCell>
        <TableCell>
          <Box
            sx={{ width: theme.spacing(28), minWidth: theme.spacing(28) }}
            onClick={(e) => e.stopPropagation()}
          >
            <SocialPostBrandEngagementStatus
              brandId={brand?.id ?? ''}
              socialPost={post}
              onUpdateCallBack={async (status) => {
                if (status === BrandSocialPostEngagementStatus.Pass) {
                  setIsPassed(true);
                } else {
                  setIsPassed(false);
                }
              }}
            />
          </Box>
        </TableCell>
        <TableCell>
          {post.socialPostEngagementSuggestions?.[0].sentiment && (
            <SentimentIcon
              sentiment={post.socialPostEngagementSuggestions?.[0].sentiment}
              size={24}
            />
          )}
        </TableCell>
        <TableCell>
          {typeof post.creator?.followerCount === 'number' && (
            <Typography
              variant="body-lg"
              color={theme.colors?.primary.black}
              fontWeight={500}
            >
              {formatBigNumber(post.creator?.followerCount)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {typeof post.socialPostEngagementSuggestions?.[0].score ===
            'number' && (
            <Typography
              variant="body-lg"
              color={theme.colors?.primary.black}
              fontWeight={500}
            >
              {parseFloat(
                (post.socialPostEngagementSuggestions[0].score * 100).toFixed(
                  1,
                ),
              )}
              %
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {typeof post.engagementRate === 'number' && (
            <Typography
              variant="body-lg"
              color={theme.colors?.primary.black}
              fontWeight={500}
            >
              {parseFloat(((post.engagementRate || 0) * 100).toFixed(1))}%
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography
            variant="body-lg"
            color={theme.colors?.primary.black}
            fontWeight={500}
          >
            -
          </Typography>
        </TableCell>
        <TableCell>
          {typeof post.playCount === 'number' && (
            <Typography
              variant="body-lg"
              color={theme.colors?.primary.black}
              fontWeight={500}
            >
              {formatBigNumber(post.playCount)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <SocialMediaListeningTopicChips
            topics={topics.map((topic) => topic?.name || '')}
            maxTopicsToShow={2}
            componentProps={{
              chip: {
                sx: {
                  ...typography['body-lg'],
                  fontWeight: 500,
                  color: theme.colors?.utility[900],
                },
              },
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton disableRipple>
            <Box component="a" href={generateLink(post)} target="_blank">
              <IconLinearExport2 size={16} color={theme.colors?.utility[700]} />
            </Box>
          </IconButton>
        </TableCell>
      </TableRow>
      <Box id="social-media-post-creative-juicebox-popover" />
      {contextMenuHandlers.isOpen && contextMenuAnchorPosition && (
        <SocialMediaListeningPostContextMenu
          open
          socialPostId={post.id}
          anchorPosition={contextMenuAnchorPosition}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setContextMenuAnchorPosition(null);
            contextMenuHandlers.onClose();
          }}
        />
      )}
    </>
  );
};
