import { Box } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { PlotFeature } from 'graphql/generated';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SocialListeningSettingsCompetitorsView } from '../settingsCompetitors';
import { SocialListeningSettingsGeneral } from '../settingsGeneral';
import { SocialListeningSettingsTopicsView } from '../settingsTopics';

type Props = {};

type TabType = 'general' | 'topics' | 'competitors';

export const SocialListeningSettingsView = (props: Props) => {
  const [params] = useSearchParams();
  const defaultTab = params.get('tab');
  const currentTopicId = params.get('topicId');

  const [tab, setTab] = useState((defaultTab || 'general') as TabType);
  const { isFeatureEnabled, loading } = useFeatureFlagContext();

  const competitorAnalysisEnabled = isFeatureEnabled(
    PlotFeature.CompetitorAnalysis,
  );

  return (
    <Box
      sx={{
        width: '100%',
        fontSize: theme.spacing(4),

        '& .settings-container': {
          width: '100%',
          height: `calc(100% - ${theme.spacing(20)})`,
        },
        '& .MuiTabPanel-root': {
          height: '100%',
          overflowY: 'auto',
        },
        '& button.MuiTab-root': {
          pb: 0,
          fontSize: theme.spacing(4),
          my: 3,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Tabs
        className="settings-container"
        tab={tab}
        onChange={(val) => {
          setTab(val as TabType);
        }}
        tabs={[
          {
            key: 'general',
            label: <Box>General</Box>,
            content: <SocialListeningSettingsGeneral />,
          },
          {
            key: 'topics',
            label: <Box>Topics</Box>,
            content: (
              <SocialListeningSettingsTopicsView
                currentTopicId={currentTopicId || ''}
              />
            ),
          },
          ...(competitorAnalysisEnabled
            ? [
                {
                  key: 'competitors',
                  label: <Box>Competitors</Box>,
                  content: <SocialListeningSettingsCompetitorsView />,
                },
              ]
            : []),
        ]}
      />
    </Box>
  );
};
