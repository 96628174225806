import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { IconLinearArrowRight2 } from 'components/icons/components/linear/IconLinearArrowRight2';
import { useUserContext } from 'contexts/users/User.context';
import { getPostIconTypeName } from 'features/post/components/icon/utils';
import {
  TrendingPreview,
  TrendingSaveButton,
} from 'features/trending/components';
import { getTrendingStatusText } from 'features/trending/constants';
import { TrendingFilterPill } from 'features/trending/filters';
import {
  TrendCategory,
  TrendFragmentTrendingCardViewFragment,
  TrendFragmentTrendingPreviewFragmentDoc,
  UrlMetadataFragmentIframelyFragmentDoc,
} from 'graphql/generated';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { getValidatedMimeType } from 'utils/mime';
import { formatBigNumber, formatNumber } from 'utils/number';

export const TREND_FRAGMENT_TRENDING_CARD_VIEW = gql`
  fragment TrendFragmentTrendingCardView on TrendModel {
    id
    description
    title
    category
    status
    trendingDate
    lastViewed
    createdAt
    trendAudio {
      id
      changePercentage
      totalVideoCount
    }
    linkedPosts {
      id
      isFavoriteTrend
      creator {
        id
      }
      urlMetadata {
        id
      }
    }
    previewUrlMetadata {
      id
      ...URLMetadataFragmentIframely
    }
    ...TrendFragmentTrendingPreview
  }
  ${TrendFragmentTrendingPreviewFragmentDoc}
  ${UrlMetadataFragmentIframelyFragmentDoc}
`;

interface Props {
  selectedCategories?: TrendCategory[];
  selectedCustomPersonaIds?: string[];
  trend: TrendFragmentTrendingCardViewFragment;
}

export const TrendingCardView = ({
  trend,
  selectedCategories = [],
  selectedCustomPersonaIds = [],
}: Props) => {
  const { user } = useUserContext();

  const urlMetadata = trend.previewUrlMetadata;
  const { icon: PostIcon } = getPostIconTypeName({
    site: urlMetadata?.metadata?.site || '',
    medium: urlMetadata?.metadata?.medium || '',
    mimeType: getValidatedMimeType(
      urlMetadata?.metadata?.type || '',
      urlMetadata?.url || '',
    ),
    url: urlMetadata?.url || '',
  });

  const userCreatedPost = trend.linkedPosts.find(
    (p) => p.creator.id === user?.id && p.isFavoriteTrend,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box
        sx={{
          height: 330,
          position: 'relative',
          borderRadius: 3,
          bgcolor: theme.colors?.primary.parchment,
          iframe: {
            padding:
              urlMetadata?.metadata?.site?.toLowerCase() === 'twitter'
                ? 2.5
                : 0,
            height: '100% !important',
            width: '100% !important',
          },
        }}
      >
        <Link
          to={{
            pathname: PlotRoutes.trendingJuice(trend.id),
          }}
          state={{
            persona: selectedCustomPersonaIds,
            trendCategories: selectedCategories,
          }}
          style={{
            borderRadius: 'inherit',
          }}
        >
          <TrendingPreview trend={trend} disableMouseEvents />
        </Link>

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            p: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: `-webkit-fill-available`,
            whiteSpace: 'nowrap',
            background: `linear-gradient(180deg, rgba(15, 15, 15, 0.4) 0.99%, rgba(15, 15, 15, 0.00) 100%)`,
            borderRadius: 'inherit',
          }}
        >
          {trend.status && (
            <Box
              sx={{
                borderRadius: 1.5,
                bgcolor: theme.colors?.primary.black,
                padding: theme.spacing(1, 2),
                width: 'fit-content',
              }}
            >
              <Typography
                variant="headline-sm"
                color={theme.colors?.primary.parchment}
              >
                {getTrendingStatusText(trend.status)}
              </Typography>
            </Box>
          )}
          {trend.trendAudio && (
            <>
              {!!trend.trendAudio?.totalVideoCount &&
                trend.trendAudio?.totalVideoCount > 0 && (
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.primary.parchment}
                  >
                    {formatBigNumber(trend.trendAudio.totalVideoCount)} videos
                  </Typography>
                )}
              {!!trend.trendAudio?.changePercentage &&
                trend.trendAudio?.changePercentage > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="headline-sm"
                      color={theme.colors?.primary.parchment}
                    >
                      •
                    </Typography>
                    <Box>
                      <IconLinearArrowRight2
                        color={theme.colors?.utility['green-3-new']}
                      />
                    </Box>
                    <Typography
                      variant="headline-sm"
                      color={theme.colors?.utility['green-3-new']}
                    >
                      {formatNumber(trend.trendAudio?.changePercentage, 2)}%
                    </Typography>
                  </Box>
                )}
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: `100%`,
            }}
          >
            <PostIcon size={18} color={theme.colors?.primary.black} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TrendingFilterPill
          filter={trend.category}
          sx={{
            gap: 3,
            '& span': {
              ...theme.typography['headline-xs'],
            },
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {trend.linkedPosts.length > 0 && (
            <Typography variant="subhead-lg" color={theme.colors?.utility[600]}>
              {trend.linkedPosts.length}{' '}
              {trend.linkedPosts.length > 1 ? 'saves' : 'save'}
            </Typography>
          )}
          {urlMetadata && (
            <TrendingSaveButton
              isSavedToTrendsCollection={!!userCreatedPost?.isFavoriteTrend}
              trendId={trend.id}
              postId={userCreatedPost?.id}
              renderBtn={() => (
                <IconButton
                  sx={{
                    padding: 0,
                  }}
                  disableRipple
                >
                  <IconBoldSave
                    size={24}
                    color={
                      userCreatedPost?.id
                        ? theme.colors?.primary.black
                        : theme.colors?.utility[400]
                    }
                  />
                </IconButton>
              )}
            />
          )}
        </Box>
      </Box>
      <Typography variant="headline-xl" fontSize={20} fontWeight={500}>
        {trend.title}
      </Typography>
    </Box>
  );
};
