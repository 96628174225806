import { gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useUserContext } from 'contexts/users/User.context';
import { UpdateCollectionPermissionsDataInput } from 'features/collectionPermission/hooks';
import { useCollectionPermissionCollectionTreeSelectionState } from 'features/collectionPermission/hooks/useCollectionPermissionCollectionTreeSelectionState';
import { useCollectionPermissionUpdateNestedCollection } from 'features/collectionPermission/hooks/useCollectionPermissionUpdateNestedCollection';
import { UserProfileChipView } from 'features/user-profile';
import {
  CollectionFragmentManyPostsPermissionDialogFragment,
  GeneralPermission,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { CollectionPermissionCollectionTreeView } from '../collectionTree/CollectionPermissionCollectionTreeView';

export const COLLECTION_FRAGMENT_MANY_POSTS_PERMISSION_DIALOG = gql`
  fragment CollectionFragmentManyPostsPermissionDialog on CollectionModel {
    id
    name
    generalPermission
    permissionLevel
    organizationId
    organizationName
  }
`;

type ManyPostsPermissionDialogProps = {
  collection: CollectionFragmentManyPostsPermissionDialogFragment;
  isOpen: boolean;
  onClose: VoidFunction;
  onNavigateBack?: VoidFunction;
  onCallBackAfterSubmit?: VoidFunction;

  inputData: UpdateCollectionPermissionsDataInput;
  setInputData: (data: UpdateCollectionPermissionsDataInput) => void;
};

export const ManyPostsPermissionDialog = (
  props: ManyPostsPermissionDialogProps,
) => {
  const {
    collection,
    isOpen,
    onClose,
    onNavigateBack,
    onCallBackAfterSubmit,
    inputData,
  } = props;
  const { handleUpdateNestedCollectionPostPermissions } =
    useCollectionPermissionUpdateNestedCollection({
      collectionId: collection.id,
      sendNotification: true,
    });

  const { user } = useUserContext();
  const allUsers = useMemo(
    () => [
      ...(user?.organization.users || []),
      ...(user?.organization.externalUsers || []),
    ],
    [user],
  );

  const getMemberById = (id: string) => {
    return allUsers.find((x) => x.id === id);
  };

  const getMemberByEmail = (email: string) => {
    return allUsers.find((x) => x.email === email);
  };

  const { permissionData, updatePermissionData } =
    useCollectionPermissionCollectionTreeSelectionState();

  const [isMainModalVisible, setIsMainModalVisible] = useState(true);

  useEffect(() => {
    setIsMainModalVisible(true);
  }, [isOpen]);

  const { dialog: confirmModal, onOpen: openConfirmModal } =
    useConfirmationDialog({
      sx: {
        '& .MuiButton-primary': {
          borderRadius: theme.spacing(2),
          border: 'none',
          backgroundColor: `${theme.colors?.primary.black} !important`,
        },
        '& .MuiButton-primary:hover': {
          border: 'none',
          backgroundColor: `${theme.colors?.primary.black} !important`,
        },
      },
    });

  const [loading, setLoading] = useState(false);

  const handleUpdatePostsPermission = async () => {
    setLoading(true);

    await handleUpdateNestedCollectionPostPermissions(
      inputData,
      permissionData,
    );

    setLoading(false);
    onCallBackAfterSubmit?.();
    onClose();
  };

  const hideDialog = () => {
    setIsMainModalVisible(false);
    openConfirmModal({
      title: 'Your changes were not saved. Save before leaving?',
      confirmText: 'Save changes',
      cancelText: 'Continue without saving',
      onConfirm: () => {
        handleUpdatePostsPermission();
      },
      onCancel: () => onClose(),
    });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={false}
        PaperProps={{
          sx: {
            border: 'none',
            display: isMainModalVisible ? 'flex' : 'none',
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(250, 243, 236, 0.8)',
            backdropFilter: 'blur(8px)',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="rgba(250, 243, 236, 0.50)"
            borderBottom="2px solid rgba(35, 6, 3, 0.1)"
            padding={theme.spacing(5, 6)}
          >
            <Box display="flex" gap={theme.spacing(3)} alignItems="center">
              {onNavigateBack && (
                <IconButton sx={{ p: 0 }} onClick={onNavigateBack}>
                  <IconOutlineArrowLeft />
                </IconButton>
              )}
              <Typography variant="headline-lg">{collection.name}</Typography>
            </Box>

            <IconButton onClick={hideDialog}>
              <IconBoldCloseCircle />
            </IconButton>
          </Box>

          <Box
            display="flex"
            gap={theme.spacing(4)}
            alignItems="flex-start"
            flexDirection="column"
            padding={theme.spacing(6)}
          >
            <Typography
              variant="headline-lg"
              fontSize="28px"
              fontWeight="500"
              color={theme.colors?.utility[900]}
            >
              Select the posts you'd like to update share access to:{' '}
            </Typography>
          </Box>

          <Box padding={theme.spacing(0, 6)} height="58vh">
            <CollectionPermissionCollectionTreeView
              selectAllChildren
              collectionId={collection.id}
              updatePermissionData={updatePermissionData}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="rgba(250, 243, 236, 0.8)"
            borderTop="2px solid rgba(35, 6, 3, 0.1)"
            padding={theme.spacing(5, 6)}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              {(!!inputData?.inviteMembers?.members?.length ||
                inputData.generalPermission?.generalPermission ===
                  GeneralPermission.OrganizationMembers) && (
                <Box display="flex" gap={theme.spacing(2)} alignItems="center">
                  <Typography
                    variant="subhead-xl"
                    color={theme.colors?.utility[800]}
                  >
                    🟢 Adding:
                  </Typography>
                  {inputData.generalPermission?.generalPermission ===
                    GeneralPermission.OrganizationMembers && (
                    <Box>
                      <Box
                        display="flex"
                        gap={2}
                        alignItems="center"
                        sx={{
                          backgroundColor: 'rgba(35, 6, 3, 0.1)',
                          p: theme.spacing(2, 4),
                          borderRadius: theme.spacing(4),
                        }}
                      >
                        <IconBoldBuildings size={16} />
                        <Typography variant="body-md">
                          Everyone at {user?.organization.name}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {inputData?.inviteMembers?.members.map((m) => {
                    const user = getMemberByEmail(m.email);
                    if (user)
                      return (
                        <UserProfileChipView
                          key={m.email}
                          userProfile={user}
                          sx={{
                            bgcolor: 'rgba(35, 6, 3, 0.1)',
                          }}
                        />
                      );

                    return (
                      <Box
                        key={m.email}
                        sx={{
                          display: 'flex',
                          gap: 2,
                          alignItems: 'center',
                          backgroundColor: 'rgba(35, 6, 3, 0.1)',
                          p: theme.spacing(1),
                          pr: theme.spacing(2),
                          borderRadius: theme.spacing(4),
                        }}
                      >
                        <Avatar
                          sx={{
                            width: theme.spacing(6),
                            height: theme.spacing(6),
                          }}
                        />
                        <Typography variant="body-md">{m.email}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              )}
              {(!!inputData?.removeMembers?.userIds?.length ||
                inputData.generalPermission?.generalPermission ===
                  GeneralPermission.InviteOnly) && (
                <Box display="flex" gap={theme.spacing(2)} alignItems="center">
                  <Typography
                    variant="subhead-xl"
                    color={theme.colors?.utility[800]}
                  >
                    ⛔️ Removing:
                  </Typography>
                  {inputData.generalPermission?.generalPermission ===
                    GeneralPermission.InviteOnly && (
                    <Box>
                      <Box
                        display="flex"
                        gap={2}
                        alignItems="center"
                        sx={{
                          backgroundColor: 'rgba(35, 6, 3, 0.1)',
                          p: theme.spacing(2, 4),
                          borderRadius: theme.spacing(4),
                        }}
                      >
                        <IconBoldBuildings size={16} />
                        <Typography variant="body-md">
                          Everyone at {user?.organization.name}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {inputData?.removeMembers?.userIds.map((memberId) => {
                    const user = getMemberById(memberId);
                    if (user)
                      return (
                        <UserProfileChipView
                          key={memberId}
                          userProfile={user}
                          sx={{
                            bgcolor: 'rgba(35, 6, 3, 0.1)',
                          }}
                        />
                      );
                  })}
                </Box>
              )}
            </Box>
            <Box ml="auto">
              <Button
                variant="contained"
                onClick={handleUpdatePostsPermission}
                sx={{
                  bgcolor: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  ':hover': {
                    bgcolor: theme.colors?.primary.black,
                    opacity: 0.7,
                  },
                }}
                startIcon={loading ? <CircularProgress size="1rem" /> : null}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {confirmModal}
    </>
  );
};
