import { gql } from '@apollo/client';
import {
  Autocomplete,
  Box,
  createFilterOptions,
  TextField,
} from '@mui/material';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  CollectionBreadcrumbs,
  CollectionThumbnail,
} from 'features/collection/components';
import { useMoveToAnotherCollectionCollectionsList } from 'features/collection/hooks/useMoveToAnotherCollectionCollectionsList';
import {
  COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
  CollectionMenuItemAddNewView,
} from 'features/collection/views/menuItem';
import { CollectionPermissionSummaryView } from 'features/collectionPermission';
import {
  CollectionFragmentCollectionNameFragment,
  CollectionFragmentCollectionNameFragmentDoc,
  useGetCollectionForCollectionMenuItemViewLazyQuery,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { theme } from 'styles/theme';

type OptionType = {
  value: string;
  label: string;
};
const filter = createFilterOptions<OptionType>();

type CollectionSearchAutocompleteSectionProps = {
  collectionIdToMove?: string;
  onSelect: (collectionId: string) => void;
  destinationCollectionId: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetCollectionForCollectionSearchAutocompleteSection(
    $collectionId: String!
  ) {
    collection(id: $collectionId) {
      id
      ...CollectionFragmentCollectionName
    }
  }
  ${CollectionFragmentCollectionNameFragmentDoc}
`;

export const CollectionSearchAutocompleteSection = ({
  collectionIdToMove,
  onSelect,
  destinationCollectionId,
}: CollectionSearchAutocompleteSectionProps) => {
  const [searchStr, setSearchStr] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(searchStr);

  const { triggerCommand } = useCommandContext();

  const debouncedSearch = useCallback(
    debounce((value) => setDebouncedQuery(value), 300),
    [],
  );

  const [getCollectionById] =
    useGetCollectionForCollectionMenuItemViewLazyQuery();

  useEffect(() => {
    debouncedSearch(searchStr);
  }, [searchStr, debouncedSearch]);

  const { collections, isLoadingCollections } =
    useMoveToAnotherCollectionCollectionsList({
      collectionIdToMove,
      collectionId: '',
      query: debouncedQuery,
    });
  const onSelectCollection = (collectionId: string) => {
    onSelect(collectionId);
    setSearchStr('');
  };
  const onCreateCollection = (
    option: OptionType,
    destinationCollection?: CollectionFragmentCollectionNameFragment,
  ) => {
    triggerCommand(COMMAND_TYPE.CREATE_COLLECTION, {
      initialValues: {
        name: option.label,
        posts: [],
        ...(destinationCollection
          ? {
              parentCollection: destinationCollection,
            }
          : {}),
      },
      onCompleted: (collection) => {
        onSelectCollection(collection.id);
      },
      onSelectExistingCollection: (collectionId) => {
        onSelectCollection(collectionId);
      },
    });
  };
  return (
    <Autocomplete
      inputValue={searchStr}
      options={[...(collections || [])].map((c) => ({
        value: c.id,
        label: c.name,
      }))}
      loading={isLoadingCollections}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
          }}
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: theme.colors?.primary.white,
              borderRadius: 4,
              pl: 4,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          placeholder="Search for a Collection"
        />
      )}
      popupIcon={null}
      getOptionLabel={(option) => option.label}
      onInputChange={(_, value) => setSearchStr(value)}
      onChange={(_, option) => {
        if (option) {
          if (option.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY) {
            if (destinationCollectionId) {
              getCollectionById({
                variables: {
                  collectionId: destinationCollectionId,
                },
                onCompleted: ({ collection: destinationCollection }) => {
                  onCreateCollection(option, destinationCollection);
                },
              });
            } else {
              onCreateCollection(option);
            }
          } else {
            onSelectCollection(option.value);
          }
        }
      }}
      renderOption={(props, option) => {
        if (option.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY) {
          return (
            <CollectionMenuItemAddNewView
              {...props}
              key={option.value}
              label={option.label}
            />
          );
        }
        const collection = [...(collections || [])].find(
          (c) => c.id === option.value,
        );

        if (!collection) {
          return null;
        }

        return (
          <Box component="span" p={1} {...props}>
            <Box display="flex" alignItems="center" gap={2}>
              <Box sx={{ width: theme.spacing(12), height: theme.spacing(9) }}>
                <CollectionThumbnail collection={collection} />
              </Box>
              <Box display="flex" flexDirection="column">
                <CollectionBreadcrumbs
                  sx={{ cursor: 'pointer', px: 2 }}
                  collection={collection}
                  ellipseAtLength={4}
                  // Disable the click event
                  onCollectionClick={() => {}}
                />
                <Box>
                  <CollectionPermissionSummaryView
                    collection={collection}
                    componentsProps={{
                      organizationBadge: {
                        sx: {
                          bgcolor: 'transparent',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const isOptionExist = options.some(
          (option) =>
            option.label.trim().toLowerCase() ===
            params.inputValue.trim().toLowerCase(),
        );

        if (params.inputValue.trim() !== '' && !isOptionExist) {
          filtered.unshift({
            label: params.inputValue,
            value: COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
          });
        }

        return filtered;
      }}
    />
  );
};
