import { gql } from '@apollo/client';
import {
  CollectionFragmentCollectionPinnedEntityDndCollectionCardFragmentDoc,
  CollectionFragmentCollectionPinnedEntityMasonryViewFragment,
  CollectionFragmentCollectionPostDndCollectionCardFragment,
  CollectionFragmentCollectionPostDndCollectionCardFragmentDoc,
  PostFragmentCollectionPostDndPostCardFragment,
  PostFragmentCollectionPostDndPostCardFragmentDoc,
} from 'graphql/generated';
import {
  CollectionPostDndMasonryView,
  CollectionPostDndMasonryViewProps,
} from 'features/collectionPostDnd/views/masonry';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionPinnedEntityDndCollectionCard on PinnedEntityModel {
    id
    collection {
      ...CollectionFragmentCollectionPostDndCollectionCard
    }
    post {
      ...PostFragmentCollectionPostDndPostCard
    }
  }
  ${CollectionFragmentCollectionPostDndCollectionCardFragmentDoc}
  ${PostFragmentCollectionPostDndPostCardFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionPinnedEntityMasonryView on CollectionModel {
    pinnedEntities {
      ...CollectionFragmentCollectionPinnedEntityDndCollectionCard
    }
  }
  ${CollectionFragmentCollectionPinnedEntityDndCollectionCardFragmentDoc}
`;

export const PINNED_ENTITIES_SECTION = 'pinnedEntitiesSection';

type CollectionPinnedEntityMasonryViewProps = Pick<
  CollectionPostDndMasonryViewProps,
  'layout'
> & {
  collection: CollectionFragmentCollectionPinnedEntityMasonryViewFragment;
};

export const CollectionPinnedEntityMasonryView = (
  props: CollectionPinnedEntityMasonryViewProps,
) => {
  const { collection, ...rest } = props;

  const pinnedCollections = (collection.pinnedEntities
    .filter((p) => !!p.collection)
    .map((p) => p.collection) ||
    []) as CollectionFragmentCollectionPostDndCollectionCardFragment[];

  const pinnedPosts = (collection.pinnedEntities
    .filter((p) => !!p.post)
    .map((p) => p.post) ||
    []) as PostFragmentCollectionPostDndPostCardFragment[];

  return (
    <CollectionPostDndMasonryView
      collections={pinnedCollections}
      posts={pinnedPosts}
      droppableId={PINNED_ENTITIES_SECTION}
      stopReorderingItems
      {...rest}
    />
  );
};
