import { Box, Typography } from '@mui/material';
import { WordLimitedInputArea } from 'features/socialMediaListening/components';
import { useBrandDataForSocialListeningOnboarding } from 'features/socialMediaListening/hooks';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type SocialMediaListeningOnboardingAboutBrandFormViewProps = {
  onNext: () => void;
};

export const SocialMediaListeningOnboardingAboutBrandFormView = ({
  onNext,
}: SocialMediaListeningOnboardingAboutBrandFormViewProps) => {
  const {
    brand,
    onboardingActions,
    queries: { getAIGeneratedBrandDescription },
  } = useBrandDataForSocialListeningOnboarding();
  const [brandDescription, setBrandDescription] = useState(
    brand?.description || '',
  );

  useEffect(() => {
    getAIGeneratedBrandDescription(brand?.name || '').then((res) => {
      if (res.data?.aiGeneratedBrandDescription && !brand?.description) {
        setBrandDescription(res.data.aiGeneratedBrandDescription || '');
      }
    });
  }, []);

  const onSubmit = async () => {
    if (brandDescription !== brand?.description) {
      await onboardingActions.handleBrandDescriptionUpdate(brandDescription);
    }
    onNext();
  };

  return (
    <Box
      width="60vw"
      maxHeight="65vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        Tell us about your brand. 🙇‍♀️
      </Typography>
      <Typography variant="body-xl" fontWeight={500}>
        Fill us in about your company. For example, what do you sell? Who is
        your target audience?
      </Typography>

      <WordLimitedInputArea
        maxWords={100}
        componentProps={{
          wordUsage: {
            sx: {
              color: theme.colors?.utility[500],
            },
          },
        }}
        value={brandDescription}
        onChange={(e) => setBrandDescription(e.target.value)}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSubmit();
          }
        }}
        helperText={
          <Typography variant="body-xl">
            Shift{' '}
            <Box
              component="span"
              p={theme.spacing(0.5, 1)}
              sx={{
                backgroundColor: 'rgba(250, 243, 236, 0.15)',
                mx: 1,
                border: `2px solid ${theme.colors?.utility[500]}`,
                borderRadius: theme.spacing(2),
                fontWeight: 600,
                fontSize: theme.spacing(3),
              }}
            >
              ⇧
            </Box>{' '}
            + Enter{' '}
            <Box
              component="span"
              p={theme.spacing(0.5, 1)}
              sx={{
                backgroundColor: 'rgba(250, 243, 236, 0.15)',
                mx: 1,
                border: `2px solid ${theme.colors?.utility[500]}`,
                borderRadius: theme.spacing(2),
                fontWeight: 600,
                fontSize: theme.spacing(3),
              }}
            >
              ⏎
            </Box>{' '}
            to make a line break
          </Typography>
        }
        autoFocus
        placeholder={`Example: Plot is a software platform designed for social media teams and creators, offering a suite of tools to plan, source, and store content seamlessly across mobile and desktop. Our end-to-end solution empowers social media teams to efficiently manage their content and collaborate effortlessly across teams. 
 
 Use cases range from saving ideas from different platforms like TikTok or Instagram, to storing photos and videos, or sourcing trends in Plot.`}
        multiline
        minRows={10}
        maxRows={16}
        sx={{
          mt: 4,
          '.MuiOutlinedInput-root': {
            borderRadius: theme.spacing(4),
            py: `${theme.spacing(8)} !important`,
            px: `${theme.spacing(8)} !important`,
            bgcolor: 'rgba(255, 255, 255, 0.10)',
            color: theme.colors?.primary.parchment,

            input: {
              p: '0 !important',
              ...theme.typography['body-xl'],
            },

            '.MuiOutlinedInput-notchedOutline': {
              display: 'none !important',
            },
          },
        }}
      />

      <RenderNextButton
        loading={onboardingActions.savingBrandData}
        onNextClick={onSubmit}
      />
    </Box>
  );
};
