import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useUserContext } from 'contexts/users/User.context';
import { getPostIconTypeName } from 'features/post/components/icon/utils';
import {
  SearchItemSearchTypeIndicator,
  SearchItemSuffix,
  SearchItemTitle,
  SearchListItemViewSearchHit,
  SearchThumbnail,
} from 'features/search';
import {
  LinkSearchHitFragmentListItemViewFragment,
  SearchableEntityType,
  useCreateCollectionSearchClickMutation,
  useCreatePostSearchClickForSearchHandlerMutation,
  useCreateTaskSearchClickMutation,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import React, { useMemo } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreatePostSearchClickForSearchHandler($data: SearchHitClickInput!) {
    createSearchClicked(data: $data) {
      post {
        id
        __typename
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateTaskSearchClick($data: SearchHitClickInput!) {
    createSearchClicked(data: $data) {
      task {
        id
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateCollectionSearchClick($data: SearchHitClickInput!) {
    createSearchClicked(data: $data) {
      collection {
        id
      }
    }
  }
`;

type SearchListItemViewProps = {
  searchHit: SearchListItemViewSearchHit;
  type: SearchableEntityType;
  variant?: 'list' | 'preview' | 'thumbnail-only';

  // TODO: remove this after fix highlight from BE
  searchString?: string;
};

export const SearchListItemView = React.forwardRef(
  (props: SearchListItemViewProps, ref: React.ForwardedRef<HTMLElement>) => {
    const { searchHit, type, variant = 'list', searchString } = props;

    const { user } = useUserContext();
    const analytics = useAnalytics();
    const navigate = useGuardNavigate();

    const [createPostSearchClick] =
      useCreatePostSearchClickForSearchHandlerMutation();
    const [createTaskSearchClick] = useCreateTaskSearchClickMutation();
    const [createCollectionSearchClick] =
      useCreateCollectionSearchClickMutation();

    const { subTitle, hoverBgColor, hoverFocusColor, onClick, PostIcon } =
      useMemo(() => {
        switch (type) {
          case SearchableEntityType.Notes:
            return {
              subTitle: 'Note',
              hoverBgColor: theme.colors?.utility['green-3'],
              hoverFocusColor: theme.colors?.utility['highlight-opacity'],
              onClick: () => {
                analytics.track(EventName.SearchBarResultClick, {
                  userId: user?.id,
                  itemType: 'Note',
                });
                navigate(PlotRoutes.juiceboxNote({ id: searchHit.item.id }));
                createPostSearchClick({
                  variables: {
                    data: {
                      query: searchString,
                      post: {
                        id: searchHit.item.id,
                      },
                    },
                  },
                });
              },
              PostIcon: null,
            };
          case SearchableEntityType.Links: {
            const link = searchHit as LinkSearchHitFragmentListItemViewFragment;
            const { icon: PostIcon } = getPostIconTypeName({
              site: link.item.urlMetadata[0].metadata?.site || undefined,
              medium: link.item.urlMetadata[0].metadata?.medium || undefined,
              mimeType: link.item.urlMetadata[0].metadata?.type || undefined,
              url: link.item.urlMetadata[0].url || undefined,
            });

            return {
              subTitle: 'Link',
              hoverBgColor: theme.colors?.utility['pink-1'],
              hoverFocusColor: theme.colors?.utility['highlight-opacity'],
              onClick: () => {
                analytics.track(EventName.SearchBarResultClick, {
                  userId: user?.id,
                  itemType: 'Link',
                });
                navigate(PlotRoutes.juice(searchHit.item.id));
                createPostSearchClick({
                  variables: {
                    data: {
                      query: searchString,
                      post: {
                        id: searchHit.item.id,
                      },
                    },
                  },
                });
              },
              PostIcon,
            };
          }

          case SearchableEntityType.Attachments: {
            return {
              subTitle: 'Attachment',
              hoverBgColor: theme.colors?.utility['yellow-1'],
              hoverFocusColor: theme.colors?.utility['highlight-opacity'],
              onClick: () => {
                analytics.track(EventName.SearchBarResultClick, {
                  userId: user?.id,
                  itemType: 'Attachment',
                });
                navigate(PlotRoutes.juice(searchHit.item.id));
                createPostSearchClick({
                  variables: {
                    data: {
                      query: searchString,
                      post: {
                        id: searchHit.item.id,
                      },
                    },
                  },
                });
              },
            };
          }

          case SearchableEntityType.Tasks:
            return {
              subTitle: 'Task',
              hoverBgColor: theme.colors?.utility['purple-1'],
              hoverFocusColor: theme.colors?.utility['highlight-opacity'],
              onClick: () => {
                analytics.track(EventName.SearchBarResultClick, {
                  userId: user?.id,
                  itemType: 'Task',
                });
                navigate(PlotRoutes.task(searchHit.item.id));
                createTaskSearchClick({
                  variables: {
                    data: {
                      query: searchString,
                      task: {
                        id: searchHit.item.id,
                      },
                    },
                  },
                });
              },
              PostIcon: null,
            };

          case SearchableEntityType.Collections:
            return {
              subTitle: 'Collection',
              hoverBgColor: theme.colors?.utility['teal-1'],
              hoverFocusColor: theme.colors?.utility['highlight-opacity'],
              onClick: () => {
                analytics.track(EventName.SearchBarResultClick, {
                  userId: user?.id,
                  itemType: 'Collection',
                });
                navigate(PlotRoutes.collection(searchHit.item.id));
                createCollectionSearchClick({
                  variables: {
                    data: {
                      query: searchString,
                      collection: {
                        id: searchHit.item.id,
                      },
                    },
                  },
                });
              },
              PostIcon: null,
            };
          default:
            return {
              subTitle: '',
              hoverBgColor: '',
              hoverFocusColor: '',
              PostIcon: null,
            };
        }
        // eslint-disable-next-line
      }, [
        analytics,
        createPostSearchClick,
        createTaskSearchClick,
        createCollectionSearchClick,
        navigate,
        searchHit,
        type,
        user?.id,
      ]);

    if (variant === 'list') {
      return (
        <Box
          ref={ref}
          onClick={onClick}
          tabIndex={0}
          my={1}
          px={3}
          sx={{
            borderRadius: theme.spacing(3),
            cursor: 'pointer',
            '&:hover': { backgroundColor: hoverBgColor },
            '&:focus': { backgroundColor: hoverBgColor, outline: 'none' },
            '& .icon-container': {
              backgroundColor: theme.colors?.utility[250],
            },
            '&:hover .icon-container': { backgroundColor: hoverFocusColor },
            '&:focus .icon-container': {
              backgroundColor: hoverFocusColor,
              outline: 'none',
            },
          }}
        >
          <Box
            py={2}
            display="flex"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap={2} maxWidth="80%">
              <Box
                display="flex"
                className="icon-container"
                borderRadius={1}
                overflow="hidden"
                flexShrink={0}
              >
                <SearchThumbnail type={type} searchHit={searchHit} />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                overflow="hidden"
                color={theme.colors?.utility[600]}
              >
                <Box
                  display="flex"
                  gap={1}
                  alignItems="center"
                  overflow="hidden"
                >
                  <SearchItemTitle searchHit={searchHit} match={searchString} />
                  <SearchItemSearchTypeIndicator
                    searchHitType={searchHit.type}
                    size={theme.spacing(3)}
                  />
                </Box>

                <Typography
                  color={theme.colors?.utility[700]}
                  fontWeight={500}
                  variant="body-sm"
                >
                  {subTitle}
                </Typography>
              </Box>
            </Box>

            <Box overflow="auto" maxWidth="20%">
              <SearchItemSuffix searchHit={searchHit} type={type} />
            </Box>
          </Box>
        </Box>
      );
    }

    if (variant === 'preview') {
      return (
        <Box
          ref={ref}
          tabIndex={0}
          padding={theme.spacing(1.5)}
          width={theme.spacing(32)}
          // height={theme.spacing(32)}
          display="flex"
          flexDirection="column"
          sx={{
            cursor: 'pointer',
            position: 'relative',
            borderRadius: theme.spacing(3),
            '&:hover': { backgroundColor: hoverBgColor },
            '&:focus': { backgroundColor: hoverBgColor, outline: 'none' },
            '& .icon-container': {
              backgroundColor: theme.colors?.utility[250],
            },
            '&:hover .icon-container': { backgroundColor: hoverFocusColor },
            '&:focus .icon-container': {
              backgroundColor: hoverFocusColor,
              outline: 'none',
            },
          }}
          onClick={onClick}
        >
          <Box
            display="flex"
            position="absolute"
            right={theme.spacing(3.5)}
            top={theme.spacing(3.75)}
          >
            <SearchItemSearchTypeIndicator
              searchHitType={searchHit.type}
              size={theme.spacing(3)}
            />
          </Box>
          <SearchThumbnail
            type={type}
            searchHit={searchHit}
            height={theme.spacing(21)}
            width="100%"
          />

          <Box width="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
              flex={1}
              minWidth={theme.spacing(29)}
            >
              {PostIcon && (
                <Box width={theme.spacing(5)} height={theme.spacing(5)}>
                  <PostIcon size={20} />
                </Box>
              )}

              <SearchItemTitle searchHit={searchHit} match={searchString} />
            </Box>
          </Box>
        </Box>
      );
    }

    if (variant === 'thumbnail-only') {
      return (
        <Box
          ref={ref}
          tabIndex={0}
          padding={theme.spacing(1.5)}
          width={theme.spacing(32)}
          height={theme.spacing(32)}
          display="flex"
          flexDirection="column"
          sx={{
            cursor: 'pointer',
            position: 'relative',
            borderRadius: theme.spacing(3),
            '&:hover': { backgroundColor: hoverBgColor },
            '&:focus': { backgroundColor: hoverBgColor, outline: 'none' },
            '& .icon-container': {
              backgroundColor: theme.colors?.utility[250],
            },
            '&:hover .icon-container': { backgroundColor: hoverFocusColor },
            '&:focus .icon-container': {
              backgroundColor: hoverFocusColor,
              outline: 'none',
            },
          }}
          onClick={onClick}
        >
          <Box
            display="flex"
            position="absolute"
            right={theme.spacing(3.5)}
            top={theme.spacing(3.75)}
          >
            <SearchItemSearchTypeIndicator
              searchHitType={searchHit.type}
              size={theme.spacing(3)}
            />
          </Box>
          <SearchThumbnail
            type={type}
            searchHit={searchHit}
            width="100%"
            height="100%"
          />
        </Box>
      );
    }

    return null;
  },
);
