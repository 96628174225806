/**
 * This component's rendering logic is very complicated as it has to:
 * 1. Support drag & drop while maintaining a small drag handle
 * 2. Allow users to click on the post to navigate to the post page
 * 3. Allow users to right click on the post to open the context menu
 * 4. Allow users to react to the post
 * 5. Allow users to hover over creator & see metadata
 *
 * FIXME: Improve this. This definitely is not the best way to do this right now but it works
 * and I'm in a rush so I'll leave it as is for now.
 *
 * For a similar component with a simpler logic, see client/src/features/post/views/masonryCard/PostMasonryCardView.tsx
 */

import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { getNoteColorFromColor } from 'features/note';
import {
  PostContextMenu,
  PostCreator,
  PostIcon,
  PostNewBadge,
  PostPreview,
  PostReactionList,
  PostTitle,
  PostUnreadCommentBadge,
} from 'features/post';
import { usePostPermissionDialogView } from 'features/post-permission/hooks/usePostPermissionDialogView';
import { StyledRightClickContextMenuWrapper } from 'features/post/views/masonryCard/styles';
import {
  PostFragmentCollectionPostDndPostCardFragment,
  PostFragmentCollectionThumbnailFragmentDoc,
  PostFragmentPostContextMenuFragmentDoc,
  PostFragmentPostCreatorFragmentDoc,
  PostFragmentPostNewBadgeFragmentDoc,
  PostFragmentPostPermissionSummaryFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  PostFragmentPostReactionListFragmentDoc,
  PostFragmentPostTitleFragmentDoc,
  PostFragmentPostUnreadCommentBadgeFragmentDoc,
  PostType,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useMemo, useState } from 'react';
import {
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Link, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_COLLECTION_POST_DND_POST_CARD = gql`
  fragment PostFragmentCollectionPostDndPostCard on PostModel {
    id
    # Need this because there are commands that depend on this data
    # See COMMAND_TYPE.SEND_TO_COLLECTION
    collections {
      id
    }
    description
    ...PostFragmentPostPreview
    ...PostFragmentPostUnreadCommentBadge
    ...PostFragmentPostNewBadge
    ...PostFragmentPostContextMenu
    ...PostFragmentPostReactionList
    ...PostFragmentPostCreator
    ...PostFragmentCollectionThumbnail
    ...PostFragmentPostPermissionSummary
    ...PostFragmentPostTitle
  }
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentPostUnreadCommentBadgeFragmentDoc}
  ${PostFragmentPostNewBadgeFragmentDoc}
  ${PostFragmentPostContextMenuFragmentDoc}
  ${PostFragmentPostReactionListFragmentDoc}
  ${UserFragmentAvatarGroupFragmentDoc}
  ${PostFragmentPostCreatorFragmentDoc}
  ${PostFragmentCollectionThumbnailFragmentDoc}
  ${PostFragmentPostPermissionSummaryFragmentDoc}
  ${PostFragmentPostTitleFragmentDoc}
`;

export const POST_DRAGGABLE_PREFIX = 'draggable-post';

export type CollectionPostDndPostCardProps = {
  post: PostFragmentCollectionPostDndPostCardFragment;
  currentCollectionId?: string;
  index: number;
  selectable?: boolean;
  selected?: boolean;
  isDragDisabled?: boolean;
  isSmartSearch?: boolean;
  isSelectModeActive?: boolean;
  searchParams?: Record<string, string>;
  stopReorderingItems?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onPostMovedToCollection?: (destinationCollectionId: string) => void;
};

export const CollectionPostDndPostCard = (
  props: CollectionPostDndPostCardProps,
) => {
  const {
    post,
    currentCollectionId,
    index,
    selectable = false,
    selected = false,
    isDragDisabled = false,
    isSmartSearch = false,
    isSelectModeActive,
    stopReorderingItems,
    onClick,
    onMouseDown,
    onMouseUp,
    onPostMovedToCollection,
  } = props;

  const {
    openDialog: showPostPermissionDialog,
    renderContent: renderPostPermissionDialogView,
  } = usePostPermissionDialogView({
    postId: post.id,
  });
  const location = useLocation();

  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [hoveredPostIds, setHoveredPostIds] = useState<string[]>([]);
  const [editingPostIds, setEditingPostIds] = useState<string[]>([]);

  const postColor = useMemo(() => getNoteColorFromColor(post.color), [post]);

  const isMobile = useMediaQueryMobile();

  const draggableId = `${POST_DRAGGABLE_PREFIX}-${post.id}`;

  const getPreventReorderingStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot,
    selected: boolean,
  ) => {
    if (!snapshot.isDragging || (selected && !snapshot.isDropAnimating)) {
      return {};
    }

    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  };

  return (
    <Box
      onMouseMove={(e) => {
        if (isMobile) {
          return;
        }

        const containerRect = e.currentTarget.getBoundingClientRect();
        const width = containerRect.width;
        const height = containerRect.height;

        // Constraint mouse pos to rect's boundary
        setMousePos({
          x: Math.min(
            Math.max(e.clientX - containerRect.left - 20, -20),
            width - 20,
          ),
          y: Math.min(
            Math.max(e.clientY - containerRect.top - 20, -20),
            height - 20,
          ),
        });
      }}
      sx={{
        position: 'relative',
      }}
      onMouseEnter={() => {
        setHoveredPostIds([...hoveredPostIds, post.id]);
      }}
      onMouseLeave={() => {
        setHoveredPostIds([...hoveredPostIds.filter((id) => id !== post.id)]);
      }}
    >
      {!isDragDisabled && (
        <Draggable
          draggableId={draggableId}
          key={draggableId}
          index={index}
          isDragDisabled={!selectable || isMobile}
        >
          {(draggableProvided, draggableSnapshot) => {
            return (
              <Box
                data-rbd-draggable-container-id={post.id}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                style={
                  stopReorderingItems
                    ? getPreventReorderingStyle(
                        draggableProvided.draggableProps.style,
                        draggableSnapshot,
                        selected,
                      )
                    : draggableProvided.draggableProps.style
                }
                sx={{
                  position: 'relative',
                  borderRadius: 4,
                  ...(isSmartSearch && {
                    background:
                      'linear-gradient(#faf3ec 0 0) padding-box, linear-gradient(146.79deg, rgba(141, 112, 163, 0.7) 1.07%, rgba(115, 148, 197, 0.7) 24.73%, rgba(111, 164, 157, 0.7) 49.37%, rgba(138, 161, 101, 0.7) 70.57%, rgba(211, 133, 67, 0.7) 99.65%) border-box',
                    border: '5px solid transparent',
                  }),
                  ...(selected && {
                    color: theme.colors?.primary.black,
                    outline: `3px solid ${theme.colors?.primary.black}`,
                    outlineOffset: 3,
                    backgroundColor: theme.colors?.primary.white,
                  }),
                }}
              >
                <Box
                  data-rbd-draggable-content-id={post.id}
                  sx={{
                    minHeight: 150,
                    overflow: 'hidden',
                    borderRadius: 3,
                  }}
                >
                  <Box
                    style={{
                      height: '100%',
                      minHeight: 'inherit',
                      display: 'flex',
                      position: 'relative',
                    }}
                  >
                    <PostPreview post={post} disableMouseEvents />
                  </Box>
                </Box>
                {/**
                 * Overlay that catches all mouse events in select & dnd mode.
                 * It's basically a floating div that follows the mouse cursor whenever users are hovering over the post.
                 */}
                {selectable && (
                  <Box
                    onClick={onClick}
                    onMouseDown={(e) => {
                      // Do nothing if users are using right click, as it messes with the context menu
                      if (e.button === 2) {
                        return;
                      }

                      onMouseDown?.(e);
                    }}
                    onMouseUp={onMouseUp}
                    sx={{
                      outline: 'none !important',
                      border: 'none !important',
                      position: 'absolute',
                      ...(isMobile
                        ? {
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }
                        : {
                            top: mousePos.y,
                            left: mousePos.x,
                            width: 40,
                            height: 40,
                          }),
                    }}
                  >
                    <StyledRightClickContextMenuWrapper
                      sx={{
                        height: '100%',
                        width: '100%',
                        pointerEvents: isSelectModeActive ? 'none' : 'auto',
                        bgcolor: 'transparent',
                        boxShadow: 'none',
                      }}
                      renderMenu={(props) => (
                        <PostContextMenu
                          onPostMoved={onPostMovedToCollection}
                          post={post}
                          currentCollectionId={currentCollectionId}
                          renderButton={false}
                          onPostShare={showPostPermissionDialog}
                          {...props}
                        />
                      )}
                    >
                      <Link
                        to={{
                          pathname:
                            post.type === PostType.Note
                              ? PlotRoutes.juiceboxNote({
                                  id: post.id,
                                  title: post.title,
                                })
                              : PlotRoutes.juice(post.id),
                          search: props.searchParams
                            ? `?${new URLSearchParams(
                                props.searchParams,
                              ).toString()}`
                            : '',
                        }}
                        state={{
                          ...(location?.state || ({} as any)),
                          backgroundLocation: location,
                        }}
                        style={{
                          display: 'block',
                          height: '100%',
                          width: '100%',
                        }}
                      />
                    </StyledRightClickContextMenuWrapper>
                  </Box>
                )}
                {selected && (
                  <IconBoldTickCircle
                    size={16}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      margin: 8,
                    }}
                  />
                )}
              </Box>
            );
          }}
        </Draggable>
      )}

      {isDragDisabled && (
        <Box
          sx={{
            minHeight: 150,
            overflow: 'hidden',
            borderRadius: 3,
            ...(selected && {
              color: theme.colors?.primary.black,
              outline: `3px solid ${theme.colors?.primary.black}`,
              outlineOffset: 3,
              backgroundColor: theme.colors?.primary.white,
            }),
          }}
        >
          <Box
            style={{
              height: '100%',
              minHeight: 'inherit',
              display: 'flex',
              position: 'relative',
            }}
          >
            <PostPreview post={post} disableMouseEvents />
          </Box>

          {selectable && (
            <Box
              onClick={onClick}
              onMouseDown={(e) => {
                // Do nothing if users are using right click, as it messes with the context menu
                if (e.button === 2) {
                  return;
                }

                onMouseDown?.(e);
              }}
              onMouseUp={onMouseUp}
              sx={{
                outline: 'none !important',
                border: 'none !important',
                position: 'absolute',
                ...(isMobile
                  ? {
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }
                  : {
                      top: mousePos.y,
                      left: mousePos.x,
                      width: 40,
                      height: 40,
                    }),
              }}
            >
              <StyledRightClickContextMenuWrapper
                sx={{
                  height: '100%',
                  width: '100%',
                  pointerEvents: isSelectModeActive ? 'none' : 'auto',
                  bgcolor: 'transparent',
                  boxShadow: 'none',
                }}
                renderMenu={(props) => (
                  <PostContextMenu
                    onPostMoved={onPostMovedToCollection}
                    post={post}
                    currentCollectionId={currentCollectionId}
                    renderButton={false}
                    onPostShare={showPostPermissionDialog}
                    {...props}
                  />
                )}
              >
                <Link
                  to={{
                    pathname:
                      post.type === PostType.Note
                        ? PlotRoutes.juiceboxNote({
                            id: post.id,
                            title: post.title,
                          })
                        : PlotRoutes.juice(post.id),
                    search: props.searchParams
                      ? `?${new URLSearchParams(props.searchParams).toString()}`
                      : '',
                  }}
                  state={{
                    ...(location?.state || ({} as any)),
                    backgroundLocation: location,
                  }}
                  style={{
                    display: 'block',
                    height: '100%',
                    width: '100%',
                  }}
                />
              </StyledRightClickContextMenuWrapper>
            </Box>
          )}
          {selected && (
            <IconBoldTickCircle
              size={16}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                margin: 8,
              }}
            />
          )}
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: 3,
          overflow: 'hidden',
          pointerEvents: editingPostIds.includes(post.id) ? 'auto' : 'none',
        }}
      >
        <Box
          sx={{
            width: '100%',
            p: 4,
            color:
              post.type === PostType.Note && postColor
                ? postColor.iconColor
                : theme.colors?.primary.parchment,
            display: 'flex',
            justifyContent: 'space-between',
            pointerEvents: 'auto',
            alignItems: 'flex-start',
            ...(post.type !== PostType.Note
              ? {
                  backgroundImage:
                    'linear-gradient(0deg, rgba(35, 6, 3, 0.00) 0%, rgba(35, 6, 3, 0.4) 100%)',
                }
              : {}),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: 2,
            }}
          >
            <PostCreator post={post} sx={{ pointerEvents: 'auto' }} />
            <Box
              id={post.id}
              sx={{
                opacity: [...hoveredPostIds, ...editingPostIds].includes(
                  post.id,
                )
                  ? 1
                  : 0,
              }}
            >
              {post.type !== PostType.Note && (
                <PostTitle
                  post={post}
                  variant="popover"
                  sx={{
                    borderBottom: editingPostIds.includes(post.id)
                      ? `1px dashed ${theme.colors?.utility[600]}`
                      : 'none',
                    ':hover': {
                      borderBottom: `1px dashed ${theme.colors?.utility[600]}`,
                    },
                  }}
                  onToggleEditingView={(postId) => {
                    if (editingPostIds.includes(postId)) {
                      setEditingPostIds([
                        ...editingPostIds.filter((id) => id !== postId),
                      ]);
                    } else {
                      setEditingPostIds([...editingPostIds, postId]);
                    }
                  }}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <PostNewBadge post={post} />
            <PostUnreadCommentBadge post={post} />
            <PostIcon post={post} size={20} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            px: 4,
            pb: 3,
            pt: 4.5,
            overflow: 'auto',
            width: '100%',
            backgroundImage:
              'linear-gradient(180deg, rgba(35, 6, 3, 0.00) 0%, rgba(35, 6, 3, 0.80) 100%)',
          }}
        >
          <PostReactionList
            post={post}
            variant="hide-zero"
            sx={{
              display: 'inline-flex',
              gap: 1.5,
              pointerEvents: 'auto',
            }}
            componentsProps={{
              reactionButton: {
                sx: {
                  px: 2.5,
                  py: 1,
                  color: theme.colors?.primary.parchment,
                  bgcolor: 'rgba(250, 243, 236, 0.1)',
                  borderColor: 'rgba(250, 243, 236, 0.2)',
                },
              },
            }}
          />
        </Box>
      </Box>
      {renderPostPermissionDialogView()}
    </Box>
  );
};
