import { gql } from '@apollo/client';
import {
  UpdateOrganizationUserRoleInput,
  useDeclinePendingUserRequestMutation,
  useDisableUsersMutation,
  useEnableOnlyTheseUsersMutation,
  useEnableUsersMutation,
  useRemoveGuestUserMutation,
  useUpdateOrganizationUserRoleMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { toast } from 'components/common/Toast';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation UpdateOrganizationUserRole($data: UpdateOrganizationUserRoleInput!) {
    updateOrganizationUserRole(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeclinePendingUserRequest($data: DeclinePendingUserRequestInput!) {
    declinePendingUserRequest(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DisableUsers($data: DisableUsersInput!) {
    disableUsers(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation EnableOnlyTheseUsers($data: EnableUsersInput!) {
    enableOnlyTheseUsers(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation EnableUsers($data: EnableUsersInput!) {
    enableUsers(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation RemoveGuestUser($data: String!) {
    removeGuestUser(data: $data) {
      success
      message
    }
  }
`;

export const useMembersHandlers = () => {
  const [updateUserRole] = useUpdateOrganizationUserRoleMutation();
  const [declinePendingUser] = useDeclinePendingUserRequestMutation();
  const [disableUsersMutation] = useDisableUsersMutation();
  const [removeGuestUser] = useRemoveGuestUserMutation();
  const [enableUsersMutation] = useEnableUsersMutation();
  const [enableOnlyTheseUsers] = useEnableOnlyTheseUsersMutation();

  const {
    dialog: userDisableConfirmationDialog,
    onOpen: openDeleteConfirmationDialogForUser,
  } = useConfirmationDialog();

  const {
    dialog: userRemoveGuestUserConfirmationDialog,
    onOpen: openRemoveGuestUserConfirmationDialogForUser,
  } = useConfirmationDialog();

  const {
    dialog: userEnableConfirmationDialog,
    onOpen: openUndeleteConfirmationDialogForUser,
  } = useConfirmationDialog();

  const onUserRoleChange = async (data: UpdateOrganizationUserRoleInput) => {
    await updateUserRole({
      variables: { data },
      update: (cache) => {
        modifyObject(cache, data.userId, 'UserProfileModel', {
          role: () => data.role,
        });
      },
    });
  };

  const declinePendingUserRequest = (
    userId: string,
    organizationIdToJoin: string,
  ) => {
    declinePendingUser({
      variables: {
        data: {
          userId,
          organizationIdToJoin,
        },
      },
      refetchQueries: ['GetMe'],
    });
  };

  const disableUsers = (userIds: string[], callBack?: VoidFunction) => {
    openDeleteConfirmationDialogForUser({
      title: <>Would you like to delete this user?</>,
      subtitle: 'Once you delete, this user will no longer be able to login.',
      onConfirm: async () => {
        await disableUsersMutation({
          variables: {
            data: { userIds },
          },
          refetchQueries: ['GetMe'],
        });

        toast({
          message: 'User deleted successfully',
          type: 'success',
        });

        callBack?.();
      },
    });
  };

  const enableOnlyTheseUsersInOrg = (userIds: string[]) => {
    openUndeleteConfirmationDialogForUser({
      title: <>Would you like to only enable these users?</>,
      subtitle:
        'Once you add, these user will have access to the organization and shared projects.',
      onConfirm: async () => {
        await enableOnlyTheseUsers({
          variables: {
            data: { userIds },
          },
          refetchQueries: ['GetMe'],
        });

        toast({
          message: 'User added successfully',
          type: 'success',
        });
      },
    });
  };

  const enableUsers = (userIds: string[]) => {
    openUndeleteConfirmationDialogForUser({
      title: <>Would you like to add this user?</>,
      subtitle:
        'Once you add, this user will have access to the organization and shared projects.',
      onConfirm: async () => {
        await enableUsersMutation({
          variables: {
            data: { userIds },
          },
          refetchQueries: ['GetMe'],
        });

        toast({
          message: 'User added successfully',
          type: 'success',
        });
      },
    });
  };

  const removeGuestUserHandler = async (userId: string) => {
    openRemoveGuestUserConfirmationDialogForUser({
      title: <>Would you like to remove this guest user?</>,
      onConfirm: async () => {
        await removeGuestUser({
          variables: {
            data: userId,
          },
          refetchQueries: ['GetMe'],
        });

        toast({
          message: 'Guest user removed successfully',
          type: 'success',
        });
      },
    });
  };

  return {
    removeGuestUserHandler,
    userRemoveGuestUserConfirmationDialog,
    onUserRoleChange,
    disableUsers,
    userDisableConfirmationDialog,
    enableUsers,
    enableOnlyTheseUsersInOrg,
    userEnableConfirmationDialog,
    declinePendingUserRequest,
  };
};
