import { Box, Typography } from '@mui/material';
import { Avatar, AvatarGroup } from 'components/common/AvatarGroup';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { IconBoldUser } from 'components/icons/components/bold/IconBoldUser';
import { IconCustomDone } from 'components/icons/components/custom/IconCustomDone';
import { IconOutlineAddCircle } from 'components/icons/components/outline/IconOutlineAddCircle';
import { IconOutlineProfileCircle } from 'components/icons/components/outline/IconOutlineProfileCircle';
import { useUserContext } from 'contexts/users/User.context';
import { theme } from 'styles/theme';
import { getFullName, getShortName } from 'utils/users';
import { AssigneeItemProps, AssigneeListProps } from './types';

const FALLBACK_UNASSIGN_ID = '-1';

export const AssigneeList = (props: AssigneeListProps) => {
  const {
    componentsProps = {},
    onChange,
    assignees,
    shouldShowEmptyText,
    sx,
  } = props;

  const { user } = useUserContext();

  const users = [
    ...(user?.organization.users || []),
    ...(user?.organization.externalUsers || []),
  ];

  const autocompleteOptions = [
    { value: FALLBACK_UNASSIGN_ID, label: 'Unassigned' },
    ...users.map((u) => ({
      label: `${getFullName(u)} ${u.email}`,
      value: u.id,
    })),
  ];

  return (
    <Box display="flex" flexDirection="row" gap={2} overflow="auto">
      <AutocompletePopup
        value={assignees.map((assignee) => ({
          value: assignee.id,
          label: `${getFullName(assignee)} ${assignee.email}`,
        }))}
        multiple
        options={autocompleteOptions}
        renderTrigger={() => {
          return (
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(1),
                alignItems: 'center',
                ...sx,
              }}
            >
              {assignees.length ? (
                <AvatarGroup
                  users={assignees}
                  avatarSize={24}
                  {...componentsProps.avatarGroup}
                />
              ) : (
                <IconBoldUser
                  size={componentsProps.icon?.size || 24}
                  color={
                    componentsProps.icon?.color || theme.colors?.utility[700]
                  }
                />
              )}
              {shouldShowEmptyText && (
                <Typography
                  variant="subhead-xl"
                  color={theme.colors?.utility[700]}
                >
                  {!assignees.length
                    ? 'Assign Task'
                    : assignees.length === 1 && getShortName(assignees[0])}
                </Typography>
              )}
            </Box>
          );
        }}
        renderOption={(props, option) => {
          if (option.value === FALLBACK_UNASSIGN_ID) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  cursor: 'pointer',
                  mt: 3,
                  ml: 1,
                }}
                onClick={() => onChange([])}
              >
                <IconOutlineProfileCircle
                  size={20}
                  color={theme.colors?.utility[800]}
                />
                <Typography variant="subhead-md">{option.label}</Typography>
              </Box>
            );
          }
          const assignee = users.find((u) => u.id === option.value);
          if (!assignee) {
            return null;
          }

          return (
            <AssigneeItem
              onSelectOption={() => {
                if (assignees.some((m) => m.id === assignee.id)) {
                  onChange(assignees.filter((m) => m.id !== assignee.id));
                } else {
                  onChange([...assignees, assignee]);
                }
              }}
              isSelected={assignees.some((m) => m.id === assignee.id)}
              assignee={assignee}
            />
          );
        }}
        placeholder="Search by user"
      />
    </Box>
  );
};

const AssigneeItem = ({
  isSelected,
  onSelectOption,
  assignee,
}: AssigneeItemProps) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        padding: theme.spacing(2, 1),
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: 2,
        ':hover': {
          borderRadius: 1,
          background: theme.colors?.utility[275],
        },
      }}
      onClick={onSelectOption}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <Avatar user={assignee} size={20} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="headline-xxs"
            fontSize={13}
            color={theme.colors?.utility[900]}
          >
            {getFullName(assignee)}
          </Typography>
          <Typography variant="headline-xxs" color={theme.colors?.utility[700]}>
            {assignee.email}
          </Typography>
        </Box>
      </Box>
      {isSelected ? (
        <IconCustomDone size={16} color={theme.colors?.utility.green} />
      ) : (
        <IconOutlineAddCircle size={16} />
      )}
    </Box>
  );
};
