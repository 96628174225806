import { gql } from '@apollo/client';
import { SocialPostCommentFragmentSocialPostCommentMessageFragment } from 'graphql/generated';
import { Typography } from '@mui/material';

gql`
  fragment SocialPostCommentFragmentSocialPostCommentMessage on SocialPostCommentModel {
    id
    text
  }
`;

type SocialPostCommentMessageProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentMessageFragment;
};

export const SocialPostCommentMessage = (
  props: SocialPostCommentMessageProps,
) => {
  const { socialPostComment } = props;

  return <Typography variant="subhead-lg">{socialPostComment.text}</Typography>;
};
