import { gql } from '@apollo/client';
import {
  BrandFragmentBrandConnectedCreatorsFragmentDoc,
  useGetBrandsQuery,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrands {
    brands {
      id
      name
      ...BrandFragmentBrandConnectedCreators
    }
  }
  ${BrandFragmentBrandConnectedCreatorsFragmentDoc}
`;

export const useBrandDetails = () => {
  const { data } = useGetBrandsQuery();
  const brand = data?.brands.length ? data.brands[0] : null;

  return { brand };
};
