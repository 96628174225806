import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { ClickAwayListener } from '@mui/material';
import { useEffect, ReactNode, useRef, useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import {
  CollectionFragmentCollectionPinnedEntityDndCollectionCardFragment,
  CollectionFragmentCollectionPostDndCollectionCardFragment,
  CollectionFragmentSelectModeFragment,
  PostFragmentCollectionPostDndPostCardFragment,
  PostFragmentSelectModeFragment,
  useMovePostsToCollectionMutation,
} from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { createContext } from '@dwarvesf/react-utils';
import {
  PINNED_ENTITIES_SECTION,
  useMoveToAnotherCollectionModal,
} from 'features/collection';
import {
  useSelectMode,
  useDraggableCardDndClones,
} from 'features/collectionPostDnd/hooks';
import {
  COLLECTION_DRAGGABLE_PREFIX,
  COLLECTION_DROPPABLE_PREFIX,
  CollectionPostDndHoveringActionPopup,
  POST_DRAGGABLE_PREFIX,
} from 'features/collectionPostDnd/components';
import { evictObject } from 'utils/apollo';
import { usePinnedEntityMutations } from 'features/pinnedEntity';
import { usePostPermissionUpdatePostPermissionToCollectionPermission } from '../../../post-permission';

// eslint-disable-next-line
gql`
  mutation MovePostsToCollection($data: MovePostsToCollectionInput!) {
    movePostsToCollection(data: $data) {
      success
      message
    }
  }
`;

export type CollectionPostDndContainerType = {
  isLoading: boolean;
  draggingId?: string;
  selectedPostIds: string[];
  onSelectPost: (
    post: PostFragmentSelectModeFragment,
    shiftKey: boolean,
  ) => void;
  onSelectCollection: (
    collection: CollectionFragmentSelectModeFragment,
    shiftKey: boolean,
  ) => void;
  selectedCollectionIds: string[];
  isSelectModeActive: boolean;
  onDraggableCardMouseDownRef: any;
  onDraggableCardMouseUp: any;
};

const [Provider, useCollectionPostDndContainerContext] =
  createContext<CollectionPostDndContainerType>();

export { useCollectionPostDndContainerContext };

type CollectionPostDndContainerProps = {
  pinnedEntities: CollectionFragmentCollectionPinnedEntityDndCollectionCardFragment[];
  posts: PostFragmentCollectionPostDndPostCardFragment[];
  collections: CollectionFragmentCollectionPostDndCollectionCardFragment[];
  disableSelectMode?: boolean;
  onPostsSelected?: (postIds: string[]) => void;
  onCollectionsSelected?: (postIds: string[]) => void;
  children: NonNullable<ReactNode>;
};

export const CollectionPostDndContainer = (
  props: CollectionPostDndContainerProps,
) => {
  const {
    pinnedEntities,
    posts,
    collections,
    onPostsSelected,
    onCollectionsSelected,
    disableSelectMode,
    children,
  } = props;

  const {
    selectedPostIds,
    selectedCollectionIds,
    isSelectModeActive,
    onSelectPost,
    onSelectCollection,
    exitSelectMode,
  } = useSelectMode({
    collections,
    posts,
    disabled: disableSelectMode,
  });

  const { collectionId: currentCollectionId = '' } = useParams();

  const [movePostsToCollection] = useMovePostsToCollectionMutation();
  const { onMoveCollection, renderMoveToAnotherCollectionModal } =
    useMoveToAnotherCollectionModal({
      collectionId: currentCollectionId,
    });

  const { onOpenUpdatePostPermissionDialog, renderUpdatePostPermissionAlert } =
    usePostPermissionUpdatePostPermissionToCollectionPermission();

  const {
    onPinCollectionToParent,
    onUnpinPostFromCollection,

    onPinManyCollectionsToParent,
    onUnpinManyCollectionsFromParent,

    onPinPostToCollection,
    onUnpinCollectionFromParent,

    onPinManyPostsToCollection,
    onUnpinManyPostsFromCollection,
  } = usePinnedEntityMutations();

  const {
    setIsDragging,
    cleanCloneElementsForSelectedDraggableCards,
    onDraggableCardMouseDown,
    onDraggableCardMouseUp,
  } = useDraggableCardDndClones({
    selectedIds: [...selectedPostIds, ...selectedCollectionIds],
  });
  const onDraggableCardMouseDownRef = useRef<typeof onDraggableCardMouseDown>();
  onDraggableCardMouseDownRef.current = onDraggableCardMouseDown;

  const [draggingId, setDraggingId] = useState<string>();
  const onDragStart = (initial: DragStart) => {
    setIsDragging(true);
    setDraggingId(initial.draggableId);
  };

  const [isLoading, setIsLoading] = useState(false);

  const client = useApolloClient();

  const onDragEnd = async (result: DropResult) => {
    setDraggingId(undefined);
    setIsDragging(false);
    cleanCloneElementsForSelectedDraggableCards();
    exitSelectMode();

    if (
      result.reason === 'CANCEL' ||
      !result.destination ||
      result.destination.droppableId === result.source.droppableId
    ) {
      return;
    }

    const draggableId = result.draggableId.split('-')[2];

    const droppedOnCollectionId = result.destination.droppableId.split('-')[2];
    const droppedOnCollection = [
      ...pinnedEntities.map((p) => p.collection),
      ...collections,
    ].find((c) => c?.id === droppedOnCollectionId);

    if (result.destination.droppableId.includes(COLLECTION_DROPPABLE_PREFIX)) {
      // drop on collection
      if (!droppedOnCollection) {
        return;
      }

      if (isSelectModeActive) {
        try {
          setIsLoading(true);

          if (selectedPostIds.length > 0) {
            await movePostsToCollection({
              variables: {
                data: {
                  postIds: selectedPostIds,
                  fromCollectionId: currentCollectionId,
                  toCollectionId: droppedOnCollectionId,
                },
              },
              update: (cache) => {
                selectedPostIds.forEach((postId) => {
                  evictObject(cache, postId, 'PostModel');
                });
              },
            });

            // update cache for pinned section when post is moved
            selectedPostIds.forEach((postId) => {
              const pinnedEntity = pinnedEntities.find(
                (p) => p.post?.id === postId,
              );

              if (pinnedEntity) {
                evictObject(client.cache, pinnedEntity.id, 'PinnedEntityModel');
              }
            });
          }

          if (selectedCollectionIds.length > 0) {
            await onMoveCollection(selectedCollectionIds, droppedOnCollection);

            // update cache for pinned section when collection is moved
            selectedCollectionIds.forEach((collectionId) => {
              const pinnedEntity = pinnedEntities.find(
                (p) => p.collection?.id === collectionId,
              );

              if (pinnedEntity) {
                evictObject(client.cache, pinnedEntity.id, 'PinnedEntityModel');
              }
            });
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      } else if (result.draggableId.includes(POST_DRAGGABLE_PREFIX)) {
        if (!currentCollectionId) {
          return;
        }

        try {
          setIsLoading(true);

          await movePostsToCollection({
            variables: {
              data: {
                postIds: [draggableId],
                fromCollectionId: currentCollectionId,
                toCollectionId: droppedOnCollectionId,
              },
            },
            update: (cache) => {
              evictObject(cache, draggableId, 'PostModel');
            },
          });

          // update cache for pinned section when post is moved
          const pinnedEntity = pinnedEntities.find(
            (p) => p.post?.id === draggableId,
          );

          if (pinnedEntity) {
            evictObject(client.cache, pinnedEntity.id, 'PinnedEntityModel');
          }

          await onOpenUpdatePostPermissionDialog(
            [draggableId],
            droppedOnCollection.id,
          );
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      } else if (result.draggableId.includes(COLLECTION_DRAGGABLE_PREFIX)) {
        try {
          await onMoveCollection([draggableId], droppedOnCollection);

          // update cache for pinned section when collection is moved
          const pinnedEntity = pinnedEntities.find(
            (p) => p.collection?.id === draggableId,
          );

          if (pinnedEntity) {
            evictObject(client.cache, pinnedEntity.id, 'PinnedEntityModel');
          }
        } catch (e) {
          console.error(e);
        }
      }
    } else if (
      result.destination.droppableId.includes(PINNED_ENTITIES_SECTION)
    ) {
      // drop on pinned section

      if (isSelectModeActive) {
        if (selectedCollectionIds.length > 0) {
          await onPinManyCollectionsToParent(selectedCollectionIds);
        }

        if (selectedPostIds.length > 0) {
          await onPinManyPostsToCollection(
            currentCollectionId,
            selectedPostIds,
          );
        }
      } else if (result.draggableId.includes(POST_DRAGGABLE_PREFIX)) {
        await onPinPostToCollection(currentCollectionId, draggableId);
      } else if (result.draggableId.includes(COLLECTION_DRAGGABLE_PREFIX)) {
        await onPinCollectionToParent(draggableId);
      }
    } else if (result.destination.droppableId.includes('allCollectionsPosts')) {
      // unpin

      if (isSelectModeActive) {
        if (selectedCollectionIds.length > 0) {
          await onUnpinManyCollectionsFromParent(selectedCollectionIds);
        }

        if (selectedPostIds.length > 0) {
          await onUnpinManyPostsFromCollection(
            currentCollectionId,
            selectedPostIds,
          );
        }
      } else if (result.draggableId.includes(POST_DRAGGABLE_PREFIX)) {
        await onUnpinPostFromCollection(currentCollectionId, draggableId);
      } else if (result.draggableId.includes(COLLECTION_DRAGGABLE_PREFIX)) {
        await onUnpinCollectionFromParent(draggableId);
      }
    }
  };

  // Expose list of selected post ids to parent component
  useEffect(() => {
    onPostsSelected?.(selectedPostIds);
  }, [selectedPostIds]); // eslint-disable-line

  // Expose list of selected post ids to parent component
  useEffect(() => {
    onCollectionsSelected?.(selectedCollectionIds);
  }, [selectedCollectionIds]); // eslint-disable-line

  // Also cleanup clones when select mode is exited
  useEffect(() => {
    if (!isSelectModeActive) {
      cleanCloneElementsForSelectedDraggableCards();
    }
  }, [isSelectModeActive]); // eslint-disable-line

  // This is a workaround for react-beautiful-dnd for react v18
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  return (
    <Provider
      value={{
        draggingId,
        isLoading,
        selectedPostIds,
        onSelectPost,
        selectedCollectionIds,
        onSelectCollection,
        isSelectModeActive,
        onDraggableCardMouseDownRef,
        onDraggableCardMouseUp,
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          exitSelectMode();
          cleanCloneElementsForSelectedDraggableCards();
        }}
      >
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {enabled && children}
        </DragDropContext>
      </ClickAwayListener>

      {selectedCollectionIds.length === 0 && (
        <CollectionPostDndHoveringActionPopup
          selectedPosts={posts.filter(({ id }) => selectedPostIds.includes(id))}
        />
      )}

      {renderMoveToAnotherCollectionModal()}
      {renderUpdatePostPermissionAlert()}
    </Provider>
  );
};
