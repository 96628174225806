import { useClipboard } from '@dwarvesf/react-hooks';
import {
  Avatar,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldChart1 } from 'components/icons/components/bold/IconBoldChart1';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconBoldRanking1 } from 'components/icons/components/bold/IconBoldRanking1';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import {
  ExpandableText,
  SentimentIcon,
} from 'features/socialMediaListening/components';
import { SocialMediaListeningPostContextMenu } from 'features/socialMediaListening/components/postContextMenu';
import {
  useBrandDetails,
  useSocialListeningPostLinkGenerator,
} from 'features/socialMediaListening/hooks';
import { SocialPostBrandEngagementStatus } from 'features/socialPost';
import {
  BrandSocialPostEngagementStatus,
  SocialPostType,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { HoverPlayableVideo } from './HoverPlayableVideo';
import { SocialMediaListeningPostCardViewProps } from './types';
import { StaticImage } from './StaticImage';

export const SocialMediaListeningPostCardView = ({
  post,
  searchCriteria,
  itemPosition,
  className,
  componentsProps = {},
}: SocialMediaListeningPostCardViewProps) => {
  const { brand } = useBrandDetails();

  const [isPassed, setIsPassed] = useState(false);
  const largeScreen = useMediaQuery(`(min-width: ${2000}px)`);
  const { generateLink } = useSocialListeningPostLinkGenerator();

  const { onCopy } = useClipboard(generateLink(post) || post.videoUrl || '');
  const [isCopied, setIsCopied] = useState(false);

  const ref = useRef<HTMLAnchorElement | null>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const onTextCopy = () => {
    onCopy();
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const hoverActionsTopRight = () => (
    <Box
      display="flex"
      gap={2}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {post.videoUrl && (
        <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
          <IconButton
            disableRipple
            sx={{
              p: 1,
              transform: 'rotate(-45deg)',
              backgroundColor: 'rgba(35, 6, 3, 0.10)',
              color: theme.colors?.primary.white,
              backdropFilter: 'blur(4px)',
            }}
            onClick={onTextCopy}
          >
            <IconOutlineLink size={16} />
          </IconButton>
        </Tooltip>
      )}

      {post.videoUrl && post.creator?.handle && (
        <IconButton
          disableRipple
          sx={{
            p: 1,
            backgroundColor: 'rgba(35, 6, 3, 0.10)',
            color: theme.colors?.primary.white,
            backdropFilter: 'blur(4px)',
          }}
          onClick={() => {
            window.open(generateLink(post) || '', '_blank');
          }}
        >
          <IconLinearExport2 size={16} />
        </IconButton>
      )}
    </Box>
  );

  const hoverActionsBottom = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        ml={2}
        width="70%"
        maxWidth="70%"
      >
        <Avatar
          src={post.creator?.profilePictureUrl || ''}
          sx={{
            width: theme.spacing(4),
            height: theme.spacing(4),
          }}
        />
        <Typography
          color={theme.colors?.primary.parchment}
          variant="body-lg"
          fontWeight={600}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {post.creator?.handle}
        </Typography>
      </Box>

      {post.type === SocialPostType.Video && (
        <Box mr={2} display="flex" gap={1} alignItems="center">
          <IconBoldPlay size={16} color={theme.colors?.primary.parchment} />
          <Typography
            color={theme.colors?.primary.parchment}
            variant="body-lg"
            fontWeight={600}
          >
            {formatBigNumber(post.playCount || 0)}
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Link
      style={{
        display: isPassed ? 'none' : 'inherit',
      }}
      ref={ref}
      to={{
        pathname: PlotRoutes.socialListeningPost(post.id),
        search: `?searchCriteria=${searchCriteria}&position=${itemPosition}`,
      }}
      {...componentsProps.link}
    >
      <Box
        sx={{
          borderRadius: 6,
          backgroundColor: theme.colors?.utility[275],
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 2,
        }}
        height="100%"
        className={className}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body-lg" color={theme.colors?.utility[600]}>
            {moment(post.platformCreateTime).format('DD MMM YYYY')}
          </Typography>
          <Box onClick={(e) => e.stopPropagation()}>
            <SocialPostBrandEngagementStatus
              brandId={brand?.id ?? ''}
              socialPost={post}
              showIcon
              componentProps={{
                button: {
                  sx: {
                    p: theme.spacing(1, 2),
                  },
                },
              }}
              onUpdateCallBack={async (status) => {
                if (status === BrandSocialPostEngagementStatus.Pass) {
                  setIsPassed(true);
                } else {
                  setIsPassed(false);
                }
              }}
            />
          </Box>
        </Box>

        {post.socialPostEngagementSuggestions?.[0]?.oneLineSummary && (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="flex-start"
          >
            <ExpandableText
              text={
                post.socialPostEngagementSuggestions[0].oneLineSummary || ''
              }
              componentProps={{
                text: {
                  sx: {
                    ...typography['body-xl'],
                    fontFamily: 'Good Sans',
                    color: theme.colors?.utility[800],
                    fontWeight: 500,
                    fontSize: largeScreen ? theme.spacing(6) : theme.spacing(4),
                  },
                },
              }}
              collapsedHeight={60}
            />
          </Box>
        )}

        <Box
          display="flex"
          gap={2}
          flexWrap="wrap"
          mt={2}
          flex={1}
          alignItems="flex-end"
          maxWidth={width ? width * 0.9 : 0}
          width="fit-content"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            variant="body-xl"
            width="fit-content"
            height="fit-content"
            color={theme.colors?.utility[700]}
            sx={{
              backgroundColor: theme.colors?.utility[400],
              borderRadius: 2,
              p: theme.spacing(1, 2),
              overflow: 'hidden',
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            {post.socialPostEngagementSuggestionsWithTopic?.[0]?.topic?.name ||
              post.socialPostEngagementSuggestions?.[0]?.topic?.name}
          </Typography>
        </Box>

        <Box display="flex" mt={4}>
          <Box display="flex" flex={4} alignItems="flex-end">
            <Box
              sx={{ width: '100%' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <RightClickContextMenuWrapper
                key={post.id}
                renderMenu={(props) => (
                  <SocialMediaListeningPostContextMenu
                    socialPostId={post.id}
                    {...props}
                  />
                )}
                sx={{
                  height: '100%',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {post.type === SocialPostType.Video && (
                  <HoverPlayableVideo
                    platform={post.platform}
                    thumbnailUrl={post.thumbnailUrl || ''}
                    videoUrl={post.videoUrl || ''}
                    renderHoverActionsTopRight={hoverActionsTopRight}
                    renderHoverActionsBottom={hoverActionsBottom}
                  />
                )}
                {(post.type === SocialPostType.Photo ||
                  post.type === SocialPostType.Carousel) && (
                  <StaticImage
                    platform={post.platform}
                    thumbnailUrl={post.thumbnailUrl || ''}
                    renderHoverActionsTopRight={hoverActionsTopRight}
                    renderHoverActionsBottom={hoverActionsBottom}
                  />
                )}
              </RightClickContextMenuWrapper>
            </Box>
          </Box>
          <Box
            display="flex"
            flex={1}
            mx={2}
            gap={2}
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1,
                p: theme.spacing(3, 4),
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: 2,
              }}
            >
              {post.socialPostEngagementSuggestions?.[0]?.sentiment && (
                <SentimentIcon
                  sentiment={post.socialPostEngagementSuggestions[0].sentiment}
                  size={16}
                />
              )}
            </Box>
            {post.type === SocialPostType.Video &&
              typeof post.engagementRate === 'number' && (
                <Tooltip
                  title={
                    <>
                      <Box sx={{ fontWeight: 600, my: 0, py: 0 }}>
                        Post engagement rate
                      </Box>
                      Post engagement rate measures interactions
                      <br /> like likes, comments, and shares
                      <br /> on a post. Anything greater than <br />
                      10% suggests it's gaining viral tractio
                    </>
                  }
                >
                  <Box
                    color={theme.colors?.utility[700]}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: 1,
                      p: theme.spacing(3, 4),
                      backgroundColor: 'rgba(35, 6, 3, 0.05)',
                      borderRadius: 2,
                    }}
                  >
                    <IconBoldChart1 size={16} />
                    <Typography variant="body-lg" fontWeight={600}>
                      {parseFloat(
                        ((post.engagementRate || 0) * 100).toFixed(1),
                      )}
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            {typeof post.socialPostEngagementSuggestions?.[0]?.score ===
              'number' && (
              <Tooltip
                title={
                  <>
                    <Box sx={{ fontWeight: 600, my: 0, py: 0 }}>
                      Topic Relevancy Score
                    </Box>
                    The confidence score rates how closely
                    <br />
                    the content aligns with your chosen <br />
                    topics; a higher score suggests a <br />
                    user worth engaging with.
                  </>
                }
              >
                <Box
                  color={theme.colors?.utility[700]}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 1,
                    p: theme.spacing(3, 4),
                    backgroundColor: 'rgba(35, 6, 3, 0.05)',
                    borderRadius: 2,
                  }}
                >
                  <IconBoldRanking1 size={16} />
                  <Typography variant="body-lg" fontWeight={600}>
                    {parseFloat(
                      (
                        post.socialPostEngagementSuggestions[0].score * 100
                      ).toFixed(1),
                    )}
                  </Typography>
                </Box>
              </Tooltip>
            )}
            {post.creator && (
              <Tooltip title="Number of followers">
                <Box
                  color={theme.colors?.utility[700]}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 1,
                    p: theme.spacing(3, 4),
                    backgroundColor: 'rgba(35, 6, 3, 0.05)',
                    borderRadius: 2,
                  }}
                >
                  <IconCustomUsers size={16} />
                  <Typography variant="body-lg" fontWeight={600}>
                    {formatBigNumber(post.creator.followerCount || 0)}
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
      <Box id="social-media-post-creative-juicebox-popover" />
    </Link>
  );
};
