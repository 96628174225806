import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Dialog, Typography } from '@mui/material';
import {
  BillingPlanName,
  InternalOrganizationRole,
  OrganizationBillingFragmentNewPricingFragment,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { useUserContext } from 'contexts/users/User.context';
import { useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowRight3 } from 'components/icons/components/linear/IconLinearArrowRight3';
import { BillingNewPricingStarterDetails } from './BillingNewPricingStarterDetails';
import { BillingUpgradeModal } from '../upgradeModal';
import { BillingNewPricingBasicDetails } from './BillingNewPricingBasicDetails';
import { useBillingPlanData } from '../../hooks';

type BillingNewPricingProps = {
  organizationBilling: OrganizationBillingFragmentNewPricingFragment;
};

export const BillingNewPricing = (props: BillingNewPricingProps) => {
  const { organizationBilling } = props;
  const { user, isMobileAppWebView } = useUserContext();
  const location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedType, setSelectedType] = useState<'upgrade' | 'freeTrial'>(
    'upgrade',
  );

  const basicPlanLimit = useBillingPlanData(BillingPlanName.Basic);

  const {
    isOpen: planOptionsOpen,
    onClose: planOptionsOnClose,
    onOpen: planOptionsOnOpen,
  } = useDisclosure();

  const shouldShowAlert = useMemo(() => {
    return (
      organizationBilling.plan === BillingPlanName.Free &&
      !organizationBilling.hasSeenNewPricing
    );
  }, [organizationBilling]);

  useEffect(() => {
    // exclude checkout page to allow admins to upgrade
    if (
      !(isMobileAppWebView && location.pathname === PlotRoutes.billing()) &&
      shouldShowAlert &&
      location.pathname !== PlotRoutes.billingCheckout()
    ) {
      onOpen();
    }
  }, [isMobileAppWebView, shouldShowAlert, location, onOpen]);

  const {
    isOpen: starterPlanOpen,
    onOpen: starterPlanOnOpen,
    onClose: starterPlanOnClose,
  } = useDisclosure();

  const {
    isOpen: basicPlanOpen,
    onOpen: basicPlanOnOpen,
    onClose: basicPlanOnClose,
  } = useDisclosure();

  const activeMembers = useMemo(() => {
    return (
      user?.organization.users.filter(
        (u) => !u.isDisabled && u.hasBeenApprovedByAdmin,
      ) ?? []
    );
  }, [user?.organization.users]);

  return !shouldShowAlert ? null : (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            maxWidth: '760px',
            bgcolor: theme.colors?.primary.parchment,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(12),
            position: 'relative',
          }}
        >
          <Box component="img" src="/images/new-pricing-banner.png" />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(6),
              padding: theme.spacing(0, 28, 15, 28),
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                variant="headline-xs"
                sx={{
                  background: '#23060312',
                  color: theme.colors?.utility['pink-3'],
                  padding: theme.spacing(1, 2),
                  borderRadius: theme.spacing(25),
                }}
              >
                Action Required
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                gap: theme.spacing(2),
              }}
            >
              <Typography variant="headline-xl" fontWeight={500} fontSize={36}>
                New plans and updates for your Starter Free plan!
              </Typography>
              <Typography
                variant="subhead-xl"
                color={theme.colors?.utility[700]}
              >
                We've updated the Starter Free plan and introduced a new plan
                for personal use. Take a look at the details to see how these
                changes might impact your current experience.
              </Typography>
            </Box>

            {user?.role === InternalOrganizationRole.Admin ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: theme.spacing(3),
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: theme.colors?.primary.black,
                    color: theme.colors?.primary.black,
                    borderRadius: theme.spacing(2),
                    ...theme.typography['headline-sm'],
                    ':hover': {
                      borderColor: theme.colors?.primary.black,
                    },
                  }}
                  onClick={() => {
                    starterPlanOnOpen();
                    onClose();
                  }}
                >
                  Stay on Starter Free
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: theme.colors?.primary.black,
                    color: theme.colors?.primary.white,
                    borderRadius: theme.spacing(2),
                    ...theme.typography['headline-sm'],
                    ':hover': {
                      bgcolor: theme.colors?.primary.black,
                    },
                  }}
                  onClick={planOptionsOnOpen}
                >
                  See new plans
                </Button>
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: theme.colors?.primary.black,
                    color: theme.colors?.primary.white,
                    borderRadius: theme.spacing(2),
                    ...theme.typography['headline-sm'],
                    ':hover': {
                      bgcolor: theme.colors?.primary.black,
                    },
                  }}
                  endIcon={<IconLinearArrowRight3 />}
                  onClick={() => {
                    onClose();
                    starterPlanOnOpen();
                  }}
                >
                  Learn More
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>

      <BillingNewPricingStarterDetails
        isOpen={starterPlanOpen}
        onClose={starterPlanOnClose}
        onOpen={starterPlanOnOpen}
        onOpenPlanOptions={() => {
          planOptionsOnOpen();
        }}
      />

      <BillingNewPricingBasicDetails
        isOpen={basicPlanOpen}
        onOpen={basicPlanOnOpen}
        onClose={basicPlanOnClose}
        onGoBack={() => {
          basicPlanOnClose();
          planOptionsOnOpen();
        }}
        onOpenPlanOptions={() => {
          planOptionsOnOpen();
        }}
        selectedType={selectedType}
      />

      <BillingUpgradeModal
        isOpen={planOptionsOpen}
        onClose={() => {
          onClose();
          starterPlanOnClose();
          basicPlanOnClose();
          planOptionsOnClose();
        }}
        isNotClosable
        onGoBack={() => {
          planOptionsOnClose();
          if (!starterPlanOpen) {
            onOpen();
          }
        }}
        onCustomUpgradeToBasicClick={
          // only show basic plan warning when there are more org avitve members then the limit
          basicPlanLimit.organizationMemberLimit &&
          (activeMembers.length ?? 1) > basicPlanLimit.organizationMemberLimit
            ? (type) => {
                setSelectedType(type);
                onClose();
                starterPlanOnClose();
                planOptionsOnClose();
                basicPlanOnOpen();
              }
            : undefined
        }
      />
    </>
  );
};
