import { gql } from '@apollo/client';
import { Masonry } from '@mui/lab';
import { Box, SxProps, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { MasonrySkeleton } from 'components/common/Skeleton';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { PostMasonryCardView } from 'features/post';
import {
  PostFragmentPostMasonryCardViewFragmentDoc,
  PostType,
  TaskModel,
  useGetTaskForTaskDetailViewMediaSectionQuery,
} from 'graphql/generated';
import { useUpdateQueryByCommand } from 'hooks/commands/useUpdateQueryByCommand';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';
import { AddPostsButton } from './AddPostsButton';
import { StyledEmptyStateWrapper } from './styles';

// eslint-disable-next-line
gql`
  query GetTaskForTaskDetailViewMediaSection($id: String!) {
    task(id: $id) {
      id
      posts {
        id
        ...PostFragmentPostMasonryCardView
      }
    }
  }
  ${PostFragmentPostMasonryCardViewFragmentDoc}
`;

export type MediaSectionProps = {
  task: Pick<TaskModel, 'id'>;
  sx?: SxProps;
};

export const MediaSection = (props: MediaSectionProps) => {
  const { task, sx } = props;

  const location = useLocation();
  const locationState = (location.state as any) || {};

  const { data, loading, refetch } =
    useGetTaskForTaskDetailViewMediaSectionQuery({
      variables: {
        id: task.id,
      },
      fetchPolicy: 'cache-and-network',
    });
  const posts = data?.task?.posts || [];
  const isFirstLoading = loading && !data;
  const isEmpty = posts.length === 0;

  useUpdateQueryByCommand(COMMAND_TYPE.POST_WRITE_A_NOTE, () => refetch());

  return (
    <Box sx={sx}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Typography
          variant="headline-xl"
          fontSize={24}
          color={theme.colors?.utility[700]}
          fontWeight={500}
        >
          Media
        </Typography>
        <AddPostsButton taskId={task.id} onPostsAdded={() => refetch()} />
      </Box>
      <Box>
        {isFirstLoading ? (
          <MasonrySkeleton />
        ) : isEmpty ? (
          <StyledEmptyStateWrapper>
            <Typography className="empty-state-title">No posts yet</Typography>
          </StyledEmptyStateWrapper>
        ) : (
          <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
            {posts.map((post) => {
              return (
                <Box key={post.id}>
                  <PostMasonryCardView
                    post={post}
                    componentsProps={{
                      link: {
                        to:
                          post.type === PostType.Note
                            ? PlotRoutes.juiceboxNote({ id: post.id })
                            : PlotRoutes.juicePreview(post.id),
                        state: {
                          ...locationState,
                          // Keep background location & replace secondary location if we are
                          // coming from a 2nd layer
                          // @ts-ignore
                          backgroundLocation:
                            locationState.backgroundLocation || location,
                          secondaryLocation: locationState.backgroundLocation
                            ? location
                            : undefined,
                        },
                      },
                    }}
                  />
                </Box>
              );
            })}
          </Masonry>
        )}
      </Box>
    </Box>
  );
};
