import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, SxProps, Typography } from '@mui/material';
import { OrganizationBillingFragmentCollectionPaywallFragment } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { BillingUpgradeModal } from '../upgradeModal';

type BillingCollectionLimitIndicatorProps = {
  organizationBilling: OrganizationBillingFragmentCollectionPaywallFragment;
  variant?: 'compact' | 'text';
  customText?: string;
  sx?: SxProps;
  onModalOpened?: VoidFunction;
  onModalClosed?: VoidFunction;
};

export const BillingCollectionLimitIndicator = (
  props: BillingCollectionLimitIndicatorProps,
) => {
  const {
    organizationBilling,
    customText,
    sx,
    variant = 'banner',
    onModalOpened,
    onModalClosed,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const _onOpen = () => {
    onOpen();
    onModalOpened?.();
  };

  const _onClose = () => {
    onClose();
    onModalClosed?.();
  };

  const hasCollectionLimit = useMemo(() => {
    return (
      organizationBilling.collectionUsageLimit !== null &&
      organizationBilling.collectionUsageLimit !== undefined
    );
  }, [organizationBilling.collectionUsageLimit]);

  const isCollectionAllowed = useMemo(() => {
    return (
      organizationBilling.collectionUsageLimit !== null &&
      organizationBilling.collectionUsageLimit !== undefined &&
      organizationBilling.collectionUsageLimit > 0
    );
  }, [organizationBilling.collectionUsageLimit]);

  const collectionUsage = useMemo(() => {
    return organizationBilling.collectionUsageCount;
  }, [organizationBilling.collectionUsageCount]);

  const paywallFilledColor = useMemo(() => {
    if (hasCollectionLimit) {
      if (organizationBilling.collectionUsageLimit === 0) {
        return theme.colors?.utility[500];
      }

      const percentageUsed =
        (collectionUsage / organizationBilling.collectionUsageLimit!) * 100;

      if (variant === 'text') {
        if (percentageUsed >= 0 && percentageUsed < 60) {
          return theme.colors?.utility['pink-1'];
        }
        if (percentageUsed >= 60 && percentageUsed < 80) {
          return theme.colors?.utility['orange-1'];
        }
        if (percentageUsed >= 80) {
          return theme.colors?.utility['yellow-3-new'];
        }
        return theme.colors?.utility[900];
      }

      if (percentageUsed >= 0 && percentageUsed < 60) {
        return theme.colors?.utility[500];
      }

      if (percentageUsed >= 60 && percentageUsed < 80) {
        return theme.colors?.utility['yellow-3-new'];
      }

      if (percentageUsed >= 80) {
        return theme.colors?.utility['pink-1'];
      }
    }

    return theme.colors?.primary.maroon;
  }, [collectionUsage, hasCollectionLimit, variant]);

  const renderVariant = () => {
    if (variant === 'compact') {
      return (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: theme.spacing(1),
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: paywallFilledColor,
            padding: '4px 0 6px 0',
            ...sx,
          }}
        >
          <Typography variant="body-sm">
            {collectionUsage}/{organizationBilling.collectionUsageLimit} used.
          </Typography>
          {organizationBilling.hasUsedFreeTrial ? (
            <>
              <Typography variant="body-sm">
                For unlimited collections,
              </Typography>

              <Typography
                variant="body-sm"
                style={{
                  fontWeight: 500,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={_onOpen}
              >
                Upgrade Now
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="body-sm"
                style={{
                  fontWeight: 500,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={_onOpen}
              >
                Start Free Trial
              </Typography>

              <Typography variant="body-sm">
                to get unlimited collections.
              </Typography>
            </>
          )}
        </Box>
      );
    }

    if (variant === 'text') {
      if (organizationBilling.collectionUsageLimit === 0) {
        return (
          <Typography variant="body-sm" sx={{ ...sx }}>
            <Typography
              variant="body-sm"
              sx={{
                fontWeight: 600,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={_onOpen}
            >
              {organizationBilling.hasUsedFreeTrial
                ? 'Upgrade Now'
                : 'Start Free Trial'}
            </Typography>{' '}
            {customText ?? 'to create or add unlimited collections'}
          </Typography>
        );
      }

      return (
        <Typography color={paywallFilledColor} variant="body-sm" sx={{ ...sx }}>
          {collectionUsage}/{organizationBilling.collectionUsageLimit} used
        </Typography>
      );
    }
  };

  return !hasCollectionLimit ||
    (isCollectionAllowed && collectionUsage === 0) ? null : (
    <>
      {renderVariant()}

      <BillingUpgradeModal isOpen={isOpen} onClose={_onClose} />
    </>
  );
};
