import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearNote2 } from 'components/icons/components/linear/IconLinearNote2';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { TopicsFilterProps } from './types';

export const TopicsFilter = ({ topics, onTopicsChange }: TopicsFilterProps) => {
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  useEffect(() => {
    onTopicsChange(selectedTopics);
  }, [selectedTopics]);

  return (
    <Box display="flex" gap={2}>
      <ContextMenu
        renderButton={() => (
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            color={theme.colors?.utility[800]}
            sx={{
              borderRadius: 25,
              backgroundColor: theme.colors?.utility[300],
              p: theme.spacing(3.5, 4),
              cursor: 'pointer',
            }}
          >
            <IconLinearNote2 size={16} />
            <Typography variant="headline-sm" minWidth={80}>
              Topics
            </Typography>
            <IconLinearArrowDown size={20} />
          </Box>
        )}
        options={topics.map((topic) => ({
          renderOption: () => (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTopics((prev) =>
                  prev.includes(topic.id)
                    ? prev.filter((id) => id !== topic.id)
                    : [...prev, topic.id],
                );
              }}
            >
              <Checkbox
                disableRipple
                checked={selectedTopics.includes(topic.id)}
                sx={{
                  p: 1,
                  pr: 3,
                  color: theme.colors?.primary.black,
                  '&.MuiCheckbox-root': {
                    color: theme.colors?.primary.black,
                  },
                }}
              />
              <Typography variant="body-lg" fontWeight={500}>
                {topic.name}
              </Typography>
            </Box>
          ),
        }))}
      />
      {selectedTopics.map((topicId) => {
        const topic = topics.find((topic) => topic.id === topicId);
        return (
          <Box
            width="fit-content"
            color={theme.colors?.utility[600]}
            display="flex"
            gap={1}
            alignItems="center"
            sx={{
              borderRadius: 25,
              backgroundColor: theme.colors?.utility[400],
              p: theme.spacing(2.5, 4),
            }}
          >
            <Typography
              color={theme.colors?.utility[900]}
              variant="headline-sm"
            >
              {topic?.name}
            </Typography>
            <IconButton
              disableRipple
              onClick={() =>
                setSelectedTopics((prev) => prev.filter((id) => id !== topicId))
              }
              sx={{
                p: 1,
              }}
            >
              <IconBoldCloseCircle size={20} />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};
