import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Grid } from '@mui/material';
import {
  MetricsTimeRange,
  useGetBrandsDataForSocialListeningAnalyticsCompetitorsQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { TimeDurationSelector } from '../inbound/sections/timeDurationSelector/TimeDurationSelector';
import { TopicsFilter } from '../inbound/sections/topicsFilter/TopicsFilter';
import { SocialMediaAnalyticsCompetitorsRanking } from './sections/competitorsRanking';
import { SocialMediaAnalyticsCompetitorsSentiment } from './sections/sentiment';
import { SentimentTrendsByBrandDialog } from './sections/sentimentTrendsByBrandDialog/SentimentTrendsByBrandDialog';

export const SocialMediaListeningAnalyticsCompetitorsView = () => {
  const brandSentimentTrendDisclosure = useDisclosure();
  const [selectedBrandId, setSelectedBrandId] = useState('');

  const [timeRange, setTimeRange] = useState(MetricsTimeRange.OneWeek);
  const [filteredTopicIds, setFilteredTopicIds] = useState<string[]>([]);

  const { data: brandsData, refetch: refetchCompetitorsData } =
    useGetBrandsDataForSocialListeningAnalyticsCompetitorsQuery({
      variables: {
        competitorsDataInput: {
          timeRange,
          topicIds: filteredTopicIds,
        },
      },
    });
  const allTopics = brandsData?.brands.flatMap((brand) => brand?.topics) || [];

  return (
    <Box display="flex" my={6} flexDirection="column" gap={4}>
      <Box display="flex" gap={2} justifyContent="space-between" mb={4}>
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            maxWidth: 'calc(100% - 200px)',
            width: 'fit-content',
            whiteSpace: 'nowrap',
          }}
        >
          <TopicsFilter
            topics={allTopics}
            onTopicsChange={(topicIds) => {
              setFilteredTopicIds(topicIds);
            }}
          />
        </Box>
        <Box display="flex" height={50}>
          <TimeDurationSelector
            options={[
              MetricsTimeRange.OneWeek,
              MetricsTimeRange.OneMonth,
              MetricsTimeRange.ThreeMonths,
              MetricsTimeRange.AllTime,
            ]}
            currentValue={timeRange}
            onChange={(value) => {
              setTimeRange(value as MetricsTimeRange);
            }}
          />
        </Box>
      </Box>

      <Box>
        <Grid container spacing={2}>
          {brandsData?.brands?.[0] &&
            brandsData?.brands?.[0]?.brandCompetitors && (
              <Grid item xs={6}>
                <SocialMediaAnalyticsCompetitorsRanking
                  onBrandClicked={(brandId) => {
                    setSelectedBrandId(brandId);
                    brandSentimentTrendDisclosure.onOpen();
                  }}
                  selectedTimeRange={timeRange}
                  currentBrandId={brandsData?.brands?.[0].id || ''}
                  currentBrandName={brandsData?.brands?.[0].name || ''}
                  data={brandsData?.brands?.[0].brandCompetitors}
                  refreshCompetitorsData={refetchCompetitorsData}
                />
              </Grid>
            )}
          {brandsData?.brands?.[0] &&
            brandsData?.brands?.[0]?.brandCompetitorsSentiment && (
              <Grid item xs={6}>
                <SocialMediaAnalyticsCompetitorsSentiment
                  onBrandClicked={(brandId) => {
                    setSelectedBrandId(brandId);
                    brandSentimentTrendDisclosure.onOpen();
                  }}
                  selectedTimeRange={timeRange}
                  currentBrandName={brandsData?.brands?.[0].name || ''}
                  data={brandsData?.brands?.[0].brandCompetitorsSentiment}
                />
              </Grid>
            )}
        </Grid>
      </Box>
      {selectedBrandId && (
        <SentimentTrendsByBrandDialog
          selectedBrandId={selectedBrandId}
          brand={brandsData?.brands?.[0]}
          topicIds={filteredTopicIds}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          {...brandSentimentTrendDisclosure}
        />
      )}
    </Box>
  );
};
