import { Button, Typography, styled } from '@mui/material';

export const StyledGoogleButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.colors?.utility[400]}`,
  borderRadius: theme.spacing(2),
  backgroundColor: theme.colors?.primary.white,
  width: '100%',
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
  ':hover': {
    backgroundColor: theme.colors?.primary.white,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.colors?.utility[1000],
}));
