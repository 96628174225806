import { Box, TableCell } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';

type Props = {
  image: string;
};

export const ImageTableCell = ({ image }: Props) => {
  return (
    <TableCell>
      <Box
        component="img"
        src={image}
        width={theme.spacing(12)}
        height={theme.spacing(12)}
        sx={{
          borderRadius: theme.spacing(2),
        }}
      />
    </TableCell>
  );
};
