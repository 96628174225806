import { gql } from '@apollo/client';
import { PlotRoutes } from 'Routes';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { toast } from 'components/common/Toast';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { PostPreview } from 'features/post';
import {
  PostFragmentPostPreviewFragmentDoc,
  useAddTrendToContentCalendarForTrendContentCalendarButtonMutation,
} from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation AddTrendToContentCalendarForTrendContentCalendarButton(
    $data: AddTrendToContentCalendarInput!
  ) {
    addTrendToContentCalendar(data: $data) {
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

interface TrendSaveToContentCalendarButtonProps {
  renderBtn?: () => React.ReactNode;
  urlMetadataId: string;
  trendId: string;
  onAfterAddedToContentCalendar?: () => void;
}

export const TrendSaveToContentCalendarButton = ({
  renderBtn,
  urlMetadataId,
  trendId,
  onAfterAddedToContentCalendar,
}: TrendSaveToContentCalendarButtonProps) => {
  const navigate = useNavigate();
  const [addPostToContentCalendar] =
    useAddTrendToContentCalendarForTrendContentCalendarButtonMutation();

  const onAddTrendToContentCalendar = async () => {
    const response = await addPostToContentCalendar({
      variables: {
        data: {
          trendId,
          urlMetadataId,
        },
      },
    });

    if (response.data?.addTrendToContentCalendar) {
      onAfterAddedToContentCalendar?.();
      toast({
        position: 'bottom-center',
        shouldShowCloseButton: false,
        sx: {
          bgcolor: theme.colors?.utility['yellow-1'],
          color: theme.colors?.primary.black,
          borderRadius: theme.spacing(4),
          padding: theme.spacing(4),
          alignItems: 'center',
          '& .MuiButtonBase-root': {
            padding: 0,
          },
        },
        message: '',
        title: 'Added!',
        icon: (
          <PostPreview
            post={response.data.addTrendToContentCalendar}
            sx={{
              width: 55,
              height: 85,
              borderRadius: 3,
              overflow: 'hidden',
              border: `4px solid ${theme.colors?.primary.white}`,
            }}
          />
        ),
        actionButtons: [
          {
            variant: 'text',
            children: `Go to content calendar`,
            onClick: () => {
              navigate(PlotRoutes.contentCalendar());
            },
            sx: {
              color: theme.colors?.utility['yellow-4-new'],
              padding: 0,
            },
          },
        ],
      });
    } else {
      toast({
        message: 'Failed to add trend to content calendar',
        type: 'error',
      });
    }
  };

  if (renderBtn) {
    return (
      <IconButtonWithTooltip
        tooltip="Add to content calendar"
        disableRipple
        sx={{
          padding: 0,
        }}
        onClick={onAddTrendToContentCalendar}
      >
        {renderBtn()}
      </IconButtonWithTooltip>
    );
  }
  return (
    <IconButtonWithTooltip
      tooltip="Add to content calendar"
      sx={{
        padding: 2.5,
        bgcolor: theme.colors?.utility['yellow-1'],
        width: 'fit-content',
      }}
      disableRipple
      onClick={onAddTrendToContentCalendar}
    >
      <IconOutlineCalendar color={theme.colors?.primary.black} />
    </IconButtonWithTooltip>
  );
};
