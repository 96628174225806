import React, { useContext, useMemo } from 'react';
import { InternalOrganizationRole } from 'graphql/generated';
import { useUserContext } from '../User.context';

type PendingActionContextProps = {
  areTherePendingUserRequests: boolean;
};

const PendingActionContext = React.createContext<PendingActionContextProps>({
  areTherePendingUserRequests: false,
});

export const usePendingActions = () => {
  return useContext(PendingActionContext);
};

export const PendingActionsProvider = ({ children }) => {
  const { user } = useUserContext();

  const areTherePendingUserRequests = useMemo(() => {
    if (user && user.role === InternalOrganizationRole.Admin) {
      return (
        [
          ...user.organization.users.filter((user) => user.isPending),
          ...user.organization.externalUsers.filter((user) => user.isPending),
        ].length > 0
      );
    }

    return false;
  }, [user]);

  return (
    <PendingActionContext.Provider
      value={{
        areTherePendingUserRequests,
      }}
    >
      {children}
    </PendingActionContext.Provider>
  );
};
