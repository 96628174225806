import { Masonry } from '@mui/lab';
import { Box } from '@mui/material';
import { MasonrySkeleton } from 'components/common/Skeleton';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import {
  DEFAULT_CJB_TAKE_LARGE,
  DEFAULT_CJB_TAKE_SMALL,
} from 'constants/pagination';
import { CollectionCardView } from 'features/collection/views/card';
import { PostMasonryCardView } from 'features/post';
import { useCollectionSmartSearchQuery } from 'features/post/views/smartSearchResult/useCollectionSmartSearchQuery';
import { usePostSmartSearchQuery } from 'features/post/views/smartSearchResult/usePostSmartSearchQuery';
import {
  CollectionModel,
  PostFiltersForSmartSearch,
  PostFragmentPostCommandFragment,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

export type SearchResultSectionProps = {
  filters: PostFiltersForSmartSearch;
  selectedPostIds: string[];
  onPostClick: (post: PostFragmentPostCommandFragment) => void;
  onCollectionClick: (collection: Pick<CollectionModel, 'id' | 'name'>) => void;
};

export const SearchResultSection = (props: SearchResultSectionProps) => {
  const { filters, selectedPostIds, onPostClick, onCollectionClick } = props;

  // check if isMobile view
  const isMobileView = useMediaQueryMobile();
  const { filterType, ...collectionFilters } = filters;

  const { data: collectionData, loading: isCollectionLoading } =
    useCollectionSmartSearchQuery({
      filters: collectionFilters,
      take: isMobileView ? DEFAULT_CJB_TAKE_SMALL : DEFAULT_CJB_TAKE_LARGE,
    });
  const collections =
    collectionData?.collectionsSmartSearch.data.map((x) => x.item) || [];

  const {
    data: postData,
    loading: isPostLoading,
    fetchMore: fetchMorePost,
  } = usePostSmartSearchQuery({ filters });
  const posts = postData?.postsSmartSearch.data.map((x) => x.item) || [];
  const hasNextPostPage = postData?.postsSmartSearch.pageInfo.hasNextPage;

  if (isCollectionLoading || isPostLoading) {
    return <MasonrySkeleton />;
  }

  return (
    <InfiniteScroll
      hasMore={hasNextPostPage}
      initialLoad={false}
      loadMore={fetchMorePost}
      threshold={1024}
      useWindow={false}
      getScrollParent={() =>
        document.querySelector(
          '.collection-cjb-window-view-scrollable-container',
        )
      }
    >
      <Box sx={{ mr: -4 }}>
        <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={4}>
          {collections.map((collection) => {
            return (
              <CollectionCardView
                key={collection.id}
                collection={collection}
                onClick={() => onCollectionClick(collection)}
                componentsProps={{
                  link: false,
                  multiPostPreview: {
                    variant: 'card-stack-alt',
                  },
                }}
              />
            );
          })}
          {posts.map((post) => {
            const selected = selectedPostIds.includes(post.id);

            return (
              <Box
                key={post.id}
                sx={{
                  position: 'relative',
                  borderRadius: 3,
                  ...(selected && {
                    color: theme.colors?.primary.black,
                    outline: selected
                      ? `3px solid ${theme.colors?.primary.black}`
                      : 'none',
                    outlineOffset: 3,
                    backgroundColor: theme.colors?.primary.white,
                  }),
                }}
              >
                <PostMasonryCardView
                  post={post}
                  componentsProps={{
                    rightClickContextMenu: false,
                    link: false,
                  }}
                  onClick={() => onPostClick(post)}
                />
                {selected && (
                  <IconBoldTickCircle
                    size={16}
                    style={{ position: 'absolute', top: 0, left: 0, margin: 8 }}
                  />
                )}
              </Box>
            );
          })}
        </Masonry>
      </Box>
    </InfiniteScroll>
  );
};
