import { Box, Button, IconButton, SxProps } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldFlash } from 'components/icons/components/bold/IconBoldFlash';
import { IconOutlineMenu1 } from 'components/icons/components/outline/IconOutlineMenu1';
import { useUserContext } from 'contexts/users/User.context';
import { BillingPlanName, BillingSubscriptionStatus } from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';
import {
  HeaderCopyLinkButton,
  HeaderInboxButton,
  HeaderSearchButton,
} from './components';
import { useCustomHeaderContext } from './contexts/CustomHeader.context';

export type CustomHeaderProps = {
  sx?: SxProps;
  iconColor?: string;
  onToggleDrawer?: () => void;
};

export const CustomHeader = (props: CustomHeaderProps) => {
  const { sx, iconColor, onToggleDrawer } = props;

  const navigate = useGuardNavigate();

  const isMobileView = useMediaQueryMobile();

  const { orgBilling } = useUserContext();

  const {
    headerMenuOptions,
    renderMenuExtraLeft,
    renderMenuExtraRight,
    renderTitle,
    headerSx,
  } = useCustomHeaderContext();

  // Show upgrade button if:
  // 1. User is in free plan
  // 2. User is in trial for standard plan
  const shouldShowUpgradeButton = useMemo(() => {
    if (!orgBilling) {
      return false;
    }

    return (
      orgBilling.plan === BillingPlanName.Free ||
      orgBilling.subscription?.status === BillingSubscriptionStatus.Trialing
    );
  }, [orgBilling]);

  const style = {
    ...sx,
    ...headerSx,
  };

  return (
    <Box
      sx={{
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(3),
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(4, 6, 4, 6),
        borderBottom: `1px solid ${theme.colors?.utility[300]}`,
        transition: 'all 0.2s linear',
        height: theme.spacing(16),
        bgcolor: theme.colors?.primary.white,
        pointerEvents: 'auto',
        ...style,
      }}
      id="custom-header"
    >
      <Box
        display="flex"
        alignItems="center"
        gap={3}
        flex={1}
        overflow="hidden"
      >
        {onToggleDrawer && (
          <IconButton onClick={onToggleDrawer} style={{ flexShrink: 0 }}>
            <IconOutlineMenu1 size={24} color={iconColor} />
          </IconButton>
        )}
        {typeof renderTitle === 'function' ? renderTitle() : renderTitle}
      </Box>
      <Box display="flex" alignItems="center">
        {shouldShowUpgradeButton && (
          <Button
            startIcon={
              isMobileView ? undefined : (
                <IconBoldFlash size={16} color={theme.colors?.primary.black} />
              )
            }
            sx={{
              color: iconColor ?? theme.colors?.primary.black,
              borderColor: iconColor ?? theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              ':hover': {
                borderColor: iconColor ?? theme.colors?.primary.black,
              },
              mr: 2,
              flexShrink: 0,
              height: 36,
              ...headerSx,
            }}
            onClick={() => navigate(PlotRoutes.billing())}
            variant="outlined"
          >
            Upgrade
          </Button>
        )}
        {renderMenuExtraLeft?.filter(Boolean).map((render, i) => (
          <Box key={i}>{render!()}</Box>
        ))}
        {headerMenuOptions?.copyLink && (
          <HeaderCopyLinkButton iconColor={iconColor} />
        )}
        {headerMenuOptions?.search && (
          <HeaderSearchButton iconColor={iconColor} />
        )}
        {headerMenuOptions?.inbox && (
          <HeaderInboxButton iconColor={iconColor} />
        )}
        {renderMenuExtraRight?.filter(Boolean).map((render, i) => (
          <Box key={i}>{render!()}</Box>
        ))}
      </Box>
    </Box>
  );
};
