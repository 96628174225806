import { CSSProperties } from 'react';
import { theme } from 'styles/theme';

/**
 * This method returns custom chip styles based on the status of a content idea.
 * Should be use for a status chip.
 */
export const getCustomStatusStyles = (
  status: string,
  fallbackBackgroundColor?: string,
): CSSProperties => {
  switch (status) {
    case 'NotStarted': {
      return {
        backgroundColor: '#2306031A',
        color: theme.colors?.utility[900],
      };
    }
    case 'InProgress': {
      return {
        backgroundColor: theme.colors?.utility['yellow-2-new'],
        color: theme.colors?.utility[900],
      };
    }
    case 'InReview': {
      return {
        backgroundColor: theme.colors?.utility['purple-2'],
        color: theme.colors?.utility[900],
      };
    }
    case 'ReadyToPost': {
      return {
        backgroundColor: theme.colors?.utility['green-2'],
        color: theme.colors?.utility[900],
      };
    }
    case 'Published': {
      return {
        backgroundColor: theme.colors?.primary.black,
        color: theme.colors?.primary.parchment,
      };
    }
    case 'Delayed': {
      return {
        backgroundColor: theme.colors?.utility['pink-2'],
        color: theme.colors?.utility[900],
      };
    }
    case 'Cancelled': {
      return {
        backgroundColor: theme.colors?.utility[700],
        color: '#FFFFFF',
      };
    }
    default: {
      return {
        backgroundColor: fallbackBackgroundColor || theme.colors?.utility[400],
      };
    }
  }
};

/**
 * This method returns custom styles based on the status of a content idea.
 * Should be used for an event card view.
 */
export const getCustomEventCardStylesBasedOnStatus = (
  status: string,
  fallbackBackgroundColor?: string,
): CSSProperties => {
  switch (status) {
    case 'NotStarted': {
      return {
        backgroundColor: '#F3F3F3',
      };
    }
    case 'InProgress': {
      return {
        backgroundColor: theme.colors?.utility['yellow-1'],
      };
    }
    case 'InReview': {
      return {
        backgroundColor: theme.colors?.utility['purple-1'],
      };
    }
    case 'ReadyToPost': {
      return {
        backgroundColor: theme.colors?.utility['green-1'],
      };
    }
    case 'Published': {
      return {
        backgroundColor: theme.colors?.primary.black,
      };
    }
    case 'Delayed': {
      return {
        backgroundColor: theme.colors?.utility['pink-1'],
      };
    }
    case 'Cancelled': {
      return {
        backgroundColor: 'transparent',
        border: `2px solid ${theme.colors?.utility[275]}`,
      };
    }
    default: {
      return {
        backgroundColor:
          fallbackBackgroundColor || `${theme.colors?.utility[400]}80`,
      };
    }
  }
};

/**
 * This method returns custom styles based on the status of a content idea.
 * Should be used for pillar chips.
 */
export const getCustomPillarChipStylesBasedOnStatus = (
  status: string,
): CSSProperties => {
  switch (status) {
    case 'Cancelled': {
      return {
        backgroundColor: '#2306030D',
        color: theme.colors?.utility[500],
      };
    }
    default: {
      return {
        backgroundColor: '#2306031A',
        color: theme.colors?.utility[800],
      };
    }
  }
};
