import { Box, Typography } from '@mui/material';
import { MoreMenuItem } from 'components/common/Menu';
import { IconLinearFilter } from 'components/icons/components/linear/IconLinearFilter';
import React from 'react';
import { theme } from 'styles/theme';
import { FilterOption } from '../types';
import { FilterByOptions } from './FilterByOptions';

interface FilterByProps {
  currentFilters: { [key: string]: string[] };
  options: FilterOption[];
  appliedFilterData: { [key: string]: string[] };
  toggleFilter: (key: string, value: string) => void;
}

export const FilterBy: React.FC<FilterByProps> = ({
  currentFilters,
  options,
  appliedFilterData,
  toggleFilter,
}) => {
  if (!options.length) {
    return null;
  }

  const appliedFilters = Object.keys(appliedFilterData || {})
    .map((key) => {
      if (appliedFilterData[key].length > 0) {
        const renderLabel = options.find((option) => option.key === key)?.label;
        return `${renderLabel} (${appliedFilterData[key].length})`;
      }
      return '';
    })
    .filter((x) => x);

  return (
    <MoreMenuItem
      disableRipple
      label={
        <Box
          sx={{
            m: 0,
            borderRadius: theme.spacing(3),
            width: 270,
            justifyContent: 'space-between',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ width: '100%' }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(1),
              }}
            >
              <IconLinearFilter size={16} />
            </Box>
            <Box display="flex" flex={1}>
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                fontWeight={500}
              >
                Filter By
              </Typography>
            </Box>

            <Typography
              variant="body-lg"
              color={theme.colors?.utility[600]}
              fontWeight={500}
              maxWidth={120}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {appliedFilters.length ? appliedFilters.join() : 'No Selection'}
            </Typography>
          </Box>
        </Box>
      }
    >
      <FilterByOptions
        options={[...options]}
        toggleValue={toggleFilter}
        currentFilters={currentFilters}
      />
    </MoreMenuItem>
  );
};
