import { PostModel } from 'graphql/generated';

export const isPostDownloadable = (post: {
  urlMetadata?: Pick<PostModel['urlMetadata'][0], 'metadata'>[];
}) => {
  const urlMetadata = post.urlMetadata?.[0];
  let medium = urlMetadata?.metadata?.medium || '';

  if (urlMetadata?.metadata?.medias?.length) {
    medium = 'socialMediaPosts';
  }

  return ['file', 'video', 'image', 'socialMediaPosts'].includes(medium);
};

export const isPostPinnedToCollection = (
  post: {
    pinnedToCollections: Pick<
      PostModel['pinnedToCollections'][0],
      'parentCollectionId'
    >[];
  },
  collectionId: string,
) => {
  return post.pinnedToCollections.some(
    (p) => p.parentCollectionId === collectionId,
  );
};
