import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { IconBoldCloseSquare } from 'components/icons/components/bold/IconBoldCloseSquare';
import { SentimentColorsSx } from 'features/socialMediaListening/views/postDetail/consts';
import {
  BrandTrendsBySentimentData,
  BrandTrendsBySentimentResponse,
  MetricsTimeRange,
  Sentiment,
  useGetBrandForSentimentTrendsByBrandDialogQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { ThemeDialog } from '../../../inbound/sections/sentimentThemeCard/themeDialog';
import { TimeDurationSelector } from '../../../inbound/sections/timeDurationSelector/TimeDurationSelector';
import { SentimentTrendsByBrandDialogProps } from './types';

export const SentimentTrendsByBrandDialog = ({
  isOpen,
  onClose,
  selectedBrandId,
  brand,
  timeRange,
  setTimeRange,
  topicIds,
}: SentimentTrendsByBrandDialogProps) => {
  const themeDialogDisclosure = useDisclosure();

  const [currentTab, setCurrentTab] = useState(Sentiment.Positive);
  const [currentTrendId, setCurrentTrendId] = useState('');

  const brandCompetitorData = useMemo(() => {
    return brand?.brandCompetitors.data.find(
      (competitor) => competitor.brand.id === selectedBrandId,
    );
  }, [brand, selectedBrandId]);

  const brandSentimentData = useMemo(() => {
    return brand?.brandCompetitorsSentiment.data.find(
      (competitor) => competitor.brand.id === selectedBrandId,
    );
  }, [brand, selectedBrandId]);

  const { data, loading, refetch } =
    useGetBrandForSentimentTrendsByBrandDialogQuery({
      variables: {
        brandId: selectedBrandId,
        data: {
          sentiment: currentTab,
          timeRange,
          topicIds,
        },
      },
    });
  const sentimentTexts = data?.brand.allBrandTrends || [];

  const currentTrend = sentimentTexts.find(
    (trend) => trend.data.name === currentTrendId,
  );

  if (!selectedBrandId) {
    return null;
  }

  const renderSentimentTrends = () => {
    const sentimentTextsToRender = sentimentTexts.filter(
      (value, index, self) => self.indexOf(value) === index,
    );

    if (!sentimentTextsToRender.length) {
      return (
        <Typography
          variant="body-lg"
          color={theme.colors?.utility[800]}
          textAlign="center"
          my={10}
          display="block"
        >
          No data
        </Typography>
      );
    }

    return (
      <Box display="flex" flexDirection="column" gap={3}>
        {sentimentTextsToRender.map((trend) => (
          <Box
            sx={{
              cursor: 'pointer',
            }}
            display="flex"
            justifyContent="space-between"
            key={trend.data.name}
            onClick={() => {
              setCurrentTrendId(trend.data.name);
              themeDialogDisclosure.onOpen();
            }}
          >
            <Typography
              variant="body-lg"
              sx={{
                width: 'fit-content',
                p: theme.spacing(1, 2),
                borderRadius: theme.spacing(1),
                backgroundColor:
                  SentimentColorsSx?.[currentTab]?.backgroundColor ||
                  SentimentColorsSx.Default.backgroundColor,
                color:
                  SentimentColorsSx?.[currentTab]?.color ||
                  SentimentColorsSx.Default.color,
              }}
            >
              {trend.data.name}
            </Typography>
            <Typography
              variant="body-xl"
              color={theme.colors?.utility[700]}
              fontWeight={500}
            >
              {trend.score}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const handleThemeDialogClose = () => {
    themeDialogDisclosure.onClose();
    setCurrentTrendId('');
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleThemeDialogClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '70%',
          maxWidth: 1200,
          margin: '0 auto',
          overflowY: 'auto',
          borderRadius: theme.spacing(6),
          p: 6,
          opacity: themeDialogDisclosure.isOpen ? 0 : 1,
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          mb={4}
          alignItems="flex-start"
        >
          <Box display="flex" gap={1} flexDirection="column">
            <Typography variant="headline-lg">
              {brandCompetitorData?.brand.name ||
                brandSentimentData?.brand.name}
            </Typography>
            <Typography variant="body-lg" color={theme.colors?.utility[800]}>
              {!!brandCompetitorData?.mentionCount && (
                <>
                  {brandCompetitorData?.brand.name || 'Brand'} is mentioned in{' '}
                  {brandCompetitorData?.mentionCount} videos
                </>
              )}
            </Typography>
          </Box>

          <IconButton
            onClick={onClose}
            disableRipple
            sx={{ p: 0, color: theme.colors?.primary.black }}
          >
            <IconBoldCloseSquare size={20} />
          </IconButton>
        </Box>
      </Box>

      <Box display="flex" width="100%" justifyContent="flex-end">
        <TimeDurationSelector
          currentValue={timeRange}
          options={[
            MetricsTimeRange.OneWeek,
            MetricsTimeRange.OneMonth,
            MetricsTimeRange.ThreeMonths,
            MetricsTimeRange.AllTime,
          ]}
          onChange={(timeRange) => setTimeRange(timeRange as MetricsTimeRange)}
        />
      </Box>

      <Box
        sx={{
          '& .MuiTab-root': {
            borderBottom: 'none',
            pb: `0 !important`,
          },
          minHeight: 'fit-content',
          height: '100%',
        }}
      >
        <Tabs
          onChange={(val) => setCurrentTab(val as Sentiment)}
          tab={currentTab}
          tabs={[
            {
              key: Sentiment.Positive,
              label: (
                <Typography variant="body-xl">
                  Positive
                  <Box
                    component="span"
                    color={SentimentColorsSx.POSITIVE.color}
                  >
                    (
                    {Number(
                      brandSentimentData?.positivePercentage || 0,
                    ).toFixed(1)}
                    %)
                  </Box>
                </Typography>
              ),
              content: <Box>{renderSentimentTrends()}</Box>,
            },
            {
              key: Sentiment.Negative,
              label: (
                <Typography variant="body-xl">
                  Negative
                  <Box component="span" color={theme.colors?.utility['pink-3']}>
                    (
                    {Number(
                      brandSentimentData?.negativePercentage || 0,
                    ).toFixed(1)}
                    %)
                  </Box>
                </Typography>
              ),
              content: <Box>{renderSentimentTrends()}</Box>,
            },
            {
              key: Sentiment.Neutral,
              label: (
                <Typography variant="body-xl">
                  Neutral
                  <Box
                    component="span"
                    color={theme.colors?.utility['blue-3-new']}
                  >
                    (
                    {Number(brandSentimentData?.neutralPercentage || 0).toFixed(
                      1,
                    )}
                    %)
                  </Box>
                </Typography>
              ),
              content: <Box>{renderSentimentTrends()}</Box>,
            },
          ]}
        />
      </Box>

      {currentTrend && (
        <ThemeDialog
          {...themeDialogDisclosure}
          topicIds={topicIds}
          showBackButton
          brandSentimentTrend={
            currentTrend as unknown as BrandTrendsBySentimentData
          }
          timeRange={timeRange}
        />
      )}
    </Dialog>
  );
};
