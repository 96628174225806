import { Box, Dialog, Divider, Typography } from '@mui/material';
import { SearchRecent } from 'features/search';
import { SearchBar } from 'features/search/components/searchBar';
import {
  PostFilterType,
  SearchClickedResultFragmentSearchRecentFragment,
  SearchType,
  SearchableEntityType,
  UniversalSearchFragmentSearchHandlerFragment,
} from 'graphql/generated';
import { usePostQuery } from 'pages/juicebox/index/usePostQuery';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { useUserContext } from 'contexts/users/User.context';
import { AttachmentSearchResults } from './components/SearchResults/AttachmentSearchResults/AttachmentSearchResults';
import { LinkSearchResults } from './components/SearchResults/LinkSearchResults/LinkSearchResults';
import { NoteSearchResults } from './components/SearchResults/NoteSearchResults/NoteSearchResults';
import { SearchResults } from './components/SearchResults/SearchResults';
import { TaskSearchResults } from './components/SearchResults/TaskSearchResults/TaskSearchResults';
import styles from './SearchModal.module.scss';
import { CollectionSearchResults } from './components/SearchResults/CollectionSearchResults/CollectionSearchResults';

type SearchModalProps = {
  open: boolean;
  onClose: () => void;
  placeholder: string;
  keyword: string;
  onKeywordChange: (text: string) => void;
  recents?: SearchClickedResultFragmentSearchRecentFragment[];
  searchData?: UniversalSearchFragmentSearchHandlerFragment;
  loading?: boolean;
  searchType: SearchType;
  setSearchType: (type: SearchType) => void;
};

export type SearchModalRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

export const SearchModal = ({
  open,
  onClose,
  placeholder,
  keyword,
  onKeywordChange,
  searchData,
  recents,
  searchType,
  setSearchType,
  loading = false,
}: SearchModalProps) => {
  const { orgBilling } = useUserContext();

  const itemRefs = useRef<(HTMLElement | null)[]>([]);
  const { data: recentlyAddedPosts } = usePostQuery({
    args: {
      filters: {
        filterType: PostFilterType.OrganizationPosts,
      },
      take: 10,
    },
  });

  const [view, setView] = useState<SearchableEntityType | undefined>();

  const [focusedElIndex, setFocusedElIndex] = useState(-1);

  const handleNavigateBackToAllView = () => {
    setView(undefined);
  };

  useEffect(() => {
    itemRefs.current = [];
  }, [searchData, searchType, view]);

  useEffect(() => {
    itemRefs.current[focusedElIndex]?.focus();
  }, [focusedElIndex]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const gridViews = [
        SearchableEntityType.Links,
        SearchableEntityType.Attachments,
      ];
      if (e.key === 'ArrowLeft') {
        e.preventDefault();
        e.stopPropagation();
        if (view && gridViews.includes(view)) {
          setFocusedElIndex((prev) => prev - 1);
        }
      } else if (e.key === 'ArrowRight') {
        e.preventDefault();
        e.stopPropagation();
        if (view && gridViews.includes(view)) {
          setFocusedElIndex((prev) => prev + 1);
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();
        if (focusedElIndex > 0) {
          if (view && gridViews.includes(view)) {
            setFocusedElIndex((prev) => (prev - 5 >= 0 ? prev - 5 : 0));
          } else {
            setFocusedElIndex((prev) => prev - 1);
          }
        }
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        e.stopPropagation();
        if (itemRefs.current[focusedElIndex + 1]) {
          if (view && gridViews.includes(view)) {
            setFocusedElIndex((focusedElIndex) =>
              focusedElIndex + 5 < itemRefs.current.length
                ? focusedElIndex + 5
                : focusedElIndex,
            );
          } else {
            setFocusedElIndex((prev) => prev + 1);
          }
        }
      } else if (e.key === 'Enter') {
        itemRefs.current[focusedElIndex]?.click();
        onClose();
      } else {
        setFocusedElIndex(-1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedElIndex]); // eslint-disable-line

  const isEmptyResult = useMemo(() => {
    return (
      keyword !== '' &&
      !loading &&
      searchData &&
      searchData.noteSearchResult.hits.length === 0 &&
      searchData.linkSearchResult.hits.length === 0 &&
      searchData.attachmentSearchResult.hits.length === 0 &&
      searchData.taskSearchResult.hits.length === 0 &&
      searchData.collectionSearchResult.hits.length === 0
    );
  }, [loading, searchData]); // eslint-disable-line

  const renderResult = () => {
    if (keyword && searchData) {
      if (view === undefined) {
        return (
          <SearchResults
            searchString={keyword}
            elRefs={itemRefs}
            searchData={searchData}
            onShowMore={(view) => {
              setView(view);
            }}
          />
        );
      }
      if (view === SearchableEntityType.Links) {
        return (
          <LinkSearchResults
            searchType={searchType}
            searchStr={keyword}
            onNavigateBack={handleNavigateBackToAllView}
            elRefs={itemRefs}
          />
        );
      }
      if (view === SearchableEntityType.Notes) {
        return (
          <NoteSearchResults
            searchType={searchType}
            searchStr={keyword}
            onNavigateBack={handleNavigateBackToAllView}
            elRefs={itemRefs}
          />
        );
      }
      if (view === SearchableEntityType.Tasks) {
        return (
          <TaskSearchResults
            searchType={searchType}
            searchStr={keyword}
            onNavigateBack={handleNavigateBackToAllView}
            elRefs={itemRefs}
          />
        );
      }
      if (view === SearchableEntityType.Attachments) {
        return (
          <AttachmentSearchResults
            searchType={searchType}
            searchStr={keyword}
            onNavigateBack={handleNavigateBackToAllView}
            elRefs={itemRefs}
          />
        );
      }
      if (view === SearchableEntityType.Collections) {
        return (
          <CollectionSearchResults
            searchType={searchType}
            searchStr={keyword}
            onNavigateBack={handleNavigateBackToAllView}
            elRefs={itemRefs}
          />
        );
      }
    }

    if (recents && recents?.length > 0) {
      return (
        <Box display="flex" overflow="hidden">
          <SearchRecent
            recents={recents}
            recentlyAddedPosts={recentlyAddedPosts?.posts.data || []}
            elRefs={itemRefs}
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <div>
      <Dialog
        className={styles.searchModal}
        open={open}
        onClose={onClose}
        maxWidth="xl"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              backgroundColor: 'transparent',
              width: '100vw',
            },
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderWidth: 0,
            maxWidth: '800px',
            position: 'absolute',
            top: theme.spacing(20),
          },
        }}
      >
        <Box
          display="flex"
          flex={1}
          mx={2.5}
          overflow="hidden"
          flexDirection="column"
          sx={{ width: 'auto', height: theme.spacing(105) }}
        >
          <Box
            display="flex"
            flexDirection="column"
            bgcolor="white"
            borderRadius={6}
            boxShadow="0px 3px 12px -3px rgba(0, 0, 0, 0.16)"
            overflow="hidden"
            maxHeight={theme.spacing(150)}
            flex={1}
            maxWidth="100vw"
            padding={theme.spacing(3, 5)}
          >
            <SearchBar
              loading={loading}
              showingAIResults={searchType === SearchType.Hybrid}
              onChange={onKeywordChange}
              placeholder={placeholder}
              onKeyDown={(e) => {
                if (e.key === 'ArrowDown') {
                  e.stopPropagation();
                  setFocusedElIndex(0);
                }
              }}
              onActionKeyPress={() => {
                setSearchType(SearchType.Hybrid);
              }}
            />

            {!orgBilling?.aiFeaturesEnabled && keyword !== '' && (
              <Box
                sx={{
                  bgcolor: theme.colors?.utility['yellow-1'],
                  border: `1px solid ${theme.colors?.utility['yellow-2-new']}`,
                  padding: theme.spacing(2),
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  margin: theme.spacing(0, 2, 5, 2),
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: theme.spacing(2),
                    alignItems: 'center',
                  }}
                >
                  <IconCustomSparkles size={12} />
                  <Typography variant="headline-xxs">
                    Find things faster with AI Search
                  </Typography>
                </Box>
                <Typography
                  variant="subhead-sm"
                  color={theme.colors?.utility[800]}
                >
                  With AI Search you can search for a phrase and it will find
                  what you're looking for. ex: "Big Blue Sofa", "Dog Wearing
                  Clothes".
                </Typography>
              </Box>
            )}

            {(keyword !== '' || (recents && recents.length > 0)) &&
              (isEmptyResult ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  margin={theme.spacing(4, 3)}
                >
                  <Typography sx={{ ...theme.typography['body-lg'] }}>
                    We couldn't find any result matching "
                    <strong>{keyword}</strong>". Press Enter to expand search
                    results
                  </Typography>
                </Box>
              ) : (
                <>
                  <Divider sx={{ borderColor: theme.colors?.utility[400] }} />
                  <Box
                    maxWidth="720px"
                    overflow="auto"
                    sx={{
                      backgroundColor: theme.colors?.primary.white,
                      borderRadius: 3,
                      mt: 4,
                    }}
                  >
                    {/* This allows modal to be closed when use clicks on an item */}
                    <Box maxHeight="600px" onClick={onClose}>
                      {renderResult()}
                    </Box>
                  </Box>
                </>
              ))}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
