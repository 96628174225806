import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldArrowDown2 } from 'components/icons/components/bold/IconBoldArrowDown2';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useNavigatePostSearchParams } from 'features/post/hooks/useNavigateSearchParams';
import { isPostDownloadable } from 'features/post/utils';
import {
  PostAnnotationFragmentAnnotationFragmentDoc,
  PostFragmentPostDetailContentCollaborationFragmentDoc,
  PostFragmentPostDetailContentViewFragment,
  UrlMetadataFragmentIframelyFragmentDoc,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { PostDetailContentCollaboration } from './PostDetailContentCollaboration';
import { PostDetailContentRenderer } from './PostDetailContentRenderer';

export const POST_FRAGMENT_POST_DETAIL_CONTENT_VIEW = gql`
  fragment PostFragmentPostDetailContentView on PostModel {
    id
    createdAt
    urlMetadata {
      id
      ...URLMetadataFragmentIframely
    }
    annotations {
      id
      ...PostAnnotationFragmentAnnotation
    }
    ...PostFragmentPostDetailContentCollaboration
  }
  ${UrlMetadataFragmentIframelyFragmentDoc}
  ${PostAnnotationFragmentAnnotationFragmentDoc}
  ${PostFragmentPostDetailContentCollaborationFragmentDoc}
`;

export type PostDetailContentViewProps = {
  post: PostFragmentPostDetailContentViewFragment;

  // TODO: This is a temporary prop to disable next and prev navigation in post preview window
  // We'll remove this once we have handled passing in task id into this view as filters for prev/next navigation.
  allowNextAndPrevNavigation?: boolean;
};

export const PostDetailContentView = (props: PostDetailContentViewProps) => {
  const { post, allowNextAndPrevNavigation = true } = props;

  const isMobileView = useMediaQueryMobile();

  const { renderPrevBtn, renderNextBtn } = useNavigatePostSearchParams({
    postId: post.id,
  });

  const { triggerCommand } = useCommandContext();

  const actionsRender = (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        m: 6,
        display: 'flex',
        flexDirection: isMobileView ? 'row' : 'column',
        gap: 3,
        transition: '0.2s all',
        '> button': {
          opacity: isMobileView ? 1 : 0.4,
          ':hover': {
            opacity: 1,
          },
        },
        '.MuiIconButton-root': {
          color: 'inherit',
          p: 0,
        },
      }}
    >
      {/* TODO: Get more specs on this */}
      {isPostDownloadable(post) && (
        <IconButtonWithTooltip
          tooltip="Download"
          size="small"
          sx={{ width: 24, height: 24 }}
          onClick={() => {
            triggerCommand(COMMAND_TYPE.POST_DOWNLOAD, {
              posts: [post],
              canDownloadSinglePost: true,
            });
          }}
        >
          <IconBoldArrowDown2 size={20} />
        </IconButtonWithTooltip>
      )}
    </Box>
  );

  return (
    <>
      <PostDetailContentCollaboration post={post} />

      <Box
        key={props.post.id}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: isMobileView ? 'calc(100% - 50px)' : 'calc(100% - 144px)',
            m: 'auto',
            pt: isMobileView ? 22 : 12,
            pb: isMobileView ? 18 : 12,
            maxHeight: isMobileView ? 'calc(100vh - 200px)' : '100vh',
            position: 'absolute',
            inset: 0,
          }}
        >
          <PostDetailContentRenderer post={post} />
        </Box>
        {allowNextAndPrevNavigation ? (
          <>
            {renderPrevBtn()}
            {renderNextBtn()}
          </>
        ) : null}
      </Box>

      {actionsRender}
    </>
  );
};
