import { makeStyles } from '@mui/styles';
import { Box, styled } from '@mui/material';

export const useRichTextStyles = makeStyles(() => ({
  toolbar: {},
  toolbarIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
  },
}));

export const RichTextContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  marginTop: -4,
}));
