import { useClipboard, useDisclosure } from '@dwarvesf/react-hooks';
import { IconButton, Snackbar, SxProps } from '@mui/material';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import { theme } from 'styles/theme';

export const useCopyLink = () => {
  const { onCopy: onCopyLink } = useClipboard(window.location.href);
  const {
    isOpen: isCopied,
    onClose: onCloseCopyToast,
    onOpen: onShowCopyToast,
  } = useDisclosure();

  const renderCopyButton = (sx?: SxProps) => {
    return (
      <IconButton
        sx={{
          borderRadius: 100,
          background: `rgba(35, 6, 3, 0.10)`,
          ...sx,
        }}
        onClick={() => {
          onCopyLink();
          onShowCopyToast();
        }}
      >
        <IconOutlineLink2 size={16} color={theme.colors?.primary.black} />
      </IconButton>
    );
  };

  const renderCopyLinkToast = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          width: 'fit-content',
          '& .MuiPaper-root': {
            minWidth: 'fit-content',
          },
        }}
        ContentProps={{
          sx: {
            bgcolor: theme.colors?.utility['orange-1'],
            borderRadius: theme.spacing(3),
            border: 'none',
            ...theme.typography['headline-sm'],
            padding: theme.spacing(0, 8),
            color: theme.colors?.utility['orange-4'],
          },
        }}
        open={isCopied}
        autoHideDuration={3000}
        onClose={onCloseCopyToast}
        message="Link copied!!"
      />
    );
  };

  return { renderCopyButton, renderCopyLinkToast };
};
