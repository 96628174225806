import { Box, List } from '@mui/material';
import { SuggestionProps } from '@tiptap/suggestion';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconCustomDivider } from 'components/icons/components/custom/IconCustomDivider';
import { IconCustomHeading1 } from 'components/icons/components/custom/IconCustomHeading1';
import { IconCustomHeading2 } from 'components/icons/components/custom/IconCustomHeading2';
import { IconCustomHeading3 } from 'components/icons/components/custom/IconCustomHeading3';
import { IconLinearTaskSquare } from 'components/icons/components/linear/IconLinearTaskSquare';
import { IconOutlineBulletedList } from 'components/icons/components/outline/IconOutlineBulletedList';
import { IconOutlineImage } from 'components/icons/components/outline/IconOutlineImage';
import { IconOutlineNumberedList } from 'components/icons/components/outline/IconOutlineNumberedList';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useFileUploadHandlers, usePostNodeHandlers } from 'features/tiptap';
import React, { useImperativeHandle, useMemo, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { CommandMenuItem, MenuItemType } from './CommandMenuItem';

export type CommandListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

type CommandMenuListProps = Pick<
  SuggestionProps,
  'command' | 'query' | 'editor' | 'range'
>;

export const CommandMenuList = React.forwardRef<
  CommandListRef,
  CommandMenuListProps
>((props, ref) => {
  const { query = '', editor, range } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const { handleMediaUpload } = useFileUploadHandlers();
  const { handleAddPostsToEditor } = usePostNodeHandlers();

  const { triggerCommand } = useCommandContext();

  const commandList = useMemo(() => {
    return [
      {
        key: 'task',
        type: MenuItemType.Option,
        Icon: IconLinearTaskSquare,
        title: 'Task',
        subTitle: 'Create a task on this note',
        onClick: () => {
          editor.chain().focus().deleteRange(range).toggleTaskList().run();
        },
      },
      {
        key: 'heading-1',
        type: MenuItemType.Option,
        Icon: IconCustomHeading1,
        title: 'Heading 1',
        subTitle: 'Big Section Heading',
        onClick: () =>
          editor
            ?.chain()
            .focus()
            .deleteRange(range)
            .toggleHeading({ level: 1 })
            .run(),
        aliases: ['h1'],
      },
      {
        key: 'heading-2',
        type: MenuItemType.Option,
        Icon: IconCustomHeading2,
        title: 'Heading 2',
        subTitle: 'Medium Section Heading',
        onClick: () =>
          editor
            ?.chain()
            .focus()
            .deleteRange(range)
            .toggleHeading({ level: 2 })
            .run(),
        aliases: ['h2'],
      },
      {
        key: 'heading-3',
        type: MenuItemType.Option,
        Icon: IconCustomHeading3,
        title: 'Heading 3',
        subTitle: 'Small Section Heading',
        onClick: () =>
          editor
            ?.chain()
            .focus()
            .deleteRange(range)
            .toggleHeading({ level: 3 })
            .run(),
        aliases: ['h3'],
      },
      {
        key: 'numbered-list',
        type: MenuItemType.Option,
        Icon: IconOutlineNumberedList,
        title: 'Numbered List',
        subTitle: 'Ordered list with numbers',
        onClick: () =>
          editor?.chain().focus().deleteRange(range).toggleOrderedList().run(),
        aliases: ['nl', 'ol'],
      },
      {
        key: 'bullet-list',
        type: MenuItemType.Option,
        Icon: IconOutlineBulletedList,
        title: 'Bullet List',
        subTitle: 'Unordered list with bullets',
        onClick: () =>
          editor?.chain().focus().deleteRange(range).toggleBulletList().run(),
        aliases: ['bl', 'ul'],
      },
      {
        key: 'divider',
        type: MenuItemType.Option,
        Icon: IconCustomDivider,
        title: 'Divider',
        subTitle: 'Visually adds a line',
        onClick: () =>
          editor?.chain().focus().deleteRange(range).setHorizontalRule().run(),
      },
      {
        key: 'media',
        type: MenuItemType.Header,
        title: 'Media',
      },
      {
        key: 'import-from-juicebox',
        type: MenuItemType.Option,
        Icon: IconCustomCreativeJuiceBox,
        title: 'Import from Juicebox',
        subTitle: 'Directly import assets from the Juicebox',
        onClick: () => {
          editor?.chain().focus().deleteRange(range).run();
          triggerCommand(COMMAND_TYPE.POST_SELECT_FROM_CJB, {
            onCompleted: (posts) => {
              handleAddPostsToEditor(
                editor.view,
                posts.map((p) => p.id),
              );
            },
          });
        },
      },
      {
        key: 'upload-an-asset',
        type: MenuItemType.Option,
        Icon: IconOutlineImage,
        title: 'Upload an asset',
        subTitle: 'Image, File etc.',
        onClick: () => {
          editor?.chain().focus().deleteRange(range).run();

          const input = document.createElement('input');
          input.type = 'file';
          input.multiple = true;
          input.onchange = (e) => {
            const fileList = Array.from(
              (e.target as HTMLInputElement).files || [],
            );

            handleMediaUpload(editor.view, fileList);
          };

          input.click();
        },
      },
    ];
  }, [editor, handleMediaUpload, range]); // eslint-disable-line react-hooks/exhaustive-deps

  const processedCommandList = useMemo(() => {
    return commandList.filter(
      (each) =>
        each.key.toLowerCase().includes(query.toLowerCase()) ||
        each.aliases?.some((a) => a.startsWith(query)),
    );
  }, [commandList, query]);

  useImperativeHandle(
    ref,
    () => ({
      onKeyDown: ({ event }) => {
        event.stopPropagation();

        if (event.key === 'ArrowUp') {
          setSelectedIndex(
            (selectedIndex + processedCommandList.length - 1) %
              processedCommandList.length,
          );

          return true;
        }

        if (event.key === 'ArrowDown') {
          setSelectedIndex((selectedIndex + 1) % processedCommandList.length);

          return true;
        }

        if (event.key === 'Enter' || event.key === 'Tab') {
          processedCommandList[selectedIndex]?.onClick?.();

          return true;
        }

        return false;
      },
    }),
    [processedCommandList, selectedIndex],
  );

  return processedCommandList.length === 0 ? null : (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="white"
      borderRadius={theme.spacing(3)}
      boxShadow="0px 3px 12px -3px rgba(0, 0, 0, 0.16)"
      overflow="auto"
      maxHeight={theme.spacing(110)}
      flex={1}
    >
      <List
        component="nav"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: theme.spacing(3),
        }}
      >
        {processedCommandList.map(({ key, ...rest }, index) => (
          <CommandMenuItem
            key={key}
            selected={selectedIndex === index}
            {...rest}
          />
        ))}
      </List>
    </Box>
  );
});
