import { gql } from '@apollo/client';
import {
  CollectionFilterType,
  CollectionFilters,
  CollectionFragmentCollectionPostDndCollectionCardFragmentDoc,
  SortType,
  useGetCollectionsSmartSearchForPostSmartSearchResultViewQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetCollectionsSmartSearchForPostSmartSearchResultView(
    $filters: CollectionFilters!
    $take: Int
    $sortType: SortType
    $after: String
  ) {
    collectionsSmartSearch(
      filters: $filters
      take: $take
      sortType: $sortType
      after: $after
    ) {
      data {
        item {
          ...CollectionFragmentCollectionPostDndCollectionCard
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CollectionFragmentCollectionPostDndCollectionCardFragmentDoc}
`;

export const useCollectionSmartSearchQuery = (props: {
  filters: CollectionFilters;
  canSkip?: boolean;
  take?: number;
  sortType?: SortType;
}) => {
  const { filters, canSkip, take = 5, sortType } = props;

  const {
    data,
    loading,
    updateQuery,
    fetchMore: _fetchMore,
  } = useGetCollectionsSmartSearchForPostSmartSearchResultViewQuery({
    variables: {
      filters: {
        query: filters.query,
        parentCollectionIds: filters.parentCollectionIds,
        dateFilterInput: filters.dateFilterInput,
        userFilterInput: filters.userFilterInput,
        filterType: filters.filterType,
        searchAllCollections:
          filters.filterType === CollectionFilterType.ContentCalendar,
        includeContentIdeaCollections: filters.includeContentIdeaCollections,
      },
      take,
      sortType,
    },
    fetchPolicy: 'cache-and-network',
    skip: canSkip,
  });

  const fetchMore = async () => {
    _fetchMore({
      variables: {
        ...filters,
        after: data?.collectionsSmartSearch.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          collectionsSmartSearch: {
            ...fetchMoreResult.collectionsSmartSearch,
            data: [
              ...prev.collectionsSmartSearch.data,
              ...fetchMoreResult.collectionsSmartSearch.data,
            ],
          },
        };
      },
    });
  };

  return {
    data,
    loading,
    updateQuery,
    fetchMore,
  };
};
