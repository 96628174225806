import { useEffect, useRef, useState } from 'react';

type Props = {
  selectedIds: string[];
};

export const useDraggableCardDndClones = (props: Props) => {
  const { selectedIds } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [
    cloneElementsForSelectedDraggableCards,
    setCloneElementsForSelectedDraggableCards,
  ] = useState<HTMLDivElement[]>([]);

  const cleanCloneElementsForSelectedDraggableCards = () => {
    setCloneElementsForSelectedDraggableCards((o) => {
      o.forEach((element) => {
        const originalContainerElement = document.querySelector(
          `[data-rbd-draggable-container-id="${element.dataset.rbdDraggableContentId}"]`,
        ) as HTMLDivElement;

        originalContainerElement.style.opacity = '1';

        element.remove();
      });

      return [];
    });
  };

  // Listen to mouse movement and transform the clone elements
  // to follow mouse cursor correctly
  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        cloneElementsForSelectedDraggableCards.forEach((element, index) => {
          element.style.top = `${e.clientY + (index + 1) * 5}px`;
          element.style.left = `${e.clientX + (index + 1) * 5}px`;
          element.style.transition = 'none';
        });
      }
    };

    if (isDragging) {
      window.addEventListener('mousemove', onMouseMove);
    }

    return () => {
      if (isDragging) {
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [isDragging, cloneElementsForSelectedDraggableCards]);

  // Timeout to be sure that users keep the press on mouse down
  // before we clone out elements to prepare for dragging animation
  const mouseDownTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  // Listen to mousedown events
  // We want to clone the selected posts's HTML elements and append them to body
  // and perform some animation-related stuff
  const onDraggableCardMouseDown = (e: React.MouseEvent) => {
    // Do nothing if meta key is pressed
    if (e.metaKey) {
      return;
    }

    // If we have a timeout, clear it
    if (mouseDownTimeoutRef.current) {
      clearTimeout(mouseDownTimeoutRef.current);
    }

    mouseDownTimeoutRef.current = setTimeout(() => {
      // Get the HTML elements of the selected posts
      const elements = selectedIds
        .map(
          (id) =>
            document.querySelector(
              `[data-rbd-draggable-content-id="${id}"]`,
            ) as HTMLDivElement,
        )
        .filter(Boolean);

      // Clone the HTML elements of post content and append them to body (see CollectionPostDndPostCard line 69)
      // We want their position to be fixed so that they can follow the mouse cursor.
      // Their top/left position should be from the original element.
      const MAX_CLONE_COUNT = 4;
      const clones = elements.map((element, index) => {
        const clone = element.cloneNode(true) as HTMLDivElement;
        clone.style.position = 'fixed';

        const { top, left, height, width } = element.getBoundingClientRect();

        clone.style.top = `${top}px`;
        clone.style.left = `${left}px`;
        clone.style.height = `${height / 2}px`;
        clone.style.width = `${width / 2}px`;
        clone.style.transition = '0.15s all';
        clone.style.pointerEvents = 'none';
        clone.style.background = 'white';
        clone.style.zIndex = (elements.length + 999 - (index + 1)).toString();

        // Only show a limited number of clones
        if (index < MAX_CLONE_COUNT) {
          document.body.appendChild(clone);
        }

        // Fade out the original element's parent
        // (which should be the container of the post content)
        if (element.parentElement) {
          element.parentElement.style.opacity = '0.5';
        }

        // Move the clone element to where the cursor is
        // Plus some offset based on their index
        setTimeout(() => {
          clone.style.top = `${e.clientY + (index + 1) * 5}px`;
          clone.style.left = `${e.clientX + (index + 1) * 5}px`;
        });

        return clone;
      });

      setCloneElementsForSelectedDraggableCards(clones);
    }, 100);
  };

  // Cleanup if this event is fired & isDragging is false
  // That generally means it's simply a click on the post
  const onDraggableCardMouseUp = () => {
    // Clear the timeout if any
    if (mouseDownTimeoutRef.current) {
      clearTimeout(mouseDownTimeoutRef.current);
    }

    if (!isDragging) {
      cleanCloneElementsForSelectedDraggableCards();
    }
  };

  return {
    isDragging,
    setIsDragging,
    onDraggableCardMouseDown,
    onDraggableCardMouseUp,
    cleanCloneElementsForSelectedDraggableCards,
  };
};
